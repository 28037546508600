import React from "react";

import Icon from "common/components/general/Icon";

interface StatusIconProps {
	type: string;
	color: string;
	backgroundColor: string;
}

export const StatusIcon = (props: StatusIconProps) => {
	return (
		<div className={`assignment__status_icon ${props.backgroundColor}`}>
			<Icon type={props.type} className={props.color} fontSize="16" />
		</div>
	);
};
