import React from "react";

export interface ImageProps {
	src: string;
	className?: string;
	onClick?: () => void;
	style?: React.CSSProperties;
	width?: string
}

const Image: React.FC<ImageProps> = props => (
	<img
		src={props.src}
		alt={props.src}
		width={props.width}
		className={props.className}
		onClick={props.onClick} style={props.style}>
	</img>
);

export default Image;
