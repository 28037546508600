import { Tooltip } from "antd";
import React, { ReactNode } from "react";
import { truncate } from "../../utils/StringUtils";

declare type FontSize = "11" | "12" | "14" | "16" | "20" | "24" | "30" | "38" | "46" | "56";
declare type LineHeight = "18" | "20" | "22" | "24" | "28" | "32" | "38" | "46" | "54" | "64";
declare type WheightType = "regular" | "semibold";

export interface TextProps {
	children: ReactNode;
	fontSize: FontSize;
	lineHeight?: LineHeight;
	wheight?: WheightType;
	className?: string;
	maxLength?: number;
	onClick?: () => void;
	tooltip?: string;
	tooltipOnEllipsis?: boolean;
}

const Text: React.FC<TextProps> = props => {
	let textRef = React.useRef<HTMLSpanElement>(null);
	const hasEllipsis = () => (textRef.current ? textRef.current.offsetWidth < textRef.current.scrollWidth : false);

	const lineHeightClass = `text_line_height_${props.lineHeight ?? "20"}`;
	const fontWheightClass = `text_font_wheight_${props.wheight ?? "regular"}`;
	const textColor = props.className && props.className.indexOf("color") ? "" : "text";
	const childrenText = (props.children === undefined ? "" : props.children) as string;
	const text = props.maxLength ? truncate(childrenText, props.maxLength, childrenText.includes(" ")) : props.children;
	const textTooltip = props.tooltipOnEllipsis
		? hasEllipsis()
			? props.tooltip
			: ""
		: props.tooltip || (props.maxLength && props.maxLength <= childrenText.length ? props.children : "");

	return (
		<Tooltip title={textTooltip}>
			<span
				ref={textRef}
				className={`${textColor} text_font_size_${
					props.fontSize
				} ${lineHeightClass} ${fontWheightClass} ${props.className ?? ""}`}
				onClick={props.onClick}
			>
				{text}
			</span>
		</Tooltip>
	);
};

export default Text;
