import { API_BASE_URL } from "../../services/api.service";
import { CurrentUrl } from "../components/utils/WindowUtils";
import { DesignSettingDto } from "../../dtos/administration/customize/DesignSetting.dto";
import { JWTTokenDto } from "../../dtos/authentication/token/jwtToken.dto";
import jwtDecode from "jwt-decode";
import S3ActionType from "../../services/domain/administration/S3Action";
import {relativeUrl} from "../../services/s3/s3.service";
import { TemplatePublicDataComponent } from "../../scenes/admin/scenes/organizations/scenes/customize/utils/types";

/**
 * Returns the current url or the organization the user is participating
 */
export function getOrganizationIdOrCurrentUrl(): string {
	const signedToken: string | null = localStorage.getItem("jwt-token");
	const token: null | JWTTokenDto = signedToken ? jwtDecode(signedToken) : null;

	if (token?.Organizations?.length) {
		const defaultOrga = token.Organizations.find(item => item.Default);
		const nonDefaultOrga = token.Organizations.find(item => !item.Default);

		if (nonDefaultOrga) {
			return nonDefaultOrga.OrganizationId;
		} else if (defaultOrga) {
			return window.location.protocol + "//" + defaultOrga.Domain;
		}
	}

	return CurrentUrl;
}

/**
 *
 * @param defaultLogo
 * @param optionSetting
 */
export function getDesignFile(defaultLogo: string, optionSetting?: DesignSettingDto): string {
	const domain = getOrganizationIdOrCurrentUrl();

	if (optionSetting?.optionName) {
		return `${API_BASE_URL}/v1/api/s3/design-setting/${encodeURIComponent(domain)}/${optionSetting.optionName}`;
	}

	return defaultLogo;
}

/**
 *
 * @param fileName
 * @param s3Action
 */
export function getDesignS3Url(
	fileName: string,
	s3Action: S3ActionType.DOWNLOAD | S3ActionType.UPLOAD | S3ActionType.DELETE,
): string {
	return `${API_BASE_URL}${relativeUrl}/design-settings/${encodeURIComponent(fileName)}/${s3Action}?redirect=1`;
}


/**
 * Decide if a component is visible or not
 * @param pageComponents
 * @param key
 */
export function isComponentVisible(pageComponents: {[key: string]: TemplatePublicDataComponent}, key: string): boolean {
	return !pageComponents[key] || pageComponents[key]?.status === 1;
}


/**
 * Decide if a component is visible or not
 * @param pageComponents
 * @param key
 */
export function getComponentProps(pageComponents: {[key: string]: TemplatePublicDataComponent}, key: string): {[key: string]: string} {
	return pageComponents[key]?.specs || {};
}

/**
 * Function to check a boolean field from the component
 * 1. key is inexistent take return default or true if default is missing
 * 2. Value is "" or "0" return false
 * 3. Value is "1" return true
 */
export function boolVal(component: {[key: string]: string}, key: string, defaultVal: boolean | null = null): boolean {
	if (typeof component[key] === "undefined") {
		return defaultVal === null ? true : defaultVal;
	}


	return component[key] === "1";
}

