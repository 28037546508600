import React, { useEffect } from "react";
import ClassroomService from "services/administration/classroom.service";
import { CourseView } from "./_courseView";
// import { useStoreActions } from "easy-peasy";
import { GroupType, useMarketplaceContext } from "./PublicMarketplace";

interface SectionCoursesProps {
	onGroupUpdate: (val: GroupType) => void;
	group: GroupType;
	title: string;
	emoji: string;
}

export const SectionCourses = (props: SectionCoursesProps) => {
	const [state, setState] = React.useState({
		items: [],
		loading: true
	});
	// const updateTotalCoursesOnPublicMarketPlace = useStoreActions((actions: any) => actions.subjects.updateTotalCoursesOnPublicMarketPlace);

	const {
		marketplaceStore: [, setMarketplaceState]
	} = useMarketplaceContext();

	const setLoading = (loading: boolean) => {
		setState(state => ({ ...state, loading }));
	};

	const setItems = (items: []) => {
		setState(state => ({ ...state, items }));
	};

	const handleSearchProgress = (loading: boolean) => {
		setMarketplaceState((marketplaceState: any) => ({
			...marketplaceState,
			loading
		}));
	};

	useEffect(() => {
		const loadFeaturedCourses = async () => {
			setLoading(true);
			handleSearchProgress(true);

			const classromSerview: any = new ClassroomService();
			try {
				const response = await classromSerview.classroomsSearch({
					order: "created",
					limit: 10,
					page: 1,
					[props.group]: true
				});

				setItems(response.items);
				// updateTotalCoursesOnPublicMarketPlace({ group: props.group, number: response.total });
				setLoading(false);
				handleSearchProgress(false);
			} catch (err) {
				setLoading(false);
				handleSearchProgress(false);
				throw err;
			}
		};

		loadFeaturedCourses();
	}, []);

	const showAllClick = () => {
		props.onGroupUpdate(props.group);
	};

	return <CourseView title={props.title} emoji={props.emoji} items={state.items} loading={state.loading} showAllClick={showAllClick} />;
};
