import React from "react";

import { Radio as AntRadio } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

export interface RadioProps {
	size?: SizeType;
	onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
	disabled?: boolean;
	defaultValue?: string | number;
	onChange?: any;
	children: React.ReactNode;
	value?: any;
}

const Radio: React.FC<RadioProps> = props => {
	return <AntRadio.Group {...props}>{props.children}</AntRadio.Group>;
};

export default Radio;
