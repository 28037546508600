/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import { Card, Col, Row, Space } from "antd";
import React, { ReactNode } from "react";
import i18n from "i18next";
import classnames from "classnames";

import { EmptyState } from "./EmptyState";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Image from "common/components/general/Image";
import DefaulClassroomImage from "assets/images/main-classes-img1.svg";
import Button from "common/components/general/Button";
import { COURSE_BUILDER_PATH } from "../../../..";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._INDIVIDUAL_CLASSROOM_LAYOUT._CLASS_SLIDER";

interface ChildClassroomsCarouselProps {
	items: any[];
}

interface ChildClassroomsCarouselStateProps {
	index: number;
	showAll: boolean;
	showAllVisible: boolean;
	sliderLeftVisible: boolean;
	sliderRightVisible: boolean;
	items: any[];
	currentItems: any[];
}

export class ChildClassroomsCarousel extends React.Component<
	ChildClassroomsCarouselProps,
	ChildClassroomsCarouselStateProps
> {
	constructor(props: any) {
		super(props);
		this.state = {
			index: 0,
			showAll: false,
			showAllVisible: this.props.items.length > this.decrementFromWindowSize,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.decrementFromWindowSize,
			items: props.items,
			currentItems: this.props.items.slice(0, this.decrementFromWindowSize)
		};
	}

	setInitialState() {
		this.setState({
			index: 0,
			showAllVisible: this.props.items.length > this.decrementFromWindowSize,
			sliderLeftVisible: false,
			sliderRightVisible: !this.state.showAll && this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items
		});
	}

	componentDidMount() {
		window.addEventListener("resize", this.setInitialState.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setInitialState.bind(this));
	}

	toggleShowAll = () => {
		this.setState({
			index: 0,
			showAll: !this.state.showAll,
			sliderLeftVisible: false,
			sliderRightVisible: this.state.showAll,
			currentItems: this.state.items
		});
	};

	scroll = (step: number) => {
		if (this.state.index + step >= 0) {
			this.setState({
				index: this.state.index + step,
				sliderLeftVisible: this.state.index + step > 0,
				sliderRightVisible: this.state.index + step < this.state.items.length - this.decrementFromWindowSize,
				currentItems: this.state.items.slice(
					this.state.index + step,
					this.state.index + this.decrementFromWindowSize + 1
				)
			});
		}
	};

	get decrementFromWindowSize(): number {
		let decrement = 1;
		if (window.innerWidth >= 992) {
			decrement = 4;
		} else if (window.innerWidth >= 768) {
			decrement = 3;
		} else if (window.innerWidth >= 576) {
			decrement = 2;
		}
		return decrement;
	}

	get ShowAllLink(): ReactNode {
		const allLabel = i18n.t(`${TRANSLATION_BASE_PATH}._LINK_SHOW_ALL`);
		const someLabel = i18n.t(`${TRANSLATION_BASE_PATH}._LINK_NOT_SHOW_ALL`);
		const label = this.state.showAll ? someLabel : allLabel;
		return (
			this.state.showAllVisible && (
				<Text
					fontSize="14"
					lineHeight="22"
					className="color-blue-6 cursor_pointer"
					onClick={this.toggleShowAll}
				>
					{label}
				</Text>
			)
		);
	}

	get LeftScrollButton() {
		const iconClass = "childs__classSlider_nav_icon childs__classSlider_nav_icon_left";

		return (
			this.state.sliderLeftVisible && (
				<Button className={iconClass} type="default" shape="circle" onClick={() => this.scroll(-1)}>
					<Icon type="ri-arrow-left-s-line" />
				</Button>
			)
		);
	}

	get RightScrollButton() {
		const iconClass = "childs__classSlider_nav_icon childs__classSlider_nav_icon_right";

		return (
			this.state.sliderRightVisible && (
				<Button className={iconClass} type="default" shape="circle" onClick={() => this.scroll(+1)}>
					<Icon type="ri-arrow-right-s-line" />
				</Button>
			)
		);
	}

	getItem(item: any): ReactNode {
		return (
			<a href={`/${COURSE_BUILDER_PATH}/${Role.Teacher}/${item.id}`} className="cursor_pointer">
				<Row className="mb-16">
					<Image className="childs__classSlider_item_card_image" src={DefaulClassroomImage} />
				</Row>
				<Row>
					<Text
						maxLength={item.name !== undefined && item.name?.length > 28 ? 28 : undefined}
						fontSize="16"
						lineHeight="24"
						className="color-gray-9"
					>
						{item.name}
					</Text>
				</Row>
			</a>
		);
	}

	get Items(): ReactNode {
		return (
			this.state.currentItems &&
			this.state.currentItems.length > 0 &&
			this.state.currentItems.map((item: any) => {
				return (
					<Col key={item.id} xs={24} sm={12} md={8} lg={6} xl={6} xxl={6} className="p-8">
						{this.getItem(item)}
					</Col>
				);
			})
		);
	}

	render() {
		const rowClass = classnames({
			row__show_all: this.state.showAll,
			row__show_some: !this.state.showAll
		});
		const Data = this.state.currentItems && this.state.currentItems.length > 0;
		const justifyItems = !Data ? "center" : "start";

		return (
			<Card
				className="individual__course_builder_courses_carousel_card"
				title={
					<Row>
						<Col>
							<Space direction="vertical" size={8}>
								<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
									{i18n.t(`${TRANSLATION_BASE_PATH}._VIRTUAL_CLASSROOMS`)}
								</Text>
								<Text fontSize="14" lineHeight="22" className="color-gray-7">
									{i18n.t(`${TRANSLATION_BASE_PATH}._VIRTUAL_CLASSROOMS_DESCRIPTION`)}
								</Text>
							</Space>
							{Data && this.ShowAllLink}
						</Col>
					</Row>
				}
			>
				<Row justify={justifyItems} align="top" className={rowClass}>
					{this.LeftScrollButton}
					{this.Items}
					{this.RightScrollButton}
					{!Data && <EmptyState />}
				</Row>
			</Card>
		);
	}
}
