import React from "react";
import { Formik } from "formik";
import { formValidator } from "./ValidationSchema";
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Button from "common/components/general/Button";
interface UpdateTagProps {
	tag: any,
	name: string,
	onSubmitForm: (tag:any, values: any) => void;
}

export const UpdateTag = (props: UpdateTagProps) => {
	const initialValues = props.name !== undefined ? {
		name: props.name
	} : {}

	return (
		<Formik
				initialValues={initialValues}
				isInitialValid={false}
				validationSchema={formValidator}
				onSubmit={(values: any, { resetForm }) => {
					props.onSubmitForm(props.tag, values);
					resetForm({});
				}}
			>
				{formik => (
					<form onSubmit={formik.handleSubmit} autoComplete="off">
						<Input name="name" defaultValue={formik.initialValues.name} />
						<Button
							type="primary"
							htmlType="submit"
							icon="ri-arrow-right-line"
							size="large"
							iconPosition="right"
							className="full_width_button"
							disabled={!formik.isValid}
						>
							Ndrysho
						</Button>
					</form>
				)}
			</Formik>
	);
};
