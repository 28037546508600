import { Button, Row } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClassroomService from "services/administration/classroom.service";
import SubjectService from "services/administration/subject.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { CourseView } from "../_courseView";
import { useStoreActions } from "easy-peasy";

const TRANSLATION_BASE_PATH = "_MARKETPLACE";
const MAX_ITEMS_TO_LOAD = 10;
const SECTION_SUBPATH = "all-courses-by-subject";

export const SectionAllSubjects = ({handleSearchProgress, searchKey, filters, key: propKey, singleSection, loading: propsLoading}: any) => {
	const { t: translate } = useTranslation();
	const [items, setItems] = useState<any>([]);
	const [searchedItems, setSearchedItems] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [index] = useState(0);
	const [indexes, setIndexes] = useState<any>([1,2,3]);
	const [subjects, setSubjects] = useState<any>([]);
	const [searchIndex, setSearchIndex] = useState(0);
	const [imagePrefix, setImagePrefix] = useState(null);
	const updateTotalAllItems = useStoreActions((actions: any) => actions.subjects.updateTotalAllItems);
	const [reachedallcourses,setReachedAllCourses] = useState(false)

	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const organizationId = currentUserInfo?.Organizations?.[0]?.OrganizationId || 'e47c7a13-83e8-41a0-afcc-3a8fd0fbda65';

	// Get all Subjects
	useEffect(() => {
		/**
		 * Load all Subjects without filters
		 */
		const loadAllSubjects = () => {
			setLoading(true);
			handleSearchProgress(1);
			return new SubjectService()
				.getAll(organizationId)
				.then((result: any) => {
					const subjects = result.filter((subject: any) => subject?.TotalClassrooms > 0);
					return setSubjects(_.orderBy(subjects, (x: any) => x.name));
				})
				.finally(() => {
					handleSearchProgress(-1);
					setLoading(false);
				});
		};


		loadAllSubjects();
	}, []);

	useEffect(() => {
		setSearchIndex(0);
		setSearchedItems([]);

	}, [filters, searchKey]);


	// Get all matching topics if there are filters
	useEffect(() => {
		/**
		 * Get all filtered subjects based on current searchIndex
		 */
		const loadFilteredSubjectTopics = (subjectId: string) => {
			setLoading(true);
			handleSearchProgress(1);

			const cs = new ClassroomService();
			cs
				.getForMarketClassroomsBySubjectId({
					subjectId,
					key: searchKey,
					filters: filters,
					limit: MAX_ITEMS_TO_LOAD
				})
				.then((result: any) => {
					if (!result[1].length) {
						setSearchIndex(searchIndex + 1);
						updateTotalAllItems(0);
						return;
					}
					setSearchedItems((prevItems: any) => [...prevItems, result[1]]);
					updateTotalAllItems(result[2]);
				return setImagePrefix(result[0]);
			})
			.finally(() => {
				setLoading(false);
				handleSearchProgress(-1);
				})
				.catch((err: any) => {
					// todo show error
					console.log(err.message);
				});
		};

		if (subjects[searchIndex] && (searchKey || filters?.length > 0)) {
			loadFilteredSubjectTopics(subjects[searchIndex].id);
		}
	}, [searchIndex, subjects, searchKey, filters]);


	// Get all topics of the ${index} subject
	useEffect(() => {
		/**
		 * Load topics based on a subject
		 */

		setLoading(true);
		// Reset items on tag/search action
		if (searchKey || filters?.length) {
			setItems([]);
			return;
		}

		if (subjects[index]) {
			loadSubjectTopics(subjects[index].id);
		}
	}, [subjects, index, searchKey, filters?.length]);

	const loadSubjectTopics = (subjectId: any) => {
		
		handleSearchProgress(1);
		return new ClassroomService()
			.getForMarketClassroomsBySubjectId({
				subjectId,
				key: propKey,
				limit: MAX_ITEMS_TO_LOAD
			})
			.then((result: any) => {
				items.push(result[1]);
				setItems(items);
				updateTotalAllItems(result[2]);
				return setImagePrefix(result[0]);
			})
			.finally(() => {
				setLoading(false);
				handleSearchProgress(-1);
			});
	};
	/**
	 * Render a single grouped subject with topics
	 * @param items
	 * @param index
	 * @param loading
	 * @param uniqueKey
	 * @constructor
	 */
	const RenderCourseView = (items: any, index: any, uniqueKey: string | number, loading: boolean) => {
		if (!subjects[index]) {
			return;
		}
		return (
			<CourseView
				key={uniqueKey}
				title={subjects[index].name}
				icon={`ri-${subjects[index].icon}`}
				items={items}
				imagePrefix={imagePrefix}
				loading={loading}
				showAllLink={singleSection ? null : `${SECTION_SUBPATH}/${subjects[index].id}`}
			/>
		);
	};

	const renderThreeCourseOneByOne = async () => {
		const newIndexes: any = []
		for(let i = 0; i < indexes.length; i ++){
			if(subjects[indexes[i]]?.id){
				await loadSubjectTopics(subjects[indexes[i]].id)
				if((indexes[i] + 3) <= subjects.length){
					newIndexes.push(indexes[i] + 3)
				}
			}
		}
		if(newIndexes[newIndexes.length - 1] === subjects.length){
			setReachedAllCourses(true)
		}
		setIndexes(newIndexes)
	}

	return (
		<>
			{!searchKey && filters.length === 0 && (
				<>
					{items.map((subjectItems: any, index: number) => {
						if (!subjectItems.length) {
							return;
						}
						const isLoading = loading && items.length - 1 === index;
						return RenderCourseView(subjectItems, index, index, isLoading);
					})}
					{!singleSection && !reachedallcourses && (
						<Row justify="center">
							<Button
								disabled={propsLoading}
								loading={propsLoading}
								onClick={() => renderThreeCourseOneByOne()}
							>
								{translate(`${TRANSLATION_BASE_PATH}._LOAD_MORE`)}
							</Button>
						</Row>
					)}
				</>
			)}

			{(searchKey || filters.length > 0) && (
				<>
					{searchedItems.map((subjectItems: any) => {
						if (!subjectItems.length) {
							return;
						}

						const subjectsIndex = subjects.findIndex((item: any) => item.id === subjectItems[0].GradeSubject.SubjectId);
						const subjectId = subjectItems[0].GradeSubject.SubjectId;
						const isLoading = loading && searchedItems.length - 1 === index;
						return RenderCourseView(subjectItems, subjectsIndex, subjectId, isLoading);
					})}


					{!singleSection && searchIndex < subjects?.length - 1 && (
						<Row justify="center">
							<Button
								disabled={propsLoading}
								loading={propsLoading}
								onClick={() => setSearchIndex(searchIndex + 1)}
							>
								{translate(`${TRANSLATION_BASE_PATH}._LOAD_MORE`)}
							</Button>
						</Row>
					)}
				</>
			)}
		</>
	);
};
