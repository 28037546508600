import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { Skeleton, Empty, Row, Space, Breadcrumb, Divider, Radio, Modal, Card, Collapse } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";

import { ChildContext } from "../../components/layout/Layout";
import Button from "common/components/general/Button";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import ClassroomService from "services/administration/classroom.service";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import SubjectPlanTreeDto from "dtos/administration/subjectPlanTree.dto";
import LessonDto from "dtos/administration/lesson.dto";
import { SUBJECTS_PATH } from "scenes/subjects";
import { LESSON_PATH } from "scenes/subjects/scenes/materialItems/scenes/lessonMaterial/LessonMaterial";
import { COURSE_PATH } from "scenes/course";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";
import { StudentLessonList } from "common/components/lessonList/StudentLessonList";
import OAuth2Service from "services/authentication/oauth2.service";
import UserService from "services/authentication/user.service";
import EnrollmentService from "services/administration/enrollment.service";
import EnrollmentDto from "dtos/administration/enrollment.dto";
import { previewFile, previewFilePublic } from "../../../../../courseBuilder/utils/FileUtils";
import { SIGN_IN_PATH } from "../../../../../login/scenes/signIn";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import TagService from "services/administration/tag.services";
import TagDto from "dtos/administration/tag.dto";
import AnalyticsClassService from "services/analytics/class.service.";
import PayPalButton, { FundingType } from "common/components/general/PaypalButton";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";
import ClassroomDto from "dtos/administration/classroom.dto";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";
import { showMessage } from "common/utils/Notification";
import { OrganizationSectorType } from "services/domain/administration/Organization";
import { PriceTag } from "../marketplace/components/_courseView/components/PriceTag";
import { turnNumberToRange } from "common/utils/NumberUtils";
import parse from "html-react-parser";

import TruncateMarkup from "react-truncate-markup";

export const COURSE_PREVIEW_PATH = "course";
export const COURSE_PREVIEW_FULL_PATH = "course/:courseId";
const TRANSLATION_BASE_PATH = "_SUBJECTS._SUBJECT_ITEMS._COURSE_ITEM";
const showEnrolledUsers = false;

const getClassroomShortDescription = (classroom: ClassroomDto) => {
	if (classroom.description && ("" + classroom.description).length < 30) {
		return (
			<Text fontSize="14" lineHeight="22" className="color-gray-8">
				<TruncateMarkup lines={5}>
					<div>{parse("" + classroom.description)}</div>
				</TruncateMarkup>
			</Text>
		);
	}

	return null;
};

const getClassroomLongDescription = (classroom?: ClassroomDto) => {
	if (
		classroom?.Organization?.sector === OrganizationSectorType.Public &&
		("" + classroom.description).length >= 30
	) {
		return (
			<Collapse defaultActiveKey="course">
				<Collapse.Panel
					key="course"
					header={
						<Text fontSize="16" lineHeight="24" className="color-gray-9" wheight="semibold">
							Përshkrimi
						</Text>
					}
				>
					<HtmlInput name="courseDescription" value={classroom?.description} readOnly />
				</Collapse.Panel>
			</Collapse>
		);
	}
};

const getClassroomBasicAnalytics = (classroom: ClassroomDto, totalEnrollments?: number, lastUpdate?: string) => {
	if (classroom.isAsync || classroom.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Group) {
		return (
			<>
				<Space direction="horizontal" size={4} align="center">
					{showEnrolledUsers && totalEnrollments && totalEnrollments >= 10 ? (
						<>
							<Icon type="ri-user-line" className="color-gray-8" fontSize="16" />
							<Text fontSize="16" lineHeight="24" className="color-gray-8">
								{totalEnrollments < 500
									? turnNumberToRange(totalEnrollments, 500, 1000)
									: totalEnrollments}
							</Text>
						</>
					) : (
						""
					)}
				</Space>
				{lastUpdate && (
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						Përditësuar më{" "}
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{moment(lastUpdate).format("DD MMMM YYYY")}
						</Text>
					</Text>
				)}
			</>
		);
	}
	return null;
};

const getClassroomInterval = (classroom: ClassroomDto) => {
	if (!classroom.isAsync && (classroom.startDate || classroom.endDate)) {
		return (
			<Space>
				<Icon type="ri-calendar-line" fontSize="16" />
				<Text fontSize="16" lineHeight="24" className="color-gray-8">
					{classroom.startDate && moment(classroom.startDate).format("DD MMMM YYYY")} -{" "}
					{classroom.endDate && moment(classroom.endDate).format("DD MMMM YYYY")}
				</Text>
			</Space>
		);
	}
	return null;
};

class CourseItemComponent extends Component<any, any> {
	static contextType = ChildContext;

	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			rootLessonTags: [],
			lessonTagGroup: [],
			tabActiceKey: "0",
			lessons: [],
			classroom: undefined,
			isRegistered: false,
			userId: "",
			enrollmentId: "",
			lessonsFinished: [],
			courseId: "",
			imageUrl: undefined,
			groupedBy: null,
			showCoursePaidModal: false
		};
		this.getCoursePreviewImage = this.getCoursePreviewImage.bind(this);
		this.getNoDataComponent = this.getNoDataComponent.bind(this);
		this.retriveLessonsFromTree = this.retriveLessonsFromTree.bind(this);
		this.startOrContinueToClassroom = this.startOrContinueToClassroom.bind(this);
		this.loadCourseItem = this.loadCourseItem.bind(this);
		this.loadPublicCourseItem = this.loadPublicCourseItem.bind(this);
		this.getNextUnwatchedVideo = this.getNextUnwatchedVideo.bind(this);
		this.getLastFinishedLesson = this.getLastFinishedLesson.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const {
			history,
			match: { params }
		} = this.props;

		if (!params.courseId) return history.push(`${SUBJECTS_PATH}`);
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (currentUserInfo) {
			this.loadCourseItem();
		} else {
			this.loadPublicCourseItem();
		}
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (prevState.courseId !== this.state.courseId && currentUserInfo) this.loadCourseItem();
		if (prevState.courseId !== this.state.courseId && !currentUserInfo) this.loadPublicCourseItem();
	}

	componentWillUnmount() {
		this.context.setIndividualLiveCourseBannerVisible(false);
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.courseId !== prevState.courseId) return { courseId: params.courseId };
		return null;
	}

	loadPublicCourseItem = async () => {
		const classroom = await new ClassroomService().getPublicClassroomById(this.state.courseId);
		const imageUrl = (classroom.File && (await previewFilePublic(classroom.File))) || undefined;

		const subjectPlanTree: any =
			classroom.SubjectPlan &&
			(await new SubjectPlanTreeService().getPublicSubjectPlanTreeBySubjectId(classroom.SubjectPlan.id));

		const lessons = this.retriveLessonsFromTree(subjectPlanTree);

		this.setState({
			loading: false,
			lessons: _(lessons)
				.orderBy((x: any) => x.name, "asc")
				.value(),
			classroom: classroom,
			isRegistered: false,
			imageUrl: imageUrl ? imageUrl.url : undefined,
			lessonTagGroup: await this.getLessonTagGroup(classroom)
		});
		this.context.setParentTag(this.state.lessonTagGroup);
	};


	async getLessonTagGroup(classroom: ClassroomDto) {
		let rootLessonTags: any[] = await new TagService().getAllRootTagsforAdminLessons(classroom?.id);
		const filteredRootLessonTags = rootLessonTags.filter((tag: TagDto) => tag.SubTagsCount !== "0");
		rootLessonTags = filteredRootLessonTags.length === 0 ? [rootLessonTags[0]] : filteredRootLessonTags;

		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const groupedBy = queryString.get("groupedBy");
		const activeIndex = groupedBy && rootLessonTags.findIndex((tag: any) => tag.name === groupedBy);
		const activeKey: any = activeIndex && activeIndex > -1 ? activeIndex.toString() : this.state.tabActiceKey;

		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			rootLessonTags[activeKey]?.id,
			this.state.courseId
		);

		return _(lessonTagGroup)
			.orderBy((x: any) => Number(x?.ClassroomTags[0]?.priority), "asc")
			.value();
	}

	loadCourseItem = async () => {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return this.props.history.push("/" + SIGN_IN_PATH);

		this.setState({ loading: true });
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const groupedBy = queryString.get("groupedBy");

		const [classroom, user] = await Promise.all([
			new ClassroomService().getClassroomById(this.state.courseId),
			new UserService().getUserById(currentUserInfo.UserId)
		]);
		if (!classroom || classroom?.ParentClassroomId) return this.props.history.push(`/${SUBJECTS_PATH}`);
		if (!classroom.isAsync && classroom?.Organization?.sector === OrganizationSectorType.Private)
			return this.props.history.push(`/${COURSE_PATH}/_student/${this.state.courseId}`);

		const classroomEnrolled =
			user &&
			user.Classrooms &&
			user.Classrooms.find(
				(classroom: any) =>
					classroom.id === this.state.courseId || classroom.ParentClassroomId === this.state.courseId
			);
		const imageUrl = (classroom.File && (await previewFile(classroom.File))) || undefined;

		const isRegistered = classroomEnrolled ? true : false;
		const enrollmentId =
			isRegistered && classroomEnrolled && classroomEnrolled.Enrollment ? classroomEnrolled.Enrollment.id : null;
		const enrollmentObject: any =
			isRegistered && enrollmentId && (await new EnrollmentService().getEnrollmentById(enrollmentId));

		let rootLessonTags: any[] = await new TagService().getAllRootTagsforAdminLessons(classroom?.id);
		const filteredRootLessonTags = rootLessonTags.filter((tag: TagDto) => tag.SubTagsCount !== "0");
		rootLessonTags = filteredRootLessonTags.length === 0 ? [rootLessonTags[0]] : filteredRootLessonTags;

		const activeIndex = groupedBy && rootLessonTags.findIndex((tag: any) => tag.name === groupedBy);
		const activeKey: any = activeIndex && activeIndex > -1 ? activeIndex.toString() : this.state.tabActiceKey;

		const subjectPlanTree: any =
			classroom.SubjectPlan &&
			(await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(classroom.SubjectPlan.id));

		new AnalyticsClassService()
			.getClassroomTotalActiveEnrollments(this.state.courseId)
			.then((totalEnrollments: any[]) => {
				return this.setState({
					totalCourseEnrollments: totalEnrollments[0]?.enrollments
				});
			})
			.catch((error: any) => {
				console.log(error);
			});

		new AnalyticsClassService()
			.getClassroomLastUpdatedDate(this.state.courseId)
			.then((result: any[]) => {
				return this.setState({
					lastUpdatedDate: result[0]?.updatedAt
				});
			})
			.catch((error: any) => {
				console.log(error);
			});

		const lessons = this.retriveLessonsFromTree(subjectPlanTree);
		this.setState(
			{
				tabActiceKey: activeKey,
				loading: false,
				lessons: _(lessons)
					.orderBy((x: any) => x.name, "asc")
					.value(),
				classroom: classroom,
				isRegistered: isRegistered,
				registeredClassroomId: isRegistered ? classroomEnrolled?.id : null,
				userId: user.id,
				enrollmentId: enrollmentId,
				lessonsFinished: (isRegistered && enrollmentObject && enrollmentObject.Lessons) ?? [],
				imageUrl: imageUrl ? imageUrl.url : undefined,
				rootLessonTags: rootLessonTags,
				lessonTagGroup: await this.getLessonTagGroup(classroom),
				groupedBy: groupedBy ? groupedBy : rootLessonTags[0] ? rootLessonTags[0].name : ""
			},
			() => {
				this.context.setParentTag(this.state.lessonTagGroup);
				this.context.setIndividualLiveCourseBannerVisible(
					!classroom.isAsync &&
						classroom?.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Individual
				);
			}
		);
	};

	retriveLessonsFromTree = (subjectPlanTree: SubjectPlanTreeDto | null): LessonDto[] | null => {
		if (!subjectPlanTree) return null;
		const subjectPlanNode: SubjectPlanTreeDto[] = [];
		subjectPlanNode.push(subjectPlanTree);
		const lesson: LessonDto[] = [];
		while (subjectPlanNode.length !== 0) {
			const node: any = subjectPlanNode.pop();
			if (node.children.length !== 0) subjectPlanNode.push(...node.children);
			lesson.push(...node.lessons);
		}
		return lesson;
	};

	fetchAllSubGroupsLesson = async (e: any) => {
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			this.state.rootLessonTags[e.target.value.toString()]?.id,
			this.state.classroom?.id
		);
		return this.setState(
			{
				lessonTagGroup: _(lessonTagGroup)
					.orderBy((x: any) => Number(x.priority), "asc")
					.value(),
				tabActiceKey: e.target.value.toString(),
				groupedBy: this.state.rootLessonTags[e.target.value.toString()]?.name
			},
			() => this.context.setParentTag(this.state.lessonTagGroup)
		);
	};

	groupingOptionsToSelect = (): React.ReactNode => {
		return (
			<Radio.Group
				defaultValue={Number(this.state.tabActiceKey)}
				onChange={this.fetchAllSubGroupsLesson}
				className="course-item__tag-choose"
			>
				{this.state.rootLessonTags.map((lessonTag: any, index: number) => (
					<Radio.Button key={index} value={index}>
						{lessonTag.name}
					</Radio.Button>
				))}
			</Radio.Group>
		);
	};

	getCoursePreviewImage = (): ReactNode => {
		return (
			<Row align="middle" justify="center" className="full__width">
				<Space direction="vertical" className="full__width course__preview_logo">
					{this.state.imageUrl ? (
						<Image src={this.state.imageUrl} className="course__thumbnail_image" />
					) : (
						<div className="course__thumbnail_icon">
							<Row align="middle" justify="center">
								<Icon type="ri-image-line" />
							</Row>
						</div>
					)}
				</Space>
			</Row>
		);
	};

	getNoDataComponent = (): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row justify="center" gutter={[16, 24]} className="p-24">
				<Col span={24}>
					<Empty description={false}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
						</Text>
					</Empty>
				</Col>
			</Row>
		);
	};

	startOrContinueToClassroom = async () => {
		const groupByQueryString =
			!this.state.groupedBy || this.state.groupedBy === "" ? "" : `?groupedBy=${this.state.groupedBy}`;

		if (this.state.isRegistered && this.state.classroom.isAsync) {
			const nextLesson = this.getNextUnwatchedVideo();
			return (
				nextLesson &&
				this.props.history.push(
					`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${nextLesson}${groupByQueryString}`
				)
			);
		} else if (this.state.isRegistered && !this.state.classroom.isAsync) {
			if (this.state.classroom.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Individual) {
				return this.props.history.push(`/${COURSE_PATH}/_student/${this.state.registeredClassroomId}`);
			}
			return this.props.history.push(`/${COURSE_PATH}/_student/${this.state.classroom.id}`);
		}
		return await new EnrollmentService()
			.createEnrollment(
				new EnrollmentDto({
					ClassroomId: this.state.classroom.id,
					UserId: this.state.userId,
					status: "ACTIVE",
					enrolledAt: new Date()
				})
			)
			.then((result: any) => {
				if (this.state.classroom.isAsync) {
					const firstGroup = this.state.lessonTagGroup?.length > 0 ? this.state.lessonTagGroup[0] : null;
					const firstLesson = firstGroup
						? firstGroup.ClassroomTags[0]?.LessonClassroomTag[0]?.LessonId
						: this.state.lessons[0].id;
					return (
						firstLesson &&
						this.props.history.push(
							`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${firstLesson}${groupByQueryString}`
						)
					);
				}
				return this.props.history.push(`/${COURSE_PATH}/_student/${result?.ClassroomId}`);
			})
			.catch(error => {
				const statusCode = Number(error?.status ?? "500");
				if (statusCode === 409) {
					showMessage(error?.data?.message);
				}
			});
	};

	getNextUnwatchedVideo = () => {
		const firstGroup = this.state.lessonTagGroup?.length > 0 ? this.state.lessonTagGroup[0] : null;
		const firstLesson = firstGroup
			? firstGroup.ClassroomTags[0]?.LessonClassroomTag[0]?.LessonId
			: this.state.lessons[0].id;
		if (!this.state.lessonsFinished || (this.state.lessonsFinished && this.state.lessonsFinished.length === 0))
			return firstLesson;

		const finishedLesson = this.getLastFinishedLesson();
		const arrayOfLessons: [] =
			this.state.lessonTagGroup?.length > 0
				? this.state.lessonTagGroup.map((tagGroup: any) => {
						return tagGroup.ClassroomTags[0]?.LessonClassroomTag.map((lessonTag: any) => {
							return lessonTag.Lesson;
						});
				  })
				: [this.state.lessons];
		const lessons: any = arrayOfLessons.length > 0 ? arrayOfLessons.flat(1) : [];
		const lessonFound = lessons && lessons.findIndex((lesson: any) => lesson.id === finishedLesson);
		const nextLesson: any = lessonFound > -1 && lessonFound === lessons.length - 1 ? 0 : lessonFound + 1;
		return (lessons && lessons.length > 0 && lessons[nextLesson].id) || firstLesson;
	};

	getLastFinishedLesson = () => {
		let mostRecentFinishedVideoDate = new Date(0);
		let finishedLesson = "";
		this.state.lessonsFinished.map((lessonFinished: any) => {
			const lessonProgressDate =
				(lessonFinished.ProgressLessonEnrollment &&
					lessonFinished.ProgressLessonEnrollment.updatedAt &&
					new Date(lessonFinished.ProgressLessonEnrollment.updatedAt)) ||
				new Date(0);
			if (moment(lessonProgressDate).isSameOrAfter(moment(mostRecentFinishedVideoDate))) {
				mostRecentFinishedVideoDate = lessonProgressDate;
				finishedLesson = lessonFinished.id;
			}
		});
		return finishedLesson;
	};

	createOrder(data: any, actions: any) {
		return new ClassroomService().getPayPalOrderId(this.state?.classroom?.id);
	}

	onApprove(data: any, actions: any) {
		this.setState({
			showCoursePaidModal: true,
			tryingEnrollingPaidCourse: true
		});
		return new EnrollmentService().createEnrollmentFromPayPalOrderId(data.orderID).then((result: any) => {
			return this.setState({
				isRegistered: true,
				tryingEnrollingPaidCourse: false,
				registeredClassroomId: result?.ClassroomId
			});
		});
	}

	getClassroomEnrollOrStartButton() {
		const { t: translate } = this.props;
		if (this.state.classroom && this.state.classroom?.createdBy === OAuth2Service.CurrentUser?.UserId) {
			return null;
		} else if (
			(this.state.lessons?.length > 0 ||
				(!this.state.classroom.isAsync &&
					this.state.classroom.Organization?.sector === OrganizationSectorType.Public)) &&
			this.state.isRegistered
		) {
			return (
				<Button type="primary" size="large" onClick={() => this.startOrContinueToClassroom()}>
					{translate(`${TRANSLATION_BASE_PATH}._CONTINUE_COURSE`)}
				</Button>
			);
		} else if (
			(this.state.lessons?.length > 0 ||
				(!this.state.classroom.isAsync &&
					this.state.classroom.Organization?.sector === OrganizationSectorType.Public)) &&
			this.state.classroom.price &&
			Number(this.state.classroom.price) <= 0 &&
			this.state.userId
		) {
			return (
				<Button type="primary" size="large" onClick={() => this.startOrContinueToClassroom()}>
					{translate(`${TRANSLATION_BASE_PATH}._START_COURSE`)}
				</Button>
			);
		} else if (!this.state.userId) {
			return (
				<Button type="primary" size="large" onClick={() => this.props.history.push("/" + SIGN_IN_PATH)}>
					{translate(`${TRANSLATION_BASE_PATH}._START_COURSE`)}
				</Button>
			);
		} else if (
			(this.state.lessons?.length > 0 ||
				(!this.state.classroom.isAsync &&
					this.state.classroom.Organization?.sector === OrganizationSectorType.Public)) &&
			this.state.classroom.price &&
			Number(this.state.classroom.price) > 0 &&
			!this.state.isRegistered
		) {
			return (
				<div style={{ width: "250px" }}>
					<PayPalButton
						style={{
							layout: "vertical",
							tagline: false,
							label: "pay",
							shape: "pill"
						}}
						funding={{
							allowed: [FundingType.CARD, FundingType.PAYPAL],
							disallowed: [FundingType.CREDIT, FundingType.VENMO]
						}}
						createOrder={(data: any, actions: any) => this.createOrder(data, actions)}
						onApprove={(data: any, actions: any) => this.onApprove(data, actions)}
						onError={(error: any) => {
							console.error(error);
						}}
					/>
				</div>
			);
		}
		return null;
	}

	getClassroomPriceLabel() {
		return <PriceTag price={this.state.classroom.price} />;
	}

	getSuccessfullyBoughtCourseModal() {
		const { t: translate } = this.props;
		return (
			<Modal
				visible={this.state.showCoursePaidModal}
				title={null}
				footer={null}
				destroyOnClose
				onCancel={() => {
					this.setState({
						showCoursePaidModal: false
					});
				}}
				className="course__bought_successfully_modal"
			>
				<Skeleton
					active
					loading={this.state.tryingEnrollingPaidCourse}
					className="trying__enrolling_paid_course_skeleton"
				>
					<Card
						cover={
							<div className="cover__image_content">
								<Image src={require("assets/images/course-successfully-bought.svg")} />
							</div>
						}
					>
						<Row className="mb-8">
							<Col span={24} className="flex__center_justify_center text__align_center">
								<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
									{translate(`${TRANSLATION_BASE_PATH}._COURSE_SUCCESSFULLY_BOUGHT`)}
								</Text>
							</Col>
						</Row>
						<Row className="mb-24">
							<Col span={24} className="flex__center_justify_center text__align_center">
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._COURSE_BOUGHT`, {
										courseName: this.state.classroom.name
									})}
								</Text>
							</Col>
						</Row>
						<Row className="mb-24">
							<Col span={24} className="flex__center_justify_center">
								<Button type="primary" onClick={() => this.startOrContinueToClassroom()}>
									{translate(`${TRANSLATION_BASE_PATH}._CONTINUE_COURSE`)}
								</Button>
							</Col>
						</Row>
					</Card>
				</Skeleton>
			</Modal>
		);
	}

	render() {
		const { t: translate } = this.props;

		return (
			<Skeleton active loading={this.state.loading}>
				{this.state.classroom && (
					<Row gutter={[24, 24]}>
						<Col span={24} className="pb-24">
							<Breadcrumb>
								<Breadcrumb.Item>
									<Link to={`/${SUBJECTS_PATH}`}>
										{translate(`${TRANSLATION_BASE_PATH}._SUBJECTS_TITLE`)}
									</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>{this.state.classroom.name}</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
						<Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
							{this.getCoursePreviewImage()}
						</Col>
						<Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14} className="flex__column_space_between">
							<Row>
								<Col span={24}>
									<Space direction="vertical" size={8}>
										<Text
											fontSize="12"
											lineHeight="20"
											className="color-gray-7 course__preview_organization_name"
										>
											{this.state.classroom?.Organization?.name}
										</Text>
										<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
											{this.state.classroom.name}
										</Text>
										{getClassroomShortDescription(this.state.classroom)}
										{getClassroomBasicAnalytics(
											this.state.classroom,
											this.state.totalCourseEnrollments,
											this.state.lastUpdatedDate
										)}
										{getClassroomInterval(this.state.classroom)}
									</Space>
								</Col>
							</Row>
							<Row>
								<Col span={24} className="flex__start_space_between">
									{this.getClassroomEnrollOrStartButton()}
									{this.getClassroomPriceLabel()}
								</Col>
							</Row>
						</Col>
						<Col span={24}>{this.getSuccessfullyBoughtCourseModal()}</Col>
					</Row>
				)}
				{getClassroomLongDescription(this.state.classroom)}
				{(this.state.classroom?.isAsync ||
					(!this.state.classroom?.isAsync &&
						this.state.classroom?.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Group)) && (
					<>
						<Divider type="horizontal" className="mt-0 mb-8" />
						{this.state.lessons?.length === 0 ? (
							this.getNoDataComponent()
						) : (
							<Space direction="vertical" size={8} className="full__width">
								{this.state.lessons && (
									<Row justify="end">
										<Col span={24}>
											<Row justify="end">{this.groupingOptionsToSelect()}</Row>
										</Col>
									</Row>
								)}
								<Row>
									<Col span={24}>
										<StudentLessonList
											lessonGroups={this.state.lessonTagGroup}
											lessons={this.state.lessons}
											lessonsFinished={this.state.lessonsFinished}
											isEnrolled={this.state.isRegistered}
											groupedBy={this.state.groupedBy}
											isPriced={this.state.classroom.price && this.state.classroom.price > 0}
										/>
									</Col>
								</Row>
							</Space>
						)}
					</>
				)}
			</Skeleton>
		);
	}
}

export const CourseItem = withTranslation()(CourseItemComponent);
