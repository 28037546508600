import { v4 as uuidv4 } from "uuid";

export default class WizardDto {
	readonly id!: string;
	readonly State!: string;
	readonly RelationObjectId?: string;
	readonly WizardType!: string;

	constructor(data: { State: string; RelationObjectId?: string; WizardType: string }) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
