import React, { ReactNode } from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";

interface NotFoundProps {
	extra?: ReactNode;
}

const TRANSLATION_BASE_PATH = "_COMPONENTS._PAGE_NOT_FOUND";

export const NotFound = (props: NotFoundProps) => {
	const { t: translate } = useTranslation();
	return (
		<Result
			status="404"
			title={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
			subTitle={translate(`${TRANSLATION_BASE_PATH}._SUB_TITLE`)}
			extra={props.extra}
		/>
	);
};
