import { Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import JoinCreateClassModal, { TypeOfClassroom } from "../../../components/joinCreateClassModal/JoinCreateClassModal";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES._VIRTUAL_COURSES_EMPTY_STATE";

export const VirtualCoursesEmptyState = () => {
	const history = useHistory();
	const { t: translate } = useTranslation();
	return (
		<>
			<Row className="pb-24">
				<Col span={24} className="flex__center_justify_center">
					<Image src={require("assets/images/my-virtual-courses-empty-state.svg")} />
				</Col>
			</Row>
			<Row className="pb-8">
				<Col span={24} className="flex__center_justify_center">
					<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Col>
			</Row>
			<Row className="pb-24">
				<Col span={24} className="flex__center_justify_center">
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
					</Text>
				</Col>
			</Row>
			<Row className="pb-24">
				<Col span={24} className="flex__center_justify_center">
					<div style={{ maxWidth: "200px" }}>
						<JoinCreateClassModal history={history} typeOfClassroomToCreate={TypeOfClassroom.Virtual} />
					</div>
				</Col>
			</Row>
		</>
	);
};
