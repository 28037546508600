import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
	MainStudentLayout,
	RELATED_COURSE_PATH
} from "scenes/course/scenes/courseDetails/components/Layout/StudentLayout";
import { MAIN_PATH } from "scenes/main";
import MainLayout from "./components/layout/Layout";
export const COURSE_PATH = "course";

const Main = () => {
	return (
		<MainLayout>
			<Switch>
				<Route path={`/${COURSE_PATH}/_student/${RELATED_COURSE_PATH}`} exact component={MainStudentLayout} />
				<Route path={`/${COURSE_PATH}/*`} component={() => <Redirect to={`/${MAIN_PATH}`} />} />
				<Route path={`/${COURSE_PATH}`} exact component={() => <Redirect to={`/${MAIN_PATH}`} />} />
			</Switch>
		</MainLayout>
	);
};

export default Main;
