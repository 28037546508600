import { Avatar, Col, Divider, Row, Space, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import OAuth2Service from "services/authentication/oauth2.service";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { ORGANIZATION_PATH } from "../../scenes/organization";
import { MAIN_PATH } from "../..";

const TRANSLATION_BASE_PATH = "_MAIN._LEFT_SIDE_MENU";

const getRoleIcon = () => {
	if (OAuth2Service.isOwner) {
		return "ri-user-star-line";
	} else if (OAuth2Service.isOwnerOrAdmin) {
		return "ri-admin-line";
	} else if (OAuth2Service.isTeacher) {
		return "ri-user-2-line";
	} else if (OAuth2Service.isStudent) {
		return "ri-user-line";
	}
	return "ri-user-line";
};

const getRoleTitle = (translate: (key: string) => string) => {
	if (OAuth2Service.isOwner) {
		return translate(`${TRANSLATION_BASE_PATH}._OWNER`);
	} else if (OAuth2Service.isAdmin) {
		return translate(`${TRANSLATION_BASE_PATH}._ADMIN`);
	} else if (OAuth2Service.isTeacher) {
		return translate(`${TRANSLATION_BASE_PATH}._TEACHER`);
	}
	return translate(`${TRANSLATION_BASE_PATH}._STUDENT`);
};

export const SidebarOrganizationInfo = () => {
	const currentUser = OAuth2Service.CurrentUser;
	const history = useHistory();
	const { t: translate } = useTranslation();

	return (
		<Row className="sidebar__organization_info">
			<Col span={24}>
				<Row>
					<Col span={24} className="mb-12">
						<Space direction="vertical" size={12} align="center" className="full__width">
							<Avatar
								icon={<Icon type="ri-building-4-line" className="color-blue-6" />}
								className="background-color-blue-1 organization__avatar"
								size={40}
							/>
							<Space direction="vertical" size={8} align="center" className="full__width">
								<Text fontSize="14" lineHeight="22" className="color-gray-9 organization__name">
									{currentUser?.Organizations[0]?.Name}
								</Text>
								<Tooltip
									title={currentUser?.Organizations[0]?.Name}
									placement="right"
									trigger={["hover"]}
								>
									<div>
										<Text
											fontSize="14"
											lineHeight="22"
											className="color-gray-9 organization__name_minimized"
										>
											{currentUser?.Organizations[0]?.Name?.match(/\b(\w)/g)?.join("")}
										</Text>
									</div>
								</Tooltip>
								<Space
									direction="horizontal"
									size={4}
									align="center"
									className="organization__role_title_content"
								>
									<Icon type={getRoleIcon()} className="color-blue-6" fontSize="14" />
									<Text
										fontSize="12"
										lineHeight="20"
										className="color-blue-6 organization__role_title"
									>
										{getRoleTitle(translate)}
									</Text>
								</Space>
							</Space>
						</Space>
					</Col>
					{OAuth2Service.isOwnerOrAdmin ? (
						<>
							<Divider orientation="center" type="horizontal" className="mt-0 mb-12" />
							<Col
								span={24}
								onClick={() => {
									history.push(`/${MAIN_PATH}/${ORGANIZATION_PATH}`);
								}}
							>
								<Space align="center" size={8} className="go__to_manage_button">
									<Icon type="ri-settings-4-line" className="color-gray-8" fontSize="16" />
									<Text fontSize="14" lineHeight="22" className="color-gray-8 manage__label">
										{translate(`${TRANSLATION_BASE_PATH}._MANAGE`)}
									</Text>
								</Space>
							</Col>
						</>
					) : null}
				</Row>
			</Col>
		</Row>
	);
};
