import React, { ReactNode } from "react";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Image from "../../../../common/components/general/Image";
import { getDesignS3Url } from "../../../../common/utils/Design";
import S3ActionType from "../../../../services/domain/administration/S3Action";

interface LayoutProps {
	children: ReactNode;
}

export const BigLayout = ({ children, ...rest }: LayoutProps & {[key: string]: any}) => {
	return (
		<Row className="big-layout">
			<Col xs={0} sm={0} md={15} lg={17} className="big-layout__side">
				{rest.cover ? (
					<Image className="big-layout__logo" src={getDesignS3Url(rest.cover, S3ActionType.DOWNLOAD)} />
				) : (
					<div className="big-layout__empty-bg" />
				)}
			</Col>

			<Col xs={24} sm={24} md={9} lg={7} className="big-layout__content">
				{children}
			</Col>
		</Row>
	);
};
