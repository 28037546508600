/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

import ApiServiceData from "../api.service";
import OrganizationDto from "dtos/administration/organization.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "../domain/login/Role";
const relativeUrl = "/v1/api/organizations";

export default class OrganizationService extends ApiServiceData {
	public getFeaturedOrganizations() {
		return this.publicGet("/v1/api/public-organizations/featured")
			.then(res => {
				return res?.data ? res.data : [];
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createOrganization(organization: OrganizationDto) {
		return this.post(relativeUrl, organization);
	}

	public updateOrganization(organization: OrganizationDto) {
		return this.update(`${relativeUrl}/${organization.id}`, {
			name: organization.name,
			domain: organization.domain,
			numberOfUsers: organization.numberOfUsers,
			price: organization.price,
			ownerEmail: organization.ownerEmail,
			code: organization.code,
			status: organization.status,
			featured: organization.featured,
			featuredSort: organization?.featuredSort,
			marketplaceLogo: organization.marketplaceLogo
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateDomainOrganization(organization: any) {
		return this.update(`${relativeUrl}/domain/${organization.id}`, {
			id: organization.id,
			ownerEmail: organization.ownerEmail,
			name: organization.name,
			domain: organization.domain,
			hasNewDomain: organization.hasNewDomain,
			changedTypeOfDomain: organization.changedTypeOfDomain,
			sector: organization.sector
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateOwnerEmailoOrganization(organization: OrganizationDto) {
		return this.update(`${relativeUrl}/owner-email/${organization.id}`, {
			ownerEmail: organization.ownerEmail,
			domain: organization.domain
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public findOrganizationById(organizationId: string) {
		return this.get(relativeUrl + "/" + organizationId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public findCurrentOrganization() {
		return this.get(relativeUrl)
			.then(res => {
				return res.data.filter((organization: any) => {
					const existParent =
						organization.default &&
						organization.UserRoles.find((userRole: any) => {
							return userRole.Role?.code === Role.Parent;
						});
					return !organization.default || existParent;
				});
			})
			.catch(error => {
				throw error.response;
			});
	}

	public findOrganizationByCode(code: string) {
		return this.get(relativeUrl + "/code/" + code)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public joinOrganization(organizationCode: string): Promise<string> {
		return this.post(`${relativeUrl}/join`, { organizationCode })
			.then(res => {
				return res.data;
			})
			.then(async (organization: any) => {
				const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
				const decodedToken = OAuth2Service.CurrentToken;
				localStorage.setItem(currentUserInfo?.UserId || "", organization.id);
				await OAuth2Service.refreshToken(decodedToken.refreshToken, organization.id);
				return organization;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public joinOrganizationByInvitation(organizationId: string): Promise<string> {
		return this.post(`${relativeUrl}/join/invitation/${organizationId}`)
			.then(res => {
				return res.data;
			})
			.then(() => {
				const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
				const decodedToken = OAuth2Service.CurrentToken;
				localStorage.setItem(currentUserInfo?.UserId || "", organizationId);
				return OAuth2Service.refreshToken(decodedToken.refreshToken, organizationId);
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateJoinedOrganization(organizationCode: string): Promise<string> {
		return this.update(`${relativeUrl}/re-join/organization`, { organizationCode })
			.then(res => {
				return res.data;
			})
			.then(async (organization: any) => {
				const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
				const decodedToken = OAuth2Service.CurrentToken;
				localStorage.setItem(currentUserInfo?.UserId || "", organization.id);
				await OAuth2Service.refreshToken(decodedToken.refreshToken, organization.id);
				return organization;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public joinOrganizationByRole(organizationCode: string, roleCode: string): Promise<string> {
		return this.post(`${relativeUrl}/join-by-role`, { organizationCode, roleCode })
			.then(res => {
				return res.data;
			})
			.then(async (organization: any) => {
				if (!organization.requireApproval) {
					const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
					const decodedToken = OAuth2Service.CurrentToken;
					localStorage.setItem(currentUserInfo?.UserId || "", organization.id);
					await OAuth2Service.refreshToken(decodedToken.refreshToken, organization.id);
				}
				return organization;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public RegisterAsStudentByRole(roleCode: string): Promise<string> {
		return this.post(`${relativeUrl}/join-as-student`, { roleCode })
			.then(res => {
				return res.data;
			})
			.then(async (organization: any) => {
				if (!organization.requireApproval) {
					const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
					const decodedToken = OAuth2Service.CurrentToken;
					localStorage.setItem(currentUserInfo?.UserId || "", organization.id);
					await OAuth2Service.refreshToken(decodedToken.refreshToken, organization.id);
				}
				return organization;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getMyOrganizations() {
		return this.get(relativeUrl + "/enrolled/me")
			.then(res => {
				return res.data.filter((organization: any) => {
					const existParent: boolean =
						organization.default &&
						organization.UserRoles.find((userRole: any) => {
							return userRole.Role?.code === Role.Parent;
						});
					return !organization.default || existParent;
				});
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getMyOrganizationsAsTeacherOrPareent() {
		return this.get(relativeUrl + "/enrolled/me")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateRequireApprovalStatus(status: boolean) {
		return this.update(`${relativeUrl}/update-require-approval/${status}`, {});
	}

	public updateEnrollment(data: any) {
		return this.update(relativeUrl + `/update-enrollment/${data.status}`, data)
			.then(() => {
				return;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public isDomainUsed(domain: string): Promise<boolean> {
		return this.get(relativeUrl + "/isDomainUsed/" + domain)
			.then(res => {
				return res.data > 0;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public deleteOrganization(organizationId: string) {
		return this.delete(`${relativeUrl}/${organizationId}`);
	}
}
