import React, { useEffect, useState } from "react";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Col, message, Row, Skeleton, Space, Switch } from "antd";
import { useTranslation } from "react-i18next";
import Text from "common/components/general/Text";
import OrganizationService from "services/administration/organization.service";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._WELCOME_POPUP";

export const RequireApprovalToJoinOrg = (props: any) => {
	const { t: translate } = useTranslation();
	const [requireApproval, setRequireApproval] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		new OrganizationService().findCurrentOrganization().then((organization: any) => {
			setRequireApproval(organization[0].requireApproval);
			return setLoading(false);
		});
	}, []);

	const submitRequireApproval = () => {
		new OrganizationService().updateRequireApprovalStatus(!requireApproval).then(() => {
			setRequireApproval(!requireApproval);
			return message.success(translate(`${TRANSLATION_BASE_PATH}._APPROVAL_STATUS_SET_MESSAGE`));
		});
	};

	return (
		<Row className="mt-24 pt-16 pl-24 pr-24 pb-24 background-color-gray-2" style={{ borderRadius: "10px" }}>
			<Skeleton loading={loading}>
				<Col span="20">
					<Space direction="vertical">
						<Text fontSize="14" lineHeight="22" className="color-gray-9" wheight="semibold">
							{translate(`${TRANSLATION_BASE_PATH}._FOOTER_1`)}
						</Text>
						<Text fontSize="12" lineHeight="20" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._FOOTER_2_${requireApproval ? "ACTIVE" : "INACTIVE"}`)}
						</Text>
					</Space>
				</Col>
				<Col span="4">
					<Row justify="end">
						<Col span="24">
							<Switch
								checkedChildren={<CheckOutlined />}
								unCheckedChildren={<CloseOutlined />}
								checked={requireApproval}
								onChange={() => submitRequireApproval()}
							/>
						</Col>
					</Row>
				</Col>
			</Skeleton>
		</Row>
	);
};
