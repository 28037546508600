export enum LoadBalancerEnv {
	DEV,
	QA,
	STAGE,
	PRODUCTION
}

export interface LoadBalancerEnvSpec {
	dns: string,
	arn: string
}

export const getLoadBalancerEnvSpec = (loadBalancerEnv: LoadBalancerEnv): LoadBalancerEnvSpec => {
	switch (loadBalancerEnv) {
		case LoadBalancerEnv.DEV:
			return {
				dns: "development-app-alb-1753854206.us-east-2.elb.amazonaws.com",
				arn: "arn:aws:elasticloadbalancing:us-east-2:691880010551:loadbalancer/app/development-app-alb/c2ca98fa857b3a4c"
			};
		case LoadBalancerEnv.QA:
			return {
				dns: "qa-app-alb-1154528469.us-east-2.elb.amazonaws.com",
				arn: "arn:aws:elasticloadbalancing:us-east-2:691880010551:loadbalancer/app/qa-app-alb/c929cd5cc4b87bf4"
			};
		case LoadBalancerEnv.STAGE:
			return {
				dns: "staging-app-alb-897073197.us-east-2.elb.amazonaws.com",
				arn: "arn:aws:elasticloadbalancing:us-east-2:691880010551:loadbalancer/app/staging-app-alb/eaecfd04d53a95f2"
			};
		case LoadBalancerEnv.PRODUCTION:
			return {
				dns: "production-app-alb-474865921.us-east-2.elb.amazonaws.com",
				arn: "arn:aws:elasticloadbalancing:us-east-2:691880010551:loadbalancer/app/production-app-alb/91ea88d7fad564df"
			};
	}
};
