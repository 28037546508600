import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._INVITE_STUDENTS";

export const formValidator = (translate: (key: string) => string) => {
	const obj: any = Yup.object({
		emails: Yup.array()
			.of(
				Yup.object().shape({
					email: Yup.string()
						.nullable(false)
						.trim()
						.email(translate(`${TRANSLATION_BASE_PATH}._NOT_VALID_EMAIL`))
						.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
				})
			)
	});

	return obj;
};
