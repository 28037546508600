import React, { ReactNode } from "react";
import { Row, Card, Col, List } from "antd";
import { useTranslation } from "react-i18next";
import Text from "common/components/general/Text";

const TRANSLATION_BASE_PATH = "_COURSE._COURSE_DETAILS._ASSIGNMENT";

interface ListCardProps {
	items: {
		id: string;
		endDate: string;
		isCommitted: any;
		isEvaluated: string;
		grade: string;
	}[];
	getDate: (value: string) => ReactNode;
	getSubmissionStatus: (isCommitted: boolean, date: any, justify: any) => ReactNode;
	getStatus: (isEvaluated: boolean, date: any, isCommitted: boolean, justify: any) => ReactNode;
	getGrade: (grade: string, date: any, isCommitted: boolean, isEvaluated: boolean, justify: any) => ReactNode;
	getName: (value: string) => ReactNode;
}

const getLabel = (value: string) => {
	return (
		<Text fontSize="14" lineHeight="22" className="color-gray-7">
			{value}
		</Text>
	);
};

const getContent = (props: ListCardProps, item: any, translate: any) => {
	return (
		<>
			<Row>
				<Col span={14}>{getLabel(translate(`${TRANSLATION_BASE_PATH}._DATE`))}</Col>
				<Col span={10}>{getLabel(translate(`${TRANSLATION_BASE_PATH}._COMMITTED`))}</Col>
			</Row>
			<Row>
				<Col span={14}>{props.getDate(item.endDate)}</Col>
				<Col span={10}>{props.getSubmissionStatus(item.isCommitted, item.endDate, "start")}</Col>
			</Row>
			<Row className="pt-16">
				<Col span={14}>{getLabel(translate(`${TRANSLATION_BASE_PATH}._STATUS`))}</Col>
				<Col span={10}>{getLabel(translate(`${TRANSLATION_BASE_PATH}._GRADE`))}</Col>
			</Row>
			<Row>
				<Col span={14}>{props.getStatus(item.isEvaluated, item.endDate, item.isCommitted, "start")}
				</Col>
				<Col span={10}>
					{props.getGrade(item.grade, item.endDate, item.isCommitted, item.isEvaluated, "start")}
				</Col>
			</Row>
		</>
	);
};

export const ListCard = (props: ListCardProps) => {
	const { t: translate } = useTranslation();
	return (
		<List
			grid={{
				gutter: 16,
				xs: 1
			}}
			dataSource={props.items}
			renderItem={(item: any) => (
				<List.Item>
					<Card id={item.id} title={props.getName(item.name)}>
						{getContent(props, item, translate)}
					</Card>
				</List.Item>
			)}
		/>
	);
};
