import ApiServiceData from "../api.service";
import S3ActionType from "services/domain/administration/S3Action";
export const relativeUrl = "/v1/api/s3";

export default class BucketS3Service extends ApiServiceData {
	public getS3UrlForAction(
		fileName: string,
		s3Action: S3ActionType.DOWNLOAD | S3ActionType.UPLOAD | S3ActionType.DELETE
	): Promise<string> {
		return this.get(`${relativeUrl}/${encodeURIComponent(fileName)}/${s3Action}`)
			.then(res => {
				return res.data as string;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getS3UrlPublicFiles(
		fileName: string,
		s3Action: S3ActionType.DOWNLOAD | S3ActionType.UPLOAD | S3ActionType.DELETE
	): Promise<string> {
		return this.publicGet(`${relativeUrl}/public-files/${encodeURIComponent(fileName)}/${s3Action}`)
			.then(res => {
				return res.data as string;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getS3UrlForPublicAction(id: string): Promise<string> {
		return this.publicGet(`${relativeUrl}/${id}`)
			.then(res => {
				return res.data as string;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
