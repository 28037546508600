import ApiServiceData from "../api.service";
import { Page } from "../../dtos/administration/customize/Page.dto";

const relativeUrl = "/v1/api/pages";

export default class PageService extends ApiServiceData {
	async findAll(organizationId: string, templateId: string): Promise<Page[]> {
		return this.get(relativeUrl + "/" + organizationId + "/" + templateId + "/list")
			.then(res => {
				return res.data as Page[];
			})
			.catch(error => {
				throw error.response;
			});
	}

	async find(id: string) {
		return this.get(relativeUrl + "/" + id)
			.then(res => {
				return res.data as Page;
			})
			.catch(error => {
				throw error.response;
			});
	}

	async updateStatus(status: number, id: string) {
		return this.update(`${relativeUrl}/${id}/update-status`, {status})
			.then(res => {
				return res.data as Page;
			})
			.catch(error => {
				throw error.response;
			});
	}
}