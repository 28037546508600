import React, { Component, ReactNode } from "react";
import { Row, Col, Space, Layout as AntLayout } from "antd";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { SIGN_IN_PATH } from "../signIn/index";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Icon from "common/components/general/Icon";
import Alert from "common/components/feedback/Alert";
import { MainStudentRegisterForm } from "./MainStudentRegisterForm";
import { TeacherRegistrationForm } from "./TeacherRegistrationForm";
import Text from "common/components/general/Text";
import { formValidator } from "./ValidationSchema";
import { Role } from "services/domain/login/Role";
import OAuth2Service from "services/authentication/oauth2.service";
import UserDto from "dtos/authentication/user/user.dto";
import { REGISTER_EMAIL_VERIFICATION_PATH } from "../registerEmailVerification/index";
import ParentRegistration from "./ParentRegistration";
import { JoinInstagramAlert } from "../../components/joinInstagramAlert";
import {getDomain, isReservedDomain} from "services/common/check-domain";
import { Layout } from "../../components/layout";

export const REGISTER_PATH = "register";
const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

class RegisterComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.onAccountTypeChange = this.onAccountTypeChange.bind(this);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: "",
			notValidEmails: [],
			registerAsStudent: false,
			registerAsTeacher: false,
			typeOfRegistration: "",
			parentRegistration: false,
			parentEmail: null,
			loadingParams: true
		};
		this.showRegisterParent = this.showRegisterParent.bind(this);
		this.RegisterComponent = this.RegisterComponent.bind(this);
	}

	recaptchaRef: any = React.createRef();

	getFieldsFromParams = (): {
		email: string | null;
		typeOfRegistration: string | null;
		dateOfBirth: string | null;
		parentEmail: string | null;
		parentInvitationId: string | null;
	} => {
		const {
			match: { params }
		} = this.props;
		if (params.code && params.code !== "") {
			const decoded = new Buffer(params.code ?? "", "base64").toString("ascii");
			const uriConvert = decoded.split("&").map((decodeParams: any) => {
				const [key, value] = decodeParams.split("=");
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			}).join("&");
			const queryString = new URLSearchParams(`?${uriConvert}`);
			return {
				email: queryString.get("email"),
				typeOfRegistration: queryString.get("typeOfRegistration"),
				dateOfBirth: queryString.get("dateOfBirth"),
				parentEmail: queryString.get("parentEmail"),
				parentInvitationId: queryString.get("parentInvitationId"),
			};
		}
		return {
			email: null,
			typeOfRegistration: null,
			dateOfBirth: null,
			parentEmail: null,
			parentInvitationId: null
		};
	};

	componentDidMount() {
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const isParent = queryString.get("parent");

		const { email, typeOfRegistration, dateOfBirth, parentEmail, parentInvitationId } = this.getFieldsFromParams();

		if (!!email && !!typeOfRegistration) {
			return this.setState({
				registerAsStudent: typeOfRegistration === "student",
				registerAsTeacher: typeOfRegistration !== "student",
				typeOfRegistration: typeOfRegistration,
				predefinedEmail: email,
				predefinedDateOfBirth: dateOfBirth,
				predefinedParentEmail: parentEmail,
				predifinedParentInvitationId: parentInvitationId,
				loadingParams: false
			});
		}

		this.setState({
			loadingParams: false,
			parentRegistration: isParent !== null
		});
	}

	onAccountTypeChange = (formik: any, e: any) => {
		if (e.target.value === Role.Teacher) {
			formik.setFieldValue("birthDate", undefined);
			formik.setFieldValue("username", "");
		}
	};

	showAlertContent = (): ReactNode => {
		return this.state.hasError ? (
			<Row gutter={24}>
				<Col
					xs={24}
					className="mt-24"
				>
					<Alert message={this.state.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	onSubmit = async (values: any) => {
		const { t: translate, history } = this.props;

		if (this.state.notValidEmails.includes(values.email.toLowerCase())) {
			this.setState({
				submitting: false,
				hasError: true,
				errorMessage: translate(`${TRANSLATION_BASE_PATH}._406_API_STATUS_CODE`)
			});
			return;
		}

		this.setState({
			submitting: true,
			hasError: false,
			errorMessage: ""
		});

		await OAuth2Service.loginClient().catch(error => {
			const errorStatus = error?.status ?? "500";
			return this.setState({
				submitting: false,
				hasError: true,
				errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
			});
		});

		const dateOfBirth =
			values.accountType === Role.Teacher ? moment().utc(true) : moment(values.birthDate, "YYYY-MM-DD").utc(true);
		const today = moment();
		const duration = moment.duration(today.diff(dateOfBirth));
		const years = duration.asYears();

		let username = values.username;

		if (values.accountType === Role.Teacher || years >= 13) {
			username = `${values.email.substring(0, values.email.indexOf("@"))}${uuidv4().substring(0, 4)}`;
		}

		const recaptchaValue = await this.recaptchaRef.current.executeAsync();

		const newUserDto = new UserDto({
			email: values.accountType === Role.Teacher || years >= 13 ? values.email : null,
			parentEmail:
				!this.state.parentEmail && values.accountType === Role.Student && years < 13
					? values.email
					: years > 13 && this.state.predefinedParentEmail
						? this.state.predefinedParentEmail
						: null,
			parentInvitationId: this.state.predifinedParentInvitationId,
			firstName: values.firstName,
			lastName: values.lastName,
			dateOfBirth: dateOfBirth.toDate(),
			password: values.password,
			username: username.replace(" ", ""),
			roleCode: this.state.parentEmail ? "_PARENT" : values.accountType,
			recaptchaValue: recaptchaValue
		});

		await new OAuth2Service()
			.signUp(newUserDto)
			.then(() => {
				this.setState({ hasError: false, errorMessage: "" });
				return history.push(`/${SIGN_IN_PATH}/${REGISTER_EMAIL_VERIFICATION_PATH}`, {
					userId: newUserDto.id,
					email: newUserDto.email !== null ? newUserDto.email : newUserDto.parentEmail
				});
			})
			.catch(error => {
				this.recaptchaRef.current.reset();
				let errorStatus = error?.status ?? "500";
				if (errorStatus === 409) errorStatus = error.data.error.statusCode;
				this.setState({
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
				if (errorStatus === 406) {
					const notValidEmails = this.state.notValidEmails;
					this.setState({
						notValidEmails: [...notValidEmails, values.email.toLowerCase()]
					});
				}
			})
			.finally(() => {
				localStorage.removeItem("jwt-token");
				this.setState({ submitting: false });
			});
	};

	showRegisterParent(parentEmail: string) {
		this.setState({
			parentRegistration: false,
			parentEmail: parentEmail
		});
	}

	RegisterComponent = () => {
		const { t: translate, history } = this.props;
		return (
			<>
				{!this.state.parentRegistration && !this.state.loadingParams && (
					<>
						<Formik
							enableReinitialize={true}
							validationSchema={formValidator(translate)}
							initialValues={{
								accountType: this.state.parentEmail ? Role.Teacher : this.state.registerAsTeacher ? Role.Teacher : Role.Student,
								email: this.state.parentEmail ? this.state.parentEmail : this.state.predefinedEmail ? this.state.predefinedEmail : "",
								birthDate: this.state.predefinedDateOfBirth,
								birthDate_date: this.state.predefinedDateOfBirth
									? +moment(this.state.predefinedDateOfBirth).format("DD")
									: null,
								birthDate_month: this.state.predefinedDateOfBirth
									? +moment(this.state.predefinedDateOfBirth).format("MM")
									: null,
								birthDate_year: this.state.predefinedDateOfBirth
									? +moment(this.state.predefinedDateOfBirth).format("YYYY")
									: null
							}}
							onSubmit={this.onSubmit}
						>
							{formik => (
								<form onSubmit={formik.handleSubmit} autoComplete="off">
									{(getDomain(window.location.hostname) === "akademi.al" || isReservedDomain(window.location.hostname)) && (
										<Row gutter={24} className="pb-24">
											<Col
												xs={24}
											>
												{!this.state.parentEmail && (
													<Row className="pb-48 pt-8" justify="center">
														<Space>
															<Text fontSize="14" lineHeight="22" className="newBadge">
																{translate(`${TRANSLATION_BASE_PATH}._PARENT._NEW`)}
															</Text>
															<Text fontSize="14" lineHeight="22" className="color-gray-8">
																{translate(
																	`${TRANSLATION_BASE_PATH}._PARENT._ARE_YOU_PARENT`
																)}{" "}
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-blue-6 cursor_pointer"
																	onClick={() =>
																		this.setState({parentRegistration: true})
																	}
																>
																	{translate(
																		`${TRANSLATION_BASE_PATH}._PARENT._ENTER_HERE`
																	)}
																</Text>
															</Text>
														</Space>
													</Row>
												)}
												<Row>
													<Text fontSize="30" lineHeight="38" wheight="semibold">
														{this.state.parentEmail
															? translate(`${TRANSLATION_BASE_PATH}._PARENT._REGISTER_TITLE`)
															: translate(`${TRANSLATION_BASE_PATH}._REGISTER_LABEL`)}
													</Text>
												</Row>
											</Col>
										</Row>)}
									{this.showAlertContent()}
									{!this.state.parentEmail && (
										<Row gutter={24}>
											<Col xs={24} className="mt-24">
												<Radio
													name="accountType"
													label={translate(`${TRANSLATION_BASE_PATH}._ACCOUNT_TYPE_LABEL`)}
													defaultValue={this.state.registerAsTeacher ? Role.Teacher : Role.Student}
													onRadioChange={e => {
														this.onAccountTypeChange(formik, e);
													}}
												>
													<Row gutter={8}>
														<Col span={12}>
															<RadioElement value={Role.Student} showAsBox={true}
																		  disabled={this.state.registerAsTeacher}>
																<div className="account__type__option">
																	<Icon type="ri-user-line" />
																	{translate(`${TRANSLATION_BASE_PATH}._STUDENT`)}
																</div>
															</RadioElement>
														</Col>
														<Col span={12}>
															<RadioElement
																value={Role.Teacher}
																showAsBox={true}
																disabled={this.state.registerAsStudent}
															>
																<div className="account__type__option">
																	<Icon type="ri-user-2-line" />
																	{translate(`${TRANSLATION_BASE_PATH}._TEACHER`)}
																</div>
															</RadioElement>
														</Col>
													</Row>
												</Radio>
											</Col>
										</Row>
									)}
									{formik.values.accountType === Role.Student && (
										<MainStudentRegisterForm
											submitting={this.state.submitting}
											predefinedEmail={
												this.state.registerAsStudent ? this.state.predefinedEmail : null
											}
											recaptchaRef={this.recaptchaRef}
											predefinedDateOfBirth={this.state.predefinedDateOfBirth}
										/>
									)}
									{(formik.values.accountType === Role.Teacher || this.state.parentEmail) && (
										<TeacherRegistrationForm
											predefinedEmail={
												this.state.registerAsTeacher ? this.state.predefinedEmail : null
											}
											submitting={this.state.submitting}
											recaptchaRef={this.recaptchaRef}
											parentEmail={this.state.parentEmail}
										/>
									)}
								</form>
							)}
						</Formik>
					</>
				)}
				{this.state.parentRegistration && (getDomain(window.location.hostname) === "akademi.al" || isReservedDomain(window.location.hostname)) && (
					<ParentRegistration
						recaptchaRef={this.recaptchaRef}
						history={history}
						showRegisterParent={this.showRegisterParent}
						goBack={() => this.setState({ parentRegistration: false })}
					/>
				)}
				<Row justify="center">
					<Col>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._ALREADY_HAVE_ACCOUNT`)}{" "}
							<Link to={`/${SIGN_IN_PATH}`}>{translate(`${TRANSLATION_BASE_PATH}._ENTER_HERE`)}</Link>
						</Text>
					</Col>
				</Row>
			</>
		);
	}

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<>
				{(getDomain(window.location.hostname) === "akademi.al" || isReservedDomain(window.location.hostname)) && <JoinInstagramAlert />}
				{hideCover ?
					<AntLayout>
						<div className="register--full-container">{this.RegisterComponent()}</div>
					</AntLayout> :
					<Layout {...this.props.pageComponents.loginCover?.specs}>{this.RegisterComponent()}</Layout>}
			</>
		);
	}
}

const Register = withTranslation()(RegisterComponent);
export default Register;
