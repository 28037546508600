import { PickerLocale } from "antd/lib/date-picker/generatePicker";

const TRANSLATION_BASE_PATH = "_MAIN._CALENDAR";

export const sqLocale = (translate: (key: string) => string): PickerLocale => {
	return {
		lang: {
			locale: "sq",
			placeholder: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_DATE`),
			rangePlaceholder: [
				translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._START_DATE`),
				translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._END_DATE`)
			],
			today: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._TODAY`),
			now: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._NOW`),
			backToToday: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._BACK_TO_TODAY`),
			ok: "Ok",
			clear: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._CLEAR`),
			month: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._MONTH`),
			year: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._YEAR`),
			timeSelect: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_TIME`),
			dateSelect: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_DATE`),
			monthSelect: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_MONTH`),
			yearSelect: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_YEAR`),
			decadeSelect: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_DECADE`),
			yearFormat: "YYYY",
			dateFormat: "DD.MM.YYYY",
			dayFormat: "D",
			dateTimeFormat: "DD.MM.YYYY HH:mm",
			monthFormat: "MMMM",
			monthBeforeYear: true,
			previousMonth: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._PREV_MONTH`),
			nextMonth: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._NEXT_MONTH`),
			previousYear: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._LAST_YEAR`),
			nextYear: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._NEXT_YEAR`),
			previousDecade: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._LAST_DECADE`),
			nextDecade: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._NEXT_DECADE`),
			previousCentury: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._LAST_CENTURY`),
			nextCentury: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._NEXT_CENTURY`)
		},
		timePickerLocale: {
			placeholder: translate(`${TRANSLATION_BASE_PATH}._ANT_CALENDAR._SELECT_TIME`)
		},
		dateFormat: "DD.MM.YYYY",
		dateTimeFormat: "DD.MM.YYYY HH:mm",
		weekFormat: "YYYY-wo",
		monthFormat: "YYYY-MM"
	};
};
