import { DatePicker, Radio } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_PARENT._COMPONENTS._WEEK_TOTAL_FILTER";

export const WeekTotalFilter = (props: any) => {
	const { t: translate } = useTranslation();
	const [mode, setMode] = useState("total");

	const handleModeChange = (e: any) => {
		const mode = e.target.value;
		setMode(mode);
	};

	const getDatePicker = () => {
		return mode === "week" && <DatePicker picker="week" />;
	};

	const getTotalToggle = () => {
		return (
			<Radio.Group onChange={handleModeChange} value={mode}>
				<Radio.Button value="week">{translate(`${TRANSLATION_BASE_PATH}._WEEK`)}</Radio.Button>
				<Radio.Button value="total">{translate(`${TRANSLATION_BASE_PATH}._TOTAL`)}</Radio.Button>
			</Radio.Group>
		);
	};

	return (
		<></>
		// <Row>
		// 	<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
		// 		<Row justify="end">
		// 			<Space>
		// 				{getDatePicker()}
		// 				{getTotalToggle()}
		// 			</Space>
		// 		</Row>
		// 	</Col>
		// 	<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
		// 		<Row justify="space-between">
		// 			<Col xs="24" sm="12" className="pt-24">
		// 				{getTotalToggle()}
		// 			</Col>
		// 			<Col xs="24" sm="12"  className="pt-24">
		// 				{getDatePicker()}
		// 			</Col>
		// 		</Row>
		// 	</Col>
		// </Row>
	);
};
