import ApiServiceData from "../api.service";
const relativeUrl = "/v1/api/analytics/classes";

export default class AnalyticsClassService extends ApiServiceData {

	public async getAsParentChildVirtualClassData(childId: string): Promise<any> {
		return this.get(relativeUrl + "/asParent/virtual-class-data/" + childId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getClassroomTotalActiveEnrollments(classroomId: string): Promise<any> {
		return this.get(relativeUrl + "/active-enrollments/" + classroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getClassroomLastUpdatedDate(classroomId: string): Promise<any> {
		return this.get(relativeUrl + "/last-updated-date/" + classroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
