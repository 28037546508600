import React, { ReactNode, useState } from "react";
import { Col, Layout, Row } from "antd";
import { useHistory } from "react-router-dom";

import Icon from "common/components/general/Icon";
import { MainMenu } from "./components/MainMenu";
import JoinCreateClassModal from "scenes/main/components/joinCreateClassModal/JoinCreateClassModal";
import OAuth2Service from "services/authentication/oauth2.service";

const { Sider } = Layout;

interface LeftSideBarProps {
	menu: any[];
	addBtnName?: string;
	footer?: ReactNode;
	showAddCourse?: boolean;
	loading?: boolean;
}

export const LeftSideBar = (props: LeftSideBarProps) => {
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const history = useHistory();

	return (
		<Sider
			collapsible
			collapsed={collapsed}
			trigger={null}
			breakpoint="lg"
			onBreakpoint={(broken: boolean) => {
				setCollapsed(broken);
			}}
			className="sider__navigation_menu"
		>
			{props.showAddCourse && <Row className="p-8">
				<Col span="24">
					<Row justify="center">
						<JoinCreateClassModal
							collapsed={collapsed}
							history={history}
							typeOfClassroomToCreate={OAuth2Service.CurrentOrganization?.contentType}
						/>
					</Row>
				</Col>
			</Row>}
			<div className="sider__fold_icon_wrapper">
				<Icon
					type={collapsed ? "ri-menu-unfold-line" : "ri-menu-fold-line"}
					onClick={() => {
						setCollapsed(!collapsed);
					}}
					className="sider__fold_icon"
					fontSize="16"
				/>
			</div>
			<MainMenu menu={props.loading ? [] : props.menu} />
			{props.footer}
		</Sider>
	);
};
