import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import Input from "common/components/dataEntry/formik/FormikInputField";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import { RegisterFooterActions } from "../../components/footerActions/RegisterFooterActions";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

interface StudentRegistrationUnder13Props {
	submitting: boolean;
	predefinedEmail?: string;
	recaptchaRef: any;
}

export const StudentRegistrationUnder13 = (props: StudentRegistrationUnder13Props) => {
	const { isValid, setFieldValue } = useFormikContext<any>();
	const { t: translate } = useTranslation();

	useEffect(() => {
		setFieldValue("email", props.predefinedEmail);
	}, []);

	return (
		<>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<Input
						name="email"
						hint={translate(`${TRANSLATION_BASE_PATH}._PARENT_EMAIL_HINT`)}
						label={translate(`${TRANSLATION_BASE_PATH}._PARENT_EMAIL`)}
						size="large"
						disabled={!!props.predefinedEmail}
					/>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={12} md={8} lg={12} xl={9}>
					<Input name="firstName" label={translate(`${TRANSLATION_BASE_PATH}._FIRST_NAME`)} size="large" />
				</Col>
				<Col xs={24} sm={12} md={8} lg={12} xl={9}>
					<Input name="lastName" label={translate(`${TRANSLATION_BASE_PATH}._LAST_NAME`)} size="large" />
				</Col>
			</Row>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<Input
						name="username"
						hint={translate(`${TRANSLATION_BASE_PATH}._USERNAME_HINT`)}
						label={translate(`${TRANSLATION_BASE_PATH}._CHOOSE_USERNAME`)}
						size="large"
						autoComplete="username"
					/>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<PasswordInput
						name="password"
						label={translate(`${TRANSLATION_BASE_PATH}._CREATE_PASSWORD`)}
						showPopover={true}
						size="large"
						autoComplete="new-password"
					/>
				</Col>
			</Row>
			<RegisterFooterActions
				isFormValid={isValid}
				submitting={props.submitting}
				recaptchaRef={props.recaptchaRef}
			/>
		</>
	);
};
