import { v4 as uuidv4 } from "uuid";

import SubjectDto from "./subject.dto";
import GradeDto from "./grade.dto";

interface GradeSubject {
	Grade: GradeDto;
	Subject: SubjectDto;
	SubjectId: string;
	GradeId: string;
}

export default class CreateClassroomDto {
	readonly id!: string;
	readonly name!: string;
	readonly description?: string;
	readonly GradeSubject?: GradeSubject;
	readonly isAsync!: boolean;
	readonly price?: number;

	constructor(data: { name: string; description: string; isAsync: boolean, [key: string]: any }) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
