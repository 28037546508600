import { Card, Col, Row } from "antd";
import React from "react";
import ChildImage from "assets/images/child-profile-stats.svg";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_STATISTICS._SECTION_TITLE";

export const SectionTitle = (props: any) => {
	const { t: translate } = useTranslation();
	
	const childFullName = (child: any) => {
		return child.firstName + " " + child.lastName;
	};

	return (
		<Card title={null} className="child__statistics__no__bottom__border__radius child__statistics__no__padding">
			<Row>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
					<Row justify="center" className="pt-24">
						<Image src={ChildImage} />
					</Row>
				</Col>
				<Col xs={24} sm={24} md={18} lg={20} xl={20} xxl={20} className="pt-24">
					<Row className="pb-4">
						<Col span="24">
							<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
								{translate(`${TRANSLATION_BASE_PATH}._TITLE`, {childFullName: childFullName(props.child)})}
							</Text>
						</Col>
					</Row>
					<Row> 
						<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}
							</Text>
						</Col>
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="pb-16">
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}
							</Text>
						</Col>
					</Row>
				</Col>
				<Col xs={0} sm={0} md={6} lg={4} xl={4} xxl={4}>
					<Row justify="end">
						<Image src={ChildImage} />
					</Row>
				</Col>
			</Row>
		</Card>
	);
};