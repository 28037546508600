import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Divider } from "antd";

import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import { REGISTER_PATH } from "../register";
import { RegisterEmailVerification } from "common/components/login/registerEmailVerification";
import OAuth2Service from "services/authentication/oauth2.service";
import AccountEmailService from "services/email/account.email.service";
import { SIGN_IN_PATH } from "../signIn";
import { Layout } from "../../components/layout";

export const REGISTER_EMAIL_VERIFICATION_PATH = "register-email-verification";
const TRANSLATION_BASE_PATH = "_LOGIN._EMAIL_VERIFICATION";

class EmailVerificationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submitting: false,
			status: "Sent"
		};
		this.Component = this.Component.bind(this);
	}

	recaptchaRef: any = React.createRef();

	resendVerificationEmail = async () => {
		const {
			history: { location }
		} = this.props;
		this.setState({
			submitting: true
		});

		await OAuth2Service.loginClient().catch(() => {
			return this.setState({submitting: false, status: "Resent"});
		});

		const recaptchaValue = await this.recaptchaRef.current.executeAsync();

		await new AccountEmailService()
			.resentVerificationEmail(location.state.userId, recaptchaValue)
			.then(() => {
				return this.recaptchaRef.current.reset();
			})
			.catch(() => {
				return this.recaptchaRef.current.reset();
			}).finally(() => {
				this.setState({
					submitting: false,
					status: "Resent"
				});
			});
	};

	Component = () => {
		const {
			t: translate,
			history: { location }
		} = this.props;
		return (
			<Row>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
					<RegisterEmailVerification
						email={location.state.email}
						submitting={this.state.submitting}
						onResendEmail={this.resendVerificationEmail}
						status={this.state.status}
						recaptchaRef={this.recaptchaRef}
					/>

					<Row justify="center" className="pt-40">
						<Col>
							<Text fontSize="14" lineHeight="22">
								<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>
									{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_REGISTER`)}
								</Link>
								<Divider orientation="center" type="vertical" />
								<Link to={`/${SIGN_IN_PATH}`}>
									{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_SIGNIN`)}
								</Link>
							</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<>
				{hideCover ? this.Component() : <Layout {...this.props.pageComponents.loginCover?.specs}>{this.Component()}</Layout>}
			</>
		);
	}
}

const EmailVerification = withTranslation()(EmailVerificationComponent);
export default EmailVerification;
