import React from "react";
import { useTranslation } from "react-i18next";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Button from "common/components/general/Button";
import { useHistory } from "react-router-dom";
import { FORGET_PASSWORD_PATH } from "../../forgetPassword";
import {SIGN_IN_PATH} from "scenes/login/scenes/signIn/index";

const TRANSLATION_BASE_PATH = "_LOGIN._CHANGE_PASSWORD";

export const LinkIsNotValid = () => {
	const { t: translate } = useTranslation();
	const history = useHistory();

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Icon type="ri-error-warning-line" className="email__verification__icon" />
					</Col>
				</Row>
				<Row className="pt-32">
					<Col span={24}>
						<Text fontSize="30" lineHeight="38" wheight="semibold">
							{translate(`${TRANSLATION_BASE_PATH}._CODE_EXPIRED`)}
						</Text>
					</Col>
				</Row>
				<Row className="pt-24">
					<Col span={24}>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._CODE_EXPIRED_DESCRIPTION`)}
						</Text>
					</Col>
				</Row>
				<Row justify="center" className="pt-24">
					<Col span={24}>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							className="full_width_button"
							onClick={() => {
								history.push(`/${SIGN_IN_PATH}/${FORGET_PASSWORD_PATH}`);
							}}
						>
							{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_FORGET_PASSWORD`)}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
