import { Col, message, Radio, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import BucketS3Service from "services/s3/s3.service";
import S3ActionType from "services/domain/administration/S3Action";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { saveToDisk } from "../../../../../courseBuilder/utils/FileUtils";
import { showError } from "common/utils/Notification";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfRenderProps {
	filePath: string;
	fileName: string;
}

const TRANSLATION_BASE_PATH = "_SUBJECTS._MATERIAL_ITEMS._LESSON_MATERIALS._PDF_RENDER";

enum PdfViewerType {
	ReactPdf,
	Iframe
}

export const PdfRender = (props: PdfRenderProps) => {
	const { t: translate } = useTranslation();
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState(true);
	const [url, setUrl] = useState("");
	const handle = useFullScreenHandle();
	const [typeOfPdfViewer, setTypeOfPdfViewer] = useState<PdfViewerType>(PdfViewerType.ReactPdf);

	function getUrl(filePath: string) {
		setLoading(true);
		return new BucketS3Service()
			.getS3UrlForAction(filePath, S3ActionType.DOWNLOAD)
			.then((downloadUrl: string) => setUrl(downloadUrl))
			.catch((error: any) => {
				message.error(error);
			})
			.finally(() => setLoading(false));
	}

	useEffect(() => {
		getUrl(props.filePath);
	}, []);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	return (
		<Skeleton loading={loading} active>
			<Row className="mb-16">
				<Col span={24}>
					{typeOfPdfViewer === PdfViewerType.ReactPdf && (
						<FullScreen handle={handle}>
							<div id="PdfRender__Container">
								<Document
									file={{ url: url }}
									onLoadSuccess={onDocumentLoadSuccess}
									onLoadError={() => showError(translate(`${TRANSLATION_BASE_PATH}._ERROR_MESSAGE`))}
									loading={<Skeleton loading={true} active />}
									className="PdfRender__PDFDocument"
									error={""}
								>
									{numPages && (
										<>
											<Row justify="center" className="background-color-gray-10">
												{!handle.active ? (
													<Page className="PdfRender__PDFPage" pageNumber={pageNumber} />
												) : (
													<Page
														className="PdfRender__PDFPage"
														pageNumber={pageNumber}
														height={window.innerHeight}
													/>
												)}
											</Row>
											<Row className="PdfRender__controls background-color-gray-10">
												<Col span="16" offset="4">
													<Row justify="center" align="middle">
														<Space>
															<Icon
																fontSize="20"
																type="ri-rewind-fill"
																className="color-gray-2 cursor_pointer"
																onClick={() =>
																	setPageNumber(
																		numPages && pageNumber - 1 > 0
																			? pageNumber - 1
																			: pageNumber
																	)
																}
																tooltip={translate(
																	`${TRANSLATION_BASE_PATH}._TOOLTIP._PREV_PAGE`
																)}
															/>
															<Text
																fontSize="14"
																lineHeight="20"
																className="color-gray-2"
															>
																{pageNumber}/{numPages}
															</Text>
															<Icon
																fontSize="20"
																type="ri-speed-fill"
																className="color-gray-2 cursor_pointer"
																onClick={() =>
																	setPageNumber(
																		pageNumber + 1 <= (numPages ?? 1)
																			? pageNumber + 1
																			: pageNumber
																	)
																}
																tooltip={translate(
																	`${TRANSLATION_BASE_PATH}._TOOLTIP._NEXT_PAGE`
																)}
															/>
														</Space>
													</Row>
												</Col>
												<Col span="4">
													<Row justify="end" className="pr-8">
														<Space>
															<Icon
																onClick={() =>
																	saveToDisk(props.filePath, props.fileName)
																}
																className="color-gray-2 cursor_pointer"
																fontSize="20"
																type="ri-download-2-line"
																tooltip={translate(
																	`${TRANSLATION_BASE_PATH}._TOOLTIP._DOWNLOAD`
																)}
															/>
															<Icon
																onClick={handle.active ? handle.exit : handle.enter}
																className="color-gray-2 cursor_pointer"
																fontSize="20"
																type="ri-fullscreen-fill"
																tooltip={translate(
																	`${TRANSLATION_BASE_PATH}._TOOLTIP._FULLSCREEN`
																)}
															/>
														</Space>
													</Row>
												</Col>
											</Row>
										</>
									)}
								</Document>
							</div>
						</FullScreen>
					)}
					{typeOfPdfViewer === PdfViewerType.Iframe && (
						<Row>
							<Col span={24}>
								<iframe
									src={url}
									frameBorder={0}
									width="100%"
									scrolling="no"
									allowFullScreen={true}
									title={props.fileName}
									className="PdfRender__iframe"
								></iframe>
							</Col>
						</Row>
					)}
					<Radio.Group
						options={[
							{ label: "Shiko si prezantim", value: PdfViewerType.ReactPdf },
							{ label: "Shiko si pdf", value: PdfViewerType.Iframe }
						]}
						onChange={e => {
							setTypeOfPdfViewer(e.target.value);
						}}
						value={typeOfPdfViewer}
						optionType="button"
						buttonStyle="solid"
					/>
				</Col>
			</Row>
		</Skeleton>
	);
};
