import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Dropdown, Menu, Row, Col, Space } from "antd";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Material from "dtos/course-builder/material.dto";
import MaterialModal from "../MaterialsModal";
import { FileCardComponent } from "../fileCard/FileCardComponent";
import { previewFile } from "../../../../utils/FileUtils";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._MATERIALS";

interface MaterialProps extends WithTranslation {
	materials?: Material[];
	saveHandler: (material: Material) => void;
	deleteHandler: (material: Material) => void;
	openModal: (opendModal: boolean) => void;
	lessonId: string;
	onSoftDeleteFile?: (file: any) => void;
}

class MaterialsComponent extends Component<MaterialProps, any> {
	constructor(props: MaterialProps) {
		super(props);
		this.state = {
			materialModalProps: {
				modalVisibility: false
			},
			filesToDelete: []
		};
	}

	addMaterial = (type: any) => {
		this.props.openModal(true);
		this.setState({
			materialModalProps: {
				material: new Material(),
				modality: type,
				closeModal: () => {
					this.props.openModal(false);
					this.setState({
						materialModalProps: { modalVisibility: false }
					});
				},
				saveMaterial: this.saveMaterial,
				modalVisibility: true,
				lessonId: this.props.lessonId
			}
		});
	};

	editMaterial = async (material: Material) => {
		this.props.openModal(true);

		if (material?.File) {
			material.fileList = [];
			await previewFile(material.File)
				.then(file => material.fileList.push(file))
				.catch(err => {});
		}

		this.setState({
			materialModalProps: {
				material: material,
				modality: material.type,
				closeModal: () => {
					this.props.openModal(false);
					this.setState({
						materialModalProps: { modalVisibility: false }
					});
				},
				saveMaterial: this.saveMaterial,
				modalVisibility: true,
				lessonId: this.props.lessonId
			}
		});
	};

	deleteMaterial = (material: Material) => {
		this.props.openModal(true);
		this.props.deleteHandler(material);
	};

	saveMaterial = (material: Material) => {
		this.props.openModal(false);

		material.type = this.state.materialModalProps.modality;
		this.props.saveHandler(material);
		this.setState({ materialModalProps: { modalVisibility: false } });
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file]
		});
	};

	render() {
		const { t: translate } = this.props;

		const AddMaterialsBtn = (
			<Menu>
				<Menu.Item onClick={() => this.addMaterial("file")}>
					<Space direction="horizontal">
						<Icon type="ri-attachment-2" />
						{translate(`${TRANSLATION_BASE_PATH}._DOCUMENT`)}
					</Space>
				</Menu.Item>
				<Menu.Item onClick={() => this.addMaterial("url")}>
					<Space direction="horizontal">
						<Icon type="ri-link-m" />
						{translate(`${TRANSLATION_BASE_PATH}._LINK`)}
					</Space>
				</Menu.Item>
			</Menu>
		);

		return (
			<Row>
				<Col span={24}>
					<Space direction="vertical" className="full__width" size={8}>
						<Row>
							<Text fontSize="14" lineHeight="22">
								{translate(`${TRANSLATION_BASE_PATH}._MATERIALS`)}
							</Text>
						</Row>
						{this.props.materials?.map((material: Material, index: number) => (
							<Row key={index}>
								<Col span={24}>
									<FileCardComponent
										material={material}
										editMaterial={this.editMaterial}
										deleteMaterial={this.deleteMaterial}
									/>
								</Col>
							</Row>
						))}
						<Dropdown overlay={AddMaterialsBtn} placement="bottomLeft">
							<Button>+ {translate(`${TRANSLATION_BASE_PATH}._ADD`)}</Button>
						</Dropdown>
					</Space>
					{this.state.materialModalProps.modalVisibility ? (
						<MaterialModal
							onSoftDeleteFile={this.props.onSoftDeleteFile}
							{...this.state.materialModalProps}
						/>
					) : (
						<></>
					)}
				</Col>
			</Row>
		);
	}
}
const AddMaterials = withTranslation()(MaterialsComponent);
export default AddMaterials;
