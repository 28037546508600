/* eslint-disable array-bracket-newline */
import { OnboardingStep, StepStatus } from "./OnboardingStepsMapping";

export const defaultStudentWizardSteps = [
	{
		id: OnboardingStep.Welcome,
		priority: 0,
		status: StepStatus.Active
	},
	{
		id: OnboardingStep.ChooseNationality,
		priority: 1,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.ProfilePicture,
		priority: 2,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.LevelSelection,
		priority: 3,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.GradeSelection,
		priority: 4,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.ChooseSubjects,
		priority: 5,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.VirtualClassroom,
		priority: 6,
		status: StepStatus.Disabled
	}
];

export const defaultTeacherWizardSteps = [
	{
		id: OnboardingStep.Welcome,
		priority: 0,
		status: StepStatus.Active
	},
	{
		id: OnboardingStep.ChooseNationality,
		priority: 1,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.ProfilePicture,
		priority: 2,
		status: StepStatus.Disabled
	},
	{
		id: OnboardingStep.OrganisationCode,
		priority: 3,
		status: StepStatus.Disabled
	}
];
