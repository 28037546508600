import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import { BaseComponentProps } from "../OnboardingStepsMapping";
import WelcomeStudent from "assets/images/icons/WelcomeStudent.svg";
import WelcomeTeacher from "assets/images/icons/WelcomeTeacher.svg";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SIGN_IN_PATH } from "../../../../login/scenes/signIn";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_ONBOARDING._WELCOME_STEP";

export const StudentWelcomeScene: React.FC<BaseComponentProps> = (props: BaseComponentProps) => {
	const [user, setUser] = useState<any>();
	const { t: translate } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		setUser(currentUserInfo);
		props.setIsFormValid(true);
		props.setTitle(translate(`${TRANSLATION_BASE_PATH}._WELCOME`) + currentUserInfo.User.firstName);
	}, []);

	props.bindSubmitFormHandler(async () => {
		const newState = { ...props.state, role: user.Roles[0]?.code };
		props.updateState(newState);

		await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				props.setIsSubmiting(false);
				props.goNext();
				return;
			})
			.catch(() => {
				props.setIsSubmiting(false);
			});
	});

	const getWelcomeLogoBasedOnRole = () => {
		if (!user) return "";
		return user.Roles.some((role: any) => role.code === Role.Teacher) ? WelcomeTeacher : WelcomeStudent;
	};

	const getWelcomeDescriptionBasedOnRole = () => {
		if (!user) return "";
		return user.Roles.some((role: any) => role.code === Role.Teacher)
			? translate(`${TRANSLATION_BASE_PATH}._WELCOME_TEACHER_DESCRIPTION`)
			: translate(`${TRANSLATION_BASE_PATH}._WELCOME_STUDENT_DESCRIPTION`);
	};

	return (
		<>
			<Row>
				<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16} className="welcome__scene_logo">
					<Image src={getWelcomeLogoBasedOnRole()} />
				</Col>
			</Row>
			<Row>
				<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16} className="mt-24">
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{getWelcomeDescriptionBasedOnRole()}
					</Text>
				</Col>
			</Row>
		</>
	);
};
