import React from "react";
import { Table } from "antd";

interface AdminTableProps {
	dataSource: any;
	columns: any;
	locale?: any;
	defaultPageSize?: any;
	scroll?: any;
	expandable?: any;
}

export const AdminTable = (props: AdminTableProps) => {
	return (
		<Table
			className="admin__table mb-24"
			dataSource={props.dataSource}
			columns={props.columns}
			locale={props.locale}
			pagination={{ defaultPageSize: props.defaultPageSize ?? 10 }}
			scroll={props.scroll ? props.scroll : {}}
			expandable={props.expandable ?? null}
		/>
	);
};
