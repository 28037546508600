import React, { Component, ReactNode } from "react";

import ChooseAccount from "./chooseAccount/index";
import ResetPassword from "./resetPassword/index";
import Skeleton from "common/components/feedback/Skeleton";
import OAuth2Service from "services/authentication/oauth2.service";
import { PASSWORD_CHANGED_SUCCESSFULLY_PATH } from "../passwordChangedSuccessfully/index";
import { SIGN_IN_PATH } from "../signIn";
import { REDIRECT_URI } from "common/utils/Constants";
import AccountEmailService from "services/email/account.email.service";
import { LinkIsNotValid } from "./linkIsNotValid/index";
import { Layout } from "../../components/layout";

export const RESET_PASSWORD_PATH = "reset-password/:code";

class ChangePasswordComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: "",
			accounts: [],
			signedAuthorizationCode: null
		};

		this.Component = this.Component.bind(this);
	}

	getFieldsFromParams = (): { authorizationCode: string | undefined; tempCode: string; email: string } => {
		const {
			history,
			match: { params }
		} = this.props;
		if (params.code === "" || params.code === null) {
			history.push(`/${SIGN_IN_PATH}`);
		} else {
			const decoded = new Buffer(params.code ?? "", "base64").toString("ascii");
			const fields = decoded.split("&");

			if (fields === null || fields.length < 3) {
				history.push(`/${SIGN_IN_PATH}`);
			} else {
				const authorizationCode = fields[0].split("=")[1];
				const tempCode = fields[1].split("=")[1];
				const email = fields[2].split("=")[1];

				return {
					authorizationCode,
					tempCode,
					email
				};
			}
		}
		return {
			authorizationCode: undefined,
			tempCode: "",
			email: ""
		};
	};

	componentDidMount() {
		const { authorizationCode, tempCode, email } = this.getFieldsFromParams();
		this.setState({
			tempCode: tempCode
		});

		if (authorizationCode !== undefined) {
			OAuth2Service.loginAuthorizationCode(authorizationCode, REDIRECT_URI)
				.then((signedToken: string) => {
					this.getListOfAccounts(email ?? "", signedToken);
					return this.setState({ signedAuthorizationCode: signedToken });
				})
				.catch(() => {
					this.setState({
						submitting: false,
						loading: false,
						hasError: true,
						errorMessage: "Ky email nuk eshte me i vlefshem"
					});
				});
		}
	}

	getListOfAccounts = async (email: string, signedToken: string) => {
		await new AccountEmailService()
			.getListOfAccounts(email, signedToken)
			.then((result: any) => {
				this.setState({
					submitting: false,
					loading: false,
					accounts: [...result],
					selectedAccount: undefined
				});
				return;
			})
			.catch(() => {
				this.setState({
					submitting: false,
					loading: false
				});
			});
	};

	onAccountSelectHandler = (account: any) => {
		this.setState({
			selectedAccount: account
		});
	};

	onGoBackHandler = () => {
		this.setState({
			selectedAccount: undefined
		});
	};

	onChangedPasswordHandler = () => {
		const { history } = this.props;
		history.push(`/${SIGN_IN_PATH}/${PASSWORD_CHANGED_SUCCESSFULLY_PATH}`);
	};

	getChangePasswordContent = (): ReactNode => {
		if (this.state.hasError) {
			return <LinkIsNotValid />;
		} else if (this.state.accounts.length > 1 && this.state.selectedAccount === undefined) {
			return <ChooseAccount accounts={this.state.accounts} onAccountSelect={this.onAccountSelectHandler} />;
		}
		return (
			<ResetPassword
				account={this.state.accounts.length > 1 ? this.state.selectedAccount : this.state.accounts[0]}
				choosedFromMultiple={this.state.accounts.length > 1}
				tempCode={this.state.tempCode}
				onGoBack={this.onGoBackHandler}
				onChangedPassword={this.onChangedPasswordHandler}
				signedAuthorizationCode={this.state.signedAuthorizationCode}
			/>
		);
	};

	Component = () => {
		return (
			<Skeleton active loading={this.state.accounts.length === 0 && !this.state.hasError}>
				{this.getChangePasswordContent()}
			</Skeleton>
		);
	};

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<>
				{hideCover ? (
					this.Component()
				) : (
					<Layout {...this.props.pageComponents.loginCover?.specs}>{this.Component()}</Layout>
				)}
			</>
		);
	}
}

export default ChangePasswordComponent;
