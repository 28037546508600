import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Row } from "antd";
import { Welcome } from "./components/welcome";
import { DataCollector } from "./components/dataCollector";
import { Success } from "./components/success";
import {DigitalizeStudentDto} from "dtos/administration/digitalizeStudent/digitalizeStudent.dto";

type State = {
	step: number,
	data: DigitalizeStudentDto,
}

class ModalControllerComponent extends Component<any, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			step: 0,
			data: {
				reason: "",
				personal: {
					city: "",
					address: "",
					device: "",
					school: "",
					age: "",
					phoneNumber: ""
				},
			}
		};
	}

	handleClose = () => {
		const { toggleDigitalizeModal } = this.props;
		toggleDigitalizeModal();
	}

	nextStep = () => {
		this.setState(prevState => ({ step: prevState.step + 1 }));
	}

	onUpdateData = (newData: object) => {
		this.setState(prevState => ({ ...prevState, data: {...prevState.data, ...newData} }));
	}

	render() {
		const { step, data } = this.state;
		return (
			<Row className="tutoring">
				{ step === 0 && (
					<Welcome next={this.nextStep} onCancel={this.handleClose}/>
				)}
				{ step === 1 && (
					<DataCollector
						onCancel={this.handleClose}
						collectedData={data}
						updateData={this.onUpdateData}
						next={this.nextStep}
					/>
				)}
				{ step === 2 && (
					<Success onCancel={this.handleClose}/>
				)}
			</Row>
		);
	}
}

export const ModalController = withTranslation()(ModalControllerComponent);