import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Skeleton from "common/components/feedback/Skeleton";
import { MainLayout } from "./components/layout/Layout";
import Dashboard, { DASHBOARD_PATH } from "./scenes/dashboard/index";
import Subjects, { SUBJECTS_PATH } from "./scenes/subjects/index";
import Levels, { LEVELS_PATH } from "./scenes/levels/index";
import AsynchClasses, { ASYNCHCLASSES_PATH } from "./scenes/asynch-classes/index";
import LiveClasses, { LIVECLASSES_PATH } from "./scenes/live-classes";
import {ORGANIZATIONS_PATH} from "./scenes/organizations/scenes/list";
import OrganizationIndex from "./scenes/organizations/index";
import Users, { USERS_PATH } from "./scenes/users";
import TagManager, { TAG_MANAGER_PATH } from "./scenes/tag-manager";

export const ADMIN_PATH = "admin";

class Admin extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		this.setState({
			loading: false
		});
	}

	render() {
		return (
			<Skeleton active loading={this.state.loading}>
				{!this.state.loading ? (
					<MainLayout>
						<Switch>
							<Route path={`/${ADMIN_PATH}/${DASHBOARD_PATH}`} exact component={Dashboard} />
							<Route path={`/${ADMIN_PATH}/${SUBJECTS_PATH}`} exact component={Subjects} />
							<Route path={`/${ADMIN_PATH}/${LEVELS_PATH}`} exact component={Levels} />
							<Route path={`/${ADMIN_PATH}/${ASYNCHCLASSES_PATH}`} exact component={AsynchClasses} />
							<Route path={`/${ADMIN_PATH}/${LIVECLASSES_PATH}`} exact component={LiveClasses} />
							<Route path={`/${ADMIN_PATH}/${ORGANIZATIONS_PATH}`} component={OrganizationIndex} />
							<Route path={`/${ADMIN_PATH}/${USERS_PATH}`} exact component={Users} />
							<Route path={`/${ADMIN_PATH}/${TAG_MANAGER_PATH}`} exact component={TagManager} />
							<Route path={`/${ADMIN_PATH}`} exact component={() => <Redirect to={`/${ADMIN_PATH}/${DASHBOARD_PATH}`} />}/>
							<Route path={`/${ADMIN_PATH}/*`} component={() => <Redirect to={`/${ADMIN_PATH}/${DASHBOARD_PATH}`} />} />
						</Switch>
					</MainLayout>
				) : null}
			</Skeleton>
		);
	}
}

export default Admin;
