import { Affix, Card, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useStoreState } from "easy-peasy";
import { OldSearchBar } from "./OldSearchBar";

interface SectionSearchProps {
	loading: boolean;
	upperSpace?: boolean;
	search: (searchValue: string) => void;
	toggleFilterBar: (toggle: boolean) => void;
}

export const OldSectionSearch = (props: SectionSearchProps) => {
	const [stickyBar, setStickyBar] = useState(false);
	const filters = useStoreState(state => state.subjects.activeFilters);
	const offsetTop = useRef(window.innerWidth < 577 ? 55 : 67);

	useEffect(() => {

		window.addEventListener("scroll", function () {
			if (window.innerWidth > 991) {
				if (stickyBar) {
					setStickyBar(false);
				}
				return;
			}
			offsetTop.current = window.innerWidth < 577 ? 55 : 67;

			const threshold = document.querySelector(".section-header")?.scrollHeight ?? 200;
			const scroll = window.scrollY;

			const reachedTheTop = scroll > (threshold + 85);
			setStickyBar(reachedTheTop);
		});
	}, []);

	return (
		<Row>
			<Col xs={24}>
				{!stickyBar && <OldSearchBar
					filters={filters}
					toggleFilterBar={props.toggleFilterBar}
					search={props.search}
					upperSpace={props.upperSpace}
					loading={props.loading}
				/>}
				{stickyBar && <Affix offsetTop={offsetTop.current} >
					<Card className={"section-search__card"}>
						<OldSearchBar
							filters={filters}
							toggleFilterBar={props.toggleFilterBar}
							search={props.search}
							upperSpace={props.upperSpace}
							loading={props.loading}
						/>
					</Card>
				</Affix>}
			</Col>
		</Row>
	);
};
