import React, { useState } from "react";
import { Col, Modal, Row, Space, Tooltip } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Button from "common/components/general/Button";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { showError } from "common/utils/Notification";
import { formValidator } from "./JoinByOrganizationCodeModalValidationSchema";
import OrganizationService from "services/administration/organization.service";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_SELECT_ORGANIZATION._CHOOSE_AS_TEACHER._JOIN_ORGANIZATION";

interface JoinByOrganizationCodeModalProps {
	visible: boolean;
	onCancel: () => void;
	onSuccessfulJoin: () => void;
	joinedOrganizations: any[];
	onWaitingApproval: () => void;
}

export const JoinByOrganizationCodeModal = (props: JoinByOrganizationCodeModalProps) => {
	const { t: translate } = useTranslation();
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [organizationDetailsModalVisible, setOrganizationDetailsModalVisible] = useState<boolean>(false);
	const [organization, setOrganization] = useState<any>();
	let bindSubmitFormHandler: any;

	const getModalFooter = () => {
		return (
			<div className="full__width flex__row_center_reverse">
				<Space direction="horizontal" size={8}>
					<Button type="default" onClick={props.onCancel}>
						{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
					</Button>
					<Button
						type="primary"
						onClick={() => bindSubmitFormHandler()}
						disabled={!isFormValid}
						loading={submitting}
					>
						{translate(`${TRANSLATION_BASE_PATH}._CONTINUE`)}
					</Button>
				</Space>
			</div>
		);
	};

	const onSubmit = (values: any) => {
		const organizationsAsTeacher = props.joinedOrganizations.filter(org =>
			org.UserRoles?.some(
				(userRole: any) => userRole.Role.code === Role.Teacher || userRole.Role.code === Role.Owner
			)
		);

		if (organization && organization.code === values.organizationCode) {
			return setOrganizationDetailsModalVisible(true);
		}

		setSubmitting(true);
		return new OrganizationService()
			.findOrganizationByCode(values.organizationCode)
			.then(result => {
				if (organizationsAsTeacher.some(org => org.id === result.id)) {
					return showError(translate(`${TRANSLATION_BASE_PATH}._ALREADY_JOINED`));
				}
				setOrganization(result);
				return setOrganizationDetailsModalVisible(true);
			})
			.catch((error: any) => {
				if (error?.status === 409) {
					return showError(translate(`${TRANSLATION_BASE_PATH}._ALREADY_JOINED`));
				}
				return showError(translate(`${TRANSLATION_BASE_PATH}._INVALID_CODE`));
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	const joinOrganization = () => {
		setSubmitting(true);
		return new OrganizationService()
			.joinOrganizationByRole(organization?.code, Role.Teacher)
			.then((organization: any) => {
				setOrganizationDetailsModalVisible(false);
				if (!organization.requireApproval) return props.onSuccessfulJoin();
				return props.onWaitingApproval();
			})
			.catch(() => {
				return showError(translate(`${TRANSLATION_BASE_PATH}._INVALID_CODE`));
			})
			.finally(() => setSubmitting(false));
	};

	const getOrganizationDetailsModalFooter = () => {
		return (
			<div className="full__width flex__row_center_reverse">
				<Button type="primary" onClick={() => joinOrganization()} loading={submitting}>
					Vazhdo regjistrimin si mësues
				</Button>
			</div>
		);
	};

	return (
		<>
			<Modal
				title={translate(`${TRANSLATION_BASE_PATH}._REGISTER_AS_TEACHER`)}
				closable
				visible={props.visible}
				onCancel={props.onCancel}
				footer={getModalFooter()}
				destroyOnClose
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={{
						organizationCode: ""
					}}
					onSubmit={onSubmit}
					validate={values => {
						setIsFormValid(formValidator(translate).isValidSync(values));
						return {};
					}}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						bindSubmitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row>
									<Col span={24}>
										<Text fontSize="14" lineHeight="22" className="color-gray-9">
											{translate(`${TRANSLATION_BASE_PATH}._REGISTER_AS_TEACHER_DESCRIPTION`)}
										</Text>
									</Col>
								</Row>
								<Row className="mt-16">
									<Col span={24}>
										<Input
											name="organizationCode"
											size="middle"
											placeholder="psh 12345678"
											label={translate(`${TRANSLATION_BASE_PATH}._ORGANIZATION_CODE`)}
											labelInfo={
												<Tooltip
													title={translate(
														`${TRANSLATION_BASE_PATH}._ORGANIZATION_CODE_TOOLTIP`
													)}
													placement="topLeft"
												>
													<span>
														<Icon
															type="ri-information-line"
															fontSize="20"
															className="color-blue-6"
														/>
													</span>
												</Tooltip>
											}
										/>
									</Col>
								</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
			<Modal
				visible={organizationDetailsModalVisible}
				destroyOnClose
				title="Institucioni arsimor"
				closable
				onCancel={() => {
					setOrganizationDetailsModalVisible(false);
				}}
				footer={getOrganizationDetailsModalFooter()}
			>
				<Row className="mb-24">
					<Col span={24}>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							Ju po bëheni pjesë e institucionit arsimor {organization?.name}.
						</Text>
					</Col>
				</Row>
				<Row className="mb-16">
					<Col span={24}>
						<Space size={12}>
							<Icon type="ri-building-line" fontSize="56" className="color-blue-6" />
							<Space direction="vertical" size={0}>
								<Text fontSize="24" lineHeight="32" className="color-gray-8">
									{organization?.name}
								</Text>
								<Text fontSize="14" lineHeight="22" className="color-gray-7">
									Institucioni arsimor
								</Text>
							</Space>
						</Space>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Button
							type="link"
							icon="ri-edit-line"
							onClick={() => {
								setOrganizationDetailsModalVisible(false);
							}}
						>
							Ndrysho
						</Button>
					</Col>
				</Row>
			</Modal>
		</>
	);
};
