import { Button, Modal, Row } from "antd";
import React from "react";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";
import invitationsSentImage from "assets/images/invitationsSentPopup.svg";
import Image from "common/components/general/Image";
import Icon from "../../../../../../../../../common/components/general/Icon";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._INVITATION_SENT_POPUP";

export const InvitesSentPopup = (props: any) => {
	const { t: translate } = useTranslation();

	return (
		<>
			<Modal bodyStyle={{ padding: "0px" }} footer={null} visible={props.visible} onCancel={props.onCancel}>
				<Row justify="center" className="background-color-blue-1 pt-24 pb-24" style={{ borderRadius: "10px" }}>
					<Image src={invitationsSentImage} />
				</Row>
				<Row justify="center" className="pt-24">
					<Icon className="color-blue-6" fontSize="40" type="ri-mail-send-line" />
				</Row>
				<Row justify="center" className="pt-4">
					<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`, { countInvites: props.countInvites })}
					</Text>
				</Row>
				<Row justify="center" className="p-24">
					<Text fontSize="14" lineHeight="22" className="color-gray-8 text__align_center">
						{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}
					</Text>
				</Row>
				<Row justify="center" className="pb-24">
					<Button type="primary" className="pr-48 pl-48" onClick={() => {
						props.onCancel();
					}}>
						{translate(`${TRANSLATION_BASE_PATH}._OK`)}
					</Button>
				</Row>
			</Modal>
		</>
	);
};
