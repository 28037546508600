import {v4 as uuidv4} from "uuid";

export default class ProgressLessonEnrollmentDto {
	readonly id!: string;
	readonly EnrollmentId!: string;
	readonly LessonId!: string;
	readonly createdBy!: string;
	readonly updatedBy!: string;

	constructor(data: {EnrollmentId: string; LessonId: string; createdBy: string; updatedBy: string;[ key: string ]: any}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
