import React, { Component } from "react";
import { Button, Col, Form, Row } from "antd";
import { Formik } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";

import Modal from "common/components/feedback/Modal";
import { formValidator } from "./ValidationSchema";
import Material from "dtos/course-builder/material.dto";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import { createFile } from "../../../../utils/FileUtils";
import { Uploader } from "common/components/uploader/Uploader";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._MATERIALS";

export interface MaterialModalProps extends WithTranslation {
	material: Material;
	modalVisibility: boolean;
	saveMaterial: (values: any) => void;
	closeModal: () => void;
	modality: "url" | "file";
	lessonId: string;
	onSoftDeleteFile?: (file: any) => void;
}

class MaterialModalComponent extends Component<MaterialModalProps, any> {
	constructor(props: MaterialModalProps) {
		super(props);
		this.state = {
			s3SignedUrl: this.props.material?.File?.filePath ?? "",
			fileUploaded: this.props.material?.File ?? null,
			fileList: [],
			filesToDelete: [],
			loading: false
		};
		this.onUploadingDone.bind(this);
	}

	saveMaterial = (material: Material) => {
		const file = createFile(this);
		material.File = file;
		material.FileId = file !== null ? file.id : "";
		material.fileList = this.state.fileList;
		this.props.saveMaterial(material);
	};

	onUploadingDone = (formik: any) => {
		return (fileUploaded: any) => {
			formik.setFieldValue("path", fileUploaded?.name);
			this.setState({
				fileUploaded: fileUploaded,
				loading: false
			});
		};
	};

	addFileToFileList = (fileList: any[]) => {
		this.setState({
			fileList: fileList
		});
	};

	onUploadingStarted = (flag: boolean) => {
		this.setState({
			loading: flag
		});
	};

	render() {
		const { t: translate } = this.props;
		const title = this.props.modality === "url" ? "._ADD_LINK" : "._ADD_DOCUMENT";
		const materialTitle = this.props.modality === "url" ? "._LINK_TITLE" : "._DOCUMENT_TITLE";
		const pathTitle = this.props.modality === "url" ? "._URL" : "._LINK";
		const uploadProps = {
			defaultFileList: this.props.material?.fileList
		};
		return (
			<Modal
				visible={this.props.modalVisibility}
				closable={true}
				footer={null}
				title={translate(TRANSLATION_BASE_PATH + title)}
				onCancel={this.props.closeModal}
			>
				<Formik
					enableReinitialize={true}
					initialValues={this.props.material}
					validationSchema={formValidator(translate, this.props.modality, this.state.fileUploaded)}
					onSubmit={(values: any) => {
						this.saveMaterial(values);
					}}
				>
					{formik => (
						<form onSubmit={formik.handleSubmit} autoComplete="off">
							<Form.Item>
								<FormikDefaultInputField
									name="title"
									label={translate(TRANSLATION_BASE_PATH + materialTitle)}
									placeholder={translate(TRANSLATION_BASE_PATH + materialTitle)}
								/>
							</Form.Item>
							{this.props.modality === "url" ? (
								<Form.Item>
									<FormikDefaultInputField
										name="path"
										label={translate(TRANSLATION_BASE_PATH + pathTitle)}
										placeholder={translate(TRANSLATION_BASE_PATH + pathTitle)}
									/>
								</Form.Item>
							) : (
								<>
									<Uploader
										// key={new Date().toISOString()}
										fileType={"OTHER"}
										filePath={`lesson/${this.props.lessonId}`}
										fileWithUniqueId={false}
										onUploadingDone={this.onUploadingDone(formik)}
										defaultFileList={uploadProps.defaultFileList}
										addFileToFileList={this.addFileToFileList}
										onSoftDeleteFile={this.props.onSoftDeleteFile}
										onUploadingStarted={this.onUploadingStarted}
									/>
									<FormikDefaultInputField name={"path"} type={"hidden"} />
								</>
							)}

							<Row justify="end">
								<Col>
									<Button onClick={this.props.closeModal} style={{ marginRight: 8 }}>
										{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
									</Button>
								</Col>
								<Col>
									<Button htmlType={"submit"} type="primary" disabled={this.state.loading}>
										{translate(`${TRANSLATION_BASE_PATH}._ADD`)}
									</Button>
								</Col>
							</Row>
						</form>
					)}
				</Formik>
			</Modal>
		);
	}
}
const MaterialModal = withTranslation()(MaterialModalComponent);
export default MaterialModal;
