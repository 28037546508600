import {message} from "antd";
import axios from "axios";
import FileDto from "dtos/administration/file.dto";
import BucketS3Service from "services/s3/s3.service";
import S3ActionType from "services/domain/administration/S3Action";
import Jimp from "jimp";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { saveAs } from "file-saver";
import sanitize from "sanitize-filename";
import { API_BASE_URL } from "../../../services/api.service";

export interface PreviewFileType {
	uid: string;
	name: string;
	status: string;
	url: string;
}

export interface FileType {
	id: string;
	name: string;
	filePath?: string;
}


export const onChangeFileUpload = (info: any) => {
	if (info.file.status !== "uploading") {
		// console.log(info.file, info.fileList);
	}
	if (info.file.status === "done") {
		message.success(`${info.file.name} file uploaded successfully`);
	} else if (info.file.status === "error") {
		message.error(`${info.file.name} file upload failed.`);
	}
};

export const deleteFromS3 = (fileUploaded: any) => {
	return new BucketS3Service()
		.getS3UrlForAction(`${fileUploaded.filePath}/${fileUploaded.name}`, S3ActionType.DELETE)
		.then(s3SignedUrl => {
			return fetch(s3SignedUrl, {method: "DELETE", body: fileUploaded.name});
		});
};

export const onBeforeFileUpload = (
	file: any,
	fileList: any[],
	subjectType: string,
	context: any
): PromiseLike<void> | boolean => {
	if (subjectType === "subjects") {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 5;
		if (!isLt2M) {
			message.error("Image must smaller than 5MB!");
		}
		if (!isJpgOrPng || !isLt2M) return false;
	} else {
		const isLt2M = file.size / 1024 / 1024 < 5;
		if (!isLt2M) {
			message.error("Files must smaller than 5MB!");
		}
		if (!isLt2M) return false;
	}

	fileList = [];
	const fileToUpload: FileDto = new FileDto({
		size: file.size,
		filePath: subjectType,
		name: file.name,
		contentType: file.type
	});
	fileToUpload.name = `${fileToUpload.id}.${file.name.substr(file.name.lastIndexOf(".") + 1)}`;
	const oldFileUploaded = context.state.fileUploaded;
	return new BucketS3Service()
		.getS3UrlForAction(`${fileToUpload.filePath}/${fileToUpload.name}`, S3ActionType.UPLOAD)
		.then(s3SignedUrl => {
			return context.setState({
				s3SignedUrl: s3SignedUrl,
				fileUploaded: fileToUpload
			});
		})
		.then(() => {
			return oldFileUploaded && deleteFromS3(oldFileUploaded);
		})
		.then(() => {
			return;
		});
};

export const onRemoveUploadedFile = (parent: any, file: any, context: any) => {
	return (
		(context.state.fileUploaded || file) &&
		deleteFromS3(context.state.fileUploaded || file).then(() => {
			if (parent) {
				parent.FileId = null;
			}
			return context.setState({s3SignedUrl: "", fileUploaded: null}, () => {
				return;
			});
		})
	);
};

export const getBase64Photo = (file: File): Promise<string | ArrayBuffer | null> => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		return (reader.onload = () => {
			return resolve(reader.result);
		});
	});
};

export const convertPhoto = (file: any) => {
	return Jimp.read(file).then(image => {
		const width = image.bitmap.width;
		const height = image.bitmap.height;
		const baseCrop = width > height ? (height > 720 ? 720 : height) : width > 1280 ? 1280 : width;
		const newWidth = width > height ? (baseCrop * 16) / 9 : baseCrop;
		const newHeight = width > height ? baseCrop : (baseCrop * 9) / 16;
		return image
			.cover(newWidth, newHeight)
			.autocrop()
			.quality(60)
			.getBase64Async(Jimp.MIME_JPEG);
	});
};

export const processPhoto = (file: File, context: any) => {
	return getBase64Photo(file)
		.then((fileInfo: any) => {
			return convertPhoto(fileInfo);
		})
		.then((base64File: string) => {
			return fetch(base64File);
		})
		.then(photo => {
			return photo.arrayBuffer();
		})
		.then(buffer => {
			const newFile = new File([buffer], (context.state.fileUploaded && context.state.fileUploaded.name) || "", {
				type: "image/jpg"
			});
			return newFile;
		});
};

export const proccessFile = (file: File, context: any) => {
	return getBase64Photo(file)
		.then((fileInfo: any) => {
			// console.log("file info", fileInfo);
			return convertPhoto(fileInfo);
		})
		.then((base64File: string) => {
			return fetch(base64File);
		})
		.then(photo => {
			return photo.arrayBuffer();
		})
		.then(buffer => {
			const newFile = new File([buffer], (context.state.fileUploaded && context.state.fileUploaded.name) || "", {
				type: "image/jpg"
			});
			return newFile;
		});
};

export const downloadFile = async (filePath: string) => {
	return new BucketS3Service().getS3UrlForAction(filePath, S3ActionType.DOWNLOAD).then(s3SignedUrl => {
		return window.open(s3SignedUrl, "_blank");
	});
};

export const saveToDisk = async (filePath: string, fileName: string) => {
	return new BucketS3Service()
		.getS3UrlForAction(filePath, S3ActionType.DOWNLOAD)
		.then(s3SignedUrl => {
			return s3SignedUrl;
		})
		.then(fileURL => {
			return fileURL;
		})
		.then(fileUrl => {
			return fetch(fileUrl);
		})
		.then(res => res.blob())
		.then(blob => {
			return saveAs(blob, sanitize(fileName));
		});
};

export const transformFileBeforeUpload = (file: File, context: any): Promise<File> => {
	return processPhoto(file, context).then((newFile: File) => {
		return newFile;
	});
};

export const createFile = (context: any): FileDto => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const file = context.state.fileUploaded;
	if (file) {
		file.updatedBy = currentUserInfo?.UserId;
		file.createdBy = currentUserInfo?.UserId;
	}
	return file;
};

export const createFileTest = (fileTest: any): FileDto => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const file = fileTest;
	if (file) {
		file.updatedBy = currentUserInfo?.UserId;
		file.createdBy = currentUserInfo?.UserId;
	}
	return file;
};


/**
 * Get a s3 url through backend service with an expire time
 * @param fileName
 */
export const getFromS3FullUrl = async (fileName: string): Promise<string> => {
	return new BucketS3Service().getS3UrlForAction(fileName, S3ActionType.DOWNLOAD);
};

/**
 * Get a s3 public url through backend service with an expire time
 * @param fileName
 */
 export const getFromS3FullUrlPublic = async (id : string): Promise<string> => {
	return new BucketS3Service().getS3UrlForPublicAction(id);
};

/**
 * Returns a backend api which generates a s3 url with optional redirect directive
 * @param path
 * @param redirect
 */
export const getPublicFileUrl = (path: string, redirect: 0 | 1 = 1): string => {
	return `${API_BASE_URL}/v1/api/s3/public-files/${encodeURIComponent(path)}/getObject?redirect=${redirect}`;
};

/**
 * Returns a backend api which generates a s3 url with optional redirect directive
 * @param fileId
 * @param redirect
 */
export const getFileUrl = (fileId: string, redirect: 0 | 1 = 1): string => {
	return `${API_BASE_URL}/v1/api/s3/${fileId}?redirect=${redirect}`;
};

/**
 * Get a s3 url through backend service with an expire time
 * @param file
 */
export const getFromS3 = async (file: { filePath?: string, name: string }): Promise<string> => {
	return getFromS3FullUrl(`${file.filePath}/${file.name}`);
};

export const getFromS3Public = async (file: { filePath?: string, name: string, id : string }): Promise<string> => {
	return getFromS3FullUrlPublic(file.id);
};


export const previewFile = async (file: FileType): Promise<PreviewFileType> => {
	return {
		uid: file.id,
		name: file.name,
		status: "done",
		url: await getFromS3(file)
	};
};

export const previewFilePublic = async (file: FileType): Promise<PreviewFileType> => {
	return {
		uid: file.id,
		name: file.name,
		status: "done",
		url: await getFromS3Public(file)
	};
};

const handleUpload = (filePath: string, fileName: string) => {
	return new BucketS3Service().getS3UrlForAction(`${filePath}/${fileName}`, S3ActionType.UPLOAD).then(s3SignedUrl => {
		return s3SignedUrl;
	});
};

export const customRequestUploader = (file: any, filePath: string) => {
	return handleUpload(filePath, file.name).then(signedUrl => {
		return axios.put(signedUrl, file, {
			headers: {
				"Content-Type": file.type
			}
		});
	});
};
