import { Col, Row, Space, Avatar, Empty } from "antd";
import React, { ReactNode, useState } from "react";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { Role } from "services/domain/login/Role";
import { JoinByOrganizationCodeModal } from "./JoinByOrganizationCodeModal";
import {WelcomeMessage} from "./WelcomeMessage";
import { InvitationMessage } from "./InvitationMessage";

interface ChooseAsTeacherProps {
	organizations: any[];
	selectOrganization: (organizationId: string) => void;
	onGoBack: () => void;
}

const getOrganizationTitleBasedOnRole = (name: string, userRole: any): ReactNode => {
	if (userRole.Role.code === Role.Teacher) {
		return (
			<Text fontSize="14" lineHeight="22" className="color-gray-9">
				{name}
			</Text>
		);
	}

	const roleIcon = userRole.Role.code === Role.Owner ? "ri-admin-line" : "ri-user-star-line";
	const roleTitle = userRole.Role.code === Role.Owner ? "Pronar" : "Administrator";

	return (
		<Space direction="vertical" size={4}>
			<Text fontSize="14" lineHeight="22" className="color-gray-9">
				{name}
			</Text>
			<Space direction="horizontal" size={4} className="organization__role_title" align="center">
				<Icon type={roleIcon} className="color-blue-6" fontSize="14" />
				<Text fontSize="12" lineHeight="20" className="color-blue-6">
					{roleTitle}
				</Text>
			</Space>
		</Space>
	);
};

export const ChooseAsTeacher = (props: ChooseAsTeacherProps) => {
	const [joinOrganizationModalVisible, setJoinOrganizationModalVisible] = useState<boolean>(false);
	const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);
	const [showRequireApproval, setShowRequireApproval] = useState<boolean>(false);
	const organizationsAsTeacher: number = props.organizations.reduce(
		(total, nextItem) =>
			total +
			nextItem?.UserRoles?.filter(
				(userRole: any) =>
					userRole.Role.code === Role.Teacher ||
					userRole.Role.code === Role.Owner ||
					userRole.Role.code === Role.Admin
			).length,
		0
	);

	return (
		<>
			{showWelcomeMessage ? (
				<WelcomeMessage />
			) : showRequireApproval ? (
				<InvitationMessage onClose={() => {
					setShowRequireApproval(false);
				}}/>
			):(
				<>
					<Row>
						<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Button type="link" icon="ri-arrow-left-line" onClick={props.onGoBack}>
								Kthehu
							</Button>
						</Col>
					</Row>
					<Row className="mt-16">
						<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Space direction="vertical" align="center" className="full__width" size={16}>
								<Avatar
									icon={<Icon type="ri-user-2-line" className="color-blue-6" />}
									className="choose__organization_avatar"
									size={48}
								/>
								<Text fontSize="30" lineHeight="38" className="color-gray-9">
									Portali i mësuesit {organizationsAsTeacher > 0 ? `(${organizationsAsTeacher})` : ""}
								</Text>
							</Space>
						</Col>
					</Row>
					{organizationsAsTeacher > 0 ? (
						<>
							<Row className="mt-16">
								<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
								<Col
									xs={24}
									sm={24}
									md={12}
									lg={12}
									xl={12}
									xxl={12}
									className="flex__center_space_between"
								>
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										Zgjidhni institucionin arsimor ku dëshironi të hyni.
									</Text>
									<Button
										icon="ri-add-circle-line"
										type="link"
										onClick={() => {
											setJoinOrganizationModalVisible(true);
										}}
									>
										Shto organizatë
									</Button>
								</Col>
							</Row>
							<Row className="mt-16">
								<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
									<Space direction="vertical" className="choose__organization_content" size={8}>
										{props.organizations.map((organization: any) => {
											return organization?.UserRoles?.filter(
												(userRole: any) =>
													userRole.Role.code === Role.Teacher ||
													userRole.Role.code === Role.Owner ||
													userRole.Role.code === Role.Admin
											).map((userRole: any) => {
												return (
													<Row
														key={userRole.id + organization.id}
														onClick={() => props.selectOrganization(organization.id)}
													>
														<Col span={24} className="choose__organization_option">
															<Space direction="horizontal" size={8} align="center">
																<Avatar
																	icon={
																		<Icon
																			type="ri-building-4-line"
																			className="color-blue-6"
																		/>
																	}
																	className="background-color-blue-1"
																/>
																{getOrganizationTitleBasedOnRole(
																	organization.name,
																	userRole
																)}
															</Space>
															<Icon type="ri-arrow-right-line" className="color-gray-6" />
														</Col>
													</Row>
												);
											});
										})}
									</Space>
								</Col>
							</Row>
						</>
					) : null}
					{organizationsAsTeacher === 0 ? (
						<>
							<Row className="mt-32">
								<Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
								<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
									<Space direction="vertical" size={24} className="full__width" align="center">
										<Empty description="Ju nuk jeni të regjistruar në një institucion arsimor." />
										<Button
											type="primary"
											icon="ri-user-line"
											onClick={() => {
												setJoinOrganizationModalVisible(true);
											}}
										>
											Regjistrohu si mësues
										</Button>
									</Space>
								</Col>
							</Row>
						</>
					) : null}
				</>
			)}
			<JoinByOrganizationCodeModal
				visible={joinOrganizationModalVisible}
				onCancel={() => {
					setJoinOrganizationModalVisible(false);
				}}
				onSuccessfulJoin={() => {
					setShowWelcomeMessage(true);
					setJoinOrganizationModalVisible(false);
				}}
				onWaitingApproval={() => {
					setShowRequireApproval(true);
					setJoinOrganizationModalVisible(false);
				}}
				joinedOrganizations={props.organizations}
			/>
		</>
	);
};
