import { Card, Col, Row } from "antd";
import React from "react";
import Text from "common/components/general/Text";
import { PageComponentSpecsOptionType, PageComponentValues } from "../../utils/types";
import { InputGenerator } from "./InputGenerator";

interface ContentInterface {
	settings: PageComponentSpecsOptionType[];
	values: PageComponentValues;
	organizationId: string;
}

export function Content(props: ContentInterface) {
	return (
		<>
			<Card>
				<Row className="mb-24">
					<Col xs={24}>
						<Text fontSize="38" lineHeight="22" className="color-gray-9">
							Content
						</Text>
					</Col>
				</Row>
				{props.settings.map((item: PageComponentSpecsOptionType, index) => {
					return (
						<Row key={index} className="mb-12">
							<Col xs={24}>
								<InputGenerator
									organizationId={props.organizationId}
									key={index}
									specs={item}
									value={props.values[item.var]}
								/>
							</Col>
						</Row>
					);
				})}
			</Card>
		</>
	);
}
