import React, { ReactNode } from "react";
import { Collapse } from "antd";

interface CollapseProps {
	borderless?: boolean;
	panels?: PanelProps[];
	defaultActiveKey?: string | string[] | number | number[];
	accordion?: boolean;
	children?: ReactNode;
	onChange?: (key: any) => void;
	className?: string;
}

export interface PanelProps {
	key: string | number;
	header: string | ReactNode;
	content: ReactNode;
	extra?: ReactNode;
	showArrow?: boolean;
	disabled?: boolean;
	className?: string;
}

const Display = (props: CollapseProps) => {
	const panels =
		props.panels &&
		props.panels
			// .sort(panel => panel.key)
			.map((p: PanelProps) => {
				return (
					<Collapse.Panel {...p} key={p.key}>
						{p.content}
					</Collapse.Panel>
				);
			});
	return props.panels ? (
		<Collapse bordered={!props.borderless} {...props}>
			{panels}
		</Collapse>
	) : (
		<Collapse bordered={!props.borderless} {...props}>
			{props.children}
		</Collapse>
	);
};

export default Display;
