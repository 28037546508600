import { Avatar, Card, Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Button from "common/components/general/Button";
import OAuth2Service from "services/authentication/oauth2.service";
import OrganizationService from "services/administration/organization.service";
import { previewFile } from "scenes/courseBuilder/utils/FileUtils";
import { MyProfileForm } from "./MyProfileForm";
import { ChangeAvatarModal } from "./ChangeAvatarModal";
import { ChangeOrganizationDrawer } from "./ChangeOrganizationDrawer";
import { isOver13 } from "common/utils/isOver13";
import { UnlinkFromParent } from "./UnlinkFromParent";
import { boolVal, getComponentProps } from "../../../../../../common/utils/Design";
import { MainLayout } from "scenes/main/components/layout/Layout";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._MY_PROFILE";

export const MyProfile = (props: any) => {
	const { t: translate } = useTranslation();

	const [userSettings, setUserSettings] = useState<any>(OAuth2Service.CurrentUserSettings);
	const [imagePhoto, setImagePhoto] = useState<any>("");
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [organization, setOrganization] = useState<any>();
	const [changeAvatar, setChangeAvatar] = useState<any>({
		visible: false
	});
	const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
	const [currentUserInfo, setCurrentUserInfo] = useState(OAuth2Service.CurrentUser);
	const isTeacher = OAuth2Service.isTeacherAtSuperOrganization;
	const isStudent = OAuth2Service.isStudent;

	const getImage = async (file: any) => {
		const response = await previewFile(file);
		setImagePhoto(response.url);
	};

	const loadOrganization = async () => {
		return await new OrganizationService().findCurrentOrganization().then((organization: any) => {
			return setOrganization(organization[0]);
		});
	};

	const refreshUserAvatar = (settings: any) => {
		if (
			settings !== undefined &&
			settings.profilePicture &&
			settings.profilePictureType &&
			settings.profilePictureType === "upload" &&
			settings.profilePictureFile
		) {
			getImage(settings.profilePictureFile);
		}
	};

	useEffect(() => {
		refreshUserAvatar(userSettings);
		if (isTeacher || isStudent) loadOrganization();
	}, []);

	const getUserAvatar = () => {
		if (
			userSettings.profilePicture &&
			userSettings.profilePictureType &&
			userSettings.profilePictureType === "static"
		) {
			return (
				<Avatar
					shape="square"
					src={require(`assets/images/avatars/${userSettings.profilePicture}`)}
					className="settings__my_profile_static_avatar"
				/>
			);
		} else if (
			userSettings.profilePicture &&
			userSettings.profilePictureType &&
			userSettings.profilePictureType === "upload" &&
			userSettings.profilePictureFile
		) {
			return <Avatar shape="square" src={imagePhoto} className="settings__my_profile_upload_avatar" />;
		}
		return (
			<Avatar shape="square" className="settings__my_profile_avatar">
				<Text fontSize="24" lineHeight="32">
					{currentUserInfo?.User?.firstName[0] || ""}
					{currentUserInfo?.User?.lastName[0] || ""}
				</Text>
			</Avatar>
		);
	};

	let submitFormHandler: () => Promise<any>;
	const sideLayoutProps = getComponentProps(props.pageComponents, "sideLayout");

	return (
		<MainLayout
			showMarketPlace={boolVal(sideLayoutProps, "showExploreCourses", true)}
			showAddCourse={boolVal(sideLayoutProps, "joinLiveCourse", true)}
		>
			<div className="settings__my_profile pt-24 pl-8 pr-8">
				<Row>
					<Col xs={0} sm={0} md={2} lg={4} xl={6} xxl={6}></Col>
					<Col xs={24} sm={24} md={20} lg={16} xl={12} xxl={12}>
						<Card
							title={
								<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
									{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
								</Text>
							}
							className="settings__my_profile_card"
						>
							<Card.Grid hoverable={false} className="full__width">
								<Space direction="vertical" size={0} align="start">
									<Space direction="horizontal" size={24} align="center">
										{getUserAvatar()}
										<Text
											fontSize="20"
											lineHeight="28"
											wheight="semibold"
										>{`${currentUserInfo?.User?.firstName} ${currentUserInfo?.User?.lastName}`}</Text>
									</Space>
									<Button
										type="link"
										htmlType="button"
										className="pl-0 ml-8"
										onClick={() => {
											setChangeAvatar({
												visible: true
											});
										}}
									>
										{translate(`${TRANSLATION_BASE_PATH}._CHANGE_PROFILE_PICTURE`)}
									</Button>
								</Space>
							</Card.Grid>
							<Card.Grid hoverable={false} className="full__width">
								<MyProfileForm
									{...currentUserInfo?.User}
									id={currentUserInfo?.UserId || ""}
									isTeacher={isTeacher}
									setSubmitting={setSubmitting}
									setIsFormValid={setIsFormValid}
									submitFormHandler={(triggerSubmit: any) => {
										submitFormHandler = triggerSubmit;
									}}
								/>
							</Card.Grid>
							<Card.Grid hoverable={false} className="full__width settings__my_profile_footer">
								<Button
									type="primary"
									disabled={!isFormValid}
									loading={submitting}
									onClick={() => submitFormHandler()}
								>
									{translate(`${TRANSLATION_BASE_PATH}._SAVE`)}
								</Button>
							</Card.Grid>
						</Card>
					</Col>
				</Row>
				{(isTeacher || isStudent) && organization && (
					<Row className="pt-24 pb-24" justify="center">
						<Col xs={24} sm={24} md={20} lg={16} xl={12} xxl={12}>
							<Card className="">
								<Row>
									<Col span="20">
										<Space direction="horizontal" size={12}>
											<Icon type="ri-building-line" className="color-blue-6" fontSize="24" />
											<Space direction="vertical" size={0}>
												<Text fontSize="20" lineHeight="32" className="color-gray-8">
													{organization ? organization.name : "-"}
												</Text>
												<Text fontSize="14" lineHeight="22" className="color-gray-7">
													{translate(`${TRANSLATION_BASE_PATH}._SCHOOL`)}
												</Text>
											</Space>
										</Space>
									</Col>
									<Col span="4">
										<Row justify="end">
											{organization && isTeacher && (
												<Icon
													className="cursor_pointer"
													type="ri-edit-line"
													tooltip={translate(`${TRANSLATION_BASE_PATH}._MODIFY_ORGANIZATION`)}
													onClick={() => setDrawerVisible(true)}
												></Icon>
											)}
											{!organization && (
												<Button
													type="primary"
													size="middle"
													icon="ri-add-line"
													onClick={() => setDrawerVisible(false)}
												>
													{translate(`${TRANSLATION_BASE_PATH}._SCHOOL`)}
												</Button>
											)}
										</Row>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				)}
				<ChangeAvatarModal
					visible={changeAvatar.visible}
					profilePicture={userSettings.profilePicture}
					profilePictureType={userSettings.profilePictureType}
					profilePictureFile={userSettings.profilePictureFile}
					onCancel={() => {
						setChangeAvatar({
							visible: false
						});
					}}
					afterUpdate={() => {
						refreshUserAvatar(OAuth2Service.CurrentUserSettings);
						setUserSettings(OAuth2Service.CurrentUserSettings);
						setChangeAvatar({
							visible: false
						});
						window.location.reload();
					}}
				/>
				{organization && (
					<ChangeOrganizationDrawer
						organization={organization}
						setDrawerVisible={setDrawerVisible}
						setOrganization={organization => setOrganization(organization)}
						visible={drawerVisible}
					/>
				)}
				{currentUserInfo?.User.parentEmail && isOver13(currentUserInfo?.User.dateOfBirth) && (
					<UnlinkFromParent refreshUser={setCurrentUserInfo} user={currentUserInfo.User} />
				)}
			</div>
		</MainLayout>
	);
};
