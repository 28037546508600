import moment from "moment";
import * as Yup from "yup";

const TRANSLATION_BASE_PATH_REQUIRED = "_COURSE_BUILDER._REQUIRED";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._MEETING";

export const formValidator = (translate: (key: string) => string) => {
	return Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.typeError(translate(TRANSLATION_BASE_PATH_REQUIRED))
			.required(translate(TRANSLATION_BASE_PATH_REQUIRED)),
		description: Yup.string().nullable(),
		startDate: Yup.date()
			.typeError(translate(TRANSLATION_BASE_PATH_REQUIRED))
			.required(translate(TRANSLATION_BASE_PATH_REQUIRED)),
		startTime: Yup.array()
			.typeError(translate(TRANSLATION_BASE_PATH_REQUIRED))
			.required(translate(TRANSLATION_BASE_PATH_REQUIRED))
			.when("startDate", {
				is: (startDate: string) => startDate !== undefined && moment(startDate).date() === moment().date(),
				then: Yup.array()
					.typeError(translate(TRANSLATION_BASE_PATH_REQUIRED))
					.required(translate(TRANSLATION_BASE_PATH_REQUIRED))
					.test("is-valid-time", translate(`${TRANSLATION_BASE_PATH}._TIME_NOT_VALID`), function(values) {
						return values.length > 0 && values[0] > moment();
					})
			})
	});
};
