import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { scrollToTop } from "common/utils/WindowUtils";
import ClassroomService from "services/administration/classroom.service";
import SubjectService from "services/administration/subject.service";
import { CourseView } from "../_courseView";

const TRANSLATION_BASE_PATH = "_MARKETPLACE";

export const SectionSingleSubjectAllItems = (props: any) => {
	const { t: translate } = useTranslation();
	const [items, setItems] = useState<any>([]);
	const [subject, setSubject] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [imagePrefix, setImagePrefix] = useState(null);

	const loadData = () => {
		setLoading(true);
		props.handleSearchProgress(1);
		return new SubjectService()
			.getById(props.subjectId)
			.then((subject: any) => {
				return setSubject(subject);
			})
			.then(() => {
				return new ClassroomService().getForMarketClassroomsBySubjectId({
					subjectId: props.subjectId,
					filters: props.filters,
					key: props.searchKey
				});
			})
			.then((result: any) => {
				setImagePrefix(result[0]);
				return setItems(result[1]);
			})
			.finally(() => {
				props.handleSearchProgress(1);
				setLoading(false);
			});
	};

	useEffect(() => {
		loadData();
		scrollToTop();
	}, [props.searchKey, props.filters, props.subjectId]);

	return (
		<CourseView
			title={subject?.name}
			icon={`ri-${subject?.icon}}`}
			items={items}
			imagePrefix={imagePrefix}
			loading={loading}
		/>
	);
};
