import { v4 as uuidv4 } from "uuid";

export default class FileDto {
	readonly id!: string;
	public name!: string;
	readonly contentType?: string;
	readonly size?: number;
	readonly filePath?: string;
	readonly createdBy?: string;
	readonly updatedBy?: string;

	constructor(data: {
		name: string;
		contentType?: string;
		size?: number;
		filePath?: string;
		createdBy?: string;
		updatedBy?: string;
		[key: string]: any;
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
