import React from "react";

import TagService, { TYPE_CATEGORY, TYPE_DROPDOWN } from "services/administration/tag.services";

import styled, { createGlobalStyle } from "styled-components";
import { Dropdown, Button, Checkbox } from "antd";
import { DownOutlined } from "@ant-design/icons";
import TagDto from "../../dtos/administration/tag.dto";

export interface ActiveFilters {
	[key: string]: string[]
}

interface DropdownFiltersProps {
	activeFilters: ActiveFilters;
	setActiveFilters: (rightFilters: ActiveFilters) => void;
}
interface DropdownRecord {
	tag: TagDto,
	children: TagDto[],
	activeChildren: string[]
}

export const DropdownFilters = (props: DropdownFiltersProps) => {

	const [parents, setParents] = React.useState<DropdownRecord[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const loadTags = async () => {
		try {
			setLoading(true);
			const tagService = new TagService();

			const dropdownTags: TagDto[] = await tagService.getAllForPublic({
				organization: "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65",
				type: TYPE_DROPDOWN,
			});
			const formattedTags = dropdownTags.filter(item => item.ParentId === null).map(item => {
				return {
					tag: item,
					children: dropdownTags.filter(_item => _item.ParentId === item.id),
					// @todo integrate with url params
					activeChildren: props.activeFilters[item.id] ?? []
				};
			}).filter(_ => _.children.length);

			setParents(formattedTags);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		loadTags();
	}, [props.activeFilters]);

	/**
	 * Groups all active filters of all dropdowns and emits them
	 */
	const setAllActiveFilters = (filters: string[], parentId: string) => {
		const fs = parents.filter(_ => _.activeChildren.length && _.tag.id !== parentId).reduce((acc: ActiveFilters, item) => {
			acc[item.tag.id] = item.activeChildren;

			return acc;
		}, {});
		if (filters.length) {
			fs[parentId] = filters;
		}
		props.setActiveFilters(fs);
	};

	return (
		<StyledFiltersWrapper>
			<>
				<DropdownGlobalStyle />
				{parents.length > 1 &&
					parents.map((parentTag: DropdownRecord, index: number) => {
						return (
							<React.Fragment key={index}>
								<CustomDropDown
									options={parentTag.children}
									value={parentTag.activeChildren}
									label={parentTag.tag.name}
									onChange={(filters) => setAllActiveFilters(filters, parentTag.tag.id)}
								/>
							</React.Fragment>
						);
					})}
			</>
		</StyledFiltersWrapper>
	);
};

interface OverlayProps {
	value: string[];
	options: { id: string, name: string }[];
	onChange: (input: string[]) => void;
}
const Overlay = (props: OverlayProps) => {
	const onchange = (checked: boolean, id: string) => {
		if (checked) {
			props.onChange([...props.value, id]);
		} else {

			props.onChange(props.value.filter((item: string) => item !== id));
		}
	};

	return (
		<div className="dropdown-overlay">
			{props.options.map((option, index: number) => (
				<Checkbox
					key={index}
					checked={props.value.includes(option.id)}
					onChange={({ target: { checked } }) => onchange(checked, option.id)}
				>
					{option.name}
				</Checkbox>
			))}
		</div>
	);
};

interface CustomDropDownProps {
	options: { id: string; name: string; }[];
	value: string[];
	onChange: (input: string[]) => void;
	label: string;
}
const CustomDropDown = (props: CustomDropDownProps) => {
	return (
		<Dropdown
			overlay={<Overlay options={props.options} value={props.value} onChange={props.onChange} />}
			overlayClassName="custom-dropdown-filters-overlay"
		>
			<Button className="dropdown-button">
				{props.label} <DownOutlined />
			</Button>
		</Dropdown>
	);
};

const DropdownGlobalStyle = createGlobalStyle`
	.custom-dropdown-filters-overlay {
		.dropdown-overlay {
			display: flex;
			flex-direction: column;
			background-color: #FFFFFF;
			row-gap: 9px;

			box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
			border-radius: 6px;
			padding: 12px 11px 11px 11px;

			.ant-checkbox-wrapper {

				margin: 0;

				>span:nth-child(2) {
					font-family: Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #4A5568;
				}

				.ant-checkbox-inner {
					border-radius: 2px;
				}
			}
		}
	}
`;

const StyledFiltersWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	gap: 10px;
	column-gap: 20px;
	margin-top: 40px;
	margin-bottom: 50px;
	.dropdown-button {
		height: 24px;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: #4a5568;
		padding: 1px 8px;
	}

	> div {
		width: calc((100% - (3 * 20px)) / 4);
	}
`;
