import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Layout, Tabs, Space, Menu, Dropdown, Modal, Skeleton } from "antd";
import Button from "common/components/general/Button";
import TabPane from "common/components/dataDisplay/TabPane";
import ClassroomService from "services/administration/classroom.service";
import { MAIN_PATH } from "scenes/main";
import StudentCourseOverview from "scenes/course/scenes/courseDetails/scenes/StudentCourseOverview/StudentCourseOverview";
import SubjectPlan from "scenes/course/scenes/courseDetails/scenes/SubjectPlan/SubjectPlan";
import StudentList from "scenes/course/scenes/courseDetails/scenes/StudentList/StudentList";
import Asignments from "scenes/course/scenes/courseDetails/scenes/Asignments/Asignments";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { COURSES_PATH } from "scenes/main/scenes/courses/TeacherCourses";
import EnrollmentService from "services/administration/enrollment.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SIGN_IN_PATH } from "../../../../../login/scenes/signIn";

export const RELATED_COURSE_PATH = ":classroomId";
const TRANSLATION_BASE_PATH = "_COURSE._COURSE_DETAILS._LAYOUT";

class MainStudentLayoutComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			classroomId: "",
			classroom: null,
			activeTab: "1",
			enrolledStudents: null,
			refreshToggle: false,
			enrollmentObject: null,
			groupedBy: null
		};
		this.getBackButton = this.getBackButton.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
		this.openConfirmationModal = this.openConfirmationModal.bind(this);
	}

	componentDidMount() {
		const {
			history,
			match: { params }
		} = this.props;
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);

		const activeContent = queryString.get("content");
		const activeAssignment = queryString.get("assignment");
		const groupedBy = queryString.get("groupedBy");

		this.props.history.push(this.props.location.url);
		this.setState({ activeTab: activeContent ? "2" : activeAssignment ? "4" : "1", groupedBy: groupedBy });

		if (!params.classroomId) return history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		this.loadClassroom();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.classroomId !== this.state.classroomId) this.loadClassroom();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.classroomId !== prevState.classroomId) return { classroomId: params.classroomId };
		return null;
	}

	loadClassroom = async () => {
		const { history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		const [classroom, enrolledStudents] = await Promise.all([
			new ClassroomService().getClassroomById(this.state.classroomId),
			new EnrollmentService().getAllEnrollmentsByClassroomId(this.state.classroomId)
		]);
		if (!classroom) return history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		const isRegistered = enrolledStudents.find(
			(enrolledStudent: any) =>
				enrolledStudent.UserId === currentUserInfo.UserId && enrolledStudent.status === "ACTIVE"
		);
		if (!isRegistered) return history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		const enrollmentObject = await new EnrollmentService().getEnrollmentById(isRegistered.id);
		this.setState({
			classroom: classroom,
			enrolledStudents: enrolledStudents,
			refreshToggle: !this.state.refreshToggle,
			enrollmentObject: enrollmentObject,
			organization: classroom?.Organization
		});
	};

	goBack = () => {
		const { history } = this.props;
		history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
	};

	getBackButton = (): React.ReactNode => {
		return (
			<Button
				onClick={() => {
					this.goBack();
				}}
				type="text"
			>
				<Space>
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						<Icon type="ri-arrow-left-line" />
					</Text>
					<Text maxLength={25} fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{this.state?.classroom?.name}
					</Text>
				</Space>
			</Button>
		);
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	openConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: <QuestionCircleOutlined />,
			content: translate(`${TRANSLATION_BASE_PATH}._UNSUBSCRIBE_MESSAGE`, {
				class: `${this.state?.classroom?.name}`
			}),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_UNSUBSCRIBE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.unsubscribeUser(this.state.enrollmentObject.id)
		});
	};

	unsubscribeUser = (enrollmentId: string) => {
		return new EnrollmentService().deleteEnrollment(enrollmentId).then(() => {
			return this.goBack();
		});
	};

	render() {
		const { t: translate } = this.props;

		const menu = (
			<Menu>
				<Menu.Item key="0" onClick={() => this.openConfirmationModal()}>
					<Space direction="horizontal">{translate(`${TRANSLATION_BASE_PATH}._UNENROLL`)}</Space>
				</Menu.Item>
			</Menu>
		);

		return (
			<Skeleton loading={!(this.state.classroom && this.state.organization)}>
				{this.state.classroom && this.state.organization && (
					<Layout className="background-color-gray-2">
						<Tabs
							centered={true}
							tabBarExtraContent={{
								left: this.getBackButton(),
								right: (
									<Dropdown.Button
										buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
										trigger={["click"]}
										overlay={menu}
										icon={<Icon type="ri-more-2-line"></Icon>}
									/>
								)
							}}
							className="courseBuilder__courseDetails-tab"
							defaultActiveKey={this.state.activeTab}
						>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._HEAD`)} key="1">
								<StudentCourseOverview
									classroom={this.state.classroom}
									enrolledStudents={this.state.enrolledStudents}
									organization={this.state.organization}
									{...this.props}
								/>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._CONTENT`)} key="2">
								<SubjectPlan
									classroom={this.state.classroom}
									enrollmentObject={this.state.enrollmentObject}
									groupedBy={this.state.groupedBy}
									{...this.props}
								/>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._STUDENTS`)} key="3">
								<StudentList
									refreshToggle={this.state.refreshToggle}
									classroom={this.state.classroom}
									enrolledStudents={this.state.enrolledStudents}
									reloadClassroom={this.loadClassroom}
									{...this.props}
								/>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._ASIGNMENTS`)} key="4">
								<Asignments classroom={this.state.classroom} {...this.props} />
							</TabPane>
						</Tabs>
					</Layout>
				)}
			</Skeleton>
		);
	}
}

export const MainStudentLayout = withTranslation()(MainStudentLayoutComponent);
