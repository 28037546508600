import { Badge, Button, Col, Divider, Empty, Popover, Row, Skeleton, Space } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { getTimeLabel } from "../utils/TimeLabel";
import { TypeIcon } from "../subject/subjectTypeIcon";
import NotificationService from "services/administration/notification.service";
import NotificationDto from "dtos/administration/notification.dto";
import { ItemType } from "services/domain/administration/ItemType";
import InvitationService from "services/administration/invitation.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import LessonService from "services/administration/lesson.service";
import { showError } from "common/utils/Notification";
import EnrollmentDto from "dtos/administration/enrollment.dto";
import { COURSE_PATH } from "scenes/course";
import EnrollmentService from "services/administration/enrollment.service";
import ClassroomService from "services/administration/classroom.service";
import UserService from "services/authentication/user.service";

const TRANSLATION_BASE_PATH = "_NOTIFICATIONS";
const MAX_ITEMS_TO_INITIALLY_DOWNLOAD = 4;

class NotificationsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			load: false,
			countUnseen: 0,
			notifications: null,
			shownPopup: false,
			shownModal: false,
			seenAll: false,
			downloadedAll: false
		};
		this.toggleNotifications = this.toggleNotifications.bind(this);
	}

	componentDidMount() {
		this.countUnseenNotifications();
	}

	countUnseenNotifications = async () => {
		let unseenNotifications = await new NotificationService().countAllByUserId();
		let unseenClassroomInvitations = 0;

		let classroomInvitations: any = await new InvitationService().getClassroomInvitations();

		classroomInvitations?.forEach((element: any) => {
			if (!window.sessionStorage.getItem(`notificationSeen_${element.id}`)) {
				unseenClassroomInvitations++;
			}
		});

		let count = (unseenNotifications < 0 ? 0 : unseenNotifications) + unseenClassroomInvitations;
		return this.setState({
			load: count !== 0,
			countUnseen: count
		});
	};

	getClassroomInvitations = async () => {
		const invite = await new InvitationService().getClassroomInvitations();
		return this.setState({
			notifications: invite ? [{ classroomInvitations: invite }] : [],
			loading: false
		});
	};

	markClassroomInvitesSeen = (invites: any) => {
		sessionStorage.clear();
		invites.forEach((element: any) => {
			window.sessionStorage.setItem(`notificationSeen_${element.id}`, "true");
		});
	};

	downloadSomeNotifications = async () => {
		this.setState({ loading: true });
		const notifications = await new NotificationService()
			.getSomeByUserId(MAX_ITEMS_TO_INITIALLY_DOWNLOAD)
			.then((notifications: NotificationDto[]) => {
				return Promise.all(
					notifications.map(async (notification: NotificationDto) => {
						if (
							notification.type === "OWNER_ORGANIZATION" ||
							notification.type === "ADMIN_ORGANIZATION" ||
							notification.type === "TEACHER_ORGANIZATION"
						) {
							const [organizationId, email] = (notification.navigateUrl &&
								notification.navigateUrl.split("|")) || [null, null];
							const invite =
								!!organizationId &&
								!!email &&
								(await new InvitationService().getOrganizationInvite(organizationId, email));
							return Promise.resolve({
								...notification,
								activeInvitation: !!invite
							});
						}
						return Promise.resolve(notification);
					})
				);
			});

		let classroomInvitations = await new InvitationService().getClassroomInvitations();
		if (classroomInvitations) this.markClassroomInvitesSeen(classroomInvitations);

		if (!!classroomInvitations.length) {
			classroomInvitations = await Promise.all(classroomInvitations.map(async (classroom: any) => {
				const classroomUser = await new UserService().getUserById(classroom?.createdBy);

				return {
					...classroom,
					invitedBy: `${classroomUser?.firstName} ${classroomUser.lastName}`
				}
			}));
		}

		return this.setState({
			notifications: [...notifications, { classroomInvitations }],
			loading: false
		});
	};

	downloadAllNotifications = async () => {
		this.setState({ loading: true });
		const notifications = await new NotificationService()
			.getAllByUserId()
			.then((notifications: NotificationDto[]) => {
				return Promise.all(
					notifications.map(async (notification: NotificationDto) => {
						if (
							notification.type === "OWNER_ORGANIZATION" ||
							notification.type === "ADMIN_ORGANIZATION" ||
							notification.type === "TEACHER_ORGANIZATION"
						) {
							const [organizationId, email] = (notification.navigateUrl &&
								notification.navigateUrl.split("|")) || [null, null];
							const invite =
								!!organizationId &&
								!!email &&
								(await new InvitationService().getOrganizationInvite(organizationId, email));
							return Promise.resolve({ ...notification, activeInvitation: !!invite });
						}
						return Promise.resolve(notification);
					})
				);
			});

		let classroomInvitations = await new InvitationService().getClassroomInvitations();

		if (!!classroomInvitations.length) {
			classroomInvitations = await Promise.all(classroomInvitations.map(async (classroom: any) => {
				const classroomUser = await new UserService().getUserById(classroom?.createdBy);

				return {
					...classroom,
					invitedBy: `${classroomUser?.firstName} ${classroomUser.lastName}`
				}
			}));
		}

		return this.setState({
			notifications: [...notifications, { classroomInvitations }],
			loading: false
		});
	};

	toggleNotifications = (modal: boolean) => {
		if (!this.state.notificattions) {
			return this.downloadSomeNotifications()
				.then(() =>
					this.setState({
						load: false,
						shownPopup: !modal ? !this.state.shownPopup : this.state.shownPopup,
						shownModal: modal ? !this.state.shownModal : this.state.shownModal,
						downloadedAll: false
					})
				)
				.then(() => {
					if (this.state.countUnseen > 0) {
						return this.markAllAsSeen();
					}
					return;
				});
		}
		this.setState({
			shownPopup: !modal ? !this.state.shownPopup : this.state.shownPopup,
			shownModal: modal ? !this.state.shownModal : this.state.shownModal
		});
	};

	setItemInProccessing = (thisItem: NotificationDto, processing: boolean) => {
		const currentItems = this.state.notifications as NotificationDto[];
		currentItems.forEach((item: NotificationDto) => {
			if (item.id === thisItem.id) {
				item.processing = processing;
			}
		});
		this.setState({
			notifications: currentItems
		});
	};

	delete = async (notification: NotificationDto) => {
		this.setItemInProccessing(notification, true);
		return new NotificationService().remove(notification.id ?? "").then(() => {
			const remainedNotifications = this.state.notifications.filter((x: NotificationDto) => {
				return x.id !== notification.id;
			});
			return this.setState(
				{
					notifications: remainedNotifications
				},
				() => {
					if (this.state.notifications.length === 0) {
						return this.downloadAllNotifications();
					}
					return;
				}
			);
		});
	};

	markAllAsSeen = async () => {
		return new NotificationService().markAllAsSeen().then(() => {
			return this.setState({ countUnseen: 0 });
		});
	};

	acceptParentInvite = (notification: any) => {
		const params = notification.navigateUrl && notification.navigateUrl.split("|");
		if (params && params?.length > 1) {
			this.setItemInProccessing(notification, true);
			return new InvitationService()
				.acceptParentInvite(params[0], params[1])
				.then(() => {
					return new NotificationService().remove(notification.id ?? "");
				})
				.then(() => {
					const decodedToken = OAuth2Service.CurrentToken;
					const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
					const selectedOrganizationId = localStorage.getItem(currentUserInfo?.UserId || "");
					return OAuth2Service.refreshToken(decodedToken.refreshToken, selectedOrganizationId);
				})
				.then(() => {
					return (notification["accepted"] = true);
				})
				.catch(error => {
					const errorStatus = (error && error.status) ?? "500";
					if (errorStatus !== 404) return;
					notification["rejectedByParent"] = true;
					return new NotificationService().remove(notification.id ?? "");
				})
				.finally(() => {
					this.setItemInProccessing(notification, false);
				});
		}
	};

	rejectParentInvite = (notification: any) => {
		const params = notification.navigateUrl && notification.navigateUrl.split("|");
		if (params && params?.length > 0) {
			return new InvitationService()
				.deleteInvite(params[0])
				.then(() => {
					this.setItemInProccessing(notification, true);
					return new NotificationService().remove(notification.id ?? "");
				})
				.then(() => {
					notification["rejected"] = true;
					return this.setItemInProccessing(notification, false);
				});
		}
	};

	get popoverTitle() {
		const { t: translate } = this.props;
		return <Row className="p-8">{translate(`${TRANSLATION_BASE_PATH}._POPOVER._TITLE`)}</Row>;
	}

	organizationInvitationNotification = (item: any) => {
		const { t: translate } = this.props;
		return (
			<Row className="pt-4 pb-4">
				{item.accepted && (
					<Text fontSize="12" lineHeight="20" className="color-green-6">
						{translate(`${TRANSLATION_BASE_PATH}._INVITE_ACCEPTED`)}
					</Text>
				)}
				{item.rejected && (
					<Text fontSize="12" lineHeight="20" className="color-red-6">
						{translate(`${TRANSLATION_BASE_PATH}._INVITE_REJECTED`)}
					</Text>
				)}
				{!item.accepted && !item.rejected && item.activeInvitation && (
					<Row>
						<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
							<Button
								className="background-color-blue-6"
								onClick={() => this.acceptOrganizationInvite(item)}
							>
								<Text fontSize="14" lineHeight="20" className="color-gray-1">
									{translate(`${TRANSLATION_BASE_PATH}._INVITE_ORGANIZATION_ACCEPT`)}
								</Text>
							</Button>
						</Col>
						<Col xs={0} sm={12} md={12} lg={12} xl={12} xxl={12}>
							<Button onClick={() => this.rejectOrganizationInvite(item)}>
								{translate(`${TRANSLATION_BASE_PATH}._INVITE_REJECT`)}
							</Button>
						</Col>
						<Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0} className="pt-4">
							<Button onClick={() => this.rejectOrganizationInvite(item)}>
								{translate(`${TRANSLATION_BASE_PATH}._INVITE_REJECT`)}
							</Button>
						</Col>
					</Row>
				)}
			</Row>
		);
	};

	acceptOrganizationInvite = (notification: any) => {
		const [organizationId, email] = notification.navigateUrl && notification.navigateUrl.split("|");
		if (!!organizationId && !!email) {
			this.setItemInProccessing(notification, true);
			return new InvitationService()
				.acceptOrganizationInvite(organizationId, email)
				.then((organization: any) => {
					const decodedToken = OAuth2Service.CurrentToken;
					const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
					localStorage.setItem(currentUserInfo?.UserId || "", organization.id);
					return OAuth2Service.refreshToken(decodedToken.refreshToken, organization.id);
				})
				.then(() => {
					notification["accepted"] = true;
					return window.location.reload();
				})
				.catch(error => {
					const errorStatus = (error && error.status) ?? "500";
					if (errorStatus !== 404) return;
					return new NotificationService().remove(notification.id ?? "");
				})
				.finally(() => {
					this.setItemInProccessing(notification, false);
				});
		}
	};

	rejectOrganizationInvite = (notification: any) => {
		const [organizationId, email] = notification.navigateUrl && notification.navigateUrl.split("|");
		if (!!organizationId && !!email) {
			return new InvitationService()
				.rejectOrganizationInvite(organizationId, email)
				.then(() => {
					this.setItemInProccessing(notification, true);
					return new NotificationService().remove(notification.id ?? "");
				})
				.then(() => {
					notification["rejected"] = true;
					return this.setItemInProccessing(notification, false);
				});
		}
	};

	acceptClassroomInvitation = async (classroomInvitation: any) => {
		const { t: translate } = this.props;
		let enrollmentToClass: any = null;

		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const enrollment: EnrollmentDto = new EnrollmentDto({
			UserId: currentUserInfo?.UserId || "",
			ClassroomId: classroomInvitation.ClassroomId,
			status: "ACTIVE",
			enrolledAt: new Date()
		});

		await new EnrollmentService()
			.createEnrollment(enrollment)
			.then((enrollment: any) => {
				if (!enrollment) return showError(translate(`_MAIN._CLASSES._MODAL_JOIN._INVALID_CODE`));
				enrollmentToClass = { status: enrollment?.status, ClassroomId: enrollment.ClassroomId };
				return;
			})
			.then(() => {
				if (classroomInvitation?.code)
					return new ClassroomService().getClassroomByCode(classroomInvitation.code);
			})

			.then(() => {
				if (enrollmentToClass?.status === "ACTIVE")
					return this.props.history.push(`/${COURSE_PATH}/_student/${enrollment.ClassroomId}`);
				return;
			})
			.catch(() => {
				return showError(translate(`_MAIN._CLASSES._MODAL_JOIN._INVALID_CODE`));
			})
			.finally(() => {
				this.deleteClassroomInvitation(classroomInvitation);
			});
	};

	deleteClassroomInvitation = async (classroomInvitation: any) => {
		await new InvitationService().deleteInvite(classroomInvitation.id);
		await this.downloadSomeNotifications();
	};

	getPopoverContent(fullScreen: boolean) {
		const { t: translate } = this.props;
		const padding = fullScreen ? "p-16" : "pb-8 pl-8 pr-8";
		return (
			<Skeleton loading={this.state.loading}>
				<div className="popover__notification__container">
					<Row className={padding}>
						{this.state.notifications && this.state.notifications.length > 0 && (
							<Col span="12">
								<Row justify="start">
									<Text fontSize="12" lineHeight="20" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._POPOVER._NEW`).toUpperCase()}
									</Text>
								</Row>
							</Col>
						)}
					</Row>
					{this.state.notifications &&
						this.state.notifications
							.filter((item: any) => item.classroomInvitations)?.[0]
							?.classroomInvitations?.map((item: any) => {
								return (
									<Row
										key={item.id}
										className={`${fullScreen ? "p-16" : "p-8"} cursor_pointer notification__item`}
									>
										<Col span={3}>
											<Row>{item.type && <TypeIcon type={item.type as ItemType} />}</Row>
										</Col>
										<Col
											span={fullScreen && !item.markedAsSeen ? 17 : 18}
											className={fullScreen ? "pl-8" : ""}
										>
											<Row>
												<Text
													fontSize="14"
													lineHeight="22"
													wheight="semibold"
													className="color-gray-8"
													maxLength={50}
												>
													{item.title}
												</Text>
											</Row>
											<Row>
												<Text fontSize="14" lineHeight="20" className="color-gray-7" wheight="semibold">
													{`Mesuesi ${item.invitedBy} ju ka ftuar në kursin "${item.name}"`}
												</Text>
											</Row>
											<Row>
												<Space>
													<Button
														className="background-color-blue-6"
														onClick={() => this.acceptClassroomInvitation(item)}
													>
														<Text fontSize="14" lineHeight="20" className="color-gray-1">
															{translate(
																`${TRANSLATION_BASE_PATH}._INVITE_ORGANIZATION_ACCEPT`
															)}
														</Text>
													</Button>
													<Button onClick={() => this.deleteClassroomInvitation(item)}>
														{translate(`${TRANSLATION_BASE_PATH}._INVITE_REJECT`)}
													</Button>
												</Space>
											</Row>
										</Col>
									</Row>
								);
							})}
					{this.state.notifications &&
						this.state.notifications
							.filter((item: any) => !item.classroomInvitations)
							.map((item: any) => {
								return (
									<Row
										key={item.id}
										className={`${fullScreen ? "p-16" : "p-8"} cursor_pointer notification__item`}
									>
										<Col span={3}>
											<Row>{item.type && <TypeIcon type={item.type as ItemType} />}</Row>
										</Col>
										<Col
											span={fullScreen && !item.markedAsSeen ? 17 : 18}
											className={fullScreen ? "pl-8" : ""}
											onClick={() =>
												item.type === "PARENT_INVITE" ||
												item.type === "OWNER_ORGANIZATION" ||
												item.type === "ADMIN_ORGANIZATION" ||
												item.type === "TEACHER_ORGANIZATION"
													? null
													: this.navigateTo(item)
											}
										>
											<Row>
												<Text
													fontSize="14"
													lineHeight="22"
													wheight="semibold"
													className="color-gray-8"
													maxLength={50}
												>
													{item.title}
												</Text>
											</Row>
											{item.type !== "ENROLLMENT_STATUS_CHANGE" && (
												<Row>
													<Text
														fontSize="14"
														lineHeight="22"
														className="color-gray-8"
														maxLength={
															item.type === "PARENT_INVITE" ||
															item.type === "OWNER_ORGANIZATION" ||
															item.type === "ADMIN_ORGANIZATION" ||
															item.type === "TEACHER_ORGANIZATION"
																? 250
																: 50
														}
													>
														{item.message}
													</Text>
												</Row>
											)}
											{item.type === "PARENT_INVITE" && (
												<Row className="pt-4 pb-4">
													{item.accepted && (
														<Text fontSize="12" lineHeight="20" className="color-green-6">
															{translate(`${TRANSLATION_BASE_PATH}._INVITE_ACCEPTED`)}
														</Text>
													)}
													{item.rejected && (
														<Text fontSize="12" lineHeight="20" className="color-red-6">
															{translate(`${TRANSLATION_BASE_PATH}._INVITE_REJECTED`)}
														</Text>
													)}
													{item.rejectedByParent && (
														<Text fontSize="12" lineHeight="20" className="color-red-6">
															{translate(
																`${TRANSLATION_BASE_PATH}._INVITE_REJECTED_BY_PARENT`
															)}
														</Text>
													)}
													{!item.accepted && !item.rejected && !item.rejectedByParent && (
														<Row>
															<Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18}>
																<Button
																	className="background-color-green-6"
																	onClick={() => this.acceptParentInvite(item)}
																>
																	<Text
																		fontSize="14"
																		lineHeight="20"
																		className="color-gray-2"
																	>
																		{translate(
																			`${TRANSLATION_BASE_PATH}._INVITE_ACCEPT`
																		)}
																	</Text>
																</Button>
															</Col>
															<Col xs={0} sm={6} md={6} lg={6} xl={6} xxl={6}>
																<Button onClick={() => this.rejectParentInvite(item)}>
																	{translate(
																		`${TRANSLATION_BASE_PATH}._INVITE_REJECT`
																	)}
																</Button>
															</Col>
															<Col
																xs={24}
																sm={0}
																md={0}
																lg={0}
																xl={0}
																xxl={0}
																className="pt-4"
															>
																<Button onClick={() => this.rejectParentInvite(item)}>
																	{translate(
																		`${TRANSLATION_BASE_PATH}._INVITE_REJECT`
																	)}
																</Button>
															</Col>
														</Row>
													)}
												</Row>
											)}
											{(item.type === "OWNER_ORGANIZATION" ||
												item.type === "ADMIN_ORGANIZATION" ||
												item.type === "TEACHER_ORGANIZATION") &&
												this.organizationInvitationNotification(item)}
											<Row>
												<Text fontSize="12" lineHeight="20" className="color-gray-7">
													{getTimeLabel(item.createdAt)}
												</Text>
											</Row>
										</Col>
										<Col span={fullScreen && !item.markedAsSeen ? 4 : 3}>
											<Row className="notification__actions" justify="end" align="middle">
												<Space size={fullScreen ? "middle" : "small"}>
													{!item.processing && (
														<>
															<Text
																onClick={() => {
																	this.delete(item);
																}}
																fontSize="20"
																lineHeight="24"
																className="color-gray-8 cursor_pointer"
															>
																<Icon
																	type="ri-close-line"
																	className={
																		fullScreen ? "" : "notification__delete__icon"
																	}
																	tooltip={translate(
																		`${TRANSLATION_BASE_PATH}._POPOVER._TOOLTIP_DELETE`
																	)}
																/>
															</Text>
															{!item.markedAsSeen && (
																<Text fontSize="20" lineHeight="24">
																	<span className="notification__mark__as__seen" />
																</Text>
															)}
														</>
													)}
													{item.processing && (
														<LoadingOutlined style={{ fontSize: 12 }} spin />
													)}
												</Space>
											</Row>
										</Col>
									</Row>
								);
							})}
					{(!this.state.notifications || this.state.notifications.length === 0) && (
						<Empty description={translate(`${TRANSLATION_BASE_PATH}._POPOVER._NOT_FOUND`)} />
					)}
					{this.state.notifications && !this.state.downloadedAll && (
						<>
							<Divider className="mb-8"></Divider>
							<Row
								justify="center"
								className="cursor_pointer notification__item"
								onClick={() => {
									this.downloadAllNotifications();
								}}
							>
								<Text fontSize="14" lineHeight="22" className="pt-4 pb-4 color-blue-6">
									{translate(`${TRANSLATION_BASE_PATH}._POPOVER._SHOW_ALL`)}
								</Text>
							</Row>
						</>
					)}
				</div>
			</Skeleton>
		);
	}

	navigateTo = async (notification: NotificationDto) => {
		const { t: translate } = this.props;
		const notificationPart = notification?.navigateUrl?.split("/") || [];
		let isVirtualClass = "";
		if (notification?.type !== "ENROLLMENT_STATUS_CHANGE") {
			const lesson: any = await new LessonService().getLessonById(notificationPart[notificationPart.length - 1]);
			if (!lesson) {
				this.toggleNotifications(false);
				return showError(translate(`${TRANSLATION_BASE_PATH}._POPOVER._NOT_EXISTING`));
			}
			const subjectPlanTrees = lesson?.SubjectPlanTrees;
			const subjectPlan = subjectPlanTrees && subjectPlanTrees.length > 0 && subjectPlanTrees[0].SubjectPlan;
			const classroom = subjectPlan && subjectPlan?.Classroom;
			isVirtualClass = !classroom?.isAsync ? "?virtualClass=true" : "";
		}
		const destinationUrl = notification.navigateUrl ? "/" + notification.navigateUrl + isVirtualClass : "";
		return (window.location.href = destinationUrl);
	};

	get notificationBell() {
		return (
			<Badge count={this.state.countUnseen} overflowCount={9} offset={[2, -4]}>
				<Icon type="ri-notification-3-line" />
			</Badge>
		);
	}

	get fullScreen() {
		const getFullScreenTitle = () => {
			const { t: translate } = this.props;
			return (
				<Row className="p-16 title">
					<Col span="12">
						<Text fontSize="16" lineHeight="24" wheight="semibold">
							{translate(`${TRANSLATION_BASE_PATH}._POPOVER._TITLE`)}
						</Text>
					</Col>
					<Col span="12">
						<Row justify="end">
							<Text lineHeight="24" fontSize="24">
								<Icon onClick={() => this.toggleNotifications(true)} type="ri-close-line"></Icon>
							</Text>
						</Row>
					</Col>
				</Row>
			);
		};

		return (
			<>
				{getFullScreenTitle()}
				{this.getPopoverContent(true)}
			</>
		);
	}

	render() {
		return (
			<>
				<Row>
					<Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<Popover
							content={() => this.getPopoverContent(false)}
							title={this.popoverTitle}
							visible={this.state.shownPopup}
							trigger="click"
							onVisibleChange={() => this.toggleNotifications(false)}
							className="cursor_pointer"
							placement="bottomRight"
							arrowPointAtCenter
						>
							{this.notificationBell}
						</Popover>
					</Col>
					<Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0} onClick={() => this.toggleNotifications(true)}>
						{this.notificationBell}
					</Col>
				</Row>
				{this.state.shownModal && <div className="fullScreen_notification__container">{this.fullScreen}</div>}
			</>
		);
	}
}

const Notifications = withTranslation()(NotificationsComponent);
export default Notifications;
