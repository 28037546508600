import { v4 as uuidv4 } from "uuid";

export const deleteElement = (element: any, elements: any[]) => {
	const index = elements.findIndex(entry => entry.id === element.id);
	elements.splice(index, 1);
};

export const saveElement = (element: any, elements: any[]) => {
	if (element.id === undefined) {
		element.id = uuidv4();
		elements.push(element);
	} else {
		const index = elements.findIndex(entry => entry.id === element.id);
		if (index > -1) {
			elements.splice(index, 1, element);
		}
	}
};

export const treeToArray = (treeData: any) => {
	const toSeeQueue = [];
	toSeeQueue.push(treeData);
	const seen = [];

	while (toSeeQueue.length !== 0) {
		const seenItem: any = toSeeQueue.pop();
		if (seenItem.children.length !== 0) {
			toSeeQueue.push(...seenItem.children);
		}
		seen.push(seenItem);
	}
	return seen;
};
