import React from "react";

interface StringObject<T> {
	[key: string]: T
}

interface PropsInterface {
	classNames: StringObject<StringObject<string>>,
}

/**
 * Works like this
 * {".abc, .cde": {"main-color": "red", "second-color": "blue"}} -> .abc, .cde {--main-color: red;--second-color: blue}
 * @param props
 * @constructor
 */
export default function RawCss(props: PropsInterface) {
	let str = "";

	for (const i in props.classNames) {
		str += `${i} {`;
		for (const j in props.classNames[i]) {
			str += `--${j}: ${props.classNames[i][j]};`;
		}

		str += "}";
	}

	return (
		<div
			style={{display: "none"}}
			dangerouslySetInnerHTML={{
				__html: `<style>${str}</style>`
			}}
		/>
	);
}
