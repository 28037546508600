import { Badge, Col, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CircularIcon } from "../../../../../../../common/components/general/CircularIcon";
import { EmptyState } from "./emptyState/EmptyState";
import Text from "common/components/general/Text";
import { AdminTable } from "../../../../../../admin/components/table/table";
import { TableRowNavLink } from "../../../../../../admin/components/tableRowNavLink/TableRowNavLink";
import { UserAvatar } from "../../../../../../../common/components/general/UserAvatar";
import { PriceTag } from "../../../../../../subjects/scenes/subjectItems/scenes/marketplace/components/_courseView/components/PriceTag";
import { truncate } from "../../../../../../../common/utils/StringUtils";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT._VIRTUAL_CLASSES_TAB";

export const VirtualClassesTab = (props: any) => {
	const { t: translate } = useTranslation();

	const RenderTable = () => {
		const getNameColumn = {
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._NAME`),
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Row>
						<Space size="middle">
							<Col>
								<CircularIcon type="ri-slideshow-3-line" />
							</Col>
							<Col>
								<Row>{text}</Row>
								<Row>
									<HtmlInput
										name="description"
										readOnly={true}
										value={truncate(row.description, 300, true)??""}
										className="color-gray-7"
									/>
								</Row>
							</Col>
						</Space>
					</Row>
				);
			}
		};

		const getAuthorColumn = {
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._AUTHOR`),
			dataIndex: "createdBy",
			key: "createdBy",
			render: (text: any, row: any) => {
				return (
					<Row>
						<Space direction="horizontal" size={8}>
							<UserAvatar
								userId={text}
								firstName={row.UserCreatedBy.firstName}
								lastName={row.UserCreatedBy.lastName}
							/>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{row.UserCreatedBy.firstName} {row.UserCreatedBy.lastName}
							</Text>
						</Space>
					</Row>
				);
			}
		};

		const getPriceColumn = {
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._PRICE`),
			dataIndex: "price",
			key: "price",
			render: (text: any) => {
				return <PriceTag price={text} />;
			}
		};

		const getSubjectColumn = {
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._SUBJECT`),
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<>
						<Row>
							{row.GradeSubject.Subject.name} {row.GradeSubject.Grade.name.split(" ")[1]}
						</Row>
						<Row>
							<Text fontSize="12" lineHeight="20" className="color-gray-7">
								{translate(
									`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._SUBJECT_GRADE_LEVEL_${row.GradeSubject.Grade.level}`
								)}
							</Text>
						</Row>
					</>
				);
			}
		};

		const getStatusColumn = {
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS`),
			dataIndex: "status",
			key: "status",
			filters: [{
				text: (
					<Badge
						color={"#52c41a"}
						text={translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_ACTIVE`)}
					/>
				),
				value: "ACTIVE"
			},
				{
					text: (
						<Badge
							color={"#98a9c0"}
							text={translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_ARCHIVED`)}
						/>
					),
					value: "ARCHIVED"
				}],
			onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
			render: (status: any, row: any) => {
				return (
					<Row>
						<Badge
							color={status === "ACTIVE" ? "#52c41a" : "#98a9c0"}
							text={translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_${status}`)}
						/>
					</Row>
				);
			}
		};

		const getNavigationColumn = {
			title: "",
			dataIndex: "id",
			key: "id",
			align: "center",
			render: (id: any, row: any) => {
				return <TableRowNavLink to={`/course-builder/_teacher/${id}`} />;
			}
		};

		return (
			<>
				<Row>
					<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
						<AdminTable
							dataSource={props.classes}
							columns={[getNameColumn,
								getSubjectColumn,
								getStatusColumn,
								getAuthorColumn,
								getPriceColumn,
								getNavigationColumn]}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
						<AdminTable dataSource={props.classes} columns={[getNameColumn, getNavigationColumn]} />
					</Col>
				</Row>
			</>
		);
	};

	return props.classes.length > 0 ? RenderTable() : <EmptyState />;
};
