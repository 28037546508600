import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Alert from "common/components/feedback/Alert";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import { formValidator } from "./ValidationSchema";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import { REGISTER_PATH } from "../register";
import { FORGET_PASSWORD_PATH } from "../forgetPassword";
import { JoinInstagramAlert } from "../../components/joinInstagramAlert";
import { getDomain, isReservedDomain } from "services/common/check-domain";
import { Layout } from "../../components/layout";
import Common from "./Common";

export const SIGN_IN_PATH = "login";
const TRANSLATION_BASE_PATH = "_LOGIN._SIGNIN";

interface FormComponentsProps {
	onSubmit: (values: any) => void;
	showAlertContent: () => ReactNode;
	predefinedEmail: string;
	submitting: boolean;
}

class SignInComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.FormComponent = this.FormComponent.bind(this);
	}

	FormComponent = ({onSubmit, showAlertContent, predefinedEmail, submitting}: FormComponentsProps) => {
		const { t: translate } = this.props;
		let showNotice: boolean | number = parseInt(this.props.pageComponents.loginForm?.specs?.showNotice);
		showNotice = isNaN(showNotice) || !!showNotice;
		let showRegister: boolean | number = parseInt(this.props.pageComponents.loginForm?.specs?.showRegister);
		showRegister = isNaN(showRegister) || !!showRegister;

		return (
			<>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={{ username: predefinedEmail }}
					onSubmit={onSubmit}
					isInitialValid={false}
				>
					{formik => (
						<form onSubmit={formik.handleSubmit} autoComplete="off">
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
									<Text fontSize="30" lineHeight="38" wheight="semibold">
										{translate(`${TRANSLATION_BASE_PATH}._SIGNIN_LABEL`)}
									</Text>
								</Col>
							</Row>
							{showNotice && (
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="mt-24">
										<Alert
											message={
												this.props.pageComponents.loginForm?.specs?.title ||
												translate(`${TRANSLATION_BASE_PATH}._SIGNIN_INFO_ALERT_TITLE`)
											}
											description={
												this.props.pageComponents.loginForm?.specs?.description ||
												translate(`${TRANSLATION_BASE_PATH}._SIGNIN_INFO_ALERT_DESCRIPTION`)
											}
											showIcon
										/>
									</Col>
								</Row>
							)}
							{showAlertContent()}
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18} className="mt-24">
									<Input
										name="username"
										label={translate(`${TRANSLATION_BASE_PATH}._USERNAME`)}
										size="large"
										autoComplete="username"
										disabled={!!predefinedEmail}
									/>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18}>
									<PasswordInput
										name="password"
										label={translate(`${TRANSLATION_BASE_PATH}._PASSWORD`)}
										showPopover={false}
										size="large"
										autoComplete="current-password"
									/>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18}>
									<Button
										type="primary"
										htmlType="submit"
										icon="ri-arrow-right-line"
										size="large"
										iconPosition="right"
										className="full_width_button"
										disabled={!formik.isValid}
										loading={submitting}
									>
										{translate(`${TRANSLATION_BASE_PATH}._LOGIN_LABEL`)}
									</Button>
								</Col>
							</Row>
							<Row gutter={24} justify="center" className="mt-24">
								<Col>
									<Text fontSize="14" lineHeight="22">
										<Link to={`/${SIGN_IN_PATH}/${FORGET_PASSWORD_PATH}`}>
											{translate(`${TRANSLATION_BASE_PATH}._FORGET_PASSWORD`)}
										</Link>
									</Text>
								</Col>
							</Row>
						</form>
					)}
				</Formik>
				{showRegister && (
					<Row justify="center">
						<Col>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._DONT_HAVE_ACCOUNT`)}{" "}
								<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>
									{translate(`${TRANSLATION_BASE_PATH}._REGISTER_HERE`)}
								</Link>
							</Text>
						</Col>
					</Row>
				)}
			</>
		);
	};

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<Common
				{...this.props}
				presentation={(props: any) => (
					<>
						{(getDomain(window.location.hostname) === "akademi.al" || isReservedDomain(window.location.hostname)) && <JoinInstagramAlert />}
						{hideCover ? (
							this.FormComponent(props)
						) : (
							<Layout {...this.props.pageComponents.loginCover?.specs}>
								{this.FormComponent(props)}
							</Layout>
						)}
					</>
				)}
			/>
		);
	}
}

const SignIn = withTranslation()(SignInComponent);
export default SignIn;
