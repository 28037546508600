import moment from "moment";
import * as Yup from "yup";

const TRANSLATION_BASE_PATH_KEY = "_COURSE_BUILDER._REQUIRED";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._QUIZ";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		quizName: Yup.string()
			.trim()
			.required(translate(TRANSLATION_BASE_PATH_KEY))
			.typeError(translate(TRANSLATION_BASE_PATH_KEY)),
		description: Yup.string().nullable(),
		questions: Yup.array()
			.min(1, translate(`${TRANSLATION_BASE_PATH}._ADD_QUESTION_TIP`)) // TODO ndrysho text
			.required(translate(TRANSLATION_BASE_PATH_KEY)),
		endDate: Yup.string()
			.trim()
			.nullable()
			.notRequired()
			.test("is-valid-date", translate(`${TRANSLATION_BASE_PATH}._DUE_DATE_IN_THE_PAST`), function(value) {
				return (
					value === undefined ||
					(moment(value, "YYYY-MM-DD HH:mm").isValid() === true &&
						moment(value, "YYYY-MM-DD HH:mm") >=
							moment()
								.seconds(0)
								.milliseconds(0))
				);
			})
	});
