import UserDto from "dtos/authentication/user/user.dto";

export class CreateNewLessonNotificationDto {
	classroomId!: string;
	lessonTitle!: string;
	lessonType!: string;
	recordId!: string;
	currentUser!: UserDto;

	constructor(classroomId: string, lessonTitle: string, lessonType: string, recordId: string, currentUser: UserDto) {
		this.classroomId = classroomId;
		this.lessonTitle = lessonTitle;
		this.lessonType = lessonType;
		this.recordId = recordId;
		this.currentUser = currentUser;
	}

	get teacherFullName() {
		return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
	}

	get notificationTitle() {
		if (this.lessonType === "ASSIGNMENT") {
			return `${this.teacherFullName} postoi një detyre të re.`;
		}
		if (this.lessonType === "QUIZ") {
			return `${this.teacherFullName} postoi një quiz të ri.`;
		}
		if (this.lessonType === "VIDEO") {
			return `${this.teacherFullName} postoi një video leksion të ri.`;
		}
		if (this.lessonType === "MEETING") {
			return `${this.teacherFullName} krijoi një klase live të re.`;
		}
		return `${this.teacherFullName} postoi një material të ri.`;
	}
}
