import React, { ReactNode } from "react";
import { Row, Col, Card, Empty, Tooltip, Progress, Space } from "antd";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import ClassPendingImg from "assets/images/class-pending.svg";

interface ClassSliderProps {
	title?: string;
	items: any[];
	defaultImg: string;
	noDataDesc: string;
}
class ClassSlider extends React.Component<ClassSliderProps, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			index: 0,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items.slice(0, this.decrementFromWindowSize)
		};
	}

	setInitialState() {
		this.setState({
			index: 0,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items
		});
	}

	componentDidMount() {
		window.addEventListener("resize", this.setInitialState.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setInitialState.bind(this));
	}

	scroll = (step: number) => {
		if (this.state.index + step >= 0) {
			this.setState({
				index: this.state.index + step,
				sliderLeftVisible: this.state.index + step > 0,
				sliderRightVisible: this.state.index + step < this.state.items.length - this.decrementFromWindowSize,
				currentItems: this.state.items.slice(
					this.state.index + step,
					this.state.index + this.decrementFromWindowSize + 1
				)
			});
		}
	};

	get decrementFromWindowSize(): number {
		let decrement = 1;
		if (window.innerWidth >= 992) {
			decrement = 4;
		} else if (window.innerWidth >= 768) {
			decrement = 3;
		} else if (window.innerWidth >= 576) {
			decrement = 2;
		}
		return decrement;
	}

	get LeftScrollButton() {
		const iconClass = "parent__statistics__classSlider__nav__icon parent__statistics__classSlider__nav__icon__left";
		const iconType = "ri-arrow-left-s-line";

		return (
			this.state.sliderLeftVisible && (
				<a className={iconClass} onClick={() => this.scroll(-1)}>
					<Icon type={iconType}></Icon>
				</a>
			)
		);
	}

	get RightScrollButton() {
		const iconClass =
			"parent__statistics__classSlider__nav__icon parent__statistics__classSlider__nav__icon__right";
		const iconType = "ri-arrow-right-s-line";

		return (
			this.state.sliderRightVisible && (
				<a className={iconClass} onClick={() => this.scroll(+1)}>
					<Icon type={iconType}></Icon>
				</a>
			)
		);
	}

	getPhoto(item: any) {
		return (
			<Image
				className="parent__statistics__classSlider__item__card__image"
				src={item.EnrollmentStatus === "PENDING" ? ClassPendingImg : item.FilePath ?? this.props.defaultImg}
			/>
		);
	}

	getProgressStats = (item: any) => {
		const totalCompleted = +item.CompletedVideo + +item.CompletedQuiz;
		const totalAvailable = +item.TotalVideo + +item.TotalQuiz;
		return (
			<>
				<Row>
					<Col span="20">
						<Progress
							strokeLinecap="square"
							percent={(totalCompleted / totalAvailable) * 100}
							showInfo={false}
						/>
					</Col>
					<Col span="4">
						<Row justify="end">
							<Text fontSize="12" lineHeight="20" className="color-gray-8">
								{totalCompleted}/{totalAvailable}
							</Text>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span="12" className="pt-4 color-gray-6">
						<Space>
							<Space>
								<Icon type="ri-play-circle-fill"></Icon>
								<Text fontSize="14" lineHeight="22">
									{item.TotalVideo}
								</Text>
							</Space>
							<Space>
								<Icon type="ri-question-fill"></Icon>
								<Text fontSize="14" lineHeight="22">
									{item.TotalQuiz}
								</Text>
							</Space>
						</Space>
					</Col>
					{/* <Col span="12">
						<Row justify="end">
							<Space className="cursor_pointer">
								<Text fontSize="16" lineHeight="24" className="color-blue-6">
									Detaje
								</Text>
								<Icon type="ri-arrow-right-line" className="color-blue-6" fontSize="20"></Icon>
							</Space>
						</Row>
					</Col> */}
				</Row>
			</>
		);
	};

	getItem(item: any): ReactNode {
		return (
			<>
				<Tooltip
					title={item.EnrollmentStatus === "PENDING" ? "Dërguar " + item.requestDate : ""}
					placement="top"
				>
					<Row>{this.getPhoto(item)}</Row>
				</Tooltip>
				<Row>
					<Col span="18">
						<Row justify="start">
							<Text
								fontSize="12"
								lineHeight="20"
								className="mt-8 pl-8 pr-8 color-gray-7 background-color-gray-3"
							>
								Akademi.al
							</Text>
						</Row>
					</Col>
				</Row>
				<Row>
					<Text
						maxLength={item.ClassroomName !== undefined && item.ClassroomName?.length > 25 ? 25 : undefined}
						fontSize="16"
						lineHeight="24"
						className="color-gray-9"
					>
						{item.ClassroomName}
					</Text>
				</Row>
				{this.getProgressStats(item)}
			</>
		);
	}

	get Items(): ReactNode {
		return (
			this.state.currentItems &&
			this.state.currentItems.length > 0 &&
			this.state.currentItems.map((item: any) => {
				return (
					<Col key={item.ClassroomId} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
						{this.getItem(item)}
					</Col>
				);
			})
		);
	}

	get EmptyState(): ReactNode {
		return <Empty className="pb-16" description={this.props.noDataDesc}></Empty>;
	}

	render() {
		const Data = this.state.currentItems && this.state.currentItems.length > 0;
		const justifyItems = !Data ? "center" : "start";
		return (
			<Row justify="center">
				<Card className="parent__statistics__classSlider__card" bordered={false}>
					<Row justify={justifyItems} align="middle" gutter={[24, 24]}>
						{this.LeftScrollButton}
						{this.Items}
						{this.RightScrollButton}
						{!Data && this.EmptyState}
					</Row>
				</Card>
			</Row>
		);
	}
}

export default ClassSlider;
