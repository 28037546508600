import React from "react";
import Modal from "common/components/feedback/Modal";
import { Formik } from "formik";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Button from "common/components/general/Button";
import DateRange from "common/components/dataEntry/components/DateRange";

interface AddLessonModal {
	modalVisibility: boolean;
	addLesson: (name: string, description: string, duration: string[]) => void;
	closeModal: () => void;
}

export const AddLessonModal = (props: AddLessonModal) => {
	return (
		<Modal visible={props.modalVisibility} closable={true} footer={null} title={"Shto leksion"}>
			<Formik
				initialValues={{}}
				isInitialValid={false}
				onSubmit={(values: any, { resetForm }) => {
					props.addLesson(values.name, values.description, values.duration);
					resetForm({});
				}}
			>
				{formik => (
					<form onSubmit={formik.handleSubmit} autoComplete="off">
						<Row gutter={24}>
							<Col>
								<Text fontSize="20" lineHeight="28" wheight="semibold">
									Tema
								</Text>
							</Col>
							<Col>
								<Input name="name" label={"Java/Kapitulli"} />
							</Col>
						</Row>
						<Row gutter={24}>
							<Col>
								<Text fontSize="20" lineHeight="28" wheight="semibold">
									Pershkrimi
								</Text>
							</Col>
							<Col>
								<Input name="description" />
							</Col>
						</Row>
						<Row gutter={24}>
							<Col>
								<Text fontSize="20" lineHeight="28" wheight="semibold">
									Nisje dhe perfundimi
								</Text>
							</Col>
							<Col>
								<DateRange
									name="duration"
									onChange={(_, dateString) => formik.setFieldValue("duration", dateString)}
								/>
							</Col>
						</Row>
						<Row gutter={24} justify="end">
							<Col>
								<Button
									type="primary"
									htmlType="button"
									icon="ri-arrow-right-line"
									size="large"
									iconPosition="right"
									className="full_width_button"
									onClick={props.closeModal}
								>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button
									type="primary"
									htmlType="submit"
									icon="ri-arrow-right-line"
									size="large"
									iconPosition="right"
									className="full_width_button"
									disabled={!formik.isValid}
								>
									Shto
								</Button>
							</Col>
						</Row>
					</form>
				)}
			</Formik>
		</Modal>
	);
};
