import React, { ReactNode } from "react";
import { Layout as AntLayout } from "antd";
import IntroMessage from "../introMessage";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import { Header } from "./Header";

interface LayoutProps {
	children: ReactNode;
}

export const Layout = ({ children, ...rest }: LayoutProps) => {
	return (
		<AntLayout className="account__layout">
			<Header />
			<Row className="layout__content">
				<Col className="intro__message_content">
					<IntroMessage {...rest} />
				</Col>
				<Col className="account__form__content__wrapper">
					<Row>
						<Col className="account__form__content">{children}</Col>
					</Row>
				</Col>
			</Row>
		</AntLayout>
	);
};
