import React, { useState } from "react";
import { Avatar, Divider, message, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import CopyToClipboard from "react-copy-to-clipboard";

interface IntroductionInfoModalProps {
	classroom: any;
	onCancel: () => void;
	onInvite: () => void;
}

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW._INTRODUCTION_INFO_MODAL";

export const IntroductionInfoModal = (props: IntroductionInfoModalProps) => {
	const { t: translate } = useTranslation();
	const [visible, setVisible] = useState<boolean>(true);

	const onCancel = () => {
		setVisible(false);
		props.onCancel();
	};

	const onCopy = (textCopied: string) => {
		message.success(
			translate(`${TRANSLATION_BASE_PATH}._COPY_CLIPBOARD`, {
				code: `${textCopied}`
			})
		);
	};

	const footer = (
		<Space direction="horizontal">
			<Button type="default" onClick={onCancel}>
				{translate(`${TRANSLATION_BASE_PATH}._INVITE_LATER_BUTTON`)}
			</Button>
			<Button
				type="primary"
				onClick={() => {
					setVisible(false);
					props.onInvite();
				}}
			>
				{translate(`${TRANSLATION_BASE_PATH}._INVITE_STUDENTS_BUTTON`)}
			</Button>
		</Space>
	);

	const modalTitle = (
		<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
			{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
		</Text>
	);

	return (
		<Modal
			visible={visible}
			title={modalTitle}
			footer={footer}
			destroyOnClose
			onCancel={onCancel}
			className="classroom__introdaction_modal"
		>
			<div className="classroom__main_info">
				<Space direction="vertical" align="center" size={8} className="section__info">
					<Avatar
						icon={<Icon type="ri-door-open-line" fontSize="24" className="color-blue-6" />}
						size={40}
						className="background-color-blue-1"
					/>
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						{translate(`${TRANSLATION_BASE_PATH}._CLASS_NAME`)}
					</Text>
					<Text fontSize="20" lineHeight="28" className="color-gray-9">
						{props.classroom.name}
					</Text>
				</Space>
				<Space direction="vertical" align="center" size={8} className="section__info">
					<Avatar
						icon={<Icon type="ri-key-2-line" fontSize="24" className="color-blue-6" />}
						size={40}
						className="background-color-blue-1"
					/>
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						{translate(`${TRANSLATION_BASE_PATH}._CLASS_CODE`)}
					</Text>
					<Text fontSize="20" lineHeight="28" className="color-gray-9">
						{props.classroom.code}
					</Text>
					<CopyToClipboard text={props.classroom.code} onCopy={(textCopied: any) => onCopy(textCopied)}>
						<Text fontSize="14" lineHeight="22" className="color-blue-6 cursor_pointer">
							<Icon type="ri-file-copy-line" /> {translate(`${TRANSLATION_BASE_PATH}._COPY`)}
						</Text>
					</CopyToClipboard>
				</Space>
			</div>
			<Divider />
			<div className="classroom__welcome_info">
				<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9 pb-24">
					{translate(`${TRANSLATION_BASE_PATH}._WELCOME_MESSAGE_TITLE`)}
				</Text>
				<Text fontSize="14" lineHeight="22" className="color-gray-8 pb-24">
					{translate(`${TRANSLATION_BASE_PATH}._WELCOME_MESSAGE_DESCRIPTION`, {
						teacherName: props.classroom.UserCreatedBy.firstName,
						className: props.classroom.name
					})}
				</Text>
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._NEXT_STEP_DESCRIPTION`)}
				</Text>
			</div>
		</Modal>
	);
};
