import React, { ReactNode } from "react";
import { Row, Col } from "antd";

import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";

import { getImageUrl } from "../_utils/getImageUrl";

import { CourseItem } from "../../CourseItem";

class ClassSlider extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			index: 0,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.noOfItemsToShowFromScreenSize,
			currentItems: this.props.expandAll
				? this.props.items
				: this.props.items.slice(0, this.noOfItemsToShowFromScreenSize)
		};
	}

	setInitialState() {
		this.setState({
			index: 0,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.noOfItemsToShowFromScreenSize,
			currentItems: this.props.expandAll
				? this.props.items
				: this.props.items.slice(0, this.noOfItemsToShowFromScreenSize)
		});
	}

	componentDidMount() {
		window.addEventListener("resize", this.setInitialState.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setInitialState.bind(this));
	}

	scroll = (step: number) => {
		if (this.state.index + step >= 0) {
			this.setState({
				index: this.state.index + step,
				sliderLeftVisible: this.state.index + step > 0,
				sliderRightVisible:
					this.state.index + step < this.props.items.length - this.noOfItemsToShowFromScreenSize,
				currentItems: this.props.expandAll
					? this.props.items
					: this.props.items.slice(this.state.index + step).slice(0, this.noOfItemsToShowFromScreenSize)
			});
		}
	};

	get noOfItemsToShowFromScreenSize(): number {
		let decrement = 1;
		if (window.innerWidth > 992) {
			decrement = 3;
		} else if (window.innerWidth >= 576) {
			decrement = 2;
		}
		return decrement;
	}

	get LeftScrollButton() {
		const iconClass = "marketplace__classSlider__nav__icon marketplace__classSlider__nav__icon__left";

		return (
			this.state.sliderLeftVisible &&
			!this.props.expandAll && (
				<a className={iconClass} onClick={() => this.scroll(-1)}>
					<Icon type="ri-arrow-left-s-line" />
				</a>
			)
		);
	}

	get RightScrollButton() {
		const iconClass = "marketplace__classSlider__nav__icon marketplace__classSlider__nav__icon__right";

		return (
			this.state.sliderRightVisible &&
			!this.props.expandAll && (
				<a className={iconClass} onClick={() => this.scroll(+1)}>
					<Icon type="ri-arrow-right-s-line" />
				</a>
			)
		);
	}

	getPhoto(item: any) {
		return (
			<Image
				className="marketplace__classSlider__item__card__image"
				src={getImageUrl(this.props.imagePrefix, item.File)}
			/>
		);
	}

	get EmptyState(): ReactNode {
		return null;
	}

	get Items(): ReactNode {
		if (!(this.state.currentItems && this.state.currentItems.length > 0)) return this.EmptyState;
		return this.state.currentItems.map((item: any) => {
			return (
				<Col key={item.id} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
					<CourseItem {...item} />
				</Col>
			);
		});
	}

	render() {
		return (
			<div className="courses-slider">
				<Row gutter={[24, 24]} align="middle" className={"marketplace__classSlider"}>
					{this.LeftScrollButton}
					{this.Items}
					{this.RightScrollButton}
				</Row>
				<Row>
					<Dots />
				</Row>
			</div>
		);
	}
}

const Dots = ({ pages = 2, currentIndex = 0 }) => {
	return (
		<div className="dots">
			{Array.from(Array(pages).keys()).map((page, pageIndex) => (
				<span key={pageIndex} className={currentIndex === pageIndex ? "active" : ""}></span>
			))}
		</div>
	);
};

export default ClassSlider;
