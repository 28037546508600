import React from "react";
import { Checkbox as AntCheckbox } from "antd";

interface CheckboxProps {
	disabled?: boolean;
	onChange?: (e: any) => void;
	className?: string;
	id?: string;
	defaultChecked?: boolean;
	checked?: boolean;
	value?: string;
	showAsBox?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = props => {
	return (
		<AntCheckbox {...props} className={`${props.className} ${props.showAsBox && "checkbox__box__element"}`}>
			{props.children}
		</AntCheckbox>
	);
};

export default Checkbox;
