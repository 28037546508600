import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Formik, FormikHelpers, FormikValues } from "formik";

import { Settings } from "./settings";
import { Content } from "./content";
import { Card, Col, message, Row, Skeleton } from "antd";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Switch from "common/components/dataEntry/components/Switch";

import OrganizationDto from "dtos/administration/organization.dto";
import { PageComponent } from "dtos/administration/customize/PageComponent.dto";
import { OrganizationPageComponent } from "dtos/administration/customize/OrganizationPageComponent.dto";

import { PAGE_STATUS_PUBLISHED, PAGE_STATUS_UNPUBLISHED } from "../../utils/constants";
import PageComponentService from "services/design/pageComponent.service";
import OrganizationPageService from "services/design/organizationPage.service";
import OrganizationPageComponentService from "services/design/organizationPageComponent.service";
import { OrganizationPage } from "../../../../../../../../dtos/administration/customize/OrganizationPage.dto";
import Icon from "../../../../../../../../common/components/general/Icon";
import { v4 as uuidv4 } from "uuid";

interface MatchParams {
	organizationId: string;
	pageId: string;
	pageComponentId: string;
}

interface PropsInterface extends RouteComponentProps<MatchParams> {
	organizationDto: OrganizationDto;
}

export function EditPageComponent(props: PropsInterface) {
	const [loading, setLoading] = useState(true);
	const [pageComponent, setPageComponent] = useState<PageComponent | null>(null);
	const [organizationPage, setOrgaPage] = useState<OrganizationPage | null>(null);
	const [organizationPageComponent, setOrganizationPageComponent] = useState<OrganizationPageComponent | null>(null);

	useEffect(() => {
		/**
		 * Loads the possible existing relation between an organization and this page
		 */
		async function loadOrganizationPage(organizationId: string, pageId: string): Promise<OrganizationPage | null> {
			return new OrganizationPageService()
				.find(organizationId, pageId)
				.then((res: OrganizationPage) => {
					setOrgaPage(res);
					return res;
				})
				.catch(err => {
					console.log(err.message);
					return null;
				});
		}

		/**
		 * Loads the dynamic input map for this page component
		 */
		async function loadSpecTypes(pageComponentId: string) {
			return new PageComponentService()
				.find(pageComponentId)
				.then((res: PageComponent) => {
					return setPageComponent(res);
				})
				.catch(err => {
					console.log(err);
				});
		}

		/**
		 * Loads the page component data, for this organization, if any
		 */
		async function loadOrganizationPageComponent(organizationPagePropId: string, pageComponentId: string) {
			return new OrganizationPageComponentService()
				.findBy(organizationPagePropId, pageComponentId)
				.then((res: OrganizationPageComponent) => {
					return setOrganizationPageComponent(res);
				})
				.catch(err => {
					console.log(err);
				});
		}

		loadOrganizationPage(props.match.params.organizationId, props.match.params.pageId)
			.then((organizationPageProp: OrganizationPage | null) => {
				return Promise.all([
					loadSpecTypes(props.match.params.pageComponentId),
					loadOrganizationPageComponent(organizationPageProp?.id || "-1", props.match.params.pageComponentId)
				]);
			})
			.then(() => {
				return setLoading(false);
			})
			.catch(err => {
				console.log(err.message);
				return setLoading(false);
			});
	}, []);

	/**
	 *
	 * @param field
	 * @returns
	 */
	function getValues(field: "content" | "settings"): object {
		if (!pageComponent) {
			return [];
		}

		return pageComponent.specs[field].reduce(function(acc, item) {
			const specs = organizationPageComponent?.specs || {};
			acc[item.var] = specs[item.var] || "";

			return acc;
		}, {} as { [key: string]: string });
	}

	function getAllFieldsAndValues(): object {
		return _.merge(getValues("settings"), getValues("content"));
	}

	async function updateData(dataObj: Partial<OrganizationPageComponent>) {
		if (!pageComponent) {
			message.error("Failed to load page component please refresh");
			return;
		}

		// If there is no organization page relation then create one
		let orgaPage;
		if (!organizationPage) {
			orgaPage = await new OrganizationPageService().save({
				id: uuidv4(),
				status: PAGE_STATUS_PUBLISHED,
				pageId: props.match.params.pageId,
				organizationId: props.match.params.organizationId
			});
			setOrgaPage(orgaPage);
		}

		const data: Partial<OrganizationPageComponent> = {
			pageComponentId: pageComponent.id,
			organizationPageId: organizationPage?.id || orgaPage?.id,
			id: organizationPageComponent?.id,
			...dataObj
		};

		return new OrganizationPageComponentService()
			.updateOrCreate(data)
			.then(res => {
				return setOrganizationPageComponent(res);
			})
			.catch(err => {
				message.error("Failed saving the data, please refresh");
				console.log(err.message);
			});

	}

	async function saveForm(val: FormikValues, actions: FormikHelpers<object>) {
		if (!pageComponent) {
			message.error("Failed to load page component please refresh");
			return;
		}
		const values = Object.assign({}, val);

		// Remove tags when you leave text editor empty
		[...pageComponent.specs.settings || [], ...pageComponent.specs.content || []].forEach((item) => {
			if (item.type === "htmlText") {
				if (values[item.var] === "<p><br></p>" || values[item.var] === "<p>\t</p>") {
					values[item.var] = "";
				}
			}
		}, []);

		actions.setSubmitting(true);

		return updateData({ status: organizationPageComponent?.status || PAGE_STATUS_PUBLISHED, specs: values })
			.then(() => {
				actions.setSubmitting(false);
				message.success("Data saved successfully");
				return;

			})
			.catch(err => {
				console.log(err.message);
				message.error("Failed saving data please refresh");
			});
	}

	return (
		<>
			<Row>
				<Col xs={24} span={24}>
					<Skeleton loading={loading}>
						<Formik initialValues={getAllFieldsAndValues()} onSubmit={saveForm}>
							{formProps => (
								<form onSubmit={formProps.handleSubmit}>
									{pageComponent && (
										<Row>
											<Col xs={24}>
												<Card>
													<Row justify="space-between">
														<Col>
															{!organizationPageComponent?.id && (
																<Icon
																	tooltip="Warning! Component have the default data."
																	type="ri-information-line"
																	className="color-orange-6 mt-4 mr-4"
																	fontSize="16"
																/>
															)}

															<Text
																fontSize="20"
																lineHeight="22"
																className="color-gray-9"
															>
																{pageComponent.title}
															</Text>
														</Col>
														<Col>
															{organizationPageComponent && (
																<Switch
																	onChange={checked =>
																		updateData({
																			status: checked
																				? PAGE_STATUS_PUBLISHED
																				: PAGE_STATUS_UNPUBLISHED
																		})
																	}
																	checked={
																		organizationPageComponent?.status ===
																		PAGE_STATUS_PUBLISHED
																	}
																/>
															)}
														</Col>
													</Row>
												</Card>
											</Col>
											{pageComponent.specs.settings.length > 0 && <Col xs={24}>
												<Settings
													organizationId={props.match.params.organizationId}
													settings={pageComponent.specs.settings}
													values={organizationPageComponent?.specs || {}}
												/>
											</Col>}

											{pageComponent.specs.content.length > 0 && <Col xs={24}>
												<Content
													organizationId={props.match.params.organizationId}
													settings={pageComponent.specs.content}
													values={organizationPageComponent?.specs || {}}
												/>
											</Col>}
										</Row>
									)}
									<Card>
										<Button type="default" onClick={() => formProps.handleSubmit()}>
											Save
										</Button>
									</Card>
								</form>
							)}
						</Formik>
					</Skeleton>
				</Col>
			</Row>
		</>
	);
}
