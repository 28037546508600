import moment from "moment";
import i18n from "i18next";

const TRANSLATION_BASE_PATH = "_COMPONENTS._NEWS_FEEDER";

export const getTimeLabel = (value?: string) => {
	const time = moment(value ?? moment.now());
	const now = moment(moment.now());
	const diffInMinutes = moment.duration(now.diff(time)).asMinutes();

	let label = moment(value ?? moment.now()).format("DD-MM-YYYY HH:mm");

	if (diffInMinutes < 1) {
		label = i18n.t(`${TRANSLATION_BASE_PATH}._BEFORE_SOME_SECONDS`);
	} else if (diffInMinutes <= 60) {
		const value = parseInt(diffInMinutes.toString());
		const minutes =
			value > 1
				? `${value} ${i18n.t(`${TRANSLATION_BASE_PATH}._MINUTES`)}`
				: `${value} ${i18n.t(`${TRANSLATION_BASE_PATH}._MINUTE`)}`;
		label = i18n.t(`${TRANSLATION_BASE_PATH}._BEFORE_SOME_MINUTES`, {
			minutes: minutes
		});
	} else if (diffInMinutes <= 60 * 24) {
		const hours = parseInt((diffInMinutes / 60).toString());
		const minutes = parseInt(((diffInMinutes / 60 - hours) * 60).toString());
		const hoursString =
			hours > 1
				? `${hours} ${i18n.t(`${TRANSLATION_BASE_PATH}._HOURS`)}`
				: `${hours} ${i18n.t(`${TRANSLATION_BASE_PATH}._HOUR`)}`;
		const minutesString =
			minutes > 1
				? `${minutes} ${i18n.t(`${TRANSLATION_BASE_PATH}._MINUTES`)}`
				: `${minutes} ${i18n.t(`${TRANSLATION_BASE_PATH}._MINUTE`)}`;
		label = i18n.t(`${TRANSLATION_BASE_PATH}._BEFORE_SOME_HOURS`, {
			hours: hoursString,
			minutes: minutesString
		});
	} else if (diffInMinutes <= 60 * 24 * 2) {
		label = i18n.t(`${TRANSLATION_BASE_PATH}._YESTERDAY`);
	}

	return label;
};