import ApiServiceData from "../api.service";
import { AllUserSettingsDto } from "dtos/authentication/userSettings/allSettings.dto";
import { UpdateAvatarDto } from "dtos/administration/updateAvatar.dto";

const relativeUrl = "/v1/api/user-settings";

export default class UserSettingsService extends ApiServiceData {
	public getSettings(): Promise<AllUserSettingsDto> {
		return this.get(relativeUrl)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateAvatar(data: UpdateAvatarDto): Promise<any> {
		return this.update(`${relativeUrl}/update-avatar`, data)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAvatar(userId: string): Promise<any> {
		return this.get(`${relativeUrl}/get-avatar/${userId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
