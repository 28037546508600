import React, { ReactNode } from "react";
import { Card as AntCard } from "antd";

interface CardProps {
	size?: "default" | "small";
	title?: string | ReactNode;
	children: ReactNode;
	extra?: string | ReactNode;
	className?: string;
	cover?: ReactNode
}

const Card = (props: CardProps) => {
	return <AntCard {...props}>{props.children}</AntCard>;
};

export default Card;
