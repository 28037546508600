import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Button from "common/components/general/Button";
import MarketplaceIcon from "assets/images/instructor-banner-icon.svg";
import InstructorApplyModal from "./components/instructorApplyModal";

const TRANSLATION_BASE_PATH = "_MARKETPLACE._INSTRUCTOR_BANNER";

type InstructionBannerProps = {
	isPublic?: boolean
}

export const InstructorBanner = ({ isPublic = false }: InstructionBannerProps) => {
	const { t: translate } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<div className="marketplace__instructor_banner">
				<Col>
					<Row className="mb-4">
						<Col span="24" className="instructor_banner__title">
							<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-2">
								{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-16">
						<Col span="24" className="instructor_banner__description">
							<Text fontSize="14" lineHeight="22" className="color-gray-5">
								{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col span="24">
							<Button
								className="instructor_banner__button"
								type="primary"
								icon="ri-arrow-right-line"
								iconPosition="right"
								onClick={() => setIsModalOpen(true)}
							>{translate(`${TRANSLATION_BASE_PATH}._BUTTON`)}</Button>
						</Col>
					</Row>
				</Col>
				<Image src={MarketplaceIcon} className="instructor_banner__icon" />
			</div>
			{isModalOpen && <InstructorApplyModal onCancel={() => setIsModalOpen(false)} isPublic={isPublic} />}
		</>
	);
};
