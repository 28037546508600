import ApiServiceData from "../api.service";
import AssignmentUserCommitDto from "dtos/administration/assignmentUserCommit.dto";
const relativeUrl = "/v1/api/assignment-user-commit";

export default class AssignmentUserCommitService extends ApiServiceData {
	public getAssignmentUserCommitByLessonId(lessonId: string): Promise<AssignmentUserCommitDto> {
		return this.get(relativeUrl + "/lesson/" + lessonId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "No Assignment User Commit found."
				)
					return null;
				throw error.response;
			});
	}

	public publishUserAssignementGrades(lessonId: string): Promise<AssignmentUserCommitDto> {
		return this.update(relativeUrl + "/lesson/" + lessonId, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "No Assignment User Commit found."
				)
					return null;
				throw error.response;
			});
	}

	public updateAssignementUserCommitByUserIdLessonId(
		assignmentUserCommitId: string,
		updatedAssignementUserCommitDto: any
	) {
		return this.update(relativeUrl + "/" + assignmentUserCommitId, updatedAssignementUserCommitDto).catch(error => {
			if (
				error &&
				error.response &&
				error.response.data &&
				error.response.data.message === "No Assignment User Commit found."
			)
				return null;
			throw error.response;
		});
	}

	public getAssignmentUserCommitByUserIdLessonId(userId: string, lessonId: string): Promise<AssignmentUserCommitDto> {
		return this.get(relativeUrl + "/" + userId + "/" + lessonId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "No Assignment User Commit found."
				)
					return null;
				throw error.response;
			});
	}

	public getAssignmentUserCommitByUserId(): Promise<AssignmentUserCommitDto[]> {
		return this.get(relativeUrl + "/user")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createAssignmentUserCommit(assignmentUserCommit: AssignmentUserCommitDto) {
		return this.post(relativeUrl, assignmentUserCommit)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Due date has passed."
				)
					return false;
				throw error.response;
			});
	}

	public deleteAssignmentUserCommit(assignmentUserCommitId: string) {
		return this.delete(`${relativeUrl}/${assignmentUserCommitId}`).catch(error => {
			throw error.response;
		});
	}
}
