import React from "react";
import { Pagination as AntdPagination } from "antd";

import styled from "styled-components";

export const Pagination = (props: any) => {
	return <StyledPagination {...props} />;
};

const StyledPagination = styled(AntdPagination)`
	display: flex;
	justify-content: center;

	.ant-pagination {
		display: flex;
		justify-content: center;
		column-gap: 13px;
		> li {
			margin: 0;
		}
	}
	.ant-pagination-item {
		border: 0;
		background: none;
		border-radius: 0;
		min-width: 16px;
		> a {
			width: 16px;
			padding: 0;
		}
		&.ant-pagination-item-active {
			> a {
				border-bottom: 2px solid #2063e3;
			}
		}
	}
	.ant-pagination-next,
	.ant-pagination-prev {
		> button {
			border-radius: 50%;
			line-height: 15px;
			font-size: 10px;
		}
	}
`;
