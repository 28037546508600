import ApiServiceData from "../api.service";
import { getValue } from "./util/GetValue";

const relativeUrl = "/v1/api/analytics/organizations";

export default class AnalyticsOrganizationService extends ApiServiceData {

	public async countAllOrganizations(): Promise<number> {
		return this.get(relativeUrl + "/count")
			.then(res => {
				return getValue(res.data, "", undefined);
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countAllOrganizationsByCountry(): Promise<any> {
		return this.get(relativeUrl + "/count/byCountry")
			.then(res => {
				return [getValue(res.data, "nationality", "ALBANIA"),
					getValue(res.data, "nationality", "KOSOVO")];
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getOrganizationsTotals(): Promise<any> {
		return this.get(relativeUrl + "/totals")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getOrganizationTotals(organizationId: string): Promise<any> {
		return this.get(relativeUrl + "/totals/" + organizationId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countOrganizationTotalStudents(organizationId: string): Promise<any> {
		return this.get(relativeUrl + "/totalStudents/" + organizationId)
			.then(res => {
				console.log(res.data);
				return getValue(res.data, "", undefined);
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getFinancialReport(dateSegment: string[]) {
		return this.post(relativeUrl + "/generateFinancialReport", dateSegment)
			.then(() => {
				return;
			})
			.catch(error => {
				throw error.response;
			});
	}	
}
