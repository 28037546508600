import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { Layout } from "./components/layout";
import SignIn, { SIGN_IN_PATH } from "./scenes/signIn/index";
import Register, { REGISTER_PATH } from "./scenes/register/index";
import ForgetPassword, { FORGET_PASSWORD_PATH } from "./scenes/forgetPassword/index";
import RegisterEmailVerification, { REGISTER_EMAIL_VERIFICATION_PATH } from "./scenes/registerEmailVerification";
import ConfirmRegistrationEmail, { CONFIRM_EMAIL_REGISTRATION_PATH } from "./scenes/confirmRegistrationEmail";
import ForgetPasswordEmailVerification, {
	FORGET_PASSWORD_EMAIL_VERIFICATION_PATH
} from "./scenes/forgetPasswordEmailVerification";
import ChangePassword, { RESET_PASSWORD_PATH } from "./scenes/changePassword";
import PasswordChangedSuccessfully, { PASSWORD_CHANGED_SUCCESSFULLY_PATH } from "./scenes/passwordChangedSuccessfully";
import { NotFound } from "common/components/feedback/NotFound";
import { TermsOfService, TERMS_OF_SERVICE_PATH } from "./scenes/terms/TermsOfService";
import { PrivacyPolicy, PRIVACY_POLICY_PATH } from "./scenes/terms/PrivacyPolicy";
import DynamicLayout from "../../common/components/utils/DynamicLayout";
import Login1 from "./scenes/signIn/login1";

class Login extends Component {
	render() {
		return (
			<Switch>
				<Route
					path={`/${SIGN_IN_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							templateName="login"
							Pages={{
								index: SignIn,
								login1: Login1
							}}
							DefaultPage={SignIn}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${REGISTER_PATH}/:code`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: Register
							}}
							DefaultPage={Register}
							templateName={"register"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: Register
							}}
							DefaultPage={Register}
							templateName={"register"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${REGISTER_EMAIL_VERIFICATION_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: RegisterEmailVerification
							}}
							DefaultPage={RegisterEmailVerification}
							templateName={"register"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${TERMS_OF_SERVICE_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: TermsOfService
							}}
							DefaultPage={TermsOfService}
							templateName={"register"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${PRIVACY_POLICY_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: PrivacyPolicy
							}}
							DefaultPage={PrivacyPolicy}
							templateName={"register"}
						/>
					)}
				/>

				<Route
					path={`/${SIGN_IN_PATH}/${CONFIRM_EMAIL_REGISTRATION_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: ConfirmRegistrationEmail
							}}
							DefaultPage={ConfirmRegistrationEmail}
							templateName={"register"}
						/>
					)}
				/>

				<Route
					path={`/${SIGN_IN_PATH}/${FORGET_PASSWORD_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: ForgetPassword
							}}
							DefaultPage={ForgetPassword}
							templateName={"register"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${FORGET_PASSWORD_EMAIL_VERIFICATION_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: ForgetPasswordEmailVerification
							}}
							DefaultPage={ForgetPasswordEmailVerification}
							templateName={"register"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/${RESET_PASSWORD_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: ChangePassword
							}}
							DefaultPage={ChangePassword}
							templateName={"register"}
						/>
					)}
				/>

				<Route
					path={`/${SIGN_IN_PATH}/${PASSWORD_CHANGED_SUCCESSFULLY_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							Pages={{
								index: PasswordChangedSuccessfully
							}}
							DefaultPage={PasswordChangedSuccessfully}
							templateName={"login"}
						/>
					)}
				/>
				<Route
					path={`/${SIGN_IN_PATH}/:code`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							templateName="login"
							Pages={{
								index: SignIn,
								login1: Login1
							}}
							DefaultPage={SignIn}
						/>
					)}
				/>
				<NotFound />
			</Switch>
		);
	}
}

export default Login;
