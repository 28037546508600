import React from "react";
import { useTranslation } from "react-i18next";
import TutoringWelcome from "assets/images/tutoring-welcome.svg";
import Icon from "common/components/general/Icon";
import { Row, Space } from "antd";
import Button from "common/components/general/Button";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";

type Props = {
	next: () => void,
	onCancel: () => void,
}

export const Welcome = (props: Props) => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const isTeacher = currentUserInfo?.Roles.some((role: any) => role.code === Role.Teacher);
	const TRANSLATION_BASE_PATH = isTeacher ? "_TUTORING._WELCOME_TEACHER" : "_TUTORING._WELCOME_STUDENT";
	const { t: translate } = useTranslation();
	const { next, onCancel } = props;
	return (
		<Modal
			className="tutoring__welcome_card"
			visible
			title=""
			footer=""
			width="480px"
			bodyStyle={{ padding: 0 }}
			onCancel={() => onCancel()}
		>
			<img width="100%" src={TutoringWelcome} alt='' />
			<div className="p-24">
				<p className="tutoring__welcome_title">{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</p>
				<p className="tutoring__welcome_subtitle">{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}</p>
				<Row align="middle">
					<Space>
						<Icon className="tutoring__welcome_icons" type="ri-checkbox-circle-line" />
						<Text>{translate(`${TRANSLATION_BASE_PATH}._FEATURE_1`)}</Text>
					</Space>
				</Row>
				<Row align="middle">
					<Space>
						<Icon className="tutoring__welcome_icons" type="ri-checkbox-circle-line" />
						<Text>{translate(`${TRANSLATION_BASE_PATH}._FEATURE_2`)}</Text>
					</Space>
				</Row>
				<Row align="middle">
					<Space>
						<Icon className="tutoring__welcome_icons" type="ri-checkbox-circle-line" />
						<Text>{translate(`${TRANSLATION_BASE_PATH}._FEATURE_3`)}</Text>
					</Space>
				</Row>
				{!isTeacher && (
					<Row align="middle">
						<Space>
							<Icon className="tutoring__welcome_icons" type="ri-checkbox-circle-line" />
							<Text>{translate(`${TRANSLATION_BASE_PATH}._FEATURE_4`)}</Text>
						</Space>
					</Row>
				)}
				<Row className="mt-16" align="middle" justify="center">
					<Button type="primary" size="middle" onClick={() => next()}>
						<Row align="middle">
							{translate(`${TRANSLATION_BASE_PATH}._BTN`)}
							<Icon className="ml-8" type="ri-arrow-right-line" />
						</Row>
					</Button>
				</Row>
			</div>
		</Modal>
	);
};