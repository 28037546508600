import { notification } from "antd";
import i18n from "i18next";

declare type notificationType = "success" | "error" | undefined;

export const showMessage = (message?: string) => {
	showNotification(message ?? i18n.t("_ADMIN._GENERIC._DEFAULT_SUCCESS_MESSAGE"), "success");
};

export const showError = (message?: string) => {
	showNotification(message ?? i18n.t("_ADMIN._GENERIC._DEFAULT_ERROR_MESSAGE"), "error");
};

const showNotification = (message: string, type: notificationType) => {
	notification.open({
		message: message,
		type: type
	});
};
