import { Action, action } from "easy-peasy";
import { DesignSettingList } from "../../../scenes/admin/scenes/organizations/scenes/customize/utils/types";

export interface DesignSettingsModel {
	loading: boolean,
	items: DesignSettingList,
	load: Action<DesignSettingsModel, DesignSettingList>;
	setLoading: Action<DesignSettingsModel, boolean>;
}

const designSettings: DesignSettingsModel = {
	loading: false,
	items: {},
	load: action((state, payload) => {
		state.items = payload;
	}),
	setLoading: action((state, payload) => {
		state.loading = payload;
	})
};

export default designSettings;