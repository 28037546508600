import { Button, Modal, Row } from "antd";
import React from "react";
import Text from "common/components/general/Text";
import { Trans, useTranslation } from "react-i18next";
import Icon from "../../../../../common/components/general/Icon";

const TRANSLATION_BASE_PATH = "_PARENT._DASHBOARD._CHILD_REG_SUCCESS";

export const ChildRegistrationSuccess = (props: any) => {
	const { t: translate } = useTranslation();
	
	return (
		<Modal title={null} visible={props.visible} footer={ null} onCancel={props.cancel}>
			<Row justify="center" className="pt-16">
				<Text fontSize="46" lineHeight="46" className="color-blue-6" > 
					<Icon type="ri-user-follow-line"/>
				</Text>
			</Row>
			<Row justify="center" className="pt-8">
				<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9"> 
					{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
				</Text>
			</Row>
			<Row justify="center" className="pt-16">
				<Text fontSize="14" lineHeight="22" className="color-gray-8"> 
					<Trans i18nKey={`${TRANSLATION_BASE_PATH}._SUBTITLE`}>
						Llogaria për fëmijën tuaj <strong title={translate("name")}>{{childFullname: props.childFullname}}</strong>,u krija.
						Fëmija mund të hyjë në platformë me emrin e përdoruesit
						<strong title={translate("username")}>{{ childUsername: props.childUsername }}</strong> dhe fjalëkalimin që krijuat gjatë regjistrimit.
					</Trans>
				</Text>
			</Row>
			<Row justify="center" className="pt-24"> 
				<Button type="primary" onClick={props.cancel}>
					{translate(`${TRANSLATION_BASE_PATH}._BTN_OK`)}
				</Button>
			</Row>
		</Modal>
	);
};
