import { Col, Empty, List, Row, Skeleton } from "antd";
import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import Text from "common/components/general/Text";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import LessonDto from "dtos/administration/lesson.dto";
import SubjectPlanTreeDto from "dtos/administration/subjectPlanTree.dto";
import { SUBJECTS_PATH } from "scenes/subjects";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";
import { LESSON_PATH } from "scenes/subjects/scenes/materialItems/scenes/lessonMaterial/LessonMaterial";
import { LessonItem } from "scenes/subjects/components/lessonItem/LessonItem";
import { MATERIAL_INFO_PATH } from "scenes/materialInfo";
import Collapse from "common/components/dataDisplay/Collapse";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._ASSIGNMENT";

class AsignmentsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			classroom: null,
			assignmentLessons: []
		};
	}

	componentDidMount() {
		this.loadCourseItem();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if ((prevState.classroom && prevState.classroom.id) !== (this.state.classroom && this.state.classroom.id))
			this.loadCourseItem();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if ((nextProps.classroom && nextProps.classroom.id) !== (prevState.classroom && prevState.classroom.id))
			return { classroom: nextProps.classroom };
		return null;
	}

	loadCourseItem = async () => {
		const { t: translate } = this.props;
		this.setState({ loading: true });
		const subjectPlanTree: SubjectPlanTreeDto | null = !this.state.classroom.SubjectPlan
			? null
			: await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(this.state.classroom.SubjectPlan.id);
		const newSubjectPlanTree: LessonDto[] | null = this.retriveLessonsFromTree(subjectPlanTree);
		const assignmentLessons = newSubjectPlanTree?.filter((lesson: any) => lesson.lessonType === "ASSIGNMENT");
		const groupAssignmentsLessonByEvaluation =
			(assignmentLessons &&
				_(assignmentLessons)
					.groupBy(x => (x.AssignmentEvaluation && x.AssignmentEvaluation.isEvaluated) || false)
					.map((value, key) => ({
						group:
							key === "true"
								? translate(`${TRANSLATION_BASE_PATH}._EVALUATED`)
								: translate(`${TRANSLATION_BASE_PATH}._NOT_EVALUATED`),
						lessons: value
					}))
					.value()) ||
			[];

		const groupedAssignments = groupAssignmentsLessonByEvaluation
			.map((assignmentStatus: any) => {
				if (assignmentStatus.group === translate(`${TRANSLATION_BASE_PATH}._EVALUATED`))
					return assignmentStatus;
				return (
					(assignmentStatus.lessons &&
						_(assignmentStatus.lessons)
							.groupBy(x => moment(x.endDate) >= moment())
							.map((value, key) => ({
								group:
									key === "true"
										? translate(`${TRANSLATION_BASE_PATH}._NOT_EVALUATED`)
										: translate(`${TRANSLATION_BASE_PATH}._EVALUATING`),
								lessons: value
							}))
							.value()) ||
					[]
				);
			})
			.flat(1);

		const defaultArray = [
			{ group: translate(`${TRANSLATION_BASE_PATH}._NOT_EVALUATED`), lessons: [] },
			{ group: translate(`${TRANSLATION_BASE_PATH}._EVALUATING`), lessons: [] },
			{ group: translate(`${TRANSLATION_BASE_PATH}._EVALUATED`), lessons: [] }
		];

		const assignmentsGrouped = defaultArray.map((item: any) => {
			const group = groupedAssignments.find(grupAssignment => grupAssignment.group === item.group);
			return group ? group : item;
		});

		this.setState({ loading: false, assignmentLessons: assignmentsGrouped });
	};

	retriveLessonsFromTree = (subjectPlanTree: SubjectPlanTreeDto | null): LessonDto[] | null => {
		if (!subjectPlanTree) return null;
		const subjectPlanNode: SubjectPlanTreeDto[] = [];
		subjectPlanNode.push(subjectPlanTree);
		const lesson: LessonDto[] = [];
		while (subjectPlanNode.length !== 0) {
			const node: any = subjectPlanNode.pop();
			if (node.children.length !== 0) subjectPlanNode.push(...node.children);
			lesson.push(...node.lessons);
		}
		return lesson;
	};

	getNoDataComponent = (description: any): ReactNode => {
		return (
			<Row justify="center" gutter={[16, 24]} className="full__width">
				<Col span={24}>
					<Empty description={false}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{description}
						</Text>
					</Empty>
				</Col>
			</Row>
		);
	};

	checkIsAvailable = (endDate: any) => {
		const today = moment();
		const dueDate = moment(endDate);
		const difference = today.diff(dueDate, "minutes");
		return difference <= 0;
	};

	onRowClick = (id: string) => {
		return this.props.history.push(
			`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${id}?isAssignmentSection=true`
		);
	};

	openEvaluation = (id: string) => {
		return this.props.history.push(
			`/${MATERIAL_INFO_PATH}/_teacher/material/${id}?classroomId=${this.state.classroom?.id}&isAssignmentSection=true`
		);
	};

	getCollapseWeek = () => {
		const { t: translate } = this.props;
		return this.state.assignmentLessons?.map(({ group, lessons }: any, index: number) => {
			return {
				key: index + 1,
				header: (
					<Text fontSize="24" lineHeight="32" className="color-blue-6">
						{group}
					</Text>
				),
				content: (
					<List
						locale={{
							emptyText: this.getNoDataComponent(
								group === translate(`${TRANSLATION_BASE_PATH}._EVALUATED`)
									? translate(`${TRANSLATION_BASE_PATH}._NO_DATA_EVALUATED`)
									: group === translate(`${TRANSLATION_BASE_PATH}._EVALUATING`)
									? translate(`${TRANSLATION_BASE_PATH}._NO_DATA_EVALUATING`)
									: translate(`${TRANSLATION_BASE_PATH}._NO_DATA_NOT_EVALUATED`)
							)
						}}
						size="default"
						dataSource={lessons}
						renderItem={(lesson: any, index: number) => (
							<List.Item>
								<Row
									key={index}
									className="pb-8 pt-8 pr-24 pl-24 courseBuilder__assignment assignment-item background-color-gray-1 full__width"
									align="middle"
								>
									<Col span={24}>
										<LessonItem
											id={lesson.id}
											canBeModified={false}
											name={lesson.name}
											lessonType={lesson.lessonType}
											textFontSize="14"
											textLineHeight="22"
											isEvalualable={true}
											isEvaluated={
												group !== translate(`${TRANSLATION_BASE_PATH}._EVALUATED`)
													? false
													: true
											}
											openEvaluation={(id: string) => this.openEvaluation(id)}
										/>
									</Col>
								</Row>
							</List.Item>
						)}
					/>
				),
				className: "background-color-gray-2"
			};
		});
	};

	render() {
		return (
			<Row justify="center">
				<Col xs={23} sm={22} md={20} lg={16} xl={16} xxl={16}>
					<Skeleton active loading={this.state.loading}>
						<Collapse defaultActiveKey={["1"]} borderless={true} panels={this.getCollapseWeek()}></Collapse>
					</Skeleton>
				</Col>
			</Row>
		);
	}
}

const Asignments = withTranslation()(AsignmentsComponent);
export default Asignments;
