import * as Yup from "yup";
import i18n from "i18next";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";

export const formValidator = (translate: (key: string) => string) => {
	const errorMessage = i18n.t(`${TRANSLATION_BASE_PATH}._EXACT_LENGTH`, {
		characters: 8
	});
	return Yup.object({
		code: Yup.string()
			.nullable()
			.trim()
			.length(8, errorMessage)
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
};
