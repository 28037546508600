import React, { Component, ReactNode } from "react";

import { ADMIN_PATH } from "../../../admin";
import { PARENT_PATH } from "../../../parent";
import { Role } from "services/domain/login/Role";
import { ON_BOARDING_PATH } from "../../../onBoarding";
import { UserStatus } from "services/domain/login/User";
import { MAIN_PATH, DASHBOARD_PATH } from "../../../main";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTTokenDto } from "dtos/authentication/token/jwtToken.dto";
import { PARENT_DASHBOARD_PATH } from "../../../parent/scenes/dashboard";
import UserSettingsService from "services/administration/userSettings.service";
import { REGISTER_EMAIL_VERIFICATION_PATH } from "../../../onBoarding/scenes/registerEmailVerification";

import Col from "common/components/layout/Col";
import Row from "common/components/layout/Row";
import Alert from "common/components/feedback/Alert";

export const SIGN_IN_PATH = "login";
const TRANSLATION_BASE_PATH = "_LOGIN._SIGNIN";

class Common extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: "",
			predefinedEmail: null
		};
	}

	getFieldsFromParams = (): {
		email: string | null;
		typeOfRegistration: string | null;
		invitationId: string | null;
	} => {
		const {
			match: { params }
		} = this.props;
		if (params.code && params.code !== "") {
			const decoded = new Buffer(params.code ?? "", "base64").toString("ascii");
			const uriConvert = decoded
				.split("&")
				.map((decodeParams: any) => {
					const [key, value] = decodeParams.split("=");
					return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
				})
				.join("&");

			const queryString = new URLSearchParams(`?${uriConvert}`);
			return {
				email: queryString.get("email"),
				typeOfRegistration: queryString.get("typeOfRegistration"),
				invitationId: queryString.get("invitationId")
			};
		}
		return {
			email: null,
			typeOfRegistration: null,
			invitationId: null
		};
	};

	componentDidMount() {
		const { email, typeOfRegistration, invitationId } = this.getFieldsFromParams();

		if (!!invitationId && !!typeOfRegistration) {
			localStorage.setItem(
				"classroom-invitation",
				JSON.stringify({
					registerAsStudent: typeOfRegistration === "student",
					invitationId: invitationId
				})
			);
		} else {
			localStorage.removeItem("classroom-invitation");
		}

		return this.setState({ predefinedEmail: email });
	}

	showAlertContent = (): ReactNode => {
		return this.state.hasError ? (
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="mt-24">
					<Alert message={this.state.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	getUserSettingsOnSuccessSignin = async () => {
		return await new UserSettingsService()
			.getSettings()
			.then(result => {
				return localStorage.setItem("user-settings", JSON.stringify(result));
			})
			.catch(error => {
				console.log(error);
			});
	};

	onSubmit = async (values: any) => {
		const { t: translate } = this.props;

		this.setState({ submitting: true });
		await OAuth2Service.loginUser(values.username.trim(), values.password)
			.then(async (token: JWTTokenDto) => {
				this.setState({
					submitting: false,
					hasError: false,
					errorMessage: ""
				});
				// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
				// @ts-ignore
				// eslint-disable-next-line no-undef
				smartlook("identify", token.UserId, {
					name: token.User.firstName + " " + token.User.lastName,
					email: token.User.email ?? token.User.parentEmail,
					username: token.User.username
				});
				localStorage.removeItem("profile-picture-string");
				await this.getUserSettingsOnSuccessSignin();

				const asOwner = token.Roles.find((role: any) => [Role.Owner, Role.Admin].includes(role.code));
				if (asOwner) {
					const organization = token.Organizations.find((org: any) => org.UserRoleId === asOwner.UserRoleId);
					if (organization && !organization.ParentOrganizationId && organization.Default)
						return (window.location.href = `/${ADMIN_PATH}/${DASHBOARD_PATH}`);
				}

				if (token.Roles[0]?.code.includes(Role.Parent) && token.User.status === UserStatus.Active) {
					return (window.location.href = `/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`);
				}
				
				let AFTER_LOGIN_URL = `/${MAIN_PATH}`;
				const location = this.props.location;
				if(location){
					const isCommingFrom = location?.state?.from;
					if (isCommingFrom && isCommingFrom?.pathname && isCommingFrom?.pathname.includes("subjects/course")) {
						AFTER_LOGIN_URL = isCommingFrom?.pathname;
					}
				}
				
				switch (token.User.status) {
					case UserStatus.New:
						return (window.location.href = `/${ON_BOARDING_PATH}/${REGISTER_EMAIL_VERIFICATION_PATH}`);
					case UserStatus.OnBoarding:
						return (window.location.href = `/${ON_BOARDING_PATH}`);
					default:
						return (window.location.href = AFTER_LOGIN_URL);
				}
			})
			.catch(error => {
				const errorStatus = error.response?.status ?? "500";
				return this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	render() {
		const Presentation = this.props.presentation;

		return <Presentation
			onSubmit={this.onSubmit}
			showAlertContent={this.showAlertContent}
		/>;
	}
}

export default Common;
