import React from "react";

import Icon from "../../general/Icon";
import Text from "../../general/Text";

export interface ContentProps {
	nrOfcharsIcon: boolean;
	lowercaseIcon: boolean;
	uppercaseIcon: boolean;
	oneNumberIcon: boolean;
	specialCharacterIcon: boolean;
	translate: (key: string) => string;
}

const resolvePasswordRequirementIcon = (requirementMet: boolean) => {
	return requirementMet ? (
		<Icon type="ri-checkbox-circle-fill" className="color-green-6 mr-8" />
	) : (
		<Icon type="ri-error-warning-fill" className="color-orange-6 mr-8" />
	);
};

const PasswordPopoverContent: React.FC<ContentProps> = props => {
	return (
		<div className="password-popover-content">
			<Text fontSize="14">
				{resolvePasswordRequirementIcon(props.nrOfcharsIcon)}
				{props.translate("_COMPONENTS._PASSWORD_REQUIREMENTS._8_CHARACTERS")}
			</Text>
			<Text fontSize="14" className="pt-8">
				{resolvePasswordRequirementIcon(props.specialCharacterIcon)}
				{props.translate("_COMPONENTS._PASSWORD_REQUIREMENTS._SPECIAL_CHARACTER")}
			</Text>
			<Text fontSize="14" className="pt-8">
				{resolvePasswordRequirementIcon(props.uppercaseIcon)}
				{props.translate("_COMPONENTS._PASSWORD_REQUIREMENTS._ONE_UPPERCASE")}
			</Text>
			<Text fontSize="14" className="pt-8">
				{resolvePasswordRequirementIcon(props.oneNumberIcon)}
				{props.translate("_COMPONENTS._PASSWORD_REQUIREMENTS._ONE_NUMBER")}
			</Text>
		</div>
	);
};

export default PasswordPopoverContent;
