import React from "react";
import { Route, Redirect } from "react-router-dom";

import OAuth2Service from "services/authentication/oauth2.service";
import {SELECT_ORGANIZATION_PATH} from "scenes/selectOrganization/SelectOrganization";
import { SIGN_IN_PATH } from "scenes/login/scenes/signIn";
import { Role } from "./services/domain/login/Role";

class SelectedOrganizationRoute extends Route {
	private decodedToken: any;
	private userInfo: any;
	private isMultiOrg: any;
	private selectedOrganizationId: any;

	constructor(props: any) {
		super(props);
		this.state = {
			loading: true, 
			hasOrganizations: true
		};
	}

	refreshToken(selectedOrganization: string | null) {
		return OAuth2Service.refreshToken(this.decodedToken.refreshToken, selectedOrganization).finally(() => {
			this.setState({loading: false});
		});
	}

	async componentDidMount() {
		this.setState({loading: true});
		this.checkTokenRoleOrganization();
		if (!this.isMultiOrg && this.userInfo.Organizations && this.userInfo.Organizations.length > 1) {
			if (!this.selectedOrganizationId) {
				this.selectedOrganizationId = this.userInfo.Organizations.find(
					(org: any) =>
						org.ParentOrganizationId &&
						this.userInfo.Roles.some((role: any) => role.UserRoleId === org.UserRoleId)
				).OrganizationId;
			}
			return this.refreshToken(this.selectedOrganizationId);
		} else if (this.isMultiOrg && this.selectedOrganizationId) {
			return this.refreshToken(this.selectedOrganizationId);
		}
		return this.setState({loading: false});
	}

	async componentDidUpdate() {
		this.checkTokenRoleOrganization();
	}

	private checkTokenRoleOrganization() {
		this.decodedToken = OAuth2Service.CurrentToken;
		this.userInfo = OAuth2Service.CurrentUser;
		if (!this.userInfo?.Organizations || this.userInfo.Organizations.length === 0) return this.setState({loading: false, hasOrganizations: false});
		this.isMultiOrg = this.userInfo?.Organizations && this.userInfo.Roles &&
			this.userInfo.Organizations.filter((organization: any) => {
				const isParent =
					!organization.ParentOrganizationId &&
					this.userInfo.Roles.some(
						(role: any) => role.UserRoleId === organization.UserRoleId && role.code === Role.Parent
					);
				return (
					isParent ||
					(organization.ParentOrganizationId &&
						this.userInfo.Roles.some((role: any) => role.UserRoleId === organization.UserRoleId))
				);
			}).length > 1;

		const organizationSelected = localStorage.getItem(this.userInfo?.UserId || "");
		const existsSelectedOrganization = this.userInfo?.Organizations.some((organization: any) => organization.OrganizationId === organizationSelected);
		if (!existsSelectedOrganization) localStorage.removeItem(this.userInfo?.UserId);
		this.selectedOrganizationId = existsSelectedOrganization ? organizationSelected : null;
	}

	render() {
		this.checkTokenRoleOrganization();
		if (this.state.loading) return null;

		if (!this.state.hasOrganizations) {
			localStorage.removeItem(this.userInfo.UserId);
			localStorage.removeItem("jwt-token");
			return (<Redirect to={`/${SIGN_IN_PATH}`} />);
		}

		if (this.isMultiOrg && !this.selectedOrganizationId)
			return (
				<Redirect
					to={{
						pathname: `/${SELECT_ORGANIZATION_PATH}`,
						state: { from: this.props.location }
					}}
				/>
			);
		return <Route {...this.props} />;
	}
}

export default SelectedOrganizationRoute;
