import React from "react";
import { Layout, Space } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LoggedInMenu from "common/components/_loggedInMenu/LoggedInMenu";
import Button from "common/components/general/Button";
import Image from "common/components/general/Image";
import Logo from "assets/images/icons/Logo.svg";
import { MAIN_PATH } from "../../../main";

export const Header = () => {
	const history = useHistory();
	const { t: translate } = useTranslation();
	return (
		<Layout.Header className="select__organization__header">
			<div className="dummy__div"></div>
			<Image
				src={Logo}
				onClick={() => {
					history.push(`/${MAIN_PATH}`);
				}}
				className="cursor_pointer"
			/>
			<Space direction="horizontal" size={24}>
				<Button
					htmlType="button"
					type="primary"
					ghost
					className="color-blue-6 dashboard__button"
					onClick={() => history.push(`/${MAIN_PATH}`)}
				>
					{translate("_MAIN._MY_DASHBOARD")}
				</Button>
				<LoggedInMenu />
			</Space>
		</Layout.Header>
	);
};
