import React from "react";
import { Space, Popconfirm, notification } from "antd";
import Icon from "../../../../common/components/general/Icon";
import SubjectService from "../../../../services/administration/subject.service";
import { WithTranslation, withTranslation } from "react-i18next";

interface RecordActionsProps extends WithTranslation {
	record: any;
	loadItemAndShowDrawer: (item: any) => void;
	popConfirm: DeleteDialogProps;
}

interface DeleteDialogProps {
	message: string;
	confirmtBtnTxt: string;
	cancelBtnTxt: string;
	onConfirm: (item: any) => void;
}

const TRANSLATION_BASE_PATH = "_ADMIN._SUBJECTS";

class RecordActions extends React.Component<RecordActionsProps, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			deletable: false
		};
	}

	getIfDeletable = async (subjectId: string) => {
		const isDeletable = await new SubjectService().hasLinkedClassrooms(subjectId);
		this.setState({ deletable: !isDeletable });
	}

	render() {
		const { deletable } = this.state;
		const { t: translate } = this.props;
		return (
			<Space size="middle">
				<a>
					<Icon
						onClick={() => this.props.loadItemAndShowDrawer(this.props.record)}
						type="ri-edit-line"
					></Icon>
				</a>

				<Popconfirm
					title={this.props.popConfirm.message}
					onConfirm={async () => {
						if (deletable) await this.props.popConfirm.onConfirm(this.props.record);
						else notification.open({ type: "error", message: translate(`${TRANSLATION_BASE_PATH}._SUBJECT_CANNOT_DELETE`) });
					}}
					okText={this.props.popConfirm.confirmtBtnTxt}
					cancelText={this.props.popConfirm.cancelBtnTxt}
				>
					<a onClick={() => this.getIfDeletable(this.props.record.id)}>
						<Icon type="ri-delete-bin-7-line"></Icon>
					</a>
				</Popconfirm>
			</Space>
		);
	}
}

export default withTranslation()(RecordActions);
