import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "common/components/general/Icon";
import { Row, Modal } from "antd";
import Button from "common/components/general/Button";
import Steps from "common/components/navigation/Steps";
import { Subject } from "./components/subject";
import { Grade } from "./components/grade";
import { Contact } from "./components/contact";
import { Availability } from "./components/availability";
import { CollectedDataDto } from "dtos/administration/tutoring/collectedData.dto";
import TutoringEmailService from "services/email/tutoring.email.service";
import GradeSubjectDto from "dtos/administration/gradeSubject.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_TUTORING._COLLECT_DATA";
const TRANSLATION_BASE_PATH_STEPS = "_TUTORING._COLLECT_DATA._STEPS";

type Props = {
	onCancel: () => void,
	updateData: (data: object) => void,
	collectedData: CollectedDataDto,
	next: () => void,
}

export const CollectData = (props: Props) => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const isTeacher = currentUserInfo?.Roles.some((role: any) => role.code === Role.Teacher);
	const { t: translate } = useTranslation();
	const { onCancel, collectedData, updateData, next } = props;
	const { subjects, level, grades, times, contact } = collectedData;
	const [step, setStep] = useState(0);
	const [isNextActive, setIsNextActive] = useState(false);
	const [sendLoading, setSendLoading] = useState(false);
	const modalTitle = translate(isTeacher ? `${TRANSLATION_BASE_PATH}._TITLE_TEACHER` : `${TRANSLATION_BASE_PATH}._TITLE_STUDENT`);
	const onNext = () => {
		setStep(step + 1);
		setIsNextActive(false);
	};
	const onBack = () => setStep(step - 1);
	const onSend = async () => {
		setSendLoading(true);
		await new TutoringEmailService()
			.sendTutoringRequestEmail(collectedData)
			.then(() => {
				setSendLoading(false);
				next();
				return;
			})
			.catch(error => {
				setSendLoading(false);
				console.log(error); 
			});
	};
	const steps = [{ title: translate(`${TRANSLATION_BASE_PATH_STEPS}._SUBJECT`), disabled: true},
		{ title: translate(`${TRANSLATION_BASE_PATH_STEPS}._YEAR`), disabled: true },
		{ title: translate(`${TRANSLATION_BASE_PATH_STEPS}._TIME`), disabled: true },
		{ title: translate(`${TRANSLATION_BASE_PATH_STEPS}._CONTACT`), disabled: true }];
	const loadComponent = () => {
		let component = null;
		const selectedSubjectsGrades = subjects?.map(subject => {
			return {
				name: subject.name,
				icon: subject.icon,
				grades: subject?.GradeSubjects?.sort((a: GradeSubjectDto, b: GradeSubjectDto) => {
					const aGradeName = a.Grade?.name?.toLowerCase();
					const bGradeName = b.Grade?.name?.toLowerCase();
					return aGradeName?.localeCompare(bGradeName ?? "", "en", { numeric: true }) || 0;
					
				})
			};
		});
		switch (step) {
			case 0:
				component = (
					<Subject
						selectedSubjects={subjects}
						selectedGrades={grades}
						updateData={updateData}
						setIsNextActive={setIsNextActive}
						level={level}
					/>
				);
				break;
			case 1:
				component = (
					<Grade
						selectedSubjectsGrades={selectedSubjectsGrades}
						selectedGrades={grades}
						updateData={updateData}
						setIsNextActive={setIsNextActive}
					/>
				);
				break;
			case 2:
				component = <Availability updateData={updateData} setIsNextActive={setIsNextActive} times={times} />;
				break;
			case 3:
				component = <Contact updateData={updateData} contact={contact} setIsNextActive={setIsNextActive} />;
				break;
			default:
				break;
		}
		return component;
	};
	return (
		<>
			<Modal
				className="collect-data"
				title={modalTitle}
				maskClosable={false}
				visible
				centered
				onCancel={onCancel}
				bodyStyle={{ paddingBottom: 0}}
				style={{ maxWidth: step === 2 ? "550px" : "450px" }}
				width="100%"
				footer={(
					<Row justify={step === 0 ? "end" : "space-between"}>
						{step !== 0 && (
							<Button
								onClick={() => onBack()}
							>
								<Row align="middle">
									<Icon className="mr-4" type="ri-arrow-left-line"></Icon>
									{translate(`${TRANSLATION_BASE_PATH}._BACK`)}
								</Row>
							</Button>
						)}
						<Row>
							<Button
								onClick={() => onCancel()}
							>
								{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
							</Button>
							{step === 3 ? (
								<Button
									onClick={() => onSend()}
									disabled={!isNextActive}
									type="primary"
									className="ml-4"
									loading={sendLoading}
								>
									{translate(`${TRANSLATION_BASE_PATH}._SEND`)}
								</Button>
							) : (
								<Button
									onClick={() => onNext()}
									disabled={!isNextActive}
									type="primary"
									className="ml-4"
								>
									{translate(`${TRANSLATION_BASE_PATH}._NEXT`)}
								</Button>
							)}
						</Row>
					</Row>
				)}
			>
				<Steps current={step} size="small" steps={steps} />
				<Row className="mt-16">{loadComponent()}</Row>
			</Modal>
		</>
	);
};