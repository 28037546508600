export enum OrganizationContentType {
	All = "ALL",
	Offline = "OFFLINE",
	Live = "LIVE"
}

export enum OrganizationSectorType {
	Public = "PUBLIC",
	Private = "PRIVATE"
}

export enum OrganizationStatus {
	_ACTIVE = "ACTIVE",
	_INACTIVE = "INACTIVE",
	_PENDING = "PENDING"
}

export enum OrganizationUpdateAttribute {
	_ALL = "ALL",
	_DOMAIN = "DOMAIN",
	_OWNER_EMAIL = "_OWNER_EMAIL"
}