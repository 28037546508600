import { Card, Col, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import Image from "common/components/general/Image";
import ClassroomService from "services/administration/classroom.service";
import DashboardStudentAssignmentsIcon from "assets/images/dashboard-student-assignments.svg";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._STUDENT_ASSIGNMENTS";

interface Course {
	id: string;
	name: string;
	icon: string;
	assignments: Assignment[];
}

interface Assignment {
	id: string;
	name: string;
	endDate: string;
}

const noDataContent = (translate: (key: string) => string) => {
	return (
		<div className="student__assignments_empty_state_wrapper">
			<Space direction="horizontal" size={24} className="student__assignments_empty_state_space">
				<Image src={DashboardStudentAssignmentsIcon} />
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION`)}
				</Text>
			</Space>
		</div>
	);
};

interface StudentAssignmentsProps {
	title?: string;
}

export const StudentAssignments = (props: StudentAssignmentsProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const [loadingData, setLoadingData] = useState<boolean>(true);
	const [courses, setCourses] = useState<Course[]>([]);
	const [viewAllCourses, setViewAllCourses] = useState<boolean>(false);
	const [viewAssignmentsList, setViewAssignmentsList] = useState<any[]>([]);

	useEffect(() => {
		const fetchData = () => {
			setLoadingData(true);
			return new ClassroomService()
				.getAssignmentsByStartDate(moment().format("YYYY-MM-DD hh:mm"))
				.then(result => {
					const formatedResponse = result.map(item => {
						let asignmentsWithoutSubmited = item.SubjectPlan.SubjectPlanTree.Lessons.map((lesson: any) => {
							if (lesson.AssignmentUserCommit && lesson.AssignmentUserCommit.length === 0) {
								return {
									id: lesson.id,
									name: lesson.name,
									endDate: lesson.endDate
								};
							}
						})
						asignmentsWithoutSubmited = asignmentsWithoutSubmited.filter((item: any) => item !== undefined)
						return {
							id: item.id,
							name: item.name,
							icon: item.GradeSubject.Subject.icon,
							assignments: asignmentsWithoutSubmited
						};
					});
					const onlyCoursesWithAssignments =
						formatedResponse.filter((item: any) => item.assignments.length > 0)

					return setCourses(onlyCoursesWithAssignments);
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					setLoadingData(false);
				});
		};
		fetchData();
	}, []);

	const getCardExtraContent = () => {
		if (courses.length > 0) {
			return translate(`${TRANSLATION_BASE_PATH}._VIRTUAL_COURSES`, {
				count: courses.length
			});
		}
		return undefined;
	};

	const getViewAssignmentStatus = (course: Course) => {
		const showAssignmentsStatusIndex = viewAssignmentsList.findIndex(item => item.id === course.id);
		const showingAssignments =
			showAssignmentsStatusIndex > -1 && viewAssignmentsList[showAssignmentsStatusIndex].viewAssignments;
		const showingAllAssignments =
			showAssignmentsStatusIndex > -1 && viewAssignmentsList[showAssignmentsStatusIndex].showingAllAssignments;
		return {
			index: showAssignmentsStatusIndex,
			viewAssignments: showingAssignments,
			viewAllAssignments: showingAllAssignments
		};
	};

	const getCourseOverviewCollapseIcon = (course: Course) => {
		const { index, viewAssignments } = getViewAssignmentStatus(course);
		const iconName = viewAssignments ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line";
		const iconColor = viewAssignments ? "color-blue-6" : "color-gray-7";
		return (
			<Icon
				type={iconName}
				fontSize="24"
				className={`${iconColor} cursor_pointer`}
				onClick={() => {
					const existing = [...viewAssignmentsList];
					if (index > -1) {
						existing[index] = {
							id: course.id,
							viewAssignments: !viewAssignments
						};
					} else {
						existing.push({
							id: course.id,
							viewAssignments: !viewAssignments
						});
					}
					setViewAssignmentsList(existing);
				}}
			/>
		);
	};

	const getNextAssignmentOverview = (assignments: Assignment[]) => {
		if (!assignments || assignments.length === 0) return null;

		const nextAssignment = assignments.sort((first, second) =>
			moment(first.endDate).isBefore(moment(second.endDate)) ? -1 : 1
		)[0];

		return (
			<Space direction="vertical" size={8}>
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._NEXT_ASSIGNMENT`)}
				</Text>
				<Text
					fontSize="14"
					lineHeight="22"
					className="color-blue-6 cursor_pointer"
					onClick={() => {
						history.push(`/subjects/materials/video/${nextAssignment.id}?virtualClass=true`);
					}}
					maxLength={40}
				>
					{nextAssignment.name}
				</Text>
				<div className="next__assignment_end_date_wrapper">
					<Icon type="ri-timer-line" className="color-gray-7" />
					<Text fontSize="12" lineHeight="20" className="color-gray-8 pl-8">
						{moment(nextAssignment.endDate).format("DD.MM.YYYY HH:mm")}
					</Text>
				</div>
			</Space>
		);
	};

	const getCourseOverview = (course: Course) => {
		const { viewAssignments } = getViewAssignmentStatus(course);
		return (
			<Card.Grid
				hoverable={false}
				className={`course__assignments_details ${viewAssignments ? "assignment__details_with_list" : ""}`}
				key={course.id}
			>
				<Row className="course__overview_content">
					<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="course__overview">
						<Space size={12}>
							<div className="subject__icon">
								<Icon type={`ri-${course.icon}`} />
							</div>
							<Space direction="vertical" size={2}>
								<Text
									fontSize="14"
									lineHeight="22"
									wheight="semibold"
									className="color-gray-9"
									maxLength={40}
								>
									{course.name}
								</Text>
								<Text fontSize="12" lineHeight="20" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._TOTAL_ASSIGNMENTS`, {
										count: course.assignments.length
									})}
								</Text>
							</Space>
						</Space>
					</Col>
					{!viewAssignments ? (
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="next_assignment__overview">
							{getNextAssignmentOverview(course.assignments)}
						</Col>
					) : null}
				</Row>
				<div className="show__all_assignments_icon">{getCourseOverviewCollapseIcon(course)}</div>
			</Card.Grid>
		);
	};

	const getAssignmentOverview = (course: Course, assignment: Assignment) => {
		return (
			<Card.Grid
				hoverable={false}
				className="assignment__list_item_overview"
				key={`${course.id}-${assignment.id}`}
			>
				<Row className="assignment__overview_content">
					<Col xs={24} sm={24} md={16} className="assignment__overview">
						<Space align="center" size={12}>
							<TypeIcon type="ASSIGNMENT" />
							<Text fontSize="14" lineHeight="22" className="color-gray-9" maxLength={40}>
								{assignment.name}
							</Text>
						</Space>
					</Col>
					<Col xs={24} sm={24} md={8}>
						<Space align="center" size={8} className="assignment__end_date_overview">
							<Icon type="ri-timer-line" className="color-gray-6" />
							<Text fontSize="12" lineHeight="20" className="color-gray-8">
								{moment(assignment.endDate).format("DD.MM.YYYY HH:mm")}
							</Text>
						</Space>
					</Col>
				</Row>
				<div className="navigate__to_assignment_icon">
					<Icon
						type="ri-arrow-right-line"
						className="color-gray-6 cursor_pointer"
						onClick={() => {
							history.push(`/subjects/materials/video/${assignment.id}?virtualClass=true`);
						}}
					/>
				</div>
			</Card.Grid>
		);
	};

	const getViewAllAssignmentsToggleOption = (course: Course) => {
		if (course.assignments.length <= 3) return null;
		const { index, viewAllAssignments } = getViewAssignmentStatus(course);
		const toggleLabelKey = viewAllAssignments ? "_CLOSE_ALL_ASSIGNMENTS" : "_VIEW_ALL_ASSIGNMENTS";

		return (
			<Card.Grid
				key={`${course.id}-view-all-assignments`}
				hoverable={false}
				className="view__all_assignments_toggle"
			>
				<Text
					fontSize="12"
					lineHeight="20"
					className="color-blue-6 cursor_pointer"
					onClick={() => {
						const existing = [...viewAssignmentsList];
						if (index > -1) {
							existing[index] = {
								id: course.id,
								viewAssignments: true,
								showingAllAssignments: !viewAllAssignments
							};
						} else {
							existing.push({
								id: course.id,
								viewAssignments: true,
								showingAllAssignments: !viewAllAssignments
							});
						}
						setViewAssignmentsList(existing);
					}}
				>
					{translate(`${TRANSLATION_BASE_PATH}.${toggleLabelKey}`)}
				</Text>
			</Card.Grid>
		);
	};

	const getFirstThreeAssignments = (course: Course) => {
		return course.assignments.slice(0, 3).map((assignment: any) => {
			return getAssignmentOverview(course, assignment);
		});
	};

	const getAllRemainingAssignments = (course: Course) => {
		const { viewAllAssignments } = getViewAssignmentStatus(course);
		if (viewAllAssignments) {
			return course.assignments.slice(3).map((assignment: any) => {
				return getAssignmentOverview(course, assignment);
			});
		}
		return null;
	};

	const getAssignmentsOverview = (course: Course) => {
		const { viewAssignments } = getViewAssignmentStatus(course);
		if (viewAssignments) {
			return [
				getFirstThreeAssignments(course),
				getAllRemainingAssignments(course),
				getViewAllAssignmentsToggleOption(course)
			];
		}
		return null;
	};

	const getCourseOverviewWithAssignments = (course: Course) => {
		return [getCourseOverview(course), getAssignmentsOverview(course)];
	};

	const getFirstThreeCourses = () => {
		return courses.slice(0, 3).map((course: Course) => {
			return getCourseOverviewWithAssignments(course);
		});
	};

	const getAllRemainingCourses = () => {
		if (viewAllCourses) {
			return courses.slice(3).map((course: Course) => {
				return getCourseOverviewWithAssignments(course);
			});
		}
		return null;
	};

	const getViewAllCoursesToggleOption = () => {
		if (courses.length <= 3) return null;
		return (
			<Card.Grid hoverable={false} className="course__assignments_view_all" key="view_all">
				{viewAllCourses ? (
					<Text
						fontSize="14"
						lineHeight="22"
						className="color-blue-6 cursor_pointer"
						onClick={() => {
							setViewAllCourses(false);
						}}
					>
						{translate(`${TRANSLATION_BASE_PATH}._CLOSE_ALL_COURSES`)}
					</Text>
				) : (
					<Text
						fontSize="14"
						lineHeight="22"
						className="color-blue-6 cursor_pointer"
						onClick={() => {
							setViewAllCourses(true);
						}}
					>
						{translate(`${TRANSLATION_BASE_PATH}._VIEW_ALL_COURSES`)}
					</Text>
				)}
			</Card.Grid>
		);
	};

	const getCoursesDetails = () => {
		return [getFirstThreeCourses(), getAllRemainingCourses(), getViewAllCoursesToggleOption()];
	};

	return (
		<Skeleton active loading={loadingData}>
			<Card
				className="dashboard__student_assignments"
				title={props.title ? (
					<div dangerouslySetInnerHTML={{ __html: props.title }} />
				) : (
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._ASSIGNMENTS`)}
					</Text>
				)}
				extra={getCardExtraContent()}
			>
				{courses.length > 0 ? getCoursesDetails() : noDataContent(translate)}
			</Card>
		</Skeleton>
	);
};
