export enum EducationLevel {
	Korporation = "KORPORATION",
	Preschool = "PRE_SCHOOL",
	University = "UNIVERSITY",
	Professional = "PROFESSIONAL",
	Other = "OTHER"
}

export const getLevelIcon = (level: string) => {
	switch (level) {
		case EducationLevel.Korporation:
			return "ri-building-2-line";
		case EducationLevel.Preschool:
			return "ri-open-arm-line";
		case EducationLevel.University:
			return "ri-user-6-line";
		case EducationLevel.Professional:
			return "ri-user-2-line";
		case EducationLevel.Other:
			return "ri-arrow-right-circle-line";
		default:
			return "ri-icon-undefined";
	}
};
