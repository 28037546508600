export abstract class BaseEntity<T> {
	public id: string;
	public createdAt: Date;
	public updatedAt: Date;
	public deletedAt: Date | undefined;

	constructor(data: {id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date}) {
		this.id = data.id;
		this.createdAt = data.createdAt;
		this.updatedAt = data.updatedAt;
		this.deletedAt = data.deletedAt;
	}
}
