import {v4 as uuidv4} from "uuid";

export default class ClassroomTagDto {
	readonly id!: string;
	readonly ClassroomId?: string;
	readonly TagId?: string;
	LessonClassroomTag?: any[];

	constructor(data: {ClassroomId?: string; TagId?: string;[ key: string ]: any}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
