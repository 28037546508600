import { Card, Col, Empty, Form, notification, Row, Skeleton, Tooltip } from "antd";
import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import moment from "moment";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { SectionContent } from "../../components/sectionContent/SectionContent";
import { DASHBOARD_PATH } from "../dashboard";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { AdminTable } from "../../components/table/table";
import { formValidator } from "./ValidationSchema";
import UserService from "services/authentication/user.service";
import { Space } from "common/components/layout/Space";
import { CircularIcon } from "common/components/general/CircularIcon";
import AlFlag from "assets/images/icons/Al.svg";
import KsFlag from "assets/images/icons/Ks.svg";
import Image from "common/components/general/Image";
import { formatTotal } from "../../util/formatTotal";
import { NewPasswordModal } from "./NewPasswordModal";
import { isOver13 } from "common/utils/isOver13";
import InviteNewUser from "./components/InviteUserSection";

export const USERS_PATH = "users";
const TRANSLATION_BASE_PATH = "_ADMIN._USERS";

class UsersComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			searching: false,
			searchedFor: "",
			results: null,
			errorMessage: "",
			drawerVisible: false,
			modalVisibile: false,
			loadingUsers: false
		};
	}

	submitSearchFormHandler: any;

	search = async (values: any) => {
		const key = values.searchKey.trim();

		this.setState({
			searching: true
		});

		return new UserService()
			.search(key)
			.then((results: any[]) => {
				return this.setState({
					searchedFor: key,
					results: results
				});
			})
			.finally(() => {
				this.setState({
					searching: false
				});
			});
	};

	getUserColumn = (row: any) => {
		return (
			<Space>
				<Col>
					<CircularIcon type="ri-user-line"></CircularIcon>
				</Col>
				<Col>
					<Row>
						<Space>
							<Text fontSize="14" lineHeight="28" className="color-gray-8">
								{row.firstName} {row.lastName}
							</Text>
							{!isOver13(row.dateOfBirth) && row.parentEmail && (
								<Text
									fontSize="12"
									lineHeight="20"
									className="users__elipse__small color-gray-7 background-color-gray-3"
								>
									<Tooltip title={moment(row.dateOfBirth).format("YYYY-MM-DD")}>
										<a>-13</a>
									</Tooltip>
								</Text>
							)}
						</Space>
					</Row>
					<Row>
						<Space>
							<Text fontSize="14" lineHeight="20" className="color-gray-7">
								<Icon type="ri-mail-line"></Icon>
							</Text>
							<Text fontSize="12" lineHeight="24" className="color-gray-7">
								{row.email ?? row.parentEmail}
							</Text>
						</Space>
					</Row>
					<Row>
						<Space>
							<Text fontSize="14" lineHeight="20" className="color-gray-7">
								<Icon type="ri-account-pin-box-line"></Icon>
							</Text>
							<Text fontSize="12" lineHeight="24" className="color-gray-7">
								{row.username}
							</Text>
						</Space>
					</Row>
				</Col>
			</Space>
		);
	};

	getNationalityColumn = (row: any) => {
		const { t: translate } = this.props;
		const other = translate(`${TRANSLATION_BASE_PATH}._TABLE._NATIONALITY._OTHER`);
		const notDefined = translate(`${TRANSLATION_BASE_PATH}._TABLE._NATIONALITY._NOT_DEFINED`);

		const nationalityFlag: Map<string, ReactNode> = new Map([
			["ALBANIA", <Image key="alFlag" src={AlFlag} />],
			["KOSOVO", <Image key="ksFlag" src={KsFlag} />],
			["DIASPORA", <Icon key="noFlag" type="ri-earth-line" />]
		]);

		return (
			<Space>
				{(!row.nationality || row.nationality === "OTHER") && (
					<>
						<Col>
							<Text fontSize="14" lineHeight="20" className="color-gray-9">
								{other}
							</Text>
						</Col>
						<Col>
							<Text fontSize="12" lineHeight="20" className="color-gray-7">
								{notDefined}
							</Text>
						</Col>
					</>
				)}
				{row.nationality && row.nationality !== "OTHER" && (
					<>
						<Col>
							<Text fontSize="14" lineHeight="20" className="color-gray-9">
								{nationalityFlag.get(row.nationality)}
							</Text>
						</Col>
						<Col>
							<Text fontSize="14" lineHeight="20" className="color-gray-9">
								{translate(`${TRANSLATION_BASE_PATH}._TABLE._NATIONALITY._${row.nationality}`)}
							</Text>
						</Col>
					</>
				)}
			</Space>
		);
	};

	getRoleColumn = (row: any) => {
		const roles = row.Roles.map((item: any) => item.code).filter(
			(value: any, index: any, self: any) => self.indexOf(value) === index
		);
		const { t: translate } = this.props;

		return roles.map((role: string) => {
			return (
				<Row key={role}>
					<Space className="users__elipse color-blue-6 background-color-blue-1">
						<Icon type="ri-user-line" />
						<Text fontSize="14" lineHeight="22">
							{translate(`${TRANSLATION_BASE_PATH}._TABLE._ROLE.${role}`)}
						</Text>
					</Space>
				</Row>
			);
		});
	};

	getStatusColumn = (row: any) => {
		const { t: translate } = this.props;

		const classes: Map<string, string> = new Map([
			["NEW", "users__elipse color-gray-8 background-color-gray-3"],
			["ACTIVE", "users__elipse color-green-6 background-color-green-1"],
			["ONBOARDING", "users__elipse color-orange-6 background-color-orange-1"]
		]);

		return (
			<Space direction="vertical" size="small">
				<Space className={classes.get(row.status)}>
					<Text fontSize="14" lineHeight="22">
						{translate(`${TRANSLATION_BASE_PATH}._TABLE._STATUS._${row.status}`)}
					</Text>
				</Space>
				{row.manuallyActivated ? (
					<Text fontSize="14" lineHeight="22">
						{translate(`${TRANSLATION_BASE_PATH}._TABLE._STATUS._MANUALLY_ACTIVATED`)}
					</Text>
				) : null}
			</Space>
		);
	};

	activateAccount = (user: any, translate: (key: string) => string) => {
		return new UserService().activateAccount(user.id).then(() => {
			this.submitSearchFormHandler();
			return notification.success({
				message: (
					<Text fontSize="16" lineHeight="24" className="color-gray-8">
						Përdoruesi{" "}
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{user.firstName} {user.lastName}
						</Text>{" "}
						<Text fontSize="16" lineHeight="24" className="color-gray-7">
							{`(${user.email ?? user.username})`}
						</Text>{" "}
						u aktivizua
					</Text>
				)
			});
		});
	};

	getActionsColumn = (row: any, translate: (key: string) => string) => {
		if (row.status === "NEW") {
			return (
				<Button
					icon="ri-check-fill"
					onClick={() => {
						this.activateAccount(row, translate);
					}}
					className="admin__activate_account_button"
				>
					{translate(`${TRANSLATION_BASE_PATH}._TABLE._ACTIVATE_ACCOUNT`)}
				</Button>
			);
		}

		return (
			<Button
				icon="ri-key-2-line"
				onClick={() => {
					const fullName = row.firstName + " " + row.lastName;
					this.setState({
						resetPasswordModal: {
							visible: true,
							userId: row.id,
							email: row.email ?? row.parentEmail,
							fullName: fullName
						}
					});
				}}
				className="admin__reset_password_button"
			>
				{translate(`${TRANSLATION_BASE_PATH}._TABLE._RESET_PASSWORD`)}
			</Button>
		);
	};

	render() {
		const { t: translate } = this.props;
		const title = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_LAST");
		const subTitle = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const cardTitle = translate(`${TRANSLATION_BASE_PATH}._CARD._TITLE`);
		const cardSubtitle = translate(`${TRANSLATION_BASE_PATH}._CARD._SUBTITLE`);
		const buttonName = translate(`${TRANSLATION_BASE_PATH}._BUTTON_NAME`);
		const resultInfo = translate(`${TRANSLATION_BASE_PATH}._RESULT_INFO`);
		const noDataLabel = translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`);

		const form = () => {
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={{
						searchKey: ""
					}}
					onSubmit={this.search}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						this.submitSearchFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
									<Row>
										<Col>
											<Icon className="users__search__icon" type="ri-search-2-line"></Icon>
										</Col>
										<Col xs={24} sm={24} md={20} lg={18} xl={14} xxl={12}>
											<Input name="searchKey" className="users_search_input" />
										</Col>
										<Col className="user__search__field">
											<Button
												loading={this.state.searching}
												type="primary"
												htmlType="submit"
												disabled={!formik.isValid}
												className="users__search__button"
											>
												{buttonName}
											</Button>
										</Col>
									</Row>
								</Col>
								<Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
									<Row>
										<Col xs={24}>
											<Input name="searchKey" />
										</Col>
										<Col xs={24}>
											<Button
												loading={this.state.searching}
												type="primary"
												htmlType="submit"
												disabled={!formik.isValid}
												className="users__search__button__xs"
											>
												{buttonName}
											</Button>
										</Col>
									</Row>
								</Col>
							</form>
						);
					}}
				</Formik>
			);
		};

		const columns = [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._USER`),
				dataIndex: "user",
				key: "user",
				render: (text: any, row: any) => {
					return this.getUserColumn(row);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._NATIONALITY`),
				dataIndex: "nationality",
				key: "nationality",
				responsive: ["sm"],
				render: (text: any, row: any) => {
					return this.getNationalityColumn(row);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._ROLE`),
				dataIndex: "role",
				key: "role",
				responsive: ["md"],
				render: (text: any, row: any) => {
					return (
						<Row>
							<Col>{this.getRoleColumn(row)}</Col>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS`),
				dataIndex: "status",
				key: "status",
				responsive: ["lg"],
				render: (text: any, row: any) => {
					return this.getStatusColumn(row);
				}
			},
			{
				title: "",
				dataIndex: "actions",
				key: "actions",
				render: (text: any, row: any) => {
					return this.getActionsColumn(row, translate);
				}
			}
		];
		return (
			<>
				<SectionTitle title={title} subtitle={[subTitle, title]} subtitleUrl={[DASHBOARD_PATH]} />
				<SectionContent>
					<InviteNewUser />
					<Row>
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Card>
								<Row className="pb-16">
									<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
										<Text fontSize="20" lineHeight="28" className="color-gray-9">
											{cardTitle}
										</Text>
									</Col>
								</Row>
								<Row className="pb-16">
									<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{cardSubtitle}
										</Text>
									</Col>
								</Row>
								<Row>
									<Col span="24">
										<Form>{form}</Form>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
					{this.state.results && (
						<Skeleton loading={this.state.searching}>
							<Row className="pt-16">
								<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
									{!this.state.searching && formatTotal(this.state.results.length)} {resultInfo} "
									{this.state.searchedFor}"
								</Text>
							</Row>
							<Row className="pt-16">
								<Col span={24}>
									<AdminTable
										columns={columns}
										dataSource={this.state.results}
										locale={{
											emptyText: (
												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noDataLabel} />
											)
										}}
									/>
								</Col>
							</Row>
						</Skeleton>
					)}
					<NewPasswordModal
						userId={this.state.resetPasswordModal?.userId}
						email={this.state.resetPasswordModal?.email}
						fullName={this.state.resetPasswordModal?.fullName}
						visible={this.state.resetPasswordModal?.visible}
						onClose={() => {
							this.setState({
								resetPasswordModal: {
									visible: false,
									userId: undefined,
									email: undefined,
									fullName: undefined
								}
							});
						}}
					/>
				</SectionContent>
			</>
		);
	}
}

const Users = withTranslation()(UsersComponent);
export default Users;
