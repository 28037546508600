import {CourseProgress} from "dtos/analytics/courseProgress.dto";
import ApiServiceData from "../api.service";

const relativeUrl = "/v1/api/analytics/courses";

export default class AnalyticsCoursesService extends ApiServiceData {

	public async getCoursesTotals(): Promise<any> {
		return this.get(relativeUrl + "/totals")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getCoursesTotalsLive(): Promise<any> {
		return this.get(relativeUrl + "/totals/live")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getPublicOrganizationsCoursesTotalsLive(): Promise<any> {
		return this.get(relativeUrl + "/totals/public/live")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAsyncCoursesProgress(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/async-courses-progress")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getTotalVideos(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/total/videos")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getTotalLiveVideos(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/total/live/videos")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getTotalQuiz(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/total/quiz")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getTotalLiveQuiz(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/total/live/quiz")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getTotalPdf(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/total/pdf")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getTotalLivePdf(): Promise<CourseProgress[]> {
		return this.get(relativeUrl + "/total/live/pdf")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}


	public getVirtualCoursesGeneralAnalytics(): Promise<any> {
		return this.get(relativeUrl + "/classroom/general")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAssignmentsAnalytics(startDate: string): Promise<any[]> {
		return this.get(relativeUrl + "/classroom/assignments-analytics/" + startDate)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAsParentChildAsyncClassData(childId: string): Promise<any[]> {
		return this.get(relativeUrl + "/asParent/async-class-data/" + childId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
