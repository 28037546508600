import subjects, { SubjectsModel } from "./subjects";
import designSettings, { DesignSettingsModel } from "./designSettings";

export interface StoreModel {
	subjects: SubjectsModel;
	designSettings: DesignSettingsModel;
}

const model: StoreModel = {
	subjects,
	designSettings
};

export default model;
