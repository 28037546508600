import { Card, Col, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";
import CounterCard from "../../../../../common/components/_counterCard/CounterCard";
import { WeekTotalFilter } from "../../../components/weekTotalFilter/WeekTotalFilter";
import ClassBackgroundImg from "assets/images/main-classes-img1.svg";
import { showError } from "../../../../../common/utils/Notification";
import { previewFile } from "../../../../courseBuilder/utils/FileUtils";
import ClassSlider from "./classSlider/ClassSlider";
import AnalyticsCoursesService from "../../../../../services/analytics/course.service";
import { sumTotal } from "../../../../admin/util/sumTotal";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_STATISTICS._SECTION_ASYNCH_CLASSES";

export const SectionAsyncClasses = (props: any) => {

	const { t: translate } = useTranslation();
	const [loadingClassItems, setLoadingClassItems] = useState(true);
	const [totalClassItems, setTotalClassItems] = useState(0);
	const [classItems, setClassItems] = useState([]);
	const [totalVideoLessons, setTotalVideoLessons] = useState(0);
	const [totalQuizLessons, setTotalQuizLessons] = useState(0);

	const getPhotoUrls = async (items: any): Promise<any> => {
		return (
			(await Promise.all(
				items.map(async function (item: any) {
					const file: any = {
						id: item.FileId,
						name: item.FileName,
						filePath: item.FilePath
					};
					const image = (item.FileId && (await previewFile(file))) || null;
					return { ...item, FilePath: image && image.url };
				})
			).then(item => {
				return item;
			})) || []
		);
	};

	useEffect(() => {
		const fetchData = () => {
			setLoadingClassItems(true);
			return new AnalyticsCoursesService().getAsParentChildAsyncClassData(props.child.id)
				.then((items: any) => {
					setTotalClassItems(items.length);
					setTotalVideoLessons(sumTotal(items, "TotalVideo"));
					setTotalQuizLessons(sumTotal(items, "TotalQuiz"));
					return getPhotoUrls(items);
				})
				.then((items: any) => {
					return setClassItems(items);
				})
				.catch((error) => {
					console.log(error);
					showError("Error");
				})
				.finally(() => {
					setLoadingClassItems(false);
				});
		};
		fetchData();
	}, []);

	return (
		<Card title={null} className="child__statistics__no__top__border child__statistics__no__bottom__border__radius">
			<Row>
				<Col xs={24} sm={24} md={8} lg={8} xl={10} xxl={14}>
					<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Col>
				<Col xs={24} sm={24} md={16} lg={16} xl={14} xxl={10}>
					<WeekTotalFilter />
				</Col>
			</Row>
			<Row className="pt-24" justify="space-between" gutter={[24, 0]}>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
					<CounterCard
						title={translate(`${TRANSLATION_BASE_PATH}._TOTAL_ASYNCH_CLASSES`)}
						icon="ri-slideshow-3-line"
						data={totalClassItems}
						loading={loadingClassItems}
					/>
				</Col>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
					<CounterCard
						title={translate(`${TRANSLATION_BASE_PATH}._TOTAL_VIDEO`)}
						icon="ri-play-mini-fill"
						data={totalVideoLessons}
						loading={loadingClassItems}
					/>
				</Col>
				<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
					<CounterCard
						title={translate(`${TRANSLATION_BASE_PATH}._TOTAL_QUIZ`)}
						icon="ri-question-fill"
						data={totalQuizLessons}
						loading={loadingClassItems}
					/>
				</Col>
			</Row>
			<Skeleton loading={loadingClassItems}>
				<ClassSlider
					items={classItems}
					defaultImg={ClassBackgroundImg}
					noDataDesc={translate(`${TRANSLATION_BASE_PATH}._CHILD_NO_ENROLLMENT`)}
				/>
			</Skeleton>
		</Card>
	);
};