import React, { ReactNode } from "react";
import { Row, Col } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";

import { StudentRegistrationUnder13 } from "./StudentRegistrationUnder13";
import { StudentRegistrationOver13 } from "./StudentRegistrationOver13";
import { FormikGroupDatePickerField } from "../../components/groupBirthDatePicker";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

interface MainStudentRegisterFormValues {
	birthDate: Moment;
}

interface MainStudentRegisterFormProps {
	submitting: boolean;
	predefinedEmail?: string;
	predefinedDateOfBirth?: boolean;
	recaptchaRef: any;
}

const getRegistrationFormBasedOnStudentAge = (
	values: MainStudentRegisterFormValues,
	submitting: boolean,
	recaptchaRef: any,
	predefinedEmail?: string
): ReactNode => {
	if (!values.birthDate || !moment(values.birthDate).isValid()) return null;
	const secondDate = moment();
	const duration = moment.duration(secondDate.diff(values.birthDate));
	const years = duration.asYears();

	if (years >= 13) {
		return (
			<StudentRegistrationOver13
				submitting={submitting}
				predefinedEmail={predefinedEmail}
				recaptchaRef={recaptchaRef}
			/>
		);
	} else if (years >= 3) {
		return (
			<StudentRegistrationUnder13
				submitting={submitting}
				predefinedEmail={predefinedEmail}
				recaptchaRef={recaptchaRef}
			/>
		);
	}
	return null;
};

export const MainStudentRegisterForm = (props: MainStudentRegisterFormProps) => {
	const { values } = useFormikContext<MainStudentRegisterFormValues>();
	const { t: translate } = useTranslation();

	return (
		<>
			<Row gutter={24}>
				<Col xs={24}>
					<FormikGroupDatePickerField
						disabled={!!props.predefinedDateOfBirth}
						name="birthDate"
						label={translate(`${TRANSLATION_BASE_PATH}._BIRTH_DATE`)}
					/>
				</Col>
			</Row>
			{getRegistrationFormBasedOnStudentAge(values, props.submitting, props.recaptchaRef, props.predefinedEmail)}
		</>
	);
};
