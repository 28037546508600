import React from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";

const { SubMenu } = Menu;

interface MenuProps {
	menu: any[];
	toggle?: () => void;
}

const findMenuItem = (arr: any[], path: any): any => {
	let result;
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].path === path) {
			result = arr[i];
			break;
		}
		if (arr[i].children) {
			result = findMenuItem(arr[i].children, path);
			if (result) break;
		}
	}
	return result;
};

export const MainMenu = (props: MenuProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const currentKey = findMenuItem(props.menu, location.pathname)?.key;
	return (
		<Menu mode="inline" selectedKeys={[currentKey]}>
			{props.menu.map(item => {
				if (item.children) {
					return (
						<SubMenu
							key={item.key}
							icon={
								<span className="anticon">
									<Icon type={item.icon} />
								</span>
							}
							title={<span>{translate(item.name)}</span>}
						>
							{item.children.map((child: any) => {
								return (
									<Menu.Item
										key={child.key}
										onClick={() => {
											if (props.toggle) {
												props.toggle();
											}
											history.push(child.path);
										}}
									>
										<Text fontSize="12" lineHeight="20">
											{translate(child.name)}
										</Text>
									</Menu.Item>
								);
							})}
						</SubMenu>
					);
				}
				return (
					<Menu.Item
						key={item.key}
						icon={
							<span className="anticon">
								<Icon type={item.icon} />
							</span>
						}
						onClick={() => {
							if (item.redirectToQuestionPage) {
								return window.location.href = item.path;
							}
							if (props.toggle) {
								props.toggle();
							}
							history.push(item.path);
						}}
					>
						{translate(item.name)}
					</Menu.Item>
				);
			})}
		</Menu>
	);
};
