import { Button, Col, Row, Skeleton, Space } from "antd";
import React from "react";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { FirstItem } from "./components/FirstItem";
import { useTranslation } from "react-i18next";
import ClassSlider from "./components/ClassSlider";
import _ from "lodash";
import { Link } from "react-router-dom";
import { turnNumberToRange } from "common/utils/NumberUtils";

const TRANSLATION_BASE_PATH = "_MARKETPLACE";
const showEnrolledUsers = false;

export const CourseView = (props: any) => {
	const { t: translate } = useTranslation();
	props.items.map((item: any) => {
		if (item?.TotalEnrollments < 500) item.TotalEnrollments = turnNumberToRange(item?.TotalEnrollments, 500, 1000);
		return item;
	});
	const items = _.orderBy(props.items, (x: any) => x?.TotalEnrollments, "desc");

	return (
		<>
			<Skeleton loading={props.loading}>
				<Row className="mt-16">
					<Col span="24">
						<Row>
							<Col span="12">
								<Space>
									<Icon fontSize="30" type={props.icon}></Icon>
									<Text fontSize="24" lineHeight="32" wheight="semibold">
										{props.title}
									</Text>
								</Space>
							</Col>
							{props.showAllLink && (
								<Col span="12">
									<Row justify="end">
										<Link to={"subjects/" + props.showAllLink}>
											<Button size="small" type="default">
												{translate(`${TRANSLATION_BASE_PATH}._SHOW_ALL`)}
											</Button>
										</Link>
									</Row>
								</Col>
							)}
						</Row>
						{items && items.length > 0 && (
							<>
								<Row className="mb-24">
									<FirstItem
										item={items[0]}
										imagePrefix={props.imagePrefix}
										showEnrolledUsers={showEnrolledUsers}
									/>
								</Row>
								<ClassSlider
									items={items.slice(1)}
									imagePrefix={props.imagePrefix}
									expandAll={!props.showAllLink}
									showEnrolledUsers={showEnrolledUsers}
								/>
							</>
						)}
						{(!items || items.length === 0) && <>Nuk ka kurse</>}
					</Col>
				</Row>
			</Skeleton>
		</>
	);
};
