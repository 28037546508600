import * as Yup from "yup";

import { usernameRegx } from "common/components/utils/Regx";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._MY_PROFILE";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		firstName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		lastName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		username: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.matches(usernameRegx, translate(`${TRANSLATION_BASE_PATH}._USERNAME_CHARACTERS_ALLOWED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		nationality: Yup.string()
			.trim()
			.nullable()
			.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
