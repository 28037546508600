import React, { ReactNode, useEffect, useState } from "react";
import { Button as AntButton, Col, Collapse, Divider, Dropdown, Menu, Modal, Row, Space } from "antd";

import Text from "common/components/general/Text";
import { LessonType } from "services/domain/administration/LessonType";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import moment from "moment";
import { previewFile } from "../../../../utils/FileUtils";
import { FileAddOutlined, LinkOutlined } from "@ant-design/icons";
import LessonSectionService from "../../../../../../services/administration/lessonSection.service";
import ProgressLessonEnrollmentService from "../../../../../../services/administration/progressLessonEnrollment.service";
import { useTranslation } from "react-i18next";
import { MATERIAL_INFO_PATH } from "../../../../../materialInfo";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._MATERIAL_ITEM_CARDS";

interface MaterialItemProps {
	length?: number | null;
	canBeModified?: boolean;
	textFontSize?: FontSize | null;
	textLineHeight?: LineHeight | null;
	textClassName?: string | null;
	onClick?: (item: any) => void;
	onDelete?: (item: any) => void;
	onUngroup?: (item: any) => void;
	isActualLesson?: boolean;
	enrolledStudents?: number;
	lesson: any;
	history: any;
	classroomId?: string;
}

declare type FontSize = "12" | "14" | "16" | "20" | "24" | "30" | "38" | "46" | "56";
declare type LineHeight = "20" | "22" | "24" | "28" | "32" | "38" | "46" | "54" | "64";

const openConfirmationModal = (props: any, translate: (key: string) => string) => {
	// e.stopPropagation();
	return Modal.confirm({
		title: translate(`${TRANSLATION_BASE_PATH}._DELETE_TITLE`),
		content: translate(`${TRANSLATION_BASE_PATH}._DELETE_MESSAGE`),
		okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
		cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
		onOk: () => props.onDelete({lessonId: props.lesson.id, lessonType: props.lesson.lessonType})
	});
};

const openUngroupConfirmationModal= (props: any, translate: (key: string) => string) => {
	// e.stopPropagation();
	return Modal.confirm({
		title: translate(`${TRANSLATION_BASE_PATH}._DELETE_UNGROUP_TITLE`),
		content: translate(`${TRANSLATION_BASE_PATH}._DELETE_UNGROUP_MESSAGE`),
		okText: translate(`${TRANSLATION_BASE_PATH}._YES_UNGROUP`),
		cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
		onOk: () => props.onUngroup({lessonId: props.lesson.id, lessonClassroomTagId: props.lesson.lessonClassroomTagId})
	});
};

const menu = (props: any, translate: (key: string) => string) => (
	<Menu>
		<Menu.Item key="0" onClick={async e => {
			e.domEvent.stopPropagation();
			props.onClick({lessonId: props.lesson.id, lessonType: props.lesson.lessonType});
		}}>
			<Space direction="horizontal">
				<Icon type="ri-edit-line" />
				{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
			</Space>
		</Menu.Item>

		<Menu.Item
			key="1"
			onClick={async e => {
				e.domEvent.stopPropagation();
				openConfirmationModal(props, translate);
			}}
		>
			<Space direction="horizontal">
				<Icon type="ri-delete-bin-line" />
				{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
			</Space>
		</Menu.Item>

		{props.lesson.lessonClassroomTagId && (
			<Menu.Item
				key="2"
				onClick={async e => {
					e.domEvent.stopPropagation();
					openUngroupConfirmationModal(props, translate);
				}}
			>
				<Space direction="horizontal">
					<Icon type="ri-link-unlink" />
					{translate(`${TRANSLATION_BASE_PATH}._UNGROUP`)}
				</Space>
			</Menu.Item>)}
	</Menu>
);

const getQuizView = (progess: any[], translate: (key: string) => string) => {
	return (
		<Space>
			<Icon type="ri-send-plane-line" className="color-blue-6" fontSize="24"></Icon>
			<Space direction="vertical" size={0}>
				<Text fontSize={"24"} lineHeight={"32"} className={"color-gray-8"}>
					{progess}
				</Text>

				<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
					{translate(`${TRANSLATION_BASE_PATH}._FILLED_QUIZ`)}
				</Text>
			</Space>
		</Space>
	);
};

const getLessonView = (progess: any[], translate: (key: string) => string) => {
	return (
		<Space>
			<Icon type="ri-eye-line" className="color-blue-6" fontSize="24"></Icon>
			<Space direction="vertical" size={0}>
				<Text fontSize={"24"} lineHeight={"32"} className={"color-gray-8"}>
					{progess}
				</Text>

				<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
					{translate(`${TRANSLATION_BASE_PATH}._FINISHED_LESSON`)}
				</Text>
			</Space>
		</Space>
	);
};

const getPdfLessonView = (progess: any[], translate: (key: string) => string) => {
	return (
		<Space>
			<Icon type="ri-eye-line" className="color-blue-6" fontSize="24"></Icon>
			<Space direction="vertical" size={0}>
				<Text fontSize={"24"} lineHeight={"32"} className={"color-gray-8"}>
					{progess}
				</Text>

				<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
					{translate(`${TRANSLATION_BASE_PATH}._FINISHED_LESSON`)}
				</Text>
			</Space>
		</Space>
	);
};

const getMeetingView = (progress: any) => {
	return <></>;
};

const getAssignmentView = (progress: any, translate: (key: string) => string) => {
	return (
		<Space>
			<Icon type="ri-send-plane-line" className="color-blue-6" fontSize="24"></Icon>
			<Space direction="vertical" size={0}>
				<Text fontSize={"24"} lineHeight={"32"} className={"color-gray-8"}>
					{progress}
				</Text>

				<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
					{translate(`${TRANSLATION_BASE_PATH}._COMMITED_ASSIGNMENTS`)}
				</Text>
			</Space>
		</Space>
	);
};

const typeToView = new Map<LessonType, any>([["VIDEO", getLessonView],
	["QUIZ", getQuizView],
	["MEETING", getMeetingView],
	["ASSIGNMENT", getAssignmentView],
	["PDF", getPdfLessonView]]);

const getEndDateView = (endDate: any, translate: any) => {
	return (
		<Col>
			<Divider />{" "}
			<Row>
				<Space className="pb-8">
					<Icon type={"ri-timer-line pr-8"}></Icon>
					<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
						{translate(`${TRANSLATION_BASE_PATH}._DUE_DATE`)}
					</Text>
				</Space>
			</Row>
			<Row>
				<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-8"}>
					{moment(endDate).format("DD-MM-YYYY HH:mm")}
				</Text>
			</Row>{" "}
		</Col>
	);
};

export const MaterialItem = (props: MaterialItemProps) => {
	const [lessonSections, setLessonSections] = useState<any[]>([]);
	const [progress, setProgress] = useState<number>(0);
	const [open, setOpen] = useState<boolean>(false);
	const { t: translate } = useTranslation();
	useEffect(() => {
		async function getLessonSections() {
			const requests = [new LessonSectionService().getByLessonId(props.lesson.id),
				new ProgressLessonEnrollmentService().countProgressLessonEnrollmentByLessonId(props.lesson.id)];

			const [lessonSections, progress] = await Promise.all(requests);
			if (progress) setProgress(progress);
			return await setLessonSections(lessonSections);
		}
		if (open) getLessonSections();

	}, [open]);

	moment.locale("sq");

	const getItemHeader = (): ReactNode => {
		return (
			<Row justify="space-around">
				<Col xs={props.canBeModified ? 20 : 22} sm={props.canBeModified ? 20 : 22} md={props.canBeModified ? 20 : 22} 
					lg={props.canBeModified ? 20 : 22} xl={props.canBeModified ? 20 : 22} xxl={props.canBeModified ? 20 : 22}>
					<Space direction="horizontal">
						<TypeIcon type={props.lesson.lessonType} />
						<Text
							fontSize={props.textFontSize || "14"}
							lineHeight={props.textLineHeight || "20"}
							className={
								props.isActualLesson ? "color-blue-1" : props.textClassName || "color-gray-8"
							}
						>
							{props.lesson.name}
						</Text>
					</Space>
				</Col>
				<Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={2} className="pt-4">
					<Row justify="center">
						<Col>
							{props.lesson.endDate && (
								<Text fontSize={"12"} lineHeight={"20"} className={"color-gray-7"}>
									{moment(props.lesson.endDate).format("D MMMM")}
								</Text>
							)}
						</Col>
					</Row>
				</Col>
				{props.canBeModified && (
					<Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
						<Row justify="end">
							<Col>
								<Dropdown.Button
									buttonsRender={() => [<></>,
								<Button
									key="1"
									type="ghost"
									className="extra__button"
									onClick={e => e.stopPropagation()}
								>
									<Icon type="ri-more-2-line" />
								</Button>]}
									trigger={["click"]}
									overlay={menu(props, translate)}
									icon={<Icon type="ri-more-2-line" />}
								/>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
		);
	};

	const getCollapsableItem = (): ReactNode => {
		return (
			<Collapse
				className="course__builder__materialItem__card mt-8"
				onChange={() => {setOpen(!open);}}
			>
				<Collapse.Panel
					key={props.lesson.id}
					header={getItemHeader()}
					showArrow={false}
				>
					<Col>
						<Row>
							<Col span={18}>
								<Space direction="vertical">
									<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
										{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
									</Text>
									{props.lesson.description ? (
										<HtmlInput name="description" readOnly={true} value={props.lesson.description} />
									) : (
										<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-8"}>
											{translate(`${TRANSLATION_BASE_PATH}._NO_DESCRIPTION`)}
										</Text>
									)}
								</Space>
							</Col>
							<Col span={6}>
								<Space direction="vertical">
									<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
										{translate(`${TRANSLATION_BASE_PATH}._MATERIALS`)}
									</Text>
									{lessonSections.map(lessonSection => {
										return lessonSection.File === null ? (
											<Space size={0}>
												<LinkOutlined />
												<AntButton
													href={lessonSection.url}
													target="_blank"
													type="link"
													rel="noopener noreferrer"
												>
													<Text fontSize={"14"} lineHeight={"22"} maxLength={25}>
														{lessonSection.name}
													</Text>
												</AntButton>
											</Space>
										) : (
											<Space size={0}>
												<FileAddOutlined />
												<AntButton
													target="_blank"
													type="link"
													rel="noopener noreferrer"
													onClick={async () => {
														if (lessonSection.File) {
															const urlObj = await previewFile(lessonSection.File);
															const url = urlObj.url;
															window.open(url, "_blank");
														}
													}}
												>
													<Text fontSize={"14"} lineHeight={"22"} maxLength={25}>
														{lessonSection.File?.name}
													</Text>
												</AntButton>
											</Space>
										);
									})}
								</Space>
							</Col>
						</Row>
						{props.lesson.lessonType === "ASSIGNMENT" && props.lesson.endDate
							? getEndDateView(props.lesson.endDate, translate)
							: null}
						<Divider />
						<Col>
							<Space>
								<Icon type="ri-user-line" className="color-blue-6" fontSize="24"></Icon>
								<Space direction="vertical" size={0}>
									<Text fontSize={"24"} lineHeight={"32"} className={"color-gray-8"}>
										{props.enrolledStudents}
									</Text>

									<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
										{translate(`${TRANSLATION_BASE_PATH}._STUDENTS`)}
									</Text>
								</Space>
								<Divider type={"vertical"}></Divider>
								{typeToView.get(props.lesson.lessonType)(progress, translate)}
							</Space>
						</Col>
						{props.lesson.lessonType === "ASSIGNMENT" ? (
							<Col>
								<Divider />
								<Button
									type="link"
									onClick={() => {
										props.history.push(
											`/${MATERIAL_INFO_PATH}/_teacher/material/${props.lesson.id}?classroomId=${props.classroomId}`
										);
									}}
								>
									<Text fontSize={"14"} lineHeight={"22"}>
										{translate(`${TRANSLATION_BASE_PATH}._VIEW_ASSIGNMENT`)}
									</Text>
								</Button>
							</Col>
						) : null}
					</Col>
				</Collapse.Panel>
			</Collapse>
		);
	};

	return (
		<>
			{props.canBeModified ? getCollapsableItem() : 
				<a href="" onClick={() => props.onClick && props.onClick(props.lesson.id)} className="full__width">
					{getItemHeader()}
				</a>
			}
		</>
	);
};

MaterialItem.defaultProps = {
	isActualLesson: false,
	canBeModified: true
};
