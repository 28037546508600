import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Space, Divider } from "antd";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Button from "common/components/general/Button";
import BashkiaTiraneLogo from "assets/images/bashkia-tirane-logo.svg";
import Gift from "assets/images/dashboard-digitalize-gift.svg";
import { ModalController } from "./modalController";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT";

export const DigitalizeStudentBaner = () => {
	const { t: translate } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);

	return (
		<>
			{isModalVisible && (
				<ModalController toggleDigitalizeModal={() => setIsModalVisible(false)}/>
			)}
			<div className="dashboard__digitalize_student_banner">
				<Col>
					<Row className="mb-4">
						<Col span="24">
							<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-2">
								{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-16">
						<Col span="24" className="digitalizeStudent__description">
							<Text fontSize="14" lineHeight="22" className="color-gray-5">
								{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col span="24">
							<Space align="center">
								<Image src={BashkiaTiraneLogo} className="digitalizeStudent__municipality_logo"/>
								<Divider type="vertical" className="digitalizeStudent__divider"/>
								<Button
									className="digitalizeStudent__button"
									type="primary"
									icon="ri-arrow-right-line"
									iconPosition="right"
									onClick={() => setIsModalVisible(true)}
								>{translate(`${TRANSLATION_BASE_PATH}._BUTTON`)}</Button>
							</Space>
						</Col>
					</Row>
				</Col>
				<Image src={Gift} className="digitalizeStudent__icon" />
			</div>
		</>
	);
};
