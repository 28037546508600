import ApiServiceData from "../api.service";
import LessonSectionDto from "../../dtos/administration/lessonSection.dto";

const relativeUrl = "/v1/api/lesson-section";

export default class LessonSectionService extends ApiServiceData {
	public getByLessonId(id: string): Promise<LessonSectionDto[]> {
		return this.get(`${relativeUrl}/lesson/${id}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
