import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ADMIN._GENERIC._FORM";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.max(255, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_255`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		icon: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		description: Yup.string()
			.nullable()
			.trim()
			.max(255, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_255`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		externalUrl: Yup.string()
			.nullable()
			.trim()
			.matches(
			/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
			translate(`${TRANSLATION_BASE_PATH}._INVALID_URL`)
			)	
	});
