import { Card, Space } from "antd";
import React from "react";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";

interface CourseIntroductionProps {
	className?: string;
	forTeacher: boolean;
}

const TRANSLATION_BASE_PATH = "_COMPONENTS._COURSE_INTRODUCTION";

export const CourseIntroduction = (props: CourseIntroductionProps) => {
	const { t: translate } = useTranslation();

	const getDescriptionByRole = () => {
		if (props.forTeacher) {
			return (
				<>
					<Space direction="horizontal" size={8}>
						<Icon type="ri-chat-2-line" />
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._NOTIFICATION_SUBTITLE`)}
						</Text>
					</Space>
					<Space direction="horizontal" size={8}>
						<Icon type="ri-question-answer-line" />
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._QUESTIONS_SUBTITLE`)}
						</Text>
					</Space>
				</>
			);
		}
		return (
			<Space direction="horizontal" size={8}>
				<Icon type="ri-todo-line" />
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._STUDENT_NOTIFICATION_SUBTITLE`)}
				</Text>
			</Space>
		);
	};

	return (
		<Card className={props.className}>
			<Space direction="vertical" size={16}>
				<Text fontSize="20" lineHeight="28" className="color-gray-9">
					{props.forTeacher
						? translate(`${TRANSLATION_BASE_PATH}._TEACHER_TITLE`)
						: translate(`${TRANSLATION_BASE_PATH}._STUDENT_TITLE`)}
				</Text>
				<Space direction="vertical" size={0}>
					{getDescriptionByRole()}
				</Space>
			</Space>
		</Card>
	);
};
