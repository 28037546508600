import React, { ReactNode } from "react";
import { Layout } from "antd";
import { MainHeader as StudentHeader } from "common/components/layout/header";
import { MainDashboardMenuItems } from "../../../main/components/layout/Layout";

interface LayoutProps {
	children: ReactNode;
}

const MainLayout = (props: LayoutProps) => {
	return (
		<Layout>
			<StudentHeader menu={MainDashboardMenuItems} showSwitchRole={true} />
			{props.children}
		</Layout>
	);
};

export default MainLayout;
