import React, { Component, ReactNode } from "react";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Alert from "common/components/feedback/Alert";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import { formValidator } from "./ValidationSchema";
import Icon from "common/components/general/Icon";
import UserService from "services/authentication/user.service";

const TRANSLATION_BASE_PATH = "_LOGIN._CHANGE_PASSWORD";

class ResetPasswordComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: ""
		};
	}

	showAlertContent = (): ReactNode => {
		return this.state.hasError ? (
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="mt-24">
					<Alert message={this.state.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	onSubmit = async (values: any) => {
		const { t: translate } = this.props;
		this.setState({
			submitting: true,
			hasError: false,
			errorMessage: ""
		});

		await new UserService()
			.resetPassword({
				code: this.props.tempCode,
				userId: this.props.account.id,
				password: values.password
			}, this.props.signedAuthorizationCode)
			.then(() => {
				this.setState({
					submitting: false
				});
				this.props.onChangedPassword();
				return;
			})
			.catch(error => {
				const errorStatus = error?.status ?? "500";
				this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={{}}
				onSubmit={this.onSubmit}
				isInitialValid={false}
			>
				{formik => (
					<form onSubmit={formik.handleSubmit} autoComplete="off">
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
								{this.props.choosedFromMultiple ? (
									<Text fontSize="30" lineHeight="38">
										<Icon
											type="ri-arrow-left-line"
											className="color-gray-8"
											onClick={this.props.onGoBack}
										/>
									</Text>
								) : null}
								<Text fontSize="30" lineHeight="38" wheight="semibold">
									{translate(`${TRANSLATION_BASE_PATH}._CHANGE_PASSWORD_TITLE`)}
								</Text>
							</Col>
						</Row>
						{this.props.choosedFromMultiple ? (
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="pt-24">
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{translate(`${TRANSLATION_BASE_PATH}._CHANGING_PASSWORD_FOR`)}{" "}
									</Text>
									<Text fontSize="14" lineHeight="22" className="color-gray-9" wheight="semibold">
										{this.props.account.firstName} {this.props.account.lastName}.
									</Text>
								</Col>
							</Row>
						) : null}
						{this.showAlertContent()}
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18} className="pt-24">
								<PasswordInput
									name="password"
									label={translate(`${TRANSLATION_BASE_PATH}._NEW_PASSWORD`)}
									showPopover={true}
									size="large"
									autoComplete="new-password"
								/>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18}>
								<PasswordInput
									name="repeatPassword"
									label={translate(`${TRANSLATION_BASE_PATH}._REPEAT_PASSWORD`)}
									showPopover={false}
									size="large"
									autoComplete="new-password"
								/>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18}>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									className="full_width_button"
									disabled={!formik.isValid}
									loading={this.state.submitting}
								>
									{translate(`${TRANSLATION_BASE_PATH}._CHANGE_PASSWORD_TITLE`)}
								</Button>
							</Col>
						</Row>
					</form>
				)}
			</Formik>
		);
	}
}

const ResetPassword = withTranslation()(ResetPasswordComponent);
export default ResetPassword;
