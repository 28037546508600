import React, { ReactNode } from "react";
import { Button as AntButton, Space } from "antd";
import { ButtonHTMLType, ButtonShape, ButtonType } from "antd/lib/button/button";
import { ButtonSize } from "antd/lib/button";

import Icon from "./Icon";

type IconPosition = "left" | "right";

export interface ButtonProps {
	disabled?: boolean;
	icon?: string | undefined;
	iconPosition?: IconPosition | undefined;
	shape?: ButtonShape;
	size?: ButtonSize;
	type?: ButtonType;
	onClick?: React.MouseEventHandler<HTMLElement>;
	className?: string;
	htmlType?: ButtonHTMLType;
	loading?: boolean;
	children?: ReactNode;
	ghost?: boolean;
	block?: boolean;
}

const getButtonContentBasedOnIconState = (
	icon: string | undefined,
	iconPosition: IconPosition | undefined,
	children: ReactNode
): ReactNode => {
	if (icon === undefined) {
		return children;
	}
	if (iconPosition === undefined || iconPosition === "left") {
		return (
			<Space direction="horizontal" align="center">
				<Icon type={icon} />
				{children}
			</Space>
		);
	}
	return (
		<Space direction="horizontal" align="center">
			{children}
			<Icon type={icon} />
		</Space>
	);
};

class Button extends React.Component<ButtonProps> {
	static __ANT_BUTTON = true;

	render() {
		const { icon, iconPosition, children, ...props } = this.props;
		return (
			<AntButton {...props} className={`${props.className}`}>
				{getButtonContentBasedOnIconState(icon, iconPosition, children)}
			</AntButton>
		);
	}
}

export default Button;
