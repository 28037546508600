import React, { useEffect, useState } from "react";
import { Menu, Space, Avatar, Dropdown, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Jimp from "jimp";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { MAIN_PATH } from "scenes/main";
import OAuth2Service from "services/authentication/oauth2.service";
import { previewFile } from "scenes/courseBuilder/utils/FileUtils";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";
import { SELECT_ORGANIZATION_PATH } from "scenes/selectOrganization/SelectOrganization";
import { SIGN_IN_PATH } from "scenes/login/scenes/signIn";
import { REGISTER_PATH } from "scenes/login/scenes/register";
import OrganizationService from "services/administration/organization.service";
import { PARENT_PATH } from "scenes/parent";
import { PARENT_SETTINGS_PATH } from "scenes/parent/scenes/changeSettings";
import { PARENT_PASSWORD_PATH } from "scenes/parent/scenes/changePassword";
import { isOver13 } from "../../utils/isOver13";

interface LoggedInMenuProps {
	showSwitchRole?: boolean;
	showParentProfileLinks?: any;
}

export const allowSwitchRoleAsTeacherAndParent = (): boolean => {
	const user = OAuth2Service.CurrentUser;
	if (user.Roles.some((r: any) => r.code === Role.Student) && user.User.parentEmail && !isOver13(user.dateOfBirth)) {
		return false;
	}
	return true;
};

const LoggedInMenu = (props: LoggedInMenuProps) => {
	const [userSettings] = useState<any>(OAuth2Service.CurrentUserSettings);
	const profilePictureString = localStorage.getItem("profile-picture-string");
	const history = useHistory();
	const { t: translate } = useTranslation();
	const [imagePhoto, setImagePhoto] = useState<any>("");
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const [myOrganizations, setMyOrganizations] = useState<any[]>([]);
	const [loadingOrganizations, setLoadingMyOrganizations] = useState<boolean>(false);

	const transformFileDownload = (imageUrl: any) => {
		return Jimp.read(imageUrl).then(image => {
			return image.getBase64Async(Jimp.MIME_JPEG);
		});
	};

	const getImage = async (file: any) => {
		const response = await previewFile(file);
		localStorage.setItem("profile-picture-string", await transformFileDownload(response.url));
		setImagePhoto(response.url);
	};

	const fetchOrganizations = () => {
		if (props.showSwitchRole) {
			setLoadingMyOrganizations(true);
			return new OrganizationService()
				.getMyOrganizations()
				.then(organizations => {
					return setMyOrganizations(organizations);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoadingMyOrganizations(false);
				});
		}
	};

	useEffect(() => {
		if (
			userSettings !== undefined &&
			userSettings.profilePicture &&
			userSettings.profilePictureType &&
			userSettings.profilePictureType === "upload" &&
			userSettings.profilePictureFile &&
			profilePictureString === null
		) {
			getImage(userSettings.profilePictureFile);
		}
		fetchOrganizations();
	}, []);

	const logout = async () => {
		return await new OAuth2Service().signOut().finally(() => {
			window.location.href = `/${MAIN_PATH}`;
		});
	};

	const logoutToParentPortal = async () => {
		return await new OAuth2Service().signOut().finally(() => {
			window.location.href = `/${SIGN_IN_PATH}/${REGISTER_PATH}?parent`;
		});
	};

	const switchAsParent = async (organizationId: string) => {
		const decodedToken = OAuth2Service.CurrentToken;
		await OAuth2Service.refreshToken(decodedToken.refreshToken, organizationId);
		return localStorage.setItem(currentUserInfo?.UserId ?? "", organizationId);
	};

	const getCurrentOrganizationNameContent = (organization: any) => {
		return (
			organization && (
				<Space size={4} align="center">
					<Icon type="ri-building-4-line" className="color-gray-6" />
					<Text fontSize="12" lineHeight="20" className="color-gray-8" maxLength={20}>
						{organization.name}
					</Text>
				</Space>
			)
		);
	};

	const openConfirmationModal = () => {
		return Modal.confirm({
			icon: <QuestionCircleOutlined />,
			content: translate("_PARENT._LOGOUT._CONTENT"),
			okText: translate("_PARENT._LOGOUT._OK"),
			cancelText: translate("_PARENT._LOGOUT._CANCEL"),
			onOk: () => logoutToParentPortal()
		});
	};

	const switchRoleMenu = () => {
		if (loadingOrganizations) return null;
		const organizationsAsStudent = myOrganizations.reduce(
			(total: number, nextItem: any) =>
				total + (nextItem?.UserRoles?.some((userRole: any) => userRole.Role.code === Role.Student) ? 1 : 0),
			0
		);
		const organizationsAsTeacher = myOrganizations.reduce(
			(total: number, nextItem: any) =>
				total +
				(nextItem?.UserRoles?.some((userRole: any) =>
					[Role.Owner, Role.Admin, Role.Teacher].includes(userRole.Role.code)
				)
					? 1
					: 0),
			0
		);
		const parentOrganization = myOrganizations.find((item: any) =>
			item.UserRoles?.some((userRole: any) => userRole.Role.code === Role.Parent)
		);
		const currentLoggedInRole = currentUserInfo?.Roles[0].code;
		const currentLoggedInOrganization = myOrganizations.find(
			item => item.id === currentUserInfo?.Organizations[0]?.OrganizationId
		);

		return [!OAuth2Service.isNotAtSuperOrganization && !OAuth2Service.isOwnerOrAdminAtDefaultOrganization ? <Menu.Item
			key="0"
			onClick={() => {
				history.push(`/${SELECT_ORGANIZATION_PATH}/${Role.Student}`);
			}}
		>
			<Space
				size={8}
				align="center"
				className={currentLoggedInRole === Role.Student ? "logged_in_menu__active_role" : ""}
			>
				<Avatar
					icon={<Icon type="ri-user-line" className="color-blue-6" />}
					className="background-color-blue-1"
				/>
				<Space size={8} align="center">
					<Space direction="vertical" size={4}>
						<Text fontSize="14" lineHeight="22" className="color-gray-8 role__name">
								Portali Nxënësit {organizationsAsStudent > 0 ? `(${organizationsAsStudent})` : ""}
						</Text>
						{currentLoggedInRole === Role.Student &&
								getCurrentOrganizationNameContent(currentLoggedInOrganization)}
					</Space>
					{currentLoggedInRole === Role.Student && <Icon type="ri-check-fill" className="color-blue-6" />}
				</Space>
			</Space>
		</Menu.Item> : null,
			!OAuth2Service.isNotAtSuperOrganization && !OAuth2Service.isOwnerOrAdminAtDefaultOrganization && allowSwitchRoleAsTeacherAndParent() ? (
				<Menu.Item
					key="1"
					onClick={() => {
						history.push(`/${SELECT_ORGANIZATION_PATH}/${Role.Teacher}`);
					}}
				>
					<Space
						size={8}
						align="center"
						className={
							[Role.Owner, Role.Admin, Role.Teacher].includes(currentLoggedInRole)
								? "logged_in_menu__active_role"
								: ""
						}
					>
						<Avatar
							icon={<Icon type="ri-user-2-line" className="color-blue-6" />}
							className="background-color-blue-1"
						/>
						<Space size={8} align="center">
							<Space direction="vertical" size={4}>
								<Text fontSize="14" lineHeight="22" className="color-gray-8 role__name">
									Portali Mësuesit {organizationsAsTeacher > 0 ? `(${organizationsAsTeacher})` : ""}
								</Text>
								{[Role.Owner, Role.Admin, Role.Teacher].includes(currentLoggedInRole) &&
									getCurrentOrganizationNameContent(currentLoggedInOrganization)}
							</Space>
							{[Role.Owner, Role.Admin, Role.Teacher].includes(currentLoggedInRole) && (
								<Icon type="ri-check-fill" className="color-blue-6" />
							)}
						</Space>
					</Space>
				</Menu.Item>
			) : null,
			!OAuth2Service.isNotAtSuperOrganization && !OAuth2Service.isOwnerOrAdminAtDefaultOrganization && allowSwitchRoleAsTeacherAndParent() ? (
				<Menu.Item
					key="2"
					onClick={async () => {
						if (currentUserInfo?.Roles.some(role => role.code === Role.Parent)) return;
						if (!parentOrganization) return openConfirmationModal();
						return await switchAsParent(parentOrganization.id).finally(() => {
							history.push(`/${PARENT_PATH}`);
						});
					}}
				>
					<Space
						size={8}
						align="center"
						className={currentLoggedInRole === Role.Parent ? "logged_in_menu__active_role" : ""}
					>
						<Avatar
							icon={<Icon type="ri-parent-line" className="color-blue-6" />}
							className="background-color-blue-1"
						/>
						<Space size={8} align="center">
							<Text fontSize="14" lineHeight="22" className="color-gray-8 role__name">
								Portali Prindit/Kujdestarit
							</Text>
							{currentLoggedInRole === Role.Parent && (
								<Icon type="ri-check-fill" className="color-blue-6" />
							)}
						</Space>
					</Space>
				</Menu.Item>
			) : null,
			!OAuth2Service.isNotAtSuperOrganization && !OAuth2Service.isOwnerOrAdminAtDefaultOrganization ? <Menu.Item key="3">
				<div className="full__width flex__center_justify_center">
					<Button
						type="link"
						onClick={() => {
							history.push(`/${SELECT_ORGANIZATION_PATH}`);
						}}
					>
						Ndrysho portalin
					</Button>
				</div>
			</Menu.Item> : null,
			<Menu.Divider key="divider" />];
	};

	const parentProfileLinks = () => {
		return [<Menu.Item key="parent-link-1" onClick={() => history.push(`/${PARENT_PATH}/${PARENT_SETTINGS_PATH}`)}>
			<Space size={8} align="center" className="pl-8">
				<Icon type="ri-user-line" />
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate("_PARENT._SETTINGS._TITLE")}
				</Text>
			</Space>
		</Menu.Item>,
			<Menu.Item key="parent-link-2" onClick={() => history.push(`/${PARENT_PATH}/${PARENT_PASSWORD_PATH}`)}>
				<Space size={8} align="center" className="pl-8">
					<Icon type="ri-key-2-line" />
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate("_PARENT._PASSWORD._TITLE")}
					</Text>
				</Space>
			</Menu.Item>,
			<Menu.Divider key="parent-divider" />];
	};

	const menu = (
		<Menu>
			{props.showParentProfileLinks ? parentProfileLinks() : null}
			{props.showSwitchRole ? switchRoleMenu() : null}
			<Menu.Item key="4" onClick={async () => await logout()}>
				<Space size={8} align="center">
					<Icon type="ri-logout-box-line" />
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate("_ONBOARDING._LOGOUT")}
					</Text>
				</Space>
			</Menu.Item>
		</Menu>
	);

	const getUserAvatar = () => {
		if (profilePictureString) {
			return <Avatar shape="square" src={profilePictureString} className="upload__profile_avatar" />;
		} else if (
			userSettings.profilePicture &&
			userSettings.profilePicture.indexOf(".") > -1 &&
			userSettings.profilePictureType &&
			userSettings.profilePictureType === "static"
		) {
			return (
				<Avatar
					shape="square"
					src={require(`assets/images/avatars/${userSettings.profilePicture}`)}
					className="static__profile_avatar"
				/>
			);
		} else if (
			userSettings.profilePicture &&
			userSettings.profilePicture.indexOf(".") < 0 &&
			userSettings.profilePictureType &&
			userSettings.profilePictureType === "upload" &&
			userSettings.profilePictureFile
		) {
			return <Avatar shape="square" src={imagePhoto} className="upload__profile_avatar" />;
		}
		return (
			<Avatar shape="square">
				{currentUserInfo?.User?.firstName[0]}
				{currentUserInfo?.User?.lastName[0]}
			</Avatar>
		);
	};

	return (
		<Dropdown trigger={["click"]} overlay={menu}>
			<Space>
				{getUserAvatar()}
				<div className="loggedInMenu__user">
					<Text fontSize="16" lineHeight="22" className="color-gray-8">
						<Space>
							<span className="loggedInMenu__fullname">
								{currentUserInfo?.User?.firstName + " " + currentUserInfo?.User?.lastName}
							</span>
							<Icon type="ri-arrow-down-s-line"></Icon>
						</Space>
					</Text>
				</div>
			</Space>
		</Dropdown>
	);
};

export default LoggedInMenu;
