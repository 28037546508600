/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

import axios from "axios";
import * as qs from "qs";
import jwt_decode from "jwt-decode";
import _ from "lodash";

import ApiServiceData from "../api.service";
import UserDto from "dtos/authentication/user/user.dto";
import {User} from "../../models/authentication/user.model";
import {JWTTokenDto} from "dtos/authentication/token/jwtToken.dto";
import {Role} from "../domain/login/Role";
import InMemoryJWT, {REFRESH_TOKEN_EVENTS} from "../inMemoryJWT.service";
import ls from "localstorage-slim";

const relativeUrl = "/v1/api/oauth2";
export const RECAPTCHA_KEY = "6LcHL84ZAAAAAOq6z263k6KkJrw8GyC8M4jCdfxX";

export const localstorageUserSessionKeys = ["user",
	"jwt-token",
	"jwrt-token",
	"user-settings",
	"profile-picture-string",
	"users-avatars"];

export default class OAuth2Service extends ApiServiceData {
	public signUp(createUserDto: UserDto, options?: {[ key: string ]: any}) {
		return this.post(relativeUrl + "/signup", createUserDto, options)
			.then(user => {
				return new User(user.data);
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async signOut() {
		const signedToken: string | null = localStorage.getItem("jwt-token");
		if (!signedToken)
			return localstorageUserSessionKeys.forEach(item => {
				localStorage.removeItem(item);
			});

		const decodedToken: JWTTokenDto = jwt_decode(signedToken);

		return this.post(relativeUrl + "/signout", {
			refreshToken: decodedToken.refreshToken
		})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Refresh token not found."
				)
					return null;
				throw error.response;
			})
			.finally(() => {
				localstorageUserSessionKeys.forEach(item => {
					localStorage.removeItem(item);
				});
				ls.remove("design-settings");
			});
	}

	public static loginUser(
		username: string,
		password: string,
		selectedOrganizationId?: string | null
	): Promise<JWTTokenDto> {
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;
		return axios
			.post(
				API_URL + relativeUrl + "/token",
				qs.stringify({
					grant_type: "passwordDomain",
					username: username,
					password: password,
					client_id: process.env.REACT_APP_PUBLIC || "",
					client_secret: process.env.REACT_APP_PRIVATE || "",
					selectedOrganizationId: selectedOrganizationId
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						BrowserBaseURL: window.location.protocol + "//" + window.location.host
					}
				}
			)
			.then(({data}: any) => {
				localStorage.setItem("jwt-token", data.signedJWTToken);
				ls.remove("design-settings");
				const decodedToken: JWTTokenDto = jwt_decode(data.signedJWTToken);
				return decodedToken;
			})
			.catch((err: any) => {
				throw err;
			});
	}

	public static loginAuthorizationCode(authorizationCode: string, redirectUri: string): Promise<string> {
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;
		return axios
			.post(
				API_URL + relativeUrl + "/token",
				qs.stringify({
					grant_type: "authorization_code",
					code: authorizationCode,
					redirect_uri: redirectUri,
					client_id: process.env.REACT_APP_PUBLIC || "",
					client_secret: process.env.REACT_APP_PRIVATE || ""
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						BrowserBaseURL: window.location.protocol + "//" + window.location.host
					}
				}
			)
			.then(({data}: any) => {
				return data.signedJWTToken;
			})
			.catch((err: any) => {
				throw err;
			});
	}

	public static refreshToken(refreshToken: string, selectedOrganizationId?: string | null): Promise<string> {
		InMemoryJWT.setIsRefreshing(true);
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;

		return axios
			.post(
				API_URL + relativeUrl + "/token",
				qs.stringify({
					grant_type: "refresh_token",
					refresh_token: refreshToken,
					client_id: process.env.REACT_APP_PUBLIC || "",
					client_secret: process.env.REACT_APP_PRIVATE || "",
					selectedOrganizationId: selectedOrganizationId ? selectedOrganizationId : this.organizationInUse
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						BrowserBaseURL: window.location.protocol + "//" + window.location.host
					}
				}
			)
			.then(({data}: any) => {
				localStorage.setItem("jwt-token", data.signedJWTToken);
				ls.remove("design-settings");
				return data.signedJWTToken;
			})
			.catch((err: any) => {
				throw err;
			}).finally(() => {
				InMemoryJWT.setIsRefreshing(false);
				InMemoryJWT.getRefreshTokenEmitter().emit(REFRESH_TOKEN_EVENTS.Unblocked);
			});
	}

	public static loginClient(): Promise<void> {
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;
		return axios
			.post(
				API_URL + relativeUrl + "/token",
				qs.stringify({
					grant_type: "client_credentials",
					client_id: process.env.REACT_APP_PUBLIC || "",
					client_secret: process.env.REACT_APP_PRIVATE || ""
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						BrowserBaseURL: window.location.protocol + "//" + window.location.host
					}
				}
			)
			.then(({data}: any) => {
				return localStorage.setItem("jwt-token", data.signedJWTToken);
			})
			.catch((err: any) => {
				throw err;
			});
	}

	static get organizationInUse(): any {
		const currentUser = this.CurrentUser;
		const organizations = currentUser && currentUser.Organizations.filter((organization: any) => !organization.Default);
		const selectedOrganization = organizations && organizations.length === 1 ? organizations[ 0 ].OrganizationId : null;
		if (selectedOrganization) localStorage.setItem(currentUser.UserId, selectedOrganization);
		return selectedOrganization;
	}

	static get isLoggedIn(): boolean {
		const signedToken: string | null = localStorage.getItem("jwt-token");
		if (!signedToken) return false;

		const decodedToken: JWTTokenDto = jwt_decode(signedToken);
		return (
			!!decodedToken.UserId &&
			!!decodedToken.accessToken &&
			!decodedToken.Roles?.some(e => e.code === "_APPLICATION")
		);
	}

	static get CurrentUser(): any {
		const signedToken: string | null = localStorage.getItem("jwt-token");
		if (!signedToken) return null;

		const decodedToken: JWTTokenDto = jwt_decode(signedToken);
		const userInfo: any = _.pick(decodedToken, ["User", "UserId", "Roles", "Organizations"]);
		return userInfo;
	}

	static get CurrentOrganization(): any | undefined {
		const currentUser = this.CurrentUser;
		return currentUser && currentUser.Organizations && currentUser.Organizations.length > 0 ? currentUser.Organizations[ 0 ] : undefined;
	}

	static get isOwner(): boolean {
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Owner);
	}

	static get isOwnerOrAdmin(): boolean {
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Owner || role.code === Role.Admin);
	}

	static get isAdmin(): boolean {
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Admin);
	}

	static get isOwnerOrAdminAtSuperOrganization(): boolean {
		const asOwner = this.CurrentUser?.Roles.find((role: any) => [Role.Owner, Role.Admin].includes(role.code));
		if (asOwner) {
			const organization = this.CurrentUser.Organizations.find((org: any) => org.UserRoleId === asOwner.UserRoleId);
			if (organization && !organization.ParentOrganizationId && organization.Default && organization.SuperOrganization) return true;
		}
		return false;
	}

	static get isTeacherAtSuperOrganization(): boolean {
		const asTeacher = this.CurrentUser?.Roles.find((role: any) => Role.Teacher === role.code);
		if (asTeacher) {
			const organization = this.CurrentUser.Organizations.find((org: any) => org.UserRoleId === asTeacher.UserRoleId);
			if (organization && !organization.ParentOrganizationId && organization.Default && organization.SuperOrganization) return true;
		}
		return false;
	}

	static get isNotAtSuperOrganization(): boolean {
		const asUser = this.CurrentUser?.Roles.find((role: any) =>
			[Role.Owner, Role.Admin, Role.Teacher, Role.Student, Role.Parent].includes(role.code));
		if (asUser) {
			const organization = this.CurrentUser.Organizations.find((org: any) => org.UserRoleId === asUser.UserRoleId);
			if (organization && !organization.ParentOrganizationId && organization.Default && !organization.SuperOrganization) return true;
		}
		return false;
	}

	static get isOwnerOrAdminAtDefaultOrganization(): boolean {
		const asOwner = this.CurrentUser?.Roles?.find((role: any) => [Role.Owner, Role.Admin].includes(role.code));
		if (asOwner) {
			const organization = this.CurrentUser.Organizations.find((org: any) => org.UserRoleId === asOwner.UserRoleId);
			if (organization && !organization.ParentOrganizationId && organization.Default) return true;
		}
		return false;
	}

	static get isTeacher(): boolean {
		const asOwner = this.CurrentUser?.Roles.find((role: any) => [Role.Owner, Role.Admin].includes(role.code));
		if (asOwner) {
			const organization = this.CurrentUser.Organizations.find((org: any) => org.UserRoleId === asOwner.UserRoleId);
			if (organization && organization.ParentOrganizationId && !organization.Default) return true;
		}
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Teacher);
	}

	static get isOnlyTeacher(): boolean {
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Teacher);
	}

	static get isStudent(): boolean {
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Student);
	}

	static get isParent(): boolean {
		return this.CurrentUser?.Roles.some((role: any) => role.code === Role.Parent);
	}

	static get CurrentToken(): any {
		const signedToken: string | null = localStorage.getItem("jwt-token");
		if (!signedToken) return null;

		const decodedToken: JWTTokenDto = jwt_decode(signedToken);
		return decodedToken;
	}

	static get CurrentUserSettings() {
		return JSON.parse(localStorage.getItem("user-settings") ?? "{}");
	}

	public async getChildren(parentEmail: string) {
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;
		return axios
			.get(API_URL + relativeUrl + "/get-children/" + parentEmail)
			.then((result: any) => {
				return result.data;
			})
			.catch((err: any) => {
				throw err;
			});
	}

	public signUpFromParent(createUserDto: any, options?: {[ key: string ]: any}) {
		return this.post(relativeUrl + "/signup-from-parent", createUserDto, options)
			.then(user => {
				return new User(user.data);
			})
			.catch(error => {
				throw error.response;
			});
	}
}
