import { Checkbox, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";
import UserService from "../../../../../../../../services/authentication/user.service";
import { showMessage } from "../../../../../../../../common/utils/Notification";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT._USERS_TAB._MODAL_PERMISSIONS";

export const ModalPermissions = (props: any) => {
	const { t: translate } = useTranslation();
	const [isAdmin, setIsAdmin] = useState(false);

	const updateRole = () => {
		new UserService()
			.updateStaffRole(props.item.id, props.item.UserRoleId, isAdmin ? "_ADMIN" : "_TEACHER")
			.then((result: any) => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._PERMISSIONS_UPDATED`));
				props.onCancel();
				return props.loadUsers();
			});
	};

	useEffect(() => {
		setIsAdmin(props.item.role === "_ADMIN");
	}, [props.item.role]);

	return (
		<Modal
			title={props.item.firstName + " " + props.item.lastName}
			visible={props.visible}
			onCancel={props.onCancel}
			onOk={updateRole}
			okText={translate(`${TRANSLATION_BASE_PATH}._BTN_OK`)}
			cancelText={translate(`${TRANSLATION_BASE_PATH}._BTN_CANCEL`)}
		>
			<Row>
				<Col span="24">
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._LABEL_EMAIL`)}
					</Text>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col span="24">
					<Input value={props.item.email}></Input>
				</Col>
			</Row>
			<Row className="pt-24">
				<Col span="24">
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._LABEL_ACCESS_RIGHTS`)}
					</Text>
				</Col>
			</Row>
			<Row className="pt-8">
				<Col span="24">
					<Checkbox disabled={true} checked={true}>
						{translate(`${TRANSLATION_BASE_PATH}._LABEL_TEACHER`)}
					</Checkbox>
				</Col>
			</Row>
			<Row className="pt-8">
				<Col span="24">
					<Checkbox checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)}>
						{translate(`${TRANSLATION_BASE_PATH}._LABEL_ADMIN`)}
					</Checkbox>
				</Col>
			</Row>
		</Modal>
	);
};
