import React from "react";
import { Row, Col, Button, Space } from "antd";

import Text from "common/components/general/Text";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";

interface SectionTitleProps {
	subtitle: string;
	tutoringButtonTitle?: string;
	onTutoringBtnClick?: () => void;
	joinCreateClassModal?: any;
}

export const SectionTitle = (props: SectionTitleProps) => {
	const RenderTutoringButton = () => {
		return (
			<Button
				onClick={() => {
					if (props.onTutoringBtnClick) props.onTutoringBtnClick();
				}}
				type="primary"
				size="middle"
				ghost
				block
			>
				{props.tutoringButtonTitle}
			</Button>
		)
	}
	return (
		<Row className="main__section_title">
			<Col span="10">
				<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
					{props.subtitle}
				</Text>
			</Col>
			<Col xs={0} sm={24} md={14} lg={14} xl={14} xxl={14}>
				<Row justify="end">
					<Space>
						{props.tutoringButtonTitle && RenderTutoringButton()}
						{ props.joinCreateClassModal }
					</Space>
				</Row>
			</Col>
			<Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
				<Row justify="center">
					<Space direction="vertical">
						{props.tutoringButtonTitle && RenderTutoringButton()}
						{ props.joinCreateClassModal }
					</Space>
				</Row>
			</Col>			
		</Row>
	);
};
