import { Space } from "antd"
import React from "react"
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";

export const UserRole = (props: any) => {
	const roleIconMap:  Map<string, string> = new Map([
			["_OWNER", "ri-user-star-line"],
			["_ADMIN", "ri-admin-line"]
	]);
	const roleNameMap:  Map<string, string> = new Map([
			["_OWNER", "Pronar"],
			["_ADMIN", "Administrator"]
		]);

	return (
		<Space className="users__elipse color-blue-6 background-color-blue-1">
			<Icon type={roleIconMap.get(props.role)??"ri-user-star-line"}/>
			<Text fontSize="14" lineHeight="22">
				{props.name??roleNameMap.get(props.role)}
			</Text>
		</Space>
	)
}