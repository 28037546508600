import { Col, Empty, Row, Skeleton } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import { SectionTitle } from "../../../../components/sectionTitle/SectionTitle";
import { SectionContent } from "../../../../components/sectionContent/SectionContent";
import {DASHBOARD_PATH} from "../../../dashboard";
import CounterCard from "common/components/_counterCard/CounterCard";
import { ResponsiveSearchBox } from "../../../../components/searchBox/ResponsiveSearchBox";
import { SearchUtil } from "../../../../util/SearchData";
import { AdminTable } from "../../../../components/table/table";
import { Space } from "common/components/layout/Space";
import { CircularIcon } from "common/components/general/CircularIcon";
import Text from "common/components/general/Text";
import { formatTotal } from "../../../../util/formatTotal";
import {ORGANIZATIONS_PATH} from "../list";
import AnalyticsUserService from "services/analytics/user.service";
import { InstitutionCode } from "../../../../components/institutionCode/InstitutionCode";
import { sumTotal } from "../../../../util/sumTotal";

export const TEACHER_PATH = "teacher";
const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._ORGANIZATION._TEACHER";

class TeacherComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			errorMessage: "",
			teacherDto: {},
			cardTotalVirtualClasses: null,
			cardTotalStudents: null,
			cardTotalStudentsLoading: null,
			cardTotalLessons: null,
			data: [],
			filteredData: []
		};
	}

	componentDidMount() {
		this.setState({
			loading: true
		});

		this.setState(
			{
				organizationId: this.props.match.params.organizationId,
				organizationName: this.props.match.params.organizationName,
				teacherId: this.props.match.params.teacherId,
				teacherName: this.props.match.params.teacherName
			},
			() => {
				this.getTeacherTotalStudents();
				return this.loadTable().then(() => {
					return this.setState(
						{
							cardTotalVirtualClasses: this.state.data.length,
							cardTotalLessons: sumTotal(this.state.data, "lessonCount")
						},
						() => {
							this.setState({ loading: false });
						}
					);
				});
			}
		);
	}

	getTeacherTotalStudents() {
		this.setState({
			cardTotalStudentsLoading: true
		});
		return new AnalyticsUserService()
			.countTeacherTotalStudents(this.state.teacherId)
			.then((result: any) => {
				return this.setState({
					cardTotalStudents: result
				});
			})
			.finally(() => {
				this.setState({
					cardTotalStudentsLoading: false
				});
			});
	}

	loadTable = () => {
		return new AnalyticsUserService()
			.getTeacherTotalsReport(this.state.teacherId)
			.then((result: any) => {
				return this.setState({
					data: result,
					filteredData: result
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				});
			});
	};

	getTotal = (data: any, field: string) => {
		return _.sum(data[field]);
	};

	search = (value: string) => {
		this.setState({
			filteredData: SearchUtil(this.state.data, value, { column1: "name", column2: "subjectName" })
		});
	};

	get columnsTable() {
		const { t: translate } = this.props;

		return [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._VIRTUAL_CLASSES`),
				dataIndex: "name",
				key: "name",
				render: (text: any, row: any) => {
					return (
						<Row align="middle">
							<Space>
								<Col xs={0} sm={4} md={4} lg={4} xl={3} xxl={2}>
									<CircularIcon type="ri-door-open-line" />
								</Col>
								<Col>
									<Row>{text}</Row>
									<Row>
										<InstitutionCode code={row.code} />
									</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._SUBJECT`),
				dataIndex: "subjectName",
				key: "subjectName",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.subjectName - b.subjectName,
				responsive: ["sm"],
				align: "left",
				render: (text: any, row: any) => {
					return (
						<Row align="middle">
							<Col>
								<Row>{text}</Row>
								<Row>
									<Text fontSize="12" lineHeight="20" className="color-gray-7">
										{translate(`_ADMIN._LEVELS._${row.level}`)}
									</Text>
								</Row>
							</Col>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STUDENTS`),
				dataIndex: "studentCount",
				key: "studentCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.studentCount - b.studentCount,
				responsive: ["md"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._LESSONS`),
				dataIndex: "lessonCount",
				key: "lessonCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.lessonCount - b.lessonCount,
				responsive: ["md"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			}
		];
	}

	render() {
		const { t: translate } = this.props;
		const subTitle1 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const subTitle2 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_6_LONG");
		const cardTitleVirtualClasses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._CLASSES`);
		const cardTitleStudents = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._STUDENTS`);
		const cardTitleLessons = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._LESSONS`);

		return (
			<>
				<SectionTitle
					title={this.state.teacherName}
					subtitle={[subTitle1, subTitle2, this.state.organizationName, this.state.teacherName]}
					subtitleUrl={[
						DASHBOARD_PATH,
						ORGANIZATIONS_PATH,
						ORGANIZATIONS_PATH + "/" + this.state.organizationId
					]}
				/>
				<SectionContent>
					<Row justify="space-between">
						<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
							<CounterCard
								title={cardTitleVirtualClasses}
								icon="ri-user-2-line"
								data={this.state.cardTotalVirtualClasses}
								loading={this.state.loading}
							/>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
							<CounterCard
								title={cardTitleStudents}
								icon="ri-door-open-line"
								data={this.state.cardTotalStudents}
								loading={this.state.cardTotalStudentsLoading}
							/>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
							<CounterCard
								title={cardTitleLessons}
								icon="ri-user-line"
								data={this.state.cardTotalLessons}
								loading={this.state.loading}
							/>
						</Col>
					</Row>
					<Row justify="end">
						<ResponsiveSearchBox placeholder={translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} onSearch={this.search} />
					</Row>
					<Row>
						<Col span={24}>
							<Skeleton loading={this.state.loading}>
								<AdminTable
									columns={this.columnsTable}
									dataSource={this.state.filteredData}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
											/>
										)
									}}
								/>
							</Skeleton>
						</Col>
					</Row>
				</SectionContent>
			</>
		);
	}
}

const Teacher = withTranslation()(TeacherComponent);
export default Teacher;
