import * as Yup from "yup";

export const formValidator = () => {
	return Yup.object({
		name: Yup.string()
        	.min(2, 'Emri sduhet te jete me i shkurter se 2 karaktere!')
        	.max(255, 'Emri nuk duhet te jete me shume se 255 karaktere!')
        	.required('Nuk mund te jete bosh!'),
	});
};
