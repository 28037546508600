import UserDto from "../../../../dtos/authentication/user/user.dto";

export class CreateParentInviteChildNotificationDto{
	childEmail!: string;
	parentEmail!: string;
	recordId!: string
	currentUser!: UserDto;
	type!: string;

	constructor(childEmail: string, parentEmail: string, recordId: string, currentUser: UserDto) {
		this.childEmail = childEmail;
		this.parentEmail = parentEmail;
		this.recordId = recordId;
		this.currentUser = currentUser;
		this.type = "PARENT_INVITE";
	}

	get parentFullName() {
		return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
	}

	get notificationTitle(){
		return `${this.parentFullName} dëshiron të behët prindi/kujdestari yt.`;
	};

	get notificationMessage() {
		return `Pasi të pranoni ftesën, ${this.currentUser.firstName} do të ketë të drejta të ndjekë progresin tuaj`;
	}
}