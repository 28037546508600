import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClassroomService from "services/administration/classroom.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { CourseView } from "../_courseView";
import { useStoreActions } from "easy-peasy";

const TRANSLATION_BASE_PATH = "_MARKETPLACE._SECTION_MY_COURSES";
const MAX_ITEMS_TO_LOAD = 10;
const SECTION_SUBPATH = "my-courses";

export const SectionMyCourses = (props: any) => {
	const { t: translate } = useTranslation();
	const [items, setItems] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [imagePrefix, setImagePrefix] = useState(null);
	const updateTotalMyItems = useStoreActions((actions: any) => actions.subjects.updateTotalMyItems);

	const loadData = () => {
		setLoading(true);
		props.handleSearchProgress(1);
		new ClassroomService()
			.getForMarketPlacePrivateOrgClassrooms({
				key: props.searchKey,
				limit: props.singleSection ? null : MAX_ITEMS_TO_LOAD,
				filters: props.filters
			})
			.then((result: any) => {
				setImagePrefix(result[0]);
				setItems(result[1]);
				updateTotalMyItems(result[2]);
			})
			.finally(() => {
				setLoading(false);
				props.handleSearchProgress(-1);
			});
	};

	useEffect(() => {
		loadData();
	}, [props.searchKey, props.filters, props.singleSection]);

	return (
		<CourseView
			title={translate(`${TRANSLATION_BASE_PATH}_TITLE`, {
				organizationName: OAuth2Service.CurrentUser.Organizations[0].Name
			})}
			icon="ri-user-voice-line"
			items={items}
			imagePrefix={imagePrefix}
			loading={loading}
			showAllLink={props.singleSection ? null : SECTION_SUBPATH}
		/>
	);
};
