import React, { Component } from "react";
import TemplateService from "services/design/template.service";
import { Col, Empty, Row, Skeleton } from "antd";
import { Space } from "common/components/layout/Space";
import { NavLink } from "react-router-dom";
import { ADMIN_PATH } from "../../../../../../index";
import { ORGANIZATIONS_PATH } from "../../../list";
import Button from "common/components/general/Button";
import { SectionContent } from "../../../../../../components/sectionContent/SectionContent";
import { DesignFiles } from "../../../../components/design/DesignFiles";
import { AdminTable } from "../../../../../../components/table/table";
import { withTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._CUSTOMIZE";

class Customize extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			organizationId: null,
			templates: [],

			// Design files
			primaryLogo: "",
			primaryInverseLogo: "",
			favicon: ""
		};
	}

	componentDidMount() {
		this.setState(
			{
				organizationId: this.props.match.params.organizationId
			},
			() => {
				this.loadTemplates();
			}
		);
	}

	/**
	 * Load organization data
	 */
	loadTemplates = () => {
		return new TemplateService().findAll(this.state.organizationId).then((res: any) => {
			return this.setState({
				templates: res
			});
		});
	};

	get columnsTable() {
		const { t: translate } = this.props;

		return [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._PAGENAME`),
				dataIndex: "title",
				key: "title",
				render: (text: any) => {
					return (
						<Row key="title" align="middle">
							<Space>
								<Col>
									<Row>{text}</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ACTIONS`),
				dataIndex: "id",
				key: "id",
				fixed: "right",

				width: 150,
				render: (id: any) => {
					return (
						<Row key="actions" align="middle">
							<Space>
								<NavLink
									to={`/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${this.state.organizationId}/customize/${id}`}
								>
									<Button type="primary" shape="circle" icon="ri-edit-line" />
								</NavLink>
							</Space>
						</Row>
					);
				}
			}
		];
	}

	render() {
		const { t: translate } = this.props;

		return (
			<>
				<SectionContent>
					{this.props.organizationDto && (
						<DesignFiles
							domain={this.props.organizationDto.domain}
							default={this.props.organizationDto.default}
							organizationId={this.state.organizationId}
						/>
					)}
					<Row>
						<Col span={24}>
							<Skeleton loading={this.state.loading}>
								<AdminTable
									columns={this.columnsTable}
									dataSource={this.state.templates}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
											/>
										)
									}}
								/>
							</Skeleton>
						</Col>
					</Row>
				</SectionContent>
			</>
		);
	}
}

const CustomizePage = withTranslation()(Customize);
export default CustomizePage;
