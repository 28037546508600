import * as Yup from "yup";

const TRANSLATION_BASE_PATH_KEY = "_COURSE_BUILDER._REQUIRED";
const TRANSLATION_QUIZ_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._QUIZ";

export const formValidator = (translate: (key: string) => string) => {
	return Yup.object({
		questionTitle: Yup.string()
			.trim()
			.required(translate(TRANSLATION_BASE_PATH_KEY)),
		questionType: Yup.string()
			.trim()
			.required(translate(TRANSLATION_BASE_PATH_KEY)),
		correctAnswerId: Yup.string().when("questionType", {
			is: value => {
				return value === "singleChoice";
			},
			then: Yup.string()
				.trim()
				.required(translate(TRANSLATION_BASE_PATH_KEY))
		}),
		answers: Yup.array()
			.of(
				Yup.object().shape({
					title: Yup.string().required(translate(TRANSLATION_BASE_PATH_KEY)),
					id: Yup.string().required(translate(TRANSLATION_BASE_PATH_KEY)),
					correct: Yup.boolean()
				})
			)
			.compact(x => !x?.correct)
			.required(translate(`${TRANSLATION_QUIZ_BASE_PATH}._AT_LEAST_ONE_CORRECT_ANSWER`))
	});
};
