export const numberRegx = "[0-9]";
export const lowercaseRegx = "[a-z]";
export const uppercaseRegx = "[A-Z]";
// eslint-disable-next-line no-useless-escape
export const specialCharRegx = RegExp("[<>\"'()`.,\\+\\-_=\\/\\\\\|:;?{}[\\]~?!@#$%^&*]");
export const generalPasswordRegx = new RegExp(
	"^(?=.*[A-Z])(?=.*[0-9])(?=.*[<>\"'()`.,\\+\\-_=\\/\\\\\|:;?{}[\\]~?!@#$%^&*])"
);
export const alphanumericOnlyRegx = new RegExp("^[a-zA-Z0-9]+$");
export const usernameRegx = new RegExp("^[a-zA-Z0-9.,\\+\\-_]+$");
export const numberOnlyRegx = new RegExp("^[0-9]+$");
export const numbersAndSpacesOnlyRegx = new RegExp("[0-9]+$");
export const numbersAndSlashOnlyRegx = new RegExp("[0-9/]$");
export const underscoreRegx = new RegExp("^[0-9s+_]*$");
export const publicUrlRegx = new RegExp("^[a-zA-Z0-9-_]*$");
export const urlRegx = new RegExp(
	"^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
);
