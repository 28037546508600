import { Avatar, Col, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { allowSwitchRoleAsTeacherAndParent } from "common/components/_loggedInMenu/LoggedInMenu";

const TRANSLATION_BASE_PATH = "_SELECT_ORGANIZATION._CHOOSE_ROLE";

interface ChooseRoleProps {
	onChooseAsStudent: () => void;
	onChooseAsTeacher: () => void;
	onChooseAsParent: () => void;
}
export const ChooseRole = (props: ChooseRoleProps) => {
	const { t: translate } = useTranslation();
	return (
		<>
			<Row>
				<Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
					<Space direction="vertical" size={16} align="center" className="full__width">
						<Text fontSize="30" lineHeight="38" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_PORTAL`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_PORTAL_DESCRIPTION`)}
						</Text>
					</Space>
				</Col>
			</Row>
			<Row className="mt-32">
				<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					xxl={12}
					className="choose__role_option_card"
					onClick={props.onChooseAsStudent}
				>
					<Space direction="horizontal" size={8} align="center">
						<Avatar
							icon={<Icon type="ri-user-line" className="color-blue-6" />}
							className="background-color-blue-1"
						/>
						<Space direction="vertical">
							<Text fontSize="14" lineHeight="22" className="color-gray-9">
								{translate(`${TRANSLATION_BASE_PATH}._STUDENT_PORTAL`)}
							</Text>
						</Space>
					</Space>
					<Icon type="ri-arrow-right-line" className="color-gray-6" />
				</Col>
			</Row>
			{allowSwitchRoleAsTeacherAndParent() && (
				<Row className="mt-8">
					<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={12}
						xl={12}
						xxl={12}
						className="choose__role_option_card"
						onClick={props.onChooseAsTeacher}
					>
						<Space direction="horizontal" size={8} align="center">
							<Avatar
								icon={<Icon type="ri-user-2-line" className="color-blue-6" />}
								className="background-color-blue-1"
							/>
							<Space direction="vertical">
								<Text fontSize="14" lineHeight="22" className="color-gray-9">
									{translate(`${TRANSLATION_BASE_PATH}._TEACHER_PORTAL`)}
								</Text>
							</Space>
						</Space>
						<Icon type="ri-arrow-right-line" className="color-gray-6" />
					</Col>
				</Row>
			)}
			{allowSwitchRoleAsTeacherAndParent() && (
				<Row className="mt-8">
					<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={12}
						xl={12}
						xxl={12}
						className="choose__role_option_card"
						onClick={props.onChooseAsParent}
					>
						<Space direction="horizontal" size={8} align="center">
							<Avatar
								icon={<Icon type="ri-parent-line" className="color-blue-6" />}
								className="background-color-blue-1"
							/>
							<Space direction="vertical">
								<Text fontSize="14" lineHeight="22" className="color-gray-9">
									{translate(`${TRANSLATION_BASE_PATH}._PARENT_PORTAL`)}
								</Text>
							</Space>
						</Space>
						<Icon type="ri-arrow-right-line" className="color-gray-6" />
					</Col>
				</Row>
			)}
		</>
	);
};
