import React from "react";
import { Card, Col, Row, Skeleton, Space } from "antd";
import { withTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import { formatTotal } from "../../../scenes/admin/util/formatTotal";
import { CircularIcon } from "common/components/general/CircularIcon";

class CounterCardComponent extends React.Component<any, any> {
	getCard = (data: any, label: string, icon: string) => {
		return (
			<Card bordered={false} className="mb-24 box_shadow">
				<Skeleton loading={this.props.loading}>
					<Row align="middle" justify={this.props.allRow ? "center" : "start"}>
						<Space size="middle">
							<Col>
								<CircularIcon type={icon}></CircularIcon>
							</Col>
							<Col>
								<Row>
									<Col span="24">
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{label}
										</Text>
									</Col>
									<Col>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{formatTotal(data)}
										</Text>
									</Col>
								</Row>
							</Col>
						</Space>
					</Row>
				</Skeleton>
			</Card>
		);
	};

	render() {
		return this.getCard(this.props.data, this.props.title, this.props.icon);
	}
}

const CounterCard = withTranslation()(CounterCardComponent);
export default CounterCard;
