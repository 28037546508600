import React, { ReactNode } from "react";

import { Space as AntSpace } from "antd";
import { SpaceProps as AntSpaceProps } from "antd/lib/space";

interface SpaceProps extends AntSpaceProps {
	children: ReactNode;
}

export const Space = (props: SpaceProps) => {
	return <AntSpace {...props} />;
};
