import React, { ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import classnames from "classnames";

import Error from "./FormItemError";
import Input, { InputProps } from "../components/Input";
import { FieldProps } from "./FieldProps";
import Label from "../components/Label";
import Text from "../../general/Text";
import { Tooltip } from "antd";

type FormikInputProps = InputProps &
	FieldProps & {
		hint?: string | ReactNode;
		labelInfo?: string | ReactNode;
		disabledTooltip?: string;
		disabledError?: boolean;
	};

const FormikDefaultInputField: React.FC<FormikInputProps> = ({ hint, labelInfo, ...props }) => {
	const [field, meta] = useField(props.name);
	const { setFieldValue } = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : props.disabledError ? null : <Error></Error>;
	const id = props.id || props.name;

	const onChange = (e: any) => {
		setFieldValue(props.name, e.target.value);
	};

	const errorClassname = classnames({ error_input: showError });

	const hintContent = hint ? (
		<Text fontSize="14" lineHeight="22" className="color-gray-7">
			{hint}
		</Text>
	) : null;

	const input = (
		<Input
			{...field}
			{...props}
			id={id}
			onChange={onChange}
			className={`${errorClassname} ${props.className ?? ""}`}
		/>
	);

	return (
		<div>
			{props.label && (
				<Label htmlFor={id} className="hand-on-hover">
					{props.label}{" "}
					{labelInfo ? (
						<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
							{labelInfo}
						</Text>
					) : null}
				</Label>
			)}
			{hintContent}
			{props.disabled && props.disabledTooltip ? (
				<Tooltip title={props.disabledTooltip} placement="bottomLeft" trigger={["hover", "click"]}>
					<div>{input}</div>
				</Tooltip>
			) : (
				input
			)}
			{showError && error}
		</div>
	);
};

export default FormikDefaultInputField;
