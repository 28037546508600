import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		description: Yup.string()
			.nullable()
			.trim()
			.optional(),
		subjectId: Yup.string()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),

		gradeId: Yup.string()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		stage: Yup.string()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		price: Yup.number()
			.nullable()
			.notRequired()
			.typeError(translate(`${TRANSLATION_BASE_PATH}._ONLY_NUMBERS`))
			.test("is-valid-number", translate(`${TRANSLATION_BASE_PATH}._ZERO_OR_POSITIVE_NUMBER_REQUIRED`), function (value) {
				return (
					value === undefined || value >= 0
				);
			})
	});
