import React from "react";
import { Card } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import { MATERIALS_PATH, SUBJECTS_PATH } from "../../../../scenes/subjects/scenes/materialItems";
import { LessonAsTodaysTaskDto } from "dtos/administration/lesson/lessonAsTodaysTask.dto";

interface QuizTaskProps {
	item: LessonAsTodaysTaskDto;
	viewAsTeacher: boolean;
	classroomId: string;
}

export const QuizTask = (props: QuizTaskProps) => {
	const history = useHistory();

	let actionsMenu = null;
	if (!props.viewAsTeacher && props.item.isSubjectMaterial) {
		actionsMenu = (
			<Icon
				type="ri-arrow-right-line"
				className="cursor_pointer color-gray-6 task__redirect_icon"
				fontSize="16"
				onClick={() => history.push(`/${SUBJECTS_PATH}/${MATERIALS_PATH}/video/${props.item.id}`)}
			/>
		);
	}

	return (
		<Card className="quiz__task_card">
			<div className="quiz__task_card_info">
				<TypeIcon type="QUIZ" />
				<div className="quiz__info">
					<Text fontSize="14" lineHeight="22" className="color-gray-9">
						{props.item.title.length > 30 ? `${props.item.title.substring(0, 30)}...` : props.item.title}
					</Text>
					<Text fontSize="12" lineHeight="20" className="color-gray-7">
						<Icon type="ri-timer-line" fontSize="12" />{" "}
						{moment(props.item.endDate).format("DD.MM.YYYY HH:mm")}
					</Text>
				</div>
			</div>
			{actionsMenu}
		</Card>
	);
};
