import React from "react";

import { useField, useFormikContext } from "formik";
import Select, { SelectProps } from "../components/Select";
import Error from "./FormItemError";
import { FieldProps } from "./FieldProps";
import Label from "../components/Label";
import Text from "../../general/Text";

type FormiKSelectFieldProps = SelectProps & FieldProps & { labelInfo?: string };

const FormikSelectFieldUpdate: React.FC<FormiKSelectFieldProps> = ({ label, labelInfo, onChange, ...props }) => {
	const [, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	const onChangeHandler = (value: any, key: any): void => {
		context.setFieldValue(props.name, value);

		if (onChange) {
			onChange(value, key);
		}
	};

	const onBlur = (): void => {
		context.setFieldTouched(props.name);
	};

	return (
		<div>
			<Label htmlFor={id} className="hand-on-hover">
				{label}{" "}
				{labelInfo ? (
					<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
						{labelInfo}
					</Text>
				) : null}
			</Label>
			<Select {...props} id={id} onChange={onChangeHandler} onBlur={onBlur}>
				{props.children}
			</Select>
			{error}
		</div>
	);
};

export default FormikSelectFieldUpdate;
