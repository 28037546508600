import React from "react";

import { ItemType } from "services/domain/administration/ItemType";
import Icon from "../../general/Icon";

const mapTypeWithIcon = new Map<ItemType, string>([["VIDEO", "ri-play-fill"],
["QUIZ", "ri-question-fill"],
["MEETING", "ri-live-fill"],
["ASSIGNMENT", "ri-todo-fill"],
["PDF", "ri-slideshow-2-line"],
["ENROLLMENT_STATUS_CHANGE", "ri-door-open-line"],
["PARENT_INVITE", "ri-team-line"],
["OWNER_ORGANIZATION", "ri-building-4-line"],
["ADMIN_ORGANIZATION", "ri-building-4-line"],
["TEACHER_ORGANIZATION", "ri-building-4-line"],
["ACTIVE_ORGANIZATION", "ri-building-4-line"],
["INACTIVE_ORGANIZATION", "ri-forbid-2-line"],
["PENDING_ORGANIZATION", "ri-time-line"],
["ASSIGNMENT_SUBMITTED", "ri-todo-fill"]]
);

const mapTypeWithBackgroundColor = new Map<ItemType, string>([["VIDEO", "background-color-red-1"],
["QUIZ", "background-color-gold-1"],
["MEETING", "background-color-blue-1"],
["ASSIGNMENT", "background-color-purple-1"],
["PDF", "background-color-cyan-1"],
["ENROLLMENT_STATUS_CHANGE", "background-color-blue-1"],
["PARENT_INVITE", "background-color-blue-1"],
["OWNER_ORGANIZATION", "background-color-blue-1"],
["ADMIN_ORGANIZATION", "background-color-blue-1"],
["TEACHER_ORGANIZATION", "background-color-blue-1"],
["ACTIVE_ORGANIZATION", "background-color-blue-1"],
["INACTIVE_ORGANIZATION", "background-color-red-1"],
["PENDING_ORGANIZATION", "background-color-gray-3"],
["ASSIGNMENT_SUBMITTED", "background-color-blue-1"]]);

export const mapTypeWithColor = new Map<ItemType, string>([["VIDEO", "color-red-6"],
["QUIZ", "color-gold-6"],
["MEETING", "color-blue-6"],
["ASSIGNMENT", "color-purple-6"],
["PDF", "color-cyan-6"],
["ENROLLMENT_STATUS_CHANGE", "color-blue-6"],
["PARENT_INVITE", "color-blue-6"],
["OWNER_ORGANIZATION", "color-blue-6"],
["ADMIN_ORGANIZATION", "color-blue-6"],
["TEACHER_ORGANIZATION", "color-blue-6"],
["ACTIVE_ORGANIZATION", "color-blue-6"],
["INACTIVE_ORGANIZATION", "color-red-6"],
["PENDING_ORGANIZATION", "color-gray-6"],
["ASSIGNMENT_SUBMITTED", "color-blue-6"]]);

interface TypeIconProps {
	type: ItemType;
	fontSize?: any;
	smallIcon?: any;
}

export const TypeIcon = (props: TypeIconProps) => {
	const iconProps = {
		fontSize: props.fontSize
	};
	return (
		<div className={`${props.smallIcon ? "subject__type_icon_content__small"
			: "subject__type_icon_content"} ${mapTypeWithBackgroundColor.get(props.type)}`}>
			<Icon
				type={mapTypeWithIcon.get(props.type) ?? ""}
				className={mapTypeWithColor.get(props.type) ?? ""}
				{...(props.fontSize ? iconProps : {})}
			/>
		</div>
	);
};
