import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Icon from "common/components/general/Icon";
import { TextOverflowTooltip } from "./TextOverflowTooltip";
import classNames from "classnames";

const TRANSLATION_BASE_PATH = "_MARKETPLACE";

export interface SectionOrganisationsProps {
	organizations: { name: string; logo: string; id: string }[];
	activeOrganization?: string;
	setActiveOrganization: (id: string) => void;
}

export const SectionOrganisations = (props: SectionOrganisationsProps) => {
	const { organizations } = props;
	const [state, setState] = useState({
		currentPage: 0
	});
	const { t: translate } = useTranslation();

	/**
	 * Region responsiveness functions
	 * @param windowWidth
	 */
	function perPageCalculator(windowWidth: number) {
		if (windowWidth >= 1600) {
			return 9;
		}
		if (windowWidth > 991) {
			return 5 + Math.ceil((windowWidth - 991) / 135);
		}
		if (windowWidth > 650) {
			return 5;
		}
		if (windowWidth > 530) {
			return 4;
		}
		if (windowWidth < 375) {
			return 2;
		}

		return 3;
	}

	const [perPage, setPerPage] = useState(perPageCalculator(window.innerWidth));

	const handleResize = () => {
		setPerPage(perPageCalculator(window.innerWidth));

		if (perPageCalculator(window.innerWidth) !== perPage) {
			setState(state => ({ ...state, currentPage: 0 }));
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const caroselRef = useRef<Carousel>(null);
	const organisationsPages = _.chunk(organizations, perPage);

	const setCurrentPage = (currentPage: number) => {
		setState(state => ({ ...state, currentPage }));
	};
	const onNextSlideClick = () => {
		caroselRef?.current?.next();
		setCurrentPage(state.currentPage + 1);
	};

	const onPreviousSlideClick = () => {
		caroselRef?.current?.prev();
		setCurrentPage(state.currentPage - 1);
	};
	return (
		<StyledOrganisations>
			<p className="section-title">{translate(`${TRANSLATION_BASE_PATH}._SECTION_ORGANISATIONS`)}</p>
			<div className="organisations-wrapper">
				{state.currentPage > 0 && (
					<button className="next-button" onClick={onPreviousSlideClick}>
						<Icon type="ri-arrow-left-s-line" />
					</button>
				)}
				<div className="carousel-wrapper">
					<Carousel autoplay={false} ref={caroselRef} dots={{ className: "dots-container-class" }}>
						{organisationsPages.map((page, pageIndex) => (
							<div key={pageIndex} className="organisations-slider-page">
								{page.map((o: any, index) => (
									<Organisation
										onClick={() =>
											props.setActiveOrganization(o.id === props.activeOrganization ? null : o.id)
										}
										logo={o.logo}
										key={index}
										name={o.name}
										active={o.id === props.activeOrganization}
									/>
								))}
							</div>
						))}
					</Carousel>
				</div>

				{state.currentPage < organisationsPages.length - 1 && (
					<button className="next-button" onClick={onNextSlideClick}>
						<Icon type="ri-arrow-right-s-line" />
					</button>
				)}
			</div>
		</StyledOrganisations>
	);
};

const Organisation = ({
	logo,
	name,
	active,
	onClick
}: {
	logo: string;
	name: string;
	active: boolean;
	onClick: () => void;
}) => {
	const classes = classNames({ organisation: true, ["organisation--active"]: active });
	return (
		<div className={classes} onClick={() => onClick()}>
			<img alt={name} src={logo} />
			<TextOverflowTooltip className="name">{name}</TextOverflowTooltip>
		</div>
	);
};

const StyledOrganisations = styled.div`
	margin-top: 22px;
	.section-title {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		color: #2d3748;

		@media (max-width: 500px) {
			font-size: 16px;
		}
	}

	.organisations-wrapper {
		margin-top: 36px;
		display: flex;
		column-gap: 21px;
		padding-bottom: 19px;
		padding-right: 62px;

		.carousel-wrapper {
			width: calc(100% - ((32px + 21px) * 1));
			.dots-container-class {
				bottom: -32px;
				> li {
					width: 5px;
					height: 5px;
					> button {
						width: 5px;
						height: 5px;
						background: #d8e0e8;
						border-radius: 50%;
						opacity: 1;
					}
					&.slick-active {
						> button {
							background: #2063e3;
						}
					}
				}
			}
		}

		.organisations-slider-page {
			display: flex !important;
			column-gap: 19px;
		}

		.next-button {
			border: 1px solid #cbd5e0;
			box-sizing: border-box;
			border-radius: 24px;
			background: #ffffff;
			cursor: pointer;
			height: 32px;
			width: 32px;
			margin-top: 24px;
		}

		.organisation {
			width: 80px;
			> img {
				cursor: pointer;
				object-fit: cover;
				width: 80px;
				height: 80px;
				border-radius: 6px;
				box-shadow: 0px 1px 1px rgba(8, 24, 35, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.06);
			}

			.name {
				font-family: Inter;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 20px;

				margin-top: 10px;
				margin-bottom: 0;

				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-align: center;
			}
		}
		.organisation--active img {
			border: 2px solid #2063e366;
		}
	}
	@media (max-width: 320px) {
		.organisations-wrapper {
			.carousel-wrapper {
				width: calc(100% - ((-14px + 38px) * 1));
			}
			.next-button {
				margin-left: -16px;
			}
		}
	}
	@media (min-width: 375px) and (max-width: 425px) {
		.organisations-wrapper {
			.carousel-wrapper {
				width: calc(100% - ((-47px + 38px) * 1));
			}
			.next-button {
				margin-left: -16px;
			}
		}
	}
`;
