import {v4 as uuidv4} from "uuid";
import FileDto from "./file.dto";

export default class AssignmentUserCommitFileDto {
	readonly id!: string;
	readonly AssignmentUserCommitId!: string;
	readonly FileId?: string;
	readonly File?: FileDto;

	constructor(data: {
		AssignmentUserCommitId?: string; FileId?: string; File?: FileDto;[ key: string ]: any
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
