import { Card, Col, Row, Space } from "antd";
import React from "react";
import { showError, showMessage } from "../../../../../../common/utils/Notification";
import OAuth2Service from "../../../../../../services/authentication/oauth2.service";
import UserService from "../../../../../../services/authentication/user.service";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Popconfirm from "../../../../../../common/components/feedback/Popconfirm";
import { useTranslation } from "react-i18next";
import { JWTUserInfoDto } from "../../../../../../dtos/authentication/token/jwtUserInfo.dto";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._MY_PROFILE._UNLINK_PARENT";

export const UnlinkFromParent = (props: any) => {
	const { t: translate } = useTranslation();
	
	const unlinkFromParent = () => {
		return new UserService().removeParent()
			.then(() => {
					const decodedToken = OAuth2Service.CurrentToken;
					const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
					const selectedOrganizationId = localStorage.getItem(currentUserInfo?.UserId || "");
					return OAuth2Service.refreshToken(decodedToken.refreshToken, selectedOrganizationId);
			})
			.then(() => {
				props.refreshUser(OAuth2Service.CurrentUser);
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._SUCCESS`));
			})	
			.catch((error: any) => {
				showError("ERROR");
			});
	}

	const GetUnlinkFormParent = () => {
		return (
			<Row className="pt-24 pb-24" justify="center">
				<Col xs={24} sm={24} md={20} lg={16} xl={12} xxl={12}>
					<Card title={null}>
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Row>
									<Text fontSize="16" lineHeight="24" className="color-gray-8">
									{props.user.parentEmail}
									</Text>
								</Row>
								<Row>
									<Text fontSize="14" lineHeight="22" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._NAME`)}
									</Text>
								</Row>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Row justify="end" align="middle">
										<Popconfirm
										title={translate(`${TRANSLATION_BASE_PATH}._CONFIRM_MESSAGE`,
											{ ParentFullName: props.user.parentEmail })}
											onConfirm={async () => unlinkFromParent()}
											okText={translate(`${TRANSLATION_BASE_PATH}._LINK`)}
											cancelText={"Anullo"}
										>
											<Space className="cursor_pointer"> 
												<Icon type="ri-delete-bin-line" className="color-red-6"/>
												<Text fontSize="14" lineHeight="20" className="color-red-6">
													{translate(`${TRANSLATION_BASE_PATH}._LINK`)}
												</Text>
											</Space>
										</Popconfirm>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		)
	}

	return (
		GetUnlinkFormParent()
	)
}