import React, { useState } from "react";
import { FieldArray, Formik } from "formik";
import { Checkbox, Col, Drawer, Row, Space } from "antd";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { useTranslation } from "react-i18next";
import Input from "common/components/dataEntry/formik/FormikInputField";
import FormikCheckboxField from "common/components/dataEntry/formik/FormikCheckboxField";
import { formValidator } from "./ValidationSchema";
import Button from "common/components/general/Button";
import InvitationService from "../../../../../../../../services/administration/invitation.service";
import { showError, showMessage } from "../../../../../../../../common/utils/Notification";
import _ from "lodash";
import { InvitesSentPopup } from "./invitesSentPopup/InvitesSentPopup";
import UserService from "../../../../../../../../services/authentication/user.service";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT._USERS_TAB._DRAWER";

export const InviteUsers = (props: any) => {
	const { t: translate } = useTranslation();
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [inviteSentPopupVisible, setInviteSentPopupVisible] = useState<boolean>(false);
	const [countInvites, setCountInvites] = useState<number>(0);

	let submitFormHandler: any = Promise;

	const drawerFooter = (
		<Space direction="horizontal" size={8} style={{ float: "right" }}>
			<Button type="default" onClick={props.onClose} disabled={submitting}>
				{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
			</Button>
			<Button type="primary" disabled={!isFormValid} onClick={() => submitFormHandler()} loading={submitting}>
				{translate(`${TRANSLATION_BASE_PATH}._SEND_INVITES`)}
			</Button>
		</Space>
	);

	const emailAddressesHeader = (values: any) => {
		return (
			<Row>
				<Col span="16">
					<Space>
						<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._EMAIL_ADDRESS`)}{" "}
							{values.emails.filter((item: any) => item.email && item.email !== "").length > 0
								? `(${values.emails.filter((item: any) => item.email && item.email !== "").length})`
								: ""}
						</Text>
						<Icon
							type="ri-information-line"
							fontSize="20"
							className="color-blue-6"
							tooltip={translate(`${TRANSLATION_BASE_PATH}._PASTE_MULTIPLE_EMAILS_TOOLTIP`)}
						/>
					</Space>
				</Col>
				<Col span="4">
					<Row justify="center" className="pt-8">
						<Text fontSize="11" lineHeight="20" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._FIELD_IS_TEACHER`)}
						</Text>
					</Row>
				</Col>
				<Col span="4">
					<Row justify="center" className="pt-8">
						<Text fontSize="11" lineHeight="20" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._FIELD_IS_ADMIN`)}
						</Text>
					</Row>
				</Col>
			</Row>
		);
	};

	const onSubmit = async (values: any) => {
		const emails = values.emails.map((x: any) => {
			return x.email.trim();
		});
		// PREVENT DUPLICATE EMAILS
		if (_.uniq(emails).length !== emails.length) {
			return showError(translate(`${TRANSLATION_BASE_PATH}._HAS_DUPLICATE_EMAILS`));
		}

		if (!props.isParentOrganization) {
			// PREVENT INVITING ALREADY INVITED
			const currentEmails: string[] = props.currentUsers.map((x: any) => {
				return x.email;
			});
			if (emails.find((x: string) => currentEmails.includes(x))) {
				return showError(translate(`${TRANSLATION_BASE_PATH}._IS_ALREADY_SENT`));
			}
		}

		if (props.isParentOrganization) {
			setSubmitting(true);
			return Promise.all(
				values.emails.map(async (value: any) => {
					const user = await new UserService().findUserByEmail(value.email);
					return user ? undefined : value;
				})
			)
				.then(emails => {
					const newEmails = emails.filter(entry => entry);
					setCountInvites(newEmails.length);
					const teacherEmails = newEmails.filter((value: any) => !value.isAdmin);
					if (teacherEmails.length !== 0) {
						new InvitationService().inviteAkademiStaff(
							teacherEmails
						);
					}
					const adminNewEmails = newEmails.filter((value: any) => value.isAdmin);
					if (adminNewEmails.length !== 0) {
						new InvitationService().inviteOrganizationStaff(adminNewEmails);
					}
					return;
				})
				.then(() => {
					props.onClose();
					setInviteSentPopupVisible(true);
					return;
				})
				.catch(() => {
					return showError(translate(`${TRANSLATION_BASE_PATH}._ERROR_SENDING_INVITATIONS`));
				})
				.finally(() => {
					setSubmitting(false);
				});
		}

		setSubmitting(true);
		return new InvitationService()
			.inviteOrganizationStaff(values.emails)
			.then(() => {
				setCountInvites(emails.length);
				setInviteSentPopupVisible(true);
				props.onClose();
				return;
			})
			.catch(() => {
				return showError(translate(`${TRANSLATION_BASE_PATH}._ERROR_SENDING_INVITATIONS`));
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<>
			<Drawer
				title={translate(`${TRANSLATION_BASE_PATH}._INVITE_TITLE`)}
				onClose={props.onClose}
				visible={props.visible}
				footer={drawerFooter}
				className="invite__students_drawer"
				destroyOnClose={true}
			>
				<div className="invite__students_content">
					<Space direction="vertical" style={{ width: "100%" }}>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._INVITE_HELPER_DESCRIPTION`)}
						</Text>
						<Formik
							enableReinitialize={true}
							validationSchema={formValidator(translate)}
							initialValues={{
								emails: [{ email: "", isAdmin: false }]
							}}
							onSubmit={onSubmit}
							validate={values => {
								setIsFormValid(formValidator(translate).isValidSync(values));
								return {};
							}}
						>
							{formik => {
								const { handleSubmit, submitForm, values } = formik;
								submitFormHandler = submitForm;
								return (
									<form onSubmit={handleSubmit} autoComplete="off">
										{emailAddressesHeader(values)}
										<FieldArray name="emails">
											{({ push, remove, replace }) => {
												return (
													<>
														<Row>
															<Col span="16">
																<Input
																	name={"emails[0].email"}
																	value={values.emails[0].email}
																	autoComplete="off"
																	onPaste={e => {
																		const pastedContent = e.clipboardData.getData(
																			"text/plain"
																		);
																		let pastedEmails: string[] = pastedContent.split(
																			"\n"
																		);
																		if (!pastedEmails || pastedEmails.length < 1) {
																			pastedEmails = pastedContent.split(" ");
																		}
																		if (pastedEmails && pastedEmails.length > 1) {
																			replace(0, { email: pastedEmails[0] });
																			for (
																				let i = 1;
																				i < pastedEmails.length;
																				i++
																			) {
																				push({ email: pastedEmails[i] });
																			}

																			e.preventDefault();
																		}
																	}}
																/>
															</Col>
															<Col span="4">
																<Row justify="center" className="pt-4">
																	<Checkbox disabled={true} checked={true} />
																</Row>
															</Col>
															<Col span="4">
																<Row justify="center" className="pt-4">
																	<FormikCheckboxField name={`emails[0].isAdmin`} />
																</Row>
															</Col>
														</Row>
														{values.emails?.map((item: any, index: number) => {
															if (index === 0) return null;
															return (
																<Row key={index}>
																	<Col span="16">
																		<Row>
																			<Col span="20">
																				<Input
																					name={`emails[${index}].email`}
																					placeholder="psh përdorues@email.com"
																					value={item.email}
																					autoComplete="off"
																				/>
																			</Col>
																			<Col span="4">
																				<Button
																					type="default"
																					shape="circle"
																					className="ml-8"
																					onClick={() => remove(index)}
																				>
																					<Icon type="ri-delete-bin-7-line" />
																				</Button>
																			</Col>
																		</Row>
																	</Col>
																	<Col span="4">
																		<Row justify="center" className="pt-4">
																			<Checkbox checked={true} disabled={true} />
																		</Row>
																	</Col>
																	<Col span="4">
																		<Row justify="center" className="pt-4">
																			<FormikCheckboxField
																				name={`emails[${index}].isAdmin`}
																			/>
																		</Row>
																	</Col>
																</Row>
															);
														})}
														<Button
															icon="ri-add-circle-line"
															type="link"
															className="pl-0"
															onClick={() => push({ email: "", isAdmin: false })}
														>
															{translate(`${TRANSLATION_BASE_PATH}._ADD_ANOTHER_ONE`)}
														</Button>
													</>
												);
											}}
										</FieldArray>
									</form>
								);
							}}
						</Formik>
					</Space>
					<Space direction="horizontal" align="start" className="mt-40">
						<Icon type="ri-information-line" className="color-blue-6" fontSize="20" />
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._INVITE_FROM_MENU`)}{" "}
							<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._MENU`)}.
							</Text>
						</Text>
					</Space>
				</div>
			</Drawer>
			<InvitesSentPopup
				visible={inviteSentPopupVisible}
				countInvites={countInvites}
				onCancel={() => {
					props.onRefresh();
					setInviteSentPopupVisible(false);
				}}
			/>
		</>
	);
};
