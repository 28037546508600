import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._GEN_REPORT_BTN._REQUIRED";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		dateSegment: Yup.array()
			.typeError(translate(`${TRANSLATION_BASE_PATH}`))
			.required(translate(`${TRANSLATION_BASE_PATH}`))
	});
