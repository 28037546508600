import React, { useState, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import moment from "moment";
import { Tooltip } from "antd";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import Option from "common/components/dataEntry/components/Option";
import Alert from "common/components/feedback/Alert";
import Icon from "common/components/general/Icon";
import { formValidator } from "./ValidationSchema";
import { showMessage } from "common/utils/Notification";
import UserService from "services/authentication/user.service";
import { FormikGroupDatePickerField } from "../../../../../login/components/groupBirthDatePicker";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._MY_PROFILE";

interface MyProfileProps {
	id: string;
	firstName: string;
	lastName: string;
	dateOfBirth?: string;
	email?: string;
	parentEmail?: string;
	username: string;
	nationality?: string;
	isTeacher: boolean;
	setSubmitting: (status: boolean) => void;
	setIsFormValid: (status: boolean) => void;
	submitFormHandler: any;
	isParent?: any;
}

export const MyProfileForm = (props: MyProfileProps) => {
	const { t: translate } = useTranslation();
	const [hasError, setHasError] = useState<{ error: boolean; errorMessage: string }>({
		error: false,
		errorMessage: ""
	});

	const [under13, setUnder13] = useState<boolean>(false);

	const initialValues = { ...props };

	useEffect(() => {
		if (!props.isTeacher) {
			const birthDate = moment(props.dateOfBirth);
			const secondDate = moment();
			const duration = moment.duration(secondDate.diff(birthDate));
			const years = duration.asYears();
			if (years < 13) {
				setUnder13(true);
			}
		}
		props.setIsFormValid(formValidator(translate).isValidSync(initialValues));
	}, []);

	const showAlertContent = (): ReactNode => {
		return hasError.error ? (
			<Row className="mb-24">
				<Col span={24}>
					<Alert message={hasError.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	const getEmailInput = (): ReactNode => {
		if (props.isTeacher) {
			return (
				<Input
					name="email"
					label={translate(`${TRANSLATION_BASE_PATH}._EMAIL_LABEL`)}
					disabled
					disabledTooltip={translate(`${TRANSLATION_BASE_PATH}._EMAIL_DISABLED_TOOLTIP`)}
				/>
			);
		}
		else if (props.isParent){
			return (
				<Input
					name="email"
					label={translate(`${TRANSLATION_BASE_PATH}._EMAIL_LABEL`)}
					disabled
					disabledTooltip={translate(`${TRANSLATION_BASE_PATH}._EMAIL_DISABLED_TOOLTIP`)}
				/>
			);
		}
		else if (!props.isTeacher && under13 === false) {
			return (
				<Input
					name="email"
					label={translate(`${TRANSLATION_BASE_PATH}._EMAIL_LABEL`)}
					disabled
					disabledTooltip={translate(`${TRANSLATION_BASE_PATH}._EMAIL_DISABLED_TOOLTIP`)}
				/>
			);
		}

		return (
			<Input
				name="parentEmail"
				label={translate(`${TRANSLATION_BASE_PATH}._UNDER_13_EMAIL_LABEL`)}
				labelInfo={
					<Tooltip
						placement="bottom"
						title={translate(`${TRANSLATION_BASE_PATH}._PARENT_EMAIL_TOOLTIP`)}
						trigger={["hover", "click"]}
					>
						<div>
							<Icon type="ri-information-line" className="color-blue-6" />
						</div>
					</Tooltip>
				}
				disabled
				disabledTooltip={translate(`${TRANSLATION_BASE_PATH}._PARENT_EMAIL_DISABLED_TOOLTIP`)}
			/>
		);
	};

	const getUsernameInput = (): ReactNode => {
		if (props.isTeacher) {
			return <Input name="username" label={translate(`${TRANSLATION_BASE_PATH}._USERNAME`)} />;
		}

		return (
			<Input
				name="username"
				label={translate(`${TRANSLATION_BASE_PATH}._USERNAME`)}
				labelInfo={
					<Tooltip
						placement="bottom"
						title={translate(`${TRANSLATION_BASE_PATH}._UNDER_13_USERNAME_TOOLTIP`)}
						trigger={["hover", "click"]}
					>
						<div>
							<Icon type="ri-information-line" className="color-blue-6" />
						</div>
					</Tooltip>
				}
			/>
		);
	};

	const onSubmit = async (values: any) => {
		props.setSubmitting(true);
		setHasError({
			error: false,
			errorMessage: ""
		});
		await new UserService()
			.updateProfile(props.id, {
				firstName: values.firstName,
				lastName: values.lastName,
				username: values.username,
				nationality: values.nationality
			})
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._PROFILE_UPDATED`));
				const decodedToken = OAuth2Service.CurrentToken;
				const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
				const selectedOrganizationId = localStorage.getItem(currentUserInfo?.UserId || "");
				return OAuth2Service.refreshToken(decodedToken.refreshToken, selectedOrganizationId);
			})
			.then(() => {
				return window.location.reload();
			})
			.catch(error => {
				const errorStatus = error?.status ?? "500";
				return setHasError({
					error: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			})
			.finally(() => {
				props.setSubmitting(false);
			});
	};

	return (
		<Formik
			enableReinitialize={false}
			validationSchema={formValidator(translate)}
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={values => {
				props.setIsFormValid(formValidator(translate).isValidSync(values));
				return {};
			}}
		>
			{formik => {
				const { handleSubmit, submitForm } = formik;
				props.submitFormHandler(submitForm);
				return (
					<form onSubmit={handleSubmit} autoComplete="off">
						{showAlertContent()}
						<Row gutter={[24, 0]}>
							<Col span={12}>
								<Input name="firstName" label={translate(`${TRANSLATION_BASE_PATH}._FIRST_NAME`)} />
							</Col>
							<Col span={12}>
								<Input name="lastName" label={translate(`${TRANSLATION_BASE_PATH}._LAST_NAME`)} />
							</Col>
						</Row>
						{(!props.isTeacher && !props.isParent) ? (
							<Row>
								<Col span={24}>
									<FormikGroupDatePickerField
										name="dateOfBirth"
										label={translate(`${TRANSLATION_BASE_PATH}._BIRTH_DATE`)}
										disabled
										size="middle"
									/>
								</Col>
							</Row>
						) : null}

						<Row>
							<Col span={24}>{getEmailInput()}</Col>
						</Row>
						<Row>
							<Col span={24}>{getUsernameInput()}</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Select
									name="nationality"
									size="middle"
									label={props.isParent ? translate(`${TRANSLATION_BASE_PATH}._NATIONALITY_PARENT`) : translate(`${TRANSLATION_BASE_PATH}._NATIONALITY`)}
									placeholder={props.isParent ? translate(`${TRANSLATION_BASE_PATH}._NATIONALITY_PLACEHOLDER`) : ""}
									defaultValue={props.nationality}
								>
									{["ALBANIA", "KOSOVO", "OTHER"].map(item => (
										<Option key={item} value={item} size="middle">
											{translate(`${TRANSLATION_BASE_PATH}._${item}`)}
										</Option>
									))}
								</Select>
							</Col>
						</Row>
					</form>
				);
			}}
		</Formik>
	);
};
