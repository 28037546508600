import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { QuizMaterial, QUIZ_FULL_PATH } from "./scenes/quizMaterial/QuizMaterial";
import { LessonMaterial, LESSON_FULL_PATH } from "./scenes/lessonMaterial/LessonMaterial";
import { MainLayout } from "./components/layout/Layout";

export const SUBJECTS_PATH = "subjects";
export const MATERIALS_PATH = "materials";

const Main = () => {
	return (
		<MainLayout>
			<Switch>
				<Route path={`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${QUIZ_FULL_PATH}`} exact component={QuizMaterial} />
				<Route
					path={`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_FULL_PATH}`}
					exact
					component={LessonMaterial}
				/>
				<Route
					path={`/${SUBJECTS_PATH}/${MATERIALS_PATH}/*`}
					render={() => <Redirect to={`/${SUBJECTS_PATH}`} />}
				/>
			</Switch>
		</MainLayout>
	);
};

export default Main;
