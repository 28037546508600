import React from "react";
import Text, {TextProps} from "common/components/general/Text";

interface CustomTextProps extends TextProps {
	children: string,
	maxTrim?: number
}

export function TrimmedText(props: CustomTextProps) {
	const length = props.maxTrim ?? 50;
	return <Text {...props}>
		{ props.children?.substr(0, length) }
		{ props.children?.length > length ? "..." : "" }
	</Text>;
}