import { Col, Row, Space } from "antd";
import React from "react";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

interface IndicatorOverviewCardProps {
	icon: string;
	value: number;
	title: string;
}

export const IndicatorOverviewCard = (props: IndicatorOverviewCardProps) => {
	return (
		<Space size={12}>
			<Icon type={props.icon} className="color-blue-6 indicator__overview_icon" fontSize="24" />
			<Space direction="vertical" size={0}>
				<Text fontSize="24" lineHeight="32" className="color-gray-8">
					{props.value}
				</Text>
				<Row>
					<Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{props.title}
						</Text>
					</Col>
				</Row>
			</Space>
		</Space>
	);
};
