import React from "react";
import { TimePicker as AntTimePicker } from "antd";
import { TimeRangePickerProps as AntTimeRangePickerProps } from "antd/lib/time-picker/index";

export type TimeRangePickerProps = AntTimeRangePickerProps;

const { RangePicker: AntRangePicker } = AntTimePicker;

const TimeRangePicker: React.FC<AntTimeRangePickerProps> = props => {
	return <AntRangePicker {...props} />;
};

export default TimeRangePicker;
