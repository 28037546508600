import React, { ReactNode } from "react";
import { Layout } from "antd";

import { Header as AdminHeader } from "common/components/_header1/Header1";
import { MainHeader as TeacherHeader } from "common/components/layout/header";
import OAuth2Service from "services/authentication/oauth2.service";
import { MainDashboardMenuItems } from "../../../main/components/layout/Layout";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

interface LayoutProps {
	children: ReactNode;
}

const MainLayout = (props: LayoutProps) => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const userRole = currentUserInfo?.Roles && currentUserInfo?.Roles.length > 0 && currentUserInfo?.Roles[0];
	const pathToRole = userRole?.code?.toLowerCase() || "";

	return (
		<Layout>
			{pathToRole === OAuth2Service.isOwnerOrAdminAtDefaultOrganization ? (
				<AdminHeader />
			) : (
				<TeacherHeader menu={MainDashboardMenuItems} showSwitchRole={true} />
			)}
			{props.children}
		</Layout>
	);
};

export default MainLayout;
