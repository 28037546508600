import React, { ReactNode, useEffect, useState } from "react";
import { Button, Card, Col, Collapse, Divider, Empty, List, Row, Skeleton, Space, Tooltip, Input } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import InputField from "common/components/dataEntry/components/Input";
import EnrollmentService from "services/administration/enrollment.service";
import ProgressLessonEnrollmentService from "services/administration/progressLessonEnrollment.service";
import AssignmentUserCommitService from "services/administration/assignmentUserCommit.service";
import { Uploader } from "common/components/uploader/Uploader";
import { previewFile } from "scenes/courseBuilder/utils/FileUtils";
import { showError, showMessage } from "common/utils/Notification";
import { UserAvatar } from "common/components/general/UserAvatar";
import { IndicatorOverviewCard } from "./IndicatorOverviewCard";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._ASSIGNMENT_GRADING";

interface AssignmentGradingComponentProps {
	lesson: any;
	classroomId: string;
	loadLesson: any;
}

export const AssignmentGradingComponent = (props: AssignmentGradingComponentProps) => {
	const { t: translate } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [enrollments, setEnrollments] = useState<any[]>([]);
	const [progress, setProgress] = useState<number>(0);
	const [assignmentCommits, setAssignmentCommits] = useState<any[]>([]);
	const [selectedEnrollment, setSelectedEnrollment] = useState<any>(undefined);
	const [selectedUserCommit, setSelectedUserCommit] = useState<any>(undefined);
	const [filesUploaded, setFilesUploaded] = useState<any[]>([]);
	const [currentGradeValue, setCurrentGradeValue] = useState<string>("");
	const [selectedEnrollments, setSelectedEnrollments] = useState<any[]>([]);
	const [assignmentsFileUploaded, setAssignmentsFileUploaded] = useState<any[]>([]);
	const [enrollmentsLoading, setEnrollmentsLoading] = useState<any[]>([]);

	useEffect(() => {
		async function getLessonSections() {
			setLoading(true);
			const requests = [new EnrollmentService().getAllEnrollmentsByClassroomId(props.classroomId),
				new ProgressLessonEnrollmentService().countProgressLessonEnrollmentByLessonId(props.lesson.id),
				new AssignmentUserCommitService().getAssignmentUserCommitByLessonId(props.lesson.id)];

			const [enrollments, progress, assignmentCommits] = await Promise.all(requests);
			setAssignmentCommits(assignmentCommits);
			if (enrollments) {
				const activeEnrollments = enrollments.filter((item: any) => item.status === "ACTIVE");
				setEnrollments(activeEnrollments);
				const submitedAssignment = assignmentCommits.find(
					(assignmentCommit:any) => assignmentCommit.UserId === activeEnrollments[0].UserId && assignmentCommit.isCommitted
				);
				
				if (activeEnrollments.length && submitedAssignment) {
					setSelectedEnrollment(activeEnrollments[0]);
					setSelectedUserCommit(submitedAssignment);
				}
			}
			setProgress(progress);
			return await setLoading(false);
		}
		getLessonSections();
	}, []);

	useEffect(() => {
		async function getUserCommitFiles() {
			setLoading(true);
			const fileUploaded =
				(selectedUserCommit &&
					selectedUserCommit.AssignmentUserCommitFiles &&
					selectedUserCommit.AssignmentUserCommitFiles.length > 0 &&
					(await Promise.all(
						selectedUserCommit.AssignmentUserCommitFiles.map(async (commitFile: { File: any }) => {
							return commitFile.File && (await previewFile(commitFile.File));
						})
					))) ||
				[];
			await setFilesUploaded(fileUploaded);
			return await setLoading(false);
		}
		if (selectedUserCommit !== undefined) {
			getUserCommitFiles();
		}
	}, [selectedUserCommit]);

	

	const saveGradeByUserCommitId = async (selectedUserCommitId: string) => {
		if (currentGradeValue !== "") {
			await new AssignmentUserCommitService()
				.updateAssignementUserCommitByUserIdLessonId(selectedUserCommitId, {
					grade: currentGradeValue
				})
				.then(() => {
					return showMessage(translate(`${TRANSLATION_BASE_PATH}._SAVED_GRADE`));
				})
				.catch(() => {
					showError(translate(`${TRANSLATION_BASE_PATH}._FAIL_GRADE`));
				});
			const currentCommit = assignmentCommits.find(commit => commit.id === selectedUserCommitId);
			if (currentCommit) currentCommit.grade = currentGradeValue;
		}
		return await setCurrentGradeValue("");
	};

	const saveGrade = async () => {
		return await saveGradeByUserCommitId(selectedUserCommit.id);
	};

	const getGradeValue = (enrollment: any) => {
		const assignement = assignmentCommits.find(assignmentCommit => assignmentCommit.UserId === enrollment.UserId);
		return assignement && assignement?.grade !== null ? assignement?.grade : "";
	};

	const publishGradesButtonContent = (): ReactNode => {
		return (
			<Button
				type="primary"
				onClick={async () => {
					setLoading(true);
					await new AssignmentUserCommitService()
						.publishUserAssignementGrades(props.lesson.id)
						.then(() => {
							showMessage(translate(`${TRANSLATION_BASE_PATH}._PUBLISH_SUCCESS`));
							return setLoading(false);
						})
						.catch(() => {
							showError(translate(`${TRANSLATION_BASE_PATH}._PUBLISH_ERROR`));
						});
				}}
				disabled={loading}
			>
				{translate(`${TRANSLATION_BASE_PATH}._PUBLISH_GRADES`)}
			</Button>
		);
	};

	const getCardTitleContent = (): ReactNode => {
		return (
			<div className="grading__card_title_wrapper">
				<div className="flex__center">
					<IndicatorOverviewCard
						icon="ri-user-line"
						value={enrollments.length}
						title={translate(`${TRANSLATION_BASE_PATH}._STUDENTS`)}
					/>
					<Divider type="vertical" className="grading__indicator_divider" />
					<IndicatorOverviewCard
						icon="ri-send-plane-line"
						value={progress}
						title={translate(`${TRANSLATION_BASE_PATH}._COMMITED_ASSIGNMENTS`)}
					/>
					<Divider type="vertical" className="grading__indicator_divider" />
					<IndicatorOverviewCard
						icon="ri-checkbox-circle-line"
						value={assignmentCommits.filter(assignmentCommit => assignmentCommit.grade !== null).length}
						title={translate(`${TRANSLATION_BASE_PATH}._GRADED_ASSIGNMENTS`)}
					/>
				</div>
				<Row>
					<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
						{publishGradesButtonContent()}
					</Col>
				</Row>
			</div>
		);
	};

	const disabledField = (enrollment:any) => {
		if(selectedUserCommit){
			const isSelected = selectedUserCommit?.UserId === enrollment.UserId;
			if (selectedUserCommit && isSelected) {
				const { isCommitted, isEvaluated, grade } = selectedUserCommit;
				if (isCommitted && isEvaluated && grade) {
					return true;
				}
				else{
					return false
				}
			}
		}
		return true;
	};

	return (
		<Row>
			<Col span={24} className="assignment__grading_card_wrapper">
				<Card title={getCardTitleContent()} className="assignment__grading_card">
					<Row>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="card__student_list_column">
							<Row className="full__width">
								<Col span={24}>
									<Row className="student__list_title_wrapper">
										<Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24}>
											<Text
												fontSize="20"
												lineHeight="28"
												className="color-blue-6 student__list_title"
											>
												{translate(`${TRANSLATION_BASE_PATH}._STUDENT_LIST`)}
											</Text>
										</Col>
										<Col xs={12} sm={12} md={0} lg={0} xl={0} xxl={0} className="ta_right">
											{publishGradesButtonContent()}
										</Col>
									</Row>
									<List
										header={
											<div className="grading__header">
												<Text
													fontSize="14"
													lineHeight="22"
													wheight="semibold"
													className="color-gray-9"
												>
													{translate(`${TRANSLATION_BASE_PATH}._NAME`)}
												</Text>
												<Space align="center" className="grade__label_wrapper">
													<Text
														fontSize="14"
														lineHeight="22"
														wheight="semibold"
														className="color-gray-9"
													>
														{translate(`${TRANSLATION_BASE_PATH}._GRADE`)}{" "}
													</Text>
													<Tooltip
														title={translate(`${TRANSLATION_BASE_PATH}._GRADE_TOOLTIP`)}
														placement="bottomLeft"
													>
														<span>
															<Icon
																type="ri-information-line"
																fontSize="20"
																className="color-blue-6"
															/>
														</span>
													</Tooltip>
												</Space>
											</div>
										}
										className="assignment__grading_student_list"
									>
										{enrollments.map(enrollment => {
											const submitedAssignment = assignmentCommits.find(
												assignmentCommit => assignmentCommit.UserId === enrollment.UserId && assignmentCommit.isCommitted
											);
											const betweenFiveDays = moment(props.lesson.endDate).isBetween(
												moment().add(-5, "days"),
												moment().add(5, "days")
											);
											const gradeValue: string = getGradeValue(enrollment);
											return (
												<List.Item
													key={enrollment.id}
													className={
														selectedEnrollment?.id === enrollment.id
															? "p-0 background-color-blue-1"
															: "p-0"
													}
												>
													<Row className="full__width">
														<Col
															xs={0}
															sm={0}
															md={24}
															lg={24}
															xl={24}
															xxl={24}
															className={`student__assignment_list_item_wrapper pt-16 pr-24 pb-16 ${
																submitedAssignment && !betweenFiveDays
																	? "pl-24"
																	: "pl-8"
															}`}
															onClick={async () => {
																if (selectedEnrollment?.id !== enrollment.id) {
																	setSelectedEnrollment({ ...enrollment });
																	setSelectedUserCommit({ ...submitedAssignment });
																} else {
																	setSelectedUserCommit(undefined);
																	setSelectedEnrollment(undefined);
																}
															}}
														>
															<Space size={8}>
																<Space size={2} align="center">
																	{!submitedAssignment && betweenFiveDays ? (
																		<Tooltip
																			title={translate(
																				`${TRANSLATION_BASE_PATH}._NOT_COMMITED_TOOLTIP`
																			)}
																		>
																			<span>
																				<Icon
																					type="ri-error-warning-line"
																					className="color-red-6"
																				/>
																			</span>
																		</Tooltip>
																	) : null}
																	<UserAvatar
																		firstName={enrollment?.User.firstName}
																		lastName={enrollment?.User.lastName}
																		userId={enrollment?.UserId}
																	/>
																</Space>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className={
																		selectedEnrollment?.id === enrollment.id
																			? "color-blue-6"
																			: "color-gray-8"
																	}
																>
																	{enrollment?.User.firstName +
																		" " +
																		enrollment?.User.lastName}
																</Text>
															</Space>
															<InputField
																onClick={e => {
																	e.stopPropagation();
																}}
																onChange={async e => {
																	setCurrentGradeValue(e.target.value);
																}}
																onBlur={() => {
																	saveGrade();
																}}
																onPressEnter={async () => {
																	setLoading(true);
																	await saveGrade();
																	setLoading(false);
																}}
																defaultValue={gradeValue}
																disabled={disabledField(enrollment)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._GRADE_PLACEHOLDER`
																)}
																style={{ width: "168px" }}
															/>
														</Col>
														<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
															<Collapse
																className="student__assignment_collapse_wrapper"
																expandIconPosition="right"
																onChange={async () => {
																	const enrollmentFoundIndex = selectedEnrollments.findIndex(
																		(item: any) => item.id === enrollment.id
																	);
																	if (enrollmentFoundIndex < 0) {
																		if (submitedAssignment !== undefined) {
																			let newEnrollmentsLoading = [...enrollmentsLoading,
																				{
																					id: enrollment.id,
																					loading: true
																				}];
																			setEnrollmentsLoading(
																				newEnrollmentsLoading
																			);
																			const fileUploaded =
																				(submitedAssignment.AssignmentUserCommitFiles &&
																					submitedAssignment
																						.AssignmentUserCommitFiles
																						.length > 0 &&
																					(await Promise.all(
																						submitedAssignment.AssignmentUserCommitFiles.map(
																							async (commitFile: {
																								File: any;
																							}) => {
																								return (
																									commitFile.File &&
																									(await previewFile(
																										commitFile.File
																									))
																								);
																							}
																						)
																					))) ||
																				[];
																			const newAssignmentsFiles = [...assignmentsFileUploaded,
																				{
																					id: enrollment.id,
																					files: fileUploaded
																				}];
																			await setAssignmentsFileUploaded(
																				newAssignmentsFiles
																			);
																			const oldSelectedEnrollments = [...selectedEnrollments,
																				enrollment];
																			setSelectedEnrollments(
																				oldSelectedEnrollments
																			);
																			newEnrollmentsLoading = [...enrollmentsLoading,
																				{
																					id: enrollment.id,
																					loading: false
																				}];
																			return setEnrollmentsLoading(
																				newEnrollmentsLoading
																			);
																		}
																	}
																}}
															>
																<Collapse.Panel
																	key="1"
																	header={
																		<Space
																			size={8}
																			className="student__grade_wrapper"
																			align="start"
																		>
																			<Space size={2} align="center">
																				{!submitedAssignment &&
																				betweenFiveDays ? (
																						<Tooltip
																							title={translate(
																								`${TRANSLATION_BASE_PATH}._NOT_COMMITED_TOOLTIP`
																							)}
																						>
																							<span>
																								<Icon
																									type="ri-error-warning-line"
																									className="color-red-6"
																								/>
																							</span>
																						</Tooltip>
																					) : null}
																				<UserAvatar
																					firstName={
																						enrollment?.User.firstName
																					}
																					lastName={enrollment?.User.lastName}
																					userId={enrollment?.UserId}
																				/>
																			</Space>
																			<div className="student__grade_value_wrapper">
																				<Text
																					fontSize="14"
																					lineHeight="22"
																					className="color-gray-8"
																				>
																					{enrollment?.User.firstName +
																						" " +
																						enrollment?.User.lastName}
																				</Text>
																				<Text
																					fontSize="14"
																					lineHeight="22"
																					className="color-gray-6"
																				>
																					{gradeValue !== ""
																						? gradeValue
																						: translate(
																							`${TRANSLATION_BASE_PATH}._GRADE_PLACEHOLDER`
																						  )}
																				</Text>
																			</div>
																		</Space>
																	}
																	className="student__assignment_collapse_panel_wrapper"
																>
																	<Skeleton
																		loading={
																			enrollmentsLoading.find(
																				item => item.id === enrollment.id
																			)?.loading
																		}
																		active
																	>
																		<Row>
																			<Col span={24}>
																				{submitedAssignment &&
																				submitedAssignment?.id ? (
																						<Row gutter={[0, 24]}>
																							<Col span={24}>
																								<Space
																									direction="vertical"
																									size={8}
																								>
																									<Text
																										fontSize="14"
																										lineHeight="22"
																										className="color-gray-7"
																									>
																										{translate(
																											`${TRANSLATION_BASE_PATH}._NOTE`
																										)}
																									</Text>
																									<Text
																										fontSize="14"
																										lineHeight="22"
																										className="color-gray-8"
																									>
																										{
																											submitedAssignment.description
																										}
																									</Text>
																								</Space>
																							</Col>
																							<Col span={24}>
																								<Space
																									direction="vertical"
																									className="full__width"
																									size={8}
																								>
																									<Text
																										fontSize="14"
																										lineHeight="22"
																										className="color-gray-7"
																									>
																										{translate(
																											`${TRANSLATION_BASE_PATH}._DOCUMENTS`
																										)}
																									</Text>
																									<Uploader
																										key={new Date().toISOString()}
																										fileType="OTHER"
																										multiple={true}
																										showUploadList={
																											true
																										}
																										defaultFileList={
																										assignmentsFileUploaded.find(
																											item =>
																												item.id ===
																												enrollment.id
																										)?.files
																										}
																										className="lessonMaterial__modal_commit"
																										showRemoveIcon={
																											false
																										}
																									/>
																								</Space>
																							</Col>
																						</Row>
																					) : (
																						<Row className="flex__center_justify_center">
																							<Empty
																								image={
																									Empty.PRESENTED_IMAGE_SIMPLE
																								}
																								description={translate(
																									`${TRANSLATION_BASE_PATH}._NOT_COMMITED`
																								)}
																							/>
																						</Row>
																					)}
																			</Col>
																			<Col span={24}>
																				<Space direction="vertical" size={2}>
																					<Text
																						fontSize="14"
																						lineHeight="22"
																						className="color-gray-9"
																					>
																						Nota
																					</Text>
																					<Input.Group>
																						<InputField
																							onClick={e => {
																								e.stopPropagation();
																							}}
																							onChange={async e => {
																								setCurrentGradeValue(
																									e.target.value
																								);
																							}}
																							defaultValue={gradeValue}
																							placeholder={translate(
																								`${TRANSLATION_BASE_PATH}._GRADE_PLACEHOLDER`
																							)}
																							className="collapse__item_grade_input"
																							disabled={disabledField(enrollment)}
																						/>
																						<Button
																							type="default"
																							onClick={async () => {
																								await saveGradeByUserCommitId(
																									submitedAssignment.id
																								);
																							}}
																							className="collapse__item_grade_button"
																						>
																							Ruaj
																						</Button>
																					</Input.Group>
																				</Space>
																			</Col>
																		</Row>
																	</Skeleton>
																</Collapse.Panel>
															</Collapse>
														</Col>
													</Row>
												</List.Item>
											);
										})}
									</List>
								</Col>
							</Row>
						</Col>
						<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
							<Skeleton loading={loading} active>
								{selectedEnrollment ? (
									<Row className="full__width">
										<Col span={24} className="p-24">
											<Space className="pb-16 flex__space_between">
												<Text fontSize="20" lineHeight="28" className="color-blue-6">
													{selectedEnrollment.User.firstName +
														" " +
														selectedEnrollment.User.lastName}
												</Text>
												<Icon
													type="ri-close-line"
													fontSize="20"
													onClick={async () => {
														setSelectedEnrollment(undefined);
														setSelectedUserCommit(undefined);
													}}
													className="cursor_pointer"
												/>
											</Space>
											{selectedUserCommit?.id ? (
												<>
													<Row className="pb-24">
														<Col span={24}>
															<Space direction="vertical">
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-7"
																>
																	{translate(`${TRANSLATION_BASE_PATH}._NOTE`)}
																</Text>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-8"
																>
																	{selectedUserCommit.description}
																</Text>
															</Space>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<Space direction="vertical" className="full__width">
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-7"
																>
																	{translate(`${TRANSLATION_BASE_PATH}._DOCUMENTS`)}
																</Text>
																<Uploader
																	key={new Date().toISOString()}
																	fileType={"OTHER"}
																	multiple={true}
																	showUploadList={true}
																	defaultFileList={filesUploaded}
																	className="lessonMaterial__modal_commit"
																	showRemoveIcon={false}
																/>
															</Space>
														</Col>
													</Row>
												</>
											) : (
												<Row className="flex__center_justify_center">
													<Empty
														image={Empty.PRESENTED_IMAGE_SIMPLE}
														description={translate(
															`${TRANSLATION_BASE_PATH}._NOT_COMMITED`
														)}
													/>
												</Row>
											)}
										</Col>
									</Row>
								) : (
									<Row className="full__width flex__center_justify_center">
										<Col span={24}>
											<Row className="p-24 pb-16">
												<Text fontSize="20" lineHeight="28" className="color-blue-6">
													{translate(`${TRANSLATION_BASE_PATH}._STUDENT`)}
												</Text>
											</Row>
											<Row className="p-24 pb-16 flex__center_justify_center">
												<Empty
													description={translate(
														`${TRANSLATION_BASE_PATH}._CHOOSE_A_STUDENT`
													)}
													className="m-0"
												/>
											</Row>
										</Col>
									</Row>
								)}
							</Skeleton>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
};
