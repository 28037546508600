/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { Component, createContext } from "react";
import { Layout, Space, Row, Col, Menu, Dropdown, Select } from "antd";
import { Link, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import { SUBJECTS_PATH } from "scenes/subjects";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import { StudentLessonList } from "common/components/lessonList/StudentLessonList";
import ClassroomService from "services/administration/classroom.service";
import SubjectPlanService from "services/administration/subjectPlanTree.service";
import SubjectPlanTreeDto from "dtos/administration/subjectPlanTree.dto";
import LessonDto from "dtos/administration/lesson.dto";
import EnrollmentService from "services/administration/enrollment.service";
import OAuth2Service from "services/authentication/oauth2.service";
import UserService from "services/authentication/user.service";
import UserDto from "dtos/authentication/user/user.dto";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import TagService from "services/administration/tag.services";
import moment from "moment";

const { Header, Sider, Content } = Layout;
const TRANSLATION_BASE_PATH = "_SUBJECTS._MATERIAL_ITEMS._LAYOUT";

type ChildProps = {
	setCourse: any;
	setActualLessonId: any;
	nextLesson: any;
	lessonsFinished: any;
	enrollmentId: string;
	classroomEnrolled: any;
	setBackUrl: any;
	setSelectedTag: any;
	groupedBy: any;
};

export const ChildContext = createContext<ChildProps>({
	setCourse: null,
	setActualLessonId: null,
	nextLesson: null,
	lessonsFinished: [],
	enrollmentId: "",
	classroomEnrolled: null,
	setBackUrl: null,
	setSelectedTag: null,
	groupedBy: null
});

class MainLayoutComponent extends Component<any, any> {
	private _isMounted: boolean;

	constructor(props: any) {
		super(props);
		this.state = {
			lessons: [],
			rootLessonTags: [],
			lessonTagGroup: [],
			tabActiceKey: "0",
			collapsed: true,
			siderFullWidth: false,
			courseId: "",
			actualLessonId: "",
			goToDashboard: false,
			isRegistered: true,
			enrollmentId: "",
			lessonsFinished: [],
			backURL: `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/`,
			isVirtualClass: false,
			isAssignmentSection: false,
			groupedBy: null
		};
		this._isMounted = false;
		this.toggle = this.toggle.bind(this);
		this.setCourse = this.setCourse.bind(this);
		this.setActualLessonId = this.setActualLessonId.bind(this);
		this.retriveLessonsFromTree = this.retriveLessonsFromTree.bind(this);
		this.nextLesson = this.nextLesson.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
		this.setBackUrl = this.setBackUrl.bind(this);
	}

	setCollapsedState = () => {
		this._isMounted && this.setState({
			collapsed: !(window.innerWidth >= 992),
			siderFullWidth: window.innerWidth <= 576
		});
	};

	componentDidMount() {
		this._isMounted = true;
		window.addEventListener("resize", this.setCollapsedState.bind(this));
		this.setCollapsedState();
	}

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener("resize", this.setCollapsedState.bind(this));
	}

	toggle = () => {
		this.setState({ collapsed: !this.state.collapsed });
	};

	setSelectedTag = async (groupedBy: string, classroomId: string) => {
		const rootLessonTags: any = await new TagService().getAllRootTagsforLessons();
		const selectedTag = rootLessonTags.findIndex((tag: any) => tag.name === groupedBy);
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			rootLessonTags[selectedTag > -1 ? selectedTag.toString() : "0"]?.id,
			classroomId
		);

		return this.setState({
			tabActiceKey: selectedTag > -1 ? selectedTag.toString() : "0",
			rootLessonTags: rootLessonTags,
			lessonTagGroup: _(lessonTagGroup)
				.orderBy((x: any) => Number(x?.ClassroomTags[0]?.priority), "asc")
				.value()
		});
	};

	setCourse = async (courseId: string) => {
		const classroom = await new ClassroomService().getClassroomById(courseId);
		const subjectPlanTree: SubjectPlanTreeDto | null = !classroom.SubjectPlan
			? null
			: await new SubjectPlanService().getSubjectPlanTreeBySubjectId(classroom.SubjectPlan.id);
		const lessons: LessonDto[] | null = this.retriveLessonsFromTree(subjectPlanTree);

		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const user: UserDto = await new UserService().getUserById(currentUserInfo?.UserId || "");

		const classroomEnrolled =
			user && user.Classrooms && user.Classrooms.find((classroom: any) => classroom.id === courseId);
		const isRegistered = classroomEnrolled ? true : false;
		const enrollmentId =
			isRegistered && classroomEnrolled && classroomEnrolled.Enrollment ? classroomEnrolled.Enrollment.id : null;

		const enrollmentObject: any =
			isRegistered && enrollmentId && (await new EnrollmentService().getEnrollmentById(enrollmentId));

		return this.setState({
			lessons: lessons,
			courseId: courseId,
			classroomEnrolled: classroomEnrolled,
			isRegistered: isRegistered,
			enrollmentId: enrollmentId,
			lessonsFinished: (isRegistered && enrollmentObject && enrollmentObject.Lessons) ?? []
		});
	};

	setBackUrl = async (url: string, isVirtualClass: boolean, isAssignmentSection: boolean, groupedBy: string | null) => {
		const rootLessonTags: any = await new TagService().getAllRootTagsforLessons();
		const selectedTag = rootLessonTags.findIndex((tag: any) => tag.name === groupedBy);
		this.setState({
			backURL: url,
			rootLessonTags: rootLessonTags,
			isVirtualClass: isVirtualClass,
			isAssignmentSection: isAssignmentSection,
			tabActiceKey: selectedTag > -1 ? selectedTag : "0",
			groupedBy: groupedBy
		});
	};

	setActualLessonId = (actualLessonId: string) => {
		this.setState({ actualLessonId: actualLessonId });
	};

	retriveLessonsFromTree = (subjectPlanTree: SubjectPlanTreeDto | null): LessonDto[] | null => {
		if (!subjectPlanTree) return null;
		const subjectPlanNode: SubjectPlanTreeDto[] = [];
		subjectPlanNode.push(subjectPlanTree);
		const lesson: LessonDto[] = [];
		while (subjectPlanNode.length !== 0) {
			const node: any = subjectPlanNode.pop();
			if (node.children.length !== 0) subjectPlanNode.push(...node.children);
			lesson.push(...node.lessons);
		}
		return lesson;
	};

	nextLesson = () => {
		const arrayOfLessons: any[] = _(this.state.lessonTagGroup)
			.orderBy(item => item?.ClassroomTags[0]?.priority, "asc")
			.value()
			.map((tagGroup: any) => {
				return (
					(tagGroup.ClassroomTags[0]?.LessonClassroomTag &&
						_.orderBy(
							tagGroup.ClassroomTags[0]?.LessonClassroomTag,
							(item: any) => {
								return item.priority
									? item.priority
									: item.Lesson.endDate
										? moment(item.Lesson.endDate)
										: moment(new Date(0));
							},
							["asc"]
						)) ||
					[]
				);
			});

		const lessons: any = arrayOfLessons.length > 0 ? arrayOfLessons.flat(1) : [];
		const newArrayOfLessons: any[] = lessons?.length > 0 ? lessons.map((lessonTag: any) => {
			return lessonTag.Lesson;
		}) : this.state.lessons;

		const lessonFound =
			newArrayOfLessons && newArrayOfLessons.findIndex((lesson: any) => lesson.id === this.state.actualLessonId);
		const nextLesson: any = lessonFound > -1 && lessonFound === lessons.length - 1 ? 0 : lessonFound + 1;
		return newArrayOfLessons && newArrayOfLessons.length > 0 && newArrayOfLessons[nextLesson].id;
	};

	unSubscribe = async () => {
		this.state.isRegistered &&
			(await new EnrollmentService().deleteEnrollment(this.state.enrollmentId).then(() => {
				return this.setState({ goToDashboard: true });
			}));
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	async isACTIVEEnrollment(enrollmentId: string) {
		return new EnrollmentService().getEnrollmentById(enrollmentId).then((enrollment: any) => {
			if (enrollment.status !== "ACTIVE") {
				window.location.href = "/";
			}
			return;
		});
	}

	fetchAllSubGroupsLesson = async (optionSelected: any) => {
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			this.state.rootLessonTags[optionSelected]?.id,
			this.state.courseId
		);

		return this.setState({
			lessonTagGroup: _(lessonTagGroup)
				.orderBy((x: any) => Number(x.priority), "asc")
				.value(),
			tabActiceKey: optionSelected.toString(),
			groupedBy: this.state.rootLessonTags[optionSelected.toString()]?.name
		});
	};

	groupingOptionsToSelect = (): React.ReactNode => {
		return (
			<Select defaultValue={Number(this.state.tabActiceKey)} onChange={this.fetchAllSubGroupsLesson}>
				{this.state.rootLessonTags.map((lessonTag: any, index: number) => (
					<Select.Option key={index} value={index}>
						{lessonTag.name}
					</Select.Option>
				))}
			</Select>
		);
	};

	render() {
		this.state.enrollmentId && this.isACTIVEEnrollment(this.state.enrollmentId);

		const { t: translate } = this.props;

		const menu = (
			<Menu>
				<Menu.Item key="0" onClick={this.unSubscribe}>
					{translate(`${TRANSLATION_BASE_PATH}._UNSUBSCRIBE`)}
				</Menu.Item>
			</Menu>
		);

		const virtualClass = this.state.isVirtualClass
			? "?content=true"
			: this.state.isAssignmentSection
				? "?assignment=true"
				: "";

		if (!this.state.isRegistered || this.state.goToDashboard)
			return (
				<Redirect
					to={
						`${this.state.backURL}${this.state.courseId}${virtualClass}` +
						(this.state.isVirtualClass || this.state.isAssignmentSection
							? (!this.state.groupedBy || this.state.groupedBy === "")
								? ""
								: `&groupedBy=${this.state.groupedBy}`
							: (!this.state.groupedBy || this.state.groupedBy === "")
								? ""
								: `?groupedBy=${this.state.groupedBy}`)
					}
				/>
			);

		return (
			<Layout className="full__min_height">
				<Header>
					<Row justify="space-between" align="middle">
						<Col span={22}>
							<Link
								to={
									`${this.state.backURL}${this.state.courseId}${virtualClass}` +
									((this.state.isVirtualClass || this.state.isAssignmentSection) === ""
										? (!this.state.groupedBy || this.state.groupedBy === "")
											? ""
											: `&groupedBy=${this.state.groupedBy}`
										: (!this.state.groupedBy || this.state.groupedBy === "")
											? ""
											: `?groupedBy=${this.state.groupedBy}`)
								}
								className="color-gray-9"
							>
								<Space>
									<Icon type="ri-arrow-left-line" />
									<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
										{(this.state.classroomEnrolled && this.state.classroomEnrolled.name) || ""}
									</Text>
								</Space>
							</Link>
						</Col>
						<Col span={2}>
							<Row justify="end">
								<Dropdown.Button
									buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
									trigger={["click"]}
									overlay={menu}
									icon={<Icon type="ri-more-2-line"></Icon>}
								/>
							</Row>
						</Col>
					</Row>
				</Header>
				<Layout className="full__min_height materialItem__layout materialItem__sidebarLayout">
					<Layout className="full__min_height materialItem__layout materialItem__contentChild">
						<Content className="background-color-gray-1">
							<ChildContext.Provider key={"children"}
								value={{
									setSelectedTag: this.setSelectedTag,
									setCourse: this.setCourse,
									setActualLessonId: this.setActualLessonId,
									nextLesson: this.nextLesson,
									enrollmentId: this.state.enrollmentId,
									lessonsFinished: this.state.lessonsFinished,
									classroomEnrolled: this.state.classroomEnrolled,
									setBackUrl: this.setBackUrl,
									groupedBy: this.state.groupedBy
								}}
							>
								{this.props.children}
							</ChildContext.Provider>
							<div className="materialItem__layout materialItem__fixed-widgets">
								<Button
									type="default"
									className={
										"materialItem__button-over background-color-gray-1 " +
										(this.state.collapsed ? "" : "hide")
									}
									size="large"
									onClick={() => this.toggle()}
								>
									<Icon type="ri-menu-fold-fill" className="color-gray-9" />
								</Button>
							</div>
						</Content>
					</Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={this.state.collapsed}
						collapsedWidth={0}
						className="materialItem__layout materialItem__sidebar background-color-gray-2"
						width={this.state.siderFullWidth ? "100%" : "300"}
					>
						<Layout className="full__min_height">
							<Header className="pl-16 background-color-gray-3">
								<Row align="middle" justify="space-between">
									<Col span={2}>
										<Icon
											type="ri-menu-unfold-fill"
											onClick={() => this.toggle()}
											className="materialItem__icon-over color-gray-8"
										/>
									</Col>
									<Col span={this.state.rootLessonTags?.length > 0 ? 11 : 22}>
										<Text fontSize="16" lineHeight="24" className="color-gray-9">
											{translate(`${TRANSLATION_BASE_PATH}._CONTENT`)}
										</Text>
									</Col>
									{this.state.rootLessonTags?.length > 0 && (
										<Col span={11}>{this.groupingOptionsToSelect()}</Col>
									)}
								</Row>
							</Header>
							<Content className="p-0">
								<StudentLessonList
									lessonGroups={this.state.lessonTagGroup}
									lessons={this.state.lessons}
									lessonsFinished={this.state.lessonsFinished}
									isEnrolled={this.state.isRegistered}
									isMaterialPanel={true}
									actualLessonId={this.state.actualLessonId}
									isVirtualClassroom={this.state.isVirtualClass}
									groupedBy={this.state.groupedBy}
								/>
							</Content>
						</Layout>
					</Sider>
				</Layout>
			</Layout>
		);
	}
}

export const MainLayout = withTranslation()(MainLayoutComponent);
