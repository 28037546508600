import { Card, Col, Row, Space } from "antd";
import React from "react";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { Link } from "react-router-dom";
import { getImageUrl } from "../_utils/getImageUrl";
import { PriceTag } from "./PriceTag";
import { LiveClassIcon } from "./LiveClassIcon";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";
import { truncate } from "common/utils/StringUtils";

const NAVIGATE_URL = "/subjects/course/";

export const FirstItem = (props: any) => {
	return (
		<Card hoverable={true} className="mt-16 box_shadow full__width" bordered={false} bodyStyle={{ padding: "0" }}>
			<Link to={NAVIGATE_URL + props.item.id}>
				<Row>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
						<Image
							className="marketplace__first__item__card__image"
							src={getImageUrl(props.imagePrefix, props.item.File)}
						/>
						{!props.item.isAsync && <LiveClassIcon />}
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="p-16 flex__column_space_between">
						<Row>
							<Space direction="vertical">
								<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
									{props.item.name}
								</Text>
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									<HtmlInput
										name="description"
										readOnly={true}
										value={truncate(props.item.description, 300, true) ?? ""}
									/>
								</Text>
							</Space>
						</Row>
						<Row>
							<Text fontSize="12" lineHeight="20" className="marketplace__organization__name">
								{props.item.Organization?.name}
							</Text>
						</Row>
						<Row>
							<Col span="12">
								<Row className="color-gray-8">
									<Space>
										{(props.showEnrolledUsers && props.item.TotalEnrollments >= 10) ? 
											<>
												<Icon type="ri-user-line" />
												<Text fontSize="16" lineHeight="24">
													{props.item.TotalEnrollments}
												</Text>
											</> 
											: ""
										}
									</Space> 
								</Row>
							</Col>
							<Col span="12">
								<Row justify="end">
									<PriceTag price={props.item.price} />
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Link>
		</Card>
	);
};
