import { Button, Col, Popconfirm, Row, Space } from "antd";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Text from "common/components/general/Text";
import Icon from "../../../../../common/components/general/Icon";
import UserService from "../../../../../services/authentication/user.service";
import { showError, showMessage } from "../../../../../common/utils/Notification";
import { useHistory } from "react-router-dom";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_SETTINGS._DELETE_ACCOUNT";

export const DeleteAccount = (props: any) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const child = props.child;
	const [deleting, setDeleting] = useState(false);

	const Delete = (child: any) => {

		setDeleting(true);

		return new UserService().deleteChildAccount(child.id)
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._SUCCESS_MESSAGE`));
				return history.goBack();
			})
			.catch((error: any) => {
				console.log(error);
				showError(translate(`${TRANSLATION_BASE_PATH}._ERROR_MESSAGE`));
			})
			.finally(() => {
				setDeleting(false);
			});
	};

	return (
		<>
			<Row>
				<Col span="24">
					<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Col>
			</Row>
			<Row className="pt-24">
				<Col span="24">
					<Popconfirm
						title={<Trans i18nKey={`${TRANSLATION_BASE_PATH}._POPOVER._CONFIRM_MESSAGE`}>
							Why is needed this, to make username bold?
							<strong title={translate("username")}>{{ username: child.username }}</strong>
						</Trans>}
						onConfirm={() => Delete(child)}
						okText={translate(`${TRANSLATION_BASE_PATH}._POPOVER._OK`)}
						cancelText={translate(`${TRANSLATION_BASE_PATH}._POPOVER._CANCEL`)}
					>
						<Button
							type="ghost"
							className="background-color-red-5"
							disabled={deleting}
							loading={deleting}
						>
							<Space>
								<Text fontSize="14" lineHeight="22" className="color-gray-1">
									<Icon type="ri-delete-bin-7-line" />
								</Text>
								<Text fontSize="14" lineHeight="22" className="color-gray-1">
									<Trans i18nKey={`${TRANSLATION_BASE_PATH}._BTN`}>
									Why is needed this, to make username bold?
										<strong title={translate("username")}>{{ username: child.username }}</strong>
									</Trans>
								</Text>
							</Space>
						</Button>
					</Popconfirm>
				</Col>
			</Row>
		</>
	);
};