import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import { Formik } from "formik";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { formValidator as formValidatorPersonal } from "./PersonalFormValidationSchema";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT._DATA_COLLECTOR._PERSONAL";

type Props = {
	updateData: (data: object) => void,
	setIsNextActive: (active: boolean) => void,
	reason: string
}

type InitialValues = {
	[key: string]: string
}

export const Personal = (props: Props) => {
	const { t: translate } = useTranslation();
	const { updateData, setIsNextActive, reason } = props;
	const [data] = useState<InitialValues>({});
	const initialValues = {
		city: "",
		address: "",
		device: "",
		school: "",
		age: "",
		phoneNumber: ""
	};
	const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
		const element = e.target as HTMLInputElement;
		const name: string = element.name;
		const phoneRegex = /^\d*$/;
		if (name === "phoneNumber") {
			if (phoneRegex.test(element.value) || element.value === "") {
				data[name] = element.value;
			}
		}
		else data[name] = element.value;
		updateData({ personal: data });
		if (data.city &&
			data.address &&
			(reason === "_REQUEST" || data.device) &&
			data.school &&
			data.age &&
			data.phoneNumber?.length >= 9
		) {
			setIsNextActive(true);
		} else setIsNextActive(false);
	};
	
	return (
		<Col span={24} className="mb-6">
			<Row gutter={[0, 12]}>{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</Row>
			<Row className="mt-16">
				<Col span={24}>
					<Formik
						enableReinitialize={true}
						validationSchema={formValidatorPersonal(translate)}
						initialValues={initialValues}
						onSubmit={() => undefined}
						isInitialValid={false}
					>
						{formik => {
							const { handleSubmit } = formik;
							return (
								<form
									onChange={e => handleChange(e)}
									className="digitalizeStudent__personal_fields"
									onSubmit={handleSubmit}
									autoComplete="off"
								>
									<Row gutter={[0, 24]}>
										<Input
											name="city"
											label={translate(`${TRANSLATION_BASE_PATH}._CITY`)}
											value={formik.values.city}
											maxLength={100}
										/>
									</Row>
									<Row gutter={[0, 24]}>
										<Input
											name="address"
											label={translate(`${TRANSLATION_BASE_PATH}._ADDRESS`)}
											value={formik.values.address}
											maxLength={100}
										/>
									</Row>
									{reason === "_DONATE" && (
										<Row gutter={[0, 24]}>
											<Input
												name="device"
												label={translate(`${TRANSLATION_BASE_PATH}._DEVICE_TYPE`)}
												value={formik.values.device}
											/>
										</Row>
									)}
									<Row gutter={[0, 24]}>
										<Input
											name="school"
											label={translate(`${TRANSLATION_BASE_PATH}._SCHOOL`)}
											value={formik.values.school}
											maxLength={100}
										/>
									</Row>
									<Row gutter={[0, 24]}>
										<Input
											name="age"
											label={translate(`${TRANSLATION_BASE_PATH}._AGE`)}
											value={formik.values.age}
											maxLength={100}
										/>
									</Row>
									<Row gutter={[0, 24]}>
										<Input
											maxLength={10}
											addonBefore="+355"
											placeholder="123 456 78..."
											name="phoneNumber"
											label={translate(`${TRANSLATION_BASE_PATH}._PHONE_NUMBER`)}
											value={data.phoneNumber || ""}
										/>
									</Row>
								</form>
							);
						}}
					</Formik>
				</Col>
			</Row>
		</Col>
	);
};