/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosInstance, AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import { JWTTokenDto } from "dtos/authentication/token/jwtToken.dto";
import OAuth2Service, { localstorageUserSessionKeys } from "./authentication/oauth2.service";
import InMemoryJWT, { REFRESH_TOKEN_EVENTS } from "./inMemoryJWT.service";
import { EventEmitter } from "events";

const CLIENTID = process.env.REACT_APP_PUBLIC;
export const API_BASE_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;

export default class ApiServiceData {
	private apiEndpoint: AxiosInstance;

	constructor(apiEndpoint?: string) {
		this.apiEndpoint = axios.create({
			baseURL: apiEndpoint
				? apiEndpoint
				: process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host
		});
		this.apiEndpoint.defaults.timeout = Number(process.env.REACT_APP_TIMEOUT || "10000");
	}

	// GET /relativeUrl/:id
	public get(
		relativeUrl: string,
		options?: { [key: string]: any },
		definedSignedToken?: string
	): Promise<AxiosResponse<any>> {
		if (options) {
			options.headers.BrowserBaseURL = window.location.protocol + "//" + window.location.host;
			options.headers.ClientId = CLIENTID;
		}

		return ApiServiceData.getAuthentication(definedSignedToken)
			.then(token => {
				return this.apiEndpoint.get(
					relativeUrl,
					options
						? options
						: {
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + token,
									BrowserBaseURL: window.location.protocol + "//" + window.location.host,
									ClientId: CLIENTID
								}
						  }
				);
			})
			.catch(err => {
				ApiServiceData.deleteSessionOnUnauthorize(err);
				throw err;
			});
	}

	// GET /relativeUrl/:id
	public publicGet(
		relativeUrl: string,
		options?: { [key: string]: any }
	): Promise<AxiosResponse<any>> {
		if (options) {
			options.headers = options.headers ?? {};
			options.headers.BrowserBaseURL = window.location.protocol + "//" + window.location.host;
			options.headers.ClientId = CLIENTID;
			options.headers["Content-Type"] = "application/json";
		}

		return this.apiEndpoint.get(
			relativeUrl,
			options
				? options
				: {
						headers: {
							"Content-Type": "application/json",
							BrowserBaseURL: window.location.protocol + "//" + window.location.host,
							ClientId: CLIENTID
						}
					}
		)
	}

	// POST /relativeUrl
	public post(
		relativeUrl: string,
		data?: any,
		options?: { [key: string]: any },
		definedSignedToken?: string
	): Promise<AxiosResponse<any>> {
		if (options) {
			options.headers.BrowserBaseURL = window.location.protocol + "//" + window.location.host;
			options.headers.ClientId = CLIENTID;
		}
		return ApiServiceData.getAuthentication(definedSignedToken)
			.then(token => {
				return this.apiEndpoint.post(
					relativeUrl,
					data,
					options
						? options
						: {
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + token,
									BrowserBaseURL: window.location.protocol + "//" + window.location.host,
									ClientId: CLIENTID
								}
						  }
				);
			})
			.catch(err => {
				ApiServiceData.deleteSessionOnUnauthorize(err.response);
				throw err;
			});
	}

	// POST /relativeUrl
	public publicPost(
		relativeUrl: string,
		data?: any,
		options?: { [key: string]: any },
	): Promise<AxiosResponse<any>> {
		if (options) {
			options.headers.BrowserBaseURL = window.location.protocol + "//" + window.location.host;
			options.headers.ClientId = CLIENTID;
		}

		return this.apiEndpoint.post(
			relativeUrl,
			data,
			options
				? options
				: {
						headers: {
							"Content-Type": "application/json",
							BrowserBaseURL: window.location.protocol + "//" + window.location.host,
							ClientId: CLIENTID
						}
					}
		)
	}

	// DELETE /relativeUrl/:id
	public delete(
		relativeUrl: string,
		options?: { [key: string]: any },
		definedSignedToken?: string
	): Promise<AxiosResponse<any>> {
		if (options) {
			options.headers.BrowserBaseURL = window.location.protocol + "//" + window.location.host;
			options.headers.ClientId = CLIENTID;
		}

		return ApiServiceData.getAuthentication(definedSignedToken)
			.then(token => {
				return this.apiEndpoint.delete(
					relativeUrl,
					options
						? options
						: {
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + token,
									BrowserBaseURL: window.location.protocol + "//" + window.location.host,
									ClientId: CLIENTID
								}
						  }
				);
			})
			.catch(err => {
				ApiServiceData.deleteSessionOnUnauthorize(err.response);
				throw err;
			});
	}

	// PUT /relativeUrl/:id
	public update(
		relativeUrl: string,
		data: any,
		options?: { [key: string]: any },
		definedSignedToken?: string
	): Promise<AxiosResponse<any>> {
		if (options) {
			options.headers.BrowserBaseURL = window.location.protocol + "//" + window.location.host;
			options.headers.ClientId = CLIENTID;
		}

		return ApiServiceData.getAuthentication(definedSignedToken)
			.then(token => {
				return this.apiEndpoint.put(
					relativeUrl,
					data,
					options
						? options
						: {
								headers: {
									"Content-Type": "application/json",
									Authorization: "Bearer " + token,
									BrowserBaseURL: window.location.protocol + "//" + window.location.host,
									ClientId: CLIENTID
								}
						  }
				);
			})
			.catch(err => {
				ApiServiceData.deleteSessionOnUnauthorize(err.response);
				throw err;
			});
	}

	private static getAuthentication(definedSignedToken?: string): Promise<string> {
		if (InMemoryJWT.getIsRefreshing())
			return this.waitForRefreshToken(InMemoryJWT.getRefreshTokenEmitter(), REFRESH_TOKEN_EVENTS.Unblocked)
				.then(() => this.getToken(definedSignedToken))
				.catch(err => err);
		return this.getToken(definedSignedToken);
	}

	private static waitForRefreshToken(emitter: EventEmitter, event: string): Promise<string> {
		return new Promise((resolve, reject) => {
			emitter.on(event, resolve);
			emitter.on("error", reject);
		});
	}

	private static getToken(definedSignedToken?: string): Promise<string> {
		const signedToken: string | null = definedSignedToken ? definedSignedToken : localStorage.getItem("jwt-token");
		if (!signedToken) throw Promise.reject(ApiServiceData.deleteSessionOnUnauthorize({ status: 401 }));

		const decodedToken: JWTTokenDto = jwt_decode(signedToken);
		const selectedOrganizationId = definedSignedToken ? "" : localStorage.getItem(decodedToken.UserId || "");
		return new Promise((resolve, reject) => {
			if (decodedToken.refreshToken && new Date(decodedToken.refreshTokenExpiresAt) < new Date()) {
				ApiServiceData.deleteSessionOnUnauthorize({ status: 401 });
				throw reject();
			}
			if (decodedToken.refreshToken && new Date(decodedToken.accessTokenExpiresAt) < new Date())
				return OAuth2Service.refreshToken(decodedToken.refreshToken, selectedOrganizationId)
					.then(token => {
						return resolve(token);
					})
					.catch(() => {
						ApiServiceData.deleteSessionOnUnauthorize({ status: 401 });
						throw reject();
					});

			if (decodedToken.accessToken) return resolve(signedToken);
			ApiServiceData.deleteSessionOnUnauthorize({ status: 401 });
			throw reject();
		});
	}

	private static deleteSessionOnUnauthorize(response: any) {
		if (response?.status === 401) {
			localstorageUserSessionKeys.forEach(item => {
				localStorage.removeItem(item);
			});
			window.location.reload();
		}
	}
}
