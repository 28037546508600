import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Alert from "common/components/feedback/Alert";
import { RegisterEmailVerification } from "../../components/emailVerification/index";
import AccountEmailService from "services/email/account.email.service";
import { ON_BOARDING_PATH } from "../..";
import { SIGN_IN_PATH } from "../../../login/scenes/signIn";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

export const REGISTER_EMAIL_VERIFICATION_PATH = "register-email-verification";
const TRANSLATION_BASE_PATH = "_ONBOARDING._VERIFY_ACCOUNT";

class EmailVerificationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: "",
			status: "Info",
			user: null,
			currentId: "",
			notValidEmails: []
		};
	}

	recaptchaRef: any = React.createRef();

	componentDidMount() {
		const { history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		if (currentUserInfo.User.status !== "NEW") return history.push("/" + ON_BOARDING_PATH);
		return this.setState({user: currentUserInfo.User, currentId: currentUserInfo.UserId});
	}

	showAlertContent = (): ReactNode => {
		return this.state.hasError ? (
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="mt-24">
					<Alert message={this.state.errorMessage} type="error" showIcon />
				</Col>
			</Row>
		) : null;
	};

	resendVerificationEmail = async () => {
		const { t: translate } = this.props;
		const email = this.state.user.email ?? this.state.user.parentEmail;
		if (this.state.notValidEmails.includes(email.toLowerCase())) {
			this.setState({
				submitting: false,
				hasError: true,
				errorMessage: translate(`${TRANSLATION_BASE_PATH}._406_API_STATUS_CODE`).replace("{email}", email)
			});
			return;
		}
		this.setState({
			submitting: true,
			hasError: false,
			errorMessage: ""
		});

		const recaptchaValue = await this.recaptchaRef.current.executeAsync();

		await new AccountEmailService()
			.resentVerificationEmail(this.state.currentId, recaptchaValue)
			.then(() => {
				this.recaptchaRef.current.reset();
				const oldStatus = this.state.status;
				this.setState({
					submitting: false,
					status: oldStatus === "Info" ? "Sent" : "Resent"
				});
				return;
			})
			.catch((error: any) => {
				this.recaptchaRef.current.reset();
				const errorStatus = error?.status ?? "500";
				if (errorStatus === 406) {
					const notValidEmails = this.state.notValidEmails;
					return this.setState({
						notValidEmails: [...notValidEmails, email.toLowerCase()],
						errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`).replace(
							"{email}",
							email
						),
						submitting: false,
						hasError: true
					});
				} 
				return this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	render() {
		return (
			<>
				{this.showAlertContent()}
				<Row className="pt-80">
					<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
						<RegisterEmailVerification
							email={this.state.user?.email ?? this.state.user?.parentEmail}
							submitting={this.state.submitting}
							onResendEmail={this.resendVerificationEmail}
							status={this.state.status}
							recaptchaRef={this.recaptchaRef}
						/>
					</Col>
				</Row>
			</>
		);
	}
}

const EmailVerification = withTranslation()(EmailVerificationComponent);
export default EmailVerification;
