import React from "react";
import { Card, Skeleton } from "antd";
import { withTranslation } from "react-i18next";
import { Pie } from "react-chartjs-2";
import _ from "lodash";
import { formatTotal } from "../../../../util/formatTotal";

const TRANSLATION_BASE_PATH = "_ADMIN._MAIN";

class PieCardComponent extends React.Component<any, any> {
	getLabels = (type: string): string[] => {
		const { t: translate } = this.props;
		const student = translate(`${TRANSLATION_BASE_PATH}._STUDENTS`);
		const teacher = translate(`${TRANSLATION_BASE_PATH}._TEACHERS`);
		const parent = translate(`${TRANSLATION_BASE_PATH}._PARENTS`);
		const albania = translate(`${TRANSLATION_BASE_PATH}._ALBANIA`);
		const kosovo = translate(`${TRANSLATION_BASE_PATH}._KOSOVO`);
		const diaspora = translate(`${TRANSLATION_BASE_PATH}._DIASPORA`);
		const otherRegions = translate(`${TRANSLATION_BASE_PATH}._OTHER_REGIONS`);
		const mobileScreen = true;

		const allLabels: Map<string, string[]> = new Map([
			["chartTotalsLabels", [student, teacher, parent]],
			[
				"chartStudentTotalsLabels",
				[
					`${mobileScreen ? "" : student} ${albania}`,
					`${mobileScreen ? "" : student} ${kosovo}`,
					`${mobileScreen ? "" : student} ${diaspora}`,
					`${mobileScreen ? "" : student} ${otherRegions}`
				]
			],
			[
				"chartTeacherTotalsLabels",
				[
					`${mobileScreen ? "" : teacher} ${albania}`,
					`${mobileScreen ? "" : teacher} ${kosovo}`,
					`${mobileScreen ? "" : teacher} ${diaspora}`,
					`${mobileScreen ? "" : teacher} ${otherRegions}`
				]
			]
		]);
		return allLabels.get(type) || [];
	};

	getColors = (type: string): string[] => {
		const allColors: Map<string, string[]> = new Map([
			["chartTotalsLabels", ["#488F0", "#1147BD", "#A0D911"]],
			["chartStudentTotalsLabels", ["#F5222D", "#2063E3", "#A0D911", "#98A9C0"]],
			["chartTeacherTotalsLabels", ["#F5222D", "#2063E3", "#A0D911", "#98A9C0"]]
		]);
		return allColors.get(type) || [];
	};

	getPieChart = (data: any, titleName: string, type: string) => {
		const { t: translate } = this.props;
		const total = translate(`${TRANSLATION_BASE_PATH}._TOTAL`);
		const labels = this.getLabels(type);
		const colors = this.getColors(type);
		const titleFontColor = "#2d3748";
		const titleFontSize = 16;

		const chartData = {
			labels: labels.map((label: string, index: number) => {
				return `${formatTotal(data[index])} ${label}`;
			}),
			datasets: [
				{
					backgroundColor: colors,
					data: data
				}
			]
		};

		return (
			<Pie
				data={chartData}
				options={{
					legend: {
						display: true,
						position: "right"
					},
					title: {
						display: true,
						text: `${titleName} ${total}: ${formatTotal(_.sum(data))}`,
						fontSize: titleFontSize,
						fontColor: titleFontColor
					}
				}}
			/>
		);
	};

	render() {
		return (
			<>
				<Card bordered={false} className="mb-24 box_shadow">
					<Skeleton loading={this.props.loading}>
						{this.props.data &&
							this.getPieChart(Object.values(this.props.data), this.props.title, this.props.labelId)}
					</Skeleton>
				</Card>
			</>
		);
	}
}

const PieCard = withTranslation()(PieCardComponent);
export default PieCard;
