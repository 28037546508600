import React, { useEffect, useState } from "react";
import { Button, Col, Layout, Row } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { SectionOrganisations } from "./SectionOrgansations";
import ClassroomService from "services/administration/classroom.service";
import { MainHeader as Header } from "common/components/layout/header";
import { SectionSearch } from "../subjects/scenes/subjectItems/scenes/marketplace/components/sectionSearch/SectionSearch";
import { ItemFilters } from "./ItemFilters";
import { SectionCourses } from "./SectionCourses";
import { ActiveFilters, DropdownFilters } from "./DropdownFilters";
import { AllCourses } from "./AllCourses";
import OrganizationService from "../../services/administration/organization.service";
import OrganizationDto from "../../dtos/administration/organization.dto";
import { getPublicFileUrl } from "../courseBuilder/utils/FileUtils";
import { useStoreActions } from "easy-peasy";
import { MainDashboardMenuItems } from "../main/components/layout/Layout";
import TagDto from "../../dtos/administration/tag.dto";
import { InstructorBanner } from "../subjects/scenes/subjectItems/scenes/marketplace/components/instructorBanner";
import Breadcrumb from "./Breadcrumb";

const MARKETPLACE_COURSE_CATEGORIES = "_MARKETPLACE._COURSE_CATEGORIES";

const { Content } = Layout;

type PublicOrganizations = Pick<OrganizationDto, "id" | "marketplaceLogo" | "name" | "featuredSort" | "sector">[];
export type GroupType = "featured" | "trending" | "popular" | "all";
type SectionGroup = {
	title: string;
	group: GroupType;
};

export interface MarketplaceStore {
	loading: boolean;
	searchKey?: string;
	activeOrganization?: string;
	showFilterModal: boolean;
	parentTag?: TagDto;
	subjects: any[];
	subject?: string;
	category?: string;
	group?: GroupType;
	rightFilters: ActiveFilters;
	organizations: { name: string; logo: string; id: string; sector: string }[];
}

const getDefaultFilters = (defaultValues: Pick<MarketplaceStore, any> = {}): MarketplaceStore => {
	return {
		loading: false,
		searchKey: undefined,
		activeOrganization: undefined,
		showFilterModal: false,
		parentTag: undefined,
		subjects: [],
		subject: undefined,
		category: undefined,
		group: undefined,
		rightFilters: {},
		organizations: [],
		...defaultValues
	};
};

const MarketplaceContext = React.createContext<any>({});
const MarketplaceContextProvider = (props: any) => {
	const marketplaceStore = React.useState<MarketplaceStore>(getDefaultFilters());
	return <MarketplaceContext.Provider value={{ marketplaceStore }}>{props.children}</MarketplaceContext.Provider>;
};
const useMarketplaceContext = () => React.useContext(MarketplaceContext);

const PublicMarketplace = () => {
	const updateTotalCoursesOnPublicMarketPlace = useStoreActions(
		(actions: any) => actions.subjects.updateTotalCoursesOnPublicMarketPlace
	);
	const {
		marketplaceStore: [state, setState]
	} = useMarketplaceContext() as { marketplaceStore: [MarketplaceStore, any] };
	const { t: translate } = useTranslation();
	const [hasActiveFilters, setHasActiveFilters] = useState<boolean>(false);

	const resetFilters = (defaultValues: Pick<MarketplaceStore, any> = {}) => {
		if (!Object.values(defaultValues).length) {
			setHasActiveFilters(false);
		}

		setState(() =>
			getDefaultFilters({
				...defaultValues,
				subjects: state.subjects,
				organizations: state.organizations
			})
		);
	};

	const setMarketplaceStoreState = (key: keyof MarketplaceStore, value: MarketplaceStore[keyof MarketplaceStore]) => {
		if (!state.group && !state.activeOrganization && key === "category" && value) {
			setState((state: MarketplaceStore) => ({ ...state, [key]: value, group: "all" }));
		} else {
			setState((state: MarketplaceStore) => ({ ...state, [key]: value }));
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [state.group]);

	// Load default organizations
	useEffect(() => {
		const getFeaturedOrganizations = () => {
			return new OrganizationService().getFeaturedOrganizations().then((result: PublicOrganizations) => {
				const orgs = result
					.map((org: any) => {
						console.log(org);
						return {
							id: org.id,
							name: org.name,
							logo: getPublicFileUrl(org.marketplaceLogo),
							marketplaceLogo: org.marketplaceLogo,
							sector: org.sector
						};
					})
					.filter((org: any) => {
						return org.sector !== "PRIVATE";
					});
				return setMarketplaceStoreState("organizations", orgs);
			});
		};
		getFeaturedOrganizations();
	}, []);

	useEffect(() => {
		const loadFeaturedCourses = async () => {
			const classromSerview: any = new ClassroomService();
			try {
				const response = await classromSerview.classroomsSearch({ order: "asc", limit: 9, page: 1 });
				updateTotalCoursesOnPublicMarketPlace({ group: "allCourses", number: response.total });
			} catch (err) {
				throw err;
			}
		};

		loadFeaturedCourses();
	}, []);

	useEffect(() => {
		setHasActiveFilters(
			!!state.searchKey ||
				!!state.activeOrganization ||
				state.showFilterModal ||
				!!state.parentTag ||
				!!state.category ||
				Object.values(state.rightFilters).length > 0 ||
				!!state.group ||
				!!state.subject
		);
	}, [
		state.searchKey,
		state.activeOrganization,
		state.showFilterModal,
		state.parentTag,
		state.category,
		state.rightFilters,
		state.group,
		state.subject
	]);

	const sectionGroups: SectionGroup[] = [
		{
			title: translate(`${MARKETPLACE_COURSE_CATEGORIES}._FEATURED`),
			group: "featured"
		},
		{
			title: translate(`${MARKETPLACE_COURSE_CATEGORIES}._TRENDING`),
			group: "trending"
		},
		{
			title: translate(`${MARKETPLACE_COURSE_CATEGORIES}._POPULAR`),
			group: "popular"
		}
	];
	console.log(state.organizations);
	return (
		<Layout>
			<Header
				menu={MainDashboardMenuItems}
				showSwitchRole
				showMarketplace
				showAllCoursesOnMarketPlace={() => resetFilters({ group: "all" })}
			/>
			<Content className={"background-color-gray-2 subject_items__layout_content"}>
				<Row justify="center" className={"ant-row-center subject_items__layout_max_width"}>
					<Col lg={8} xl={5} xxl={5} md={0} sm={0} xs={0} className="pr-24">
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={24}
							xl={24}
							xxl={24}
							className={"cursor_pointer mb-4 pl-8 pr-8 pt-4 pb-4 mr-4"}
						></Col>
						<ItemFilters
							activeFilters={state.category}
							setActiveFilters={val => setMarketplaceStoreState("category", val)}
						/>
					</Col>
					<Col xs={24} sm={24} lg={16} xl={17} xxl={16}>
						<BannerWrapper justify="center">
							<InstructorBanner isPublic={true} />
						</BannerWrapper>

						{hasActiveFilters && <Breadcrumb group={state?.group} resetFilters={() => resetFilters()} />}

						<SectionSearch
							toggleFilterBar={val => setMarketplaceStoreState("showFilterModal", val)}
							loading={state.loading}
							search={val => setMarketplaceStoreState("searchKey", val)}
						/>
						{!state.activeOrganization && hasActiveFilters && (
							<DropdownFilters
								activeFilters={state.rightFilters}
								setActiveFilters={val => setMarketplaceStoreState("rightFilters", val)}
							/>
						)}
						{(!hasActiveFilters || state.activeOrganization) && (
							<SectionOrganisations
								setActiveOrganization={val => setMarketplaceStoreState("activeOrganization", val)}
								organizations={state.organizations}
								activeOrganization={state.activeOrganization}
							/>
						)}

						{!hasActiveFilters &&
							sectionGroups.map((s: SectionGroup, index: number) => {
								const title = s.title.split(" ");

								return (
									<SectionCourses
										{...{
											...s,
											title: title.slice(0, title.length - 1).join(" "),
											emoji: title.slice(-1)[0]
										}}
										key={index}
										onGroupUpdate={val => setMarketplaceStoreState("group", val)}
									/>
								);
							})}
						{!hasActiveFilters && (
							<>
								<Row justify="center" style={{ marginTop: "43px" }}>
									<Button size="small" onClick={() => resetFilters({ group: "all" })}>
										{translate("_MARKETPLACE._SHOW_ALL_COURSES")}
									</Button>
								</Row>
							</>
						)}
						{hasActiveFilters && <AllCourses />}
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

const PublicMarketplaceWithContext = () => (
	<MarketplaceContextProvider>
		<PublicMarketplace />
	</MarketplaceContextProvider>
);

export { PublicMarketplaceWithContext as PublicMarketplace, useMarketplaceContext };

const BannerWrapper = styled(Row)`
	margin-bottom: 28px;
`;
