import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import { Space, Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Image from "common/components/general/Image";
import { formValidator } from "./ValidationSchema";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import { studentAvatars, teacherAvatars } from "./StaticAvatarsMapping";
import { Uploader, UploadImageType } from "common/components/uploader/Uploader";
import { previewFile } from "../../../../courseBuilder/utils/FileUtils";
import OAuth2Service from "services/authentication/oauth2.service";
import FileDto from "dtos/administration/file.dto";
import { SIGN_IN_PATH } from "../../../../login/scenes/signIn";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";

const { TabPane } = Tabs;

export enum ProfilePictureType {
	Static = "static",
	Upload = "upload"
}

const TRANSLATION_BASE_PATH = "_ONBOARDING._PROFILE_PICTURE";

class ProfilePictureComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			filesToDelete: [],
			profilePictureType: props.state.profilePictureType ?? ProfilePictureType.Static,
			fileUploaded: props.state.profilePictureFile,
			initialValues: props.state,
			userInfo: null
		};
	}

	componentDidMount() {
		const { t: translate, history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		this.setState({ userInfo: currentUserInfo });
		this.props.setIsFormValid(formValidator(translate).isValidSync(this.state.initialValues));
		this.props.setTitle(translate(`${TRANSLATION_BASE_PATH}._PROFILE_PICTURE`));
		this.getImage(this.state.fileUploaded);
	}

	getAvatarOptionsBasedOnRole() {
		if (!this.state.userInfo) return [];
		if (this.state.userInfo.Roles.some((role: any) => role.code === Role.Teacher)) return teacherAvatars;
		return studentAvatars;
	}

	onChangeProfilePictureTypeHandler(key: string, formik: any): void {
		formik.setFieldValue("profilePicture", undefined);
		if (key === ProfilePictureType.Static) {
			this.setState({
				imagePhoto: undefined,
				loadingThumbnailImage: undefined,
				fileUploaded: undefined
			});
		}
		this.setState({
			profilePictureType: key
		});
	}

	onUploadingDone = async (fileUploaded: any, formik: any) => {
		this.setState({ fileUploaded: fileUploaded });
		await this.getImageWithFormik(formik, fileUploaded);
	};

	getImageWithFormik = async (formik: any, file?: any) => {
		this.getImage(file);
		return formik.setFieldValue("profilePicture", file.id);
	};

	getImage = async (file?: any) => {
		if (!file) return;
		this.setState({ loadingThumbnailImage: true });
		const response = await previewFile(file);
		return this.setState({ imagePhoto: response.url, loadingThumbnailImage: false });
	};

	createFile = (fileUploaded: any): FileDto => {
		const file = fileUploaded;
		if (file) {
			file.updatedBy = this.state.userInfo?.UserId;
			file.createdBy = this.state.userInfo?.UserId;
		}
		return file;
	};

	onSubmit = async (values: any) => {
		const file = this.createFile(this.state.fileUploaded);

		const isStaticProfilePicture = values.profilePicture.indexOf(".") > -1;
		const calculatedPictureType = isStaticProfilePicture ? ProfilePictureType.Static : ProfilePictureType.Upload;

		const newState = {
			...this.props.state,
			profilePicture: values.profilePicture,
			profilePictureType: calculatedPictureType,
			profilePictureFile: !isStaticProfilePicture ? file : undefined
		};
		this.props.updateState(newState);

		await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				this.props.setIsSubmiting(false);

				const userSettings = OAuth2Service.CurrentUserSettings;

				localStorage.setItem(
					"user-settings",
					JSON.stringify({
						...userSettings,
						profilePicture: values.profilePicture,
						profilePictureType: calculatedPictureType,
						profilePictureFile: !isStaticProfilePicture ? file : undefined
					})
				);
				
				return OAuth2Service.isNotAtSuperOrganization && OAuth2Service.isTeacher
					? this.props.finish(newState.id ?? "", "", false) : this.props.goNext();
			})
			.catch(error => {
				console.log(error);
				this.props.setIsSubmiting(false);
			});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={this.state.initialValues}
				onSubmit={this.onSubmit}
				validate={values => {
					this.props.setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					this.props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-8">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Tabs
										defaultActiveKey={ProfilePictureType.Static}
										activeKey={this.state.profilePictureType}
										onChange={key => this.onChangeProfilePictureTypeHandler(key, formik)}
									>
										<TabPane
											tab={translate(`${TRANSLATION_BASE_PATH}._CHOOSE_PROFILE_PICTURE`)}
											key={ProfilePictureType.Static}
										>
											<Radio
												name="profilePicture"
												defaultValue={this.state.initialValues.profilePicture}
												value={formik.values.profilePicture}
											>
												<Row className="onboarding__static__avatars_list">
													{this.getAvatarOptionsBasedOnRole().map(item => {
														return (
															<Col
																xs={24}
																sm={12}
																md={6}
																lg={6}
																xl={6}
																xxl={6}
																key={item}
																className="p-6"
															>
																<RadioElement
																	value={item}
																	showAsBox={true}
																	className="onboarding__avatar_preview"
																>
																	<div className="onboarding__avatar_preview_image">
																		<Image
																			src={require(`assets/images/avatars/${item}`)}
																		/>
																	</div>
																</RadioElement>
															</Col>
														);
													})}
												</Row>
											</Radio>
										</TabPane>
										<TabPane
											tab={translate(`${TRANSLATION_BASE_PATH}._UPLOAD_PROFILE_PICTURE`)}
											key={ProfilePictureType.Upload}
										>
											<Space direction="horizontal" align="start">
												<Uploader
													fileType="IMAGE"
													filePath={`avatar/${this.state.userInfo?.UserId}`}
													fileWithUniqueId={false}
													uploadListType="picture-card"
													onUploadingDone={(fileUploaded: any) =>
														this.onUploadingDone(fileUploaded, formik)
													}
													showUploadList={false}
													uploadImageType={UploadImageType.Avatar}
												/>
												{this.state.imagePhoto || this.state.loadingThumbnailImage ? (
													<div className="onboarding__upload_avatar_preview_wrapper">
														{this.state.loadingThumbnailImage ? (
															<LoadingOutlined />
														) : (
															<Image src={this.state.imagePhoto} />
														)}
													</div>
												) : null}
											</Space>
										</TabPane>
									</Tabs>
								</Col>
							</Row>
						</form>
					);
				}}
			</Formik>
		);
	}
}

export const ProfilePicture = withTranslation()(ProfilePictureComponent);
