import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponentService from "services/design/pageComponent.service";
import { PageComponent } from "dtos/administration/customize/PageComponent.dto";
import { Col, Empty, message, Row, Skeleton } from "antd";
import { Space } from "common/components/layout/Space";
import { ADMIN_PATH } from "../../../../../../index";
import { ORGANIZATIONS_PATH } from "../../../list";
import { NavLink } from "react-router-dom";
import Button from "common/components/general/Button";
import { AdminTable } from "../../../../../../components/table/table";
import { RouteComponentProps } from "react-router";
import OrganizationDto from "dtos/administration/organization.dto";
import Card from "common/components/dataDisplay/Card";
import Text from "common/components/general/Text";
import PageService from "services/design/page.service";
import { Page } from "dtos/administration/customize/Page.dto";
import { PAGE_STATUS_PUBLISHED, PAGE_STATUS_UNPUBLISHED } from "../../utils/constants";
import Switch from "common/components/dataEntry/components/Switch";
import OrganizationPageService from "services/design/organizationPage.service";
import { v4 as uuidv4 } from "uuid";
import OrganizationPageComponentService from "services/design/organizationPageComponent.service";
import { OrganizationPageComponent } from "dtos/administration/customize/OrganizationPageComponent.dto";
import { OrganizationPage } from "dtos/administration/customize/OrganizationPage.dto";
import Icon from "common/components/general/Icon";

interface MatchParams {
	organizationId: string;
	templateId: string;
	pageId: string;
}

interface PropsInterface extends RouteComponentProps<MatchParams> {
	organizationDto: OrganizationDto;
}

export function PageComponents(props: PropsInterface) {

	const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._CUSTOMIZE._COMPONENTS";
	const [loading, setLoading] = useState(false);
	const [components, setComponents] = useState<PageComponent[]>([]);
	const [organizationPage, setOrganizationPage] = useState<OrganizationPage | null>(null);
	const [customizedContent, setCustomizedContent] = useState<boolean>(false);

	const [page, setPage] = useState<Page | null>(null);
	const { t: translate } = useTranslation();

	useEffect(() => {
		setLoading(true);

		const loadOrganizationPage = () => {
			return new OrganizationPageService()
				.find(props.match.params.organizationId, props.match.params.pageId)
				.then((res) => {
					return setOrganizationPage(res);
				})
				.catch(err => {
					console.log(err.message);
					message.error("Failed to load organization page");
				});
		};

		const loadPageInfo = () => {
			return new PageService()
				.find(props.match.params.pageId)
				.then(res => {
					return setPage(res);
				})
				.catch(err => {
					console.log(err);
					console.log("Failed getting pages");
				});
		};

		const loadPageComponents = () => {
			return new PageComponentService()
				.findAll(props.match.params.organizationId, props.match.params.pageId)
				.then(res => {
					const customized = res.filter((item) => item.organizationPageComponent && item.organizationPageComponent.length > 0);
					setCustomizedContent(customized.length > 0);
					return setComponents(res);
				})
				.catch(err => {
					console.log(err);
					console.log("Failed getting pages");
				});
		};

		Promise.all([loadOrganizationPage(), loadPageComponents(), loadPageInfo()])
			.then(() => {
				return setLoading(false);
			})
			.catch(err => {
				console.log(err);
				console.log("Failed to load pages");
			});
	}, []);

	const updateStatus = async (checked: boolean, record: PageComponent) => {
		let orgaPage;
		// Create a relation if none
		if (!organizationPage) {
			orgaPage = await new OrganizationPageService()
				.save({
					id: uuidv4(),
					status: PAGE_STATUS_PUBLISHED,
					pageId: props.match.params.pageId,
					organizationId: props.match.params.organizationId
				});
			setOrganizationPage(orgaPage);
		}

		// Create/Update an OrganizationPageComponent
		const data: Partial<OrganizationPageComponent> = {
			status: checked ? PAGE_STATUS_PUBLISHED : PAGE_STATUS_UNPUBLISHED,
			pageComponentId: record.id,
			organizationPageId: organizationPage?.id || orgaPage?.id,
		};
		record.organizationPageComponent?.length && (data.id = record.organizationPageComponent[0].id);
		const res: OrganizationPageComponent = await new OrganizationPageComponentService().updateOrCreate(data);
		// Set new components to the table record
		const newComponents = components.map(_ => {
			if (_.id === record.id) {
				_.organizationPageComponent = [res];
			}

			return _;
		});
		!customizedContent && setCustomizedContent(true);


		return setComponents(newComponents);
	};


	function resetPage(id: string) {
		if (!organizationPage?.id) {
			return;
		}
		return new OrganizationPageComponentService()
			.reset(organizationPage.id, id)
			.then(() => {
				const newComponents = components.map(_ => {
					if (_.id === id) {
						_.organizationPageComponent = [];
					}

					return _;
				});
				return setComponents(newComponents);
			})
			.catch(err => {
				message.error("Failed resetting, please refresh the page");
				console.log(err.message);
			});
	}

	const columnsTable = () => {
		return [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._STATUS`),
				dataIndex: "status",
				key: "status",
				render: (status: number, record: PageComponent) => {
					const checkedAttr =
						!!record.organizationPageComponent?.length &&
						record.organizationPageComponent[0].status === PAGE_STATUS_PUBLISHED;

					return (
						<Row key="status">
							<Space>
								<Col>
									<Row>
										<Switch
											checked={checkedAttr}
											onChange={checked => updateStatus(checked, record)}
										/>
									</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._PAGENAME`),
				dataIndex: "title",
				key: "title",
				render: (text: any) => {
					return (
						<Row key="title" align="middle">
							<Space>
								<Col>
									<Row>{text}</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ACTIONS`),
				dataIndex: "id",
				key: "id",
				fixed: "right",
				width: 150,
				render: (id: any, record: any) => {
					const url =
						`/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${props.match.params.organizationId}` +
						`/customize/${props.match.params.pageId}/${id}/edit-components`;

					return (
						<Row key="actions" align="middle">
							<Space>
								<NavLink to={url}>
									<Button type="primary" shape="circle" icon="ri-edit-line" />
								</NavLink>
							</Space>
							{record.organizationPageComponent?.length > 0 && (
								<Space className="ml-4">
									<Icon
										tooltip="Warning! Data will be lost"
										type="ri-restart-line"
										className="color-green-6 mt-4 ml-4 cursor_pointer"
										fontSize="20"
										onClick={() => resetPage(id)}
									/>
								</Space>
							)}
						</Row>
					);
				}
			}
		];
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Skeleton loading={loading}>
						<Card>
							{page && (
								<Row className="mb-24" justify="space-between">
									<Col>
										{!customizedContent && (
											<Icon
												tooltip="Warning! Components have the default status and data."
												type="ri-information-line"
												className="color-orange-6 mt-4 mr-4"
												fontSize="16"
											/>
										)}
										<Text fontSize="20" lineHeight="22" className="color-gray-9">
											{page.title}
										</Text>
									</Col>
								</Row>
							)}
							<AdminTable
								columns={columnsTable()}
								dataSource={components}
								locale={{
									emptyText: (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
										/>
									)
								}}
							/>
						</Card>
					</Skeleton>
				</Col>
			</Row>
		</>
	);
}
