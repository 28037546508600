import ApiServiceData from "../api.service";
import UserDto from "../../dtos/authentication/user/user.dto";
const relativeUrl = "/v1/api/account-email";

export default class AccountEmailService extends ApiServiceData {
	public resentVerificationEmail(userId: string, recaptchaValue: string): Promise<string> {
		return this.post(relativeUrl + "/send/" + userId, {
			type: 1,
			recaptchaValue: recaptchaValue
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public sentForgetPasswordEmail(email: string, recaptchaValue: string): Promise<string> {
		return this.post(relativeUrl + "/forget-password", {
			email: email,
			recaptchaValue: recaptchaValue
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public generateLinkForResetPassword(userId: string): Promise<string> {
		return this.post(relativeUrl + "/link-for-reset-password/" + userId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getListOfAccounts(email: string, definedSignedToken: string): Promise<UserDto[]> {
		return this.post(relativeUrl + "/list-of-accounts", {
			email: email
		}, undefined, definedSignedToken)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
