import { Col, Divider, Menu, Row, Skeleton, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";

import TagService from "services/administration/tag.services";
import Text from "common/components/general/Text";

const TRANLSATION_BASE_PATH = "_SUBJECTS._ITEM_FILTERS";

interface ItemFiltersProps {
	parentTag: any;
	modalPresentation?: boolean;
}

export const ItemFilters = (props: ItemFiltersProps) => {
	const { t: translate } = useTranslation();
	const [loadingTags, setLoadingTags] = useState(false);
	const [tags, setTags] = useState([]);
	const [expanded, setExpanded] = useState<Array<string>>([]);
	const activeFilters = useStoreState(state => state.subjects.activeFilters);
	const allItems = useStoreState(state => state.subjects.allItems);
	const updateFilters = useStoreActions((actions: any) => actions.subjects.updateFilters);
	const location = useLocation();

	useEffect(() => {
		setLoadingTags(true);
		new TagService()
			.getAllFor()
			.then((result: any) => {
				return setTags(result);
			})
			.finally(() => {
				setLoadingTags(false);
			});
	}, []);

	const onFilterChange = (item: any) => {
		let temp = [...activeFilters];
		if (
			activeFilters.some((x: any) => {
				return item.id === x.id;
			})
		) {
			temp = activeFilters.filter((x: any) => {
				return x.id !== item.id;
			});
		} else {
			const elementToRemove = temp.find((tag: any) => tag.parentId === item.ParentId);
			if (elementToRemove) {
				temp.splice(temp.indexOf(elementToRemove), 1);
			}
			temp.push({ id: item.id, name: item.name, parentId: item.ParentId });
		}
		updateFilters({ filters: temp, items: allItems });
	};

	const getContentMenu = (groupChildrenTag: any[]) => {
		console.log(groupChildrenTag);
		return (
			<Space direction="vertical" className="full__width" size={6}>
				<Row>
					<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-9">
						Përmbledhje
					</Text>
				</Row>
				<Row>
					{groupChildrenTag && groupChildrenTag.length > 0 && (
						<Menu
							mode="inline"
							inlineIndent={0}
							inlineCollapsed={false}
							defaultSelectedKeys={["0"]}
							className="background-color-gray-2 courseBuilder__subjectPlanCreator-menuContent"
						>
							{groupChildrenTag.map((childrenTag: any, indexChild: any) => {
								return (
									<Menu.Item key={indexChild} className="background-color-gray-2">
										<Link
											to={childrenTag.name}
											spy={true}
											smooth={true}
											className="background-color-gray-2"
										>
											{childrenTag.name}
										</Link>
									</Menu.Item>
								);
							})}
						</Menu>
					)}
				</Row>
			</Space>
		);
	};

	const RenderFiltersForLessons = () => {
		return getContentMenu(props.parentTag);
	};

	const RenderFiltersForClassrooms = () => {
		return tags
			.filter((x: any) => {
				return x.target === "CLASSROOM" && !x.ParentId;
			})
			.map((tag: any, index: number) => {
				const subTags = tags.filter((item: any) => {
					return item.ParentId === tag.id;
				});
				return (
					<Skeleton key={index} loading={loadingTags}>
						<Row>
							<Col className="pt-0">
								<Text
									fontSize="12"
									lineHeight="20"
									wheight="semibold"
									className="color-gray-9 pr-8 pl-8"
								>
									{tag.name.toUpperCase()}
								</Text>
							</Col>
						</Row>
						<Divider className="pb-16 m-0 mt-4" />
						<Row>
							{subTags
								.slice(0, expanded.includes(tag.id) ? subTags.length : 8)
								.map((childTag: any, indexChild: number) => {
									const classStyle = activeFilters.some((x: any) => {
										return childTag.id === x.id;
									})
										? "itemFilters__selected__filter"
										: "";
									return (
										<Col
											key={indexChild}
											xs={24}
											sm={24}
											md={24}
											lg={24}
											xl={24}
											xxl={24}
											onClick={() => onFilterChange(childTag)}
											className={`cursor_pointer mb-4 pl-8 pr-8 pt-4 pb-4 mr-4 ${classStyle}`}
										>
											<Text fontSize="14" lineHeight="22">
												{childTag.name}
											</Text>
										</Col>
									);
								})}
							{subTags.length > 8 && !expanded.includes(tag.id) && (
								<Col
									xs={11}
									sm={7}
									md={24}
									lg={24}
									xl={24}
									xxl={24}
									onClick={() => setExpanded([...expanded, tag.id])}
									className="cursor_pointer mb-4 pl-8 pr-8 pt-4 pb-4 mr-4"
								>
									<Row justify="center">
										<Text fontSize="14" lineHeight="22" className="color-blue-6">
											{translate(`${TRANLSATION_BASE_PATH}._MORE_FILTERS`, {
												number: subTags.length - 8
											})}
										</Text>
									</Row>
								</Col>
							)}
						</Row>
						<Row className="mb-16"></Row>
					</Skeleton>
				);
			});
	};

	const RenderFilters = () => {
		if (location.pathname.indexOf("/subjects/course/") > -1) return RenderFiltersForLessons();
		return RenderFiltersForClassrooms();
	};

	return (
		<Row className="pt-16">
			<Col span="24">
				<Skeleton loading={loadingTags} active>
					{tags && tags.length > 0 && RenderFilters()}
				</Skeleton>
			</Col>
		</Row>
	);
};
