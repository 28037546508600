import React from "react";
import { useTranslation } from "react-i18next";
import DigitalizeSuccess from "assets/images/digitalize-success.svg";
import Icon from "common/components/general/Icon";
import { Row, Modal } from "antd";
import Button from "common/components/general/Button";
import Text from "common/components/general/Text";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT._SUCCESS";

type Props = {
	onCancel: () => void,
}

export const Success = (props: Props) => {
	const { t: translate } = useTranslation();
	const { onCancel } = props;
	return (
		<Modal
			visible
			centered
			title=""
			footer=""
			width="100%"
			style={{ maxWidth: "480px" }}
			bodyStyle={{ padding: 0 }}
			onCancel={() => onCancel()}
		>
			<img width="100%" src={DigitalizeSuccess} alt='' />
			<div className="p-24">
				<Row justify="center" className="mb-16 mt-8">
					<Text fontSize="46">
						<Icon className="color-blue-6" type="ri-mail-send-line" />
					</Text>
				</Row>
				<Row justify="center" className="tutoring__welcome_title">{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</Row>
				<Row className="tutoring__welcome_subtitle">{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}</Row>
				<Row className="mt-16" align="middle" justify="center">
					<Button type="primary" size="middle" onClick={() => onCancel()}>{translate(`${TRANSLATION_BASE_PATH}._OK`)}
					</Button>
				</Row>
			</div>
		</Modal>
	);
};