import { Card, Col } from "antd";
import React from "react";
import { PageComponentSpecsOptionType, PageComponentValues } from "../../utils/types";
import { InputGenerator } from "./InputGenerator";
import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";

interface SettingsInterface {
	settings: PageComponentSpecsOptionType[];
	values: PageComponentValues;
	organizationId: string;
}

export function Settings(props: SettingsInterface) {
	return (
		<>
			<Card>
				<Row className="mb-24">
					<Col xs={24}>
						<Text fontSize="38" lineHeight="22" className="color-gray-9">
							Settings
						</Text>
					</Col>
				</Row>
				{props.settings.map((item: PageComponentSpecsOptionType, index) => {
					return (
						<Row key={index} className="mb-12">
							<Col xs={24}>
								<InputGenerator
									organizationId={props.organizationId}
									key={index}
									specs={item}
									value={props.values[item.var]}
								/>
							</Col>
						</Row>
					);
				})}
			</Card>
		</>
	);
}
