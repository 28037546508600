import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

interface TableRowNavLinkProps{
	to: string;
}

export const TableRowNavLink = (props: TableRowNavLinkProps) => {
	return (
		<NavLink to={props.to}>
			<Text fontSize="20" lineHeight="32" className="color-gray-6">
				<Icon type="ri-arrow-right-line" />
			</Text>
		</NavLink>
	);
};
