import { Col, Divider, Row } from "antd";
import React from "react";

import Text from "common/components/general/Text";
import { Link } from "react-router-dom";
import { SIGN_IN_PATH } from "../signIn";
import { REGISTER_PATH } from "../register";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/layout";

export const PRIVACY_POLICY_PATH = "privacy-policy";

const TRANSLATION_BASE_PATH = "_LOGIN._EMAIL_VERIFICATION";

export const PrivacyPolicy = (props: any) => {
	const { t: translate } = useTranslation();

	const Component = () => {
		return (
			<>
				<Row>
					<Col span={24} className="flex__column">
						<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-gray-9 mb-24">
							Politikat e Privatësisë
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							TË PËRGJITHSHME
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Akademi.al (“Kompania” ose “ne” ose ” tonë”) respekton privatësinë e përdoruesve të tij
							(“përdorues” ose “ju”) që përdorin faqen tonë të internetit{" "}
							<a href="https://akademi.al/" target="_blank" rel="noopener noreferrer">
								www.akademi.al
							</a>
							. Këtu përfshihen format e tjera të mediave, kanalet e mediave, websiten ose aplikacionin
							mobile të lidhur me të (kolektivisht, “Website”). Politika e mëposhtme e Privatësisë së
							Kompanisë (“Politikat e Privatësisë”) është krijuar për t’ju informuar ju, si përdorues të
							Website, për llojet e informacionit që mund të mbledhë Kompania ose të mbledhë nga ju në
							lidhje me përdorimin tuaj të Website. Politikat e Privatësisë gjithashtu kanë për qëllim të
							shpjegojë kushtet në të cilat Kompania përdor dhe shpalos atë informacion, dhe të drejtat
							tuaja në lidhje me atë informacion. Ndryshimet në këtë Politikë të Privatësisë diskutohen në
							fund të këtij dokumenti.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							DUKE PËRDORUR OSE HYRË NË WEBSITE, JU PO PRANONI PRAKTIKAT E PËRSHKRUARA NË KËTË POLITIKË TË
							PRIVATËSISË.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-24">
							MBLEDHJA, PËRDORIMI DHE SHPËRNDARJA E INFORMACIONEVE JO- PERSONALE-IDENTIFIKUESE
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-16">
							Përdoruesit e faqes në përgjithësi
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Kompania mbledh nga përdoruesit e website Informacione Jo-Personale-Identifikuese të llojit
							që shfletuesit e website, në varësi të cilësimeve të tyre. Ky informacion përfshin adresën e
							Protokollit të Internetit (IP) të përdoruesit, sistemin operativ, llojin e shfletuesit dhe
							vendndodhjet e faqeve të internetit që përdoruesi shikon menjëherë para se të arrijë, gjatë
							lundrimit dhe menjëherë pas largimit nga website. Duke identifikuar trendet e përdorimit,
							Kompania është në gjendje të dizenjojë më mirë websiten për të përmirësuar përvojat e
							përdoruesve në aspektin e përmbajtjes ashtu edhe lehtësinë e përdorimit.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-24">
							POLITIKA E PRIVATËSISË SË FËMIJËVE / NXËNËSVE NËN 13 VJEÇ TË AKADEMI.AL
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-16">
							Akademi.al është e përkushtuar në privatësinë e fëmijëve.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Mbrojtja e privatësisë së fëmijëve është veçanërisht e rëndësishme për ne. Për atë arsye, ne
							kemi krijuar disa funksionalitete të programuara për të ndihmuar në mbrojtjen e
							informacionit personal në lidhje me nxënësit që janë më pak se 13 vjeç ("Nxënës nën 13
							vjeç"). Akademia.al nuk lejon që ‘Nxënësit nën 13 vjeç’ të regjistrohen drejtpërdrejt për
							Shërbimin tonë pa miratimin e një Prindi ose Kujdestari ligjor. Nëse Akademi.al mëson se
							Informacioni Personal i një ‘Nxënësi nën 13 vjeç’ është mbledhur në Shërbimin tonë pa
							miratimin e prindërit apo kujdestarit, atëherë Akademi.al do të ndërmarrë hapat e duhur për
							të fshirë këtë informacion. Nëse jeni prind ose kujdestar ("Prind") dhe zbuloni se fëmija
							juaj nën moshën 13 vjeç ka një llogari të regjistruar në Shërbimin tonë pa miratimin tuaj,
							ju lutemi njoftoni Akademi.al në support@akademi.al dhe kërkoni që ne ta fshijmë atë
							informacion personal nga sistemet tona.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-24">
							Si mund të regjistrohen dhe të përdorin fëmijët Shërbimin tone:
						</Text>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9 mb-16">
							Kur një ‘Nxënës nën 13 vjeç’ regjistrohet për një Llogari të Akademi.al ne kërkojmë
							miratimin e Prindit / Kujdestarit Ligjor.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Kur një ‘Nxënës nën 13 vjeç’ regjistrohet për Shërbimin tonë, ne kërkojmë një datëlindje,
							emrin e përdoruesit, fjalëkalimin dhe adresën e postës elektronike të një prindi në mënyrë
							që të mund t’i dërgojmë me email Prindit të ‘’Nxënësit nën 13 vjeç’’ në mënyrë që të
							kërkojmë pëlqimin që Fëmija / ‘Nxënësi nën 13 vjeç’ të përdorë Shërbimin. Akademi.al nuk
							pyet ’Nxënësin nën 13 vjeç’ për më shumë informacion sesa është e nevojshme për të siguruar
							Shërbimin.
						</Text>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9 mb-16">
							Metodat për sigurimin e pëlqimit prindëror.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Prindërit mund të japin pëlqimin që një ‘Nxënës nën 13 vjeç’ të përdorë Shërbimin përmes një
							Llogarie duke iu përgjigjur pozitivisht një emaili dërguar nga Akademi.al në adresën e
							postës elektronike të Prindit të dhënë nga ‘Nxënësi nën 13 vjeç’ gjatë regjistrimit. Nëse
							nuk marrim pëlqimin / konfirmimin nga Prindi, llogaria e ‘Nxënës nën 13 vjeç’ nuk eshte
							aktive dhe informacionet personale të Fëmijës do të fshihen nga sistemet tona. Llogaritë
							joaktive / te pakonfirmuara nuk kanë asnje qasje në Shërbim.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Për të mundësuar funksionalitete shtesë për llogarinë e ‘Nxënës nën 13 vjeç’ dhe për të
							mundësuar hyrjen dhe kontrollin e plotë mbi veprimtarinë e llogarisë së ‘Nxënës nën 13
							vjeç’, Prindi duhet të krijojë një Llogari ‘Prind’ në Shërbim dhe të lidhet me llogarinë e
							‘Nxënës nën 13 vjeç’ duke klikuar në lidhjen e lidhjes të llogarisë Prind – Fëmijë, në
							kryefaqen e llogarise Prind. Llogaritë prindërore kontrollojnë cilësimet dhe
							funksionalitetin e llogarisë ‘Nxënës nën 13 vjeç’. Prindi mund të mbajë Llogarinë e Fëmijës
							dhe / ose mund të mundësojë funksione shtesë dhe veçori të ndarjes së të dhënave, nëse
							dëshiron. Prindërit mund të revokojnë lejet e një ‘Nxënës nën 13 vjeç’ për të përdorur
							Shërbimin në çdo kohë duke fshirë llogarinë.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-16">
							Çfarë informacioni mbledhim nga një ‘Nxënës nën 13 vjeç’?
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Ne mbledhim një emër përdoruesi, emer mbiemer, datën e lindjes dhe adresën e postës
							elektronike të Prindit kur një ‘Nxënës nën 13 vjeç’regjistrohet në Shërbim. Ne mbledhim
							informacione në lidhje me përdorimin e Shërbimit nga ‘Nxënës nën 13 vjeç’ (mësimet e
							përfunduara, simbolet e fituara) si dhe përmbajtjen që Fëmija poston në Shërbim. Ne
							gjithashtu mbledhim informacionin e përdorimit dhe pajisjes, siç përshkruhet në Politikën
							tonë të Privatësisë. Ne i përdorim këto informacione për të siguruar Shërbimin për ‘Nxënës
							nën 13 vjeç’ dhe për qëllimet e përshkruara në seksionin e Politikës tonë të Privatësisë "
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
								MBLEDHJA, PËRDORIMI DHE SHPËRNDARJA E INFORMACIONEVE IDENTIFIKUESE PERSONALE
							</Text>
							". Ne përdorim adresën e postës elektronike të Prindit për të komunikuar mesazhe në lidhje
							me llogarinë e krijuar.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-16">
							Ndjekja e palëve të treta dhe reklamimi në internet.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Akademi.al nuk shfaq ndonjë reklamë të targetuar në Shërbim. Ne nuk zbulojmë informacione
							personale të ‘Nxënës nën 13 vjeç’ për qëllime të marketingut direkt ose për qëllime
							reklamuese. Ndërsa ne i lejojmë partnerët e reklamave të palëve të treta të veprojnë në
							Shërbimin tonë për qëllime të rigjenerimit, analizave dhe shërbimeve të atribuimit (Shikoni
							seksionin tonë të Politikës së Privatësisë "
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
								MBLEDHJA DHE PËRDORIMI I INFORMACIONIT NGA PALËT E TRETA
							</Text>
							", ne marrim hapa për të çaktivizuar rrjetet e reklamave të palëve të treta në faqet e
							internetit me përmbajtje e drejtuar nga Fëmija.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9 mb-16">
							YouTube
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Akademi.al përdor YouTube për të shfaqur përmbajtje video në Shërbim. YouTube përfshin
							teknologji gjurmimi, të cilat mund të jenë të pranishme në videot YouTube të ngulitura në
							Shërbim, të cilat mund të mbledhin informacion nga shfletuesi juaj kur shikoni videon në
							Shërbim, duke përfshirë identifikuesit e pajisjes dhe / ose ID-të e cookie-t. Ky informacion
							mblidhet drejtpërdrejt dhe automatikisht nga YouTube dhe partnerët e tij dhe Akademi.al nuk
							merr pjesë në këto transmetime të të dhënave. Akademi.al nuk jep asnjë informacion personal,
							të tillë si emrat e përdoruesit, në YouTube.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Cookies në internet
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Një “Web Cookie” është një varg informacioni që cakton një identifikim unik që një website
							ruan në kompjuterin e një përdoruesi dhe që shfletuesi i përdoruesit i jep në website sa
							herë që përdoruesi dërgon një kërkesë në faqen e internetit. Ne përdorim cookie-t në Website
							për të mbajtur nën kontroll shërbimet që keni përdorur, për të ruajtur informacionin e
							regjistrimit në lidhje me emrin tuaj dhe fjalëkalimin, për të regjistruar preferencat e
							përdoruesit, për t’ju mbajtur të loguar në Website dhe për të lehtësuar procedurat e
							navigimit. Kompania gjithashtu përdor Cookie Web për të gjurmuar faqet që përdoruesit
							vizitojnë gjatë secilës seancë në Website, për të ndihmuar kompaninë të përmirësojë përvojat
							e përdoruesve dhe të kuptojë se si po përdoret Website.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Ashtu si me Informacionet e tjera Identifikuese Jo-Personale të mbledhura nga përdoruesit e
							Websitet, Kompania analizon informacionin e formës së grumbulluar duke përdorur Web Cookies,
							në mënyrë që të ndihmojë kompaninë, partnerët e saj dhe të tjerët të kuptojnë më mirë se si
							po përdoret Website. Përdoruesit e kompanisë që nuk dëshirojnë të kenë cookie në internet të
							vendosur në kompjuterët e tyre duhet të refuzojnë cookie-t në internet përpara se të hyjnë
							në faqen e internetit.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Analiza Statistikore
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Ne mund të përdorim elementë të palëve të treta, duke përfshirë Google dhe Facebook. Cookies
							të tilla si Google Analytics dhe pixel në Facebook përdoren për të informuar, optimizuar dhe
							shërbyer reklama bazuar në aktivitetin tuaj të kaluar në website. Informacioni i mbledhur
							mund të përdoret për të, ndër të tjera, për të analizuar dhe ndjekur të dhënat, për të
							përcaktuar popullaritetin e përmbajtjes dhe për të kuptuar më mirë veprimtarinë në internet.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Informacione të grumbulluara
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Akademi.al mund të ndajë informacionin e grumbulluar dhe jo personalisht identifikues që
							mbledhim në cilëndo nga rrethanat e mësipërme. Ne gjithashtu mund ta ndajmë atë me palët e
							treta dhe kompanitë tona partnere për të zhvilluar dhe shpërndarë reklama të synuara në
							Website dhe në faqet e internetit të palëve të treta. Ky informacion nuk përmban ndonjë
							informacion personal-identifikues dhe mund të përdoret për të zhvilluar përmbajtje në
							internet dhe shërbime që shpresojmë që ju dhe përdoruesit e tjerë të gjeni interesim dhe të
							synoni përmbajtjen dhe reklamat.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							MBLEDHJA, PËRDORIMI DHE SHPËRNDARJA E INFORMACIONEVE IDENTIFIKUESE PERSONALE
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Regjistrimi në Website
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Siç përcaktohet më lart, Informacioni Identifikues Personal është informacion që mund të
							shoqërohet drejtpërdrejt nga një person specifik. Kompania mund të mbledhë një sërë
							informacionesh për identifikimin personal nga dhe rreth përdoruesve të Website. Pjesa më e
							madhe e Informacionit Identifikues Personal të mbledhur nga Kompania për përdoruesit është
							informacion i dhënë nga vetë përdoruesit kur (1) regjistrohen për shërbimin tonë ose (2)
							komunikojnë me ne. Ky informacion mund të përfshijë emrin, adresën, adresën e emailit të
							secilit përdorues. Përdoruesit e Website nuk kanë asnjë detyrim t’i ofrojnë Kompanisë
							informacione Identifikuese Personale të çdo lloji.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Komunikimet e Kompanisë
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Ndonjëherë mund të përdorim emrin dhe adresën tuaj të postës elektronike për t’ju dërguar
							njoftime dhe mesazhe promocionale në lidhje me shërbimet e reja të ofruara nga website ose
							nga palë të treta, për të cilat ne mendojmë se mund t’i gjeni të vlefshme. Ne gjithashtu
							mund t’ju dërgojmë njoftime të lidhura me shërbimin herë pas here. Ju mund të hiqni dorë nga
							emailet e tilla duke na kontaktuar në format tona të kontaktit.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Zbulimet e Kompanisë
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Kompania do të zbulojë Informacionin Identifikues Personal në këto rrethana:
						</Text>
						<ul>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
									<strong>Me ligj ose për të mbrojtur të drejtat</strong>. Kur besojmë se zbulimi
									është i përshtatshëm, ne mund të zbulojmë Informacionin Identifikues Personal në
									lidhje me përpjekjet për të hetuar, parandaluar ose ndërmarrë ndonjë veprim tjetër
									në lidhje me veprimtari të paligjshme, mashtrim të dyshuar ose ndonjë keqbërje
									tjetër; për të mbrojtur të drejtat, pronën ose sigurinë e Kompanisë, përdoruesve
									tanë, punonjësve tanë ose të tjerëve; në pajtim me ligjin në fuqi ose në bashkëpunim
									me zbatimin e ligjit; për të zbatuar Kushtet tona të Përdorimit ose marrëveshjet ose
									politikat e tjera, në përgjigje të një kërkese të ngjashme hetimore, një urdhër
									gjykate ose një kërkesë për bashkëpunim nga një zbatim i ligjit ose një agjenci
									tjetër qeveritare; të krijojmë ose ushtrojmë të drejtat tona ligjore; në mbrojtje
									kundër pretendimeve ligjore; ose siç kërkohet ndryshe me ligj. Në raste të tilla, ne
									mund të ngremë ose të heqim dorë nga çdo kundërshtim ligjor ose e drejtë e
									disponueshme për ne.
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
									<strong>Komunikimet e marketingut</strong>. Në qoftë se përdoruesit nuk heqin dorë
									nga marrja e materialeve të marketingut të Kompanisë pas regjistrimit, Kompania mund
									t’i dërgojë përdoruesit me email në lidhje me produktet dhe shërbimet për të cilat
									Kompania beson se mund të jenë me interes për ta. Nëse dëshironi të hiqni dorë nga
									marrja e materialeve të marketingut, ju mund ta bëni këtë duke bërë unsubscribe me
									email ose të na kontaktoni.
								</Text>
							</li>
						</ul>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Ndryshimi Informacionit Identifikues Personal; Mbyllja e llogarisë
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Në çdo kohë mund të rishikoni ose ndryshoni Informacionin tuaj Identifikues duke shkuar te
							cilësimet e llogarisë tuaj (nëse aplikohet) ose duke na kontaktuar. Me kërkesën tuaj, ne do
							të çaktivizojmë ose fshijmë llogarinë dhe informacionin e kontaktit nga bazat e të dhënave
							aktive. Një informacion i tillë do të çaktivizohet ose fshihet sa më shpejt që të jetë e
							mundur bazuar në veprimtarinë e llogarisë tuaj dhe në përputhje me politikën tonë të
							çaktivizimit dhe ligjin në fuqi. Për ta bërë këtë kërkesë, ose shkoni te cilësimet e
							llogarisë suaj (nëse aplikohet) ose kontaktoni me ne. Për më tepër, ne mbajmë një histori të
							informacionit të ndryshuar për të hetuar ndonjë mashtrim të lidhur me llogarinë tuaj.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Përdorimi i përgjithshëm
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Kompania përdor Informacionin Identifikues Personal në skedarin që ruan për ju, dhe
							informacione të tjera që marrim nga aktivitetet tuaja aktuale dhe të kaluara në Website. Kjo
							bëhet: (1) për të ofruar produktet dhe shërbimet që keni kërkuar; (2) për të menaxhuar
							llogarinë tuaj dhe t’ju ofrojë mbështetje përdoruesit; (3) për të komunikuar me ju përmes
							postës elektronike, (4) për të zhvilluar dhe shfaqur përmbajtje dhe reklama të përshtatshme
							për interesat tuaja në Website dhe faqet e tjera; (5) për të zgjidhur mosmarrëveshjet dhe
							problemet e zgjidhjes; (6) për të matur interesin e konsumatorit për shërbimet tona; (7)
							t’ju informojmë për azhurnimet; (8) për të rregulluar përvojën tuaj dhe (9) për të zbatuar
							Kushtet tona të Përdorimit. Ndonjëherë, ne mund të shikojmë nëpër përdorues të shumtë për të
							identifikuar problemet.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							MBLEDHJA DHE PËRDORIMI I INFORMACIONIT NGA PALËT E TRETA
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Kompania ndalon kontraktorët, bashkëpunëtorët e saj, shitësit dhe furnizuesit të zbulojnë
							Informacionin e Identitetit Personal të marrë nga Kompania, përveç se në përputhje me këtë
							Politikë të Privatësisë. Sidoqoftë, palët e treta nuk kanë asnjë detyrim të zbatojnë këtë
							Politikë të Privatësisë në lidhje me Informacionin e Identitetit Personal që përdoruesit u
							japin drejtpërdrejt palëve të treta, ose që ato palë të treta mbledhin vetë. Këto palë të
							treta përfshijnë reklamuesit, ofruesit e lojërave, shërbimet, widget dhe një shumëllojshmëri
							të aplikacioneve të tjera të palëve të treta të arritshme përmes websitet. Kompania as nuk
							posedon dhe as kontrollon faqet e internetit të palëve të treta dhe aplikacionet e arritshme
							përmes Website.
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Kështu, kjo Politikë e Privatësisë nuk vlen për informacionet e dhëna ose të mbledhura nga
							palët e treta që i përdorin ato. Para se të vizitoni një palë të tretë, ose të përdorni një
							aplikacion të palëve të treta, qoftë me anë të një lidhjeje në website, direkt përmes
							website ose në ndonjë mënyrë tjetër, dhe përpara se të regjistroni ndonjë Informacion
							Personal për ndonjë palë të tretë, përdoruesit duhet të informojnë veten e tyre mbi
							Politikat dhe Praktikat e Privatësisë (nëse ka) të palës së tretë përgjegjëse për atë faqe
							interneti ose aplikacion, dhe duhet të ndërmarrin ato hapa të nevojshëm për të mbrojtur
							privatësinë e tyre.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							SIGURIA
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Ne e marrim seriozisht sigurinë e Informacionit të Identitetit Personal dhe përdorim masa të
							arsyeshme elektronike për ta mbrojtur atë nga humbja, vjedhja, ndryshimi ose keqpërdorimi.
							Sidoqoftë, ju lutemi të informoheni që edhe masat më të mira të sigurisë nuk mund të
							eleminojnë plotësisht të gjitha rreziqet. Ne nuk mund të garantojmë që vetëm personat e
							autorizuar do të shohin informacionin tuaj. Akademi.al nuk është përgjegjës për ndërhyrjen e
							palëve të treta. Ne jemi të përkushtuar për të mbrojtur të gjitha informacionet në Website
							ashtu siç është e nevojshme. Sidoqoftë, ju jeni përgjegjës për ruajtjen e konfidencialitetit
							të Informacionit tuaj të Identitetit Personal duke e mbajtur të fshehtë fjalëkalimin tuaj.
							Ju duhet të ndryshoni fjalëkalimin tuaj menjëherë nëse besoni se dikush ka fituar akses të
							paautorizuar në të ose llogarinë tuaj. Nëse humbni kontrollin e llogarisë tuaj, duhet të na
							njoftoni menjëherë.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							NDRYSHIME TË POLITIKAVE TË PRIVATËSISË
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Kompania mund, sipas gjykimit të saj, ta ndryshojë Politikat e Privatësisë herë pas here. Të
							gjitha ndryshimet në Politikat e Privatësisë do të pasqyrohen në këtë faqe. Politikat e
							Privatësisë vlejnë për të gjitha informacionet që kemi për ju dhe llogarinë tuaj.
							Përdoruesit duhet të kontrollojnë rregullisht këtë faqe për çdo ndryshim në këtë Politikë të
							Privatësisë. Kompania gjithmonë do të postojë versione të reja të Politikës së Privatësisë
							në Website. Sidoqoftë, Kompania mundet, siç përcaktohet në diskrecionin e saj, të vendosë të
							njoftojë përdoruesit për ndryshimet e bëra në këtë Politikë të Privatësisë përmes postës
							elektronike ose ndryshe. Prandaj, është e rëndësishme që përdoruesit të ruajnë dhe
							azhurnojnë informacionin e tyre të kontaktit.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							FËMIJËT
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Ligji nr.18 për mbrojtjen e të drejtave të fëmijëve në Republikën e Shqipërisë u miratua nga
							parlamenti në shkurt 2017 dhe hyri në fuqi në qershor 2017. Ligji përcakton të drejtat dhe
							mbrojtjen që gëzon secili fëmijë, mekanizmat dhe autoritetet përgjegjëse, të cilat
							garantojnë në mënyrë efektive ushtrimin, respektimin, promovimin e këtyre të drejtave dhe
							mbrojtjen e veçantë të fëmijës. Qëllimi i këtij ligji është: të përcaktojë të drejtat dhe
							qasjen në të drejtat; mekanizma konkrete dhe efektive që garantojnë realizimin efektiv të
							këtyre të drejtave; kujdes i veçantë për fëmijët; krijimi i një sistemi të integruar të
							mbrojtjes së fëmijëve. Ne nuk mbledhim ose ruajmë me vetëdije Informacione të Identitetit
							Personal nga fëmijët, përveç nëse nëse është e lejuar me ligj. Personi i cili siguron
							Informacione të Identitetit Personal përmes website, merr materiale që janë në përputhje me
							legjislacionin shqiptar.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							PRIVATËSIA
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Mbrojtja e privatësisë dhe të dhënave personale janë çështje të një rëndësie të veçantë dhe
							akademi.al është i përkushtuar të zbatojë rigorozisht të gjithë kuadrin ligjor në mbrojtje
							dhe respektim të tyre, veçanërisht Ligjin 9887, datë 10.03.2008 “Për Mbrojtjen e të Dhënave
							Personale” si dhe aktet nënligjore përkatëse. Tё dhënat personale mbrohen nёpёrmjet
							fjalёkalimit qё ju pёrdorni pёr ndjekjen e mёsimeve nё rrugё elektronike. Pёrdoruesi merr
							përsipër që këtë fjalёkalim ta mbajë gjithmonё të fshehtë. Nëse pёrdoruesi mendon ose dyshon
							se llogaria e tij mund të përdoret nga të tretë të paautorizuar,{" "}
							<a href="https://akademi.al/" target="_blank" rel="noopener noreferrer">
								akademi.al
							</a>
							duhet tё njoftohet menjёherё. Në përputhje me kuadrin ligjor, pёrdoruesi ka të drejtën e
							hyrjes, korrigjimit, fshirjes të të dhënave të tij personale ose kundërshtimit të përpunimit
							të tyre.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							TË DREJTAT E AUTORIT
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Kjo faqe interneti është faqja zyrtare e akademi.al. Të gjitha elementet e përdorura në këtë
							faqe, duke përfshirë elementë vizualë apo zanorë, teknologjinë bazë, janë të mbrojtura nga e
							drejta e autorit, marka tregtare ose patenta. Ato janë në pronësinë ekskluzive të kompanisë{" "}
							<a href="https://akademi.al/" target="_blank" rel="noopener noreferrer">
								akademi.al
							</a>
							. Kjo faqe përdoret sipas Kushteve të Përgjithshme që përcaktohen shprehimisht këtu.
							Pёrdoruesi ka të drejtë ta përdorë këtë faqe vetëm për përdorimin e tij personal dhe çdo
							kopjim, përdorim i paautorizuar i elementeve të përdorura në këtë faqe është i ndaluar.
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							KONTAKTI
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Nëse keni ndonjë pyetje në lidhje me Politikat e Privatësisë, ju lutemi na kontaktoni në:
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							<a href="https://akademi.al/" target="_blank" rel="noopener noreferrer">
								www.akademi.al
							</a>
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Tiranë, Rruga “Themistokli Gërmenji”, Nr.3/1
						</Text>
					</Col>
				</Row>
				<Row justify="center" className="pt-40">
					<Col>
						<Text fontSize="14" lineHeight="22">
							<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>
								{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_REGISTER`)}
							</Link>
							<Divider orientation="center" type="vertical" />
							<Link to={`/${SIGN_IN_PATH}`}>{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_SIGNIN`)}</Link>
						</Text>
					</Col>
				</Row>
			</>
		);
	};

	const hideCover = props.pageComponents?.loginCover?.status === 0;

	return (
		<>
			{hideCover ? (
				<Component />
			) : (
				<Layout {...props.pageComponents.loginCover?.specs}>
					<Component />
				</Layout>
			)}
		</>
	);
};
