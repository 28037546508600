import { Tooltip } from "antd";
import React from "react";
import LiveIcon from "assets/images/icons/marketplace-live-icon.svg";
import Image from "common/components/general/Image";

export const LiveClassIcon = () => {
	return (
		<Tooltip
			className="marketplace__card__image__live__class"
			title={"Kurs live"}
		>
			<a>
				<Image src={LiveIcon} />
			</a>
		</Tooltip>
	);
};
