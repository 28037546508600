export enum GradeLevel {
	Preschool = "PRESCHOOL",
	Elementary = "ELEMENTARY",
	Middle = "MIDDLE",
	High = "HIGH",
	Other = "OTHER"
}

export const getLevelIcon = (level: string) => {
	switch (level) {
		case GradeLevel.Preschool:
			return "ri-user-5-line";
		case GradeLevel.Elementary:
			return "ri-open-arm-line";
		case GradeLevel.Middle:
			return "ri-user-line";
		case GradeLevel.High:
			return "ri-user-4-line";
		case GradeLevel.Other:
			return "ri-t-box-line";
		default:
			return "ri-icon-undefined";
	}
};
