import React, { useEffect, useState } from "react";
import { Col, Empty, message, Row, Skeleton } from "antd";
import { AdminTable } from "../../../../../../components/table/table";
import OrganizationDto from "dtos/administration/organization.dto";
import PageService from "services/design/page.service";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Space } from "common/components/layout/Space";
import { NavLink } from "react-router-dom";
import { ADMIN_PATH } from "../../../../../../index";
import { ORGANIZATIONS_PATH } from "../../../list";
import Button from "common/components/general/Button";
import { Page } from "dtos/administration/customize/Page.dto";
import Switch from "common/components/dataEntry/components/Switch";
import Card from "common/components/dataDisplay/Card";
import Text from "common/components/general/Text";
import TemplateService from "services/design/template.service";
import { Template } from "dtos/administration/customize/Template.dto";
import { PAGE_STATUS_PUBLISHED, PAGE_STATUS_UNPUBLISHED } from "../../utils/constants";
import OrganizationPageService from "services/design/organizationPage.service";
import { v4 as uuidv4 } from "uuid";
import Icon from "common/components/general/Icon";

interface MatchParams {
	organizationId: string;
	templateId: string;
}

interface PropsInterface extends RouteComponentProps<MatchParams> {
	organizationDto: OrganizationDto;
}

export function Pages(props: PropsInterface) {
	const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._CUSTOMIZE._PAGES";
	const [loading, setLoading] = useState(false);
	const [template, setTemplate] = useState<Template | null>(null);
	const [customizedContent, setCustomizedContent] = useState<boolean>(false);
	const [pages, setPages] = useState<Page[]>([]);
	const { t: translate } = useTranslation();

	useEffect(() => {
		setLoading(true);

		function loadPages() {
			return new PageService()
				.findAll(props.match.params.organizationId, props.match.params.templateId)
				.then(res => {
					const organizationPagesNumber = res.filter(
						item => item.organizationPage && item.organizationPage.length > 0
					).length;
					setCustomizedContent(organizationPagesNumber > 0);
					return setPages(res);
				})
				.catch(err => {
					console.log(err);
					message.error("Failed getting pages");
				});
		}

		function loadTemplate() {
			return new TemplateService()
				.find(props.match.params.templateId)
				.then(res => {
					return setTemplate(res);
				})
				.catch(err => {
					console.log(err);
					message.error("Failed getting pages");
				});
		}

		Promise.all([loadTemplate(), loadPages()])
			.then(() => {
				return setLoading(false);
			})
			.catch(err => {
				console.log(err);
				message.error("Failed to load pages");
			});
	}, []);

	function updateStatus(status: boolean, record: Page) {
		// You cannot uncheck
		if (template?.required && !status) {
			message.error("At least one page must be active");
			return;
		}
		return new OrganizationPageService()
			.save({
				id: record.organizationPage?.length ? record.organizationPage[0].id : uuidv4(),
				status: status ? PAGE_STATUS_PUBLISHED : PAGE_STATUS_UNPUBLISHED,
				pageId: record.id,
				organizationId: props.match.params.organizationId
			})
			.then(res => {
				const newPages = pages.map(_ => {
					if (_.id === record.id) {
						_.organizationPage = [res];
					} else if (_.organizationPage?.length) {
						_.organizationPage[0].status = PAGE_STATUS_UNPUBLISHED;
					}

					return _;
				});
				!customizedContent && setCustomizedContent(true);

				return setPages(newPages);
			})
			.catch(err => {
				message.error("Failed changing status, please refresh the page");
				console.log(err.message);
			});
	}

	function resetPage(id: string) {
		return new OrganizationPageService()
			.reset(props.match.params.organizationId, id)
			.then(() => {
				const newPages = pages.map(_ => {
					if (_.id === id) {
						_.organizationPage = [];
					}

					return _;
				});
				setPages(newPages);
			})
			.catch(err => {
				message.error("Failed resetting, please refresh the page");
				console.log(err.message);
			});
	}

	function columnsTable() {
		return [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._STATUS`),
				dataIndex: "status",
				key: "status",
				render: (status: number, record: Page) => {
					const checkedAttr =
						record.organizationPage &&
						record.organizationPage.length > 0 &&
						record.organizationPage[0].status === PAGE_STATUS_PUBLISHED;

					return (
						<Row key="status">
							<Space>
								<Col>
									<Row>
										<Switch
											checked={checkedAttr}
											onChange={checked => updateStatus(checked, record)}
										/>
									</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._PAGENAME`),
				dataIndex: "title",
				key: "title",
				render: (text: any) => {
					return (
						<Row key="title">
							<Space>
								<Col>
									<Row>{text}</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ACTIONS`),
				dataIndex: "id",
				key: "id",
				fixed: "right",
				width: 150,
				render: (id: any, record: any) => {
					const url = `/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${props.match.params.organizationId}/customize/${props.match.params.templateId}/${id}`;
					return (
						<Row key="actions" align="middle">
							<Space>
								<NavLink to={url}>
									<Button type="primary" shape="circle" icon="ri-edit-line" />
								</NavLink>
							</Space>
							{record.organizationPage?.length > 0 && (
								<Space className="ml-4">
									<Icon
										tooltip="Warning! Data will be lost"
										type="ri-restart-line"
										className="color-green-6 mt-4 ml-4 cursor_pointer"
										fontSize="20"
										onClick={() => resetPage(id)}
									/>
								</Space>
							)}
						</Row>
					);
				}
			}
		];
	}

	return (
		<>
			<Row>
				<Col span={24}>
					<Skeleton loading={loading}>
						<Card>
							{template && (
								<Row className="mb-24" justify="space-between">
									<Col>
										{!customizedContent && (
											<Icon
												tooltip="Warning! Page has the default status."
												type="ri-information-line"
												className="color-orange-6 mt-4 mr-4"
												fontSize="16"
											/>
										)}
										<Text fontSize="20" lineHeight="22" className="color-gray-9">
											{template.title}
										</Text>
									</Col>
								</Row>
							)}
							<AdminTable
								columns={columnsTable()}
								dataSource={pages}
								locale={{
									emptyText: (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
										/>
									)
								}}
							/>
						</Card>
					</Skeleton>
				</Col>
			</Row>
		</>
	);
}
