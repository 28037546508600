import React from "react";
import { Skeleton as AntSkeleton } from "antd";
import { SkeletonProps } from "antd/lib/skeleton";

type Props = SkeletonProps;

const Skeleton = (props: Props) => {
	return <AntSkeleton {...props}></AntSkeleton>;
};

export default Skeleton;
