import React, { Component, ReactNode } from "react";
import ReactPlayer from "react-player";
import { withTranslation } from "react-i18next";
import { Skeleton, Row, Col, Divider, Space, Card, Button as AntButton } from "antd";
import moment from "moment";

import { showError,showMessage } from "common/utils/Notification";
import Tooltip from "common/components/dataDisplay/Tooltip";
import Icon from "common/components/general/Icon";
import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import { LessonItem } from "scenes/subjects/components/lessonItem/LessonItem";
import { SUBJECTS_PATH } from "scenes/subjects/index";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";
import { QUIZ_PATH } from "scenes/subjects/scenes/materialItems/scenes/quizMaterial/QuizMaterial";
import LessonService from "services/administration/lesson.service";
import { ChildContext } from "../../components/layout/Layout";
import LessonSectionDto from "dtos/administration/lessonSection.dto";
import ProgressLessonEnrollmentService from "services/administration/progressLessonEnrollment.service";
import QuizUserResponseService from "services/administration/quizUserResponse.service";
import ProgressLessonEnrollmentDto from "dtos/administration/progressLessonEnrollment.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import { getFromS3, previewFile } from "../../../../../courseBuilder/utils/FileUtils";
import { COURSE_PATH } from "scenes/course";
import { Uploader } from "common/components/uploader/Uploader";
import CommitModal from "../../components/commitModal/CommitModal";
import AssignmentUserCommitDto from "dtos/administration/assignmentUserCommit.dto";
import AssignmentUserCommitFileDto from "dtos/administration/assignmentUserCommitFile.dto";
import AssignmentUserCommitService from "services/administration/assignmentUserCommit.service";
import FileDto from "dtos/administration/file.dto";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SIGN_IN_PATH } from "../../../../../login/scenes/signIn";
import { PdfRender } from "./PdfRender";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";
import { CreateAssignmentSubmittedNotification } from "common/components/_notifications/util/createNotifications";
import { CreateAssignmentSubmittedNotificationDto } from "common/components/_notifications/dtos/createAssignmentSubmittedNotificationDto";

export const LESSON_FULL_PATH = "video/:videoId";
export const LESSON_PATH = "video";

const TRANSLATION_BASE_PATH = "_SUBJECTS._MATERIAL_ITEMS._LESSON_MATERIALS";

class LessonMaterialComponent extends Component<any, any> {
	static contextType = ChildContext;
	private _isMounted: boolean;
	
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			videoId: "",
			lessonVideo: null,
			isVideoMaterial: true,
			lessonSections: [],
			videoUrl: null,
			isChecked: false,
			correctAnswers: 0,
			questionTotal: 0,
			quizUserAnswer: null,
			assignmentUserCommit: null,
			isVirtualClass: false,
			isAssignmentSection: false,
			modalCommitVisibility: false,
			fileUploaded: [],
			isAvailableDueDate: true,
			userId: "",
			groupedBy: null,
			classroomId: "",
			timer: null,
			player: null
		};
		this._isMounted = false;
		this.getSubLessons = this.getSubLessons.bind(this);
		this.loadLesson = this.loadLesson.bind(this);
		this.nextLesson = this.nextLesson.bind(this);
		this.checkCorrectAnswers = this.checkCorrectAnswers.bind(this);
		this.openQuiz = this.openQuiz.bind(this);
		this.resetQuiz = this.resetQuiz.bind(this);
		this.completeLesson = this.completeLesson.bind(this);
		this.getAssignmentInfo = this.getAssignmentInfo.bind(this);
		this.getLeftButton = this.getLeftButton.bind(this);
		this.saveAssignment = this.saveAssignment.bind(this);
		this.handleError = this.handleError.bind(this);
		this.ref = this.ref.bind(this);
	}

	componentDidMount() {
		const {
			history,
			match: { params }
		} = this.props;
		this._isMounted = true;
		if (!params.videoId) return history.push(`${SUBJECTS_PATH}`);
		this.loadLesson();

		// Every 200 ms check the position, with an event we can't get the position when the error occurs
		setInterval(() => {
			const video: HTMLVideoElement | null = document.querySelector(".videoMaterial__react-player video");
			if (!video) {
				return;
			}
			this.setState({
				timer: video?.currentTime,
			});
		}, 200);
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.videoId !== this.state.videoId) this.loadLesson();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.videoId !== prevState.videoId) return { videoId: params.videoId };
		return null;
	}

	loadLesson = async () => {
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const isVirtualClass = queryString.get("virtualClass");
		const isAssignmentSection = queryString.get("isAssignmentSection");
		const groupedBy = this.context.groupedBy ? this.context.groupedBy : queryString.get("groupedBy");

		this._isMounted && this.context.setActualLessonId(this.state.videoId);
		this._isMounted && this.context.setBackUrl(
			isVirtualClass || isAssignmentSection
				? `/${COURSE_PATH}/_student/`
				: `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/`,
			isVirtualClass || false,
			isAssignmentSection || false,
			groupedBy
		);
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return this.props.history.push("/" + SIGN_IN_PATH);
		this._isMounted && this.setState({ loading: true, userId: currentUserInfo.UserId });
		const lessonVideo: any = this._isMounted && await new LessonService().getLessonById(this.state.videoId);
		if (!lessonVideo)
			return this.props.history.push(
				isVirtualClass || isAssignmentSection ? `/${COURSE_PATH}` : `/${SUBJECTS_PATH}`
			);

		let videoUrl = lessonVideo.videoUrl;
		if (lessonVideo.File) {
			const fileUrl = await previewFile(lessonVideo.File);
			videoUrl = fileUrl.url;
		}
		const isVideoMaterial = lessonVideo?.lessonType === "VIDEO" ?? true;
		const isPdfMaterial = lessonVideo?.lessonType === "PDF" ?? false;
		const subjectPlanTrees = lessonVideo?.SubjectPlanTrees;
		const subjectPlan = subjectPlanTrees && subjectPlanTrees.length > 0 && subjectPlanTrees[0].SubjectPlan;
		const classroom = subjectPlan && subjectPlan.Classroom;
		this._isMounted && classroom && this.context.setCourse(classroom.id);
		this._isMounted && classroom && this.context.setSelectedTag(groupedBy, classroom.id);
		this._isMounted && this.setState(
			{
				groupedBy: groupedBy,
				lessonVideo: lessonVideo,
				lessonType: lessonVideo?.lessonType,
				isVideoMaterial: isVideoMaterial,
				isPdfMaterial: isPdfMaterial,
				lessonSections: lessonVideo?.LessonSections,
				videoUrl: videoUrl,
				isVirtualClass: isVirtualClass,
				isAssignmentSection: isAssignmentSection,
				isChecked: false,
				classroomId: classroom.id
			},
			() => {
				this.state.lessonType === "QUIZ" && this._isMounted && this.checkQuizAnswers(lessonVideo);
				this.state.lessonType === "ASSIGNMENT" && this._isMounted && this.getAssignmentUserCommit(lessonVideo);
				return this._isMounted && this.setState({ loading: false });
			}
		);
	};

	checkQuizAnswers = async (lessonVideo: any) => {
		if (this.state.isVideoMaterial) return this.setState({ loading: false });
		const quizUserAnswer = await new QuizUserResponseService().getQuizUserResponseByUserIdLessonId(
			this.state.videoId
		);
		if (!quizUserAnswer) return this.setState({ loading: false });
		const answers =
			quizUserAnswer && quizUserAnswer.answerMetaInfo && Array.isArray(quizUserAnswer.answerMetaInfo)
				? quizUserAnswer.answerMetaInfo
				: quizUserAnswer && quizUserAnswer.answerMetaInfo && JSON.parse(quizUserAnswer.answerMetaInfo);

		const questions =
			lessonVideo && lessonVideo.lessonMetaInfo && Array.isArray(lessonVideo.lessonMetaInfo)
				? lessonVideo.lessonMetaInfo
				: lessonVideo && lessonVideo.lessonMetaInfo && JSON.parse(lessonVideo.lessonMetaInfo);

		const correctAnswers = (questions && answers && this.checkCorrectAnswers(questions, answers)) || 0;
		return this.setState({
			loading: false,
			isChecked: true,
			correctAnswers: correctAnswers,
			quizUserAnswer: quizUserAnswer,
			questionTotal: (questions && questions.length) || 0
		});
	};

	checkCorrectAnswers = (questions: any, answers: any) => {
		let correctAnswers = 0;
		questions.length > 0 &&
			questions.map((question: any) => {
				const questionResponse = answers.find((answer: any) => answer.questionId === question.id);
				if (questionResponse) {
					if (question.questionType === "singleChoice") {
						if (
							questionResponse.answers &&
							questionResponse.answers.length > 0 &&
							question.correctAnswerId === questionResponse.answers[0].answerId
						)
							return correctAnswers++;
					} else {
						const correctAnswerInQuestion: any[] =
							question.answers.filter((answer: any) => answer.correct === true) || [];
						const userAnswer: any[] =
							(questionResponse.answers.length > 0 &&
								questionResponse.answers.filter(
									(answer: any) =>
										correctAnswerInQuestion.findIndex(
											correctAnswer => correctAnswer.id === answer.answerId
										) > -1
								)) ||
							[];
						if (
							correctAnswerInQuestion.length === userAnswer.length &&
							correctAnswerInQuestion.length ===
								(questionResponse.answers && questionResponse.answers.length)
						)
							return correctAnswers++;
					}
				}
			});
		return correctAnswers;
	};

	getSubLessons = (lessonSection: LessonSectionDto, key: number): ReactNode => {
		return (
			<Row key={key}>
				{lessonSection && !lessonSection.File ? (
					<AntButton type="link" href={lessonSection.url} rel="noopener noreferrer" target="_blank">
						<Space direction="horizontal" size={24}>
							<Icon type="ri-link-m" className="color-gray-6" />
							<Text fontSize="14" lineHeight="22" className="color-blue-6">
								{lessonSection.name}
							</Text>
						</Space>
					</AntButton>
				) : (
					<AntButton
						type="link"
						onClick={async () => {
							if (lessonSection.File) {
								const urlObj = await previewFile(lessonSection.File);
								const url = urlObj.url;
								window.open(url, "_blank");
							}
						}}
						rel="noopener noreferrer"
						target="_blank"
					>
						<Space direction="horizontal" size={24}>
							<Icon type="ri-file-text-line" className="color-gray-6" />
							<Text fontSize="14" lineHeight="22" className="color-blue-6">
								{lessonSection.name}
							</Text>
						</Space>
					</AntButton>
				)}
			</Row>
		);
	};

	getAssignmentUserCommit = async (lessonVideo: any) => {
		const assignmentUserCommit: AssignmentUserCommitDto = await new AssignmentUserCommitService().getAssignmentUserCommitByUserIdLessonId(
			this.state.userId,
			lessonVideo.id
		);

		const fileUploaded =
			(assignmentUserCommit &&
				assignmentUserCommit.AssignmentUserCommitFiles &&
				assignmentUserCommit.AssignmentUserCommitFiles.length > 0 &&
				(await Promise.all(
					assignmentUserCommit.AssignmentUserCommitFiles.map(async commitFile => {
						return commitFile.File && (await previewFile(commitFile.File));
					})
				))) ||
			[];

		return this.setState({
			assignmentUserCommit: assignmentUserCommit,
			fileUploaded: fileUploaded,
			isAvailableDueDate: this.checkIsAvailable()
		});
	};

	toggleCommitModal = (state: boolean) => {
		const { t: translate } = this.props;
		const {assignmentUserCommit} = this.state
		if(assignmentUserCommit?.AssignmentUserCommitFiles?.length > 0){
			this.setState({ modalCommitVisibility: state });
		}
		else {
			return showMessage(translate(`${TRANSLATION_BASE_PATH}._ASSIGNMENT_REQUIRED`))
		}
	};

	commitAssignmentModal = async (description: string) => {
		const { t: translate } = this.props;
		const {assignmentUserCommit} = this.state
		if(!assignmentUserCommit?.AssignmentUserCommitFiles?.length){
			return showMessage(translate(`${TRANSLATION_BASE_PATH}._ASSIGNMENT_REQUIRED`))
		}	
		const newAssignmentUserCommit = await this.saveAssignment(true, null, description);
		return this.setState(
			{
				assignmentUserCommit: newAssignmentUserCommit ? newAssignmentUserCommit : null,
				modalCommitVisibility: false
			},
			() => this.state.isAvailableDueDate && this.completeLesson()
		);
	};

	addFileToFileList = (fileUploaded: any) => {
		this.setState({ fileUploaded: fileUploaded });
	};

	onUploadingDone = async (fileCreated: any) => {
		const assignmentFile = new AssignmentUserCommitFileDto({
			File: new FileDto({ ...fileCreated, createdBy: this.state.userId, updatedBy: this.state.userId })
		});

		const newAssignmentUserCommit = await this.saveAssignment(false, assignmentFile);
		return this.setState({ assignmentUserCommit: newAssignmentUserCommit ? newAssignmentUserCommit : null });
	};

	onRemoveUpload = async (fileToDelete: any) => {
		const assignmentUserCommitFiles =
			this.state.assignmentUserCommit && this.state.assignmentUserCommit.AssignmentUserCommitFiles
				? [...this.state.assignmentUserCommit.AssignmentUserCommitFiles]
				: [];
		const index = assignmentUserCommitFiles.findIndex((files: any) => files.File.name === fileToDelete.name);
		if (index > -1) assignmentUserCommitFiles.splice(index, 1);

		return this.setState(
			{
				assignmentUserCommit: {
					...this.state.assignmentUserCommit,
					AssignmentUserCommitFiles: assignmentUserCommitFiles
				}
			},
			() => this.saveAssignment(false)
		);
	};

	saveAssignment = async (isCommitted: boolean, newFile?: any, description?: string) => {
		const { t: translate } = this.props;
		const newFileList =
			(this.state.assignmentUserCommit &&
				this.state.assignmentUserCommit.AssignmentUserCommitFiles &&
				this.state.assignmentUserCommit.AssignmentUserCommitFiles.map((commitFiles: any) => {
					delete commitFiles.File.createdAt;
					delete commitFiles.File.updatedAt;
					delete commitFiles.File.deletedAt;
					delete commitFiles.File.id;
					return new AssignmentUserCommitFileDto({
						File: new FileDto({
							...commitFiles.File,
							createdBy: this.state.userId,
							updatedBy: this.state.userId
						})
					});
				})) ||
			[];

		const filesConcat = newFileList && newFile ? newFileList.concat(newFile) : newFileList;
		const assignmentUserCommit = new AssignmentUserCommitDto({
			UserId: this.state.userId,
			description: description || "",
			isCommitted: isCommitted,
			isEvaluated: false,
			LessonId: this.state.lessonVideo.id,
			AssignmentUserCommitFiles: filesConcat ? filesConcat : [newFile]
		});
		try{
			const newAssignmentUserCommit = await new AssignmentUserCommitService().createAssignmentUserCommit(
				assignmentUserCommit
			);
		
			if (!newAssignmentUserCommit) {
				showError(translate(`${TRANSLATION_BASE_PATH}._DUE_DATE_FINISHED`));
				return this.setState({ isAvailableDueDate: false });
			} 
			if (isCommitted) {
				CreateAssignmentSubmittedNotification(
					new CreateAssignmentSubmittedNotificationDto(
						this.state.classroomId,
						this.state.lessonVideo.name,
						newAssignmentUserCommit.LessonId,
						OAuth2Service.CurrentUser?.User
					)
				);
			}

			return newAssignmentUserCommit;
		}catch(error){
			return showMessage(translate(`${TRANSLATION_BASE_PATH}._ASSIGNMENT_REQUIRED`))
		}
	};

	nextLesson = (completedLesson: boolean) => {
		const nextLesson =
			this.state.lessonType !== "ASSIGNMENT" || !completedLesson
				? this.context.nextLesson()
				: this.state.lessonVideo?.id;
		const virtualClass = this.state.isVirtualClass || this.state.isAssignmentSection ? "?virtualClass=true" : "";
		const groupBy = (this.state.isVirtualClass || this.state.isAssignmentSection) ?
			((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `&groupedBy=${this.state.groupedBy}`) :
			((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `?groupedBy=${this.state.groupedBy}`);

		if (this.state.lessonVideo && nextLesson && nextLesson === this.state.lessonVideo.id)
			return this.props.history.push(this.props.location);
		return this.props.history.push(
			`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${nextLesson}${virtualClass}${groupBy}`
		);
	};

	completeLesson = async () => {
		return (
			this.context.enrollmentId !== "" &&
			(await new ProgressLessonEnrollmentService()
				.createProgressLessonEnrollment(
					new ProgressLessonEnrollmentDto({
						LessonId: this.state.videoId,
						EnrollmentId: this.context.enrollmentId,
						createdBy: this.state.userId,
						updatedBy: this.state.userId
					})
				)
				.then(() => {
					return this.nextLesson(true);
				}))
		);
	};

	openQuiz = () => {
		const virtualClass = this.state.isVirtualClass || this.state.isAssignmentSection ? "?virtualClass=true" : "";
		const groupBy = (this.state.isVirtualClass || this.state.isAssignmentSection) ?
			((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `&groupedBy=${this.state.groupedBy}`) :
			((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `?groupedBy=${this.state.groupedBy}`);
		return this.props.history.push(
			`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${QUIZ_PATH}/${this.state.videoId}${virtualClass}${groupBy}`
		);
	};

	commitAssignment = () => {
		if (this.checkIsAvailable()) return this.toggleCommitModal(true);
		return this.setState({ isAvailableDueDate: false });
	};

	checkIsAvailable = () => {
		const today = moment();
		const dueDate = moment(this.state.lessonVideo?.endDate);
		const difference = today.diff(dueDate, "minutes");
		return difference <= 0;
	};

	resetQuiz = () => {
		return new QuizUserResponseService().deleteQuizUserResponse(this.state.quizUserAnswer.id).then(() => {
			const virtualClass =
				this.state.isVirtualClass || this.state.isAssignmentSection ? "?virtualClass=true" : "";
			const groupBy = (this.state.isVirtualClass || this.state.isAssignmentSection) ?
				((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `&groupedBy=${this.state.groupedBy}`) :
				((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `?groupedBy=${this.state.groupedBy}`);
			return (
				!this.state.isVideoMaterial &&
				this.props.history.push(
					`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${QUIZ_PATH}/${this.state.videoId}${virtualClass}${groupBy}`
				)
			);
		});
	};

	getQuizResult = (): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Space className="full__width" direction="vertical">
				<Row>
					<Col span={24}>
						<Space direction="horizontal" size={8}>
							<Icon type="ri-trophy-line" />
							<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-7"}>
								{translate(`${TRANSLATION_BASE_PATH}._RESULT`)}
							</Text>
						</Space>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Text fontSize={"14"} lineHeight={"22"} className={"color-gray-9"}>
							{translate(`${TRANSLATION_BASE_PATH}._FINAL_TEST_RESPONSE`, {
								success: this.state.correctAnswers,
								outOf: this.state.questionTotal
							})}
						</Text>
					</Col>
				</Row>
				<Divider />
			</Space>
		);
	};

	getMeetingInfo = (): ReactNode => {
		const { t: translate } = this.props;
		const meetingInfo = JSON.parse(this.state.lessonVideo?.lessonMetaInfo);
		return (
			<Row>
				<Col span={24}>
					<Space direction="vertical">
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							<Icon type="ri-time-line" /> {translate(`${TRANSLATION_BASE_PATH}._START_TIME`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{moment(meetingInfo?.startTime).format("DD.MM.YYYY HH:mm")} -{" "}
							{moment(meetingInfo?.startTime)
								.add(meetingInfo?.duration, "minutes")
								.format("HH:mm")}
						</Text>
					</Space>
				</Col>
			</Row>
		);
	};

	getDueDateComponent = () => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					<Space direction="vertical">
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							<Icon type="ri-timer-line" /> {translate(`${TRANSLATION_BASE_PATH}._END_DATE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{moment(this.state.lessonVideo?.endDate).format("DD.MM.YYYY HH:mm")}
						</Text>
					</Space>
				</Col>
			</Row>
		);
	};

	getAssignmentInfo = (): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Space direction="vertical" size={24} className="full__width">
				{this.getDueDateComponent()}
				{this.state.isAvailableDueDate && (
					<Row>
						<Col span={24}>
							<Uploader
								fileType={"OTHER"}
								filePath={`lesson/${this.state.lessonVideo.id}/student/${this.state.userId}/assignment`}
								fileWithUniqueId={false}
								addFileToFileList={this.addFileToFileList}
								onUploadingDone={this.onUploadingDone}
								onRemoveUpload={(fileToDelete: any) => this.onRemoveUpload(fileToDelete)}
								showUploadList={true}
								uploaderType={"DRAGGER"}
								className="lessonMaterial__uploader"
								defaultFileList={this.state.fileUploaded}
								multiple={true}
								showRemoveIcon={
									!(this.state.assignmentUserCommit && this.state.assignmentUserCommit.isCommitted)
								}
							>
								<Space direction="vertical" size={8}>
									<Icon
										type="ri-file-add-line"
										className="lessonMaterial__uploader lessonMaterial__uploader-icon"
									/>
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._UPLOAD_ASSIGNMENT`)}
									</Text>
									<Text fontSize="14" lineHeight="22" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._UPLOAD_ASSIGNMENT_DESCRIPTION`)}
									</Text>
								</Space>
							</Uploader>
						</Col>
					</Row>
				)}
			</Space>
		);
	};

	getMeetingJoinButton = (): ReactNode => {
		const { t: translate } = this.props;
		const meetingInfo = JSON.parse(this.state.lessonVideo?.lessonMetaInfo);
		return (
			<Button
				type="primary"
				onClick={() => {
					window.open(meetingInfo?.joinUrl, "_blank");
				}}
			>
				{translate(`${TRANSLATION_BASE_PATH}._OPEN_ZOOM`)}
			</Button>
		);
	};

	getLeftButton = (icon: string, onClick: any, description: string, disabled?: any) => {
		const { t: translate } = this.props;
		return (
			<Button
				icon={icon}
				htmlType="button"
				type="primary"
				onClick={() => onClick()}
				disabled={disabled || false}
				className="lessonMaterial__button__fullWidth"
			>
				{translate(`${TRANSLATION_BASE_PATH}.${description}`)}
			</Button>
		);
	};

	getAssignmentNotCommittedForm = () => {
		const { t: translate } = this.props;
		return (
			<>
				<Row gutter={[0, 24]} className="mt-16">
					<Col span={24}>{this.getAssignmentInfo()}</Col>
				</Row>
				{this.state.isAvailableDueDate && (
					<Row justify="center" className="full__width">
						<Col span={24}>
							<Row justify="center">
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._COMMIT_INSTRUCTIONS`)}
								</Text>
							</Row>
						</Col>
					</Row>
				)}
			</>
		);
	};

	getAssignmentCommittedForm = () => {
		const { t: translate } = this.props;
		return (
			this.state.assignmentUserCommit && (
				<>
					<Row gutter={[0, 24]} className="mt-16">
						<Col span={24}>{this.getDueDateComponent()}</Col>
					</Row>
					<Row gutter={[0, 24]}>
						<Col span={24}>
							<Card
								size="small"
								title={translate(`${TRANSLATION_BASE_PATH}._COMITTED_ASSIGNMENT`)}
								className="mt-16 full__width"
							>
								<Card.Grid className="full__width" hoverable={false}>
									<Row gutter={[0, 16]}>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Row gutter={[0, 8]}>
												<Col span={24}>
													<Text fontSize="14" lineHeight="22" className="color-gray-7">
														{translate(`${TRANSLATION_BASE_PATH}._NOTE`)}
													</Text>
												</Col>
											</Row>
											<Row gutter={[0, 8]}>
												<Col span={24}>
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{this.state.assignmentUserCommit.description}
													</Text>
												</Col>
											</Row>
										</Col>
										<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
											<Row gutter={[0, 8]}>
												<Col span={24}>
													<Text fontSize="14" lineHeight="22" className="color-gray-7">
														{translate(`${TRANSLATION_BASE_PATH}._UPLOADED_DOCS`)}
													</Text>
												</Col>
											</Row>
											<Row gutter={[0, 8]}>
												<Col span={24}>
													<Uploader
														key={new Date().toISOString()}
														fileType={"OTHER"}
														multiple={true}
														showUploadList={true}
														defaultFileList={this.state.fileUploaded}
														className="lessonMaterial__modal_commit"
														showRemoveIcon={false}
													>
														<Row />
													</Uploader>
												</Col>
											</Row>
										</Col>
									</Row>
								</Card.Grid>
								{this.state.assignmentUserCommit.isEvaluated && (
									<Card.Grid className="full__width" hoverable={false}>
										<Row className="full__width pt-0 pb-0 mt-0 mb-0">
											<Col span={24}>
												<Space direction="horizontal">
													<Icon
														type="ri-shield-star-line"
														className="color-blue-6"
														fontSize="24"
													/>
													<Row>
														<Col>
															<Row className="full__width">
																<Text
																	fontSize="24"
																	lineHeight="32"
																	className="color-gray-8"
																>
																	{this.state.assignmentUserCommit.grade}
																</Text>
															</Row>
															<Row className="full__width">
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-7"
																>
																	{translate(`${TRANSLATION_BASE_PATH}._EVALUATION`)}
																</Text>
															</Row>
														</Col>
													</Row>
												</Space>
											</Col>
										</Row>
									</Card.Grid>
								)}
							</Card>
						</Col>
					</Row>
				</>
			)
		);
	};

	getAssignmentButton = (isAvailableDueDate: boolean) => {
		const { t: translate } = this.props;
		if (isAvailableDueDate)
			return this.getLeftButton(
				"ri-send-plane-line",
				this.commitAssignment,
				"_COMITT_ASSIGNMENT",
				!isAvailableDueDate
			);
		return (
			<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._TOOLTIP_INFO`)} placement="bottomLeft">
				{this.getLeftButton(
					"ri-send-plane-line",
					this.commitAssignment,
					"_COMITT_ASSIGNMENT",
					!isAvailableDueDate
				)}
			</Tooltip>
		);
	};

	handleError = async () => {
		const lastTime = this.state.timer;
		const url = await getFromS3(this.state.lessonVideo.File);
		const video: HTMLVideoElement | null = document.querySelector(".videoMaterial__react-player video");

		video?.setAttribute("src", url);
		video?.play().then(() => {
			this.state.player.seekTo(lastTime);
		});
	}

	ref = (player: any) => {
		this.setState({
			player: player
		});
	}

	render() {
		const { t: translate } = this.props;

		return (
			<Skeleton active loading={this.state.loading}>
				{this.state.lessonVideo && (
					<Row>
						<Col span={24}>
							<Row gutter={[0, 16]}>
								<Col span={24}>
									<LessonItem
										id={this.state.lessonVideo.id}
										name={this.state.lessonVideo.name}
										lessonType={this.state.lessonVideo.lessonType}
										canBeModified={false}
									/>
								</Col>
							</Row>
							{this.state.isVideoMaterial && (
								<Row gutter={[0, 16]}>
									<Col span={24}>
										<div className="videoMaterial__scene videoMaterial__react-player-wrapper">
											<ReactPlayer
												ref={this.ref}
												onError={this.handleError}
												className="videoMaterial__react-player"
												width="100%"
												height="100%"
												url={this.state.videoUrl}
												controls={true}
												config={{
													file: {
														attributes: {
															controlslist: "nodownload",
														}
													}
												}}
												onContextMenu={(e: any) => e.preventDefault()}
											/>
										</div>
									</Col>
								</Row>
							)}
							{this.state.isPdfMaterial && (
								<PdfRender
									filePath={
										this.state.lessonVideo?.File.filePath + "/" + this.state.lessonVideo?.File.name
									}
									fileName={this.state.lessonVideo?.name + ".pdf"}
								/>
							)}
							<Row gutter={[0, 16]}>
								<Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
									<Row gutter={[0, 8]}>
										<Col span={24}>
											<Text fontSize="14" lineHeight="22" className="color-gray-7">
												{translate(`${TRANSLATION_BASE_PATH}._VIDEO_DESCRIPTION`)}
											</Text>
										</Col>
									</Row>
									<Row gutter={[0, 8]}>
										<Col span={24}>
											<HtmlInput
												name="description"
												readOnly={true}
												value={this.state.lessonVideo.description}
											/>
										</Col>
									</Row>
									{this.state.lessonType === "ASSIGNMENT" &&
										!(
											this.state.assignmentUserCommit &&
											this.state.assignmentUserCommit.isCommitted
										) &&
										this.getAssignmentNotCommittedForm()}
								</Col>
								<Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
									<Row gutter={[0, 8]}>
										<Col span={24}>
											<Text fontSize="14" lineHeight="22" className="color-gray-7">
												{translate(`${TRANSLATION_BASE_PATH}._VIDEO_MATERIALS`)}
											</Text>
										</Col>
									</Row>
									<Row gutter={[0, 8]}>
										<Col span={24}>
											<Space direction="vertical" size={8}>
												{this.state.lessonVideo &&
													this.state.lessonSections.map(
														(lessonSection: LessonSectionDto, i: number) =>
															this.getSubLessons(lessonSection, i)
													)}
											</Space>
										</Col>
									</Row>
								</Col>
							</Row>
							{this.state.lessonType === "MEETING" && this.getMeetingInfo()}
							{this.state.lessonType === "ASSIGNMENT" &&
								this.state.assignmentUserCommit &&
								this.state.assignmentUserCommit.isCommitted &&
								this.getAssignmentCommittedForm()}
							<Divider />
							{this.state.isChecked && this.state.lessonType === "QUIZ" && this.getQuizResult()}
							<Row gutter={[0, 16]}>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
									<Row justify="start">
										{this.state.lessonType === "MEETING" && this.getMeetingJoinButton()}
										{this.state.lessonType === "ASSIGNMENT" &&
											!(
												this.state.assignmentUserCommit &&
												this.state.assignmentUserCommit.isCommitted
											) &&
											this.getAssignmentButton(this.state.isAvailableDueDate)}
										{(this.state.lessonType === "VIDEO" || this.state.lessonType === "PDF") &&
											this.getLeftButton("ri-check-line", this.completeLesson, "_VIDEO_FINISHED")}
										{this.state.lessonType === "QUIZ" &&
											!this.state.isChecked &&
											this.getLeftButton("ri-check-line", this.openQuiz, "_START_QUIZ")}
										{this.state.lessonType === "QUIZ" &&
											this.state.isChecked &&
											this.getLeftButton("ri-refresh-line", this.resetQuiz, "_RESET_QUIZ")}
									</Row>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
									<Row justify="end">
										<Button
											icon="ri-arrow-right-line"
											htmlType="button"
											type="default"
											iconPosition="right"
											onClick={() => this.nextLesson(false)}
											className="lessonMaterial__button__fullWidth"
										>
											{translate(`${TRANSLATION_BASE_PATH}._NEXT_MATERIAL`)}
										</Button>
									</Row>
								</Col>
							</Row>
							<CommitModal
								modalVisibility={this.state.modalCommitVisibility}
								closeModal={() => this.toggleCommitModal(false)}
								okModal={(description: string) => this.commitAssignmentModal(description)}
								lessonName={this.state.lessonVideo.name}
								fileUploaded={this.state.fileUploaded}
							/>
						</Col>
					</Row>
				)}
			</Skeleton>
		);
	}
}

export const LessonMaterial = withTranslation()(LessonMaterialComponent);
