import React, { ReactNode } from "react";
import { Layout, Skeleton } from "antd";

import { MainHeader as Header } from "common/components/layout/header";
import { LeftSideBar } from "common/components/_leftSideBar/LeftSideBar";
import { COURSES_PATH } from "../../scenes/courses/TeacherCourses";
import { CALENDAR_PATH } from "../../scenes/calendar";
import { CHANGE_PASSWORD_PATH, SETTINGS_PATH, USER_PROFILE_PATH } from "../../scenes/settings";
import { SidebarOrganizationInfo } from "../sidebarOrganizationInfo";

const { Content } = Layout;

interface LayoutProps {
	children: ReactNode;
	showMarketPlace?: boolean;
	showAddCourse?: boolean;
	loading?: boolean;
}

export const MainDashboardMenuItems = [{
	name: "_MAIN._LEFT_SIDE_MENU._ITEM_1",
	path: "/main/dashboard",
	icon: "ri-home-3-line",
	key: "1"
},
	{
		name: "_MAIN._LEFT_SIDE_MENU._ITEM_2",
		path: "/main/" + COURSES_PATH,
		icon: "ri-function-line",
		key: "2"
	},
	{
		name: "_MAIN._LEFT_SIDE_MENU._ITEM_3",
		path: "/main/" + CALENDAR_PATH,
		icon: "ri-calendar-2-line",
		key: "3"
	},
	{
		name: "_MAIN._LEFT_SIDE_MENU._ITEM_5",
		path: "/main/" + SETTINGS_PATH,
		icon: "ri-settings-4-line",
		key: "4",
		children: [{
			name: "_MAIN._LEFT_SIDE_MENU._MY_PROFILE",
			path: `/main/${SETTINGS_PATH}/${USER_PROFILE_PATH}`,
			icon: "",
			key: "4-1"
		},
			{
				name: "_MAIN._LEFT_SIDE_MENU._CHANGE_PASSWORD",
				path: `/main/${SETTINGS_PATH}/${CHANGE_PASSWORD_PATH}`,
				icon: "",
				key: "4-2"
			}]
	}];

export const MainLayout = (props: LayoutProps) => {
	const menuItems = MainDashboardMenuItems;

	return (
		<Layout className="main__dashboard_layout">
			{!props.loading && <Header menu={menuItems} showSwitchRole={true} showMarketplace={props.showMarketPlace} />}
			<Layout className="full__min_height">
				<LeftSideBar loading={props.loading} showAddCourse={props.showAddCourse} menu={menuItems} footer={<SidebarOrganizationInfo />} />
				<Content className="main__dashboard_content">
					{props.loading ? <Skeleton className="mt-56"/> : props.children}
				</Content>
			</Layout>
		</Layout>
	);
};
