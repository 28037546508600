import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Modal } from "antd";
import Button from "common/components/general/Button";
import Steps from "common/components/navigation/Steps";
import { DigitalizeStudentDto } from "dtos/administration/digitalizeStudent/digitalizeStudent.dto";
import DigitalizeEmailService from "services/email/digitalize.email.service";
import { Reason } from "./components/reason";
import { Personal } from "./components/personal";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT._DATA_COLLECTOR";
const TRANSLATION_BASE_PATH_STEPS = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT._DATA_COLLECTOR._STEPS";

type Props = {
	onCancel: () => void,
	updateData: (data: object) => void,
	collectedData: DigitalizeStudentDto,
	next: () => void,
}

export const DataCollector = (props: Props) => {
	const { t: translate } = useTranslation();
	const { onCancel, collectedData, updateData, next } = props;
	const { reason, personal } = collectedData;
	const [step, setStep] = useState(0);
	const [isNextActive, setIsNextActive] = useState(false);
	const [sendLoading, setSendLoading] = useState(false);
	const modalTitle = translate(`${TRANSLATION_BASE_PATH}._TITLE`);
	const onNext = () => {
		setStep(step + 1);
		setIsNextActive(false);
	};
	const onSend = async () => {
		setSendLoading(true);
		await new DigitalizeEmailService()
			.sendDigitalizeEmail(
				reason,
				personal
			)
			.then(() => {
				setSendLoading(false);
				next();
				return;
			})
			.catch(error => {
				setSendLoading(false);
				console.log(error); 
			});
	};
	const steps = [{ title: translate(`${TRANSLATION_BASE_PATH_STEPS}._REASON`), disabled: true},
		{ title: translate(`${TRANSLATION_BASE_PATH_STEPS}._PERSONAL_DATA`), disabled: true }];
	const loadComponent = () => {
		let component = null;
		switch (step) {
			case 0:
				component = <Reason updateData={updateData} setIsNextActive={setIsNextActive} />;
				break;
			case 1:
				component = <Personal updateData={updateData} setIsNextActive={setIsNextActive} reason={reason} />;
				break;
			default:
				break;
		}
		return component;
	};
	return (
		<>
			<Modal
				title={modalTitle}
				maskClosable={false}
				visible
				centered
				onCancel={onCancel}
				bodyStyle={{ paddingBottom: 0}}
				style={{ maxWidth: "470px" }}
				width="100%"
				footer={(
					<Row justify="end">
						<Row>
							<Button
								onClick={() => onCancel()}
							>
								{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
							</Button>
							{step === 1 ? (
								<Button
									onClick={() => onSend()}
									disabled={!isNextActive}
									type="primary"
									className="ml-4"
									loading={sendLoading}
								>
									{translate(`${TRANSLATION_BASE_PATH}._SEND`)}
								</Button>
							) : (
								<Button
									onClick={() => onNext()}
									disabled={!isNextActive}
									type="primary"
									className="ml-4"
								>
									{translate(`${TRANSLATION_BASE_PATH}._NEXT`)}
								</Button>
							)}
						</Row>
					</Row>
				)}
			>
				<Steps current={step} size="small" steps={steps} />
				<Row className="mt-16">{loadComponent()}</Row>
			</Modal>
		</>
	);
};