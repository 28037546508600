import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Formik } from "formik";
import { Alert, Col, Radio,Space } from "antd";

import _ from "lodash";

import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import FormikDatePickerField from "common/components/dataEntry/formik/FormikDatePickerField";
import { TeacherCopyContentList } from "common/components/lessonList/TeacherCopyContentList";
import { formValidator } from "./ValidationSchema";
import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import LessonDto from "dtos/administration/lesson.dto";
import {
	CreateSubjectPlanTreeDto,
	Lesson,
	SubjectPlanLesson,
	LessonSection,
	Quiz
} from "dtos/course-builder/subjectPlanTree.dto.ts/createSubjectPlanTree.dto";
import Button from "common/components/general/Button";
import SubjectPlanTreeDto from "dtos/administration/subjectPlanTree.dto";
import { showMessage } from "common/utils/Notification";
import OAuth2Service from "services/authentication/oauth2.service";
import File from "dtos/administration/file.dto";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import TagService from "services/administration/tag.services";
import ClassroomTagDto from "dtos/administration/classroomTag.dto";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._SUBJECT_PLAN_CREATOR";

class ClassCloneDrawerComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			rootLessonTags: [],
			lessonTagGroup: [],
			tabActiceKey: "0",
			classContentShown: false,
			classContentLoaded: false,
			formInitialValues: {
				startDate: null
			},
			selectedDateHumanized: null,
			submitting: false,
			displayCheckBOxes: false,
			readioValue: 2,
			rootLessonTagsFilterRadio:[]
		};
	}

	submitForm(form: any) {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const date = form.startDate;
		const copyFromSubjectPlanId = this.props.fromClassroom?.SubjectPlan?.id;
		const copyToSubjectPlanId = this.props.toClassroom.SubjectPlan.id;
		const userId = currentUserInfo ? currentUserInfo.UserId : "";
		const { t: translate } = this.props;

		const selectedImportType = this.state.rootLessonTags[this.state.readioValue]?.id

		let lessonsToCopy: any = []
		if(selectedImportType){
			this.state.lessonTagGroup?.forEach((item:any) =>{
				const {ClassroomTags} = item;
				if(ClassroomTags){
					ClassroomTags?.forEach((lesson:any) =>{
						lesson.LessonClassroomTag?.forEach((i:any)=>lessonsToCopy.push(i.LessonId))
					})
				}
			})
		}
		if (!copyFromSubjectPlanId) return;
		this.setState({ submitting: true });
		let newLessons: Lesson[] = [];
		return new SubjectPlanTreeService()
			.getTree(copyFromSubjectPlanId)
			.then((result: any) => {

				const records: CreateSubjectPlanTreeDto[] = [];
				result.data.map((item: any) => {
					const lessons: Lesson[] = [];
					item.Lessons.map((lesson: any) => {
						const lessonsSection =
							(lesson &&
								lesson.LessonSections &&
								lesson.LessonSections.map((lessonSection: any) => {
									if (lessonSection.File) delete lessonSection.File.id;
									const newFile = new File({
										...lessonSection.File,
										createdBy: userId,
										updatedBy: userId
									});
									return new LessonSection({
										name: lessonSection.name,
										description: lessonSection.description,
										url: lessonSection.url,
										createdBy: userId,
										updatedBy: userId,
										File: newFile
									});
								})) ||
							[];

						const quiz =
							(lesson.Quiz &&
								new Quiz({
									lessonMetaInfo: lesson.Quiz.lessonMetaInfo,
									createdBy: userId,
									updatedBy: userId
								})) ||
							null;
						
						const lessonWeek = (lesson.Week && lesson.Week.priority) || 0;
						const lessonToAdd = new Lesson({
							name: lesson.name,
							description: lesson.description,
							startDate: lesson.startDate,
							endDate: this.props.toClassroom.isAsync ? undefined : moment(date)
								.add(7 * (lessonWeek - 1), "days")
								.toDate(),
							videoUrl: lesson.videoUrl,
							lessonType: lesson.lessonType,
							LessonSections: lessonsSection,
							createdBy: userId,
							updatedBy: userId,
							WeekId: lesson.WeekId,
							Quiz: quiz,
							CopiedFromLessonId: lesson.id
						})
						if(lessonsToCopy.length && lessonsToCopy.length > 0){
							if(lessonsToCopy.includes(lesson.id.toString())){
								lessons.push(lessonToAdd);
							}
						}else{
							lessons.push(lessonToAdd);
						}
						
					});

					const subjectPlanLessons = lessons.map(lesson => {
						return new SubjectPlanLesson({ Lesson: lesson, createdBy: userId, updatedBy: userId });
					});

					newLessons = newLessons.concat(lessons);
					records.push(
						new CreateSubjectPlanTreeDto({
							SubjectPlanId: copyToSubjectPlanId,
							name: item.name,
							description: item.description,
							left: item.left,
							right: item.right,
							level: item.level,
							createdBy: userId,
							updatedBy: userId,
							SubjectPlanLessons: subjectPlanLessons
						})
					);
				});
				
				return new SubjectPlanTreeService().createTree(records, copyToSubjectPlanId);
			})
			.then(() => {
				return this.createGroupListForTheNewClassroom(newLessons,selectedImportType);
			})
			.then((groupList: any) => {
				return new TagService().creategroupClassroomTag(groupList);
			})
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._CLONE_COMPLETE`));
				return this.props.reload();
			})
			.finally(() => {
				this.setState({ submitting: false });
				this.props.hideDrawer();
			});
	}

	createGroupListForTheNewClassroom = async (lessons: any,selectedImportType:any) => {
		let {rootLessonTags} = this.state;
		if(selectedImportType){
			rootLessonTags = rootLessonTags.filter((tag: any) => tag.id === selectedImportType)
		}
		return Promise.all(
			rootLessonTags.map((tag: any) => {
				return new TagService().getAllLessonClassroomTags(tag.id, this.props.fromClassroom?.id);
			})
		).then((groupList: any) => {
			const allTags = groupList.flat(1);
			return Promise.all(
				allTags.map((tag: any, index: number) => {
					const newGroupTree = new ClassroomTagDto({
						ClassroomId: this.props.toClassroom.id,
						TagId: tag.id,
						priority: index
					});
					const lessonsClassroomTag = tag.ClassroomTags[0].LessonClassroomTag.map(
						(lessonClassroomTag: any, indexLesson: number) => {
							return {
								ClassroomTagId: newGroupTree.id,
								LessonId: lessons.find(
									(lesson: any) => lesson.CopiedFromLessonId === lessonClassroomTag.LessonId
								)?.id,
								priority: indexLesson
							};
						}
					);
					newGroupTree.LessonClassroomTag = lessonsClassroomTag;
					return newGroupTree;
				})
			);
		});
	};

	onSubmit = (form: any) => {
		if (!this.state.classContentLoaded) {
			return this.loadClassContent().then(() => {
				return this.submitForm(form);
			});
		}

		return this.submitForm(form);
	};

	dateInputChanged = (item: any) => {
		this.setState({ selectedDateHumanized: item.format("DD.MM.YYYY") });
	};

	toggleClassContent = () => {
		if (!this.state.classContentLoaded && !this.state.classContentShown) {
			return this.loadClassContent().then(() => {
				return this.setState({
					classContentShown: !this.state.classContentShown,
					displayCheckBOxes: !this.state.displayCheckBOxes
				});
			});
		}

		return this.setState({
			classContentShown: !this.state.classContentShown
		});
	};

	loadClassContent = async () => {
		const { t: translate } = this.props;
		const rootLessonTags: any = await new TagService().getAllRootTagsforLessons();
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			rootLessonTags[this.state.tabActiceKey]?.id,
			this.props.fromClassroom?.id
		);

		const rootLessonTagsFilterRadio = [...rootLessonTags]
		rootLessonTagsFilterRadio.push({
			id: 3,
			isGlobal: false,
			name: translate(`${TRANSLATION_BASE_PATH}._CHOOSE_ALL`),
			priority: rootLessonTags.length +1,
			target: "LESSON",
		})

		const retriveLessonsFromTree = (subjectPlanTree: SubjectPlanTreeDto | null): LessonDto[] | null => {
			if (!subjectPlanTree) return null;
			const subjectPlanNode: SubjectPlanTreeDto[] = [];
			subjectPlanNode.push(subjectPlanTree);
			const lesson: LessonDto[] = [];
			while (subjectPlanNode.length !== 0) {
				const node: any = subjectPlanNode.pop();
				if (node.children.length !== 0) subjectPlanNode.push(...node.children);
				lesson.push(...node.lessons);
			}
			return lesson;
		};

		if (!this.props.fromClassroom?.SubjectPlan?.id) return;
		return await new SubjectPlanTreeService()
			.getSubjectPlanTreeBySubjectId(this.props.fromClassroom.SubjectPlan.id)
			.then((result: any) => {
				const lessons: LessonDto[] | null = retriveLessonsFromTree(result);
				return this.setState({
					rootLessonTags: rootLessonTags,
					lessonTagGroup: _(lessonTagGroup)
						.orderBy((x: any) => Number(x.priority), "asc")
						.value(),
					lessonsToClone: lessons,
					rootLessonTagsFilterRadio
				});
			});
	};

	groupingOptionsToSelect = (): React.ReactNode => {
		return (
			<Radio.Group defaultValue={Number(this.state.tabActiceKey)} onChange={this.fetchAllSubGroupsForCopyContent}>
				{this.state.rootLessonTags.map((lessonTag: any, index: number) => (
					<Radio.Button key={index} value={index}>
						{lessonTag.name}
					</Radio.Button>
				))}
			</Radio.Group>
		);
	};

	fetchAllSubGroupsForCopyContent = async (e: any) => {
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			this.state.rootLessonTags[e.target.value.toString()]?.id,
			this.props.fromClassroom?.id
		);
		return this.setState({
			lessonTagGroup: _(lessonTagGroup)
				.orderBy((x: any) => Number(x.priority), "asc")
				.value(),
			tabActiceKey: e.target.value.toString()
		});
	};

	onChange = (e:any) => {
		this.setState({
		  readioValue: e.target.value,
		},()=>{
			this.fetchAllSubGroupsForCopyContent(e)
		});
	};

	render() {
		const { readioValue } = this.state;
		const { t: translate } = this.props;
		const btnTitle = translate(`${TRANSLATION_BASE_PATH}._CLONE_DRAWER_TITLE`);
		let submitHandler: any;

		const CreateEditForm = (): ReactNode => {
			const { t: translate } = this.props;
			const subtitle = translate(`${TRANSLATION_BASE_PATH}._CLONE_DRAWER_SUBTITLE`, {
				className: this.state.respectiveAsyncClassroom?.name
			});
			const info = translate(`${TRANSLATION_BASE_PATH}._CLONE_INFO`);
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.onSubmit}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row className="pb-16">
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{subtitle}
									</Text>
								</Row>
								<Row>
									<Alert message={info} description={""} type="info" showIcon />
								</Row>
								<Row className="pt-16">
									<FormikDatePickerField
										name="startDate"
										label={translate(`${TRANSLATION_BASE_PATH}._CLONE_DATE_LABEL`)}
										disabledDate={d => !d || moment(d).day() !== 1}
										mode="date"
										size="middle"
										placeholder={translate(`${TRANSLATION_BASE_PATH}._CLONE_DATE_PLACEHOLDELR`)}
										onSelect={(value: any) => this.dateInputChanged(value)}
									/>
								</Row>
								{this.state.selectedDateHumanized && (
									<Row>
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._CLONE_DATE_SELECTED`)}
											{this.state.selectedDateHumanized}
										</Text>
									</Row>
								)}
								<Row className="pt-24">
									<Button
										type="default"
										htmlType="button"
										icon={this.state.classContentShown ? "ri-eye-off-line" : "ri-eye-line"}
										iconPosition="left"
										onClick={() => this.toggleClassContent()}
									>
										{this.state.classContentShown
											? translate(`${TRANSLATION_BASE_PATH}._CLONE_HIDE_CLASS_CONTENT`)
											: translate(`${TRANSLATION_BASE_PATH}._CLONE_LOAD_CLASS_CONTENT`)}
									</Button>
								</Row>
								{this.state.classContentShown && (
									<Row>
										<Col span={24}>
											<Row className="pt-24">
												<Col span={24}>{this.groupingOptionsToSelect()}</Col>
											</Row>
											<Row className="pt-24">
												<Col span={24}>
													<TeacherCopyContentList
														lessonGroups={this.state.lessonTagGroup}
														lessons={this.state.lessonsToClone}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								)}
								
								{this.state.classContentShown && (<Row className="pt-16">
									<Col span="24" style={{marginTop:"15px"}}>
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_CONTENT`)}
										</Text>
										<br/>
										<Radio.Group 
											name="radiogroup"  
											defaultValue={readioValue}
											onChange={this.onChange}
										>
											{this.state.rootLessonTagsFilterRadio.map((item:any,index:number) =>{
												return <Radio value={index}>{item.name}</Radio>
											})}
										</Radio.Group>
									</Col>
								</Row>)}
							</form>
						);
					}}
				</Formik>
			);
		};

		return (
			<CreateEditDrawer
				title={btnTitle}
				onClose={() => this.props.hideDrawer()}
				onSave={() => submitHandler()}
				visible={true}
				form={CreateEditForm}
				width={408}
				submitting={this.state.submitting}
			/>
		);
	}
}

export const ClassCloneDrawer = withTranslation()(ClassCloneDrawerComponent);
