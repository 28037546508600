import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ADMIN._GENERIC._FORM";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
