import { Col, Modal, Row, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik } from "formik";

import Button from "common/components/general/Button";
import Image from "common/components/general/Image";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import { ProfilePictureType } from "../../../../../onBoarding/scenes/wizard/profilePicture";
import OAuth2Service from "services/authentication/oauth2.service";
import {
	studentAvatars,
	teacherAvatars
} from "../../../../../onBoarding/scenes/wizard/profilePicture/StaticAvatarsMapping";
import { previewFile } from "../../../../../courseBuilder/utils/FileUtils";
import { formValidator } from "./ChangeAvatarModalValidationSchema";
import { Uploader, UploadImageType } from "common/components/uploader/Uploader";
import UserSettingsService from "services/administration/userSettings.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

const { TabPane } = Tabs;

interface ChangeAvatarModalProps {
	visible: boolean;
	profilePicture?: string;
	profilePictureType?: string;
	profilePictureFile?: any;
	onCancel: () => void;
	afterUpdate: () => void;
}

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._MY_PROFILE";

export const ChangeAvatarModal = (props: ChangeAvatarModalProps) => {
	const { t: translate } = useTranslation();
	const [profilePictureType, setProfilePictureType] = useState<string>(
		props.profilePictureType ?? ProfilePictureType.Static
	);
	const [fileUploaded, setFileUploaded] = useState(props.profilePictureFile);
	const [imagePhoto, setImagePhoto] = useState<string | undefined>();
	const [loadingThumbnailImage, setLoadingThumbnailImage] = useState<boolean>(false);
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const isTeacher = OAuth2Service.isTeacher;
	const isParent = OAuth2Service.isParent;
	const userId = currentUserInfo?.UserId;

	const initialValues = {
		profilePicture: props.profilePicture
	};
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	let submitFormHandler: () => Promise<any>;

	const getImage = async (file?: any) => {
		if (!file) return;
		setLoadingThumbnailImage(true);
		const response = await previewFile(file);
		setImagePhoto(response.url);
		return setLoadingThumbnailImage(false);
	};

	useEffect(() => {
		setIsFormValid(formValidator(translate).isValidSync(initialValues));
		getImage(fileUploaded);
	}, []);

	const getAvatarOptionsBasedOnRole = () => {
		if (!currentUserInfo) return [];
		if (isTeacher || isParent) return teacherAvatars;
		return studentAvatars;
	};

	const onChangeProfilePictureTypeHandler = (key: string, formik: any) => {
		formik.setFieldValue("profilePicture", undefined);
		if (key === ProfilePictureType.Static) {
			setImagePhoto(undefined);
			setLoadingThumbnailImage(false);
			setFileUploaded(undefined);
		}
		setProfilePictureType(key);
	};

	const getImageWithFormik = async (formik: any, file?: any) => {
		getImage(file);
		return formik.setFieldValue("profilePicture", file.id);
	};

	const onUploadingDone = async (fileUploaded: any, formik: any) => {
		setFileUploaded(fileUploaded);
		await getImageWithFormik(formik, fileUploaded);
	};

	const createFile = (fileUploaded: any) => {
		const file = fileUploaded;
		if (file) {
			file.updatedBy = userId;
			file.createdBy = userId;
		}
		return file;
	};

	const footer = (
		<Row>
			<Col span={24} className="flex__row_center_reverse">
				<Space direction="horizontal">
					<Button type="default" size="middle" onClick={props.onCancel}>
						{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
					</Button>
					<Button
						type="primary"
						size="middle"
						onClick={() => submitFormHandler()}
						disabled={!isFormValid}
						loading={submitting}
					>
						{translate(`${TRANSLATION_BASE_PATH}._SAVE`)}
					</Button>
				</Space>
			</Col>
		</Row>
	);

	const onSubmit = async (values: any) => {
		setSubmitting(true);
		const file = createFile(fileUploaded);
		const userSettings = OAuth2Service.CurrentUserSettings;

		const isStaticProfilePicture = values.profilePicture.indexOf(".") > -1;
		const calculatedPictureType = isStaticProfilePicture ? ProfilePictureType.Static : ProfilePictureType.Upload;

		await new UserSettingsService()
			.updateAvatar({
				profilePicture: values.profilePicture,
				profilePictureType: calculatedPictureType,
				profilePictureFile: !isStaticProfilePicture ? file : undefined
			})
			.then(() => {
				localStorage.setItem(
					"user-settings",
					JSON.stringify({
						...userSettings,
						profilePicture: values.profilePicture,
						profilePictureType: calculatedPictureType,
						profilePictureFile: !isStaticProfilePicture ? file : undefined
					})
				);
				localStorage.removeItem("profile-picture-string");
				return props.afterUpdate();
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<Modal
			title={translate(`${TRANSLATION_BASE_PATH}._CHANGE_AVATAR_MODAL_TITLE`)}
			visible={props.visible}
			onCancel={props.onCancel}
			footer={footer}
			destroyOnClose={true}
		>
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={values => {
					setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					submitFormHandler = submitForm;
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row>
								<Col span={24}>
									<Tabs
										defaultActiveKey={ProfilePictureType.Static}
										activeKey={profilePictureType}
										onChange={key => onChangeProfilePictureTypeHandler(key, formik)}
									>
										<TabPane
											tab={translate(`${TRANSLATION_BASE_PATH}._CHOOSE_PROFILE_PICTURE`)}
											key={ProfilePictureType.Static}
										>
											<Radio
												name="profilePicture"
												defaultValue={initialValues.profilePicture}
												value={formik.values.profilePicture}
											>
												<Row className="user__settings_static_avatars_list">
													{getAvatarOptionsBasedOnRole().map(item => {
														return (
															<Col
																xs={24}
																sm={12}
																md={6}
																lg={6}
																xl={6}
																xxl={6}
																key={item}
																className="p-6"
															>
																<RadioElement
																	value={item}
																	showAsBox={true}
																	className="user__settings__avatar_preview"
																>
																	<div className="user__settings__avatar_preview_image">
																		<Image
																			src={require(`assets/images/avatars/${item}`)}
																		/>
																	</div>
																</RadioElement>
															</Col>
														);
													})}
												</Row>
											</Radio>
										</TabPane>
										<TabPane
											tab={translate(`${TRANSLATION_BASE_PATH}._UPLOAD_PROFILE_PICTURE`)}
											key={ProfilePictureType.Upload}
										>
											<Space direction="horizontal" align="start">
												<Uploader
													fileType="IMAGE"
													filePath={`avatar/${userId}`}
													fileWithUniqueId={false}
													uploadListType="picture-card"
													onUploadingDone={(fileUploaded: any) =>
														onUploadingDone(fileUploaded, formik)
													}
													showUploadList={false}
													uploadImageType={UploadImageType.Avatar}
												/>
												{imagePhoto || loadingThumbnailImage ? (
													<div className="onboarding__upload_avatar_preview_wrapper">
														{loadingThumbnailImage ? (
															<LoadingOutlined />
														) : (
															<Image src={imagePhoto ?? ""} />
														)}
													</div>
												) : null}
											</Space>
										</TabPane>
									</Tabs>
								</Col>
							</Row>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};
