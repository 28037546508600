import React, { Component, ReactNode } from "react";
import { Prompt } from "react-router-dom";
import { Col, Divider, Row, Skeleton, Card, Select, Input, Empty } from "antd";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";

import Icon from "common/components/general/Icon";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import { HtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import FormikDatePickerField from "common/components/dataEntry/formik/FormikDatePickerField";
import FormikTimeRangePickerField from "common/components/dataEntry/formik/FormikTimeRangePickerField";
import { ChildContext } from "scenes/courseBuilder/scenes/buildMaterials/components/layout/Layout";
import { formValidator } from "./ValidationSchema";

import LessonService from "services/administration/lesson.service";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";

import { showMessage, showError } from "common/utils/Notification";
import { treeToArray, deleteElement, saveElement } from "../../../../utils/ListUtils";
import OAuth2Service from "services/authentication/oauth2.service";
import AddMaterials from "../../components/materials/MaterialsComponent";
import { InfoModal } from "./InfoModal";
import { previewFile } from "../../../../utils/FileUtils";
import { CreateNewLessonNotificationDto } from "common/components/_notifications/dtos/createNewLessonNotificationDto";
import { createNewLessonNotifications } from "common/components/_notifications/util/createNotifications";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import TagService from "services/administration/tag.services";
import TagDto from "dtos/administration/tag.dto";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._MEETING";
export const MEETING_FULL_PATH = "meeting/:meetingId";

class MeetingMaterialComponent extends Component<any, any> {
	static contextType = ChildContext;

	constructor(props: any) {
		super(props);
		this.state = {
			rootLessonTags: [],
			formInitialValues: {},
			loading: false,
			subjectPlanTrees: [],
			meetingId: "",
			classroomId: "",
			userRole: "",
			s3SignedUrl: "",
			fileUploaded: null,
			filesToDelete: [],
			uploading: false,
			currentUser: null,
			newOption: ""
		};
	}

	async componentDidMount() {
		const {
			match: { params }
		} = this.props;
		if (!params.meetingId) return this.context.closeLessonMaterial();
		this.loadLessonMaterial(params.meetingId);
	}

	loadLessonMaterial = async (lessonId: string) => {
		const { t: translate } = this.props;
		this.setState({ loading: true });
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const userRole = currentUserInfo?.Roles && currentUserInfo?.Roles.length > 0 && currentUserInfo?.Roles[0];
		const lesson = await new LessonService().getLessonById(lessonId);
		const chapterId =
			lesson && lesson.SubjectPlanTrees && lesson.SubjectPlanTrees.length > 0
				? lesson.SubjectPlanTrees[0].id
				: null;

		this.context.setActualLessonId(lessonId);
		this.context.setEditMode(!!lesson);
		this.context.setTitle(
			this.context.isEditMode
				? translate(`${TRANSLATION_BASE_PATH}._MODIFY_MEETING`)
				: translate(`${TRANSLATION_BASE_PATH}._ADD_MEETING`)
		);

		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const subjectPlanId = queryString.get("subjectPlanId");
		const subjectPlanTreeId = queryString.get("subjectPlanTreeId");
		const classroomId = queryString.get("classroomId");
		this.context.setClassroom(classroomId);

		const rootLessonTags: any[] = await this.getAllTagsTree(classroomId || "");
		const allLessonGroups: any[] = await new TagService().getAllLessonsGroup(lessonId);

		const subjectPlanTree =
			subjectPlanId && (await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(subjectPlanId));
		const subjectPlanTrees = (subjectPlanTree || subjectPlanTree !== "") && treeToArray(subjectPlanTree);
		const filteredSubjectPlanTrees = subjectPlanTrees && subjectPlanTrees.filter(node => node.level > 0);
		const root = subjectPlanTrees && _(subjectPlanTrees).find(node => node.isRoot === true);

		if (lesson) {
			const meetingInfo = lesson.lessonMetaInfo ? JSON.parse(lesson.lessonMetaInfo) : {};

			this.setState({
				currentUser: currentUserInfo,
				loading: false,
				lessonId: lessonId,
				userRole: userRole?.code?.toLowerCase() || "",
				rootLessonTags: rootLessonTags,
				classroomId: classroomId,
				defaultFileList: lesson?.File ? [await previewFile(lesson.File)] : [],
				subjectPlanTrees: filteredSubjectPlanTrees,
				rootId: root && root.id,
				formInitialValues: this.createInitialValuesFormik(
					lesson,
					meetingInfo,
					root,
					allLessonGroups,
					rootLessonTags,
					chapterId,
					subjectPlanTreeId
				)
			});
		} else {
			this.setState({
				currentUser: currentUserInfo,
				loading: false,
				showInfoModal: true,
				lessonId: lessonId,
				userRole: userRole?.code?.toLowerCase() || "",
				rootLessonTags: rootLessonTags,
				classroomId: classroomId,
				subjectPlanTrees: filteredSubjectPlanTrees,
				rootId: root && root.id,
				formInitialValues: {
					chapterId: chapterId ? (chapterId !== this.state.rootId ? chapterId : "") : subjectPlanTreeId,
					name: undefined,
					startDate: undefined,
					startTime: [],
					materials: []
				}
			});
		}
	};

	createInitialValuesFormik(
		lesson: any,
		meetingInfo: any,
		root: any,
		allLessonGroups: any,
		rootLessonTags: any[],
		chapterId: string | null,
		subjectPlanTreeId: string | null
	) {
		const start = moment(meetingInfo.startTime);
		const startTime1 = moment(meetingInfo.startTime);
		const startTime2 = moment(meetingInfo.startTime).add(meetingInfo.duration, "minute");

		const formInitialValues = {
			...lesson,
			chapterId: chapterId ? (chapterId !== root?.id ? chapterId : "") : subjectPlanTreeId,
			name: lesson.name,
			description: lesson.description,
			materials: lesson.LessonSections.map((lessonSection: any) => ({
				id: lessonSection.id,
				title: lessonSection.name,
				lessonId: lesson.id,
				path: lessonSection.url,
				File: lessonSection.File,
				FileId: lessonSection.File?.id,
				fileList: [],
				type: lessonSection.File ? "file" : "url"
			})),
			meetingId: meetingInfo?.id,
			type: meetingInfo.type,
			startDate: start,
			startTime: [startTime1, startTime2],
			duration: meetingInfo.duration,
			password: meetingInfo.password
		};

		const parentChoosen = allLessonGroups.map((lessonSubGroup: any) => {
			const parent = rootLessonTags.find((rootLesson: any) => rootLesson.id === lessonSubGroup.ParentId);
			return { childId: lessonSubGroup.id, parentName: parent?.name };
		});

		parentChoosen.map((parent: any) => {
			formInitialValues[parent.parentName] = parent.childId;
		});

		return formInitialValues;
	}

	getAllTagsTree = async (classroomId: string) => {
		const rootLessonTags: any = OAuth2Service.isOwnerOrAdminAtDefaultOrganization
			? await new TagService().getAllRootTagsforAdminLessons(classroomId)
			: await new TagService().getAllRootTagsforLessons();

		const tagTree = await Promise.all(
			rootLessonTags.map(async (tag: any) => {
				let childTags: any[] = [];
				if (tag.isGlobal) childTags = await new TagService().getAllChildTags(tag.id);
				else childTags = await new TagService().getAllChildTagsForClassroom(tag.id, classroomId);
				return {
					...tag,
					childTags: _(childTags)
						.orderBy(x => Number(x.priority), "asc")
						.value()
				};
			})
		);

		return tagTree;
	};

	addItemToTagList = (input: string, parentId: string) => {
		if (input !== "") {
			const newTag: any = new TagDto({
				name: input,
				target: "LESSON",
				asyncOnly: false,
				isGlobal: false,
				priority: "0",
				ParentId: parentId,
				SubTagsCount: "0"
			});
			newTag.ClassroomTags = [{ TagId: newTag.id, ClassroomId: this.state.classroomId }];
			return new TagService()
				.createTagTree([newTag])
				.then(() => {
					return this.getAllTagsTree(this.state.classroomId);
				})
				.then((rootLessonTags: any) => {
					return this.setState({ rootLessonTags: rootLessonTags, newOption: "" });
				});
		}
	};

	getAddNewItem = (menu: any, parentId: string): any => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					{menu}
					<Divider className={"mt-4 mb-4"} />
					<Row className={"pr-4 pl-4"}>
						<Col span={20}>
							<Input
								placeholder={translate(`${TRANSLATION_BASE_PATH}._ADD_OPTION`)}
								value={this.state.newOption}
								onChange={this.onChange}
							/>
						</Col>
						<Col span={4}>
							<Row justify="center" align="middle" className="full__height">
								<a
									style={{ cursor: "pointer" }}
									onClick={() => this.addItemToTagList(this.state.newOption, parentId)}
								>
									<Icon type="ri-add-line" fontSize="12" />{" "}
									{translate(`${TRANSLATION_BASE_PATH}._ADD`)}
								</a>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	onChange = (e: any) => {
		this.setState({ newOption: e.target.value });
	};

	removeNewOption = () => {
		this.setState({ newOption: "" });
	};

	saveLesson = async (meetingDto: any) => {
		const { t: translate } = this.props;
		this.context.setShowPromptState(true);
		const duration = Math.round(moment.duration(meetingDto.startTime[1].diff(meetingDto.startTime[0])).asMinutes());
		const startTime = moment(
			moment(meetingDto.startDate).format("YYYY-MM-DD") + " " + meetingDto.startTime[0].format("HH:mm"),
			"YYYY-MM-DD HH:mm"
		);
		const lessonServerDto = {
			id: meetingDto.id,
			name: meetingDto.name,
			description: meetingDto.description ?? "",
			subjectPlanTreeId: this.state.rootId,
			WeekId: "f97b8b78-fe06-4f12-b95b-6abced66c1d1",
			startDate: startTime,
			endDate: startTime.clone().add(duration, "minutes"),
			lessonType: "MEETING",
			lessonMetaInfo: JSON.stringify({
				id: this.context.isEditMode ? meetingDto.meetingId : "",
				duration: duration,
				type: 2,
				password: uuidv4().substr(0, 10),
				startTime: startTime
			}),
			LessonSections: meetingDto.materials?.map((material: any) => {
				return {
					id: material.id,
					name: material.title,
					lessonId: meetingDto.id,
					url: material.path,
					File: material.File,
					FileId: material.FileId
				};
			}),
			classroomId: this.state.classroomId,
			GroupingInfo: this.state.rootLessonTags
				.map((rootTag: any) => {
					const element: string = rootTag.name || "";
					return meetingDto[element];
				})
				.filter((rootTag: any) => !!rootTag)
		};

		return (this.context.isEditMode
			? new LessonService().updateLesson(lessonServerDto)
			: new LessonService().saveLesson(lessonServerDto)
		)
			.then((result: any) => {
				const status = (this.context.isEditMode ? "_UPDATE" : "") + "_API_STATUS_CODE";
				showMessage(translate(`${TRANSLATION_BASE_PATH}._${result.status}${status}`));
				return result.data.id;
			})
			.then((recordId: string) => {
				if (this.context.isEditMode) return;
				return createNewLessonNotifications(
					new CreateNewLessonNotificationDto(
						this.state.classroomId,
						lessonServerDto.name,
						lessonServerDto.lessonType,
						recordId,
						this.state.currentUser?.User
					)
				);
			})
			.catch(error => {
				const errorStatus =
					typeof error?.response?.data?.error?.message === "number"
						? error.response.data.error.message
						: error.response.status ?? "500";
				showError(translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`));
			})
			.finally(() => {
				this.context.closeLessonMaterial();
			});
	};

	onBeforeUnload = (event: any) => {
		event.preventDefault();
		event.returnValue = "Some browsers display this to the user";
	};

	onUploadingDone = (formik: any) => {
		return (fileUploaded: any) => {
			formik.setFieldValue("path", fileUploaded?.name ? fileUploaded?.name : undefined);

			this.setState({
				fileUploaded: fileUploaded,
				uploading: true
			});
		};
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			fileUploaded: undefined
		});
	};

	onUploadingStarted = () => {
		this.setState({
			uploading: true
		});
	};

	getLessonClassroomTags = (formik: any): ReactNode => {
		const { t: translate } = this.props;
		return this.state.rootLessonTags.map((lessonTag: any, index: number) => {
			const formikSelectFiledProps = !lessonTag.isGlobal
				? {
					dropdownRender: (menu: any) => this.getAddNewItem(menu, lessonTag.id)
				  }
				: {};
			return (
				<Row key={index}>
					<Col span={24}>
						<FormikSelectField
							name={lessonTag.name}
							label={lessonTag.name}
							defaultValue={formik.initialValues[lessonTag.name]}
							placeholder={translate(`${TRANSLATION_BASE_PATH}._CHOOSE`)}
							allowClear={true}
							notFoundContent={
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
								/>
							}
							onFocus={this.removeNewOption}
							{...formikSelectFiledProps}
						>
							{lessonTag.childTags?.map((lessonSubTag: any) => {
								return (
									<Select.Option key={lessonSubTag.id} value={lessonSubTag.id}>
										{lessonSubTag.name}
									</Select.Option>
								);
							})}
						</FormikSelectField>
					</Col>
				</Row>
			);
		});
	};

	render() {
		const { t: translate } = this.props;
		const { handleOnSubmit } = this.props;

		return (
			<Skeleton active loading={this.state.loading}>
				{!this.state.loading ? (
					<Formik
						enableReinitialize={true}
						initialValues={this.state.formInitialValues}
						onSubmit={this.saveLesson}
						validationSchema={formValidator(translate)}
					>
						{formik => {
							const { handleSubmit, submitForm } = formik;
							handleOnSubmit(submitForm);
							return (
								<form onSubmit={handleSubmit} autoComplete="off">
									{!this.context.showPrompt && (
										<Prompt
											when={
												!formik.values.name ||
												!formik.values.materials ||
												formik.values.materials.length >= 0
											}
											message={translate(`${TRANSLATION_BASE_PATH}._LEAVE_PAGE`)}
										/>
									)}
									<Card>
										<Row className="courseBuilder__lessonMaterial">
											<Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
												{this.getLessonClassroomTags(formik)}
											</Col>

											<Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
												<Row align="middle" justify="center" className="full_height">
													<Divider type={"vertical"} className="divider" />
												</Row>
											</Col>
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={15}
												xl={15}
												xxl={15}
												className="full__width"
											>
												<Row>
													<Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
														{this.getLessonClassroomTags(formik)}
														<Divider />
													</Col>
												</Row>
												<FormikDefaultInputField
													name="name"
													label={translate(`${TRANSLATION_BASE_PATH}._TOPIC_LABEL`)}
													placeholder={translate(
														`${TRANSLATION_BASE_PATH}._TOPIC_PLACEHOLDER`
													)}
													size="middle"
												/>
												<HtmlInput
													name="description"
													label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_LABEL`)}
													placeholder={translate(
														`${TRANSLATION_BASE_PATH}._DESCRIPTION_PLACEHOLDER`
													)}
													labelInfo={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_INFO`)}
												/>
												<Row justify="space-between">
													<Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
														<FormikDatePickerField
															name="startDate"
															label={translate(
																`${TRANSLATION_BASE_PATH}._START_DATE_LABEL`
															)}
															defaultValue={this.state.formInitialValues.startDate}
															disabledDate={d => !d || d.isBefore(new Date(), "day")}
															mode="date"
															size="middle"
															placeholder={translate(
																`${TRANSLATION_BASE_PATH}._START_DATE_PLACEHOLDER`
															)}
															className="full__width"
														/>
													</Col>
													<Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
														<FormikTimeRangePickerField
															name="startTime"
															label={translate(
																`${TRANSLATION_BASE_PATH}._DURATION_LABEL`
															)}
															defaultValue={this.state.formInitialValues.startTime}
															format={"HH:mm"}
															size="middle"
															placeholder={[translate(
																`${TRANSLATION_BASE_PATH}._DURATION_START_PLACEHOLDER`
															),
																translate(
																	`${TRANSLATION_BASE_PATH}._DURATION_END_PLACEHOLDER`
																)]}
															direction="ltr"
															order={true}
															minuteStep={5}
															className="full__width"
														/>
													</Col>
												</Row>
												<Divider></Divider>
												<AddMaterials
													materials={formik.values.materials}
													deleteHandler={material =>
														deleteElement(material, formik.values.materials)
													}
													saveHandler={material =>
														saveElement(material, formik.values.materials)
													}
													openModal={opendModal =>
														this.context.setShowPromptState(opendModal)
													}
													lessonId={this.state.lessonId}
													onSoftDeleteFile={this.onSoftDeleteFile}
												/>
											</Col>
										</Row>
										<InfoModal
											visible={this.state.showInfoModal}
											handleClose={() => {
												this.setState({
													showInfoModal: false
												});
											}}
										/>
									</Card>
								</form>
							);
						}}
					</Formik>
				) : null}
			</Skeleton>
		);
	}
}

const MeetingMaterial = withTranslation()(MeetingMaterialComponent);
export default MeetingMaterial;
