import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "antd";
import moment from "moment";

import Button from "common/components/general/Button";
import SectionHeader from "common/components/_vituralClassMainSection/SectionHeader";
import { SectionContent } from "common/components/_vituralClassMainSection/SectionContent";
import NewsFeeder from "common/components/_vituralClassMainSection/NewsFeeder";
import { TodaysTasks } from "common/components/_vituralClassMainSection/TodaysTasks";
import { ScheduleMeetingModal } from "./scheduleMeetingModal";
import ClassroomService from "services/administration/classroom.service";
import MeetingService from "services/zoom/meeting.service";
import { IntroductionInfoModal } from "./introductionInfoModal";
import { NoStudentsBadge } from "./noStudentsBadge";
import { InviteStudentsDrawer } from "../../components/students/InviteStudentsDrawer";
import { PendingClassJoinBadge } from "./pendingClassJoinBadge";
import { COURSE_BUILDER_PATH } from "../../../..";
import EnrollmentService from "services/administration/enrollment.service";
import { createEnrollmentChangeNotification } from "common/components/_notifications/util/createNotifications";
import { CreateEnrollmentChangeNotificationDto } from "common/components/_notifications/dtos/createEnrollmentChangeNotificationDto";
import { OrganizationSectorType } from "services/domain/administration/Organization";

class TeacherCourseOverviewComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			showScheduleMeeting: false,
			scheduleMeetingInitialValues: undefined,
			loadingTasks: false,
			tasks: [],
			justCreated: props.justCreated && props.classroom?.Organization?.sector === OrganizationSectorType.Private,
			showNoStudentsBadge:
				props.justCreated === undefined &&
				(props.enrolledStudents === undefined || props.enrolledStudents.length === 0),
			inviteStudentsDrawerVisible: false,
			showPendingClassJoinBadge: false,
			pendingRequests: []
		};
	}

	async componentDidMount() {
		this.getPendingRequests();
		await this.getTodaysTasks();
	}

	getPendingRequests() {
		const pendingRequsts = this.props.enrolledStudents.filter((x: any) => {
			return x.status === "PENDING";
		});
		if (pendingRequsts.length > 0) {
			this.setState({
				pendingRequests: pendingRequsts,
				showPendingClassJoinBadge: true
			});
		}
	}

	pendingStudentsAccept = async (enrollmentId: string) => {
		let enrollment: any = null;
		await new EnrollmentService().getEnrollmentById(enrollmentId).then((result: any) => {
			enrollment = result;
			return;
		});
		return await new EnrollmentService()
			.updateEnrollment({ id: enrollmentId, status: "ACTIVE", updatedAt: new Date() })
			.then(() => {
				const notification = new CreateEnrollmentChangeNotificationDto(
					enrollment,
					this.props.classroom,
					"ACTIVE"
				);
				createEnrollmentChangeNotification(notification);
				const student = this.props.enrolledStudents.filter((x: any) => {
					return x.id === enrollmentId;
				})[0].User;
				return this.pendingStudentsShow(student.firstName + " " + student.lastName);
			});
	};

	pendingStudentsShow = (newStudent?: string) => {
		window.location.href = `/${COURSE_BUILDER_PATH}/_teacher/${
			this.props.classroom.id
		}?goToStudentList=${newStudent ?? "tab3"}`;
	};

	async getTodaysTasks(): Promise<any> {
		this.setState({
			loadingTasks: true
		});

		const tasks = await new ClassroomService().getTeacherTasks(this.props.classroom.id);

		this.setState({
			tasks: tasks,
			loadingTasks: false
		});
	}

	async deleteMeeting(meeting: any): Promise<any> {
		await new MeetingService()
			.deleteMeeting(meeting.id)
			.then(() => {
				return this.getTodaysTasks();
			})
			.catch(error => {
				console.log(error);
			});
	}

	GetTodaysTasks(classroomId: string) {
		return (
			<>
				<Row>
					<Col span={24} className="course__builder__todaysTasks__card">
						<TodaysTasks
							items={this.state.tasks}
							loadingTasks={this.state.loadingTasks}
							viewAsTeacher={true}
							classroomId={classroomId}
							onEditTask={(task: any) => {
								// TODO In the future Should call specific logic based on task type
								const meetingInfo = JSON.parse(task.extraInfo);
								const startTime1 = moment(meetingInfo.startTime);
								const startTime2 = moment(meetingInfo.startTime).add(meetingInfo.duration, "minute");
								this.setState({
									showScheduleMeeting: true,
									scheduleMeetingInitialValues: {
										id: task.id,
										name: task.title,
										startTime: [startTime1, startTime2],
										description: meetingInfo.agenda
									}
								});
							}}
							onDelete={async (task: any) => {
								// TODO In the future Should call specific logic based on task type
								await this.deleteMeeting(task);
							}}
						/>
					</Col>
				</Row>
				<Row className="mt-24">
					<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
						<Button
							type="primary"
							icon="ri-live-line"
							className="full__width"
							onClick={() => {
								this.setState({
									showScheduleMeeting: true
								});
							}}
						>
							Krijo klasë live
						</Button>
					</Col>
				</Row>
			</>
		);
	}

	render() {
		return (
			<>
				{this.state.showNoStudentsBadge && this.props?.classroom?.status !== "ARCHIVED" && (
					<NoStudentsBadge
						onInvite={() =>
							this.setState({
								inviteStudentsDrawerVisible: true
							})
						}
					/>
				)}
				{this.state.showPendingClassJoinBadge && (
					<PendingClassJoinBadge
						pendingRequests={this.state.pendingRequests}
						onShow={this.pendingStudentsShow}
						onAccept={this.pendingStudentsAccept}
					/>
				)}
				<Row justify="center">
					<SectionHeader
						classroom={this.props.classroom}
						editMode={this.props.classroomEditMode}
						setEditMode={this.props.setEditMode}
						pupils={this.props.enrolledStudents}
						organization={this.props.organization}
					/>
				</Row>
				<Row justify="center">
					<SectionContent>
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="mb-24">
							{this.GetTodaysTasks(this.props.classroom.id)}
						</Col>
						<NewsFeeder classroomId={this.props.classroom.id} />
						<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6} className="pl-24">
							{this.GetTodaysTasks(this.props.classroom.id)}
						</Col>
					</SectionContent>
				</Row>
				{this.state.justCreated && (
					<IntroductionInfoModal
						classroom={this.props.classroom}
						onCancel={() => {
							this.setState({
								showNoStudentsBadge: true
							});
						}}
						onInvite={() => {
							this.setState({
								showNoStudentsBadge: true,
								inviteStudentsDrawerVisible: true
							});
						}}
					/>
				)}
				<ScheduleMeetingModal
					classroomId={this.props.classroom.id}
					visible={this.state.showScheduleMeeting}
					initialValues={this.state.scheduleMeetingInitialValues}
					afterSaveHandler={() => {
						this.getTodaysTasks();
						this.setState({
							showScheduleMeeting: false,
							scheduleMeetingInitialValues: undefined
						});
					}}
					onCloseHandler={() => {
						this.setState({
							showScheduleMeeting: false,
							scheduleMeetingInitialValues: undefined
						});
					}}
				/>
				<InviteStudentsDrawer
					classroomId={this.props.classroom.id}
					visible={this.state.inviteStudentsDrawerVisible}
					onCancel={() => {
						this.setState({
							inviteStudentsDrawerVisible: false
						});
					}}
					onSuccess={() => {
						this.setState({
							inviteStudentsDrawerVisible: false
						});
					}}
					showHelperInfo
				/>
			</>
		);
	}
}

const TeacherCourseOverview = withTranslation()(TeacherCourseOverviewComponent);
export default TeacherCourseOverview;
