import React from "react";
import { Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW._NO_STUDENTS_BADGE";

interface NoStudentsBadgeProps {
	onInvite: () => void;
}

export const NoStudentsBadge = (props: NoStudentsBadgeProps) => {
	const { t: translate } = useTranslation();

	return (
		<Row justify="center" className="classroom__no_students_badge">
			<Space direction="horizontal" align="center" size={16}>
				<Icon type="ri-error-warning-fill" className="color-blue-1" fontSize="24" />
				<Text fontSize="14" lineHeight="22" className="color-blue-1">
					{translate(`${TRANSLATION_BASE_PATH}._NO_STUDENTS_DESCRIPTION`)}
				</Text>
				<Button type="default" onClick={props.onInvite}>
					{translate(`${TRANSLATION_BASE_PATH}._INVITE_STUDENTS_BUTTON`)}
				</Button>
			</Space>
		</Row>
	);
};
