import React, { Component, ReactNode } from "react";
import { Col, Divider, Row, Card, Skeleton, Select, Input, Empty } from "antd";
import { Formik } from "formik";
import { Prompt } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import Icon from "common/components/general/Icon";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import { HtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import { ChildContext } from "scenes/courseBuilder/scenes/buildMaterials/components/layout/Layout";
import { formValidator } from "./ValidationSchema";
import LessonService from "services/administration/lesson.service";
import { showMessage } from "common/utils/Notification";
import AddMaterials from "../../components/materials/MaterialsComponent";
import { treeToArray } from "../../../../utils/ListUtils";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import OAuth2Service from "services/authentication/oauth2.service";
import FormikDatePickerField from "common/components/dataEntry/formik/FormikDatePickerField";
import { createNewLessonNotifications } from "common/components/_notifications/util/createNotifications";
import { CreateNewLessonNotificationDto } from "common/components/_notifications/dtos/createNewLessonNotificationDto";
import TagService from "services/administration/tag.services";
import Material from "dtos/course-builder/material.dto";
import TagDto from "dtos/administration/tag.dto";

export const ASSIGNMENT_FULL_PATH = "assignment/:assignmentId";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._ASSIGNMENT";

class AssignmentsMaterialComponent extends Component<any, any> {
	static contextType = ChildContext;

	constructor(props: any) {
		super(props);
		this.state = {
			rootLessonTags: [],
			formInitialValues: {},
			showPreview: false,
			loading: false,
			subjectPlanTrees: [],
			lessonId: "",
			classroomId: "",
			newOption: ""
		};
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props;
		if (!params.assignmentId) return this.context.closeLessonMaterial();
		this.loadLessonMaterial(params.assignmentId);
	}

	loadLessonMaterial = async (lessonId: string) => {
		const { t: translate } = this.props;
		this.setState({ loading: true });
		const lesson: any = await new LessonService().getLessonById(lessonId);
		const chapterId =
			lesson && lesson.SubjectPlanTrees && lesson.SubjectPlanTrees.length > 0
				? lesson.SubjectPlanTrees[0].id
				: null;

		this.context.setActualLessonId(lessonId);
		this.context.setEditMode(!!lesson);
		this.context.setTitle(
			this.context.isEditMode
				? translate(`${TRANSLATION_BASE_PATH}._MODIFY_ASSIGNMENT`)
				: translate(`${TRANSLATION_BASE_PATH}._ADD_ASSIGNMENT`)
		);

		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const subjectPlanId = queryString.get("subjectPlanId");
		const subjectPlanTreeId = queryString.get("subjectPlanTreeId");
		const classroomId = queryString.get("classroomId");
		this.context.setClassroom(classroomId);

		const rootLessonTags: any[] = await this.getAllTagsTree(classroomId || "");
		const allLessonGroups: any[] = await new TagService().getAllLessonsGroup(lessonId);

		const subjectPlanTree =
			subjectPlanId && (await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(subjectPlanId));
		const subjectPlanTrees = (subjectPlanTree || subjectPlanTree !== "") && treeToArray(subjectPlanTree);
		const filteredSubjectPlanTrees = subjectPlanTrees && subjectPlanTrees.filter(node => node.level > 0);
		const root = subjectPlanTrees && _(subjectPlanTrees).find(node => node.isRoot === true);
		this.setState({
			loading: false,
			showPreview: !!lesson,
			lessonId: lessonId,
			rootLessonTags: rootLessonTags,
			classroomId: classroomId,
			subjectPlanTrees: filteredSubjectPlanTrees,
			rootId: root && root.id,
			formInitialValues: this.createInitialValuesFormik(
				lesson,
				root,
				allLessonGroups,
				rootLessonTags,
				chapterId,
				subjectPlanTreeId
			)
		});
	};

	createInitialValuesFormik(
		lesson: any,
		root: any,
		allLessonGroups: any,
		rootLessonTags: any[],
		chapterId: string,
		subjectPlanTreeId: string | null
	) {
		const formInitialValues = {
			...lesson,
			startDate: (lesson && lesson.startDate) || undefined,
			endDate:
				(lesson && lesson.endDate) ||
				(this.context.classroom && !this.context.classroom.isAsync ? new Date() : undefined),
			chapterId: chapterId ? (chapterId !== root?.id ? chapterId : "") : subjectPlanTreeId,
			name: lesson && lesson?.name,
			materials: lesson
				? lesson.LessonSections.map((lessonSection: any) => ({
					id: lessonSection.id,
					title: lessonSection.name,
					lessonId: lesson.id,
					path: lessonSection.url,
					File: lessonSection.File,
					FileId: lessonSection.File?.id,
					fileList: [],
					type: lessonSection.File ? "file" : "url"
				  }))
				: [],
			path: lesson?.File?.name
		};

		const parentChoosen = allLessonGroups.map((lessonSubGroup: any) => {
			const parent = rootLessonTags.find((rootLesson: any) => rootLesson.id === lessonSubGroup.ParentId);
			return { childId: lessonSubGroup.id, parentName: parent?.name };
		});

		parentChoosen.map((parent: any) => {
			formInitialValues[parent.parentName] = parent.childId;
		});

		return formInitialValues;
	}

	activatePreview = () => {
		this.setState({ showPreview: true });
	};

	getAllTagsTree = async (classroomId: string) => {
		const rootLessonTags = OAuth2Service.isOwnerOrAdminAtDefaultOrganization
			? await new TagService().getAllRootTagsforAdminLessons(classroomId)
			: await new TagService().getAllRootTagsforLessons();

		const tagTree = await Promise.all(
			rootLessonTags.map(async (tag: any) => {
				let childTags: any[] = [];
				if (tag.isGlobal) childTags = await new TagService().getAllChildTags(tag.id);
				else childTags = await new TagService().getAllChildTagsForClassroom(tag.id, classroomId);
				return {
					...tag,
					childTags: _(childTags)
						.orderBy(x => Number(x.priority), "asc")
						.value()
				};
			})
		);

		return tagTree;
	};

	addItemToTagList = (input: string, parentId: string) => {
		if (input !== "") {
			const newTag: any = new TagDto({
				name: input,
				target: "LESSON",
				asyncOnly: false,
				isGlobal: false,
				priority: "0",
				ParentId: parentId,
				SubTagsCount: "0"
			});
			newTag.ClassroomTags = [{ TagId: newTag.id, ClassroomId: this.state.classroomId }];
			return new TagService()
				.createTagTree([newTag])
				.then(() => {
					return this.getAllTagsTree(this.state.classroomId);
				})
				.then((rootLessonTags: any) => {
					return this.setState({ rootLessonTags: rootLessonTags, newOption: "" });
				});
		}
	};

	getAddNewItem = (menu: any, parentId: string): any => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					{menu}
					<Divider className={"mt-4 mb-4"} />
					<Row className={"pr-4 pl-4"}>
						<Col span={20}>
							<Input
								placeholder={translate(`${TRANSLATION_BASE_PATH}._ADD_OPTION`)}
								value={this.state.newOption}
								onChange={this.onChange}
							/>
						</Col>
						<Col span={4}>
							<Row justify="center" align="middle" className="full__height">
								<a
									style={{ cursor: "pointer" }}
									onClick={() => this.addItemToTagList(this.state.newOption, parentId)}
								>
									<Icon type="ri-add-line" fontSize="12" />{" "}
									{translate(`${TRANSLATION_BASE_PATH}._ADD`)}
								</a>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	onChange = (e: any) => {
		this.setState({ newOption: e.target.value });
	};

	removeNewOption = () => {
		this.setState({ newOption: "" });
	};

	saveLesson = async (lessonDto: any) => {
		const { t: translate } = this.props;
		this.context.setShowPromptState(true);

		const lessonServerDto = {
			id: lessonDto.id,
			name: lessonDto.name,
			description: lessonDto.description,
			subjectPlanTreeId: this.state.rootId,
			WeekId: "f97b8b78-fe06-4f12-b95b-6abced66c1d1",
			startDate: this.context.isEditMode ? moment(lessonDto.startDate).toDate() : new Date(),
			endDate: lessonDto.endDate ? moment(lessonDto.endDate).toDate() : null,
			lessonType: "ASSIGNMENT",
			LessonSections: lessonDto.materials?.map((material: Material) => {
				return {
					id: material.id,
					name: material.title,
					lessonId: lessonDto.id,
					url: material.path,
					File: material.File,
					FileId: material.FileId
				};
			}),
			classroomId: this.state.classroomId,
			GroupingInfo: this.state.rootLessonTags
				.map((rootTag: any) => {
					const element: string = rootTag.name || "";
					return lessonDto[element];
				})
				.filter((rootTag: any) => !!rootTag)
		};
		return (this.context.isEditMode
			? new LessonService().updateLesson(lessonServerDto)
			: new LessonService().saveLesson(lessonServerDto)
		)
			.then((result: any) => {
				showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
				return result.data.id;
			})
			.then((recordId: string) => {
				if (this.context.isEditMode) return;
				return createNewLessonNotifications(
					new CreateNewLessonNotificationDto(
						this.state.classroomId,
						lessonServerDto.name,
						lessonServerDto.lessonType,
						recordId,
						OAuth2Service.CurrentUser?.User
					)
				);
			})
			.finally(() => {
				this.context.closeLessonMaterial();
			});
	};

	getLessonClassroomTags = (formik: any): ReactNode => {
		const { t: translate } = this.props;
		return this.state.rootLessonTags.map((lessonTag: any, index: number) => {
			const formikSelectFiledProps = !lessonTag.isGlobal
				? {
					dropdownRender: (menu: any) => this.getAddNewItem(menu, lessonTag.id)
				  }
				: {};
			return (
				<Row key={index}>
					<Col span={24}>
						<FormikSelectField
							name={lessonTag.name}
							label={lessonTag.name}
							defaultValue={formik.initialValues[lessonTag.name]}
							placeholder={translate(`${TRANSLATION_BASE_PATH}._CHOOSE`)}
							allowClear={true}
							notFoundContent={
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
								/>
							}
							onFocus={this.removeNewOption}
							{...formikSelectFiledProps}
						>
							{lessonTag.childTags?.map((lessonSubTag: any) => {
								return (
									<Select.Option key={lessonSubTag.id} value={lessonSubTag.id}>
										{lessonSubTag.name}
									</Select.Option>
								);
							})}
						</FormikSelectField>
					</Col>
				</Row>
			);
		});
	};

	render() {
		const { t: translate } = this.props;
		const { handleOnSubmit } = this.props;
		return (
			<Skeleton active loading={this.state.loading}>
				<Formik
					enableReinitialize={true}
					initialValues={this.state.formInitialValues}
					onSubmit={this.saveLesson}
					validationSchema={formValidator(translate)}
				>
					{formik => {
						const { handleSubmit, submitForm, dirty } = formik;
						handleOnSubmit(submitForm);
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								{!this.context.showPrompt && dirty && (
									<Prompt when={dirty} message={translate(`${TRANSLATION_BASE_PATH}._LEAVE_PAGE`)} />
								)}
								<Card>
									<Row className="courseBuilder__lessonMaterial">
										<Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
											{this.getLessonClassroomTags(formik)}
										</Col>
										<Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
											<Row align="middle" justify="center" className="full_height">
												<Divider type={"vertical"} className="divider" />
											</Row>
										</Col>
										<Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15} className="full__width">
											<Row>
												<Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
													{this.getLessonClassroomTags(formik)}
													<Divider />
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<FormikDefaultInputField
														name="name"
														label={translate(`${TRANSLATION_BASE_PATH}._NAME`)}
														placeholder={translate(
															`${TRANSLATION_BASE_PATH}._NAME_PLACEHOLDER`
														)}
													/>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<HtmlInput
														name="description"
														label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
														placeholder={translate(
															`${TRANSLATION_BASE_PATH}._DESCRIPTION_PLACEHOLDER`
														)}
														labelInfo={translate(
															`${TRANSLATION_BASE_PATH}._DESCRIPTION_INFO`
														)}
													/>
												</Col>
											</Row>
											{this.context.classroom && !this.context.classroom.isAsync && (
												<Row>
													<Col span={24}>
														<FormikDatePickerField
															minuteStep={5}
															dateFormat="YYYY-MM-DD HH:mm"
															className="full__width"
															name="endDate"
															defaultValue={moment(formik.initialValues.endDate)}
															label={translate(`${TRANSLATION_BASE_PATH}._DUE_DATE`)}
															placeholder={translate(
																`${TRANSLATION_BASE_PATH}._DUE_DATE_PLACEHOLDER`
															)}
															showTime={{
																defaultValue: moment("00:00", "HH:mm")
															}}
															disabledDate={d => !d || d.isBefore(moment(), "day")}
														/>
													</Col>
												</Row>
											)}
											<Divider></Divider>
											{this.state.lessonId ? (
												<AddMaterials
													materials={formik.values.materials}
													deleteHandler={material => {
														const newArray = formik.values.materials;
														const index = newArray.findIndex(
															(entry: any) => entry.id === material.id
														);
														newArray.splice(index, 1);
														formik.setFieldValue("materials", newArray);
														this.context.setShowPromptState(false);
													}}
													saveHandler={material => {
														let newArray: any = formik.values.materials;
														if (material.id === undefined) {
															material.id = uuidv4();
															newArray = [...formik.values.materials, material];
														} else {
															const index = formik.values.materials.findIndex(
																(entry: any) => entry.id === material.id
															);
															if (index > -1) {
																newArray.splice(index, 1, material);
															}
														}
														formik.setFieldValue("materials", newArray);
													}}
													openModal={opendModal =>
														this.context.setShowPromptState(opendModal)
													}
													lessonId={this.state.lessonId}
												/>
											) : null}
										</Col>
									</Row>
								</Card>
							</form>
						);
					}}
				</Formik>
			</Skeleton>
		);
	}
}

const AssignmentsMaterial = withTranslation()(AssignmentsMaterialComponent);
export default AssignmentsMaterial;
