import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Alert from "common/components/feedback/Alert";
import CheckBoxGroup from "common/components/dataEntry/formik/FormikCheckboxGroupField";
import CheckBoxElement from "common/components/dataEntry/components/CheckBoxGroupElement";
import { formValidator } from "./ValidationSchema";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_SUBJECTS";
const ALL_VALUE = "all";

class ChooseSubjectsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			initialValues: { subjects: props.state?.classRooms ?? [] },
			subjectOptions: [],
			selectedAll: false
		};
	}

	fetchData = async (gradeId: string) => {
		await new OnboardingService()
			.getSubjectsBasedOnGrade(gradeId)
			.then((data: any) => {
				const subjectOptions = data.filter(
					(item: any) => item.classRoomId !== undefined && item.classRoomId !== ""
				);
				const selectedAll = subjectOptions.length === this.state.initialValues.subjects.length;

				let selectedSubjects = this.state.initialValues.subjects;
				if (selectedAll === true) {
					selectedSubjects = [...selectedSubjects, ALL_VALUE];
				}

				this.setState({
					subjectOptions: subjectOptions,
					selectedAll: selectedAll,
					initialValues: { subjects: selectedSubjects }
				});
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	componentDidMount() {
		const { t: translate } = this.props;
		if (this.props.state.grade === undefined) {
			this.props.goBack();
		} else {
			this.props.setIsFormValid(formValidator().isValidSync(this.state.initialValues));
			this.props.setTitle(translate(`${TRANSLATION_BASE_PATH}._SUBJECTS`));
			this.fetchData(this.props.state.grade);
		}
	}

	onCheckBoxChange = async (checkedValues: any[]) => {
		if (checkedValues.includes(ALL_VALUE) && !this.state.selectedAll) {
			const allSubjectsSelected = [
				...this.state.subjectOptions.map((subject: any) => subject.classRoomId),
				ALL_VALUE
			];
			this.setState({
				selectedAll: true,
				initialValues: { subjects: allSubjectsSelected }
			});
		} else if (checkedValues.includes(ALL_VALUE) && this.state.selectedAll) {
			const oldValues = [...checkedValues];
			const allIndex = oldValues.findIndex(item => item === ALL_VALUE);
			if (allIndex > -1) {
				oldValues.splice(allIndex, 1);
			}

			this.setState({
				selectedAll: false,
				initialValues: { subjects: [...oldValues] }
			});
		} else if (
			!checkedValues.includes(ALL_VALUE) &&
			checkedValues.length === this.state.subjectOptions.length &&
			!this.state.selectedAll
		) {
			const allSubjectsSelected = [
				...this.state.subjectOptions.map((subject: any) => subject.classRoomId),
				ALL_VALUE
			];
			this.setState({
				selectedAll: true,
				initialValues: { subjects: allSubjectsSelected }
			});
		} else if (
			!checkedValues.includes(ALL_VALUE) &&
			checkedValues.length === this.state.subjectOptions.length &&
			this.state.selectedAll
		) {
			this.setState({
				selectedAll: false,
				initialValues: { subjects: [] }
			});
		}
	};

	getSubjectOptionsContent = (formik: FormikProps<any>): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
					<CheckBoxGroup
						name="subjects"
						value={formik.values.subjects}
						defaultValue={this.state.initialValues.subjects}
						onChange={this.onCheckBoxChange}
					>
						<Row gutter={[8, 4]}>
							{this.state.subjectOptions.length > 0 ? (
								<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
									<CheckBoxElement value={ALL_VALUE}>
										{translate(`${TRANSLATION_BASE_PATH}._ALL`)}
									</CheckBoxElement>
								</Col>
							) : null}
							{this.state.subjectOptions.map((item: any, index: number) => {
								return (
									<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} key={index}>
										<CheckBoxElement value={item.classRoomId}>{item.subjectName}</CheckBoxElement>
									</Col>
								);
							})}
							{this.state.subjectOptions.length === 0 ? (
								<Col span={24}>
									<Alert
										showIcon
										message={translate(`${TRANSLATION_BASE_PATH}._NO_SUBJECTS_ALERT_TITLE`)}
										description={translate(
											`${TRANSLATION_BASE_PATH}._NO_SUBJECTS_ALERT_DESCRIPTION`
										)}
									/>
								</Col>
							) : null}
						</Row>
					</CheckBoxGroup>
				</Col>
			</Row>
		);
	};

	onSubmit = async (values: any) => {
		const newState = {
			...this.props.state,
			classRooms: values.subjects.filter((item: string) => item !== ALL_VALUE)
		};
		this.props.updateState(newState);

		await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				this.props.setIsSubmiting(false);
				this.props.goNext();
				return;
			})
			.catch(error => {
				console.log(error);
				this.props.setIsSubmiting(false);
			});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator}
				initialValues={this.state.initialValues}
				onSubmit={this.onSubmit}
				validate={values => {
					this.props.setIsFormValid(formValidator().isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					this.props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-8">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_SUBJECTS_DESCRIPTION`)}
									</Text>
								</Col>
							</Row>
							{this.getSubjectOptionsContent(formik)}
						</form>
					);
				}}
			</Formik>
		);
	}
}

export const ChooseSubjects = withTranslation()(ChooseSubjectsComponent);
