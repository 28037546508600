import { Col, Modal, Row, Skeleton } from "antd";
import React, { Component, ReactNode } from "react";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { showError } from "common/utils/Notification";
import { WelcomeBaner } from "./components/welcome";
import { DigitalizeStudentBaner } from "./components/digitalizeStudent";
import { MarketplaceBanner } from "./components/marketplaceBanner";
import { previewFile } from "../../../courseBuilder/utils/FileUtils";
import { SUBJECTS_PATH } from "scenes/subjects";
import { COURSE_PATH } from "scenes/course";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import EnrollmentDto from "dtos/administration/enrollment.dto";
import EnrollmentService from "services/administration/enrollment.service";
import ClassSlider, { ClassItem } from "./components/classSlider";
import { CoursesPerformance } from "./components/coursesPerformance";
import { StudentAssignments } from "./components/studentAssignments";
import { CalendarWithLessons } from "./components/calendarWithLessons";
import InvitationService from "services/administration/invitation.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import OrganizationService from "services/administration/organization.service";
import { SIGN_IN_PATH } from "../../../login/scenes/signIn";
import { Role } from "services/domain/login/Role";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { boolVal, getComponentProps, isComponentVisible } from "../../../../common/utils/Design";
import { MainLayout } from "scenes/main/components/layout/Layout";

const studentRoleID = Role.Student;
const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._STUDENT_DASHBOARD";

class StudentDashboardComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			classItems: null,
			currentUserId: "",
			parentInvite: null
		};
	}

	getPhotoUrls = async (items: any): Promise<any> => {
		return (
			(await Promise.all(
				items.map(async function(classroom: any) {
					const image = (classroom.File && (await previewFile(classroom.File))) || null;
					return { ...classroom, filePath: image && image.url };
				})
			).then(classroom => {
				return classroom;
			})) || []
		);
	};

	getClassItemsOverviewDetails = async (data: EnrollmentDto[]): Promise<ClassItem[]> => {
		const items = data
			.filter((x: EnrollmentDto) => x.status === "ACTIVE")
			.map((x: EnrollmentDto) => {
				let coursePath = "";
				if (x.Classroom?.isAsync) {
					coursePath = `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/${x.ClassroomId}`;
				} else {
					coursePath = `/${COURSE_PATH}/_student/${x.ClassroomId}`;
				}
				return {
					id: x.ClassroomId,
					name: x.Classroom?.name ?? "",
					organizationName: x.Classroom?.Organization?.name ?? "",
					teacherName: x.Classroom?.UserCreatedBy?.firstName + " " + x.Classroom?.UserCreatedBy?.lastName,
					isAsync: x.Classroom?.isAsync ?? true,
					pathToClassroom: coursePath,
					File: x.Classroom?.File
				};
			});

		return this.getPhotoUrls(items);
	};

	resize() {
		this.setState({
			smallerOrMedium: window.innerWidth < 992,
			large: window.innerWidth >= 992 && window.innerWidth < 1200,
			extraLarge: window.innerWidth >= 1200
		});
	}

	checkForParentInvitation() {
		return new InvitationService().getInviteFromParent().then((result: any) => {
			return this.setState({
				parentInvite: result
			});
		});
	}

	async componentDidMount() {
		const { t: translate, history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		const isStudent: boolean = currentUserInfo.Roles.some((role: any) => role.code === studentRoleID);
		this.setState({ loading: true, currentUserId: currentUserInfo.UserId, isStudent: isStudent });
		const classroomInvitation = JSON.parse(localStorage.getItem("classroom-invitation") ?? "{}");
		if (classroomInvitation.registerAsStudent && isStudent) {
			new InvitationService()
				.getClassroomInvitationDetails(classroomInvitation.invitationId)
				.then(result => {
					this.setState({
						classroomInvitation: { ...result },
						classroomInvitationId: classroomInvitation.invitationId,
						classroomInvitationModalVisible: true
					});
					return;
				})
				.catch(() => {
					return showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INVALID_CODE`));
				});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		this.checkForParentInvitation();

		return new EnrollmentService()
			.getAllEnrollments()
			.then(async (result: EnrollmentDto[]) => {
				return await this.getClassItemsOverviewDetails(result);
			})
			.then(res => {
				const orderedResult = res.sort((first, second) => (first.isAsync && !second.isAsync ? -1 : 1));
				return this.setState({
					classItems: orderedResult
				});
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	enrollToInvitedClassroom() {
		const { t: translate } = this.props;
		const enrollment: EnrollmentDto = new EnrollmentDto({
			UserId: this.state.currentUserId,
			ClassroomId: this.state.classroomInvitation.id,
			status: "ACTIVE",
			enrolledAt: new Date(),
			invitationId: this.state.classroomInvitationId
		});

		let currentOrg: any = null;
		let enrollmentToClass: any = null;

		return new OrganizationService()
			.findCurrentOrganization()
			.then((currentOrganization: any) => {
				currentOrg = currentOrganization?.length === 1 ? { ...currentOrganization[0] } : null;
				return new EnrollmentService().createEnrollment(enrollment);
			})
			.then((enrollment: any) => {
				if (!enrollment)
					return showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INVALID_CODE`));
				localStorage.removeItem("classroom-invitation");
				enrollmentToClass = { status: enrollment?.status, ClassroomId: enrollment?.ClassroomId };
				if (!currentOrg || currentOrg.id !== this.state.classroomInvitation?.Organization?.id)
					return new OrganizationService().joinOrganization(
						this.state.classroomInvitation?.Organization?.code
					);
				return currentOrg;
			})
			.then(() => {
				return this.props.history.push(`/${COURSE_PATH}/_student/${enrollmentToClass.ClassroomId}`);
			})
			.catch(() => {
				this.setState({
					classroomInvitationModalVisible: false
				});
				localStorage.removeItem("classroom-invitation");
				showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INVALID_CODE`));
			});
	}

	get joinClassFromInvitationModal(): ReactNode {
		const { t: translate } = this.props;

		const modalTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._TITLE`);
		const OkBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._OK_BTN_TITLE`);
		const cancelBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._CANCEL_BTN_TITLE`);
		const inputTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INPUT_TITLE`);

		return (
			<Modal
				title={modalTitle}
				visible={this.state.classroomInvitationModalVisible}
				onOk={() => this.enrollToInvitedClassroom()}
				onCancel={() => {
					this.setState({
						classroomInvitationModalVisible: false
					});
					localStorage.removeItem("classroom-invitation");
				}}
				okText={OkBtnTitle}
				cancelText={cancelBtnTitle}
			>
				<Formik
					initialValues={{}}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onSubmit={() => {}}
				>
					{() => (
						<>
							<Row>
								<Col span={24}>
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{translate(
											`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INVITATION_DESCRIPTION`,
											{
												teacherName: this.state.classroomInvitation.UserCreatedBy.firstName
											}
										)}{" "}
										<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
											{this.state.classroomInvitation.name}.
										</Text>
									</Text>
								</Col>
							</Row>
							<Row className="mt-16">
								<Col span={24}>
									<Input
										name="code"
										label={inputTitle}
										value={`${this.state.classroomInvitation.code} (${this.state.classroomInvitation.name})`}
										disabled
										disabledTooltip={translate(
											`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._FIELD_CANT_BE_MODIFIED`
										)}
										suffix={<Icon type="ri-checkbox-circle-fill" className="color-green-6" />}
									/>
								</Col>
							</Row>
						</>
					)}
				</Formik>
			</Modal>
		);
	}

	render() {
		const showGreetings: boolean = isComponentVisible(this.props.pageComponents, "greetingsCard");
		const showBanner: boolean = isComponentVisible(this.props.pageComponents, "banner");
		const showMyClasses: boolean = isComponentVisible(this.props.pageComponents, "myClasses");
		const showCoursePerformance: boolean = isComponentVisible(this.props.pageComponents, "coursePerformance");
		const showHomeworks: boolean = isComponentVisible(this.props.pageComponents, "homeworks");
		const showCalendar: boolean = isComponentVisible(this.props.pageComponents, "calendar");
		const sideLayoutProps = getComponentProps(this.props.pageComponents, "sideLayout");

		return (
			<MainLayout
				showMarketPlace={boolVal(sideLayoutProps, "showExploreCourses", true)}
				showAddCourse={boolVal(sideLayoutProps, "joinLiveCourse", true)}
			>
				<SectionTitle subtitle="Kreu" />
				<div className="dashboard__wrapper">
					<div className="dashboard__wrapper_content">
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={19}>
								{showGreetings && (
									<Row>
										<Col span={24}>
											<WelcomeBaner
												{...getComponentProps(this.props.pageComponents, "greetingsCard")}
											/>
										</Col>
									</Row>
								)}
								{this.state.displayDigitalizeStudentBaner && (
									<Row className="mt-24">
										<Col span={24}>
											<DigitalizeStudentBaner />
										</Col>
									</Row>
								)}
								{showBanner && (
									<Row className="mt-24">
										<Col span={24}>
											<MarketplaceBanner
												{...getComponentProps(this.props.pageComponents, "banner")}
											/>
										</Col>
									</Row>
								)}
								{this.state.smallerOrMedium && showCalendar ? (
									<Row className="mt-24">
										<Col span={24}>
											<CalendarWithLessons {...getComponentProps(this.props.pageComponents, "calendar")} />
										</Col>
									</Row>
								) : null}
								{this.state.large ? (
									<Row>
										<Col span={16} className="pr-24">
											<Row className="mt-24">
												<Col span={24}>
													<Skeleton active loading={this.state.loading}>
														{this.state.classItems && showMyClasses && (
															<ClassSlider
																{...getComponentProps(
																	this.props.pageComponents,
																	"myClasses"
																)}
																items={this.state.classItems}
															/>
														)}
													</Skeleton>
												</Col>
											</Row>

											{showCoursePerformance && <Row className="mt-24">
												<Col span={24}>
													<CoursesPerformance
														{...getComponentProps(
															this.props.pageComponents,
															"coursePerformance"
														)}
													/>
												</Col>
											</Row>}

											{showHomeworks && <Row className="mt-24">
												<Col span={24}>
													<StudentAssignments
														{...getComponentProps(
															this.props.pageComponents,
															"homeworks"
														)}
													/>
												</Col>
											</Row>}
										</Col>
										{showCalendar && <Col span={8} className="mt-24">
											<CalendarWithLessons {...getComponentProps(this.props.pageComponents, "calendar")} />
										</Col>}
									</Row>
								) : null}
								{this.state.smallerOrMedium || this.state.extraLarge ? (
									<>
										<Row className="mt-24">
											<Col span={24}>
												<Skeleton active loading={this.state.loading}>
													{this.state.classItems && showMyClasses && (
														<ClassSlider
															{...getComponentProps(
																this.props.pageComponents,
																"myClasses"
															)}
															items={this.state.classItems}
														/>
													)}
												</Skeleton>
											</Col>
										</Row>
										{showCoursePerformance && <Row className="mt-24">
											<Col span={24}>
												<CoursesPerformance
													{...getComponentProps(
														this.props.pageComponents,
														"coursePerformance"
													)}
												/>
											</Col>
										</Row>}
										{showHomeworks && <Row className="mt-24">
											<Col span={24}>
												<StudentAssignments
													{...getComponentProps(
														this.props.pageComponents,
														"homeworks"
													)}
												/>
											</Col>
										</Row>}
									</>
								) : null}
							</Col>
							{this.state.extraLarge && showCalendar ? (
								<Col xs={0} sm={0} md={0} lg={0} xl={7} xxl={5} className="pl-24">
									<CalendarWithLessons {...getComponentProps(this.props.pageComponents, "calendar")} />
								</Col>
							) : null}
							{this.joinClassFromInvitationModal}
						</Row>
					</div>
				</div>
			</MainLayout>
		);
	}
}

const StudentDashboard = withTranslation()(StudentDashboardComponent);
export default StudentDashboard;
