import React, { useState } from "react";
import { Modal, Col, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import Input from "common/components/dataEntry/components/Input";
import Text from "common/components/general/Text";
import TextArea from "common/components/dataEntry/components/TextArea";
import { Uploader } from "common/components/uploader/Uploader";
import MarketplaceEmailService from "services/email/marketplace.email.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { API_BASE_URL } from "../../../../../../../../../../services/api.service";
import S3ACTIONTYPE from "../../../../../../../../../../services/domain/administration/S3Action";

const TRANSLATION_BASE_PATH = "_MARKETPLACE._INSTRUCTOR_APPLY_MODAL";
const MAX_CHAR_LENGTH = 400;

type Props = {
	onCancel: () => void,
	isPublic?: boolean
}

const InstructorApplyModal = (props: Props) => {
	const { t: translate } = useTranslation();
	const { onCancel } = props;
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [job, setJob] = useState("");
	const [email, setEmail] = useState("");
	const [cv, setCV] = useState<any>();
	const [isSubmiting, setIsSubmiting] = useState(false);

	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const organizationId = currentUserInfo?.Organizations?.[0]?.OrganizationId;

	const onUploadingDone = () => {
		return (fileUploaded: any) => {
			setCV(fileUploaded);
		};
	};
	const onSend = async () => {
		try {
			const payload = {
				email,
				name,
				job,
				description,
				cv: cv && `${API_BASE_URL}/v1/api/s3/public-files/${encodeURIComponent("public-files/" + cv.name)}/${S3ACTIONTYPE.DOWNLOAD}?redirect=1`,
				filename: cv ? cv.name : "",
				...(organizationId && { organizationId })
			};
	
			setIsSubmiting(true);
			await new MarketplaceEmailService().sendApplyAsInstructorEmail(payload);
			message.success(translate(`${TRANSLATION_BASE_PATH}._APPLICATION_FORM._SUCCESS`));
		}
		catch (err) {
			message.error(translate(`${TRANSLATION_BASE_PATH}._APPLICATION_FORM._ERROR`));
		}

		setIsSubmiting(false);
		return onCancel();
	};
	return (
		<Modal
			title={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
			maskClosable={false}
			visible
			centered
			onCancel={onCancel}
			bodyStyle={{ paddingBottom: 0}}
			style={{ maxWidth: "450px" }}
			width="100%"
			onOk={() => onSend()}
			okText={translate(`${TRANSLATION_BASE_PATH}._SEND`)}
			okButtonProps={{
				disabled: !(name && email && job) || isSubmiting
			}}
			cancelText={translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
		>
			<Row>
				<Col span={24} className="mb-16">
					<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._NAME_SURNAME`)}</Text>
					<Col span={24}>
						<Input
							value={name}
							onChange={e => {
								setName(e.target.value);
							}}
						/>
					</Col>
				</Col>
				<Col span={24} className="mb-16">
					<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._EMAIL`)}</Text>
					<Col span={24}>
						<Input
							value={email}
							onChange={e => {
								setEmail(e.target.value);
							}}
						/>
					</Col>
				</Col>
				<Col span={24} className="mb-16">
					<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._JOB`)}</Text>
					<Col span={24}>
						<Input
							value={job}
							onChange={e => {
								setJob(e.target.value);
							}}
						/>
					</Col>
				</Col>
				<Col span={24} className="mb-24">
					<Space>
						<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}</Text>
						<Text fontSize="14" className="color-gray-7">{translate(`${TRANSLATION_BASE_PATH}._OPTIONAL`)}</Text>
					</Space>
					<Row>
						<TextArea
							maxLength={MAX_CHAR_LENGTH}
							autoSize={{ minRows: 5, maxRows: 7 }}
							value={description}
							onChange={e => setDescription(e.target.value)}
						/>
					</Row>
				</Col>
				<Col span={24} className="mb-24">
					<Space>
						<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._CV`)}</Text>
						<Text fontSize="14" className="color-gray-7">{translate(`${TRANSLATION_BASE_PATH}._OPTIONAL`)}</Text>
					</Space>
					<br/>
					<Uploader
						fileType={"PDF"}
						filePath="public-files"
						fileWithUniqueId={false}
						onUploadingDone={onUploadingDone()}
						isPublic={props.isPublic}
					/>
				</Col>
			</Row>
		</Modal>
	);
};

export default InstructorApplyModal;
