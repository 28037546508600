import ApiServiceData from "../api.service";

const relativeUrl = "/v1/api/meeting";

export default class MeetingService extends ApiServiceData {
	public async createMeeting(meetingDto: any): Promise<any> {
		return this.post(relativeUrl, meetingDto);
	}

	public async updateMeeting(meetingDto: any): Promise<any> {
		return this.update(`${relativeUrl}/${meetingDto.id}`, meetingDto);
	}

	public async deleteMeeting(meetingId: string): Promise<any> {
		return this.delete(`${relativeUrl}/${meetingId}`);
	}
}
