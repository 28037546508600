import { Col, Row } from "antd";
import React from "react";

interface SubjectsTagsProps {
	subjects: any;
	handleClick: (searchValue: object) => void;
	activeSubject?: string;
}

export const SubjectsSearch = function(props: SubjectsTagsProps) {
	const width = window.innerWidth;

	return <Row style={{
		borderRadius: "6px",
		backgroundColor: "#FFFFFF",
		marginTop: "10px",
		position: "relative",
		boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.08)",
		display: "flex",
		overflowY: "auto",
		maxHeight: "160px",
		padding: "10px"
	}}>
		{props.subjects?.map((item: any) => {
			return <Col
				xs={8}
				lg={4}
				style={{ marginBottom: "10px" }}>
				<>
					<a style={{
						fontFamily: "Inter",
						fontStyle: "normal",
						fontWeight: props.activeSubject === item.id ? "bold" : "normal",
						fontSize: "12px",
						position: width <= 500 ? "relative" : "absolute",
						color: "#4A5568",
						marginLeft: width <= 500 ? "0px" : "10px",
						marginTop: "10px"
					}}
					   onClick={(e: any) => {
						   e.preventDefault();
						   props.handleClick(item);
					   }}>
						{item.name}
					</a><br/>
				</>
			</Col>;
		})}
	</Row>;
};