import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.max(255, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_255`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		level: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		gradeId: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		subjectId: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))		
	});
