import { Col, Divider, Row } from "antd";
import React from "react";

import Text from "common/components/general/Text";
import { Link } from "react-router-dom";
import { SIGN_IN_PATH } from "../signIn";
import { REGISTER_PATH } from "../register";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/layout";

export const TERMS_OF_SERVICE_PATH = "terms-of-service";

const TRANSLATION_BASE_PATH = "_LOGIN._EMAIL_VERIFICATION";

export const TermsOfService = (props: any) => {
	const { t: translate } = useTranslation();

	const Component = () => {
		return (
			<>
				<Row>
					<Col span={24} className="flex__column">
						<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-gray-9 mb-24">
							Kushtet e shërbimit
						</Text>
						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Introduction
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							These Website Standard Terms and Conditions of Service written on this webpage shall manage
							your use of our website, Webiste akademi.al accessible at www.akademi.al
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							These Terms will be applied fully and affect to your use of this Website. By using this
							Website, you agreed to accept all terms and conditions written in here. You must not use
							this Website if you disagree with any of these Website Standard Terms and Conditions.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Intellectual Property Rights
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							Other than the content you own, under these Terms,&nbsp;akademi.al&nbsp;and/or its licensors
							own all the intellectual property rights and materials contained in this Website.
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							You are granted limited license only for purposes of viewing the material contained on this
							Website.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Restrictions
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							You are specifically restricted from all of the following:
						</Text>

						<ul>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									publishing any Website material in any other media;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									selling, sublicensing and/or otherwise commercializing any Website material;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									publicly performing and/or showing any Website material;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									using this Website in any way that is or may be damaging to this Website;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									using this Website in any way that impacts user access to this Website;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									using this Website contrary to applicable laws and regulations, or in any way may
									cause harm to the Website, or to any person or business entity;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									engaging in any data mining, data harvesting, data extracting or any other similar
									activity in relation to this Website;
								</Text>
							</li>
							<li>
								<Text fontSize="16" lineHeight="24" className="color-gray-7">
									using this Website to engage in any advertising or marketing.
								</Text>
							</li>
						</ul>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Certain areas of this Website are restricted from being access by you
							and&nbsp;akademi.al&nbsp;may further restrict access by you to any areas of this Website, at
							any time, in absolute discretion. Any user ID and password you may have for this Website are
							confidential and you must maintain confidentiality as well.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Your Content
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video
							text, images or other material you choose to display on this Website. By displaying Your
							Content, you grant&nbsp;akademi.al&nbsp;a non-exclusive, worldwide irrevocable, sub
							licensable license to use, reproduce, adapt, publish, translate and distribute it in any and
							all media.
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							Your Content must be your own and must not be invading any third-party’s
							rights.&nbsp;akademi.al reserves the right to remove any of Your Content from this Website
							at any time without notice.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							No warranties
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							This Website is provided “as is,” with all faults, and&nbsp;akademi.al&nbsp;express no
							representations or warranties, of any kind related to this Website or the materials
							contained on this Website. Also, nothing contained on this Website shall be interpreted as
							advising you.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Limitation of liability
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							In no event shall&nbsp;akademi.al, nor any of its officers, directors and employees, shall
							be held liable for anything arising out of or in any way connected with your use of this
							Website whether such liability is under contract. &nbsp;akademi.al, including its officers,
							directors and employees shall not be held liable for any indirect, consequential or special
							liability arising out of or in any way related to your use of this Website.
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							IndemnificationYou hereby indemnify to the fullest extent&nbsp;akademi.al&nbsp;from and
							against any and/or all liabilities, costs, demands, causes of action, damages and expenses
							arising in any way related to your breach of any of the provisions of these Terms.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Severability
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							If any provision of these Terms is found to be invalid under any applicable law, such
							provisions shall be deleted without affecting the remaining provisions herein.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Variation of Terms
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							akademi.al&nbsp;is permitted to revise these Terms at any time as it sees fit, and by using
							this Website you are expected to review these Terms on a regular basis.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Assignment
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-24">
							The&nbsp;akademi.al&nbsp;is allowed to assign, transfer, and subcontract its rights and/or
							obligations under these Terms without any notification. However, you are not allowed to
							assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
						</Text>

						<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
							Entire Agreement
						</Text>

						<Text fontSize="16" lineHeight="24" className="color-gray-7 mb-16">
							These Terms constitute the entire agreement between&nbsp;akademi.al&nbsp;and you in relation
							to your use of this Website, and supersede all prior agreements and understandings.
						</Text>
					</Col>
				</Row>
				<Row justify="center" className="pt-40">
					<Col>
						<Text fontSize="14" lineHeight="22">
							<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>
								{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_REGISTER`)}
							</Link>
							<Divider orientation="center" type="vertical" />
							<Link to={`/${SIGN_IN_PATH}`}>{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_SIGNIN`)}</Link>
						</Text>
					</Col>
				</Row>
			</>
		);
	};

	const hideCover = props.pageComponents?.loginCover?.status === 0;

	return (
		<>
			{hideCover ? (
				<Component />
			) : (
				<Layout {...props.pageComponents.loginCover?.specs}>
					<Component />
				</Layout>
			)}
		</>
	);
};
