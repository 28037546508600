import * as Yup from "yup";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		level: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		gradeId: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		subjectId: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		price: Yup.number()
			.nullable()
			.notRequired()
			.typeError(translate(`${TRANSLATION_BASE_PATH}._ONLY_NUMBERS`))
			.test("is-valid-number", translate(`${TRANSLATION_BASE_PATH}._ZERO_OR_POSITIVE_NUMBER_REQUIRED`), function (value) {
				return (
					value === undefined || value >= 0
				);
			}),
		numberOfStudents: Yup.number()
			.nullable()
			.notRequired()
			.typeError(translate(`${TRANSLATION_BASE_PATH}._ONLY_NUMBERS`))
			.when("virtualClassroomType", {
				is: (virtualClassroomType: string) => virtualClassroomType && virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Group,
				then: Yup.number()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._ONLY_NUMBERS`))
					.test("is-valid-number-of-students", translate(`${TRANSLATION_BASE_PATH}._NUMBER_OF_STUDENTS_FOR_GROUP_CLASSROOM`), function (value) {
						return (
							value >= 2 && value <= 100
						);
					})
			})
			
	});
