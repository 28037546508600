import { Button, Col, Modal, Row, Space } from "antd";
import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { formValidator } from "./ValidationSchema";
import { showMessage } from "common/utils/Notification";
import OAuth2Service from "services/authentication/oauth2.service";
import FormikDateRangePickerField from "common/components/dataEntry/formik/FormikDateRangePickerField";
import AnalyticsOrganizationService from "services/analytics/organization.service";

export const GenerateReportButton = (props: any) => {
	const [translate] = useTranslation();
	const [modalVisible, setModalVisible] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [initialForm, setInitialForm] = useState({
		dateSegment: null
	});

	const generateReport = (values: any) => {
		setSubmitting(true);
		return new AnalyticsOrganizationService()
			.getFinancialReport(values.dateSegment)
			.then(() => {
				return showMessage(
					translate(`${TRANSLATION_BASE_PATH}._REPORT_SENT`, {
						email: OAuth2Service.CurrentOrganization.ownerEmail
					})
				);
			})
			.finally(() => {
				setSubmitting(false);
				setModalVisible(true);
			});
	};

	const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._GEN_REPORT_BTN";

	const FormModal = () => {
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._FORM_TITLE`);
		const formBtnCancel = translate(`${TRANSLATION_BASE_PATH}._BTN._CANCEL`);
		const formBtnGenerate = translate(`${TRANSLATION_BASE_PATH}._BTN._GENERATE`);

		let submitFormHandler: () => Promise<any>;
		return (
			<Modal
				title={formTitle}
				visible={modalVisible}
				destroyOnClose={true}
				onCancel={() => setModalVisible(false)}
				footer={[
					<Button key="back" onClick={() => setModalVisible(false)}>
						{formBtnCancel}
					</Button>,
					<Button
						key="submit"
						type="primary"
						form="dateSegment"
						loading={submitting}
						htmlType="submit"
						disabled={false}
						onClick={() => submitFormHandler}
					>
						{formBtnGenerate}
					</Button>
				]}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={initialForm}
					onSubmit={generateReport}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} id="dateSegment" autoComplete="off">
								<Row gutter={24}>
									<Col span="24">
										<FormikDateRangePickerField
											name="dateSegment"
											label={translate(`${TRANSLATION_BASE_PATH}._DATE`)}
										/>
									</Col>
								</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	};

	return (
		<>
			{FormModal()}
			<Button onClick={() => setModalVisible(true)}>
				<Space>
					<Text fontSize="14" lineHeight="22">
						<Icon type="ri-download-line"></Icon>
					</Text>
					{translate(`${TRANSLATION_BASE_PATH}._FORM_TITLE`)}
				</Space>
			</Button>
		</>
	);
};
