import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import { FORGET_PASSWORD_PATH } from "../forgetPassword";
import OAuth2Service, { RECAPTCHA_KEY } from "services/authentication/oauth2.service";
import AccountEmailService from "services/email/account.email.service";
import { SIGN_IN_PATH } from "../signIn";
import { SUPPORT_EMAIL } from "common/utils/Constants";
import { Layout } from "../../components/layout";

type VerificationStatus = "Sent" | "Resent";

export const FORGET_PASSWORD_EMAIL_VERIFICATION_PATH = "forget-password-email-verification";
const TRANSLATION_BASE_PATH = "_LOGIN._FORGET_PASWORD_EMAIL_VERIFICATION";

const getContactSupportContent = (status: VerificationStatus, translate: (key: string) => string) => {
	if (status === "Resent") {
		return (
			<Row justify="center" className="pt-24">
				<Col>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._PROBLEMS_WITH_FORGET_PASSWORD_EMAIL`)}
					</Text>
					<br />
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._CONTACT_US`)}{" "}
						<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
					</Text>
				</Col>
			</Row>
		);
	}
	return null;
};

const getTitleContentBasedOnStatus = (status: VerificationStatus, translate: (key: string) => string) => {
	switch (status) {
		case "Sent":
			return (
				<>
					{translate(`${TRANSLATION_BASE_PATH}._FORGET_PASSWORD_EMAIL_SENT`)}{" "}
					{translate(`${TRANSLATION_BASE_PATH}._SENT`)} {translate(`${TRANSLATION_BASE_PATH}._SUCCESS`)}
				</>
			);
		case "Resent":
			return (
				<>
					{translate(`${TRANSLATION_BASE_PATH}._FORGET_PASSWORD_EMAIL_SENT`)}{" "}
					<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-blue-6">
						{translate(`${TRANSLATION_BASE_PATH}._RESENT`)}
					</Text>{" "}
					{translate(`${TRANSLATION_BASE_PATH}._SUCCESS`)}
				</>
			);
	}
};

class ForgetPasswordEmailVerificationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			submitting: false,
			status: "Sent"
		};

		this.Component = this.Component.bind(this);
	}

	recaptchaRef: any = React.createRef();

	resendVerificationEmail = async () => {
		const {
			t: translate,
			history: { location }
		} = this.props;
		this.setState({
			submitting: true,
			hasError: true,
			errorMessage: ""
		});

		await OAuth2Service.loginClient()
			.then(async () => {
				return await this.sentForgetPasswordEmail(location.state.email);
			})
			.catch(error => {
				const errorStatus = error?.status ?? "500";
				return this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	sentForgetPasswordEmail = async (email: string) => {
		const { t: translate, history } = this.props;

		const recaptchaValue = await this.recaptchaRef.current.executeAsync();

		await new AccountEmailService()
			.sentForgetPasswordEmail(email, recaptchaValue)
			.then(() => {
				this.recaptchaRef.current.reset();
				this.setState({
					submitting: false,
					status: "Resent"
				});
				history.push(`/${SIGN_IN_PATH}/${FORGET_PASSWORD_EMAIL_VERIFICATION_PATH}`, {
					email: email
				});
				return;
			})
			.catch(error => {
				this.recaptchaRef.current.reset();
				const errorStatus = error?.status ?? "500";
				return this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	Component = () => {
		const {
			t: translate,
			history: { location }
		} = this.props;

		return (
			<Row>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
					<Row>
						<Col span={24}>
							<Icon type="ri-mail-send-line" className="email__verification__icon" />
						</Col>
					</Row>
					<Row className="pt-32">
						<Col span={24}>
							<Text fontSize="30" lineHeight="38" wheight="semibold">
								{getTitleContentBasedOnStatus(this.state.status, translate)}
							</Text>
						</Col>
					</Row>
					<Row className="pt-24">
						<Col span={24}>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._EMAIL_SENT_AT`)}{" "}
								<Text fontSize="14" lineHeight="22" wheight="semibold">
									{location.state.email}
								</Text>
								. {translate(`${TRANSLATION_BASE_PATH}._PLEASE_VERIFY_EMAIL`)}
							</Text>
						</Col>
					</Row>
					<Row className="pt-24">
						<Col span={24}>
							<Button
								type="primary"
								htmlType="submit"
								size="large"
								className="full_width_button"
								loading={this.state.submitting}
								onClick={this.resendVerificationEmail}
							>
								{translate(`${TRANSLATION_BASE_PATH}._RESENT_EMAIL`)}
							</Button>
						</Col>
					</Row>
					{getContactSupportContent(this.state.status, translate)}

					<Row justify="center" className="pt-40">
						<Col>
							<Text fontSize="14" lineHeight="22">
								<Link to={`/${SIGN_IN_PATH}/${FORGET_PASSWORD_PATH}`}>
									{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_FORGET_PASSWORD`)}
								</Link>
							</Text>
						</Col>
					</Row>
					<Row justify="center">
						<Col>
							<ReCAPTCHA ref={this.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<>
				{hideCover ? (
					this.Component()
				) : (
					<Layout {...this.props.pageComponents.loginCover?.specs}>{this.Component()}</Layout>
				)}
			</>
		);
	}
}

const ForgetPasswordEmailVerification = withTranslation()(ForgetPasswordEmailVerificationComponent);
export default ForgetPasswordEmailVerification;
