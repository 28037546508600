import { Calendar, Col, Empty, Row, Select, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/sq";

import Text from "common/components/general/Text";
import { sqLocale } from "../../../../components/calendar/sqLocale";
import LessonService from "services/administration/lesson.service";
import LessonDto from "dtos/administration/lesson.dto";
import { LesonOverviewByType } from "./LessonOverviewByType";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._CALENDAR_WITH_LESSONS";

export const CalendarWithLessons = (props: any) => {
	const { t: translate } = useTranslation();
	const [loadingData, setLoadingData] = useState<boolean>(false);
	const [selectedDate, setSelectedDate] = useState(moment());
	const [lessons, setLessons] = useState<any[]>([]);

	const loadMonthlyData = () => {
		const startOfMonth = selectedDate
			.clone()
			.startOf("month")
			.format("YYYY-MM-DD hh:mm");
		const endOfMonth = selectedDate
			.clone()
			.endOf("month")
			.format("YYYY-MM-DD hh:mm");
		return new LessonService()
			.getCalendarLessonsByDateSegmentAndClassroomId(startOfMonth, endOfMonth, "ALL")
			.then((result: LessonDto[]) => {
				setLessons(result);
				return setLoadingData(false);
			});
	};

	useEffect(() => {
		const fetachData = async () => {
			return loadMonthlyData().then(() => {
				return setLoadingData(false);
			});
		};
		moment.locale("sq");
		setLoadingData(true);
		fetachData();
	}, []);

	const getMonthOptions = () => {
		const start = 0;
		const end = 12;
		const monthOptions = [];

		const current = moment();
		const localeData = current.localeData();
		const months = [];
		for (let i = 0; i < 12; i++) {
			current.month(i);
			months.push(localeData.months(current));
		}

		for (let index = start; index < end; index++) {
			monthOptions.push(
				<Select.Option className="month-item" key={`${index}`} value={`${index}`}>
					{months[index]}
				</Select.Option>
			);
		}

		return monthOptions;
	};

	const headerRender = ({ value, onChange }: any) => {
		const monthOptions = getMonthOptions();
		const month = value.month();
		return (
			<Select
				size="middle"
				dropdownMatchSelectWidth={false}
				value={String(month)}
				onChange={async selectedMonth => {
					const newValue = value.month(parseInt(selectedMonth, 10));

					setLoadingData(true);
					setSelectedDate(value);
					await loadMonthlyData();
					onChange(newValue);
				}}
			>
				{monthOptions}
			</Select>
		);
	};

	const dateCellRender = (value: any) => {
		const lessonsByDate = lessons.filter((item: any) => {
			return value.format("MM/DD/YYYY") === moment(item.endDate).format("MM/DD/YYYY");
		});
		return lessonsByDate.length > 0 ? <div className="badge__dot"></div> : null;
	};

	const selectedDateItems = () => {
		return lessons
			.filter((item: any) => {
				return selectedDate.format("MM/DD/YYYY") === moment(item.endDate).format("MM/DD/YYYY");
			})
			.map((item: any) => {
				return {
					id: item.id,
					name: item.name,
					startDate: item.startDate,
					endDate: item.endDate,
					type: item.lessonType,
					subjectPlan:
						item.SubjectPlanTrees &&
						item.SubjectPlanTrees.length > 0 &&
						item.SubjectPlanTrees[0]?.SubjectPlan,
					classroom:
						item.SubjectPlanTrees &&
						item.SubjectPlanTrees.length > 0 &&
						item.SubjectPlanTrees[0]?.SubjectPlan?.Classroom
				};
			});
	};

	const getEmptyContent = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION`)}
			/>
		);
	};

	const getLessonItemsContent = () => {
		return (
			<Space direction="vertical" size={8} className="full__width">
				{selectedDateItems().map((item: any, index: number) => (
					<LesonOverviewByType key={index} item={item} />
				))}
			</Space>
		);
	};

	return (
		<div className="calendar__with_lessons_wrapper">
			<Row>
				<Col xs={24} sm={24} md={12} lg={24} xl={24} xxl={24}>
					<Row className="mb-4">
						<Col span={24}>
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
								{props.title ? props.title : translate(`${TRANSLATION_BASE_PATH}._LESSONS_BY_DATE_LABEL`)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-16">
						<Col span={24}>
							<Text fontSize="14" lineHeight="22" className="color-gray-9">
								{selectedDate.format("dddd, D MMMM YYYY")}
							</Text>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Calendar
								fullscreen={false}
								locale={sqLocale(translate)}
								headerRender={headerRender}
								onChange={date => {
									setSelectedDate(date);
								}}
								dateCellRender={value => dateCellRender(value)}
								className="calendar__component"
							/>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={12} lg={24} xl={24} xxl={24} className="calendar__lessons_wrapper">
					<Skeleton active loading={loadingData}>
						{selectedDateItems().length > 0 ? getLessonItemsContent() : getEmptyContent()}
					</Skeleton>
				</Col>
			</Row>
		</div>
	);
};
