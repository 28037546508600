import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Skeleton from "common/components/feedback/Skeleton";
import Organizations from "./scenes/list";
import Teacher, { TEACHER_PATH } from "./scenes/teacher/index";
import Organization from "./scenes/organization";
import CustomizePage from "./scenes/customize";

class OrganizationIndex extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		this.setState({
			loading: false
		});
	}

	render() {
		const path = this.props.match.path;
		return (
			<Skeleton active loading={this.state.loading}>
				{!this.state.loading ? (
					<Switch>
						<Route path={`${path}`} exact component={Organizations} />
						<Route path={`${path}/:id`} exact render={(props) => <Organization {...props}/>} />
						<Route path={`${path}/:organizationId/customize`} render={(props) => <CustomizePage {...props}/>} />
						<Route path={`${path}/:organizationId/:organizationName/${TEACHER_PATH}/:teacherId/:teacherName`}
							   exact render={(props) => <Teacher {...props} />} />
					</Switch>
				) : null}
			</Skeleton>
		);
	}
}

export default OrganizationIndex;
