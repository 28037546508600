import React, { ReactNode } from "react";
import { DatePicker } from "antd";
import { useField, useFormikContext } from "formik";
import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import Error from "./FormItemError";
import Label from "../components/Label";
import { FieldProps } from "./FieldProps";
import moment from "moment";
import Text from "../../general/Text";

const { RangePicker } = DatePicker;

type FormikFieldProps = Omit<FieldProps, "placeholder">;

interface FormikDateRangePickerProps extends FormikFieldProps {
	defaultValue?: RangeValue<Moment>;
	value?: RangeValue<Moment>;
	disabled?: boolean;
	disabledDate?: (current: moment.Moment | null) => boolean;
	noStyles?: boolean;
	format?: string | string[];
	placeholder?: [string, string];
	labelInfo?: string | ReactNode;
}

const FormikDateRangePickerField: React.FC<FormikDateRangePickerProps> = props => {
	const [field, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	const onDateChange = (dates: any, dateStrings: [string, string]) => {
		context.setFieldValue(props.name, dateStrings);
	};

	const onBlur = (): void => {
		context.setFieldTouched(props.name);
	};

	return (
		<div>
			<div>
				{props.label && (
					<Label htmlFor={id} className="hand-on-hover">
						{props.label}{" "}
						{props.labelInfo ? (
							<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
								{props.labelInfo}
							</Text>
						) : null}
					</Label>
				)}
				<RangePicker
					{...props}
					id={id}
					onChange={onDateChange}
					className={` ${props.className}`}
					onBlur={onBlur}
				/>
			</div>
			{error}
		</div>
	);
};

export default FormikDateRangePickerField;
