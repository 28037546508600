import { Action, action, Computed, computed } from "easy-peasy";
import _ from "lodash";
import { Subject } from "./subject.model";

export interface SubjectsModel {
	items: Subject[];
	menuItems: Subject[];
	allItems: Subject[];
	activeFilters: any[];
	totalNewItems: number;
	totalMyItems: number;
	totalAllItems: number;
	totalCoursesOnPublicMarketPlace: any,
	load: Action<SubjectsModel, Subject[]>;
	updateFilters: Action<SubjectsModel, any>;
	updateTotalNewItems: Action<SubjectsModel, any>;
	updateTotalMyItems: Action<SubjectsModel, any>;
	updateTotalAllItems: Action<SubjectsModel, any>;
	updateTotalCoursesOnPublicMarketPlace: Action<SubjectsModel, any>;
	totalItems: Computed<SubjectsModel, number>;
}

const filterClassrooms = (state: any, filters: any[], subjects: any[]) => {
	const items = _.cloneDeep(subjects.filter((x: any) => !x.externalUrl));

	if (filters.length === 0) {
		state.items = items;
		return;
	}

	items.forEach((subject: any, index: number, arr: any[]) => {
		const filteredClassrooms: any[] = [];
		subject.classrooms.forEach((classroom: any) => {
			if (classroom.Tags && classroom.Tags.length > 0
				&& filters.map((x: any) => { return x.id;}).every((tagId: any) =>
					classroom.Tags.map((tag: any) => tag.TagId).includes(tagId))) {
				filteredClassrooms.push(classroom);
			}
		});
		arr[index].classrooms = filteredClassrooms;
	});

	state.items = items;
};

const subjects: SubjectsModel = {
	items: [],
	menuItems: [],
	allItems: [],
	activeFilters: [],
	totalNewItems: 0,
	totalMyItems: 0,
	totalAllItems: 0,
	totalCoursesOnPublicMarketPlace: {},
	load: action((state, payload) => {
		state.items = [...payload];
		state.allItems = [...payload];
		state.menuItems = [...payload];
	}),
	updateFilters: action((state, payload) => {
		state.activeFilters = payload.filters;
		filterClassrooms(state, payload.filters, payload.items);
	}),
	updateTotalNewItems: action((state, number) => {
		state.totalNewItems = number;
	}),
	updateTotalMyItems: action((state, number) => {
		state.totalMyItems = number;
	}),
	updateTotalAllItems: action((state, number) => {
		state.totalAllItems = number;
	}),
	updateTotalCoursesOnPublicMarketPlace: action((state, { group, number }) => {
		state.totalCoursesOnPublicMarketPlace[group] = number;
	}),
	totalItems: computed(state => state.totalNewItems + state.totalMyItems + state.totalAllItems)
};

export default subjects;
