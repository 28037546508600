import React from "react";
import { Modal, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

interface InfoModalProps {
	visible: boolean;
	handleClose: () => void;
}

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._MEETING";

export const InfoModal = (props: InfoModalProps) => {
	const { t: translate } = useTranslation();
	return (
		<Modal
			title={translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_TITLE`)}
			visible={props.visible}
			onCancel={props.handleClose}
			footer={
				<Button onClick={props.handleClose} type="primary" htmlType="button">
					{translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_OK_BUTTON`)}
				</Button>
			}
			className="zoom__info_modal"
		>
			<Icon type="ri-live-fill" className="color-blue-6 zoom__info_modal_icon" />
			<Row className="mt-24">
				<Col span={24}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_DESCRIPTION_1`)}
					</Text>
					<br />
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_DESCRIPTION_2`)}
					</Text>
				</Col>
			</Row>
			<Row className="mt-24">
				<Col span={24}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_DESCRIPTION_3`)}
					</Text>
				</Col>
			</Row>
			<Row className="mt-24">
				<Col span={24}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_DESCRIPTION_4`)}
					</Text>
				</Col>
			</Row>
			<Row className="mt-24">
				<Col span={24}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._INFO_MODAL_DESCRIPTION_5`)}
					</Text>
				</Col>
			</Row>
		</Modal>
	);
};
