import React, { useState,useEffect } from "react";
import { Button, Col, Modal, Row, Space, Tooltip } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { showError, showMessage } from "common/utils/Notification";
import { formValidator } from "./JoinByClassroomCodeModalValidationSchema";
import OrganizationService from "services/administration/organization.service";
import ClassroomService from "services/administration/classroom.service";
import EnrollmentDto from "dtos/administration/enrollment.dto";
import EnrollmentService from "services/administration/enrollment.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_SELECT_ORGANIZATION._CHOOSE_AS_STUDENT._JOIN_CLASSROOM";

interface JoinByClassroomCodeModalProps {
	visible: boolean;
	onCancel: () => void;
	onSuccessfulJoin: () => void;
	joinedOrganizations: any[];
	renderOnMount: () => void;
}

export const JoinByClassroomCodeModal = (props: JoinByClassroomCodeModalProps) => {
	const { t: translate } = useTranslation();
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	let bindSubmitFormHandler: any;
	const [isinsuperorganization, setIsOrganization] = useState<boolean>(false);

	useEffect(() => {
		checkIfIsRegisterInSuperOrganization()
	},[props.joinedOrganizations])

	const checkIfIsRegisterInSuperOrganization = () =>{
		const isSet = props.joinedOrganizations.find((item:any) => item.superOrganization === true)
		const roles = isSet.UserRoles.map((item:any)=> (item.Role)).map((i:any)=>(i.code))
		if(roles.includes("_STUDENT")){
			setIsOrganization(true)
		}
	}

	const getModalFooter = () => {
		return (
			<div className="full__width flex__row_center_reverse">
				<Space direction="horizontal" size={8}>
					<Button type="default" onClick={props.onCancel}>
						{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
					</Button>
					<Button
						type="primary"
						onClick={() => bindSubmitFormHandler()}
						disabled={!isFormValid}
						loading={submitting}
					>
						{translate(`${TRANSLATION_BASE_PATH}._CONTINUE`)}
					</Button>
				</Space>
			</div>
		);
	};

	const onSubmit = async (values: any) => {
		setSubmitting(true);
		const currentOrg: any = null;
		let enrollmentToClass: any = null;
		let classroomDetails: any = null;
		let classroomOrgCode: string | null = null;
		let classroomOrgId: string | null = null;
		const organizationsAsTeacher = props.joinedOrganizations.filter(org =>
			org.UserRoles?.some(
				(userRole: any) => userRole.Role.code === Role.Teacher || userRole.Role.code === Role.Owner
			)
		);
		const organizationsAsStudent = props.joinedOrganizations.filter(org =>
			org.UserRoles?.some((userRole: any) => userRole.Role.code === Role.Student)
		);
		await new ClassroomService()
			.getClassroomByCode(values.virtualClassCode)
			.then((classroom: any) => {
				if (!classroom) {
					return showError(translate(`${TRANSLATION_BASE_PATH}._INVALID_CODE`));
				} else if (organizationsAsTeacher.some(org => org.id === classroom?.Organization?.id)) {
					return showError(translate(`${TRANSLATION_BASE_PATH}._ALREADY_JOINED_AS_TEACHER`));
				}
				classroomDetails = classroom;
				classroomOrgCode = classroom?.Organization?.code;
				classroomOrgId = classroom?.Organization?.id;
				return;
			})
			.finally(() => setSubmitting(false));

		if (classroomDetails) {
			setSubmitting(true);
			const enrollment: EnrollmentDto = new EnrollmentDto({
				UserId: OAuth2Service.CurrentUser.UserId,
				ClassroomId: classroomDetails.id,
				status: "ACTIVE",
				enrolledAt: new Date()
			});
			return new EnrollmentService()
				.createEnrollment(enrollment)
				.then((enrollment: any) => {
					if (!enrollment) return showError(translate(`${TRANSLATION_BASE_PATH}._INVALID_CODE`));
					enrollmentToClass = { status: enrollment?.status, ClassroomId: enrollment?.ClassroomId };
					if (organizationsAsStudent.some(org => org.id === classroomOrgId)) return currentOrg;
					if ((!currentOrg && classroomOrgCode) || (classroomOrgCode && currentOrg.code !== classroomOrgCode))
						return new OrganizationService().joinOrganizationByRole(classroomOrgCode, Role.Student);
					return currentOrg;
				})
				.then(() => {
					if (!enrollmentToClass) return;
					if (enrollmentToClass.status === "ACTIVE") return props.onSuccessfulJoin();
					if (enrollmentToClass.status === "PENDING")
						return showMessage(translate(`${TRANSLATION_BASE_PATH}._REQUEST_PENDING`));
					if (enrollmentToClass.status === "BLOCKED")
						return showError(translate(`${TRANSLATION_BASE_PATH}._BLOCKED_RETRY`));
					return;
				})
				.finally(() => setSubmitting(false));
		}
	};

	const onSubmitAsDefaultOrganization = async () => {
		await new OrganizationService().RegisterAsStudentByRole(Role.Student)
		.then(() =>{
			showMessage(translate(`${TRANSLATION_BASE_PATH}._REGISTER_WITH_SUKES`))
			props.renderOnMount()
			props.onCancel()
		})
	};

	return (
		<Modal
			title={translate(`${TRANSLATION_BASE_PATH}._REGISTER_AS_STUDENT`)}
			closable
			visible={props.visible}
			onCancel={props.onCancel}
			footer={getModalFooter()}
			destroyOnClose
		>
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={{
					virtualClassCode: ""
				}}
				onSubmit={onSubmit}
				validate={values => {
					setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					bindSubmitFormHandler = submitForm;
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row>
								<Col span={24}>
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._REGISTER_AS_STUDENT_DESCRIPTION`)}
									</Text>
								</Col>
							</Row>
							<Row className="mt-16">
								<Col span={24}>
									<Input
										name="virtualClassCode"
										size="middle"
										placeholder="psh 12345678"
										label={translate(`${TRANSLATION_BASE_PATH}._VIRTUAL_CLASSROOM_CODE`)}
										labelInfo={
											<Tooltip
												title={translate(`${TRANSLATION_BASE_PATH}._CLASSROOM_CODE_TOOLTIP`)}
												placement="topLeft"
											>
												<span>
													<Icon
														type="ri-information-line"
														fontSize="20"
														className="color-blue-6"
													/>
												</span>
											</Tooltip>
										}
									/>
								</Col>
							</Row>
							<Row className="mt-16">
								<Col span={24}>
									{!isinsuperorganization && <Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._OR_REGISTER_AT_DEFAULT_ORGANIZATION`)} <a onClick={()=> onSubmitAsDefaultOrganization()}>
											{translate(`${TRANSLATION_BASE_PATH}._HERE`)}
										</a>!
									</Text>}
								</Col>
							</Row>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};
