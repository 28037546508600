import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Row, Skeleton } from "antd";
import _ from "lodash";

import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import ClassSlider, { ClassItem } from "../../components/classSlider/ClassSlider";
import img1 from "assets/images/main-classes-img1.svg";
import EnrollmentDto from "dtos/administration/enrollment.dto";
import ClassroomDto from "dtos/administration/classroom.dto";
import { COURSE_BUILDER_PATH } from "scenes/courseBuilder";
import { SUBJECTS_PATH } from "scenes/subjects";
import { COURSE_PATH } from "scenes/course";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import { previewFile } from "../../../courseBuilder/utils/FileUtils";
import OAuth2Service from "services/authentication/oauth2.service";
import EnrollmentService from "services/administration/enrollment.service";
import { TutoringModal } from "./components/tutoringModal";
import { Role } from "services/domain/login/Role";
import JoinCreateClassModal from "../../components/joinCreateClassModal/JoinCreateClassModal";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";
import { boolVal, getComponentProps, isComponentVisible } from "../../../../common/utils/Design";
import { MainLayout } from "scenes/main/components/layout/Layout";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";
const teacherRoleID = Role.Teacher;
const studentRoleID = Role.Student;

class StudentCoursesComponent extends Component<any, any> {
	private _isMounted: boolean;

	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			classItems: null,
			asynchClassItems: null,
			formInitialValues: {}
		};
		this._isMounted = false;
	}

	getPhotoUrls = async (items: any): Promise<any> => {
		return (
			(await Promise.all(
				items.map(async function(classroom: any) {
					const image = (classroom.File && (await previewFile(classroom.File))) || null;
					return { ...classroom, filePath: image && image.url };
				})
			).then(classroom => {
				return classroom;
			})) || []
		);
	};

	getVirutalClassItemsForTeacher = async (data: ClassroomDto[]): Promise<ClassItem[]> => {
		if (!data) return [];
		const teacherCourseBuilderPath = `/${COURSE_BUILDER_PATH}/${teacherRoleID.toLowerCase()}/`;
		const individualCourseBuilderPath = `/${COURSE_BUILDER_PATH}/individual/`;
		const studentCoursePath = `/${COURSE_PATH}/_student/`;

		const items = data
			.filter((x: ClassroomDto) => {
				return x.isAsync === false;
			})
			.map((x: ClassroomDto) => {
				let pathToClassroom = "";
				if (x.createdBy !== OAuth2Service.CurrentUser.UserId) {
					pathToClassroom = studentCoursePath + x.id;
				} else if (
					x.virtualClassroomType &&
					x.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Individual
				) {
					pathToClassroom = individualCourseBuilderPath + x.id;
				} else {
					pathToClassroom = teacherCourseBuilderPath + x.id;
				}
				return {
					id: x.id,
					showAsTeacher: x.createdBy === OAuth2Service.CurrentUser.UserId,
					name: x.name,
					teacherName: x.UserCreatedBy?.firstName + " " + x.UserCreatedBy?.lastName,
					File: x.File,
					isAsync: false,
					pathToClassroom: pathToClassroom,
					organizationName: x.Organization?.name,
					totalEnrollments: x.TotalEnrollments,
					classroomStatus: x.status,
					virtualClassroomType: x.virtualClassroomType
				};
			});

		return this.getPhotoUrls(items);
	};

	getAsynchClassItemsForTeacher = async (data: ClassroomDto[]): Promise<ClassItem[]> => {
		if (!data) return [];
		const teacherCourseBuilderPath = `/${COURSE_BUILDER_PATH}/asynch/`;

		const items = data
			.filter((x: ClassroomDto) => {
				return x.isAsync === true;
			})
			.map((x: ClassroomDto) => {
				return {
					id: x.id,
					showAsTeacher: true,
					name: x.name,
					teacherName: x.UserCreatedBy?.firstName + " " + x.UserCreatedBy?.lastName,
					File: x.File,
					isAsync: true,
					pathToClassroom: teacherCourseBuilderPath + x.id,
					status: x.status,
					organizationName: x.Organization?.name,
					totalEnrollments: x.TotalEnrollments
				};
			});

		return this.getPhotoUrls(items);
	};

	getVirutalClassItemsForStudent = async (data: any[]): Promise<ClassItem[]> => {
		const formatDate = (input: string) => {
			let result = "";
			try {
				result = input
					.split("T")[0]
					.split("-")
					.reverse()
					.join(".");
			} catch {
				return input;
			}
			return result;
		};

		const items = _.orderBy(
			data
				.filter((x: EnrollmentDto) => {
					return (
						(x.status === "ACTIVE" || x.status === "PENDING") &&
						x.Classroom?.isAsync === false &&
						x.UserId === OAuth2Service.CurrentUser.UserId
					);
				})
				.map((x: EnrollmentDto) => {
					return {
						id: x.ClassroomId,
						showAsTeacher: false,
						name: x.Classroom?.name,
						teacherName: x.Classroom?.UserCreatedBy?.firstName + " " + x.Classroom?.UserCreatedBy?.lastName,
						lecturerName: "lecturer",
						File: x.Classroom?.File,
						isAsync: false,
						pathToClassroom: `/${COURSE_PATH}/_student/${x.ClassroomId}`,
						status: x.status,
						requestDate: formatDate(x.enrolledAt.toString()),
						organizationName: x.Classroom?.Organization?.name,
						totalEnrollments: x.Classroom?.TotalEnrollments
					};
				}),
			"status"
		);

		return this.getPhotoUrls(items);
	};

	getAsyncClassItemsForCurrentUser = async (data: EnrollmentDto[]): Promise<ClassItem[]> => {
		const items = data
			.filter((x: EnrollmentDto) => {
				return (
					x.status === "ACTIVE" &&
					x.Classroom?.isAsync === true &&
					x.UserId === OAuth2Service.CurrentUser.UserId
				);
			})
			.map((x: EnrollmentDto) => {
				return {
					id: x.ClassroomId,
					showAsTeacher: false,
					name: x.Classroom?.name,
					lecturerName: "lecturer",
					File: x.Classroom?.File,
					isAsync: true,
					pathToClassroom: `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/${x.ClassroomId}`,
					status: x.status,
					organizationName: x.Classroom?.Organization?.name,
					totalEnrollments: x.Classroom?.TotalEnrollments
				};
			});

		return this.getPhotoUrls(items);
	};

	componentDidMount() {
		this._isMounted = true;

		this.setState({
			loading: true
		});

		const requests: any[] = [new EnrollmentService().getAllEnrollments()];

		return Promise.all(requests)
			.then(([allEnrollments]) => {
				return Promise.all([
					this.getVirutalClassItemsForStudent(allEnrollments as EnrollmentDto[]),
					Promise.resolve([]),
					this.getAsyncClassItemsForCurrentUser(allEnrollments as EnrollmentDto[])
				]);
			})
			.then(([virtualClass, myAsynchClassrooms, asyncClass]) => {
				return (
					this._isMounted &&
					this.setState({
						classItems: virtualClass,
						myAsynchClassrooms: myAsynchClassrooms,
						asynchClassItems: asyncClass
					})
				);
			})
			.finally(() => {
				this._isMounted && this.setState({ loading: false });
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	toggleTutoringModal = () => {
		this.setState({ isTutoringModalVisible: !this.state.isTutoringModalVisible });
	};

	getVirtualClassroomsContent() {
		const { t: translate } = this.props;
		const noDataTextVC = translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESC._VC.${studentRoleID}`);
		if (this.state.classItems) {
			const title = translate(`${TRANSLATION_BASE_PATH}._VC_TITLE`);
			return (
				<ClassSlider
					title={title}
					items={this.state.classItems}
					defaultImg={img1}
					noDataDesc={noDataTextVC}
					emptyState={null}
				/>
			);
		}
		return null;
	}

	render() {
		const { t: translate } = this.props;

		const noDataTextAC = translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESC._AC.${studentRoleID}`);
		const subTitle = translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`);
		const titleSliderAC = translate(`${TRANSLATION_BASE_PATH}._AC_TITLE`);
		const tutoringButtonTitle = translate(`${TRANSLATION_BASE_PATH}._BTN_TUTORING_STUDENT`);
		const sideLayoutProps = getComponentProps(this.props.pageComponents, "sideLayout");
		const headerProps = getComponentProps(this.props.pageComponents, "header");
		const showHeader: boolean = isComponentVisible(this.props.pageComponents, "header");

		return (
			<MainLayout
				showMarketPlace={boolVal(sideLayoutProps, "showExploreCourses", true)}
				showAddCourse={boolVal(sideLayoutProps, "joinLiveCourse", true)}
			>
				{showHeader && <SectionTitle
					subtitle={subTitle}
					tutoringButtonTitle={boolVal(headerProps, "showPrivateCourse", true) ? tutoringButtonTitle : null}
					onTutoringBtnClick={boolVal(headerProps, "showPrivateCourse", true) ? this.toggleTutoringModal : undefined}
					joinCreateClassModal={boolVal(headerProps, "joinLiveCourse", true) ? <JoinCreateClassModal history={this.props.history} /> : null}
				/>}
				{this.state.isTutoringModalVisible && <TutoringModal toggleTutoringModal={this.toggleTutoringModal} />}
				<Skeleton active loading={this.state.loading}>
					{this.getVirtualClassroomsContent()}
				</Skeleton>
				<Skeleton active loading={this.state.loading}>
					{this.state.asynchClassItems && !this.state.loading && (
						<ClassSlider
							title={titleSliderAC}
							items={this.state.asynchClassItems}
							defaultImg={img1}
							noDataDesc={noDataTextAC}
						/>
					)}
				</Skeleton>
				<Row className="mb-24">
					<Col span={24}></Col>
				</Row>
			</MainLayout>
		);
	}
}

const StudentCourses = withTranslation()(StudentCoursesComponent);
export default StudentCourses;
