import React from "react";
import { Card, Col, Row, Space } from "antd";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";

export const IndividualLiveCourseBanner = () => {
	return (
		<Row>
			<Col span="24">
				<Card
					className="individual__live_course_card_banner"
					cover={
						<div className="introduction__cover_content">
							<Image src={require("assets/images/my-asynch-courses-empty-state.svg")} />
						</div>
					}
					bordered={false}
				>
					<Space direction="vertical" size={24}>
						<Space direction="vertical" size={8} className="full__width text__align_center">
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
								Nëse po kërkoni të forconi njohurite apo të rrisni notat, jeni në vendin e duhur.
							</Text>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								Studioni së bashku me ekspertët më të mirë që do t'ju ndihmojnë të arrini qëllimet tuaja
								akademike.
							</Text>
						</Space>
						<Space direction="vertical" size={8}>
							<Space direction="horizontal" size={4}>
								<Icon type="ri-checkbox-circle-line" className="color-lime-6" fontSize="20" />
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									Ekspertë të kualifkuar
								</Text>
							</Space>
							<Space direction="horizontal" size={4}>
								<Icon type="ri-checkbox-circle-line" className="color-lime-6" fontSize="20" />
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									Shumëllojshmeri lëndesh
								</Text>
							</Space>
							<Space direction="horizontal" size={4}>
								<Icon type="ri-checkbox-circle-line" className="color-lime-6" fontSize="20" />
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									Fleksibilitet në orare
								</Text>
							</Space>
						</Space>
						<Row>
							<Col span={24} className="text__align_center">
								<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
									Mësoni më shume
								</a>
							</Col>
						</Row>
					</Space>
				</Card>
			</Col>
		</Row>
	);
};
