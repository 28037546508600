import React from "react";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

interface CirclularIconProps {
	type: string;
	fontSize?: any;
	lineHeight?: any;
	noBackground?: any;
}

const iconStyle = {
	padding: "11px",
	borderRadius: "100px",
	border: "thin solid #9ECAFF"
};

export const CircularIcon = (props: CirclularIconProps) => {
	return (
		<Text fontSize={props.fontSize ?? "20"} lineHeight={props.lineHeight ?? "24"}>
			<Icon
				type={props.type}
				className={`color-blue-6 ${props.noBackground ? "" : "background-color-blue-1"}`}
				style={iconStyle}
			/>
		</Text>
	);
};
