import React from "react";

import { InputNumber as AntInputNumber } from "antd";
import { InputNumberProps as AntInputNumberProps } from "antd/lib/input-number";

export type InputNumberProps = AntInputNumberProps;

const InputNumber: React.FC<InputNumberProps> = props => {
	return <AntInputNumber {...props} />;
};

export default InputNumber;
