import React, { ReactNode } from "react";
import { Space, Row, Card, Col, Empty } from "antd";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import { UserAvatar } from "common/components/general/UserAvatar";

const TRANSLATION_BASE_PATH = "_COMPONENTS._LIST_CARD";

interface ListCardProps {
	listItem: { userId: string; firstName: string; lastName: string }[];
	cardTitle: ReactNode;
	size?: "small" | "default" | undefined;
	extraRowElement?: any;
	withEmail?: boolean;
}

export const ListCard = (props: ListCardProps) => {
	const { t: translate } = useTranslation();

	const getNoDataComponent = (): ReactNode => {
		return (
			<Row justify="center" gutter={[16, 24]} className="p-24">
				<Col span={24}>
					<Empty description={false}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
						</Text>
					</Empty>
				</Col>
			</Row>
		);
	};

	return (
		<Card size={props.size} title={props.cardTitle} key={new Date().toString()}>
			{props.listItem && props.listItem.length > 0
				? props.listItem.map((item: any, index: number) => {
					return (
						<Row gutter={[0, 8]} key={index} className="listCard__row" align="middle">
							<Col span={16}>
								<Space direction="horizontal" size={8}>
									<UserAvatar {...item} userId={item.userId} />
									<Col>
										<Row>
											<Text fontSize={"14"} lineHeight="18" className="color-gray-8">
												{item.firstName} {item.lastName}
											</Text>
										</Row>
										{props.withEmail && (
											<Row>
												<Text fontSize="12" lineHeight="18" className="color-gray-7">{item.email}</Text>
											</Row>
										)}
									</Col>
								</Space>
							</Col>
							{props.extraRowElement && (
								<Col span={8}>
									<Row justify="end">
										<Space>
											{props.extraRowElement(item.id)}
										</Space>
									</Row>
								</Col>
							)}
						</Row>
					);
				  })
				: getNoDataComponent()}
		</Card>
	);
};
