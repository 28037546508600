export const studentAvatars = [
	"student1.svg",
	"student2.svg",
	"student3.svg",
	"student4.svg",
	"student5.svg",
	"student6.svg",
	"student7.svg",
	"student8.svg",
	"student9.png",
	"student10.png",
	"student11.png",
	"student12.png",
	"student13.png",
	"student14.png",
	"student15.png",
	"student16.png",
	"student17.png",
	"student18.png",
	"student19.png",
	"student20.png",
	"student21.png",
	"student22.png",
	"student23.png",
	"student24.png",
	"student25.png",
	"student26.png",
	"student27.png",
	"student28.png",
	"student29.png",
	"student30.png",
	"student31.png",
	"student32.png",
	"student33.png",
	"student34.png",
	"student35.png",
	"student36.png",
	"student37.png",
	"student38.png",
	"student39.png",
	"student40.png",
	"student41.png",
	"student42.png"
];

export const teacherAvatars = [
	"teacher1.svg",
	"teacher2.svg",
	"teacher3.svg",
	"teacher4.svg",
	"teacher5.svg",
	"teacher6.svg",
	"teacher7.svg",
	"teacher8.svg",
	"teacher9.png",
	"teacher10.png",
	"teacher11.png",
	"teacher12.png",
	"teacher13.png",
	"teacher14.png",
	"teacher15.png",
	"teacher16.png",
	"teacher17.png",
	"teacher18.png",
	"teacher19.png",
	"teacher20.png",
	"teacher21.png",
	"teacher22.png",
	"teacher23.png",
	"teacher24.png",
	"teacher25.png",
	"teacher26.png",
	"teacher27.png",
	"teacher28.png",
	"teacher29.png"
];
