import ApiServiceData from "../api.service";
import { PageComponent } from "../../dtos/administration/customize/PageComponent.dto";

const relativeUrl = "/v1/api/page-components";

export default class PageComponentService extends ApiServiceData {
	async findAll(organizationId: string, pageId: string): Promise<PageComponent[]> {
		return this.get(`${relativeUrl}/${organizationId}/${pageId}/list`)
			.then(res => {
				return res.data as PageComponent[];
			})
			.catch(error => {
				throw error.response;
			});
	}

	async find(id: string): Promise<PageComponent> {
		return this.get(`${relativeUrl}/${id}`)
			.then(res => {
				return res.data as PageComponent;
			})
			.catch(error => {
				throw error.response;
			});
	}
}