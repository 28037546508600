import FileDto from "../administration/file.dto";

export default class Material {
	id?: string;
	title?: string = "";
	type!: string;
	path?: string = "";
	downloadUrl!: string;
	File?: FileDto;
	FileId?: string;
	fileList?: any = []
}
