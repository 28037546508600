import moment from "moment";
import * as Yup from "yup";
import { usernameRegx } from "../../../../../common/components/utils/Regx";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_SETTINGS._PROFILE._FORM";

export const profileFormValidator = (translate: (key: string) => string) =>
	Yup.object({
		firstName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		lastName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		parentEmail: Yup.string()
			.email(translate(`${TRANSLATION_BASE_PATH}._NOT_VALID_EMAIL`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(40, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_40`)),
		dateOfBirth: Yup.string()
			.nullable()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.test("is-valid-date", translate(`${TRANSLATION_BASE_PATH}._DATE_NOT_VALID`), function(value) {
				return moment.utc(value, "YYYY-MM-DD").isValid() === true;
			})
			.test("gretaer-than-three", translate(`${TRANSLATION_BASE_PATH}._GREATER_THAN_THREE`), function(
				value
			) {
				return moment.utc(value, "YYYY-MM-DD") < moment().add(-3, "years");
			}),
		username: Yup.string()
			.nullable()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.matches(usernameRegx, translate(`${TRANSLATION_BASE_PATH}._USERNAME_CHARACTERS_ALLOWED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		nationality: Yup.string()
			.nullable()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});

