import React from "react";

export interface EllipseProps {
	size: number;
	color: string;
}

const Ellipse: React.FC<EllipseProps> = props => {
	return (
		<div
			style={{
				width: `${props.size}px`,
				height: `${props.size}px`,
				borderRadius: "50%",
				backgroundColor: props.color
			}}
		></div>
	);
};

export default Ellipse;
