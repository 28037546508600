import * as Yup from "yup";
import moment from "moment";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

export const formValidatorBirthDateChild = (translate: (key: string) => string) =>
	Yup.object({
		birthDate: Yup.string()
			.nullable()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.test("is-valid-date", translate(`${TRANSLATION_BASE_PATH}._DATE_NOT_VALID`), function(value) {
				return moment.utc(value, "YYYY-MM-DD").isValid() === true;
			})
			.test("gretaer-than-three", translate(`${TRANSLATION_BASE_PATH}._GREATER_THAN_THREE`), function(value) {
				return moment.utc(value, "YYYY-MM-DD") < moment().add(-3, "years");
			}),
	});
