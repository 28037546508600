import { Button, Col, Divider, message, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import OrganizationService from "services/administration/organization.service";
import { GenerateReportButton } from "../../../../../admin/scenes/organizations/components/generateReportButton/GenerateReportButton";
import ManWithKey from "assets/images/ManWithKey.svg";
import Image from "common/components/general/Image";
import { CircularIcon } from "common/components/general/CircularIcon";
import { EditDrawer } from "../../../../../admin/scenes/organizations/components/editDrawer/EditDrawer";
import { UserRole } from "../orgContent/usersTab/userRole/UserRole";
import OAuth2Service from "services/authentication/oauth2.service";
import { showMessage } from "common/utils/Notification";
import { OrganizationUpdateAttribute } from "services/domain/administration/Organization";
import OrganizationDto from "dtos/administration/organization.dto";
import InvitationService from "services/administration/invitation.service";
import CopyToClipboard from "react-copy-to-clipboard";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._SUB_HEADER";

export const SubHeader = (props: any) => {
	const { t: translate } = useTranslation();

	const [organization, setOrganization] = useState<any>(null);
	const [organizationLoading, setOrganizationLoading] = useState(false);
	const [editDrawerVisible, setEditDrawerVisible] = useState(false);

	useEffect(() => {
		loadOrganization();
	}, []);

	const loadOrganization = () => {
		setOrganizationLoading(true);
		return new OrganizationService()
			.findCurrentOrganization()
			.then((result: any) => {
				setOrganization(result[0]);
				props.updateOrganization(result[0]);
			})
			.finally(() => {
				setOrganizationLoading(false);
			});
	};

	const RenderGreeting = () => {
		const RenderGreetingPart1 = () => {
			return (
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					<Space>
						{translate(`${TRANSLATION_BASE_PATH}._GREETING`)}
						<UserRole
							role="_OWNER"
							name={
								OAuth2Service.CurrentUser.User.firstName + " " + OAuth2Service.CurrentUser.User.lastName
							}
						/>
					</Space>
				</Text>
			);
		};

		const RenderGreetingPart2 = () => {
			return (
				<Space>
					<Icon type="ri-information-line" className="color-blue-6" />
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						<Space>{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}</Space>
					</Text>
				</Space>
			);
		};

		return (
			<>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
					{RenderGreetingPart1()}
				</Col>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
					{RenderGreetingPart2()}
				</Col>
				<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
					<Space>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{RenderGreetingPart1()}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							<Divider type="vertical" />
						</Text>
						{RenderGreetingPart2()}
					</Space>
				</Col>
			</>
		);
	};

	const RenderOrganization = () => {
		const RenderEditOrgButton = () => {
			return (
				<Button type="default" className="mr-16" onClick={() => setEditDrawerVisible(true)}>
					<Space>
						<Icon type="ri-edit-line" />
						{translate(`${TRANSLATION_BASE_PATH}._BUTTON_EDIT_ORG`)}
					</Space>
				</Button>
			);
		};
		return (
			<Row>
				<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
					<Row>
						<Col span="24">
							<Space size="large">
								<CircularIcon type="ri-building-4-line" fontSize="30" lineHeight="38" />
								<Space direction="vertical">
									<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
										{organization.name}
									</Text>
									<Space>
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											<Space>
												<Icon type="ri-key-line" />
												{organization.code}
											</Space>
										</Text>
										<CopyToClipboard
											text={organization.code}
											onCopy={(textCopied: any) => {
												message.success(
													translate("_ADMIN._MAIN._COPY_CLIPBOARD", {
														code: `${textCopied}`
													})
												);
											}}
										>
											<Text fontSize="14" lineHeight="32" className="color-blue-6 cursor_pointer">
												<Space>
													<Icon type="ri-file-copy-line" />
													{translate("_ADMIN._MAIN._COPY")}
												</Space>
											</Text>
										</CopyToClipboard>
									</Space>
								</Space>
							</Space>
						</Col>
					</Row>
				</Col>
				<Col xs={0} sm={24} md={24} lg={12} xl={12} xxl={12}>
					<Row justify="end">
						<Space>
							<GenerateReportButton />
							{RenderEditOrgButton()}
							<Image src={ManWithKey} />
						</Space>
					</Row>
				</Col>
				<Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
					<Row justify="center" className="pb-16 pt-24">
						<Space direction="vertical">
							<Image src={ManWithKey} />
							<GenerateReportButton />
							{RenderEditOrgButton()}
						</Space>
					</Row>
				</Col>
			</Row>
		);
	};

	const setDomainChangeRequest = (values: any) => {
		return new OrganizationService().updateDomainOrganization(values).then((result: any) => {
			showMessage(translate(`${TRANSLATION_BASE_PATH}._DOMAIN_REQUEST_SUCCESS`, { orgName: organization.name }));
			setOrganization({
				...organization,
				...values
			});
			props.updateOrganization({
				...organization,
				...values
			});
			return result;
		});
	};

	const setOwnerEmailChangeRequest = (values: any) => {
		return new OrganizationService().updateOwnerEmailoOrganization(values).then((result: any) => {
			showMessage(translate(`${TRANSLATION_BASE_PATH}._RESEND_REQUEST_SUCCESS`));
			setOrganization({
				...organization,
				...values
			});
			props.updateOrganization({
				...organization,
				...values
			});
			return result;
		});
	};

	const updateOrganizationInfo = (values: any) => {
		return new OrganizationService()
			.updateOrganization(values)
			.then((result: any) => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._UPDATE_SUCCESS`, { orgName: values.name }));
				setEditDrawerVisible(false);
				return result;
			})
			.finally(() => setTimeout(() => window.location.reload(), 500));
	};

	const onSubmit = (values: any, organizationUpdateAttribute: OrganizationUpdateAttribute) => {
		let organizationUpdate: Promise<any>;
		switch (organizationUpdateAttribute) {
			case OrganizationUpdateAttribute._ALL:
				organizationUpdate = updateOrganizationInfo(values);
				break;
			case OrganizationUpdateAttribute._DOMAIN:
				organizationUpdate = setDomainChangeRequest(values);
				break;
			case OrganizationUpdateAttribute._OWNER_EMAIL:
				organizationUpdate = setOwnerEmailChangeRequest(values);
				break;
		}

		return organizationUpdate.then((organizations: OrganizationDto[]) => {
			setOrganization(organizations[0]);
			return props.updateOrganization(organizations[0]);
		});
	};

	const resendInvitation = async (invitationId: string) => {
		const invitation = await new InvitationService().resendInvitation(invitationId);
		if (invitation) showMessage(translate(`${TRANSLATION_BASE_PATH}._RESEND_REQUEST_SUCCESS`));
	};

	return (
		<>
			<Row className="main__section_title pb-0">
				<Col span="24">{RenderGreeting()}</Col>
				<Col span="24" className="pt-16">
					<Skeleton loading={organizationLoading}>{organization && RenderOrganization()}</Skeleton>
				</Col>
			</Row>
			{organization && (
				<EditDrawer
					toggleDrawer={() => setEditDrawerVisible(!editDrawerVisible)}
					drawerShown={editDrawerVisible}
					organization={organization}
					formInitialValues={organization}
					resendInvitation={resendInvitation}
					onSubmit={onSubmit}
					disableModify={OAuth2Service.isAdmin}
				/>
			)}
		</>
	);
};
