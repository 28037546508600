import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import { MarketPlace } from "./scenes/marketplace";

import { RELATED_SUBJECTS_PATH, RelatedSubjects } from "./scenes/courseList";
import { CourseItem, COURSE_PREVIEW_FULL_PATH } from "./scenes/courseItem/CourseItem";
import { MainLayout } from "./components/layout/Layout";
import { matchPath } from "react-router";
import AuthorizedGuardedRoute, { TypeOfOrganization } from "App.AuthorizedRoute";
import { Role } from "../../../../services/domain/login/Role";

export const SUBJECTS_PATH = "subjects";

const accessControlList = [
	{
		role: Role.Owner,
		type: TypeOfOrganization.Child
	},
	{
		role: Role.Admin,
		type: TypeOfOrganization.Child
	},
	{
		role: Role.Teacher,
		type: TypeOfOrganization.Main
	},
	{
		role: Role.Teacher,
		type: TypeOfOrganization.Child
	},
	{
		role: Role.Student,
		type: TypeOfOrganization.Main
	},
	{
		role: Role.Student,
		type: TypeOfOrganization.Child
	}
];

const Main = () => {
	const subjectsPath = `/${SUBJECTS_PATH}`;
	const location = useLocation();
	const matchesPath = !!matchPath(location.pathname, { path: subjectsPath, exact: true });

	return (
		<MainLayout showFilters={!matchesPath}>
			<Switch>
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}/new-courses`}
					exact
					component={() => <MarketPlace {...{ section: 1 }} />}
				/>
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}/my-courses`}
					exact
					component={() => <MarketPlace {...{ section: 2 }} />}
				/>
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}/all-courses-by-subject/:id`}
					exact
					component={(props: any) => <MarketPlace {...{ section: 3, subjectId: props.match.params.id }} />}
				/>
				<Route path={`/${SUBJECTS_PATH}/${COURSE_PREVIEW_FULL_PATH}`} exact component={CourseItem} />
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}${RELATED_SUBJECTS_PATH}`}
					exact
					component={RelatedSubjects}
				/>
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}`}
					exact
					component={MarketPlace}
				/>
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}/`}
					exact
					component={MarketPlace}
				/>
			</Switch>
		</MainLayout>
	);
};

export default Main;
