import React, { useState, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Icon from "common/components/general/Icon";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Alert from "common/components/feedback/Alert";
import { formValidator } from "./ValidationSchema";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import { BaseComponentProps } from "../OnboardingStepsMapping";
import InvitationService from "services/administration/invitation.service";
import { Skeleton } from "antd";

const TRANSLATION_BASE_PATH = "_ONBOARDING._ORGANISATION_CODE";

export const JoinOrganisation = (props: BaseComponentProps) => {
	const { t: translate } = useTranslation();
	const [hasError, setHasError] = useState<{ error: boolean; errorMessage: string }>({
		error: false,
		errorMessage: ""
	});
	const [organizationInvitations, setOrganizationInvitations] = useState<any[]>();
	const [isOwner, setIsOwner] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const fetchOrganizationInvitations = async () => {
		setLoading(true);
		await Promise.all([new InvitationService().getOrganizationOwnerInvitations(),
			new InvitationService().getOrganizationAdminInvitations(),
			new InvitationService().getOrganizationTeacherInvitations()])
			.then((organizationInvitation: any) => {
				setIsOwner(organizationInvitation[0].length > 0);
				const allOrganizationInvitation = organizationInvitation?.flat(1) || [];
				return setOrganizationInvitations(allOrganizationInvitation);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		props.setIsFormValid(true);
		props.setTitle(translate(`${TRANSLATION_BASE_PATH}._TITLE`));
		fetchOrganizationInvitations();
	}, []);

	const showAlertContent = (): ReactNode => {
		return hasError.error ? (
			<Row className="mb-24">
				<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={15} lg={10} xl={10} xxl={10}>
					<Alert message={hasError.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	const onSubmit = async (values: any) => {
		const newState = {
			...props.state,
			organisationCode: values.organisationCode
		};
		props.updateState(newState);

		return new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				return props.finish(newState.id ?? "", values.organisationCode, isOwner);
			}).catch((error: any) => {
				return setHasError(error);
			});
	};

	return (
		<Skeleton active loading={loading}>
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator}
				initialValues={{
					organisationCode: ""
				}}
				onSubmit={onSubmit}
				validate={values => {
					props.setIsFormValid(formValidator().isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-24">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Alert
										type="info"
										showIcon
										message={translate(`${TRANSLATION_BASE_PATH}._ALERT_TITLE`)}
										description={translate(`${TRANSLATION_BASE_PATH}._ALERT_DESCRIPTION`)}
									/>
								</Col>
							</Row>
							{showAlertContent()}
							{organizationInvitations?.map((organization, index) => {
								return (
									<Row key={index}>
										<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
										<Col xs={24} sm={24} md={15} lg={10} xl={10} xxl={10}>
											<Input
												name={`organization-${index}`}
												size="large"
												value={`${organization.code} (${organization.name})`}
												disabled
												disabledTooltip={translate(
													`${TRANSLATION_BASE_PATH}._ORGANIZATION_CODE_DISABLED_TOOLTIP`
												)}
												suffix={
													<Icon type="ri-checkbox-circle-fill" className="color-green-6" />
												}
											/>
										</Col>
									</Row>
								);
							})}
							<Row>
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={15} lg={10} xl={10} xxl={10}>
									<Input
										name="organisationCode"
										label={translate(`${TRANSLATION_BASE_PATH}._INPUT_ORGANISATION_CODE`)}
										labelInfo={translate(`${TRANSLATION_BASE_PATH}._INPUT_ORGANISATION_CODE_HINT`)}
										size="large"
									/>
								</Col>
							</Row>
						</form>
					);
				}}
			</Formik>
		</Skeleton>
	);
};
