import React, { ReactNode } from "react";
import { Layout } from "antd";

import { Header as AdminHeader } from "common/components/_header1/Header1";
import { MainHeader as TeacherHeader } from "common/components/layout/header";
import OAuth2Service from "services/authentication/oauth2.service";
import { MainDashboardMenuItems } from "../../../main/components/layout/Layout";

interface LayoutProps {
	children: ReactNode;
}

const MainLayout = (props: LayoutProps) => {
	return (
		<Layout>
			{OAuth2Service.isOwnerOrAdminAtDefaultOrganization ? (
				<AdminHeader />
			) : (
				<TeacherHeader menu={MainDashboardMenuItems} showSwitchRole={true} />
			)}
			{props.children}
		</Layout>
	);
};

export default MainLayout;
