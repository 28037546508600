import React, { ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import Error from "./FormItemError";
import CheckboxGroup from "../components/CheckboxGroup";
import Label from "../components/Label";
import { FieldProps } from "./FieldProps";

interface CheckboxGroupProps extends FieldProps {
	options?: string[];
	value?: string[];
	disabled?: boolean;
	defaultValue?: string[];
	children: ReactNode;
	onChange?: (checkedValues: any[]) => void;
}

const FormikCheckboxGroupField: React.FC<CheckboxGroupProps> = ({ label, ...props }) => {
	const [, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched || meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;

	const onChange = (checkedValues: any[]) => {
		context.setFieldValue(props.name, checkedValues);
		if (props.onChange) props.onChange(checkedValues);
	};

	return (
		<>
			{label && <Label htmlFor={props.id || props.name}>{label}</Label>}
			<CheckboxGroup {...props} onChange={onChange}>
				{props.children}
			</CheckboxGroup>
			{error}
		</>
	);
};

export default FormikCheckboxGroupField;
