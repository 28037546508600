import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Image from "common/components/general/Image";
import { formValidator } from "./ValidationSchema";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import AlFlag from "assets/images/icons/Al.svg";
import KsFlag from "assets/images/icons/Ks.svg";
import Icon from "common/components/general/Icon";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_NATIONALITY";

class ChooseNationalityComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			initialValues: props.state
		};
	}

	componentDidMount() {
		const { t: translate } = this.props;
		this.props.setIsFormValid(formValidator(translate).isValidSync(this.state.initialValues));
		this.props.setTitle(translate(`${TRANSLATION_BASE_PATH}._CHOOSE_NATIONALITY`));
	}

	getChooseNationalityLabelBasedOnRole() {
		const { t: translate } = this.props;
		const labelKey =
			this.props.state.role === Role.Teacher
				? "_CHOOSE_NATIONALITY_TEACHER_LABEL"
				: "_CHOOSE_NATIONALITY_STUDENT_LABEL";
		return translate(`${TRANSLATION_BASE_PATH}.${labelKey}`);
	}

	onSubmit = async (values: any) => {
		const newState = {
			...this.props.state,
			nationality: values.nationality
		};
		this.props.updateState(newState);

		await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				this.props.setIsSubmiting(false);
				this.props.goNext();
				return;
			})
			.catch(error => {
				console.log(error);
				this.props.setIsSubmiting(false);
			});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={this.state.initialValues}
				onSubmit={this.onSubmit}
				validate={values => {
					this.props.setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					this.props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-8">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{this.getChooseNationalityLabelBasedOnRole()}
									</Text>
								</Col>
							</Row>
							<Row>
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Radio name="nationality" defaultValue={this.state.initialValues.nationality}>
										<Row gutter={[8, 8]}>
											<Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
												<RadioElement value="ALBANIA" showAsBox={true}>
													<div className="choose_nationality__option">
														<Image src={AlFlag} />
														{translate(`${TRANSLATION_BASE_PATH}._ALBANIA`)}
													</div>
												</RadioElement>
											</Col>
											<Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
												<RadioElement value="KOSOVO" showAsBox={true}>
													<div className="choose_nationality__option">
														<Image src={KsFlag} />
														{translate(`${TRANSLATION_BASE_PATH}._KOSOVO`)}
													</div>
												</RadioElement>
											</Col>
											<Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
												<RadioElement value="OTHER" showAsBox={true}>
													<div className="choose_nationality__option">
														<Icon type="ri-earth-line" />
														{translate(`${TRANSLATION_BASE_PATH}._OTHER`)}
													</div>
												</RadioElement>
											</Col>
										</Row>
									</Radio>
								</Col>
							</Row>
						</form>
					);
				}}
			</Formik>
		);
	}
}

export const ChooseNationality = withTranslation()(ChooseNationalityComponent);
