import ApiServiceData from "../api.service";
import EnrollmentDto from "../../dtos/administration/enrollment.dto";
const relativeUrl = "/v1/api/enrollments";

export default class EnrollmentService extends ApiServiceData {
	public getEnrollmentById(id: string): Promise<EnrollmentDto> {
		return this.get(relativeUrl + "/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllEnrollments(): Promise<EnrollmentDto[]> {
		return this.get(`${relativeUrl}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllEnrollmentsByClassroomId(classroomId: string): Promise<EnrollmentDto[]> {
		return this.get(`${relativeUrl}/classroom/${classroomId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createEnrollment(enrollment: EnrollmentDto): Promise<EnrollmentDto> {
		return this.post(relativeUrl, enrollment)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public deleteEnrollment(enrollmentId: string) {
		return this.delete(relativeUrl + "/" + enrollmentId);
	}

	public getAllEnrollmentsForCurrentUser(): Promise<EnrollmentDto[]> {
		return this.get(`${relativeUrl}/virtual/current-user`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateEnrollment(enrollment: any): Promise<[number, EnrollmentDto[]]> {
		return this.update(relativeUrl, enrollment)
			.then(res => {
				console.log(res);
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllEnrollmentsByParent(userId: string): Promise<EnrollmentDto[]> {
		return this.get(`${relativeUrl}/byParent/${userId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createEnrollmentFromPayPalOrderId(orderId: string): Promise<EnrollmentDto> {
		return this.post(relativeUrl + "/pay-pal/" + orderId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
