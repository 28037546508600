import React, { useState, ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import classnames from "classnames";
import { InputProps } from "antd/lib/input";
import { useTranslation } from "react-i18next";

import Error from "./FormItemError";
import PasswordInput from "../components/PasswordInput";
import Popover from "../../dataDisplay/Popover";
import { FieldProps } from "./FieldProps";
import Label from "../components/Label";
import PasswordPopoverContent, { ContentProps } from "../components/PasswordPopoverContent";
import { numberRegx, lowercaseRegx, uppercaseRegx, specialCharRegx } from "../../utils/Regx";

type PasswordProps = InputProps &
	FieldProps & {
		showPopover?: boolean;
	};

const NR_OF_CHARS_REQUIRED = 8;
const hasNumberKey = 1;
const hasUppercaseKey = 2;
const hasLowerCaseKey = 3;
const hasLength8Key = 4;
const hasSpecialCharacters = 5;

const progressMap = new Map<number, boolean>([
	[hasNumberKey, false],
	[hasUppercaseKey, false],
	[hasLowerCaseKey, false],
	[hasLength8Key, false],
	[hasSpecialCharacters, false]
]);

const FormikInputField: React.FC<PasswordProps> = ({ showPopover, ...props }) => {
	const { t: translate } = useTranslation();
	const [field, meta] = useField(props.name);
	const showError = meta.touched && meta.error;
	const { setFieldValue } = useFormikContext();

	const passwordValidationResult: ContentProps = {
		nrOfcharsIcon: false,
		lowercaseIcon: false,
		oneNumberIcon: false,
		uppercaseIcon: false,
		specialCharacterIcon: false,
		translate: translate
	};

	const [content, setContent] = useState<ReactNode>(<PasswordPopoverContent {...passwordValidationResult} />);

	const calculatePasswordPopoverContent = (password: string) => {
		passwordValidationResult.oneNumberIcon = password.match(numberRegx) !== null;
		passwordValidationResult.nrOfcharsIcon = password.length >= NR_OF_CHARS_REQUIRED;
		passwordValidationResult.lowercaseIcon = password.match(lowercaseRegx) !== null;
		passwordValidationResult.uppercaseIcon = password.match(uppercaseRegx) !== null;
		passwordValidationResult.specialCharacterIcon = password.match(specialCharRegx) !== null;

		setContent(<PasswordPopoverContent {...passwordValidationResult} />);
	};

	const calculateProgress = (password: string) => {
		progressMap.set(hasNumberKey, password.match(numberRegx) !== null);
		progressMap.set(hasLength8Key, password.length >= NR_OF_CHARS_REQUIRED);
		progressMap.set(hasLowerCaseKey, password.match(lowercaseRegx) !== null);
		progressMap.set(hasUppercaseKey, password.match(uppercaseRegx) !== null);
		progressMap.set(hasSpecialCharacters, password.match(specialCharRegx) !== null);
	};

	const onPasswordInputChange = (e: React.FormEvent<HTMLInputElement>) => {
		const password = e.currentTarget.value;
		setFieldValue(props.name, password);
		calculatePasswordPopoverContent(password);
		calculateProgress(password);
	};

	const id = props.id || props.name;
	const errorClassname = classnames({ error_input: showError });

	const passWordInput = (
		<PasswordInput
			{...field}
			{...props}
			onChange={onPasswordInputChange}
			className={`${errorClassname} ${props.className}`}
			id={id}
		/>
	);

	const input = showPopover ? (
		<Popover
			title={translate("_COMPONENTS._PASSWORD_REQUIREMENTS._TITLE")}
			content={content}
			placement="bottomLeft"
			trigger="focus"
		>
			{passWordInput}
		</Popover>
	) : (
		passWordInput
	);

	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;

	return (
		<div>
			<Label htmlFor={id} className="hand-on-hover">
				{props.label}
			</Label>
			{input}
			{error}
		</div>
	);
};

export default FormikInputField;
