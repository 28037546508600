import ApiServiceData from "../api.service";
import { Template } from "../../dtos/administration/customize/Template.dto";

const relativeUrl = "/v1/api/templates";

export default class TemplateService extends ApiServiceData {
	async findAll(organizationId: string): Promise<Template[]> {
		return this.get(relativeUrl + "/" + organizationId + "/list")
			.then(res => {
				return res.data as Template[];
			})
			.catch(error => {
				throw error.response;
			});
	}
	async find(templateId: string): Promise<Template> {
		return this.get(`${relativeUrl}/${templateId}`)
			.then(res => {
				return res.data as Template;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
