import { Button, Col, Collapse, Divider, Row, Space, Select, Skeleton } from "antd";
import React, { Component } from "react";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

import { CircularIcon } from "common/components/general/CircularIcon";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { FormikGroupDatePickerField } from "../../../../login/components/groupBirthDatePicker";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import { profileFormValidator } from "./ProfileValidationSchema";
import UserService from "services/authentication/user.service";
import { showError, showMessage } from "common/utils/Notification";

const { Panel } = Collapse;
const TRANSLATION_BASE_PATH = "_PARENT._CHILD_SETTINGS._PROFILE";

class ProfileFormComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submittingProfileForm: false,
			profileInitalValuesLoaded: false,
			profileInitialValues: {
				firstName: "",
				lastName: "",
				dateOfBirth: null,
				parentEmail: "",
				username: "",
				nationality: null
			}
		};
	}

	componentDidMount() {
		this.setState({
			profileInitialValues: this.props.child,
			profileInitalValuesLoaded: true
		});
	}

	get profileHeader() {
		const { t: translate } = this.props;
		return (
			<Space>
				<CircularIcon type="ri-user-line" />
				<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
					{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
				</Text>
			</Space>
		);
	}

	get profileContentForm() {
		const { t: translate } = this.props;
		return (
			<Skeleton loading={!this.state.profileInitalValuesLoaded}>
				<Row className="pt-24">
					<Col span="24">
						<Formik
							enableReinitialize={true}
							initialValues={{ ...this.state.profileInitialValues }}
							onSubmit={this.submitProfileForm}
							isInitialValid={false}
							validationSchema={profileFormValidator(translate)}
						>
							{formik => {
								const { handleSubmit } = formik;
								return (
									<form onSubmit={handleSubmit} id="profile-form" autoComplete="off">
										<Row justify="space-between">
											<Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
												<Input
													name="firstName"
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIRST_NAME`)}
												/>
											</Col>
											<Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
												<Input
													name="lastName"
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._LAST_NAME`)}
												/>
											</Col>
										</Row>
										<Row>
											<Col span="24">
												<FormikGroupDatePickerField
													disabled={true}
													name="dateOfBirth"
													size="middle"
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._BIRTH_DATE`)}
												/>
											</Col>
										</Row>
										<Row>
											<Col span="24">
												<Input
													disabled={true}
													name="parentEmail"
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._EMAIL`)}
												/>
											</Col>
										</Row>
										<Row>
											<Col span="24">
												<Input
													name="username"
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._USERNAME`)}
												/>
											</Col>
										</Row>
										<Row>
											<Col span="24">
												<FormikSelectField
													name="nationality"
													defaultValue={this.props.child.nationality}
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._NATIONALITY`)}
												>
													{["ALBANIA", "KOSOVO", "OTHER"].map((nationality: string) => (
														<Select.Option key={nationality} value={nationality}>
															{translate(
																`${TRANSLATION_BASE_PATH}._FORM._CHOOSE_NATIONALITY._${nationality}`
															)}
														</Select.Option>
													))}
												</FormikSelectField>
											</Col>
										</Row>
										<Row justify="end">
											<Button
												type="primary"
												size="middle"
												htmlType="submit"
												loading={this.state.submittingProfileForm}
												disabled={this.state.submittingProfileForm}
											>
												{translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_SAVE`)}
											</Button>
										</Row>
									</form>
								);
							}}
						</Formik>
					</Col>
				</Row>
				<Divider className="pb-0" />
			</Skeleton>
		);
	}

	submitProfileForm = async (values: any) => {
		const { t: translate } = this.props;
		this.setState({
			submittingProfileForm: true
		});
		const child = {
			firstName: values.firstName,
			lastName: values.lastName,
			username: values.username,
			nationality: values.nationality
		};
		return new UserService()
			.updateProfile(values.id, child)
			.then(() => {
				this.props.updateProfile(child);
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._FORM._PROFILE_UPDATED`));
			})
			.catch(error => {
				const errorStatus = (error && error.status) ?? "500";
				return showError(translate(`${TRANSLATION_BASE_PATH}._FORM._${errorStatus}_API_STATUS_CODE`));
			})
			.finally(() => {
				this.setState({
					submittingProfileForm: false
				});
			});
	};

	render() {
		return (
			<Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
				<Panel header={this.profileHeader} key="1">
					{this.profileContentForm}
				</Panel>
			</Collapse>
		);
	}
}

const ProfileForm = withTranslation()(ProfileFormComponent);
export default ProfileForm;
