import EnrollmentDto from "dtos/administration/enrollment.dto";
import NotificationDto from "dtos/administration/notification.dto";
import EnrollmentService from "services/administration/enrollment.service";
import NotificationService from "services/administration/notification.service";
import UserService from "services/authentication/user.service";
import ClassroomService from "services/administration/classroom.service";
import { CreateAssignmentSubmittedNotificationDto } from "../dtos/createAssignmentSubmittedNotificationDto";
import { CreateEnrollmentChangeNotificationDto } from "../dtos/createEnrollmentChangeNotificationDto";
import { CreateNewLessonNotificationDto } from "../dtos/createNewLessonNotificationDto";
import { CreateOrganizationEnrollmentChangeNotificationDto } from "../dtos/CreateOrganizationEnrollmentChangeNotificationDto";
import { CreateParentInviteChildNotificationDto } from "../dtos/createParentInviteChildNotificationDto";
import ClassroomDto from "dtos/administration/classroom.dto";

export const createNewLessonNotifications = async (data: CreateNewLessonNotificationDto) => {
	const title = data.notificationTitle;
	const isVirtualClass: any = data.currentUser.Roles?.some((role: any) => role.code === "_OWNER");
	const navigationUrl = `subjects/materials/VIDEO/${data.recordId}${isVirtualClass ? "?virtualClass=true" : ""}`;

	return new EnrollmentService()
		.getAllEnrollmentsByClassroomId(data.classroomId)
		.then((enrollments: EnrollmentDto[]) => {
			const notifications: NotificationDto[] = [];
			enrollments.forEach((enrollment: EnrollmentDto) => {
				if (enrollment.status !== "BLOCKED") {
					notifications.push({
						UserId: enrollment.UserId,
						title: title,
						message: data.lessonTitle,
						type: data.lessonType,
						navigateUrl: navigationUrl
					});
				}
			});
			return notifications;
		})
		.then((notifications: NotificationDto[]) => {
			return new NotificationService().create(notifications);
		});
};

export const createEnrollmentChangeNotification = async (data: CreateEnrollmentChangeNotificationDto) => {
	const notification: NotificationDto = {
		UserId: data.enrollment.UserId,
		title: data.title,
		message: data.title,
		type: data.type,
		navigateUrl: data.navigateUrl
	};
	return new NotificationService().create([notification]);
};

export const createParenInviteChildNotification = async (data: CreateParentInviteChildNotificationDto) => {
	const childAlreadyRegistered = await new UserService().findUserByEmail(data.childEmail);
	if (childAlreadyRegistered && !childAlreadyRegistered.parentEmail) {
		const notification: NotificationDto = {
			UserId: childAlreadyRegistered.id,
			title: data.notificationTitle,
			message: data.notificationMessage,
			type: data.type,
			navigateUrl: data.recordId + "|" + data.parentEmail
		};
		return new NotificationService().create([notification]);
	}
};

export const createOrganizationEnrollmentChangeNotification = async (
	data: CreateOrganizationEnrollmentChangeNotificationDto
) => {
	const notification: NotificationDto = {
		UserId: data.UserId,
		title: "Aksesi në organizatë",
		message: data.title,
		type: data.type,
		navigateUrl: ""
	};
	return new NotificationService().create([notification]);
};

export const CreateAssignmentSubmittedNotification = async (data: CreateAssignmentSubmittedNotificationDto) => {
	const title = data.notificationTitle;
	const navigationUrl = `material-info/_teacher/material/${data.recordId}?classroomId=${data.classroomId}&active=2`;

	return new ClassroomService()
		.getClassroomById(data.classroomId)
		.then((classroom: ClassroomDto) => {
			const notifications: NotificationDto[] = [];
			notifications.push({
				UserId: classroom.createdBy || "",
				title: title,
				message: data.assignmentTitle,
				type: data.type,
				navigateUrl: navigationUrl
			});
			return notifications;
		})
		.then((notifications: NotificationDto[]) => {
			return new NotificationService().create(notifications);
		});
};
