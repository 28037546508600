import { Card, Col, Row, Skeleton, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from "../../../../../../services/authentication/user.service";
import InvitationService from "../../../../../../services/administration/invitation.service";
import _ from "lodash";
import { UsersTab } from "./usersTab/UsersTab";
import { AsyncClassesTab } from "./asyncClassesTab/AsyncClassesTab";
import { VirtualClassesTab } from "./virtualClassesTab/VirtualClassesTab";
import ClassroomService from "../../../../../../services/administration/classroom.service";

const { TabPane } = Tabs;

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT";

export const OrgContent = (props: any) => {
	const { t: translate } = useTranslation();
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [loadingAsyncClasses, setLoadingAsyncClasses] = useState(false);
	const [loadingVirtualClasses, setLoadingVirtualClasses] = useState(false);
	const [users, setUsers] = useState<any>([]);
	const [asyncClasses, setAsyncClasses] = useState<any>([]);
	const [virtualClasses, setVirtualClasses] = useState<any>([]);

	const LoadUsers = () => {
		setLoadingUsers(true);
		new InvitationService()
			.getOrganizationStaffInvites()
			.then((invites: any) => {
				return invites.map((item: any) => {
					return {
						id: item.id,
						firstName: item.email,
						lastName: null,
						email: item.email,
						role: item.invitationType == "ADMIN_ORGANIZATION" ? "_ADMIN" : "_TEACHER",
						type: "INVITE",
						updatedAt: item.updatedAt,
						UserRoleId: null,
						UserId: null,
						priority: item.invitationType == "ADMIN_ORGANIZATION" ? 2 : 100,
						status: "ACTIVE"
					};
				});
			})
			.then((invites: any) => {
				return new UserService().getOrganizationUsers().then((users: any) => {
					console.log(users);
					const totalRecords = _.orderBy(
						invites.concat(
							users.map((item: any) => {
								return {
									id: item.id,
									firstName: item.firstName,
									lastName: item.lastName,
									email: item.email,
									role: item.UserRoles[0].Role.code,
									type: "USER",
									updatedAt: item.updatedAt,
									UserRoleId: item.UserRoles[0].id,
									UserId: item.UserRoles[0].UserId,
									priority: item.UserRoles[0].Role.priority,
									status: item.UserRoles[0].UserRoleOrganizations[0].status
								};
							})
						),
						["priority", "firstName", "lastName"],
						["asc"]
					);
					setUsers(totalRecords);
					props.updateTotalUsers(totalRecords);
					setUsers(totalRecords);
				});
			})
			.finally(() => {
				return setLoadingUsers(false);
			});
	};

	const LoadAsyncClasses = () => {
		setLoadingAsyncClasses(true);
		new ClassroomService()
			.getAsyncClassesByOrganization()
			.then((result: any) => {
				props.updateTotalAsyncClasses(result.length);
				setAsyncClasses(result);
			})
			.finally(() => setLoadingAsyncClasses(false));
	};

	const LoadVirtualClasses = () => {
		setLoadingVirtualClasses(true);
		new ClassroomService()
			.getVirtualClassesByOrganization()
			.then((result: any) => {
				props.updateTotalVirtualClasses(result.length);
				setVirtualClasses(result);
			})
			.finally(() => setLoadingVirtualClasses(false));
	};

	useEffect(() => {
		LoadUsers();
		LoadAsyncClasses();
		LoadVirtualClasses();
	}, []);

	useEffect(() => {
		LoadUsers();
	}, [props.refresh]);

	return (
		<>
			<Row className="mb-24 ml-24 mr-24">
				<Col span="24">
					<Card>
						<Tabs defaultActiveKey="1">
							<TabPane
								tab={translate(`${TRANSLATION_BASE_PATH}._USERS_TAB._TITLE`, {
									count: users.filter((x: any) => {
										return x.status === "ACTIVE";
									}).length
								})}
								key="1"
							>
								<Skeleton loading={loadingUsers}>
									<UsersTab users={users} loadUsers={LoadUsers} />
								</Skeleton>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._ASYNC_CLASSES_TAB._TITLE`)} key="2">
								<Skeleton loading={loadingAsyncClasses}>
									<AsyncClassesTab classes={asyncClasses} />
								</Skeleton>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._VIRTUAL_CLASSES_TAB._TITLE`)} key="3">
								<Skeleton loading={loadingVirtualClasses}>
									<VirtualClassesTab classes={virtualClasses} />
								</Skeleton>
							</TabPane>
						</Tabs>
					</Card>
				</Col>
			</Row>
		</>
	);
};
