import React, { useEffect, useState } from "react";
import { Skeleton, Space } from "antd";
import Jimp from "jimp";

import Icon from "common/components/general/Icon";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import FileDto from "dtos/administration/file.dto";
import { previewFile } from "../../../../../../utils/FileUtils";

interface ImagePreviewProps {
	file: FileDto;
	onDelete?: () => void;
}

const transformFileDownload = (imageUrl: any) => {
	return Jimp.read(imageUrl).then(image => {
		return image.getBase64Async(Jimp.MIME_JPEG);
	});
};

export const ImagePreview = (props: ImagePreviewProps) => {
	const [imagePhoto, setImagePhoto] = useState<string | undefined>(undefined);
	useEffect(() => {
		async function fetchData() {
			const response = await previewFile(props.file);
			const photoAsString = await transformFileDownload(response.url);
			setImagePhoto(photoAsString);
		}
		fetchData();
	}, []);

	return (
		<Skeleton
			active
			loading={!imagePhoto}
			paragraph={{
				rows: 1
			}}
		>
			{imagePhoto ? (
				<div className="quiz__crud_image_preview">
					<Space size={8} align="center">
						<Image src={imagePhoto} className="image_preview" />
						<Text fontSize="14" lineHeight="20" className="color-gray-9" maxLength={50}>
							{props.file.name}
						</Text>
					</Space>
					{props.onDelete ? (
						<Icon type="ri-close-line" className="color-gray-7 cursor_pointer" onClick={props.onDelete} />
					) : null}
				</div>
			) : null}
		</Skeleton>
	);
};
