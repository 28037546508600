import React from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, message, Row, Space } from "antd";
import Text from "common/components/general/Text";
import Modal from "common/components/feedback/Modal";
import Icon from "common/components/general/Icon";
import { AdminTable } from "../table/table";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._ORGANIZATION";

interface ModalInfoDNSProps {
	certificateCreated: any;
	onOk: () => void;
	showModal: boolean
}

const onCopy = (textCopied: string, translate: any) => {
	message.success(
		translate(`${TRANSLATION_BASE_PATH}._COPY_CLIPBOARD`, {
			code: `${textCopied}`
		})
	);
};
	
export const ModalInfoDNS = (props: ModalInfoDNSProps) => {
	const { t: translate } = useTranslation();

	return (
		<Modal title={translate(`${TRANSLATION_BASE_PATH}._MODAL_TITLE_DOMAIN_REQUEST`)} width={1200} onOk={props.onOk}
			visible={props.showModal} maskClosable={false} cancelButtonProps={{style: {display: "none"}}} closable={false}>
			<Row>
				<Col span={24}>
					<Row className="mb-16">
						<Col span={24}>
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
								{translate(`${TRANSLATION_BASE_PATH}._MODAL_DOMAIN_REQUEST`)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-16">
						<Col span={24}> 
							<AdminTable
								scroll={{ x: 1200}}
								columns={[{
									title: translate(`${TRANSLATION_BASE_PATH}._MODAL_DOMAIN_REQUEST_COLUMNS._CNAME`),
									dataIndex: "cname",
									key: "cname",
									width: "15%"
								},
								{
									title: translate(`${TRANSLATION_BASE_PATH}._MODAL_DOMAIN_REQUEST_COLUMNS._SUBDOMAIN`),
									responsive: ["sm"],
									dataIndex: "subdomain",
									key: "subdomain",
									width: "25%",
									ellipsis: true,
									render: (text: any) => {
										return (
											<Space direction="vertical" size={8}>
												<Row align="middle">
													<Text fontSize="14" lineHeight="22" className="color-gray-9">
														{text}
													</Text>
												</Row>
												<Row align="middle">
													<CopyToClipboard text={text}
														onCopy={(textCopied: any) => onCopy(textCopied, translate)}>
														<Text fontSize="14" lineHeight="22" className="color-blue-6 cursor_pointer">
															<Icon type="ri-file-copy-line" /> {translate(`${TRANSLATION_BASE_PATH}._COPY`)}
														</Text>
													</CopyToClipboard>
												</Row>
											</Space>
										);
									}
								},
								{
									title: translate(`${TRANSLATION_BASE_PATH}._MODAL_DOMAIN_REQUEST_COLUMNS._ALB_DNS`),
									responsive: ["md"],
									dataIndex: "albdns",
									key: "albdns",
									width: "55%",
									ellipsis: true,
									render: (text: any) => {
										return (
											<Space direction="vertical" size={8}>
												<Row align="middle">
													<Text fontSize="14" lineHeight="22" className="color-gray-9">
														{text}
													</Text>
												</Row>
												<Row align="middle">
													<CopyToClipboard text={text}
														onCopy={(textCopied: any) => onCopy(textCopied, translate)}>
														<Text fontSize="14" lineHeight="22" className="color-blue-6 cursor_pointer">
															<Icon type="ri-file-copy-line" /> {translate(`${TRANSLATION_BASE_PATH}._COPY`)}
														</Text>
													</CopyToClipboard>
												</Row>
											</Space>
										);
									}
								}]}
								dataSource={[{
									key: "1",
									cname: "CNAME",
									subdomain: props.certificateCreated?.subdomain || "www",
									albdns: props.certificateCreated?.environmentVar?.dns || translate(`${TRANSLATION_BASE_PATH}._DNS_NOT_RECOGNIZED`)
								}, {
									key: "2",
									cname: props.certificateCreated?.certificateVar?.Type,
									subdomain: props.certificateCreated?.subdomain === "*" ? props.certificateCreated?.certificateVar?.Name?.split(".")[ 0 ] :
										[props.certificateCreated?.certificateVar?.Name?.split(".")[ 0 ], props.certificateCreated?.subdomain].join("."),
									albdns: props.certificateCreated?.certificateVar?.Value
								}]}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Modal>
	);
};
