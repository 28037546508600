import ApiServiceData from "../api.service";
import NotificationDto from "../../dtos/administration/notification.dto";
const relativeUrl = "/v1/api/notifications";

export default class NotificationService extends ApiServiceData {
	public countAllByUserId() {
		return this.get(`${relativeUrl}/count`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllByUserId() {
		return this.get(`${relativeUrl}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getSomeByUserId(limit: number) {
		return this.get(`${relativeUrl}/limit/${limit}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public create(notifications: NotificationDto[]) {
		return this.post(relativeUrl, notifications);
	}

	public markAllAsSeen() {
		return this.update(`${relativeUrl}`, {});
	}

	public remove(notificationId: string) {
		return this.delete(`${relativeUrl}/${notificationId}`);
	}
}

