import React, { ReactNode } from "react";
import { Col as AntdCol } from "antd";
import { ColProps as AntColProps } from "antd/lib/col";

interface ColProps extends AntColProps {
	children?: ReactNode;
}

const Col: React.FC<ColProps> = props => <AntdCol {...props}>{props.children}</AntdCol>;

export default Col;
