import { Space } from "antd";
import React from "react";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

interface StatisticsCardProps {
	title: string;
	icon: string;
	value: number;
	rawTitle: boolean;
}

export const StatisticsCard = (props: StatisticsCardProps) => {
	return (
		<div className="dashboard__statistics_card">
			<Space align="center" size={12}>
				<Icon type={props.icon} className="card__icon" />
				<Space direction="vertical" size={4}>
					{props.rawTitle ? (
						<div dangerouslySetInnerHTML={{ __html: props.title }} />
					) : (
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{props.title}
						</Text>
					)}
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{props.value.toLocaleString()}
					</Text>
				</Space>
			</Space>
		</div>
	);
};
