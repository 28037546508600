import React, { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import Jimp from "jimp";
import { useTranslation } from "react-i18next";
import { Button, message, Progress, Space, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined } from "@ant-design/icons";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import BucketS3Service from "services/s3/s3.service";
import S3ActionType from "services/domain/administration/S3Action";
import { FileType } from "services/domain/administration/FileType";
import { UploaderType } from "services/domain/administration/UploaderType";
import FileDto from "dtos/administration/file.dto";

const TRANSLATION_BASE_PATH = "_ADMIN._ASYNCH_CLASSES";

export enum UploadImageType {
	Cover = "cover",
	Avatar = "avatar",
	QuizQuestion = "quiz-question",
	LoginCover = "login-cover"
}
const { Dragger } = Upload;
const AcceptRatioBasedOnUploadImageType = new Map<UploadImageType, number>([
	[UploadImageType.Cover, 16 / 9],
	[UploadImageType.Avatar, 1],
	[UploadImageType.QuizQuestion, 16 / 10],
	[UploadImageType.LoginCover, 34 / 27]
]);
const RedusedQualityBasedOnUploadImageType = new Map<UploadImageType, number>([
	[UploadImageType.Cover, 60],
	[UploadImageType.Avatar, 60],
	[UploadImageType.QuizQuestion, 50],
	[UploadImageType.LoginCover, 100]
]);

interface UploaderProps {
	noCrop?: boolean;
	fileType: FileType;
	filePath?: string;
	fileWithUniqueId?: boolean;
	onRemoveUpload?: (fileToDelete: any) => void;
	onUploadingDone?: (file: any) => void;
	onUploadingDoneWithFormik?: (file: any, formik: any) => void;
	defaultFileList?: any[];
	addFileToFileList?: (fileList: any[]) => void;
	showUploadList?: boolean;
	onSoftDeleteFile?: (file: any) => void;
	onUploadingStarted?: (flag: boolean) => void;
	uploadListType?: "picture" | "picture-card";
	uploadImageType?: UploadImageType;
	children?: ReactNode;
	uploaderType?: UploaderType;
	className?: string;
	multiple?: boolean;
	showRemoveIcon?: boolean;
	formik?: object;
	isPublic?: boolean
}

export const Uploader = (props: UploaderProps) => {
	const { t: translate } = useTranslation();
	const [fileCreated, setFileCreated] = useState<any>(props.defaultFileList ? props.defaultFileList[0] : null);
	const [fileList, setFileList] = useState<any[]>(props.defaultFileList ? props.defaultFileList : []);
	const [progress, setProgress] = useState<number>(0);

	const UploadComp = props.uploaderType === "DRAGGER" ? Dragger : Upload;

	useEffect(() => {
		setFileList(props.defaultFileList ? props.defaultFileList : fileList);
	}, [props.defaultFileList]);

	const onChangeFileUpload = (info: any) => {
		if (info.file.status !== "uploading") {
			props.onUploadingStarted && props.onUploadingStarted(true);
		}
		if (info.file.status === "done") {
			setFileList(info.fileList);
			props.addFileToFileList && props.addFileToFileList(info.fileList);
			props.onUploadingDone && props.onUploadingDone(fileCreated);
			props.onUploadingDoneWithFormik && props.onUploadingDoneWithFormik(fileCreated, props.formik);
			props.onUploadingStarted && props.onUploadingStarted(false);
			message.success(`${info.file.name} ${translate(`${TRANSLATION_BASE_PATH}._SUCCESS_UPLOAD`)}`);
		} else if (info.file.status === "error") {
			message.error(`${info.file.name}  ${translate(`${TRANSLATION_BASE_PATH}._FAILED_UPLOAD`)}`);
		}
	};

	const customRequestUploader = (option: any) => {
		const { onSuccess, onError, action, file } = option;
		setProgress(1);
		return axios
			.put(action, file, {
				headers: {
					"Content-Type": file.type
				}
			})
			.then((respones: any) => {
				setProgress(100);

				return onSuccess(respones.body);
			})
			.catch((err: any) => {
				setProgress(0);
				return onError(err);
			});
	};

	const handleUplaod = () => {
		if (props.isPublic) {
			return new BucketS3Service()
				.getS3UrlPublicFiles(`${fileCreated.filePath}/${fileCreated.name}`, S3ActionType.UPLOAD)
				.then(s3SignedUrl => {
					return s3SignedUrl;
				});
		}
		return new BucketS3Service()
			.getS3UrlForAction(`${fileCreated.filePath}/${fileCreated.name}`, S3ActionType.UPLOAD)
			.then(s3SignedUrl => {
				return s3SignedUrl;
			});
	};

	const convertPhoto = (file: any, uploadImageType: UploadImageType, imageType: any) => {
		const avatarBaseCrop = 100;
		return Jimp.read(file).then(image => {
			const width = image.bitmap.width;
			const height = image.bitmap.height;
			let baseCrop = width > height ? (height > 720 ? 720 : height) : width > 1280 ? 1280 : width;
			let newWidth = 0;
			let newHeight = 0;
			switch (uploadImageType) {
				case UploadImageType.Cover:
					newWidth = width > height ? (baseCrop * 16) / 9 : baseCrop;
					newHeight = width > height ? baseCrop : (baseCrop * 9) / 16;
					break;
				case UploadImageType.LoginCover:
					newWidth = width > height ? (baseCrop * 34) / 27 : baseCrop;
					newHeight = width > height ? baseCrop : (baseCrop * 27) / 34;
					break;
				case UploadImageType.Avatar:
					newWidth = avatarBaseCrop;
					newHeight = avatarBaseCrop;
					break;
				case UploadImageType.QuizQuestion: {
					baseCrop = width > height ? (height > 300 ? 300 : height) : width > 1280 ? 1280 : width;
					newWidth = width > height ? (baseCrop * 16) / 10 : baseCrop;
					newHeight = width > height ? baseCrop : (baseCrop * 10) / 16;
					break;
				}
			}
			return (
				image
					.cover(newWidth, newHeight)
					// .autocrop()
					.quality(RedusedQualityBasedOnUploadImageType.get(uploadImageType) ?? 60)
					.getBase64Async(imageType)
			);
		});
	};

	const getBase64Photo = (file: File): Promise<string | ArrayBuffer | null> => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			return (reader.onload = () => {
				return resolve(reader.result);
			});
		});
	};

	const processPhoto = (file: File, uploadImageType: UploadImageType) => {
		return getBase64Photo(file)
			.then((fileInfo: any) => {
				return convertPhoto(fileInfo, uploadImageType, file.type);
			})
			.then((base64File: string) => {
				return fetch(base64File);
			})
			.then(photo => {
				return photo.arrayBuffer();
			})
			.then(buffer => {
				const newFile = new File([buffer], (fileCreated && fileCreated.name) || "", {
					type: fileCreated.contentType
				});
				return newFile;
			});
	};

	const transformFileBeforeUpload = (file: File, uploadImageType: UploadImageType): Promise<File> => {
		return processPhoto(file, uploadImageType).then((newFile: File) => {
			return newFile;
		});
	};

	const deleteFromS3 = (fileUploaded: any) => {
		if (props.isPublic) {
			return new BucketS3Service()
				.getS3UrlPublicFiles(`${fileUploaded.filePath}/${fileUploaded.name}`, S3ActionType.DELETE)
				.then(s3SignedUrl => {
					return fetch(s3SignedUrl, { method: "DELETE", body: fileUploaded.name });
				});
		}
		return new BucketS3Service()
			.getS3UrlForAction(`${fileUploaded.filePath}/${fileUploaded.name}`, S3ActionType.DELETE)
			.then(s3SignedUrl => {
				return fetch(s3SignedUrl, { method: "DELETE", body: fileUploaded.name });
			});
	};

	const removeProcedure = (fileToDelete: any, indexToRemove: number) => {
		fileList.splice(indexToRemove, 1);
		const newFileList = [...fileList];
		setFileList(newFileList);
		props.addFileToFileList && props.addFileToFileList(newFileList);

		if (props.onSoftDeleteFile) {
			props.onSoftDeleteFile(fileToDelete);
			return props.onUploadingDone && props.onUploadingDone(undefined);
		}

		return deleteFromS3(fileToDelete).then(() => {
			return props.onRemoveUpload && props.onRemoveUpload(fileToDelete);
		});
	};

	const onRemoveUploadedFile = (file: any) => {
		const indexToRemove = fileList.findIndex(fileUploaded => fileUploaded.uid === file.uid);
		const fileToDelete = fileList[indexToRemove];
		setProgress(0);
		if (fileToDelete) {
			fileToDelete.filePath = props.filePath;
			return removeProcedure(fileToDelete, indexToRemove);
		}
		return;
	};

	const onBeforeFileUpload = (file: any): PromiseLike<void> | boolean => {
		setProgress(0);

		const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
		const fileType = file.type;
		if (fileType.includes("image")) {
			if (Number(fileSizeMB) >= 5) {
				message.error(translate(`${TRANSLATION_BASE_PATH}._BIG_UPLOADED_FILE`));
				return false;
			}
		}
		const fileToUpload: FileDto = new FileDto({
			size: file.size,
			filePath: props.filePath,
			name: file.name,
			contentType: file.type
		});
		if (props.fileWithUniqueId)
			fileToUpload.name = `${fileToUpload.id}.${file.name.substr(file.name.lastIndexOf(".") + 1)}`;

		const oldFileUploaded = fileCreated;
		!props.multiple && oldFileUploaded && removeProcedure(oldFileUploaded, 1);

		setFileCreated(fileToUpload);
		const newFileList = props.multiple ? [...fileList, file] : [file];
		setFileList(newFileList);

		if (props.addFileToFileList) props.addFileToFileList(newFileList);
		if (props.onUploadingStarted) props.onUploadingStarted(true);

		return true;
	};

	const uploadProps = {
		accept:
			props.fileType === "IMAGE"
				? "image/*"
				: props.fileType === "VIDEO"
				? "video/*"
				: props.fileType === "PDF"
				? ".pdf"
				: "image/*,video/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx," +
				  ".pptx,.ppt,.csv",
		onChange: (file: any) => onChangeFileUpload(file),
		action: () => handleUplaod(),
		customRequest: (options: any) => customRequestUploader(options),
		beforeUpload: (file: any) => onBeforeFileUpload(file),
		onRemove: (file: any) => onRemoveUploadedFile(file),
		showUploadList:
			props.showUploadList !== undefined
				? props.showRemoveIcon !== undefined
					? { showPreviewIcon: props.showUploadList, showRemoveIcon: props.showRemoveIcon }
					: props.showUploadList
				: true
	};

	const allUploadProps =
		props.fileType !== "IMAGE"
			? uploadProps
			: {
					...uploadProps,
					transformFile: (file: File) =>
						transformFileBeforeUpload(file, props.uploadImageType ?? UploadImageType.Cover)
			  };

	const getUploadButtonBasedOnType = (): ReactNode => {
		if (props.uploadListType) {
			switch (props.uploadListType) {
				case "picture":
					return (
						<Button>
							<Space>
								<Icon type="ri-upload-line" />
								{translate(`${TRANSLATION_BASE_PATH}._BTN_UPLOAD`)}
							</Space>
						</Button>
					);
				case "picture-card":
					return (
						<div>
							{progress > 0 && progress !== 100 ? (
								<LoadingOutlined />
							) : (
								<>
									<Icon type="ri-add-line" />
									<br />
									<Text fontSize="14" lineHeight="22" className="color-gray-8 mt-8">
										{translate(`${TRANSLATION_BASE_PATH}._BTN_UPLOAD`)}
									</Text>
								</>
							)}
						</div>
					);
			}
		}
		return (
			<Button>
				<Space>
					<Icon type="ri-upload-line" />
					{translate(`${TRANSLATION_BASE_PATH}._BTN_UPLOAD`)}
				</Space>
			</Button>
		);
	};

	const getUploader = () => {
		return [
			<UploadComp
				className={props.className}
				method="PUT"
				listType={props.uploadListType ? props.uploadListType : "picture"}
				multiple={false}
				fileList={fileList}
				{...allUploadProps}
				key="uploader"
			>
				{!props.children ? getUploadButtonBasedOnType() : props.children}
			</UploadComp>,
			progress > 0 && progress !== 100 ? (
				<Progress
					strokeColor={{
						from: "#9ecaff",
						to: "#2063e3"
					}}
					percent={100}
					status={progress !== 100 ? "active" : "success"}
					showInfo={false}
					key="uploader-progress"
				/>
			) : null
		];
	};

	const accept = AcceptRatioBasedOnUploadImageType.get(props.uploadImageType ?? UploadImageType.Cover);

	const noCropCase = props.noCrop ? (
		<>{getUploader()}</>
	) : (
		<ImgCrop fillColor="transparent" aspect={accept} modalTitle="Edito imazhin" modalOk="Ruaj" modalCancel="Anullo">
			{getUploader()}
		</ImgCrop>
	);

	return props.fileType === "IMAGE" ? noCropCase : <>{getUploader()}</>;
};
