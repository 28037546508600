import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CounterCard from "common/components/_counterCard/CounterCard";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._STAT_CARDS";

export const StatCards = (props: any) => {
	const { t: translate } = useTranslation();

	const RenderCard = (index: number, icon: string, data: number, size?: number) => {
		return (
			<Col xs={24} sm={24} md={12} lg={12} xl={size ?? 6} xxl={size ?? 6}>
				<CounterCard
					title={translate(`${TRANSLATION_BASE_PATH}._ITEM._${index}`)}
					icon={icon}
					data={data}
					loading={data === -1}
				/>
			</Col>
		);
	};

	const RenderFirstRow = () => {
		return (
			<Row gutter={[24, 0]}>
				{RenderCard(1, "ri-group-line", props.totalUsers)}
				{RenderCard(2, "ri-admin-line", props.totalAdmins)}
				{RenderCard(3, "ri-user-2-line", props.totalTeachers)}
				{RenderCard(4, "ri-user-line", props.totalStudents)}
			</Row>
		);
	};

	const RenderSecondRow = () => {
		return (
			<Row gutter={[24, 0]}>
				{RenderCard(5, "ri-door-open-line", props.totalVirtualClasses, 12)}
				{RenderCard(6, "ri-slideshow-3-line", props.totalAsyncClasses, 12)}
			</Row>
		);
	};

	return (
		<Row className="mt-16 ml-24 mr-24">
			<Col span="24">
				{RenderFirstRow()}
				{RenderSecondRow()}
			</Col>
		</Row>
	);
};
