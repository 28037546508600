export class CreateOrganizationEnrollmentChangeNotificationDto {
	UserId!: string;
	status!: string;
	organizationName!: string;
	readonly type? = "ORGANIZATION_ENROLLMENT_STATUS_CHANGE";

	constructor(id: string, status: string, organizationName: string) {
		this.UserId = id;
		this.status = status;
		this.organizationName = organizationName;
	}

	get title() {
		if (this.status === "ACTIVE") {
			return `Hyrja juaj në organizaten "${this.organizationName}" u aprovua.`;
		}
		if (this.status === "BLOCKED") {
			return `Hyrja juaj në organizatën "${this.organizationName}" u bllokua.`;
		}
		if (this.status === "CANCELLED") {
			return `Ju u çregjistruat nga organizatën "${this.organizationName}".`;
		}
		return `Aksesi juaj ne organizatën "${this.organizationName}" u ndryshua.`;
	}
}
