import React, { ReactNode } from "react";
import { Formik } from "formik";
import { formValidator } from "./ValidationSchema";
import Row from "../../../../common/components/layout/Row";
import Col from "../../../../common/components/layout/Col";
import Text from "../../../../common/components/general/Text";
import Input from "../../../../common/components/dataEntry/formik/FormikInputField";
import PasswordInput from "../../../../common/components/dataEntry/formik/FormikPasswordField";
import Button from "../../../../common/components/general/Button";
import { Link } from "react-router-dom";
import { FORGET_PASSWORD_PATH } from "../forgetPassword";
import { REGISTER_PATH } from "../register";
import { getDomain, isReservedDomain } from "../../../../services/common/check-domain";
import { JoinInstagramAlert } from "../../components/joinInstagramAlert";
import { SIGN_IN_PATH } from "./index";
import { useTranslation } from "react-i18next";
import Common from "./Common";
import { BigLayout } from "../../components/layout/BigLayout";
import Image from "../../../../common/components/general/Image";
import { getDesignFile } from "../../../../common/utils/Design";
import Logo from "../../../../assets/images/icons/WhiteLogo.svg";
import { useStoreState } from "../../../../store/hooks";
import RawCss from "../../../../common/components/utils/RawCss";

interface PropsInterface {
	pageComponents: { [key: string]: any };
}
interface FormComponentsProps {
	onSubmit: (values: any) => void;
	showAlertContent: () => ReactNode;
	predefinedEmail: string;
	submitting: boolean;
}

export default function login1(props: PropsInterface) {
	const hideCover = props.pageComponents?.loginCover?.status === 0;
	const classNames = {
		".login1__form,.big-layout": {
			"text-color": props.pageComponents.loginForm?.specs?.textColor,
			"background": props.pageComponents.loginForm?.specs?.background
		}
	};

	const FormComponent = ({ onSubmit, showAlertContent, predefinedEmail, submitting }: FormComponentsProps) => {
		const TRANSLATION_BASE_PATH = "_LOGIN._SIGNIN";
		const { t: translate } = useTranslation();
		const primaryLogo = useStoreState(state => state.designSettings.items.primaryLogo);
		const designLoading = useStoreState(state => state.designSettings.loading);
		let showRegister: boolean | number = parseInt(props.pageComponents.loginForm?.specs?.showRegister);
		showRegister = isNaN(showRegister) || !!showRegister;


		return (
			<div className="login1__form pl-40 pr-40">
				<RawCss
					classNames={classNames}
				/>

				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={{ username: predefinedEmail }}
					onSubmit={onSubmit}
					isInitialValid={false}
				>
					{formik => (
						<form onSubmit={formik.handleSubmit} autoComplete="off" className="pt-32">
							{showAlertContent()}
							<Row className="mt-32" gutter={24}>
								<Col xs={24}>
									<a href="https://www.akademi.al/">
										{!designLoading && (
											<Image className="login1__logo" src={getDesignFile(Logo, primaryLogo)} />
										)}
									</a>
								</Col>
							</Row>
							<Row gutter={24} className="mt-8">
								<Col xs={24} className="mt-24">
									<Input
										name="username"
										label={translate(`${TRANSLATION_BASE_PATH}._USERNAME`)}
										size="large"
										autoComplete="username"
										disabled={!!predefinedEmail}
									/>
								</Col>
							</Row>
							<Row gutter={24} className="mt-8">
								<Col xs={24}>
									<PasswordInput
										name="password"
										label={translate(`${TRANSLATION_BASE_PATH}._PASSWORD`)}
										showPopover={false}
										size="large"
										autoComplete="current-password"
									/>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={24}>
									<Button
										type="primary"
										htmlType="submit"
										icon="ri-arrow-right-line"
										size="large"
										iconPosition="right"
										className="full_width_button"
										disabled={!formik.isValid}
										loading={submitting}
									>
										{translate(`${TRANSLATION_BASE_PATH}._LOGIN_LABEL`)}
									</Button>
								</Col>
							</Row>
							<Row gutter={24} justify="center" className="mt-24">
								<Col>
									<Text fontSize="14" lineHeight="22">
										<Link to={`/${SIGN_IN_PATH}/${FORGET_PASSWORD_PATH}`}>
											{translate(`${TRANSLATION_BASE_PATH}._FORGET_PASSWORD`)}
										</Link>
									</Text>
								</Col>
							</Row>
						</form>
					)}
				</Formik>
				{showRegister && (
					<Row justify="center">
						<Col>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._DONT_HAVE_ACCOUNT`)}{" "}
								<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>
									{translate(`${TRANSLATION_BASE_PATH}._REGISTER_HERE`)}
								</Link>
							</Text>
						</Col>
					</Row>
				)}
				{(getDomain(window.location.hostname) === "akademi.al" ||
					isReservedDomain(window.location.hostname)) && <JoinInstagramAlert />}
			</div>
		);
	};

	return (
		<Common
			{...props}
			presentation={(innerProps: any) => (
				<>
					{hideCover ? (
						FormComponent(innerProps)
					) : (
						<BigLayout
							{...props.pageComponents.loginCover?.specs}
							formBackground={props.pageComponents.loginForm?.specs?.background}
							formTextColor={props.pageComponents.loginForm?.specs?.textColor}
						>
							{FormComponent(innerProps)}
						</BigLayout>
					)}
				</>
			)}
		/>
	);
}
