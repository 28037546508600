import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "common/components/general/Icon";
import { Row, Col, List, Space, Table } from "antd";
import Checkbox from "common/components/dataEntry/components/Checkbox";
import CheckboxGroup from "common/components/dataEntry/components/CheckboxGroup";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import MorningIcon from "assets/images/icons/morning.png";
import NoonIcon from "assets/images/icons/noon.png";
import AfternoonIcon from "assets/images/icons/afternoon.png";
import {Breakpoint} from "antd/es/_util/responsiveObserve";

const TRANSLATION_BASE_PATH = "_TUTORING._COLLECT_DATA._AVAILABILITY";

type Props = {
	updateData: (data: object) => void,
	setIsNextActive: (active: boolean) => void,
	times: Array<string>
}

let checkedTimes: Array<string> = [];
const tableData: Array<object> = [];
const timeMapping: {[key: number]: string} = {
	1: "MORNING",
	2: "NOON",
	3: "AFTERNOON"
};
for (let i = 1; i < 4; i++) {
	tableData.push({
		time: `_${timeMapping[i]}`,
		monday: `MONDAY ${timeMapping[i]}`,
		tuesday: `TUESDAY ${timeMapping[i]}`,
		wednesday: `WEDNESDAY ${timeMapping[i]}`,
		thursday: `THURSAY ${timeMapping[i]}`,
		friday: `FRIDAY ${timeMapping[i]}`,
		saturday: `SATURDAY ${timeMapping[i]}`,
		sunday: `SUNDAY ${timeMapping[i]}`
	});
}

const tableColumnProps = (weekend: boolean, name: string, props: Props) => {
	const { updateData, setIsNextActive } = props;
	return {
		props: {
			style: {backgroundColor: weekend ? "#F7FAFC" : ""}
		},
		children: (
			<Row justify="center" align="middle">
				<Checkbox onChange={e => {
					if (e.target.checked) {
						if (!checkedTimes.includes(e.target.value)) checkedTimes.push(e.target.value);
					} else {
						checkedTimes = checkedTimes.filter(time =>
							time !== e.target.value
						);
					}
					setIsNextActive(checkedTimes.length > 0);
					updateData({ times: checkedTimes });
				}} value={name}/>
			</Row>
		)
	};
};

export const Availability = (props: Props) => {
	const { t: translate } = useTranslation();
	const { times, setIsNextActive } = props;
	useEffect(() => {
		setIsNextActive(times.length > 0);
		if (times.length === 0) checkedTimes = [];
	}, [setIsNextActive, times.length]);
	const listData = [{
		title: translate(`${TRANSLATION_BASE_PATH}._MORNING`),
		icon: <Image src={MorningIcon} />,
		time: "08:00 - 12:00"
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._NOON`),
		icon: <Image src={NoonIcon} />,
		time: "12:00 - 16:00"
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._AFTERNOON`),
		icon: <Image src={AfternoonIcon} />,
		time: "16:00 - 20:00"
	}];
	const tableColumns = [{
		title: "",
		dataIndex: "time",
		render: (text: string) => {
			text = translate(`${TRANSLATION_BASE_PATH}.${text}`);
			return {
				props: {
					style: { backgroundColor: "#F0F8FF" }
				},
				children: (
					<Row justify="center" align="middle">
						<Col>
							<Row justify="center">{listData.find(data => data.title === text)?.icon}</Row>
							<Text fontSize="12">{text}</Text>
						</Col>
					</Row>
				)
			};
		}
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._MONDAY`),
		dataIndex: "monday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._MONDAY_SHORT`),
		dataIndex: "monday_short",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["xs" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._TUESDAY`),
		dataIndex: "tuesday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._TUESDAY_SHORT`),
		dataIndex: "tuesday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["xs" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._WEDNESDAY`),
		dataIndex: "wednesday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._WEDNESDAY_SHORT`),
		dataIndex: "wednesday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["xs" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._THURSDAY`),
		dataIndex: "thursday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._THURSDAY_SHORT`),
		dataIndex: "thursday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["xs" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._FRIDAY`),
		dataIndex: "friday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._FRIDAY_SHORT`),
		dataIndex: "friday",
		render: (text: string) => {
			return tableColumnProps(false, text, props);
		},
		responsive: ["xs" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._SATURDAY`),
		dataIndex: "saturday",
		render: (text: string) => {
			return tableColumnProps(true, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title: translate(`${TRANSLATION_BASE_PATH}._SATURDAY_SHORT`),
		dataIndex: "saturday",
		render: (text: string) => {
			return tableColumnProps(true, text, props);
		},
		responsive: ["xs" as Breakpoint]
	},
	{
		title:
			translate(`${TRANSLATION_BASE_PATH}._SUNDAY`),
		dataIndex: "sunday",
		render: (text: string) => {
			return tableColumnProps(true, text, props);
		},
		responsive: ["sm" as Breakpoint]
	},
	{
		title:
			translate(`${TRANSLATION_BASE_PATH}._SUNDAY_SHORT`),
		dataIndex: "sunday",
		render: (text: string) => {
			return tableColumnProps(true, text, props);
		},
		responsive: ["xs" as Breakpoint]
	}];
	
	return (
		<>
			<p className="tutoring__ws">{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</p>
			<Space direction="vertical" size="large">
				<List
    			dataSource={listData}
					renderItem={item => (
						<List.Item className="pt-4 pb-4" extra={(
							<Row>
								<Space >
									<Icon type="ri-time-line" fontSize="20" className="tutoring__icon" />
									<Text fontSize="14">{item.time}</Text>
								</Space>
							</Row>
						)}>
							<Row>
								<Space >
									{item.icon}
									<Text fontSize="14">{item.title}</Text>
								</Space>
							</Row>
						</List.Item>
					)}>
				</List>
				<Row className="mb-24">
					<CheckboxGroup defaultValue={times} name="availability">
						<Table
							className="tutoring__table_header"
							dataSource={tableData}
							columns={tableColumns}
							size="small"
							pagination={false}
						/>
					</CheckboxGroup>
				</Row>
			</Space>
		</>
	);
};