import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import OAuth2Service from "services/authentication/oauth2.service";
import {MAIN_PATH} from "scenes/main";

const LandingPageRoute = ({ component: Component, ...rest }: RouteProps) => {
	if (!Component) return null;
	return (
		<Route
			{...rest}
			render={props =>
				OAuth2Service.isLoggedIn ? (
					<Redirect
						to={{
							pathname: `/${MAIN_PATH}`,
							state: { from: props.location }
						}}
					/>
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default LandingPageRoute;
