import { StudentWelcomeScene } from "./welcome/index";
import { ChooseGrade } from "./chooseGrade";
import { ChooseLevel } from "./chooseLevel";
import { ChooseSubjects } from "./chooseSubjects";
import { VirtualClassroom } from "./virtualClassroom";
import { JoinOrganisation } from "./joinOrganisation";
import { GradeLevel } from "services/domain/administration/Grade";
import { EducationLevel } from "services/domain/administration/Education";
import { ChooseNationality } from "./chooseNationality";
import { ProfilePicture } from "./profilePicture";
import OAuth2Service from "services/authentication/oauth2.service";

export interface OnboardingState {
	id?: string;
	role?: string;
	nationality?: string;
	level?: GradeLevel;
	education?: EducationLevel,
	grade?: string;
	classRooms?: string[];
	virtualClassCode?: string;
	organisationCode?: string;
	profilePicture?: string;
	profilePictureType?: string;
	profilePictureFile?: any;
	navigationItems: WizardStep[];
}

export interface WizardStep {
	id: OnboardingStep;
	priority: number;
	status: StepStatus;
}

export enum StepStatus {
	Active = 0,
	Complete = 1,
	Disabled = 2
}

export enum OnboardingStep {
	Welcome = "welcome",
	ProfilePicture = "profile-picture",
	ChooseNationality = "choose-nationality",
	LevelSelection = "choose-level;",
	GradeSelection = "select-grade",
	ChooseSubjects = "choose-subjects",
	VirtualClassroom = "virtual-classroom",
	OrganisationCode = "organisation-code"
}

export interface NavItemProps {
	id: OnboardingStep;
	itemsToSkip?: number;
	component: React.ComponentType<any>;
	show?: boolean
}

export interface BaseComponentProps {
	name: string;
	setIsFormValid: (isValid: boolean) => void;
	setIsSubmiting: (submiting: boolean) => void;
	setTitle: (title: string) => void;
	state: OnboardingState;
	updateState: (state: OnboardingState) => void;
	bindSubmitFormHandler: any;
	goNext: () => void;
	goBack: () => void;
	finish: (stateId: string, organizationCode: string, isOwner: boolean) => void;
}

export const getNavItems = (): NavItemProps[] => 
	[{
		id: OnboardingStep.Welcome,
		component: StudentWelcomeScene,
		show: true
	},
	{
		id: OnboardingStep.ChooseNationality,
		component: ChooseNationality,
		show: true
	},
	{
		id: OnboardingStep.ProfilePicture,
		component: ProfilePicture,
		show: true
		},
	{
		id: OnboardingStep.LevelSelection,
		component: ChooseLevel,
		show: true
	},
	{
		id: OnboardingStep.GradeSelection,
		component: ChooseGrade,
		itemsToSkip: 1,
		show: !OAuth2Service.isNotAtSuperOrganization
	},
	{
		id: OnboardingStep.ChooseSubjects,
		component: ChooseSubjects,
		show: !OAuth2Service.isNotAtSuperOrganization
	},
	{
		id: OnboardingStep.VirtualClassroom,
		component: VirtualClassroom,
		show: true
	},
	{
		id: OnboardingStep.OrganisationCode,
		component: JoinOrganisation,
		show: !OAuth2Service.isNotAtSuperOrganization
	}];
