import React, { ReactElement } from "react";
import { Row, Col, Button, Space, Dropdown } from "antd";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { ADMIN_PATH } from "../..";
import { InstitutionCode } from "../institutionCode/InstitutionCode";
import {useTranslation} from "react-i18next";
import { NavLink } from "react-router-dom";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._SECTION_HEADER";

interface SectionTitleProps {
	title: string;
	smallTitle?: string;
	subtitle?: string[];
	subtitleUrl: string[];
	dropDownMenu?: ReactElement;
	certificationState?: string;
	canPersonalize?: boolean;
	customizeUrl?: string;
}

export const SectionTitle = (props: SectionTitleProps) => {
	const { t: translate } = useTranslation();
	
	const renderCertState = (state: any) => {
		return (
			<Space>
				<Icon
					type="ri-checkbox-blank-circle-fill"
					className={
						(!state || state === "PENDING_VALIDATION")
							? "color-gray-5 "
							: state === "ISSUED_CONNECTED"
								? "color-green-6"
								: state === "ISSUED_NOT_CONNECTED"
									? "color-orange-6"
									: "color-red-6"
					}
				></Icon>
				{`Çertifikata: ${translate(`${TRANSLATION_BASE_PATH}._CERT_STATE_${state}`)}`}
			</Space>
		);
	};

	return (
		<>
			<Row className="analytics__section__title pl-24 pr-24 pt-16 pb-16" align="middle">
				<Col xs={props.canPersonalize ? 24 : 28} sm={props.canPersonalize ? 24 : 18} md={18} lg={18} xl={18} xxl={18}>
					{props.subtitle && (
						<Row>
							<Col>
								<Text fontSize="14" lineHeight="22" >
									{props.subtitle && props.subtitle.map((item: any, index: number) => {
										const url = props.subtitle && index < props.subtitle?.length - 1 ?
											"/" + ADMIN_PATH + "/" + props.subtitleUrl[ index ] : "";
										const divider = (props.subtitle && index < props.subtitle?.length - 1) ? "/" : "";
										const color = (props.subtitle && index < props.subtitle?.length - 1) ? "color-gray-7" : "color-gray-8";
										return (
											<>
												<a className={color} href={url}>
													{item}
												</a>
												<span className="ml-8 mr-8">{divider}</span>
											</>);
									})}
								</Text>
							</Col>
						</Row>
					)}
					{props.title && (
						<Row align="middle">
							<Col>
								<Text fontSize="20" lineHeight="28" className="color-gray-9"  >
									{
										props.subtitle && props.subtitle.length > 2 && (
											<a href={"/"+ADMIN_PATH+"/"+props.subtitleUrl[props.subtitleUrl.length - 1]}>
												<Icon className="mr-8 cursor_pointer" type="ri-arrow-left-line" />
											</a>
										)
									}
								</Text>
							</Col>
							<Col>
								<Space>
									<Text fontSize="20" lineHeight="32" className="color-gray-9" wheight="semibold">
										{props.title}
									</Text>
									<Text fontSize="14" lineHeight="20" className="color-gray-7">
										{props.smallTitle && <InstitutionCode code={props.smallTitle??""} />}
									</Text>
									{props.certificationState && (<Text fontSize="14" lineHeight="20" className="color-gray-7">
										{renderCertState(props.certificationState)}
									</Text>)}
								</Space>
							</Col>
						</Row>
					)}
				</Col>

				{props.canPersonalize && props.customizeUrl && <Col xs={20} sm={20} md={4} lg={5} xl={5} xxl={5}>
					<Row justify="end">
						<NavLink to={props.customizeUrl}>
							<Button
								type="primary"
							>
								{translate(`${TRANSLATION_BASE_PATH}._PERSONALIZE`)}
							</Button>
						</NavLink>
					</Row>
				</Col>}
				{props.dropDownMenu &&
					<Col
						xs={4}
					 	sm={props.canPersonalize ? 4 : 6}
						md={props.canPersonalize ? 2 : 6}
						lg={props.canPersonalize ? 1 : 6}
						xl={props.canPersonalize ? 1 : 6}
						xxl={props.canPersonalize ? 1 : 6}
					>
						<Row justify="end">
							<Dropdown.Button
								buttonsRender={() => [<></>,
								<Button
									key="1"
									type="default"
									shape="circle"
									className="materialItem__layout materialItem__button-circle color-gray-8"
								>
									<Icon type="ri-more-2-line" />
								</Button>]}
								trigger={["click"]}
								overlay={props.dropDownMenu}
								icon={<Icon type="ri-more-2-line" />}
							/>
						</Row>
					</Col>
				}
			</Row>
		</>
	);
};
