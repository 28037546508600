import { Button, Col, Row, Space } from "antd";
import React from "react";

import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import WelcomeImage from "assets/images/select-organization-welcome-teacher.svg";
import OAuth2Service from "services/authentication/oauth2.service";

export const InvitationMessage = (props: any) => {
	return (
		<>
			<Row>
				<Col span={24} className="flex__center_justify_center">
					<Image src={WelcomeImage} />
				</Col>
			</Row>
			<Row>
				<Col span={24} className="flex__center_justify_center">
					<Space direction="vertical" size={16} align="center">
						<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-gray-9">
							Përshëndetje {OAuth2Service.CurrentUser.User.firstName},
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-8">
							Faleminderit që u bëtë pjesë e grupit të mësuesve të akademi.al!
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-8">
							Ftesa juaj për të hyrë në organizatë u dërgua!
						</Text>
						<Text fontSize="16" lineHeight="24" className="color-gray-8" wheight="semibold">
							Ju do të lejoheni të bëheni pjesë e organizatës sapo kërkesa juaj të aprovohet.
						</Text>
					</Space>
				</Col>
			</Row>
			<Row className="mt-32">
				<Col span={24} className="flex__center_justify_center">
					<Button type="primary" onClick={props.onClose}>
						Hyr në portalin e mësuesit
					</Button>
				</Col>
			</Row>
		</>
	);
};
