import React from "react";
import classNames from "classnames";
import { Tooltip } from "antd";

declare type FontSize = "12" | "14" | "16" | "20" | "24" | "30" | "38" | "40" | "46" | "56";

export interface IconProps {
	type: string;
	className?: string;
	onClick?: () => void;
	style?: React.CSSProperties;
	fontSize?: FontSize;
	title?: string;
	tooltip?: string;
}

const Icon: React.FC<IconProps> = props => {
	const iconClassNames = classNames({
		[props.type]: true,
		[props.className ?? ""]: true,
		[`text_font_size_${props.fontSize}`]: props.fontSize !== undefined
	});
	return (
		<Tooltip title={props.tooltip}>
			<i className={iconClassNames} onClick={props.onClick} style={props.style} title={props.title}></i>
		</Tooltip>
	);
};

export default Icon;
