import ApiServiceData from "../api.service";
const relativeUrl = "/v1/api/grades";

export default class GradeService extends ApiServiceData {
	public getAllGrades(): Promise<object> {
		return this.get(relativeUrl)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createGrade(grade: any) {
		return this.post(`${relativeUrl}`, grade);
	}

	public updateGrade(gradeId: string, grade: any) {
		return this.update(`${relativeUrl}/${gradeId}`, grade);
	}
}
