import React, { ReactNode, createContext, useState } from "react";
import { Layout, Row, Col } from "antd";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";

import { ItemFilters } from "../../../../components/itemFilters/ItemFilters";
import { SUBJECTS_PATH } from "../../../..";
import { RELATED_SUBJECTS_PATH } from "../../scenes/courseList";
import { IndividualLiveCourseBanner } from "./IndividualLiveCourseBanner";

const { Content } = Layout;

interface LayoutProps {
	children: ReactNode,
	showFilters?: boolean,
}

type ChildProps = {
	setParentTag: any;
	setIndividualLiveCourseBannerVisible: any;
};

export const ChildContext = createContext<ChildProps>({
	setParentTag: null,
	setIndividualLiveCourseBannerVisible: null
});

export const MainLayout = (props: LayoutProps) => {
	const [parentTag, setParentTag] = useState(undefined);
	const [individualLiveCourseBannerVisible, setIndividualLiveCourseBannerVisible] = useState<boolean>(false);
	const subjectsPath = `/${SUBJECTS_PATH}`;
	const relatedSubjectsPath = `/${SUBJECTS_PATH}${RELATED_SUBJECTS_PATH}`;
	const location = useLocation();
	const matchesPath =
		matchPath(location.pathname, { path: subjectsPath, exact: true }) ||
		matchPath(location.pathname, { path: relatedSubjectsPath, exact: true });
	const ContentStyle = matchesPath ? "subject_items__layout_content" : "";
	const MaxWidthStyle = matchesPath ? "subject_items__layout_max_width" : "";
	return (
		<Layout>
			<Content className={`background-color-gray-2 ${ContentStyle}`}>
				<Row justify="center" className={MaxWidthStyle}>
					<Col
						xs={props.showFilters ? 24 : 0}
						sm={props.showFilters ? 24 : 0}
						md={props.showFilters ? 8 : 0}
						lg={8}
						xl={5}
						xxl={5}
						className="pr-24">
						{individualLiveCourseBannerVisible ? (
							<IndividualLiveCourseBanner />
						) : (
							<ItemFilters parentTag={parentTag} />
						)}
					</Col>
					<Col xs={24} sm={24} md={props.showFilters ? 16 : 24} lg={16} xl={17} xxl={16}>
						<ChildContext.Provider
							value={{
								setParentTag: setParentTag,
								setIndividualLiveCourseBannerVisible: setIndividualLiveCourseBannerVisible
							}}
						>
							{props.children}
						</ChildContext.Provider>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};
