import React from "react";
import { Alert } from "antd";
import { AlertProps } from "antd/lib/alert";

type Props = AlertProps;

const Feedback = (props: Props) => {
	return <Alert {...props}></Alert>;
};

export default Feedback;
