import { Drawer, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Formik } from "formik";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { showMessage, showError } from "common/utils/Notification";
import { formValidator } from "./ValidationSchema";
import InvitationService from "services/administration/invitation.service";
import EnrollmentService from "services/administration/enrollment.service";
import EnrollmentDto from "dtos/administration/enrollment.dto";

interface InviteStudentsDrawerProps {
	classroomId: string;
	visible: boolean;
	onCancel: () => void;
	onSuccess: () => void;
	showHelperInfo?: boolean;
}

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._INVITE_STUDENTS";

export const InviteStudentsDrawer = (props: InviteStudentsDrawerProps) => {
	const { t: translate } = useTranslation();
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	let submitFormHandler: any = Promise;

	const drawerFooter = (
		<Space direction="horizontal" size={8} style={{ float: "right" }}>
			<Button type="default" onClick={props.onCancel} disabled={submitting}>
				{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
			</Button>
			<Button
				type="primary"
				disabled={!isFormValid}
				onClick={() => {
					submitFormHandler();
				}}
				loading={submitting}
			>
				{translate(`${TRANSLATION_BASE_PATH}._SEND_INVITES`)}
			</Button>
		</Space>
	);

	const emailAddressesHeader = (values: any) => {
		return (
			<Space direction="horizontal" className="mb-8" align="center">
				<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._EMAIL_ADRESSES`)}{" "}
					{values.emails.filter((item: any) => item.email && item.email !== "").length > 0
						? `(${values.emails.filter((item: any) => item.email && item.email !== "").length})`
						: ""}
				</Text>
				<Icon
					type="ri-information-line"
					fontSize="20"
					className="color-blue-6"
					tooltip={translate(`${TRANSLATION_BASE_PATH}._PASTE_MULTIPLE_EMAILS_TOOLTIP`)}
				/>
			</Space>
		);
	};

	const onSubmit = async (values: any) => {
		setSubmitting(true);

		const enrolledUsers = await new EnrollmentService()
			.getAllEnrollmentsByClassroomId(props.classroomId);
		const emails = values.emails
			.filter((item: any) => !enrolledUsers.some((enrolled: EnrollmentDto) =>
				enrolled?.User?.email === item.email || enrolled?.User?.parentEmail === item.email))
			.map((item: any) => item.email);

		await new InvitationService()
			.inviteStudents(props.classroomId, emails)
			.then(() => {
				setSubmitting(false);
				showMessage(translate(`${TRANSLATION_BASE_PATH}._INVITATIONS_SUCCEDED`));
				props.onSuccess();
				return;
			})
			.catch((error) => {
				const data = error.data;
				if(data && data.message === "Can't send email to yourself"){
					showError(translate(`${TRANSLATION_BASE_PATH}._INVITATIONS_ERROR_SAME_EMAIL`))
				}else if(data && data.message === "remove your email"){
					showError(translate(`${TRANSLATION_BASE_PATH}._INVITATIONS_ERROR_EXIST_EMAIL`));
				}else{
					showError(translate(`${TRANSLATION_BASE_PATH}._ERROR_SENDING_INVITATIONS`));
				}
				
				setSubmitting(false);
			});
	};

	return (
		<Drawer
			title={translate(`${TRANSLATION_BASE_PATH}._INVITE_STUDENTS_TITLE`)}
			onClose={props.onCancel}
			visible={props.visible}
			footer={drawerFooter}
			className="invite__students_drawer"
			destroyOnClose={true}
		>
			<div className="invite__students_content">
				<Space direction="vertical" style={{ width: "100%" }}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._INVITE_STUDENTS_HELPER_DESCRIPTION`)}
					</Text>

					<Formik
						enableReinitialize={true}
						validationSchema={formValidator(translate)}
						initialValues={{
							emails: [{ email: "" }]
						}}
						onSubmit={onSubmit}
						validate={values => {
							setIsFormValid(formValidator(translate).isValidSync(values));
							return {};
						}}
					>
						{formik => {
							const { handleSubmit, submitForm, values } = formik;
							submitFormHandler = submitForm;
							return (
								<form onSubmit={handleSubmit} autoComplete="off">
									{emailAddressesHeader(values)}
									<FieldArray name="emails">
										{({ push, remove, replace }) => {
											return (
												<>
													<Input
														name={"emails[0].email"}
														value={values.emails[0].email}
														autoComplete="off"
														onPaste={e => {
															const pastedContent = e.clipboardData.getData("text/plain");
															let pastedEmails: string[] = pastedContent.split("\n");
															if (!pastedEmails || pastedEmails.length < 1) {
																pastedEmails = pastedContent.split(" ");
															}
															if (pastedEmails && pastedEmails.length > 1) {
																replace(0, { email: pastedEmails[0] });
																for (let i = 1; i < pastedEmails.length; i++) {
																	push({ email: pastedEmails[i] });
																}

																e.preventDefault();
															}
														}}
													/>
													{values.emails?.map((item: any, index: number) => {
														if (index === 0) return null;
														return (
															<div className="student__email_input" key={index}>
																<Input
																	name={`emails[${index}].email`}
																	placeholder="psh nxënës@email.com"
																	value={item.email}
																	autoComplete="off"
																/>
																<Button
																	type="default"
																	shape="circle"
																	className="ml-8"
																	onClick={() => remove(index)}
																>
																	<Icon type="ri-delete-bin-7-line" />
																</Button>
															</div>
														);
													})}
													<Button
														icon="ri-add-circle-line"
														type="link"
														className="pl-0"
														onClick={() => push({ email: "" })}
													>
														{translate(`${TRANSLATION_BASE_PATH}._ADD_ANOTHER_ONE`)}
													</Button>
												</>
											);
										}}
									</FieldArray>
								</form>
							);
						}}
					</Formik>
				</Space>
				{props.showHelperInfo && (
					<Space direction="horizontal" align="start" className="mt-40">
						<Icon type="ri-information-line" className="color-blue-6" fontSize="20" />
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._INVITE_FROM_STUDENTS_MENU`)}{" "}
							<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._STUDENTS_MENU`)}.
							</Text>
						</Text>
					</Space>
				)}
			</div>
		</Drawer>
	);
};
