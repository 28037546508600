import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Radio, Space } from "antd";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import GradeSubjectDto from "dtos/administration/gradeSubject.dto";
import Input from "common/components/dataEntry/components/Input";
import Icon from "common/components/general/Icon";
import Text from "antd/lib/typography/Text";
import TextElement from "common/components/general/Text";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";
import CheckboxGroup from "antd/lib/checkbox/Group";
import CheckboxElement from "common/components/dataEntry/components/CheckBoxGroupElement";

const TRANSLATION_BASE_PATH = "_TUTORING._COLLECT_DATA._GRADE";

type Props = {
	updateData: (data: object) => void,
	selectedGrades: ({ subjectName: string, grades: Array<string>})[],
	selectedSubjectsGrades: ({ name: string, icon: string, grades: (GradeSubjectDto[] | undefined) })[],
	setIsNextActive: (active: boolean) => void,
}

const MAX_CHAR_LENGTH = 100;

export const Grade = (props: Props) => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const isTeacher = currentUserInfo?.Roles.some((role: any) => role.code === Role.Teacher);
	const { t: translate } = useTranslation();
	const { updateData, selectedGrades, selectedSubjectsGrades, setIsNextActive } = props;
	const [selectedGrade, setSelectedGrade] = useState("");
	const [other, setOther] = useState("");
	let defaultValue = null;
	const defaultGrade = () => {
		if (isTeacher) {
			if (selectedGrades.length === selectedSubjectsGrades.length) setIsNextActive(true);
			else setIsNextActive(false);
		} else {
			if (selectedGrades.length > 0 && !other) {
				if (selectedSubjectsGrades[0]?.grades?.some(gradeSubject => gradeSubject?.Grade?.name === selectedGrades[0].grades[0])) {
					setIsNextActive(true);
					return selectedGrades[0].grades[0];
				}
				setOther(selectedGrades[0].grades[0]);
				setSelectedGrade("Other");
				setIsNextActive(true);
				return "Other";
			}
			return "";
		}
	};
	defaultValue = defaultGrade();
	return (
		<Col span={24}>
			{!isTeacher ? (
				<>
					<Row gutter={[0, 12]}>{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</Row>
					<Row className="tutoring__scrollable">
						<Col span={24}>
							<Radio.Group
								defaultValue={defaultValue}
								name="grades"
								onChange={e => {
									if (e.target.value !== "Other") {
										updateData({
											grades: [{
												subjectName: selectedSubjectsGrades[0].name,
												grades: [e.target.value]
											}]
										});
									}
									else updateData({ grades: [] });
									setIsNextActive(e.target.value !== "Other");
									setOther("");
									setSelectedGrade(e.target.value);
								}}
							>
								<Row gutter={[0, 10]}>
									{selectedSubjectsGrades[0]?.grades?.map((gradeSubject: GradeSubjectDto) => {
										const gradeName = gradeSubject?.Grade?.name;
										return (
											<Col key={gradeName} span={24}>
												<RadioElement value={gradeName}>
													{gradeName}
												</RadioElement>
											</Col>
										);
									})}
									<Col key="Other" span={24}>
										<RadioElement value="Other">
											{translate(`${TRANSLATION_BASE_PATH}._OTHER`)}
										</RadioElement>
									</Col>
								</Row>
							</Radio.Group>
							<Row>
								{selectedGrade === "Other" && (
									<Col span={24}>
										<Row className="mb-24">
											<Col span={24}>
												<Text>{translate(`${TRANSLATION_BASE_PATH}._OTHER`)}</Text>
											</Col>
											<Col span={24}>

												<Input
													maxLength={MAX_CHAR_LENGTH}
													placeholder={translate(`${TRANSLATION_BASE_PATH}._SPECIFY_OTHER`)}
													value={other}
													onChange={e => {
														if (other !== e.target.value) {
															updateData({ grades: [{
																subjectName: selectedSubjectsGrades[0].name,
																grades: [e.target.value.trim()]
															}] });
															setOther(e.target.value);
															setIsNextActive( e.target.value.trim() ? true : false);
														}
													}}
												/>
											</Col>
										</Row>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
				</>
			) : (
				<>
					<Row gutter={[0, 12]}>{translate(`${TRANSLATION_BASE_PATH}._TITLE_TEACHER`)}</Row>
					<Row className="tutoring__scrollable">
						<Col span={24}>
							{selectedSubjectsGrades.map(subject => {
								const grade = selectedGrades.find(grade => grade.subjectName === subject.name);
								return (
									<Row align="middle" key={subject.name}>
										<Col span={24}>
											<Row>
												<Space direction="horizontal">
													<Icon fontSize="20" className="tutoring__grade_icon" type={`ri-${subject.icon}`} />
													<TextElement wheight="semibold" fontSize="16">{subject.name}</TextElement>
												</Space>
											</Row>
											<Row className="mt-6">
												<CheckboxGroup
													name={subject.name}
													defaultValue={selectedGrades.find(grade => grade.subjectName === subject.name)?.grades}
													onChange={e => {
														if (selectedGrades.some(grade => grade.subjectName === subject.name)) {
															const removedGrades = selectedGrades.filter(grade => grade.subjectName !== subject.name);
															const otherGrade = grade?.grades.find(selectedGrade =>
																!subject.grades?.some(grade => grade.Grade?.name === selectedGrade));
															if (grade) {
																if (otherGrade) grade.grades = [...e.map(grade => grade.toString()), otherGrade];
																else grade.grades = e.map(grade => grade.toString());
															}
															if (e.length > 0 || otherGrade) updateData({ grades: [...removedGrades, grade] });
															else {
																setIsNextActive(false);
																updateData({ grades: [...removedGrades] });
															}
														} else {
															const newGrades = [...selectedGrades,
																{ subjectName: subject.name, grades: e.map(grade => grade.toString()) }];
															if (newGrades.length === selectedSubjectsGrades.length) setIsNextActive(true);
															else setIsNextActive(false);
															updateData({ grades: newGrades });
														}
													}}
												>
													<Row gutter={[0, 10]}>
														<Col span={24} className="pb-0">
															{subject.grades?.map(grade => (
																<CheckboxElement className="ml-0" key={grade.GradeId} value={grade.Grade?.name}>
																	{grade.Grade?.name}
																</CheckboxElement>
															))}
														</Col>
													</Row>
												</CheckboxGroup>
												<Col span={24}>
													<Row>
														<Col span={24}>
															<Input
																placeholder={translate(`${TRANSLATION_BASE_PATH}._OTHER`)}
																width="100%"
																defaultValue={grade?.grades.find(selectedGrade =>
																	!subject.grades?.some(grade => grade.Grade?.name === selectedGrade))}
																onChange={e => {
																	const grade = selectedGrades.find(grade => grade.subjectName === subject.name);
																	const otherGrades = selectedGrades.filter(grade => grade.subjectName !== subject.name);
																	const filteredGrades = grade?.grades.filter(selectedGrade =>
																		selectedSubjectsGrades.some(subjectGrade =>
																			subjectGrade.grades?.some(grade =>
																				grade.Grade?.name === selectedGrade))) || [];
																	if (e.target.value.trim()) {
																		filteredGrades?.push(e.target.value.trim());
																		updateData({
																			grades: [...otherGrades,
																				{ subjectName: subject.name, grades: [...filteredGrades] }]
																		});
																	} else {
																		updateData({ grades: [...otherGrades] });
																	}
																}}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								);
							}
							)}
						</Col>
					</Row>
				</>
			)}
		</Col>
	);
};