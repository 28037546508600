import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ParentDashboard, { PARENT_DASHBOARD_PATH } from "./scenes/dashboard/index";
import { MainLayout } from "./components/layout/Layout";
import ParentChildSettings, { PARENT_CHILD_SETTINGS_PATH } from "./scenes/childSettings";
import ParentChildStatistics, { PARENT_CHILD_STATISTICS_PATH } from "./scenes/childStatistics";
import { ParentSettings, PARENT_SETTINGS_PATH } from "./scenes/changeSettings";
import { ParentPassword, PARENT_PASSWORD_PATH } from "./scenes/changePassword";

export const PARENT_PATH = "parent";

class Parent extends Component<any, any> {
	render() {
		return (
			<MainLayout>
				<Switch>
					<Route path={`/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`} exact component={ParentDashboard} />
					<Route path={`/${PARENT_PATH}/${PARENT_SETTINGS_PATH}`} exact component={ParentSettings} />
					<Route path={`/${PARENT_PATH}/${PARENT_PASSWORD_PATH}`} exact component={ParentPassword} />
					<Route
						path={`/${PARENT_PATH}/${PARENT_CHILD_SETTINGS_PATH}/:id`}
						exact
						component={ParentChildSettings}
					/>
					<Route
						path={`/${PARENT_PATH}/${PARENT_CHILD_STATISTICS_PATH}/:id`}
						exact
						component={ParentChildStatistics}
					/>
					<Route
						path={`/${PARENT_PATH}`}
						exact
						component={() => <Redirect to={`/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`} />}
					/>
					<Route
						path={`/${PARENT_PATH}/*`}
						component={() => <Redirect to={`/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`} />}
					/>
				</Switch>
			</MainLayout>
		);
	}
}

export default Parent;
