/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import React, { ReactNode } from "react";
import { Row, Col, Card, Empty } from "antd";
import i18n from "i18next";
import classnames from "classnames";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import DefaultClassroomCover from "assets/images/main-classes-img1.svg";
import OAuth2Service from "services/authentication/oauth2.service";

export interface ClassItem {
	id: string;
	name: string;
	organizationName: string;
	teacherName?: string;
	filePath?: string;
	isAsync: boolean;
	pathToClassroom: string;
}

interface ClassSliderProps {
	items: ClassItem[];
	hideTeacherName?: boolean;
	title?: string;
}

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._CLASS_SLIDER";

class ClassSlider extends React.Component<ClassSliderProps, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			index: 0,
			showAll: false,
			showAllVisible: this.props.items.length > this.decrementFromWindowSize,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items.slice(0, this.decrementFromWindowSize),
			currentUser: OAuth2Service.CurrentUser
		};
	}

	setInitialState() {
		this.setState({
			index: 0,
			showAllVisible: this.props.items.length > this.decrementFromWindowSize,
			sliderLeftVisible: false,
			sliderRightVisible: !this.state.showAll && this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items
		});
	}

	componentDidMount() {
		window.addEventListener("resize", this.setInitialState.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setInitialState.bind(this));
	}

	toggleShowAll = () => {
		this.setState({
			index: 0,
			showAll: !this.state.showAll,
			sliderLeftVisible: false,
			sliderRightVisible: this.state.showAll,
			currentItems: this.props.items
		});
	};

	scroll = (step: number) => {
		if (this.state.index + step >= 0) {
			this.setState({
				index: this.state.index + step,
				sliderLeftVisible: this.state.index + step > 0,
				sliderRightVisible: this.state.index + step < this.state.items.length - this.decrementFromWindowSize,
				currentItems: this.state.items.slice(
					this.state.index + step,
					this.state.index + this.decrementFromWindowSize + 1
				)
			});
		}
	};

	get decrementFromWindowSize(): number {
		let decrement = 1;
		if (window.innerWidth >= 1600) {
			decrement = 4;
		} else if (window.innerWidth >= 1200) {
			decrement = 4;
		} else if (window.innerWidth >= 576) {
			decrement = 2;
		} else {
			decrement = 1;
		}
		return decrement;
	}

	get Title(): ReactNode {
		return (
			<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
				{i18n.t(this.props.title ? this.props.title : `${TRANSLATION_BASE_PATH}._CONTINUE`, {
					count: this.props.items.length
				})}
			</Text>
		);
	}

	get ShowAllLink(): ReactNode {
		const allLabel = i18n.t(`${TRANSLATION_BASE_PATH}._LINK_SHOW_ALL`);
		const someLabel = i18n.t(`${TRANSLATION_BASE_PATH}._LINK_NOT_SHOW_ALL`);
		const label = this.state.showAll ? someLabel : allLabel;
		return (
			this.state.showAllVisible && (
				<Text
					fontSize="14"
					lineHeight="22"
					className="color-blue-6 cursor_pointer"
					onClick={this.toggleShowAll}
				>
					{label}
				</Text>
			)
		);
	}

	get LeftScrollButton() {
		const iconClass = "dashboard__classSlider_nav_icon dashboard__classSlider_nav_icon_left";

		return (
			this.state.sliderLeftVisible && (
				<Button className={iconClass} type="default" shape="circle" onClick={() => this.scroll(-1)}>
					<Icon type="ri-arrow-left-s-line" />
				</Button>
			)
		);
	}

	get RightScrollButton() {
		const iconClass = "dashboard__classSlider_nav_icon dashboard__classSlider_nav_icon_right";

		return (
			this.state.sliderRightVisible && (
				<Button className={iconClass} type="default" shape="circle" onClick={() => this.scroll(+1)}>
					<Icon type="ri-arrow-right-s-line" />
				</Button>
			)
		);
	}

	getPhoto(item: ClassItem) {
		return (
			<Image className="dashboard__classSlider_item_card_image" src={item.filePath ?? DefaultClassroomCover} />
		);
	}

	getItem(item: ClassItem): ReactNode {
		return (
			<a href={item.pathToClassroom} className="classroom__details">
				<Row>{this.getPhoto(item)}</Row>
				{item.organizationName && item.organizationName !== "" ? (
					<Row className="pt-16">
						<Col span={24}>
							<Text maxLength={25} fontSize="12" lineHeight="20" className="organization__name">
								{item.organizationName}
							</Text>
						</Col>
					</Row>
				) : null}
				<Row className="pt-8">
					<Col span={24}>
						<Text maxLength={25} fontSize="16" lineHeight="24" className="color-gray-9">
							{item.name}
						</Text>
					</Col>
				</Row>
				{!item.isAsync && !this.props.hideTeacherName ? (
					<Row>
						<Col span={24}>
							<Text
								maxLength={item.teacherName && item.teacherName.length > 15 ? 15 : undefined}
								fontSize="12"
								lineHeight="20"
								className="color-gray-7"
							>
								<Icon type="ri-user-2-line" className="color-gray-7" /> {item.teacherName}
							</Text>
						</Col>
					</Row>
				) : null}
			</a>
		);
	}

	get Items(): ReactNode {
		return (
			this.state.currentItems &&
			this.state.currentItems.length > 0 &&
			this.state.currentItems.map((item: ClassItem) => {
				return (
					<Col key={item.id} xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
						{this.getItem(item)}
					</Col>
				);
			})
		);
	}

	get EmptyState(): ReactNode {
		let description = i18n.t(`${TRANSLATION_BASE_PATH}._STUDENT_NO_DATA_DESCRIPTION`);
		if (OAuth2Service.isTeacher) {
			description = i18n.t(`${TRANSLATION_BASE_PATH}._TEACHER_NO_DATA_DESCRIPTION`);
		}
		return <Empty className="pb-16" description={description} />;
	}

	render() {
		const rowClass = classnames({
			row__show_all: this.state.showAll,
			row__show_some: !this.state.showAll
		});
		const Data = this.state.currentItems && this.state.currentItems.length > 0;
		const justifyItems = !Data ? "center" : "start";
		return (
			<Card className="dashboard__classSlider__card" title={this.Title} extra={Data && this.ShowAllLink}>
				<Row justify={justifyItems} align="top" gutter={[24, 12]} className={rowClass}>
					{this.LeftScrollButton}
					{this.Items}
					{this.RightScrollButton}
					{!Data && this.EmptyState}
				</Row>
			</Card>
		);
	}
}

export default ClassSlider;
