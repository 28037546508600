import React from "react";
import { useTranslation } from "react-i18next";

type IStatusType = "EVALUATED" | "NOT_EVALUATED" | "EVALUATING";

interface AssignmentEvaluationStatusProps {
	status: IStatusType;
}

const TRANSLATION_BASE_PATH = "_COMPONENTS._ASSIGNMENT_EVALUATION_STATUS";

const mapStatusWithBgColor = new Map<IStatusType, string>([["EVALUATED", "background-color-green-1"],
	["NOT_EVALUATED", "background-color-blue-1"],
	["EVALUATING", "background-color-orange-1"]]);

const mapStatusWithColor = new Map<IStatusType, string>([["EVALUATED", "color-green-6"],
	["NOT_EVALUATED", "color-blue-6"],
	["EVALUATING", "color-orange-6"]]);

export const AssignmentEvaluationStatus = (props: AssignmentEvaluationStatusProps) => {
	const { t: translate } = useTranslation();
	return (
		<span
			className={`${mapStatusWithBgColor.get(props.status)} ${mapStatusWithColor.get(props.status)} subject__assignment_evaluation_status`}
		>
			{translate(`${TRANSLATION_BASE_PATH}._${props.status}`)}
		</span>
	);
};