import React, { Component, ReactNode } from "react";
import moment from "moment";
import { Calendar as AntCalendar, Card, Col, Popover, Row, Select, Space } from "antd";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import "moment/locale/sq";

import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import LessonService from "services/administration/lesson.service";
import ClassroomService from "services/administration/classroom.service";
import ClassroomDto from "dtos/administration/classroom.dto";
import LessonDto from "dtos/administration/lesson.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import EnrollmentService from "services/administration/enrollment.service";
import { mapTypeWithColor, TypeIcon } from "common/components/subject/subjectTypeIcon/index";
import { LessonType } from "services/domain/administration/LessonType";
import { sqLocale } from "../../components/calendar/sqLocale";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SIGN_IN_PATH } from "../../../login/scenes/signIn";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { ItemType } from "services/domain/administration/ItemType";
import { CalendarWithLessons } from "../dashboard/components/calendarWithLessons";
import { boolVal, getComponentProps } from "../../../../common/utils/Design";
import { MainLayout } from "scenes/main/components/layout/Layout";

export const CALENDAR_PATH = "calendar";
const TRANSLATION_BASE_PATH = "_MAIN._CALENDAR";

class CalendarComponent extends Component<any, any> {
	inputElement: any;
	state = {
		enrollments: [],
		classrooms: [],
		lessons: [],
		selectedValue: moment(),
		selectedClassId: "ALL",
		loading: false,
		xsScreen: false,
		smScreen: false,
		isTeacher: false
	};

	componentDidMount() {
		const { history } = this.props;
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
		moment.locale("sq");
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		const isTeacher = OAuth2Service.isTeacher;
		this.setState({ loading: true, isTeacher: isTeacher });

		const initialLoad = isTeacher ? this.loadForTeacher() : this.loadForStudent();

		return initialLoad
			.then((result: any) => {
				this.setState({ classrooms: result });
				return this.loadMonthlyData();
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	async loadForTeacher() {
		return new ClassroomService().findAllByUserIdWithoutInclude().then((data: any) => {
			return data.filter((x: any) => {
				return !x.isAsync;
			});
		});
	}

	async loadForStudent() {
		return new EnrollmentService().getAllEnrollmentsForCurrentUser().then((result: any) => {
			return result
				.filter((x: any) => {
					return x.status !== "BLOCKED";
				})
				.map((item: any) => {
					return item.Classroom;
				});
		});
	}

	loadMonthlyData = async () => {
		const startOfMonth = this.state.selectedValue.startOf("month").format("YYYY-MM-DD hh:mm");
		const endOfMonth = this.state.selectedValue.endOf("month").format("YYYY-MM-DD hh:mm");
		const classroomId = this.state.selectedClassId;
		if (this.state.classrooms && this.state.classrooms.length > 0) {
			return new LessonService()
				.getCalendarLessonsByDateSegmentAndClassroomId(startOfMonth, endOfMonth, classroomId)
				.then((result: LessonDto[]) => {
					return this.setState({
						lessons: result,
						loading: false
					});
				});
		}
		this.setState({
			lessons: [],
			loading: false
		});
	};

	resize() {
		this.setState({
			xsScreen: window.innerWidth <= 320,
			smScreen: window.innerWidth <= 576
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	onPanelChange = (value: any) => {
		this.setState(
			{
				selectedValue: value,
				loading: true
			},
			() => {
				return this.loadMonthlyData();
			}
		);
	};

	get classOptions(): ReactNode {
		const { t: translate } = this.props;
		const allClassesLabel = translate(`${TRANSLATION_BASE_PATH}._ALL_CLASSES`);
		return (
			<>
				<Select.Option key={0} value={"ALL"}>
					{allClassesLabel}
				</Select.Option>

				{this.state.classrooms.map((item: ClassroomDto) => (
					<Select.Option key={item.id} value={item.id}>
						{item.name}
					</Select.Option>
				))}
			</>
		);
	}

	get monthOptions(): ReactNode {
		const current = moment();
		const localeData = moment.localeData();
		const monthOptions = [];
		const months = [];
		for (let i = 0; i < 12; i++) {
			current.month(i);
			months.push(localeData.months(current));
		}

		for (let index = 0; index < 12; index++) {
			monthOptions.push(
				<Select.Option key={`${index}`} value={`${index}`}>
					{months[index]}
				</Select.Option>
			);
		}
		return monthOptions;
	}

	get yearOptions(): ReactNode {
		const year = moment().year();
		const yearOptions = [];
		for (let i = year - 5; i < year + 5; i += 1) {
			yearOptions.push(
				<Select.Option key={i} value={i}>
					{i}
				</Select.Option>
			);
		}
		return yearOptions;
	}

	headerRender(value: any, type: any, onChange: any) {
		return (
			<Row gutter={[8, 24]}>
				<Col xs={24} sm={5} md={5} lg={4} xl={3} xxl={3}>
					<Select
						dropdownMatchSelectWidth={false}
						onChange={selectedMonth => {
							const newValue = value.month(parseInt(selectedMonth, 10));

							this.setState({ selectedValue: newValue }, () => {
								this.loadMonthlyData();
							});
							onChange(newValue);
						}}
						value={String(value.month())}
						listHeight={1000}
					>
						{this.monthOptions}
					</Select>
				</Col>
				<Col xs={24} sm={5} md={5} lg={4} xl={3} xxl={3}>
					<Select
						dropdownMatchSelectWidth={false}
						onChange={selectedMonth => {
							const newValue = value.year(selectedMonth);

							this.setState({ selectedValue: newValue }, () => {
								this.loadMonthlyData();
							});
							onChange(newValue);
						}}
						value={String(value.year())}
						listHeight={1000}
					>
						{this.yearOptions}
					</Select>
				</Col>
				<Col xs={24} sm={14} md={14} lg={16} xl={18} xxl={18}>
					<Row justify="end">
						<Col xs={24} sm={14} md={12} lg={8} xl={6} xxl={6}>
							<Select
								dropdownMatchSelectWidth={false}
								onChange={(newClass: any) => {
									this.setState(
										{
											selectedClassId: newClass,
											selectedValue: value
										},
										() => {
											this.loadMonthlyData();
										}
									);
								}}
								value={this.state.selectedClassId}
								listHeight={1000}
							>
								{this.classOptions}
							</Select>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}

	getDailyItems(value: any) {
		return this.state.lessons
			.filter((item: any) => {
				return value.format("MM/DD/YYYY") === moment(item.endDate).format("MM/DD/YYYY");
			})
			.map((item: any) => {
				return {
					id: item.id,
					name: item.name,
					description: item.description,
					endDate: moment(item.endDate).format("DD.MM.YYYY HH:mm"),
					type: item.lessonType,
					color: mapTypeWithColor.get(item.lessonType as LessonType),
					className:
						item.SubjectPlanTrees &&
						item.SubjectPlanTrees.length > 0 &&
						item.SubjectPlanTrees[0]?.SubjectPlan?.Classroom?.name,
					subjectPlanId:
						item.SubjectPlanTrees &&
						item.SubjectPlanTrees.length > 0 &&
						item.SubjectPlanTrees[0]?.SubjectPlan?.id,
					classroomId:
						item.SubjectPlanTrees &&
						item.SubjectPlanTrees.length > 0 &&
						item.SubjectPlanTrees[0]?.SubjectPlan?.ClassroomId,
					completed:
						!this.state.isTeacher &&
						item.ProgressLessonEnrollments &&
						item.ProgressLessonEnrollments.length > 0,
					url: this.state.isTeacher
						? `/course-builder/materials/${item.lessonType === "VIDEO" ? "lesson" : item.lessonType}/${
								item.id
						  }?subjectPlanId=
						${item.SubjectPlanTrees[0]?.SubjectPlan?.id}&classroomId=${item.SubjectPlanTrees[0]?.SubjectPlan?.ClassroomId}`
						: `/subjects/materials/video/${item.id}?virtualClass=true`
				};
			});
	}

	getPopupContent(item: any): ReactNode {
		return (
			<div style={{ maxWidth: 400 }} className="pr-16">
				<Row className="pb-24 pt-16">
					<Space>
						<Text fontSize="16" lineHeight="24">
							<TypeIcon type={item.type as ItemType} />
						</Text>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="pl-16 color-gray-9">
							{item.name}
						</Text>
					</Space>
				</Row>
				<Row className="pb-24 pl-8 color-gray-8">
					<Space>
						<Text fontSize="16" lineHeight="24" className="pr-24">
							<Icon type={"ri-time-line"} />
						</Text>
						<Text fontSize="14" lineHeight="22">
							{item.endDate}
						</Text>
					</Space>
				</Row>
				<Row className="pb-24 pl-8 pr-8 color-gray-8">
					<Space>
						<Text fontSize="16" lineHeight="24" className="pr-24">
							<Icon type={"ri-align-left"} />
						</Text>
						<Text fontSize="14" lineHeight="22">
							{item.description &&
								ReactHtmlParser(item.description.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, ""))}
						</Text>
					</Space>
				</Row>
				<Row className="pb-24 pl-8 pr-8 color-gray-8">
					<Space>
						<Text fontSize="16" lineHeight="24" className="pr-24">
							<Icon type={"ri-calendar-2-line"} />
						</Text>
						<Text fontSize="14" lineHeight="22">
							{item.className}
						</Text>
					</Space>
				</Row>
				<Row justify="end">
					<Text fontSize="14" lineHeight="22" className="color-blue-6">
						<a href={item.url}>Shiko detajet</a>
					</Text>
				</Row>
			</div>
		);
	}

	dateCellRender(value: any) {
		const result = this.getDailyItems(value);

		return result.map((item: any) => {
			return (
				<Row key={item.id} className="calendar-item">
					<span className="ant-badge ant-badge-status ant-badge-not-a-wrapper">
						<span className={`background-${item.color} ant-badge-status-dot`}></span>
						<Popover key={item.id} content={this.getPopupContent(item)} title={null} trigger={["click"]}>
							<Text
								fontSize="12"
								lineHeight="20"
								className={`color-gray-8 ant-badge-status-text ${item.completed && "linethrough"}`}
							>
								{item.name}
							</Text>
						</Popover>
					</span>
				</Row>
			);
		});
	}

	render() {
		const { t: translate } = this.props;
		const subtitle = translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`);
		const sideLayoutProps = getComponentProps(this.props.pageComponents, "sideLayout");

		return (
			<MainLayout
				showMarketPlace={boolVal(sideLayoutProps, "showExploreCourses", true)}
				showAddCourse={boolVal(sideLayoutProps, "joinLiveCourse", true)}
			>
				<SectionTitle subtitle={subtitle} />
				{this.state.xsScreen || this.state.smScreen ? (
					<Row className="p-16">
						<Col span={24}>
							<CalendarWithLessons />
						</Col>
					</Row>
				) : (
					<Card className="m-24">
						<Row className="calendar__container">
							<Col span={24}>
								<AntCalendar
									headerRender={({ value, type, onChange }) =>
										this.headerRender(value, type, onChange)
									}
									dateCellRender={value => this.dateCellRender(value)}
									onPanelChange={this.onPanelChange}
									locale={sqLocale(translate)}
								/>
							</Col>
						</Row>
					</Card>
				)}
			</MainLayout>
		);
	}
}

const Calendar = withTranslation()(CalendarComponent);
export default Calendar;
