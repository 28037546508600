import ApiServiceData from "../api.service";
import { OrganizationPageComponent } from "../../dtos/administration/customize/OrganizationPageComponent.dto";
import {
	PAGE_STATUS_PUBLISHED,
	PAGE_STATUS_UNPUBLISHED
} from "../../scenes/admin/scenes/organizations/scenes/customize/utils/constants";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { OrganizationPage } from "../../dtos/administration/customize/OrganizationPage.dto";

const relativeUrl = "/v1/api/organization-page-components";

export default class OrganizationPageComponentService extends ApiServiceData {

	/**
	 * Save organization page form data
	 * @returns
	 * @param data
	 */
	async create(data: OrganizationPageComponent) {
		return this.post(relativeUrl, data).then((res) => {
			return res.data as OrganizationPageComponent;
		}).catch(error => {
			throw error.response;
		});
	}

	/**
	 * Update organization page form data
	 * @returns
	 * @param data
	 */
	async updateData(data: Partial<OrganizationPageComponent>) {
		return this.update(relativeUrl, data).then((res) => {
			return res.data as OrganizationPageComponent;
		}).catch(error => {
			throw error.response;
		});
	}

	/**
	 * 
	 * @param organizationPageId 
	 * 
	 * @param pageComponentId 
	 * @returns 
	 */
	async findBy(organizationPageId: string | null, pageComponentId: string): Promise<OrganizationPageComponent> {
		return this.get(`${relativeUrl}/${organizationPageId || -1}/${pageComponentId}`)
			.then(res => {
				return res.data as OrganizationPageComponent;
			})
			.catch(error => {
				throw error.response;
			});
	}

	async updateOrCreate(data: Partial<OrganizationPageComponent>) {
		let promise: Promise<OrganizationPageComponent>;

		if (data.id) {
			promise =  this.updateData(data);
		} else {
			data.id = uuidv4();
			data.specs = data.specs || {};
			promise = this.create(data as OrganizationPageComponent);
		}

		return promise;
	}

	async reset(organizationPageId: string, pageComponentId: string) {
		return this.get(`${relativeUrl}/reset/${organizationPageId}/${pageComponentId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

}
