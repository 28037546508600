import React, { ReactNode } from "react";

interface LabelProps {
	htmlFor?: string | undefined;
	children: ReactNode;
	className?: string;
}

const Label: React.FC<LabelProps> = props => {
	return (
		<label {...props} className={`base__label_styles ${props.className}`}>
			{props.children}
		</label>
	);
};

export default Label;
