import { Card, Col, Row, Skeleton } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import OAuth2Service from "services/authentication/oauth2.service";
import { SectionHeader } from "../../components/sectionHeader/sectionHeader";
import { isOver13 } from "common/utils/isOver13";
import { SectionAsyncClasses } from "./sectionAsynchClasses/sectionAsynchClasses";
import { SectionVirtualClasses } from "./sectionVirtualClasses/sectionVirtualClasses";
import { SectionTitle } from "./sectionTitle/SectionTitle";

export const PARENT_CHILD_STATISTICS_PATH = "child-statistics";

class ParentChildStatisticsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			child: null
		};
	}

	componentDidMount() {
		this.loadSettings(this.props.match.params.id);
	}

	loadSettings = (childId: string) => {
		const parentEmail = OAuth2Service.CurrentUser.User.email;

		this.setState({
			loading: true
		});

		return new OAuth2Service()
			.getChildren(parentEmail)
			.then((result: any) => {
				const child = result.children
					.filter((x: any) => {
						return x.id === childId;
					})
					.map((item: any) => {
						return {
							...item,
							over13: isOver13(item.dateOfBirth),
							parentEmail: parentEmail
						};
					});
				return this.setState({
					child: child[0]
				});
			})
			.finally(() => this.setState({ loading: false }));
	};

	childFullName = (child: any) => {
		return child.firstName + " " + child.lastName;
	};

	render() {
		return (
			<Skeleton loading={this.state.loading}>
				{this.state.child && (
					<>
						<Row justify="space-around">
							<Col span="22">
								<Row className="pt-24 pb-24">
									<Col span="24">
										<Card title={null}>
											<Row>
												<SectionHeader child={this.state.child} showConfigBtn />
											</Row>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="space-around">
							<Col span="22">
								<Row>
									<Col span="24">
										<SectionTitle child={this.state.child} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="space-around">
							<Col span="22">
								<Row>
									<Col span="24">
										<SectionAsyncClasses child={this.state.child} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="space-around" className="mb-24">
							<Col span="22">
								<Row>
									<Col span="24">
										<SectionVirtualClasses child={this.state.child} />
									</Col>
								</Row>
							</Col>
						</Row>
					</>
				)}
			</Skeleton>
		);
	}
}

const ParentChildStatistics = withTranslation()(ParentChildStatisticsComponent);
export default ParentChildStatistics;
