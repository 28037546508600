import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Space, Row, Col, Dropdown, Menu, Modal, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

import { ListCard } from "common/components/listCard/ListCard";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import EnrollmentService from "services/administration/enrollment.service";
import { InviteStudentsDrawer } from "../../components/students/InviteStudentsDrawer";
import { createEnrollmentChangeNotification } from "common/components/_notifications/util/createNotifications";
import { CreateEnrollmentChangeNotificationDto } from "common/components/_notifications/dtos/createEnrollmentChangeNotificationDto";
import { EnrollmentStatus } from "services/domain/administration/EnrollmentStatus";
import { OrganizationSectorType } from "services/domain/administration/Organization";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._STUDENT_LIST";

class StudentListComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			teacher: [],
			students: [],
			pendingStudents: [],
			blockedStudents: [],
			classroom: null,
			enrolledStudents: null,
			refreshToggle: null,
			updating: false
		};
		this.openConfirmationModal = this.openConfirmationModal.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
	}

	componentDidMount() {
		this.loadStudentList();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.refreshToggle !== this.state.refreshToggle)
			this.setState({ teacher: [], students: [] }, () => {
				this.loadStudentList();
			});
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if (nextProps.refreshToggle !== prevState.refreshToggle) return { refreshToggle: nextProps.refreshToggle };
		return null;
	}

	loadStudentList = () => {
		const { firstName, lastName } = (this.props.classroom && this.props.classroom.UserCreatedBy) || {
			firstName: null,
			lastName: null
		};
		const teacher = this.state.teacher;
		const students: any[] = [];
		const pendingStudents: any[] = [];
		const blockedStudents: any[] = [];
		teacher.push({ firstName, lastName, userId: (this.props.classroom && this.props.classroom.createdBy) || null });

		this.props.enrolledStudents &&
			this.props.enrolledStudents
				.filter((x: any) => x.status === "ACTIVE")
				.map((enrolledStudent: any) => {
					const { firstName, lastName, email, parentEmail } = enrolledStudent.User;
					students.push({
						firstName,
						lastName,
						email: email || parentEmail,
						id: enrolledStudent.id,
						status: enrolledStudent.status,
						userId: enrolledStudent.UserId
					});
				});

		this.props.enrolledStudents &&
			this.props.enrolledStudents
				.filter((x: any) => x.status === "PENDING")
				.map((enrolledStudent: any) => {
					const { firstName, lastName, email, parentEmail } = enrolledStudent.User;
					pendingStudents.push({
						firstName,
						lastName,
						email: email || parentEmail,
						id: enrolledStudent.id,
						status: enrolledStudent.status,
						userId: enrolledStudent.UserId
					});
				});

		this.props.enrolledStudents &&
			this.props.enrolledStudents
				.filter((x: any) => x.status === "BLOCKED")
				.map((enrolledStudent: any) => {
					const { firstName, lastName, email, parentEmail } = enrolledStudent.User;
					blockedStudents.push({
						firstName,
						lastName,
						email: email || parentEmail,
						id: enrolledStudent.id,
						status: enrolledStudent.status,
						userId: enrolledStudent.UserId
					});
				});

		const orderStudents = _(students)
			.orderBy(x => x.firstName, "asc")
			.value();

		this.setState({
			teacher: teacher,
			students: orderStudents,
			pendingStudents: pendingStudents,
			blockedStudents: blockedStudents
		});
	};

	updateStudentsLists = (id: string, newStatus: string) => {
		const students = [...this.state.students, ...this.state.pendingStudents, ...this.state.blockedStudents];

		students.forEach((item: any) => {
			if (item.id === id) {
				item.status = newStatus;
				return;
			}
		});
		this.setState(
			{
				students: students.filter((x: any) => x.status === "ACTIVE"),
				pendingStudents: students.filter((x: any) => x.status === "PENDING"),
				blockedStudents: students.filter((x: any) => x.status === "BLOCKED")
			},
			() => {
				this.setState({ updating: false });
			}
		);
	};

	openConfirmationModal = (enrollmentId: any, actionName: string, confirm: any) => {
		const { t: translate } = this.props;
		const user = this.props.enrolledStudents.find((student: any) => student.id === enrollmentId);
		const actionVerb = translate(`${TRANSLATION_BASE_PATH}._ACTION.${actionName}`);
		return Modal.confirm({
			icon: <QuestionCircleOutlined />,
			content: translate(`${TRANSLATION_BASE_PATH}._ACTION_MESSAGE`, {
				action: actionVerb,
				student: user
					? `${user.User.firstName} ${user.User.lastName}`
					: translate(`${TRANSLATION_BASE_PATH}._ALL_STUDENTS`)
			}),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES`) + actionVerb,
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => confirm(enrollmentId)
		});
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="courseBuilder__courseDetails-studentList courseBuilder__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	addOptionContentForPending = (itemId: any) => {
		const { t: translate } = this.props;
		return (
			<Menu>
				<Menu.Item
					key="0"
					onClick={() => this.openConfirmationModal(itemId, "_BLOCK", this.block)}
					className="courseBuilder__courseDetails__studentList__menuItem__block"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._BLOCK`)}
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.openConfirmationModal(itemId, "_CANCEL", this.cancell)}>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._CANCEL`)}
				</Menu.Item>
				<Menu.Item
					key="2"
					onClick={() => this.openConfirmationModal(itemId, "_ACCEPT", this.accept)}
					className="courseBuilder__courseDetails__studentList__menuItem__accept"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._ACCEPT`)}
				</Menu.Item>
			</Menu>
		);
	};

	createOptionButtonForPending = (itemId: any): React.ReactNode => {
		return (
			<Dropdown.Button
				buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
				trigger={["click"]}
				placement="bottomRight"
				overlay={this.addOptionContentForPending(itemId)}
				icon={<Icon type="ri-more-2-line"></Icon>}
			/>
		);
	};

	addOptionContentForActive = (itemId: any) => {
		const { t: translate } = this.props;
		return (
			<Menu>
				<Menu.Item
					key="0"
					onClick={() => this.openConfirmationModal(itemId, "_BLOCK", this.block)}
					className="courseBuilder__courseDetails__studentList__menuItem__block"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._BLOCK`)}
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.openConfirmationModal(itemId, "_UNREGISTER", this.cancell)}>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._UNREGISTER`)}
				</Menu.Item>
			</Menu>
		);
	};

	createOptionButtonForActive = (itemId: any): React.ReactNode => {
		return (
			<Dropdown.Button
				buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
				trigger={["click"]}
				placement="bottomRight"
				overlay={this.addOptionContentForActive(itemId)}
				icon={<Icon type="ri-more-2-line"></Icon>}
			/>
		);
	};

	addOptionContentForBlocked = (itemId: any) => {
		const { t: translate } = this.props;
		return (
			<Menu>
				<Menu.Item key="0" onClick={() => this.openConfirmationModal(itemId, "_UNBLOCK", this.unblock)}>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._UNBLOCK`)}
				</Menu.Item>
			</Menu>
		);
	};

	createOptionButtonForBlocked = (itemId: any): React.ReactNode => {
		return (
			<Dropdown.Button
				buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
				trigger={["click"]}
				placement="bottomRight"
				overlay={this.addOptionContentForBlocked(itemId)}
				icon={<Icon type="ri-more-2-line"></Icon>}
			/>
		);
	};

	updateStatus = async (id: string, newStatus: EnrollmentStatus) => {
		this.setState({ updating: true });
		let enrollment: any = null;
		await new EnrollmentService().getEnrollmentById(id).then((result: any) => {
			enrollment = result;
			return;
		});

		if (newStatus === "CANCELLED") {
			return await new EnrollmentService().deleteEnrollment(id).then(() => {
				const notification = new CreateEnrollmentChangeNotificationDto(
					enrollment,
					this.props.classroom,
					newStatus
				);
				createEnrollmentChangeNotification(notification);
				return this.updateStudentsLists(id, newStatus);
			});
		}
		return await new EnrollmentService()
			.updateEnrollment({ id: id, status: newStatus, updatedAt: new Date() })
			.then(() => {
				const notification = new CreateEnrollmentChangeNotificationDto(
					enrollment,
					this.props.classroom,
					newStatus
				);
				createEnrollmentChangeNotification(notification);
				return this.updateStudentsLists(id, newStatus);
			});
	};

	cancell = (itemId: string) => {
		this.updateStatus(itemId, "CANCELLED");
	};

	block = (itemId: string) => {
		this.updateStatus(itemId, "BLOCKED");
	};

	unblock = (itemId: string) => {
		this.updateStatus(itemId, "ACTIVE");
	};

	accept = (itemId: string) => {
		this.updateStatus(itemId, "ACTIVE");
	};

	cancellAll = () => {
		this.state.pendingStudents.forEach((element: any) => {
			this.updateStatus(element.id, "CANCELLED");
		});
	};

	acceptAll = () => {
		this.state.pendingStudents.forEach((element: any) => {
			this.updateStatus(element.id, "ACTIVE");
		});
	};

	cancellButton = (itemId: string): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Button
				type="default"
				onClick={() => this.openConfirmationModal(itemId, "_CANCEL", this.cancell)}
				className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__cancell"
			>
				{translate(`${TRANSLATION_BASE_PATH}._BTN._CANCEL`)}
			</Button>
		);
	};

	unregisterButton = (itemId: string): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Button
				type="default"
				onClick={() => this.openConfirmationModal(itemId, "_UNREGISTER", this.cancell)}
				className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__cancell"
			>
				{translate(`${TRANSLATION_BASE_PATH}._BTN._UNREGISTER`)}
			</Button>
		);
	};

	acceptButton = (itemId: string): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Button
				type="default"
				onClick={() => this.openConfirmationModal(itemId, "_ACCEPT", this.accept)}
				className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__accept"
			>
				{translate(`${TRANSLATION_BASE_PATH}._BTN._ACCEPT`)}
			</Button>
		);
	};

	blockButton = (itemId: string): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Button
				type="default"
				onClick={() => this.openConfirmationModal(itemId, "_BLOCK", this.block)}
				className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__block"
			>
				{translate(`${TRANSLATION_BASE_PATH}._BTN._BLOCK`)}
			</Button>
		);
	};

	unblockButton = (itemId: string): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Button
				type="default"
				onClick={() => this.openConfirmationModal(itemId, "_UNBLOCK", this.unblock)}
				className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__unblock"
			>
				{translate(`${TRANSLATION_BASE_PATH}._BTN._UNBLOCK`)}
			</Button>
		);
	};

	getActionAllButtons = () => {
		const { t: translate } = this.props;
		return (
			<Row justify="end">
				<Space>
					<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._BTN._CANCEL_ALL_TOOLTIP`)}>
						<Button
							type="default"
							icon="ri-close-line"
							onClick={() => this.openConfirmationModal(null, "_CANCEL", this.cancellAll)}
							className="courseBuilder__courseDetails__studentList__btn__cancellAll"
						/>
					</Tooltip>
					<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._BTN._ACCEPT_ALL_TOOLTIP`)}>
						<Button
							type="default"
							icon="ri-check-fill"
							onClick={() => this.openConfirmationModal(null, "_ACCEPT", this.acceptAll)}
							className="courseBuilder__courseDetails__studentList__btn__acceptAll"
						/>
					</Tooltip>
				</Space>
			</Row>
		);
	};

	render() {
		const { t: translate } = this.props;

		return (
			<Row key={new Date().toString()}>
				<Col span={24}>
					<Space direction="vertical" className="full__width" size={24}>
						<Row justify="center">
							<Col xs={23} sm={22} md={20} lg={16} xl={16} xxl={16}>
								<ListCard
									listItem={this.state.teacher}
									size="small"
									cardTitle={translate(`${TRANSLATION_BASE_PATH}._TEACHER`)}
								/>
							</Col>
						</Row>
						{this.state.pendingStudents.length > 0 && !this.state.updating && (
							<Row justify="center">
								<Col xs={0} sm={0} md={20} lg={16} xl={16} xxl={16}>
									<ListCard
										extraRowElement={(itemId: string) => (
											<>
												{this.blockButton(itemId)}
												{this.cancellButton(itemId)}
												{this.acceptButton(itemId)}
											</>
										)}
										listItem={this.state.pendingStudents}
										size="small"
										withEmail
										cardTitle={
											<Row>
												<Col span="18">
													<Row justify="start">
														<Text
															fontSize="16"
															lineHeight="24"
															wheight="semibold"
															className="color-gray-9"
														>
															{translate(`${TRANSLATION_BASE_PATH}._STUDENT_PENDING`, {
																noStudent:
																	(this.state.pendingStudents &&
																		this.state.pendingStudents.length) ||
																	0
															})}
														</Text>
													</Row>
												</Col>
												<Col span="6">{this.getActionAllButtons()}</Col>
											</Row>
										}
									/>
								</Col>
								<Col xs={23} sm={22} md={0} lg={0} xl={0} xxl={0}>
									<ListCard
										extraRowElement={this.createOptionButtonForPending}
										listItem={this.state.pendingStudents}
										size="small"
										withEmail
										cardTitle={
											<Row>
												<Col span="18">
													<Row justify="start">
														<Text
															fontSize="16"
															lineHeight="24"
															wheight="semibold"
															className="color-gray-9"
														>
															{translate(`${TRANSLATION_BASE_PATH}._STUDENT_PENDING`, {
																noStudent:
																	(this.state.pendingStudents &&
																		this.state.pendingStudents.length) ||
																	0
															})}
														</Text>
													</Row>
												</Col>
												<Col span="6">{this.getActionAllButtons()}</Col>
											</Row>
										}
									/>
								</Col>
							</Row>
						)}
						{!this.state.updating && (
							<Row justify="center">
								<Col xs={0} sm={0} md={20} lg={16} xl={16} xxl={16}>
									<ListCard
										extraRowElement={(itemId: string) => (
											<>
												{this.unregisterButton(itemId)}
												{this.blockButton(itemId)}
											</>
										)}
										listItem={this.state.students}
										withEmail
										size="small"
										cardTitle={
											<div className="flex__center_space_between">
												<Text
													fontSize="16"
													lineHeight="24"
													wheight="semibold"
													className="color-gray-9"
												>
													{translate(`${TRANSLATION_BASE_PATH}._STUDENT`, {
														noStudent:
															(this.state.students && this.state.students.length) || 0
													})}
												</Text>
												{this.props.classroom?.Organization?.sector ===
													OrganizationSectorType.Private && (
														<Button
															type="default"
															icon="ri-add-line"
															onClick={() => {
																this.setState({
																	inviteStudentsDrawerVisible: true
																});
															}}
															disabled={this.props.classroom.status === "ARCHIVED"}
														>
														Ftoni nxënës
														</Button>
													)}
											</div>
										}
									/>
								</Col>
								<Col xs={23} sm={22} md={0} lg={0} xl={0} xxl={0}>
									<ListCard
										extraRowElement={this.createOptionButtonForActive}
										listItem={this.state.students}
										size="small"
										withEmail
										cardTitle={
											<div className="flex__center_space_between">
												<Text
													fontSize="16"
													lineHeight="24"
													wheight="semibold"
													className="color-gray-9"
												>
													{translate(`${TRANSLATION_BASE_PATH}._STUDENT`, {
														noStudent:
															(this.state.students && this.state.students.length) || 0
													})}
												</Text>
												<Button
													type="default"
													icon="ri-add-line"
													onClick={() => {
														this.setState({
															inviteStudentsDrawerVisible: true
														});
													}}
													disabled={this.props.classroom.status === "ARCHIVED"}
												></Button>
											</div>
										}
									/>
								</Col>
							</Row>
						)}
						{this.state.blockedStudents.length > 0 && !this.state.updating && (
							<Row justify="center" className="pb-24">
								<Col xs={0} sm={0} md={20} lg={16} xl={16} xxl={16}>
									<ListCard
										extraRowElement={(itemId: string) => <>{this.unblockButton(itemId)}</>}
										listItem={this.state.blockedStudents}
										size="small"
										withEmail
										cardTitle={
											<div className="flex__center_space_between">
												<Text
													fontSize="16"
													lineHeight="24"
													wheight="semibold"
													className="color-gray-9"
												>
													{translate(`${TRANSLATION_BASE_PATH}._STUDENT_BLOCKED`, {
														noStudent:
															(this.state.blockedStudents &&
																this.state.blockedStudents.length) ||
															0
													})}
												</Text>
											</div>
										}
									/>
								</Col>
								<Col xs={23} sm={23} md={0} lg={0} xl={0} xxl={0}>
									<ListCard
										extraRowElement={this.createOptionButtonForBlocked}
										listItem={this.state.blockedStudents}
										size="small"
										withEmail
										cardTitle={
											<div className="flex__center_space_between">
												<Text
													fontSize="16"
													lineHeight="24"
													wheight="semibold"
													className="color-gray-9"
												>
													{translate(`${TRANSLATION_BASE_PATH}._STUDENT_BLOCKED`, {
														noStudent:
															(this.state.blockedStudents &&
																this.state.blockedStudents.length) ||
															0
													})}
												</Text>
											</div>
										}
									/>
								</Col>
							</Row>
						)}
					</Space>
					<InviteStudentsDrawer
						classroomId={this.props.classroom.id}
						visible={this.state.inviteStudentsDrawerVisible}
						onCancel={() => {
							this.setState({
								inviteStudentsDrawerVisible: false
							});
						}}
						onSuccess={() => {
							this.setState({
								inviteStudentsDrawerVisible: false
							});
						}}
					/>
				</Col>
			</Row>
		);
	}
}

const StudentList = withTranslation()(StudentListComponent);
export default StudentList;
