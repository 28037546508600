import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "./components/layout/Layout";
import {
	MainMaterialInfoLayout,
	MATERIAL_INFO_FULL_PATH
} from "./scenes/materialInfo/components/layout/MainMaterialInfoLayout";

export const MATERIAL_INFO_PATH = "material-info";

const Main = () => {
	return (
		<MainLayout>
			<Switch>
				<Route path={`/${MATERIAL_INFO_PATH}/_teacher/${MATERIAL_INFO_FULL_PATH}`} exact component={MainMaterialInfoLayout} />
			</Switch>
		</MainLayout>
	);
};

export default Main;
