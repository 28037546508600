import React, { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Collapse, Modal, Space, Empty, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { CaretRightOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import { MaterialItem } from "scenes/courseBuilder/scenes/buildMaterials/components/materialItem/MaterialItem";

const TRANSLATION_BASE_PATH = "_COMPONENTS._LESSON_LIST";

const { Panel } = Collapse;

interface TeacherLessonListProps {
	lessonGroups: any[];
	lessons: any[];
	classroomId: string;
	enrolledStudents: number;
	canBeModified: boolean;
	onDeleteGroup?: (item: any) => void;
	onDeleteItem?: (item: any) => void;
	onClickItem?: (item: any) => void;
	onUngroupItem?: (item: any) => void;
	onGroupReorder?: (groups: any[]) => void;
	onGroupLessonsReorder?: (groupId: string, groupLessonsTags: string[]) => void;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export const TeacherLessonList = (props: TeacherLessonListProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const [activeKey, setActiveKey] = useState([1]);
	const [ungroupedLessons, setUngroupedLessons] = useState(Array);

	const changeActiveKey = (e: any) => {
		setActiveKey(e);
	};

	const openConfirmationModal = (classroomTagId: string, translate: (key: string) => string) => {
		// e.stopPropagation();
		return Modal.confirm({
			title: translate(`${TRANSLATION_BASE_PATH}._DELETE_TITLE`),
			content: translate(`${TRANSLATION_BASE_PATH}._DELETE_MESSAGE`),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => props.onDeleteGroup && props.onDeleteGroup(classroomTagId)
		});
	};

	const menu = (classroomTagId: string, translate: (key: string) => string) => (
		<Menu>
			<Menu.Item
				key="0"
				onClick={async e => {
					e.domEvent.stopPropagation();
					openConfirmationModal(classroomTagId, translate);
				}}
			>
				<Space direction="horizontal">
					<Icon type="ri-delete-bin-line" />
					{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
				</Space>
			</Menu.Item>
		</Menu>
	);

	const getNoDataComponent = (): ReactNode => {
		return (
			<Row justify="center">
				<Col span={24}>
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={props.canBeModified ? translate(`${TRANSLATION_BASE_PATH}._NO_DATA`) :
							translate(`${TRANSLATION_BASE_PATH}._NO_DATA_TO_CHECK`)}
					/>
				</Col>
			</Row>
		);
	};

	
	const getListStyle = (isDraggingOver: any) => ({
		background: isDraggingOver ? "#F0F8FF" : "none"
	});
	
	const getLessonItems = (lessonItems: any[], groupId: string, allowDragAndDrop?: boolean): ReactNode => {
		if (!allowDragAndDrop) {
			return (
				<>
					{lessonItems.map((lesson: any, index: number) => {
						return (
							<Row
								key={index}
								className={
									"item-list " + (props.canBeModified ? "p-0" : "mt-8 background-color-gray-1")
								}
								align="middle"
							>
								<Col span={24}>
									<MaterialItem
										key={lesson.id}
										lesson={lesson}
										onClick={props.onClickItem}
										onDelete={props.onDeleteItem}
										onUngroup={props.onUngroupItem}
										enrolledStudents={props.enrolledStudents}
										history={history}
										classroomId={props.classroomId}
										canBeModified={props.canBeModified}
									/>
								</Col>
							</Row>
						);
					})}
				</>
			);
		}

		return (
			<Droppable droppableId={groupId} type="droppableSubItem">
				{(provided, snapshot) => (
					<div ref={provided.innerRef} className="full__width" style={getListStyle(snapshot.isDraggingOver)}>
						{_(lessonItems)
							.orderBy((x: any) => x.lessonClassroomTagPriority, "asc")
							.value()
							.map((lesson: any, index: number) => {
								return (
									<Draggable
										key={lesson.lessonClassroomTagId}
										draggableId={lesson.lessonClassroomTagId}
										index={index}
									>
										{(provided) => (
											<Row
												key={index}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												className={
													"item-list " +
													(props.canBeModified ? "p-0" : "mt-8 background-color-gray-1")
												}
												align="middle"
											>
												<Col span={24}>
													<MaterialItem
														key={lesson.id}
														lesson={lesson}
														onClick={props.onClickItem}
														onDelete={props.onDeleteItem}
														onUngroup={props.onUngroupItem}
														enrolledStudents={props.enrolledStudents}
														history={history}
														classroomId={props.classroomId}
														canBeModified={props.canBeModified}
													/>
												</Col>
											</Row>
										)}
									</Draggable>
								);
							})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		);
	};

	const getExtraMenuButton = (classroomTagId: string, translate: any): ReactNode => {
		return (
			<Dropdown.Button
				buttonsRender={() => [<></>,
					<Button key="1" type="ghost" onClick={e => e.stopPropagation()}>
						<Icon type="ri-more-2-line" />
					</Button>]}
				trigger={["click"]}
				overlay={menu(classroomTagId, translate)}
				icon={<Icon type="ri-more-2-line" />}
			/>
		);
	};

	const getGroupCard = (childTag: any, index: number, allowDragAndDrop?: boolean): ReactNode => {
		return (
			<Collapse
				bordered={false}
				className={
					props.canBeModified
						? "lessonList__course-view_teacher-panel"
						: "lessonList__course-view_student-panel"
				}
				activeKey={activeKey}
				expandIcon={({ isActive }) => (
					<CaretRightOutlined className="color-gray-8" rotate={isActive ? 90 : 0} />
				)}
				onChange={e => changeActiveKey(e)}
			>
				<Panel
					className={childTag.name}
					header={childTag.name}
					key={index + 1}
					extra={
						childTag.classroomId &&
						props.canBeModified &&
						getExtraMenuButton(childTag.classroomId, translate)
					}
				>
					{childTag.lessons?.length > 0
						? getLessonItems(childTag.lessons, childTag.id, allowDragAndDrop)
						: getNoDataComponent()}
				</Panel>
			</Collapse>
		);
	};

	const calculateUnGroupedItems = (lessonGroups: any[], lessons: any[]) => {
		const groupedLessons = lessonGroups?.flatMap((group: any) => group.ClassroomTags[0].LessonClassroomTag);
		const ungroupedLessson = lessons?.filter(
			(lesson: any) =>
				groupedLessons.findIndex((groupedLesson: any) => groupedLesson.LessonId === lesson.id) === -1
		);
		return _(ungroupedLessson)
			.orderBy((x: any) => x.name, "asc")
			.value();
	};

	useEffect(() => {
		const oldActiveKey = [...activeKey];
		setActiveKey([...oldActiveKey, 1]);
		const ungroupLessons: any[] = calculateUnGroupedItems(props.lessonGroups, props.lessons);
		setUngroupedLessons(ungroupLessons);
	}, [props.lessonGroups, props.lessons]);

	const onDragEnd = (result: any) => {
		if (!result.destination) return;

		if (result.type === "droppableItem") {
			const items = reorder(
				_(props.lessonGroups)
					?.orderBy((x: any) => x.ClassroomTags[0].priority, "asc")
					.value(),
				result.source.index,
				result.destination.index
			);
			if (props.onGroupReorder) props.onGroupReorder(items);
		} else if (result.type === "droppableSubItem") {
			if (result.source.droppableId !== result.destination.droppableId) return;
			const childTag = props.lessonGroups.find(item => item.id === result.destination.droppableId);
			const lessonsTags = childTag.ClassroomTags[0].LessonClassroomTag;
			const items = reorder(
				_(lessonsTags)
					.orderBy((x: any) => x.priority, "asc")
					.value(),
				result.source.index,
				result.destination.index
			);
			if (props.onGroupLessonsReorder) props.onGroupLessonsReorder(result.destination.droppableId, items);
		}
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="subGroupList" type="droppableItem">
				{(provided) => (
					<div ref={provided.innerRef} className="full__width">
						{ungroupedLessons?.length > 0 && (
							<Row className="mb-24">
								<Col span={24}>
									{getGroupCard(
										{
											id: Date.now(),
											name: translate(`${TRANSLATION_BASE_PATH}._UNGROUP`),
											lessons: ungroupedLessons
										},
										0
									)}
								</Col>
							</Row>
						)}
						{_(props.lessonGroups)
							?.orderBy((x: any) => x.ClassroomTags[0].priority, "asc")
							.value()
							.map((childTag: any, index) => (
								<Draggable key={childTag.id} draggableId={childTag.id} index={index}>
									{(provided) => (
										<Row
											key={index + new Date().toString()}
											justify="center"
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="mb-24"
										>
											<Col span={24}>
												{getGroupCard(
													{
														id: childTag.id,
														name: childTag.name,
														lessons: childTag.ClassroomTags[0].LessonClassroomTag.flatMap(
															(lessonTag: any) => {
																return {
																	...lessonTag.Lesson,
																	lessonClassroomTagId: lessonTag.id,
																	lessonClassroomTagPriority: lessonTag.priority
																};
															}
														),
														classroomId: childTag.ClassroomTags[0].id
													},
													ungroupedLessons?.length > 0 ? index + 1 : index,
													true
												)}
											</Col>
										</Row>
									)}
								</Draggable>
							))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

TeacherLessonList.defaultProps = {
	canBeModified: true
};
