import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Space } from "antd";
import Input from "common/components/dataEntry/components/Input";
import Text from "common/components/general/Text";
import TextArea from "common/components/dataEntry/components/TextArea";

const TRANSLATION_BASE_PATH = "_TUTORING._COLLECT_DATA._CONTACT";

type ContactType = {
	phoneNumber?: string,
	reason?: string
}

type Props = {
	updateData: (data: object) => void,
	contact: ContactType,
	setIsNextActive: (active: boolean) => void,
}

const MAX_CHAR_LENGTH = 150;


export const Contact = (props: Props) => {
	const { t: translate } = useTranslation();
	const { updateData, contact, setIsNextActive } = props;
	const [phoneNumber, setPhoneNumber] = useState("");
	const [reason, setReason] = useState("");
	useEffect(() => {
		setPhoneNumber(contact.phoneNumber || "");
		setReason(contact.reason || "");
		setIsNextActive(true);
	}, [contact.phoneNumber, contact.reason, setIsNextActive]);
	const updateTime = (updated: ContactType ) => {
		const data = updated.phoneNumber ? {...updated, reason } : { phoneNumber, ...updated };
		updateData({ contact: data });
	};
	const onPhoneNrChange = (number: string) => {
		setPhoneNumber(number);
		updateTime({ phoneNumber: number });
	};
	const onReasonChange = (reason: string) => {
		setReason(reason);
		updateTime({ reason });
	};
	return (
		<Row>
			<Row gutter={[0, 12]}>
				<Col>{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</Col>
			</Row>
			<Col span={24} className="mb-16">
				<Space>
					<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._PHONE_NR`)}</Text>
					<Text fontSize="14" className="color-gray-7">{translate(`${TRANSLATION_BASE_PATH}._OPTIONAL`)}</Text>
				</Space>
				<Col span={24}>
					<Input
						maxLength={10}
						addonBefore="+355"
						placeholder="123 456 78..."
						value={phoneNumber}
						onChange={e => {
							const { value } = e.target;
							const reg = /^\d*$/;
							if ( reg.test(value) || value === "") {
								onPhoneNrChange(value);
							}
						}}
					/>
				</Col>
			</Col>
			<Col span={24} className="mb-24">
				<Space>
					<Text fontSize="14">{translate(`${TRANSLATION_BASE_PATH}._REASON`)}</Text>
					<Text fontSize="14" className="color-gray-7">{translate(`${TRANSLATION_BASE_PATH}._OPTIONAL`)}</Text>
				</Space>
				<Row>
					<TextArea
						maxLength={MAX_CHAR_LENGTH}
						autoSize={{ minRows: 3, maxRows: 6 }}
						placeholder={translate(`${TRANSLATION_BASE_PATH}._REASON_PLACEHOLDER`)}
						value={reason}
						onChange={e => onReasonChange(e.target.value)}
					/>
				</Row>
			</Col>
		</Row>
	);
};