import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Checkbox, Col, Collapse, Divider, Drawer, Radio, Row, Space } from "antd";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Formik, FormikProps } from "formik";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { Uploader, UploadImageType } from "common/components/uploader/Uploader";
import Question from "dtos/course-builder/question.dto";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import Option from "common/components/dataEntry/components/Option";
import { formValidator } from "./QuestionValidationSchema";
import Answer from "dtos/course-builder/answer.dto";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import { HtmlInput as FormikHtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import { HtmlInput as FormikHtmlInputWithClear } from "common/components/dataEntry/formik/FormikHtmlInputWithClear";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";
import { ImagePreview } from "./components/imagePreviewOnCrud";
import { CoverImagePreview } from "./components/coverImagePreview";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import OAuth2Service from "services/authentication/oauth2.service";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._QUIZ";

interface QuestionProps extends WithTranslation {
	questions?: Question[];
	saveHandler: (material: Question) => void;
	deleteHandler: (material: Question) => void;
	opendDrawer: (flag: boolean) => void;
}

class QuestionsComponent extends Component<QuestionProps, any> {
	currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	constructor(props: QuestionProps) {
		super(props);
		this.state = {
			modalProps: {
				visibility: false,
				question: {
					questionType: undefined
				}
			},
			questionFileUploaded: undefined,
			optionFileUploaded: undefined,
			filesToDelete: []
		};
	}
	drawerSubmitFormHandler: any;

	addQuestion = () => {
		this.props.opendDrawer(true);

		this.setState({
			modalProps: {
				question: new Question(),
				closeModal: () => {
					this.setState({ modalProps: { visibility: false } });
					this.props.opendDrawer(false);
				},
				saveQuestion: this.saveQuestion,
				visibility: true
			}
		});
	};

	editQuestion = async (question: Question) => {
		const clonedQuestion = _.cloneDeep(question);
		clonedQuestion.fileList = [];
		if (question?.QuestionFile) {
			this.setState({
				questionFileUploaded: question?.QuestionFile
			});
		}
		this.props.opendDrawer(true);

		this.setState({
			modalProps: {
				question: clonedQuestion,
				closeModal: () => {
					this.setState({ modalProps: { visibility: false } });
					this.props.opendDrawer(false);
				},
				saveQuestion: this.saveQuestion,
				visibility: true
			}
		});
	};

	deleteQuestion = (question: Question) => {
		this.props.opendDrawer(true);
		this.props.deleteHandler(question);
	};

	deleteAnswer = (answer: Answer, formik: any) => {
		const elements = [...formik.values?.answers];
		const index = elements.findIndex((entry: any) => entry.id === answer.id);
		elements.splice(index, 1);
		formik.setFieldValue("answers", elements);
	};

	deleteAnswerImage = (answerId: string, formik: any) => {
		const elements = [...formik.values?.answers];
		const index = elements.findIndex((entry: any) => entry.id === answerId);
		elements[index] = { ...elements[index], answerFile: undefined };
		formik.setFieldValue("answers", elements);
	};

	addAnswer = (formik: FormikProps<any>) => {
		const answerTitle = formik.values.answerTitle;
		if (answerTitle){
			this.setState({error: undefined});
		}
		if (answerTitle && answerTitle.length > 0) {
			formik.values?.answers.push({
				title: answerTitle,
				id: uuidv4(),
				correct: false,
				answerFile: this.state.optionFileUploaded
			});
			formik.setFieldValue("answerTitle", "");
			this.setState({
				optionFileUploaded: undefined,
				clearAnswer: true
			}, () => this.setState({clearAnswer: false}));
		}
	};

	saveQuestion = (question: Question) => {
		question.QuestionFile = this.state.questionFileUploaded;
		this.props.saveHandler(question);
		this.props.opendDrawer(false);
		this.setState({
			modalProps: { visibility: false },
			questionFileUploaded: undefined,
			questionImagePhoto: undefined,
			optionFileUploaded: undefined,
			optionImagePhoto: undefined
		});
	};

	toggleDrawer = () => {
		this.props.opendDrawer(!this.state.modalProps.visibility);
		this.setState({
			modalProps: !this.state.modalProps.visibility
		});
	};

	getCollapseHeader = (index: number) => {
		const { t: translate } = this.props;
		return (
			<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
				{translate(`${TRANSLATION_BASE_PATH}._QUESTION`) + " " + index}
			</Text>
		);
	};

	getDrawerFooter = () => {
		const { t: translate } = this.props;
		return (
			<Space size={8} className="full__width justify__flex_end">
				<Button onClick={this.toggleDrawer}>{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}</Button>
				<Button
					htmlType="button"
					type="primary"
					onClick={() => {
						this.drawerSubmitFormHandler();
					}}
				>
					{translate(`${TRANSLATION_BASE_PATH}._SAVE`)}
				</Button>
			</Space>
		);
	};

	onQuestionPhotoUploadingDone = async (fileUploaded: any) => {
		this.setState({ questionFileUploaded: fileUploaded });
	};

	onOptionPhotoUploadingDone = async (fileUploaded: any, formik: FormikProps<any>) => {
		const {values} = formik;
		const { t: translate} = this.props;
		if (values?.answers?.length === 0){
			this.setState({error: translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)});
		} else {
			const lastAnswer = values.answers.find((item:any) => item.answerFile === fileUploaded);
			if (!lastAnswer?.title){
				this.setState({error: translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)});
			}
		}
		this.setState({ optionFileUploaded: fileUploaded });
	};

	onQuestionPhotoSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			questionFileUploaded: undefined
		});
	};

	onOptionPhotoSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			optionFileUploaded: undefined
		});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<>
				<Row>
					<Col span={24}>
						<Space direction="vertical" className="full__width">
							{this.props.questions &&
								this.props.questions.map((question: Question, i: number) => (
									<Collapse key={i} defaultActiveKey={i === 0 ? "0" : "-1"}>
										<Collapse.Panel
											className="full__width"
											header={this.getCollapseHeader(i + 1)}
											extra={
												<div onClick={e => e.stopPropagation()}>
													<Space size={16}>
														<Icon
															type="ri-edit-line"
															className="color-gray-7 cursor__pointer"
															onClick={() => this.editQuestion(question)}
														/>
														<Icon
															type="ri-delete-bin-7-line"
															className="color-gray-7 cursor__pointer"
															onClick={() => this.deleteQuestion(question)}
														/>
													</Space>
												</div>
											}
											key={i.toString()}
										>
											<Row>
												<Col span={24}>
													<Space size={2} className="full__width" direction="vertical">
														<HtmlInput
															name="questionTitle"
															readOnly
															value={question.questionTitle}
														/>
														{question.QuestionFile ? (
															<CoverImagePreview file={question.QuestionFile} />
														) : null}
													</Space>
													<Divider />
												</Col>
											</Row>
											<Row gutter={[24, 24]}>
												{question.answers?.map((answer, index) =>
													question.questionType === "singleChoice" ? (
														<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} key={index}>
															<Space direction="vertical" size={2}>
																{answer.answerFile ? (
																	<CoverImagePreview file={answer.answerFile} />
																) : null}
																<Radio
																	disabled={true}
																	checked={answer.correct}
																	key={index}
																	className="flex__center"
																>
																	<HtmlInput
																		name={`answer-${index}`}
																		readOnly
																		value={answer.title}
																	/>
																</Radio>
															</Space>
														</Col>
													) : (
														<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
															<Space direction="vertical" size={2}>
																{answer.answerFile ? (
																	<CoverImagePreview file={answer.answerFile} />
																) : null}
																<Checkbox
																	disabled={true}
																	checked={answer.correct}
																	key={index}
																	className="flex__center"
																>
																	<HtmlInput
																		name={`answer-${index}`}
																		readOnly
																		value={answer.title}
																	/>
																</Checkbox>
															</Space>
														</Col>
													)
												)}
											</Row>
										</Collapse.Panel>
									</Collapse>
								))}
							<Row>
								<Col span={24}>
									<Button
										type="dashed"
										className="full__width"
										onClick={this.addQuestion}
										icon="ri-add-line"
										size="large"
									>
										{translate(`${TRANSLATION_BASE_PATH}._ADD_QUESTION`)}
									</Button>
								</Col>
							</Row>
						</Space>
					</Col>
				</Row>
				<Drawer
					title={translate(`${TRANSLATION_BASE_PATH}._ADD_QUESTION`)}
					className="quiz__add_question_drawer"
					onClose={this.toggleDrawer}
					placement="right"
					closable={true}
					visible={this.state.modalProps.visibility}
					footer={this.getDrawerFooter()}
				>
					<Formik
						enableReinitialize={true}
						initialValues={this.state.modalProps.question}
						validationSchema={formValidator(translate)}
						onSubmit={(values: any, { resetForm }) => {
							this.saveQuestion(values);
							resetForm({});
						}}
					>
						{formik => {
							const { handleSubmit, submitForm } = formik;
							this.drawerSubmitFormHandler = submitForm;
							return (
								<form onSubmit={handleSubmit} autoComplete="off">
									<Row>
										<Col span={24} className="quiz__add_question_wrapper">
											<FormikHtmlInput
												name="questionTitle"
												label={translate(`${TRANSLATION_BASE_PATH}._QUESTION_TITLE`)}
												placeholder={translate(`${TRANSLATION_BASE_PATH}._QUESTION_TITLE_TIP`)}
												className="html__with_reverse_toolbar"
											/>
											<Uploader
												fileType="IMAGE"
												uploadImageType={UploadImageType.QuizQuestion}
												filePath={`quizzes/${this.currentUserInfo?.UserId}`}
												fileWithUniqueId={true}
												onUploadingDone={this.onQuestionPhotoUploadingDone}
												showUploadList={false}
												onSoftDeleteFile={this.onQuestionPhotoSoftDeleteFile}
												className="quiz__add_question_upload_photo"
											>
												<Icon type="ri-image-fill" className="color-gray-7 cursor_pointer" />
											</Uploader>
										</Col>
									</Row>
									{this.state.questionFileUploaded ? (
										<Row className="pb-24">
											<Col span={24}>
												<ImagePreview
													file={this.state.questionFileUploaded}
													onDelete={() => {
														const oldFile = { ...this.state.questionFileUploaded };
														this.setState({
															filesToDelete: [...this.state.filesToDelete, oldFile],
															questionFileUploaded: undefined
														});
													}}
												/>
											</Col>
										</Row>
									) : null}
									<Row>
										<Col span={24}>
											<FormikSelectField
												name="questionType"
												defaultValue={formik?.initialValues?.questionType}
												value={this.state.modalProps?.question?.questionType}
												onChange={(e: any) => {
													const oldState = { ...this.state.modalProps.question };
													const newAnswers = formik.values?.answers.map((answer: any) => ({
														...answer,
														correct: false
													}));
													formik.setFieldValue("answers", newAnswers);
													formik.setFieldValue("correctAnswerId", "");

													this.setState({
														modalProps: {
															question: {
																...oldState,
																...formik.values,
																questionType: e,
																answers: newAnswers
															},
															visibility: this.state.modalProps.visibility
														}
													});
												}}
												label={translate(`${TRANSLATION_BASE_PATH}._QUESTION_TYPE`)}
												placeholder={translate(`${TRANSLATION_BASE_PATH}._QUESTION_TYPE_TIP`)}
											>
												<Option value="singleChoice" key="singleChoice">
													<Radio checked={true}>
														{translate(`${TRANSLATION_BASE_PATH}._SINGLE_CHOICE`)}
													</Radio>
												</Option>
												<Option value="multipleChoice" key="multipleChoice">
													<Checkbox checked={true}>
														{translate(`${TRANSLATION_BASE_PATH}._MULTIPLE_CHOICE`)}
													</Checkbox>
												</Option>
											</FormikSelectField>
										</Col>
									</Row>
									<Row>
										<Col span={24} className="quiz__add_option_wrapper">
											<FormikHtmlInputWithClear
												name="answerTitle"
												label={translate(`${TRANSLATION_BASE_PATH}._ADD_CHOICES`)}
												className="html__with_reverse_toolbar"
												clearAnswer={this.state.clearAnswer}
												error={this.state.error}
											/>
											<Uploader
												fileType="IMAGE"
												uploadImageType={UploadImageType.QuizQuestion}
												filePath={`quizzes/${this.currentUserInfo?.UserId}/options`}
												fileWithUniqueId={true}
												onUploadingDoneWithFormik={this.onOptionPhotoUploadingDone}
												showUploadList={false}
												onSoftDeleteFile={this.onOptionPhotoSoftDeleteFile}
												className="quiz__add_option_upload_photo"
												formik={formik}
											>
												<Icon type="ri-image-fill" className="color-gray-7 cursor_pointer" />
											</Uploader>
											<Button
												onClick={() => this.addAnswer(formik)}
												icon="ri-add-line"
												type="text"
												className="quiz__add_option_add_button"
											>
												{translate(`${TRANSLATION_BASE_PATH}._ADD`)}
											</Button>
										</Col>
									</Row>
									{this.state.error && <p style={{color: "red"}}>{this.state.error}</p>}
									{this.state.optionFileUploaded ? (
										<Row className="pb-24">
											<Col span={24}>
												<ImagePreview
													file={this.state.optionFileUploaded}
													onDelete={() => {
														const oldFile = { ...this.state.optionFileUploaded };
														this.setState({
															filesToDelete: [...this.state.filesToDelete, oldFile],
															optionFileUploaded: undefined
														});
													}}
												/>
											</Col>
										</Row>
									) : null}
									<Row>
										<Col span={24}>
											<Space direction="vertical" className="full__width" size={8}>
												{formik.values?.answers?.map((answer: Answer, i: number) => (
													<Row key={i}>
														<Col span={16}>
															{formik.values.questionType === "singleChoice" ? (
																<Radio disabled={true} className="flex__center">
																	<HtmlInput
																		name={`answer-${i}`}
																		readOnly
																		value={answer.title}
																	/>
																</Radio>
															) : (
																<Checkbox disabled={true} className="flex__center">
																	<HtmlInput
																		name={`answer-${i}`}
																		readOnly
																		value={answer.title}
																	/>
																</Checkbox>
															)}
														</Col>
														<Col span={8} className="flex__center_space_between">
															{formik.values.questionType === "singleChoice" ? (
																<Radio.Group
																	value={formik.values.correctAnswerId}
																	onChange={(e: any) => {
																		formik.values?.answers.map(
																			(element: Answer) =>
																				(element.correct = false)
																		);
																		answer.correct = e.target.checked;
																		formik.setFieldValue(
																			"correctAnswerId",
																			answer.id
																		);
																	}}
																>
																	<Radio
																		value={answer.id}
																		defaultChecked={answer.correct}
																	>
																		<Text
																			fontSize="12"
																			lineHeight="20"
																			className="color-gray-8"
																		>
																			{translate(
																				`${TRANSLATION_BASE_PATH}._CORRECT_ANSWER`
																			)}
																		</Text>
																	</Radio>
																</Radio.Group>
															) : (
																<Checkbox
																	defaultChecked={answer.correct}
																	onChange={(e: any) => {
																		answer.correct = e.target.checked;
																		setTimeout(() => {
																			formik.validateForm();
																		});
																	}}
																>
																	<Text
																		fontSize="12"
																		lineHeight="20"
																		className="color-gray-8"
																	>
																		{translate(
																			`${TRANSLATION_BASE_PATH}._CORRECT_ANSWER`
																		)}
																	</Text>
																</Checkbox>
															)}
															<Button
																shape="circle"
																onClick={() => this.deleteAnswer(answer, formik)}
															>
																<Icon type="ri-subtract-line" />
															</Button>
														</Col>
														{answer.answerFile ? (
															<Col span={24} className="pt-8">
																<ImagePreview
																	file={answer.answerFile}
																	onDelete={() => {
																		this.deleteAnswerImage(answer.id ?? "", formik);
																	}}
																/>
															</Col>
														) : null}
													</Row>
												))}
												<FormikDefaultInputField name="answers" type="hidden" />
											</Space>
										</Col>
									</Row>
								</form>
							);
						}}
					</Formik>
				</Drawer>
			</>
		);
	}
}

const AddQuestions = withTranslation()(QuestionsComponent);
export default AddQuestions;
