import React, { Component } from "react";
import { Layout, Modal } from "antd";
import { Redirect } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Skeleton from "common/components/feedback/Skeleton";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SIGN_IN_PATH } from "scenes/login/scenes/signIn";
import OrganizationService from "services/administration/organization.service";
import { MAIN_PATH } from "scenes/main";
import { Header } from "./components/header";
import { ChooseRole } from "./components/chooseRole";
import { ChooseAsStudent } from "./components/chooseAsStudent";
import { ChooseAsTeacher } from "./components/chooseAsTeacher";
import { Role } from "services/domain/login/Role";
import { REGISTER_PATH } from "../login/scenes/register";
import { withTranslation } from "react-i18next";

export const SELECT_ORGANIZATION_PATH = "select-organization";

enum SelectOrganizationPhase {
	ChooseRole = 0,
	ChooseAsStudent = 1,
	ChooseAsTeacher = 2,
	ChooseAsParent = 3
}

class SelectOrganizationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true,
			organizations: [],
			isSelectedOrganization: false,
			userId: null,
			phase: SelectOrganizationPhase.ChooseRole
		};
	}

	getPhaseByRole(role: string | undefined) {
		switch (role) {
			case Role.Student:
				return SelectOrganizationPhase.ChooseAsStudent;
			case Role.Teacher:
				return SelectOrganizationPhase.ChooseAsTeacher;
			default:
				return SelectOrganizationPhase.ChooseRole;
		}
	}

	async componentDidMount() {
		await this.renderOnMount()
	}

	renderOnMount = async () => {
		const {
			history,
			match: { params }
		} = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		const organizations = await new OrganizationService().getMyOrganizationsAsTeacherOrPareent();
		return this.setState({
			loading: false,
			organizations: organizations,
			userId: currentUserInfo.UserId,
			phase: this.getPhaseByRole(params.role)
		});
	}

	async selectOrganization(organizationId: string) {
		const decodedToken = OAuth2Service.CurrentToken;
		await OAuth2Service.refreshToken(decodedToken.refreshToken, organizationId);
		this.state.userId && localStorage.setItem(this.state.userId, organizationId);
		return this.setState({ isSelectedOrganization: !!this.state.userId });
	}

	async logoutToParentPortal () {
		return new OAuth2Service().signOut().finally(() => {
			window.location.href = `/${SIGN_IN_PATH}/${REGISTER_PATH}?parent`;
		});
	};

	openConfirmationModal() {
		const { t: translate } = this.props;
		return (
			Modal.confirm({
				icon: <QuestionCircleOutlined />,
				content: translate("_PARENT._LOGOUT._CONTENT"),
				okText: translate("_PARENT._LOGOUT._OK"),
				cancelText: translate("_PARENT._LOGOUT._CANCEL"),
				onOk: () => this.logoutToParentPortal()
			})
		);
	};


	getContentBasedOnPhase = () => {
		switch (this.state.phase) {
			case SelectOrganizationPhase.ChooseRole: {
				return (
					<ChooseRole
						onChooseAsStudent={() => {
							this.setState({
								phase: SelectOrganizationPhase.ChooseAsStudent
							});
						}}
						onChooseAsTeacher={() => {
							this.setState({
								phase: SelectOrganizationPhase.ChooseAsTeacher
							});
						}}
						onChooseAsParent={() => {
							const parentOrganization = this.state.organizations.find((item: any) =>
								item.UserRoles?.some((userRole: any) => userRole.Role.code === Role.Parent)
							);
							if (parentOrganization) {
								this.selectOrganization(parentOrganization.id);
							} else {
								this.openConfirmationModal();
							}
						}}
					/>
				);
			}
			case SelectOrganizationPhase.ChooseAsStudent:
				return (
					<ChooseAsStudent
						organizations={this.state.organizations}
						selectOrganization={(id: string) => this.selectOrganization(id)}
						renderOnMount={this.renderOnMount}
						onGoBack={() => {
							this.setState({
								phase: SelectOrganizationPhase.ChooseRole
							});
						}}
					/>
				);
			case SelectOrganizationPhase.ChooseAsTeacher:
				return (
					<ChooseAsTeacher
						organizations={this.state.organizations}
						selectOrganization={(id: string) => this.selectOrganization(id)}
						onGoBack={() => {
							this.setState({
								phase: SelectOrganizationPhase.ChooseRole
							});
						}}
					/>
				);
		}
	};

	render() {
		if (this.state.isSelectedOrganization)
			return (
				<Redirect
					to={{
						pathname: `/${MAIN_PATH}`,
						state: { from: this.props.location }
					}}
				/>
			);

		return (
			<Skeleton active loading={this.state.loading}>
				<Layout className="select__organization_layout">
					<Header />
					{!OAuth2Service.isNotAtSuperOrganization && !OAuth2Service.isOwnerOrAdminAtDefaultOrganization && (
						<Layout.Content>{this.getContentBasedOnPhase()}</Layout.Content>
					)}
				</Layout>
			</Skeleton>
		);
	}
}

const SelectOrganization = withTranslation()(SelectOrganizationComponent);
export default SelectOrganization;
