import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Empty, Modal, Col, Skeleton, Button, Space, Divider, Badge } from "antd";
import { Formik } from "formik";
import parse from "html-react-parser";
import TruncateMarkup from "react-truncate-markup";
import _ from "lodash";

import Row from "common/components/layout/Row";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import Option from "common/components/dataEntry/components/Option";
import { COURSE_BUILDER_PATH } from "scenes/courseBuilder";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import { showMessage } from "common/utils/Notification";
import ClassroomService from "services/administration/classroom.service";
import GradeService from "services/administration/grade.service";
import { GradeLevel } from "services/domain/administration/Grade";
import OAuth2Service from "services/authentication/oauth2.service";
import { ResponsiveSearchBox } from "../../components/searchBox/ResponsiveSearchBox";
import { SearchUtil } from "../../util/SearchData";
import { Uploader } from "common/components/uploader/Uploader";
import { previewFile } from "../../../courseBuilder/utils/FileUtils";
import { AdminTable } from "../../components/table/table";
import { SectionContent } from "../../components/sectionContent/SectionContent";
import { DASHBOARD_PATH } from "../dashboard";
import Icon from "common/components/general/Icon";
import FormikTextAreaField from "common/components/dataEntry/formik/FormikTextAreaField";
import CounterCard from "common/components/_counterCard/CounterCard";
import { formatTotal } from "../../util/formatTotal";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import { CircularIcon } from "common/components/general/CircularIcon";
import AnalyticsCoursesService from "services/analytics/course.service";
import { TableRowNavLink } from "../../components/tableRowNavLink/TableRowNavLink";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import TagService from "services/administration/tag.services";
import { UserAvatar } from "../../../../common/components/general/UserAvatar";
import { sumTotal } from "../../util/sumTotal";
import { TrimmedText } from "../../components/common/TrimmedText";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";
import { classroomTypePopover } from "../../../main/components/joinCreateClassModal/CreateClassroomHelpers";
import { formValidator } from "./ValidationSchema";
import { getUuidCode } from "common/utils/UuidCode";

export const LIVECLASSES_PATH = "live-classes";

const TRANSLATION_BASE_PATH = "_ADMIN._LIVE_CLASSES";
const inputWidth = { minWidth: "250px" };

class LiveClassesComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			addTeacherModalVisible: false,
			loading: true,
			loadingCoursesOfPublicOrganizations: true,
			message: "",
			classes: [],
			s3SignedUrl: "",
			fileUploaded: null,
			userRole: "",
			filesToDelete: [],
			cardTotalCourses: 0,
			cardTotalCoursesLoading: false,
			cardTotalRegistered: 0,
			cardTotalCompleted: 0,
			cardTotalVideos: 0,
			cardTotalVideosLoading: false,
			cardTotalQuiz: 0,
			cardTotalQuizLoading: false,
			cardTotalPdf: 0,
			cardTotalPdfLoading: false
		};
		this.loadItemAndShowDrawer = this.loadItemAndShowDrawer.bind(this);
		this.onUploadingDone = this.onUploadingDone.bind(this);
	}

	async componentDidMount() {
		this.loadPublicOrganizationsData()
		this.loadData();
		this.getTotalVideos();
		this.getTotalQuiz();
		this.getTotalPdf();
		this.loadTags();
	}

	loadPublicOrganizationsData = async () => {
		return new AnalyticsCoursesService()
			.getPublicOrganizationsCoursesTotalsLive()
			.then((result: any) => {
				console.log(result)
				return this.setState({
					classes: result,
					filteredData: _.cloneDeep(result)
				});
			})
			.finally(() => {
				this.setState({
					loadingCoursesOfPublicOrganizations: false
				})
			})
	};

	getTotalVideos = async () => {
		this.setState({
			cardTotalVideosLoading: true
		});
		return new AnalyticsCoursesService()
			.getTotalLiveVideos()
			.then((result: any) => {
				return this.setState({
					cardTotalVideos: result[0].total
				});
			})
			.finally(() => {
				this.setState({
					cardTotalVideosLoading: false
				});
			});
	};

	getTotalQuiz = async () => {
		this.setState({
			cardTotalQuizLoading: true
		});
		return new AnalyticsCoursesService()
			.getTotalLiveQuiz()
			.then((result: any) => {
				return this.setState({
					cardTotalQuiz: result[0].total
				});
			})
			.finally(() => {
				this.setState({
					cardTotalQuizLoading: false
				});
			});
	};

	getTotalPdf = async () => {
		this.setState({
			cardTotalPdfLoading: true
		});
		return new AnalyticsCoursesService()
			.getTotalLivePdf()
			.then((result: any) => {
				return this.setState({
					cardTotalPdf: result[0].total
				});
			})
			.finally(() => {
				this.setState({
					cardTotalPdfLoading: false
				});
			});
	};

	toggleDrawer = async (item?: any) => {
		if (item && item.File) {
			const file = await previewFile(item.File);
			this.setState({
				formInitialValues: {
					...this.state.formInitialValues,
					defaultFileList: [file]
				}
			});
		}
		this.setState({
			drawerShown: !this.state.drawerShown
		});
	};

	mapGradeSubjectDetails = (grades: any[], classrooms: any[]) => {
		classrooms.map((classroom: any) => {
			const result = grades.filter((x: any) => x.id === classroom.GradeSubjectId);
			if (result.length > 0) {
				classroom["levelName"] = result[0].Grade.level;
				classroom["gradeName"] = result[0].Grade.name;
				classroom["gradeId"] = result[0].Grade.id;
				classroom["subjectName"] = result[0].Subject.name;
				classroom["subjectId"] = result[0].Subject.id;
			}
		});
	};

	loadData = async () => {
		const requests = [new AnalyticsCoursesService().getCoursesTotalsLive(), new GradeService().getAllGrades()];

		await Promise.all(requests)
			.then((result: any) => {
				const liveClasses = result[0];
				this.mapGradeSubjectDetails(
					result[1]
						.map((x: any) => {
							return x.GradeSubjects;
						})
						.flat(),
					liveClasses
				);
				return this.setState({
					allGrades: _.groupBy(result[1], "level"),
					allLevels: Object.values(GradeLevel),
					cardTotalCourses: liveClasses.length,
					cardTotalRegistered: sumTotal(liveClasses, "countSeen"),
					cardTotalCompleted: sumTotal(liveClasses, "countCompleted")
				});
			})
			.finally(() => {
				this.setState({
					...this.state,
					loading: false
				});
			});
	};

	onUploadingDone = (fileUploaded: any) => {
		this.setState({ fileUploaded: fileUploaded });
	};

	onSubmit = (item: any) => {
		this.setState({ submitting: true });
		const tags: any = [];
		this.state.tags
			.filter((x: any) => {
				return x.ParentId;
			})
			.forEach((childTag: any) => {
				if (item[childTag.ParentId]) {
					tags.push(item[childTag.ParentId]);
				}
			});

		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const file = this.state.fileUploaded;
		if (file) {
			file.updatedBy = currentUserInfo?.UserId;
			file.createdBy = currentUserInfo?.UserId;
		}
		const obj: any = {
			id: item.id,
			name: item.name,
			description: item.description,
			startDate: new Date(),
			endDate: new Date(),
			isAsync: false,
			GradeSubject: {
				GradeId: item.gradeId,
				SubjectId: item.subjectId
			},
			updatedBy: currentUserInfo?.UserId,
			File: file,
			FileId:
				file !== null
					? file?.id
					: this.state.formInitialValues.FileId
						? this.state.formInitialValues.FileId
						: null,
			maxEnrollmentsAllowed: item.numberOfStudents ? Number(item.numberOfStudents) : null,
			virtualClassroomType: item.virtualClassroomType,
			code: getUuidCode(),
			tags: _.uniq(tags)
		};

		const { t: translate } = this.props;

		if (this.state.isCreate) {
			obj.createdBy = currentUserInfo?.UserId;
		}

		const service = this.state.isCreate
			? new ClassroomService().createClassroom(obj)
			: new ClassroomService().updateClassroom(obj.id, obj);

		return service
			.then((result: any) => {
				showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
				return this.loadData();
			})
			.finally(() => {
				this.setState({
					submitting: false
				});
				this.toggleDrawer();
			});
	};

	loadItemAndShowDrawer(item: any) {
		const isCreate = item === null;
		if (item === null) {
			item = {
				name: "",
				description: "",
				GradeSubjectId: "",
				GradeSubject: {
					Grade: {},
					Subject: {}
				},
				FileId: null
			};
		}
		this.setState(
			{
				formInitialValues: {
					id: item.id,
					name: item.name,
					description: item.description,
					level: item.levelName,
					gradeId: item.gradeId,
					gradeName: item.gradeName,
					subjectId: item.subjectId,
					subjectName: item.subjectName,
					numberOfStudents: undefined,
					FileId: item.FileId
				},
				isCreate: isCreate,
				selectedLevel: item.levelName,
				selectedGrade: item.gradeName
			},
			() => {
				this.toggleDrawer(item);
			}
		);
	}

	toggleAddTeacherModal = () => {
		this.setState({
			addTeacherModalVisible: !this.state.addTeacherModalVisible
		});
	};

	addTeacherPopup = () => {
		this.toggleAddTeacherModal();
	};

	addTeacherHandleOk = () => {
		// TODO: Add teacher
		console.log("todo add teacher");
		this.toggleAddTeacherModal();
	};

	addTeacherHandleCancel = () => {
		this.toggleAddTeacherModal();
	};

	onLevelChange = (level: any) => {
		this.setState({
			selectedLevel: level
		});
	};

	onGradeChange = (grade: any) => {
		this.setState({
			selectedGrade: grade
		});
	};

	search = (value: string) => {
		this.setState({
			filteredData: SearchUtil(this.state.classes, value, {
				column1: "className",
				column2: "classDescription"
			})
		});
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file]
		});
	};

	loadTags = () => {
		return new TagService().getAllFor().then((result: any) => {
			return this.setState({
				tags: result
			});
		});
	};

	renderTags = () => {
		return this.state.tags
			.filter((x: any) => {
				return !x.ParentId;
			})
			.map((tag: any) => {
				return (
					<Row>
						<Col span={24}>
							<Select
								name={tag.id}
								label={tag.name}
								style={inputWidth}
								listHeight={1000}
								showSearch
								filterOption={(inputValue: string, option?: any): boolean => {
									return option.children.toLowerCase().includes(inputValue.toLowerCase());
								}}
							>
								{this.state.tags
									.filter((item: any) => {
										return item.ParentId === tag.id;
									})
									.map((childTag: any) => (
										<Option key={childTag.id} value={childTag.id}>
											{childTag.name}
										</Option>
									))}
							</Select>
						</Col>
					</Row>
				);
			});
	};

	render() {
		const { t: translate } = this.props;

		const title = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_5");
		const subtitle1 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const subtTitle2 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_5");
		const btnTitle = translate(`${TRANSLATION_BASE_PATH}._BTN_ADD`);
		const cardTitleCourses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._COURSES`);
		const cardTitleTotalRegistered = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._TOTAL_REGISTERED`);
		const cardTitleTotalCompleted = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._TOTAL_COMPLETED`);
		const cardTitleVideoTotal = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._VIDEO`);
		const cardTitleQuizTotal = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._QUIZ`);
		const cardTitlePdfTotal = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._PDF`);

		const columns = [{
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._COURSE`),
			dataIndex: "className",
			key: "className",
			fixed: "left",
			width: 250,
			render: (text: any, row: any) => {
				return (
					<Row>
						<Space>
							<Col>
								<CircularIcon type="ri-slideshow-3-line" />
							</Col>
							<Col>
								<Row>{text}</Row>
								{row.classDescription && (
									<Row>
										<Text
											fontSize="12"
											lineHeight="20"
											className="color-gray-7"
										>
											<TruncateMarkup lines={2}>
												<div>{parse("" + row.classDescription)}</div>
											</TruncateMarkup>
										</Text>
									</Row>
								)}
							</Col>
						</Space>
					</Row>
				);
			}
		},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._ORGANIZATION`),
				dataIndex: "organizationName",
				key: "organizationName",
				render: (text: any, row: any) => {
					return (
						<Row>
							<Text fontSize="12" lineHeight="20" className="color-gray-7">
								{row.organizationName}
							</Text>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._AUTHOR`),
				dataIndex: "createdBy",
				key: "createdBy",
				render: (text: any, row: any) => {
					return (
						<Row>
							<Space direction="horizontal" size={8}>
								<UserAvatar userId={text} firstName={row.firstName} lastName={row.lastName} />
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{row.firstName} {row.lastName}
								</Text>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS`),
				dataIndex: "classroomStatus",
				key: "classroomStatus",
				filters: [{
					text: (
						<Badge
							color={"#52c41a"}
							text={translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_ACTIVE`)}
						/>
					),
					value: "ACTIVE"
				},
					{
						text: (
							<Badge
								color={"#98a9c0"}
								text={translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_ARCHIVED`)}
							/>
						),
						value: "ARCHIVED"
					}],
				onFilter: (value: any, record: any) => record.classroomStatus.indexOf(value) === 0,
				render: (status: any, row: any) => {
					return (
						<Row>
							<Badge
								color={status === "ACTIVE" ? "#52c41a" : "#98a9c0"}
								text={translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_${status}`)}
							/>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._SUBJECT`),
				dataIndex: "subjectName",
				key: "subjectName",
				render: (text: any, row: any) => {
					return (
						<>
							<Row>
								{text} {row.gradeName.split(" ").length > 1 && row.gradeName.split(" ")[1]}
							</Row>
							<Row>
								<Text fontSize="12" lineHeight="20" className="color-gray-7">
									{translate(`_ADMIN._LEVELS._${row.gradeLevel}`)}
								</Text>
							</Row>
						</>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._SEEN`),
				dataIndex: "countSeen",
				key: "countSeen",
				align: "right",
				sorter: (a: any, b: any) => a.countSeen - b.countSeen,
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._COMPLETED`),
				dataIndex: "countCompleted",
				key: "countCompleted",
				align: "right",
				sorter: (a: any, b: any) => a.countCompleted - b.countCompleted,
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._PRICE`),
				dataIndex: "classroomPrice",
				key: "classroomPrice",
				render: (text: any, row: any) => {
					return (
						<Space>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{text}
							</Text>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{"ALL"}
							</Text>
						</Space>
					);
				}
			},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				fixed: "right",
				width: 150,
				align: "center",
				render: (id: any, row: any) => {
					const teacherCourseBuilderPath = `/${COURSE_BUILDER_PATH}/_teacher`;
					const individualCourseBuilderPath = `/${COURSE_BUILDER_PATH}/individual`;
					const path = row?.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Individual
						? individualCourseBuilderPath
						: teacherCourseBuilderPath

					const to = `${path}/${row.id}`;
					return <TableRowNavLink to={to} />;
				}
			}];

		let submitHandler: any;

		const CreateEditForm = () => {
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.onSubmit}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row>
									<Col span={24}>
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._FOTO`)}
										</Text>
									</Col>
								</Row>
								<Row className="mb-8">
									<Col span={24}>
										{translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._SUGGEST`)}
										<a href="https://unsplash.com/" rel="noopener noreferrer" target="_blank">
											Unsplash.com
										</a>
										{translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._OR`)}
										<a href="https://pexels.com/" rel="noopener noreferrer" target="_blank">
											Pexels.com
										</a>
									</Col>
								</Row>
								<Row className="mb-24">
									<Col span={24}>
										<Uploader
											fileType={"IMAGE"}
											filePath={`classroom/${this.state.formInitialValues?.id}`}
											fileWithUniqueId={false}
											onUploadingDone={this.onUploadingDone}
											defaultFileList={this.state.formInitialValues.defaultFileList}
											onSoftDeleteFile={this.onSoftDeleteFile}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Input
											name="name"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._NAME`)}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<FormikTextAreaField
											name="description"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._DESCRIPTION`)}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Radio
											name="virtualClassroomType"
											value={formik.values.virtualClassroomType}
											defaultValue={formik.values.virtualClassroomType}
											label="Lloji kursit"
											labelInfo={classroomTypePopover(translate)}
											onRadioChange={(e: any) => {
												if (
													e.target.value ===
														TypeOfClassroomBasedOnEnrollments.Individual
												) {
													formik.setFieldValue("numberOfStudents", null);
												}
											}}
										>
											<RadioElement
												withOutStyle={true}
												value={TypeOfClassroomBasedOnEnrollments.Individual}
											>
												<Text
													fontSize="14"
													lineHeight="22"
													className="color-gray-8"
												>
														Individual
												</Text>
											</RadioElement>
											<RadioElement
												withOutStyle={true}
												value={TypeOfClassroomBasedOnEnrollments.Group}
											>
												<Text
													fontSize="14"
													lineHeight="22"
													className="color-gray-8"
												>
														Grupe
												</Text>
											</RadioElement>
										</Radio>
									</Col>
								</Row>
								{formik.values.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Group && (
									<Row>
										<Col span={24}>
											<Input
												name="numberOfStudents"
												placeholder="Numri maksimal i nxënësve"
											/>
										</Col>
									</Row>
								)}
								<Row>
									<Col span={24}>
										<Select
											name="teacher"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._TEACHER`)}
											placeholder={translate(
												`${TRANSLATION_BASE_PATH}._FORM._PLACEHOLDER_FOR._TEACHER`
											)}
											style={inputWidth}
										>
											{["Akademia.al"].map(item => (
												<Option key={item} value={item}>
													{item}
												</Option>
											))}
										</Select>
									</Col>
								</Row>
								<Row className="mb-8">
									<Col span={24}>
										<a onClick={this.addTeacherPopup}>
											{translate(`${TRANSLATION_BASE_PATH}._BTN_ADD_TEACHER`)}
										</a>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Select
											name="level"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._LEVEL`)}
											placeholder={translate(
												`${TRANSLATION_BASE_PATH}._FORM._PLACEHOLDER_FOR._LEVEL`
											)}
											style={inputWidth}
											onChange={this.onLevelChange}
											defaultValue={this.state.formInitialValues.level || ""}
										>
											{this.state.allLevels?.map((item: string) => (
												<Option key={item} value={item}>
													{translate(`_ADMIN._LEVELS._${item}`)}
												</Option>
											))}
										</Select>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Select
											name="gradeId"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._GRADE`)}
											placeholder={translate(
												`${TRANSLATION_BASE_PATH}._FORM._PLACEHOLDER_FOR._GRADE`
											)}
											style={inputWidth}
											onChange={this.onGradeChange}
											defaultValue={
												!this.state.isCreate && this.state.formInitialValues.gradeName
											}
										>
											{this.state.selectedLevel &&
												this.state.allGrades[this.state.selectedLevel]?.map((item: any) => {
													return (
														<Option key={item.id} value={item.gradeId}>
															{item.name}
														</Option>
													);
												})}
										</Select>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Select
											name="subjectId"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIELDS._SUBJECT`)}
											placeholder={translate(
												`${TRANSLATION_BASE_PATH}._FORM._PLACEHOLDER_FOR._SUBJECT`
											)}
											style={inputWidth}
											defaultValue={this.state.formInitialValues.subjectName}
											listHeight={1000}
											showSearch
											filterOption={(inputValue: string, option?: any): boolean => {
												return option.children.toLowerCase().includes(inputValue.toLowerCase());
											}}
										>
											{this.state.selectedLevel &&
												this.state.selectedGrade &&
												this.state.allGrades[this.state.selectedLevel]
													?.filter((x: any) => x.id === this.state.selectedGrade)
													.map((x: any) =>
														x.GradeSubjects?.map((x: any) => (
															<Option key={x.SubjectId} value={x.Subject.id}>
																{x.Subject.name}
															</Option>
														))
													)}
										</Select>
									</Col>
								</Row>
								{this.state.tags && (
									<>
										<Row>
											<Divider className="p-0" />
										</Row>
										<Row className="pb-16">
											<Text fontSize="14" lineHeight="20" wheight="semibold">
												Grupimet
											</Text>
										</Row>
										{this.renderTags()}
									</>
								)}
							</form>
						);
					}}
				</Formik>
			);
		};

		return (
			<>
				<SectionTitle title={title} subtitle={[subtitle1, subtTitle2]} subtitleUrl={[DASHBOARD_PATH]} />

				<CreateEditDrawer
					title={btnTitle}
					onClose={this.toggleDrawer}
					onSave={() => submitHandler()}
					visible={this.state.drawerShown}
					submitting={this.state.submitting}
					form={CreateEditForm}
					width={window.innerWidth >= 576 ? 408 : window.innerWidth}
				/>

				<SectionContent>
					<Row gutter={[16, 16]}>
						<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
							<CounterCard
								title={cardTitleCourses}
								icon="ri-slideshow-3-line"
								data={this.state.cardTotalCourses}
								loading={this.state.loading}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
							<CounterCard
								title={cardTitleTotalRegistered}
								icon="ri-eye-line"
								data={this.state.cardTotalRegistered}
								loading={this.state.loading}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
							<CounterCard
								title={cardTitleTotalCompleted}
								icon="ri-checkbox-circle-line"
								data={this.state.cardTotalCompleted}
								loading={this.state.loading}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
							<CounterCard
								title={cardTitleVideoTotal}
								icon="ri-play-mini-fill"
								data={this.state.cardTotalVideos}
								loading={this.state.cardTotalVideosLoading}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
							<CounterCard
								title={cardTitleQuizTotal}
								icon="ri-question-fill"
								data={this.state.cardTotalQuiz}
								loading={this.state.cardTotalQuizLoading}
							/>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
							<CounterCard
								title={cardTitlePdfTotal}
								icon="ri-slideshow-2-line"
								data={this.state.cardTotalPdf}
								loading={this.state.cardTotalPdfLoading}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={24} sm={4} md={3} lg={3} xl={2} xxl={2}>
							<Button onClick={() => this.loadItemAndShowDrawer(null)} type="primary" size="middle" block>
								<Space>
									<Text fontSize="14" lineHeight="22">
										<Icon type="ri-add-line"></Icon>
									</Text>
									{btnTitle}
								</Space>
							</Button>
						</Col>
						<Col xs={24} sm={20} md={21} lg={21} xl={22} xxl={22}>
							<Row justify="end">
								<ResponsiveSearchBox placeholder={translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} onSearch={this.search} />
							</Row>
						</Col>
					</Row>
					<Skeleton loading={this.state.loadingCoursesOfPublicOrganizations}>
						<AdminTable
							dataSource={this.state.filteredData}
							columns={columns}
							scroll={{ x: 1200, y: "max-content" }}
							locale={{
								emptyText: (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
									/>
								)
							}}
						/>
					</Skeleton>
				</SectionContent>
				<Modal
					title={translate(`${TRANSLATION_BASE_PATH}._BTN_ADD_TEACHER`)}
					visible={this.state.addTeacherModalVisible}
					onOk={this.addTeacherHandleOk}
					onCancel={this.addTeacherHandleCancel}
				>
					<Formik
						enableReinitialize={true}
						validationSchema={formValidator(translate)}
						initialValues={this.state.formInitialValues}
						onSubmit={this.onSubmit}
						isInitialValid={false}
					>
						{formik => {
							const { handleSubmit, submitForm } = formik;
							submitHandler = submitForm;
							return (
								<form onSubmit={handleSubmit} autoComplete="off">
									<Row gutter={[0, 24]}>
										<Input
											name="teacherName"
											label={translate(`${TRANSLATION_BASE_PATH}._LABEL_TEACHER_NAME`)}
											style={inputWidth}
										/>
									</Row>
								</form>
							);
						}}
					</Formik>
				</Modal>
			</>
		);
	}
}

const LiveClasses = withTranslation()(LiveClassesComponent);
export default LiveClasses;
