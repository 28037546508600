import { OrganizationPage } from "../../dtos/administration/customize/OrganizationPage.dto";
import ApiServiceData from "../api.service";

const relativeUrl = "/v1/api/organization-pages";

export default class OrganizationPageService extends ApiServiceData {
	async find(organizationId: string, pageId: string): Promise<OrganizationPage> {
		return this.get(`${relativeUrl}/${organizationId}/${pageId}`)
			.then(res => {
				return res.data as OrganizationPage;
			})
			.catch(error => {
				throw error.response;
			});
	}

	async save(data: OrganizationPage) {
		return this.post(`${relativeUrl}`, data)
			.then(res => {
				return res.data as OrganizationPage;
			})
			.catch(error => {
				throw error.response;
			});
	}

	async reset(organizationId: string, pageId: string) {
		return this.get(`${relativeUrl}/reset/${organizationId}/${pageId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
