import React, { Component, ReactNode } from "react";
import { Col, Divider, Radio, Row, Space, Card, Skeleton, Select, Input, Empty, Modal} from "antd";
import { Formik,Form } from "formik";
import { Prompt } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactPlayer from "react-player/lazy";
import _ from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Icon from "common/components/general/Icon";
import Button from "common/components/general/Button";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import FormikSelectFieldUpdate from "common/components/dataEntry/formik/FormikSelectFieldUpdate";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import { HtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import { ChildContext } from "scenes/courseBuilder/scenes/buildMaterials/components/layout/Layout";
import FormikRadioField from "common/components/dataEntry/formik/FormikRadioField";
import { formValidator } from "./ValidationSchema";
import LessonService from "services/administration/lesson.service";
import { showMessage,showError } from "common/utils/Notification";
import AddMaterials from "../../components/materials/MaterialsComponent";
import { treeToArray } from "../../../../utils/ListUtils";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { createFile, previewFile } from "../../../../utils/FileUtils";
import { Uploader } from "common/components/uploader/Uploader";
import FormikDatePickerField from "common/components/dataEntry/formik/FormikDatePickerField";
import { createNewLessonNotifications } from "common/components/_notifications/util/createNotifications";
import { CreateNewLessonNotificationDto } from "common/components/_notifications/dtos/createNewLessonNotificationDto";
import TagService from "services/administration/tag.services";
import Material from "dtos/course-builder/material.dto";
import TagDto from "dtos/administration/tag.dto";
import {UpdateTag} from '../../components/updateTag'
export const LESSON_FULL_PATH = "lesson/:lessonId";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._LESSON_MATERIAL";

class LessonMaterialComponent extends Component<any, any> {
	static contextType = ChildContext;

	constructor(props: any) {
		super(props);
		this.state = {
			rootLessonTags: [],
			formInitialValues: {},
			showPreview: false,
			loading: false,
			subjectPlanTrees: [],
			lessonId: "",
			classroomId: "",
			contentType: "url",
			s3SignedUrl: "",
			fileUploaded: null,
			filesToDelete: [],
			uploading: false,
			newOption: "",
			isOnUpdate: false
		};
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props;
		if (!params.lessonId) return this.context.closeLessonMaterial();
		this.loadLessonMaterial(params.lessonId);
	}

	loadLessonMaterial = async (lessonId: string) => {
		const { t: translate } = this.props;
		this.setState({ loading: true });
		const lesson: any = await new LessonService().getLessonById(lessonId);
		const chapterId =
			lesson && lesson.SubjectPlanTrees && lesson.SubjectPlanTrees.length > 0
				? lesson.SubjectPlanTrees[0].id
				: null;

		this.context.setActualLessonId(lessonId);
		this.context.setEditMode(!!lesson);
		this.context.setTitle(
			this.context.isEditMode
				? translate(`${TRANSLATION_BASE_PATH}._MODIFY_LESSON`)
				: translate(`${TRANSLATION_BASE_PATH}._ADD_LESSON`)
		);

		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const subjectPlanId = queryString.get("subjectPlanId");
		const subjectPlanTreeId = queryString.get("subjectPlanTreeId");
		const classroomId = queryString.get("classroomId");
		this.context.setClassroom(classroomId);

		const rootLessonTags: any[] = await this.getAllTagsTree(classroomId || "");
		const allLessonGroups: any[] = await new TagService().getAllLessonsGroup(lessonId);
		const subjectPlanTree =
			subjectPlanId && (await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(subjectPlanId));
		const subjectPlanTrees = (subjectPlanTree || subjectPlanTree !== "") && treeToArray(subjectPlanTree);
		const filteredSubjectPlanTrees = subjectPlanTrees && subjectPlanTrees.filter(node => node.level > 0);
		const root = subjectPlanTrees && _(subjectPlanTrees).find(node => node.isRoot === true);
		this.setState({
			loading: false,
			showPreview: !!lesson,
			lessonId: lessonId,
			rootLessonTags: rootLessonTags,
			classroomId: classroomId,
			defaultFileList: lesson?.File ? [await previewFile(lesson.File)] : [],
			fileUploaded: lesson?.File,
			subjectPlanTrees: filteredSubjectPlanTrees,
			rootId: root && root.id,
			formInitialValues: this.createInitialValuesFormik(
				lesson,
				root,
				allLessonGroups,
				rootLessonTags,
				chapterId,
				subjectPlanTreeId
			)
		});
	};

	createInitialValuesFormik(
		lesson: any,
		root: any,
		allLessonGroups: any,
		rootLessonTags: any[],
		chapterId: string,
		subjectPlanTreeId: string | null
	) {
		const formInitialValues = {
			...lesson,
			startDate: (lesson && lesson.startDate) || undefined,
			endDate:
				(lesson && lesson.endDate) ||
				(this.context.classroom && !this.context.classroom.isAsync ? new Date() : undefined),
			chapterId: chapterId ? (chapterId !== root?.id ? chapterId : "") : subjectPlanTreeId,
			contentType: lesson ? (lesson.videoUrl ? "url" : "file") : "url",
			name: lesson && lesson?.name,
			videoUrl: lesson && lesson?.videoUrl,
			materials: lesson
				? lesson.LessonSections.map((lessonSection: any) => ({
					id: lessonSection.id,
					title: lessonSection.name,
					lessonId: lesson.id,
					path: lessonSection.url,
					File: lessonSection.File,
					FileId: lessonSection.File?.id,
					fileList: [],
					type: lessonSection.File ? "file" : "url"
				  }))
				: [],
			path: lesson?.File?.name
		};

		const parentChoosen = allLessonGroups.map((lessonSubGroup: any) => {
			const parent = rootLessonTags.find((rootLesson: any) => rootLesson.id === lessonSubGroup.ParentId);
			return { childId: lessonSubGroup.id, parentName: parent?.name };
		});

		parentChoosen.map((parent: any) => {
			formInitialValues[parent.parentName] = parent.childId;
		});

		return formInitialValues;
	}

	activatePreview = () => {
		this.setState({ showPreview: true });
	};

	getAllTagsTree = async (classroomId: string) => {
		const rootLessonTags: any = OAuth2Service.isOwnerOrAdminAtDefaultOrganization
			? await new TagService().getAllRootTagsforAdminLessons(classroomId)
			: await new TagService().getAllRootTagsforLessons();

		const tagTree = await Promise.all(
			rootLessonTags.map(async (tag: any) => {
				let childTags: any[] = [];
				if (tag.isGlobal) childTags = await new TagService().getAllChildTags(tag.id);
				else childTags = await new TagService().getAllChildTagsForClassroom(tag.id, classroomId);
				return {
					...tag,
					childTags: _(childTags)
						.orderBy(x => Number(x.priority), "asc")
						.value()
				};
			})
		);

		return tagTree;
	};

	saveLesson = async (lessonDto: any) => {
		const { t: translate } = this.props;
		this.context.setShowPromptState(true);
		const file = createFile(this);
		const lessonServerDto = {
			id: lessonDto.id,
			name: lessonDto.name,
			description: lessonDto.description,
			videoUrl: lessonDto.videoUrl,
			subjectPlanTreeId: this.state.rootId,
			WeekId: "f97b8b78-fe06-4f12-b95b-6abced66c1d1",
			startDate: this.context.isEditMode ? moment(lessonDto.startDate).toDate() : new Date(),
			endDate: lessonDto.endDate ? moment(lessonDto.endDate).toDate() : null,
			lessonType: "VIDEO",
			File: file,
			FileId: file !== null ? file?.id : null,
			LessonSections: lessonDto.materials?.map((material: Material) => {
				return {
					id: material.id,
					name: material.title,
					lessonId: lessonDto.id,
					url: material.path,
					File: material.File,
					FileId: material.FileId
				};
			}),
			classroomId: this.state.classroomId,
			GroupingInfo: this.state.rootLessonTags
				.map((rootTag: any) => {
					const element: string = rootTag.name || "";
					return lessonDto[element];
				})
				.filter((rootTag: any) => !!rootTag)
		};

		return (this.context.isEditMode
			? new LessonService().updateLesson(lessonServerDto)
			: new LessonService().saveLesson(lessonServerDto)
		)
			.then((result: any) => {
				showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
				return result.data.id;
			})
			.then((recordId: string) => {
				if (this.context.isEditMode) return;
				return createNewLessonNotifications(
					new CreateNewLessonNotificationDto(
						this.state.classroomId,
						lessonServerDto.name,
						lessonServerDto.lessonType,
						recordId,
						OAuth2Service.CurrentUser?.User
					)
				);
			})
			.finally(() => {
				this.context.closeLessonMaterial();
			});
	};

	onBeforeUnload = (event: any) => {
		event.preventDefault();
		event.returnValue = "Some browsers display this to the user";
	};

	onUploadingDone = (formik: any) => {
		return (fileUploaded: any) => {
			formik.setFieldValue("path", fileUploaded?.name ? fileUploaded?.name : undefined);
			this.setState({
				fileUploaded: fileUploaded,
				uploading: true
			});
		};
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			fileUploaded: undefined
		});
	};

	onUploadingStarted = () => {
		this.setState({
			uploading: true
		});
	};

	addItemToTagList = (input: string, parentId: string, nextOrder: number) => {
		if (input !== "") {
			const newTag: any = new TagDto({
				name: input,
				target: "LESSON",
				asyncOnly: false,
				isGlobal: false,
				priority: nextOrder.toString(),
				ParentId: parentId,
				SubTagsCount: "0"
			});
			newTag.ClassroomTags = [{ TagId: newTag.id, ClassroomId: this.state.classroomId }];
			return new TagService()
				.createTagTree([newTag])
				.then(() => {
					return this.getAllTagsTree(this.state.classroomId);
				})
				.then((rootLessonTags: any) => {
					return this.setState({ rootLessonTags: rootLessonTags, newOption: "" });
				});
		}
	};

	getAddNewItem = (menu: any, parentId: string, nextOrder: number): any => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					{menu}
					<Divider className={"mt-4 mb-4"} />
					<Row className={"pr-4 pl-4"}>
						<Col span={20}>
							<Input
								placeholder={translate(`${TRANSLATION_BASE_PATH}._ADD_OPTION`)}
								value={this.state.newOption}
								onChange={this.onChange}
							/>
						</Col>
						<Col span={4}>
							<Row justify="center" align="middle" className="full__height">
								<a
									style={{ cursor: "pointer" }}
									onClick={() => this.addItemToTagList(this.state.newOption, parentId, nextOrder)}
								>
									<Icon type="ri-add-line" fontSize="12" />{" "}
									{translate(`${TRANSLATION_BASE_PATH}._ADD`)}
								</a>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	onChange = (e: any) => {
		this.setState({ newOption: e.target.value });
	};

	removeNewOption = () => {
		this.setState({ newOption: "" });
	};

	toggleInputWithSelectedTag = async (SelectedTag: any) =>{
		this.setState((state: any)=>({...state,
			CurrentTag: SelectedTag
		}),() => this.toggleDrawer())
	}

	openDeleteGroupConfirmationModal = (tag:any) => {
		const { t: translate } = this.props;

		return Modal.confirm({
			title: translate(`${TRANSLATION_BASE_PATH}._DELETE_GROUP_TITLE`),
			content: translate(`${TRANSLATION_BASE_PATH}._DELETE_CHAPTER_MESSAGE`),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => {
				this.deleteTag(tag)
				this.getTagsAfterDelete()
			}
		});
	};

	deleteTag = async (tag: any) => {
		const { t: translate,location } = this.props;
		const search = location.search;
		const queryString = new URLSearchParams(search);
		const classroomId = queryString.get("classroomId");
		await new TagService().deleteClassroomTagId(tag.id, classroomId ? classroomId : "")
		.finally(async () =>{
			showMessage(translate(`${TRANSLATION_BASE_PATH}._DELETE_TAG_SUCCESS`));
			this.getTagsAfterDelete()
		})		
	};

	getTagsAfterDelete = async () =>{
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const classroomId = queryString.get("classroomId");
		const rootLessonTags: any[] = await this.getAllTagsTree(classroomId || "");
		this.setState((state:any) =>({...state,rootLessonTags}))
	}

	SelectOtionGlobal = (lessonSubTag: any) =>{
		return (
			<Select.Option 
				key={lessonSubTag.id} value={lessonSubTag.id}>
				{lessonSubTag.name}
			</Select.Option>
		);
	}

	SelectOtionNotGlobal = (lessonSubTag: any) =>{
		return (
			<Select.Option 
				onMouseEnter={()=> this.setState({changed: false})}
				onMouseLeave={()=> this.setState({changed: true})}
				key={lessonSubTag.id} value={lessonSubTag.id}>
				{!this.state.changed ?
					<div style={{display:'flex',justifyContent:'space-between'}}>
						{lessonSubTag.name || ""}
						<div>
							<a style={{paddingRight:'10px'}} 
								onClick={()=> this.toggleInputWithSelectedTag(lessonSubTag)}>
								<span><i className="ri-edit-2-line"></i></span>
							</a>
							<a style={{paddingRight:'10px'}} 
								onClick={()=> this.openDeleteGroupConfirmationModal(lessonSubTag.ClassroomTags[0])}>
								<span><i className="ri-delete-bin-6-line"></i></span>
							</a>
						</div>
					</div>
				: lessonSubTag.name
			}
			</Select.Option>
		);
	}

	updateTagName = (tag:any,value:any) => {
		let selectedTag = tag;
		selectedTag.name = value.name
		this.setState((state:any) => ({
			...state,
			CurrentTag: selectedTag
		}),() => this.onSubmitForm(this.state.CurrentTag));
	}
	
	RenderChildTagAddForm = () => {
		const { t: translate } = this.props;
		const tag = this.state.CurrentTag
		return (<UpdateTag tag={tag} name={tag.name} onSubmitForm={this.updateTagName}/>
		);
	};

	getLessonClassroomTags = (formik: any): ReactNode => {
		const { t: translate } = this.props;
		return this.state.rootLessonTags.map((lessonTag: any, index: number) => {
			const formikSelectFiledProps = !lessonTag.isGlobal
				? {
					dropdownRender: (menu: any) =>
						this.getAddNewItem(menu, lessonTag.id, lessonTag.childTags?.length || 0)
				  }
				: {};
			return (
				<Row key={index}>
					<Col span={24}>
						<FormikSelectFieldUpdate
							key={index}
							name={lessonTag.name}
							label={lessonTag.name}
							defaultValue={this.state.deletedTag ? '' : formik.initialValues[lessonTag.name]}
							placeholder={translate(`${TRANSLATION_BASE_PATH}._CHOOSE`)}
							allowClear={true}
							onFocus={this.removeNewOption}
							notFoundContent={
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
								/>
							}
							{...formikSelectFiledProps}
						>
							{lessonTag.childTags?.map((lessonSubTag: any) => {
								if(!lessonTag.isGlobal && lessonTag.name !== 'Java'){
									return (this.SelectOtionNotGlobal(lessonSubTag))
								}else{
									return (this.SelectOtionGlobal(lessonSubTag))
								}
							})}
						</FormikSelectFieldUpdate>
					</Col>
				</Row>
			);
		});
	};

	toggleDrawer = () => {
		this.setState({
			isOnUpdate: !this.state.isOnUpdate
		});
	}

	onSubmitForm = (selectedTag: any) => {
		const { t: translate } = this.props;
		delete selectedTag.ClassroomTags
		return new TagService().updateClassroomTagId(selectedTag.id,selectedTag)
			.then(() => {
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._FORM._EDIT_SUCCESS`));
			})
			.finally(() => {
				this.toggleDrawer()
			});
	};



	render() {
		const { t: translate } = this.props;
		const { handleOnSubmit } = this.props;
		return (
			<Skeleton active loading={this.state.loading}>
				<Formik
					enableReinitialize={true}
					initialValues={this.state.formInitialValues}
					onSubmit={this.saveLesson}
					validationSchema={formValidator(translate)}
				>
					{formik => {
						const { handleSubmit, submitForm, dirty } = formik;
						handleOnSubmit(submitForm);
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								{!this.context.showPrompt && dirty && (
									<Prompt when={dirty} message={translate(`${TRANSLATION_BASE_PATH}._LEAVE_PAGE`)} />
								)}
								<Card>
									<Row className="courseBuilder__lessonMaterial">
										<Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
											{this.getLessonClassroomTags(formik)}
											{this.state.isOnUpdate && this.RenderChildTagAddForm()}
										</Col>
										<Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
											<Row align="middle" justify="center" className="full__height">
												<Divider type={"vertical"} className="divider" />
											</Row>
										</Col>
										<Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15} className="full__width">
											<Row>
												<Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
													{this.getLessonClassroomTags(formik)}
													{this.state.isOnUpdate && this.RenderChildTagAddForm()}
													<Divider />
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<FormikDefaultInputField
														name="name"
														label={translate(`${TRANSLATION_BASE_PATH}._NAME`)}
														placeholder={translate(
															`${TRANSLATION_BASE_PATH}._NAME_PLACEHOLDER`
														)}
													/>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<HtmlInput
														name="description"
														label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
														placeholder={translate(
															`${TRANSLATION_BASE_PATH}._DESCRIPTION_PLACEHOLDER`
														)}
														labelInfo={translate(
															`${TRANSLATION_BASE_PATH}._DESCRIPTION_INFO`
														)}
													/>
												</Col>
											</Row>
											{this.context.classroom && !this.context.classroom.isAsync && (
												<Row>
													<Col span={24}>
														<FormikDatePickerField
															minuteStep={5}
															dateFormat="YYYY-MM-DD HH:mm"
															className="full__width"
															name="endDate"
															defaultValue={moment(formik.initialValues.endDate)}
															label={translate(`${TRANSLATION_BASE_PATH}._DUE_DATE`)}
															placeholder={translate(
																`${TRANSLATION_BASE_PATH}._DUE_DATE_PLACEHOLDER`
															)}
															showTime={{
																defaultValue: moment("00:00", "HH:mm")
															}}
															disabledDate={d => !d || d.isBefore(moment(), "day")}
														/>
													</Col>
												</Row>
											)}
											<Row>
												<Col span={24}>
													<Row>
														<FormikRadioField
															name={"contentType"}
															label={translate(`${TRANSLATION_BASE_PATH}._VIDEO_LESSON`)}
															defaultValue={formik.values.contentType}
														>
															<Radio value={"url"}>
																{translate(`${TRANSLATION_BASE_PATH}._YOUTUBE_URL`)}
															</Radio>
															<Radio value={"file"}>
																{translate(`${TRANSLATION_BASE_PATH}._UPLOAD`)}
															</Radio>
														</FormikRadioField>
													</Row>
													{formik.values.contentType === "url" ? (
														<Space className="full__width" direction="vertical">
															<Row>
																<Col span={24}>
																	<Row>
																		<Col
																			xs={24}
																			sm={16}
																			md={18}
																			lg={20}
																			xl={21}
																			xxl={21}
																		>
																			<FormikDefaultInputField
																				name={"videoUrl"}
																				value={formik.values?.videoUrl}
																				onChange={(e: any) => {
																					formik.setFieldValue(
																						"videoUrl",
																						e.target.value
																					);
																					this.setState({
																						showPreview: false
																					});
																				}}
																			/>
																		</Col>
																		<Col
																			xs={24}
																			sm={8}
																			md={6}
																			lg={4}
																			xl={3}
																			xxl={3}
																		>
																			<Button
																				htmlType="button"
																				type="default"
																				onClick={this.activatePreview}
																				className="full__width"
																			>
																				{translate(
																					`${TRANSLATION_BASE_PATH}._ADD`
																				)}
																			</Button>
																		</Col>
																	</Row>
																</Col>
															</Row>
															{this.state.showPreview &&
																formik.errors.videoUrl === undefined && (
																<Row>
																	<Col span={24}>
																		<ReactPlayer
																			width="100%"
																			url={formik.values.videoUrl}
																			config={{
																				youtube: {
																					playerVars: {
																						modestbranding: 1
																					}
																				},
																				file: {
																					attributes: {
																						controlslist: "nodownload",
																					}
																				}
																			}}
																			onContextMenu={(e: any) => e.preventDefault()}
																		/>
																	</Col>
																</Row>
															)}
														</Space>
													) : (
														<>
															<Uploader
																fileType={"VIDEO"}
																filePath={`lesson/${this.props.lessonId}`}
																fileWithUniqueId={false}
																onUploadingDone={this.onUploadingDone(formik)}
																defaultFileList={this.state.defaultFileList}
																onSoftDeleteFile={this.onSoftDeleteFile}
																onUploadingStarted={this.context.onUploadingStarted}
															/>
															<FormikDefaultInputField name={"path"} type={"hidden"} />
														</>
													)}
												</Col>
											</Row>
											<Divider></Divider>
											{this.state.lessonId ? (
												<AddMaterials
													materials={formik.values.materials}
													deleteHandler={material => {
														const newArray = formik.values.materials;
														const index = newArray.findIndex(
															(entry: any) => entry.id === material.id
														);
														newArray.splice(index, 1);
														formik.setFieldValue("materials", newArray);
														this.context.setShowPromptState(false);
													}}
													saveHandler={material => {
														let newArray: any = formik.values.materials;
														if (material.id === undefined) {
															material.id = uuidv4();
															newArray = [...formik.values.materials, material];
														} else {
															const index = formik.values.materials.findIndex(
																(entry: any) => entry.id === material.id
															);
															if (index > -1) {
																newArray.splice(index, 1, material);
															}
														}
														formik.setFieldValue("materials", newArray);
													}}
													openModal={opendModal =>
														this.context.setShowPromptState(opendModal)
													}
													lessonId={this.state.lessonId}
												/>
											) : null}
										</Col>
									</Row>
								</Card>
							</form>
						);
					}}
				</Formik>
			</Skeleton>
		);
	}
}

const LessonMaterial = withTranslation()(LessonMaterialComponent);
export default LessonMaterial;
