import { Col, Collapse, Dropdown, Menu, Modal, Popover, Row, Space } from "antd";
import { Formik, FormikProps } from "formik";
import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { showError, showMessage } from "common/utils/Notification";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Select from "common/components/dataEntry/formik/FormikSelectField";
import Option from "common/components/dataEntry/components/Option";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import { getUuidCode } from "common/utils/UuidCode";
import { formValidator as formValidatorCreateClass } from "./CreateClassValidationSchema";
import { formValidator as formValidatorJoinClass } from "./JoinClassOrgValidationSchema";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import EnrollmentDto from "dtos/administration/enrollment.dto";
import CreateClassroomDto from "dtos/administration/createClassroom.dto";
import ClassroomService from "services/administration/classroom.service";
import OrganizationService from "services/administration/organization.service";
import EnrollmentService from "services/administration/enrollment.service";
import { COURSE_PATH } from "scenes/course";
import OAuth2Service from "services/authentication/oauth2.service";
import GradeService from "services/administration/grade.service";
import { GradeLevel } from "services/domain/administration/Grade";
import { COURSE_BUILDER_PATH } from "../../../courseBuilder";
import { Uploader } from "common/components/uploader/Uploader";
import FormikTextAreaField from "common/components/dataEntry/formik/FormikTextAreaField";
import TagService from "services/administration/tag.services";
import { OrganizationSectorType } from "services/domain/administration/Organization";
import { HtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import FormikDateRangePickerField from "common/components/dataEntry/formik/FormikDateRangePickerField";
import {
	classroomDescriptionPopover,
	classroomIntervalPopover,
	classroomTagsTitlePopover,
	classroomTypePopover
} from "./CreateClassroomHelpers";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";
const drawerInputWidth = { minWidth: "250px" };

export enum TypeOfClassroom {
	Asynch = "OFFLINE",
	Virtual = "LIVE",
	All = "ALL"
}

class JoinCreateClassModalComponent extends Component<any, any> {
	submitHandler: any;

	constructor(props: any) {
		super(props);
		this.state = {
			drawerIsVisible: false,
			modalIsVisible: false,
			currentUserId: null,
			formInitialValues: {},
			isAsynchClassroom: false,
			submitting: false,
			filesToDelete: []
		};
	}

	componentDidMount() {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		this.setState({
			currentUserId: currentUserInfo?.UserId,
			isAsynchClassroom: [TypeOfClassroom.All, TypeOfClassroom.Virtual].includes(
				this.props.typeOfClassroomToCreate
			)
				? false
				: true
		});
		this.loadTags();
	}

	loadTags = () => {
		return new TagService().getAllFor().then((result: any) => {
			return this.setState({
				tags: result
			});
		});
	};

	toggleModal = () => {
		this.setState({
			modalIsVisible: !this.state.modalIsVisible,
			formInitialValues: {
				code: ""
			}
		});
	};

	toggleDrawer = () => {
		this.setState({
			drawerIsVisible: !this.state.drawerIsVisible
		});
	};

	onLevelChange = (formik: FormikProps<any>, level: any) => {
		formik.setFieldValue("level", level);
		formik.setFieldValue("gradeId", undefined);
		formik.setFieldValue("subjectId", undefined);
	};

	onGradeChange = (formik: FormikProps<any>, grade: any) => {
		formik.setFieldValue("gradeId", grade);
		formik.setFieldValue("subjectId", undefined);
	};

	get joinClassModal(): ReactNode {
		const { t: translate } = this.props;

		const modalTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._TITLE`);
		const OkBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._OK_BTN_TITLE`);
		const cancelBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._CANCEL_BTN_TITLE`);
		const inputTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INPUT_TITLE`);
		const inputSubTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INPUT_SUBTITLE`);
		const inputPlaceHolder = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INPUT_PLACEHOLDER`);

		return (
			<Modal
				title={modalTitle}
				visible={this.state.modalIsVisible}
				onOk={() => this.submitHandler()}
				onCancel={this.toggleModal}
				okText={OkBtnTitle}
				cancelText={cancelBtnTitle}
				okButtonProps={{ disabled: this.state.submitting, loading: this.state.submitting }}
				cancelButtonProps={{ disabled: this.state.submitting }}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorJoinClass(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.joinClass}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						this.submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row gutter={[0, 0]}>
									<Input
										name="code"
										label={inputTitle}
										style={drawerInputWidth}
										placeholder={inputPlaceHolder}
										autoFocus={true}
									/>
								</Row>
								<Row>{inputSubTitle}</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	}

	get joinOrgModal(): ReactNode {
		const { t: translate } = this.props;

		const modalTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._TITLE`);
		const OkBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._OK_BTN_TITLE`);
		const cancelBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._CANCEL_BTN_TITLE`);
		const inputTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._INPUT_TITLE`);
		const inputSubTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._INPUT_SUBTITLE`);
		const inputPlaceHolder = translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._INPUT_PLACEHOLDER`);

		return (
			<Modal
				title={modalTitle}
				visible={this.state.modalIsVisible}
				onOk={() => this.submitHandler()}
				onCancel={this.toggleModal}
				okText={OkBtnTitle}
				cancelText={cancelBtnTitle}
				okButtonProps={{ disabled: this.state.submitting, loading: this.state.submitting }}
				cancelButtonProps={{ disabled: this.state.submitting }}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorJoinClass(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.joinOrganization}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						this.submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row gutter={[0, 0]}>
									<Input
										name="code"
										label={inputTitle}
										style={drawerInputWidth}
										placeholder={inputPlaceHolder}
										autoFocus={true}
									/>
								</Row>
								<Row>{inputSubTitle}</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	}

	onUploadingDone = (fileUploaded: any) => {
		this.setState({ fileUploaded: fileUploaded });
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file]
		});
	};

	renderTags = () => {
		return this.state.tags
			.filter((x: any) => {
				return !x.ParentId;
			})
			.map((tag: any, index: number) => {
				return (
					<Row key={index}>
						<Col span={24}>
							<Select
								name={tag.id}
								label={tag.name}
								labelInfo="(Jo e detyrueshme)"
								listHeight={1000}
								showSearch
								filterOption={(inputValue: string, option?: any): boolean => {
									return option.children.toLowerCase().includes(inputValue.toLowerCase());
								}}
							>
								{this.state.tags
									.filter((item: any) => {
										return item.ParentId === tag.id;
									})
									.map((childTag: any) => (
										<Option key={childTag.id} value={childTag.id}>
											{childTag.name}
										</Option>
									))}
							</Select>
						</Col>
					</Row>
				);
			});
	};

	get createClassDrawer(): ReactNode {
		const { t: translate } = this.props;

		let title = translate(`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._VC_TITLE`);
		if (this.state.isAsynchClassroom) {
			title = translate(`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._AC_TITLE`);
		}

		const Form = () => {
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorCreateClass(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.createClass}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						this.submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Collapse
									bordered={false}
									defaultActiveKey="details"
									expandIcon={({ isActive }) => (
										<div>
											<Icon
												type={isActive ? "ri-arrow-down-s-fill" : "ri-arrow-right-s-fill"}
												fontSize="24"
											/>
										</div>
									)}
								>
									<Collapse.Panel
										key="details"
										header={
											<Text
												fontSize="16"
												lineHeight="24"
												wheight="semibold"
												className="color-gray-9"
											>
												Detaje
											</Text>
										}
									>
										<Row>
											<Col span={24}>
												<Text fontSize="14" lineHeight="22" className="color-gray-8">
													{translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._FOTO`
													)}
												</Text>
											</Col>
										</Row>
										<Row className="mb-24">
											<Col span={24}>
												<Uploader
													fileType="IMAGE"
													filePath={`classroom/${this.state.formInitialValues?.id}`}
													fileWithUniqueId={true}
													onUploadingDone={this.onUploadingDone}
													defaultFileList={this.state.formInitialValues.defaultFileList}
													onSoftDeleteFile={this.onSoftDeleteFile}
												/>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Input
													name="name"
													label={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS.${
															this.state.isAsynchClassroom
																? "_ASYNCH_NAME"
																: "_VIRTUAL_NAME"
														}`
													)}
													placeholder={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._PLACEHOLDER_FOR.${
															this.state.isAsynchClassroom
																? "_ASYNCH_NAME"
																: "_VIRTUAL_NAME"
														}`
													)}
													value={formik.values.name}
												/>
											</Col>
										</Row>
										{OAuth2Service.CurrentOrganization?.sector ===
											OrganizationSectorType.Public && (
												<Row>
													<Col span={24}>
														<HtmlInput
															name="description"
															label={translate(
																`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._DESCRIPTION`
															)}
															placeholder={translate(
																`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._PUBLIC_SECTOR_DESCRIPTION_PLACEHOLDER`
															)}
															basicToolbar
															labelInfo={classroomDescriptionPopover(translate)}
														/>
													</Col>
												</Row>
											)}
										{this.state.isAsynchClassroom &&
											OAuth2Service.CurrentOrganization?.sector ===
												OrganizationSectorType.Private && (
											<Row>
												<Col span={24}>
													<FormikTextAreaField
														name="description"
														label={translate(
															`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._DESCRIPTION`
														)}
													/>
												</Col>
											</Row>
										)}

										{!this.state.isAsynchClassroom &&
											OAuth2Service.CurrentOrganization?.sector ===
												OrganizationSectorType.Public && (
											<>
												<Row>
													<Col span={24}>
														<Radio
															name="virtualClassroomType"
															value={formik.values.virtualClassroomType}
															defaultValue={formik.values.virtualClassroomType}
															label="Lloji kursit"
															labelInfo={classroomTypePopover(translate)}
															onRadioChange={(e: any) => {
																if (
																	e.target.value ===
																		TypeOfClassroomBasedOnEnrollments.Individual
																) {
																	formik.setFieldValue("numberOfStudents", null);
																}
															}}
														>
															<RadioElement
																withOutStyle={true}
																value={TypeOfClassroomBasedOnEnrollments.Individual}
															>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-8"
																>
																		Individual
																</Text>
															</RadioElement>
															<RadioElement
																withOutStyle={true}
																value={TypeOfClassroomBasedOnEnrollments.Group}
															>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-8"
																>
																		Grupe
																</Text>
															</RadioElement>
														</Radio>
													</Col>
												</Row>
												{formik.values.virtualClassroomType ===
														TypeOfClassroomBasedOnEnrollments.Group && (
													<Row>
														<Col span={24}>
															<Input
																name="numberOfStudents"
																placeholder="Numri maksimal i nxënësve"
															/>
														</Col>
													</Row>
												)}

												<Row>
													<Col span={24}>
														<FormikDateRangePickerField
															name="interval"
															label="Vlefshmëria"
															labelInfo={classroomIntervalPopover(translate)}
															placeholder={["Datë fillimi", "Datë përfundimi"]}
														/>
													</Col>
												</Row>
											</>
										)}

										{OAuth2Service.CurrentOrganization?.sector ===
											OrganizationSectorType.Public && (
												<Row>
													<Col span={24}>
														<Input
															name="price"
															label="Cmimi"
															value={formik.values.price}
															addonAfter={<>&euro;</>}
														/>
													</Col>
												</Row>
											)}
									</Collapse.Panel>
								</Collapse>
								<Collapse
									bordered={false}
									defaultActiveKey="category"
									expandIcon={({ isActive }) => (
										<div>
											<Icon
												type={isActive ? "ri-arrow-down-s-fill" : "ri-arrow-right-s-fill"}
												fontSize="24"
											/>
										</div>
									)}
								>
									<Collapse.Panel
										key="category"
										header={
											<Text
												fontSize="16"
												lineHeight="24"
												wheight="semibold"
												className="color-gray-9"
											>
												Kategoria
											</Text>
										}
									>
										<Row>
											<Col span={24}>
												<Select
													name="level"
													label={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._LEVEL`
													)}
													placeholder={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._PLACEHOLDER_FOR._LEVEL`
													)}
													size="middle"
													style={drawerInputWidth}
													onChange={value => this.onLevelChange(formik, value)}
													defaultValue={this.state.formInitialValues.level}
													value={formik.values.level}
												>
													{this.state.allLevels?.map((item: string) => (
														<Option key={item} value={item}>
															{translate(`_ADMIN._LEVELS._${item}`)}
														</Option>
													))}
												</Select>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Select
													name="gradeId"
													label={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._GRADE`
													)}
													placeholder={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._PLACEHOLDER_FOR._GRADE`
													)}
													size="middle"
													style={drawerInputWidth}
													onChange={value => this.onGradeChange(formik, value)}
													defaultValue={this.state.formInitialValues.gradeName}
													value={formik.values.gradeId}
												>
													{formik.values.level &&
														this.state.allGrades[formik.values.level]?.map((item: any) => {
															return (
																<Option key={item.id} value={item.gradeId}>
																	{item.name}
																</Option>
															);
														})}
												</Select>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Select
													name="subjectId"
													label={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._SUBJECT`
													)}
													placeholder={translate(
														`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._PLACEHOLDER_FOR._SUBJECT`
													)}
													size="middle"
													style={drawerInputWidth}
													defaultValue={this.state.formInitialValues.subjectName}
													value={formik.values.subjectId}
													showSearch
													filterOption={(inputValue: string, option?: any): boolean => {
														return option.children
															.toLowerCase()
															.includes(inputValue.toLowerCase());
													}}
												>
													{formik.values.level &&
														formik.values.gradeId &&
														this.state.allGrades[formik.values.level]
															?.filter((x: any) => x.id === formik.values.gradeId)
															.map((x: any) =>
																x.GradeSubjects?.map((x: any) => (
																	<Option key={x.SubjectId} value={x.Subject.id}>
																		{x.Subject.name}
																	</Option>
																))
															)}
												</Select>
											</Col>
										</Row>
									</Collapse.Panel>
								</Collapse>
								<Collapse
									bordered={false}
									defaultActiveKey="tags"
									expandIcon={({ isActive }) => (
										<div>
											<Icon
												type={isActive ? "ri-arrow-down-s-fill" : "ri-arrow-right-s-fill"}
												fontSize="24"
											/>
										</div>
									)}
								>
									<Collapse.Panel
										key="tags"
										header={
											<Space direction="horizontal" size={4}>
												<Text
													fontSize="16"
													lineHeight="24"
													wheight="semibold"
													className="color-gray-9"
												>
													Grupimet
												</Text>
												{OAuth2Service.CurrentOrganization?.sector ===
													OrganizationSectorType.Public &&
													classroomTagsTitlePopover(translate)}
											</Space>
										}
									>
										{this.state.tags && <>{this.renderTags()}</>}
									</Collapse.Panel>
								</Collapse>
							</form>
						);
					}}
				</Formik>
			);
		};

		return (
			<CreateEditDrawer
				title={title}
				onClose={this.toggleDrawer}
				onSave={() => this.submitHandler()}
				visible={this.state.drawerIsVisible}
				form={Form}
				width={window.innerWidth >= 576 ? 350 : window.innerWidth}
				submitting={this.state.submitting}
				className="create__classroom_drawer"
			/>
		);
	}

	joinClass = async (item?: any) => {
		const { t: translate } = this.props;

		if (!this.state.modalIsVisible) return this.toggleModal();
		let currentOrg: any = null;
		let enrollmentToClass: any = null;

		await new ClassroomService().getClassroomByCode(item?.code).then((classroom: any) => {
			if (!classroom) {
				return this.setState({
					classroomDetails: undefined,
					classroomOrgCode: undefined,
					classroomOrgId: undefined
				});
			}
			return this.setState({
				classroomDetails: classroom,
				classroomOrgCode: classroom?.Organization?.code,
				classroomOrgId: classroom?.Organization?.id
			});
		});

		if (this.state.classroomDetails?.status === "ARCHIVED") {
			showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INVALID_CODE_ARCHIVED_CLASS`));
		}
		else if (this.state.classroomDetails) {
			const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
			await new OrganizationService().getMyOrganizations().then(organizations => {
				currentOrg = organizations.find(
					(item: any) => item.id === currentUserInfo?.Organizations[0]?.OrganizationId
				);
				return this.setState({
					myOrganizations: organizations
				});
			});

			if (!currentOrg || currentOrg.id === this.state.classroomOrgId) {
				const enrollment: EnrollmentDto = new EnrollmentDto({
					UserId: this.state.currentUserId,
					ClassroomId: this.state.classroomDetails.id,
					status: "ACTIVE",
					enrolledAt: new Date()
				});
				return new EnrollmentService()
					.createEnrollment(enrollment)
					.then((enrollment: any) => {
						if (!enrollment)
							return showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INVALID_CODE`));
						enrollmentToClass = { status: enrollment?.status, ClassroomId: enrollment?.ClassroomId };
						return;
					})
					.then(() => {
						if (!currentOrg && this.state.classroomOrgCode)
							return new OrganizationService().joinOrganization(this.state.classroomOrgCode);
						return currentOrg;
					})
					.then(() => {
						if (enrollmentToClass.status === "ACTIVE")
							return this.props.history.push(`/${COURSE_PATH}/_student/${enrollmentToClass.ClassroomId}`);
						if (enrollmentToClass.status === "PENDING")
							return showMessage(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._REQUEST_PENDING`));
						if (enrollmentToClass.status === "BLOCKED")
							return showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._BLOCKED_RETRY`));
						return;
					})
					.catch(() => {
						return showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INVALID_CODE`));
					})
					.finally(() => this.toggleModal());
			} else if (currentOrg && currentOrg.id !== this.state.classroomOrgId) {
				showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._CLASSROOM_PART_OF_ANOTHER_ORGANIZATION`));
			}
		} else {
			showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN._INVALID_CODE`));
		}
	};

	joinOrganization = (item?: any) => {
		const { t: translate } = this.props;

		if (!this.state.modalIsVisible) return this.toggleModal();
		this.setState({ submitting: true });
		return new OrganizationService()
			.findOrganizationByCode(item.code)
			.then((organization: any) => {
				return new OrganizationService()
					.joinOrganization(organization.code);

			}).then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._DONE`));
				this.setState({ teacherHasJoinedOrg: true });
				return window.location.reload();
			})
			.catch((error) => {
				showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_JOIN_ORG._INVALID_CODE`));
			})
			.finally(() => {
				this.setState({ submitting: false });
				this.toggleModal();
			});
	};

	createClass = async (item?: any) => {
		if (!this.state.drawerIsVisible) {
			return new GradeService().getAllGrades().then((result: any) => {
				const item = new CreateClassroomDto({
					name: "",
					description: "",
					GradeSubjectId: "",
					GradeSubject: {
						Grade: {},
						Subject: {}
					},
					isAsync: this.state.isAsynchClassroom,
					price: 0
				});
				this.setState(
					{
						formInitialValues: {
							id: item.id,
							name: item.name,
							level: item.GradeSubject?.Grade.level,
							gradeId: item.GradeSubject?.Grade.id,
							gradeName: item.GradeSubject?.Grade.name,
							subjectId: item.GradeSubject?.Subject.id,
							subjectName: item.GradeSubject?.Subject.name,
							price: item.price,
							interval: null,
							numberOfStudents: undefined,
							virtualClassroomType:
								!item.isAsync &&
								OAuth2Service.CurrentOrganization?.sector === OrganizationSectorType.Public
									? TypeOfClassroomBasedOnEnrollments.Individual
									: undefined
						},
						allLevels: Object.values(GradeLevel),
						allGrades: _.groupBy(result, "level")
					},
					() => {
						this.toggleDrawer();
					}
				);
				return;
			});
		}

		const tags: any = [];
		this.state.tags
			.filter((x: any) => {
				return x.ParentId;
			})
			.forEach((childTag: any) => {
				if (item[childTag.ParentId]) {
					tags.push(item[childTag.ParentId]);
				}
			});

		const file = this.state.fileUploaded;

		if (file !== undefined) {
			file.updatedBy = this.state.currentUserId;
			file.createdBy = this.state.currentUserId;
		}
		let classroomCode = null;
		if (
			!this.state.isAsynchClassroom &&
			OAuth2Service.CurrentOrganization?.sector === OrganizationSectorType.Private
		) {
			classroomCode = getUuidCode();
		}
		let virtualClassroomType = null;
		if (
			!this.state.isAsynchClassroom &&
			OAuth2Service.CurrentOrganization?.sector === OrganizationSectorType.Public
		) {
			virtualClassroomType = item.virtualClassroomType;
		}
		const obj: any = {
			id: item.id,
			name: item.name,
			description: item.description,
			startDate: item.interval ? moment(item.interval[0]).toDate() : null,
			endDate: item.interval ? moment(item.interval[1]).toDate() : null,
			isAsync: this.state.isAsynchClassroom,
			GradeSubject: {
				GradeId: item.gradeId,
				SubjectId: item.subjectId
			},
			File: file,
			FileId:
				file !== null
					? file?.id
					: this.state.formInitialValues.FileId
						? this.state.formInitialValues.FileId
						: null,
			code: classroomCode,
			price: Number(item.price),
			maxEnrollmentsAllowed: item.numberOfStudents ? Number(item.numberOfStudents) : null,
			virtualClassroomType: virtualClassroomType,
			tags: _.uniq(tags)
		};

		this.setState({
			submitting: true
		});

		return new ClassroomService()
			.createClassroom(obj)
			.then((result: any) => {
				let courseBuilderPath = "";
				if (!obj.isAsync && obj.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Individual) {
					courseBuilderPath = `/${COURSE_BUILDER_PATH}/individual/`;
				} else {
					courseBuilderPath = `/${COURSE_BUILDER_PATH}/${
						this.state.isAsynchClassroom ? "asynch" : "_teacher"
					}/`;
				}

				return this.props.history.push(courseBuilderPath + result.data.id, {
					justCreated: true
				});
			})
			.finally(() => {
				this.toggleDrawer();
				this.setState({
					submitting: false,
					formInitialValues: {}
				});
			});
	};

	render() {
		const { t: translate } = this.props;
		const isTeacher =
			!OAuth2Service.isOwnerOrAdminAtDefaultOrganization && (OAuth2Service.isTeacher || OAuth2Service.isOwnerOrAdmin);
		const isStudent = OAuth2Service.isStudent;
		const teacherHasJoinedOrg =
			(!OAuth2Service.isOwnerOrAdminAtDefaultOrganization && OAuth2Service.isOwnerOrAdmin) ||
			(OAuth2Service.isTeacher &&
				OAuth2Service.CurrentUser.Organizations.some((x: any) => x.ParentOrganizationId));

		let form = null;
		let buttonAction: any = null;
		let buttonName = null;

		if (isStudent) {
			form = this.joinClassModal;
			buttonAction = this.joinClass;
			buttonName = "_BTN_JOIN";
		} else if (isTeacher) {
			if (teacherHasJoinedOrg || !OAuth2Service.isTeacherAtSuperOrganization) {
				form = this.createClassDrawer;
				buttonAction = this.createClass;
				buttonName = "_BTN_CREATE";
			} else {
				form = this.joinOrgModal;
				buttonAction = this.joinOrganization;
				buttonName = "_BTN_JOIN_ORG";
			}
		}

		let joinCreateButtonContent = null;
		if (
			form &&
			buttonAction &&
			buttonName &&
			((isTeacher && !teacherHasJoinedOrg) ||
				isStudent ||
				(isTeacher && teacherHasJoinedOrg && this.props.typeOfClassroomToCreate !== TypeOfClassroom.All))
		) {
			joinCreateButtonContent = (
				<>
					<Button
						onClick={buttonAction}
						type="primary"
						size="middle"
						icon="ri-add-line"
						className="full__width"
					>
						{!this.props.collapsed && translate(`_MAIN._CLASSES.${buttonName}`)}
					</Button>
					{form}
				</>
			);
		} else if (isTeacher && teacherHasJoinedOrg && this.props.typeOfClassroomToCreate === TypeOfClassroom.All) {
			const menu = (
				<Menu
					onClick={(e: any) => {
						this.setState({
							isAsynchClassroom: e.key === TypeOfClassroom.Asynch
						});
						buttonAction();
					}}
				>
					<Menu.Item key={TypeOfClassroom.Virtual}>
						<Space size={8} align="center">
							<Icon type="ri-door-open-line" />
							{translate("_MAIN._CLASSES._ADD_VIRTUAL_COURSE_OPTION")}
						</Space>
					</Menu.Item>
					<Menu.Item key={TypeOfClassroom.Asynch}>
						<Space size={8} align="center">
							<Icon type="ri-slideshow-3-line" />
							{translate("_MAIN._CLASSES._ADD_ASYNCH_COURSE_OPTION")}
						</Space>
					</Menu.Item>
				</Menu>
			);
			joinCreateButtonContent = (
				<>
					<Dropdown overlay={menu} trigger={["click"]}>
						<Button
							type="primary"
							size="middle"
							icon="ri-add-line"
							className="full__width create__classroom_by_type_button"
						>
							{translate(`_MAIN._CLASSES.${buttonName}`)}
						</Button>
					</Dropdown>
					{form}
				</>
			);
		}

		return joinCreateButtonContent;
	}
}

const JoinCreateClassModal = withTranslation()(JoinCreateClassModalComponent);
export default JoinCreateClassModal;
