import React, { ReactNode } from "react";
import { Affix, Col, Layout, Row } from "antd";
import { MainHeader as Header } from "common/components/layout/header";
import { MainDashboardMenuItems } from "../../../main/components/layout/Layout";
import { matchPath } from "react-router";
import { SUBJECTS_PATH } from "../../index";
import { useLocation } from "react-router-dom";

interface LayoutProps {
	children: ReactNode;
}

export const MainLayout = (props: LayoutProps) => {
	const subjectsPath = `/${SUBJECTS_PATH}`;
	const location = useLocation();
	const matchesPath = matchPath(location.pathname, { path: subjectsPath, exact: true });

	return (
		<Layout>
			{!matchesPath && <Header menu={MainDashboardMenuItems} showSwitchRole={true} showMarketplace />}
			{matchesPath && <Row>
				<Col xs={0} lg={24}><Header menu={MainDashboardMenuItems} showSwitchRole={true} showMarketplace /></Col>
				<Col xs={24} lg={0}><Affix><Header menu={MainDashboardMenuItems} showSwitchRole={true} showMarketplace /></Affix></Col>
			</Row>}
			{props.children}
		</Layout>
	);
};
