import React from "react";
import { Switch, Route } from "react-router-dom";

import Subjects from "./scenes/subjectItems/index";
import MaterialItems, { MATERIALS_PATH } from "./scenes/materialItems/index";
import { MainLayout } from "./components/layout/Layout";
import AuthorizedGuardedRoute, { TypeOfOrganization } from "App.AuthorizedRoute";
import { Role } from "../../services/domain/login/Role";

export const SUBJECTS_PATH = "subjects";

const accessControlList = [
	{
		role: Role.Owner,
		type: TypeOfOrganization.Child
	},
	{
		role: Role.Admin,
		type: TypeOfOrganization.Child
	},
	{
		role: Role.Teacher,
		type: TypeOfOrganization.Main
	},
	{
		role: Role.Teacher,
		type: TypeOfOrganization.Child
	},
	{
		role: Role.Student,
		type: TypeOfOrganization.Main
	},
	{
		role: Role.Student,
		type: TypeOfOrganization.Child
	}
];

const Main = () => {
	return (
		<MainLayout>
			<Switch>
				<AuthorizedGuardedRoute
					allowed={accessControlList}
					path={`/${SUBJECTS_PATH}/${MATERIALS_PATH}/*`}
					component={MaterialItems}
				/>
				<Route path={`/${SUBJECTS_PATH}`} exact component={Subjects} />
				<Route path={`/${SUBJECTS_PATH}/*`} exact component={Subjects} />
			</Switch>
		</MainLayout>
	);
};

export default Main;
