import React from "react";
import { Row, Col, Card, Space, message } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { withTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import defaultImg from "assets/images/main-classes-img2.svg";
import Icon from "common/components/general/Icon";
import OAuth2Service from "services/authentication/oauth2.service";
import { SIGN_IN_PATH } from "../../../scenes/login/scenes/signIn";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { OrganizationSectorType } from "services/domain/administration/Organization";

const TRANSLATION_BASE_PATH = "_COMPONENTS._SECTION_HEADER";

class SectionHeaderComponent extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			courseName: this.props.classroom.name,
			userId: ""
		};
	}

	async componentDidMount() {
		const { history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		this.setState({ userId: currentUserInfo.UserId });
	}

	onCopy = (textCopied: any) => {
		const { t: translate } = this.props;
		message.success(
			translate(`${TRANSLATION_BASE_PATH}._COPY_CLIPBOARD`, {
				code: `${textCopied}`
			})
		);
	};

	render() {
		const teacherName =
			this.props.classroom.UserCreatedBy.firstName + " " + this.props.classroom.UserCreatedBy.lastName;
		const gutter = this.props.editMode ? 40 : 60;
		const { t: translate } = this.props;
		const countActivePupils = this.props.pupils
			? this.props.pupils.filter((x: any) => x.status === "ACTIVE").length
			: 0;

		return (
			<Card className="course__builder__sectionHeader__card ml-8 mr-8">
				<Row className="course__builder__sectionHeader__card_row_1">
					<Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
						<Row gutter={[0, gutter]}>
							<Col>
								<Row>
									<Text fontSize="30" lineHeight="38" className="color-blue-1" maxLength={25}>
										{this.state.courseName}
									</Text>
								</Row>
								<Row>
									<Text fontSize="20" lineHeight="28" className="color-blue-2">
										{teacherName}
									</Text>
								</Row>
								<Row>
									<Text fontSize="14" lineHeight="22" className="color-blue-2">
										{countActivePupils === 0
											? "Nuk ka nxënës të regjistruar..."
											: countActivePupils + " nxënës"}
									</Text>
								</Row>
							</Col>
						</Row>
						{this.props.classroom.Organization?.sector === OrganizationSectorType.Private ? (
							<Row>
								<Col>
									<Row>
										<Text fontSize="16" lineHeight="24" className="color-gray-1">
											<Space>
												{this.props.classroom.code}
												<CopyToClipboard
													text={this.props.classroom.code}
													onCopy={(textCopied: any) => this.onCopy(textCopied)}
												>
													<a>
														<Text fontSize="20" lineHeight="24">
															<Icon type="ri-file-copy-line"></Icon>
														</Text>
													</a>
												</CopyToClipboard>
											</Space>
										</Text>
									</Row>
									<Row>
										<Text fontSize="16" lineHeight="24" className="color-blue-2">
											Kodi i klasës
										</Text>
									</Row>
								</Col>
							</Row>
						) : (
							<Row className="pt-48">
								<Col></Col>
							</Row>
						)}
					</Col>
					<Col xs={0} sm={0} md={10} lg={10} xl={10} xxl={10}>
						<Row justify="end">
							<Image className="main__sectionHeader__img" src={this.props.backgroundImg ?? defaultImg} />
						</Row>
					</Col>
				</Row>
				<Row className="course__builder__sectionHeader__card_row_2">
					<Col span={24}>
						<Space direction="horizontal" className="pr-40">
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._SCHOOL`)}
							</Text>
							<Text fontSize="16" lineHeight="24" className="color-gray-8">
								{this.props.organization?.name}
							</Text>
						</Space>
						<Space direction="horizontal" className="pr-40">
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._CLASS`)}
							</Text>
							<Text fontSize="16" lineHeight="24" className="color-gray-8">
								{this.props.classroom?.GradeSubject?.Grade?.name}
							</Text>
						</Space>
						<Space direction="horizontal">
							<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._SUBJECT`)}
							</Text>
							<Text fontSize="16" lineHeight="24" className="color-gray-8">
								{this.props.classroom?.GradeSubject?.Subject?.name}
							</Text>
						</Space>
					</Col>
				</Row>
			</Card>
		);
	}
}

const SectionHeader = withTranslation()(SectionHeaderComponent);
export default SectionHeader;
