import React, { ReactNode, Component } from "react";
import { Skeleton, Space, message } from "antd";
import { withTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import { getNavItems, OnboardingState, WizardStep, StepStatus } from "./OnboardingStepsMapping";
import { defaultTeacherWizardSteps, defaultStudentWizardSteps } from "./WizardStepsConfig";
import { HeaderNavigationStatus } from "../../components/headerNavigationStatus";
import { ON_BOARDING_PATH } from "../..";
import { REGISTER_EMAIL_VERIFICATION_PATH } from "../registerEmailVerification";
import { MAIN_PATH } from "scenes/main";
import WizardDto from "dtos/administration/onboarding/wizard.dto";
import OnboardingService from "services/administration/onboarding.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SIGN_IN_PATH } from "../../../login/scenes/signIn";
import { Role } from "services/domain/login/Role";
import { ORGANIZATION_PATH } from "scenes/main/scenes/organization";
import { ChooseLevel } from './chooseLevel';

enum WizardButton {
	Next = "next",
	Finish = "finish",
	Back = "back",
	Skip = "skip"
}

declare type NextButtonType = WizardButton.Next | WizardButton.Finish;
const DEFAULT_NAVIGATION_ITEM_INDEX = 0;
const TRANSLATION_BASE_PATH = "_ONBOARDING";

class OnboardingWizardComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			currentNavigationItem: undefined,
			onboardingState: undefined,
			isFormValid: false,
			isSubmiting: false,
			editingNavigationItem: undefined,
			stepTitle: ""
		};
	}

	submitFormHandler: any;

	getIndexOfActiveNavigationItem = (items: WizardStep[]): number => {
		try {
			if (this.state.editingNavigationItem) {
				const navItemIndex = items.findIndex(item => item.id === this.state.editingNavigationItem);
				if (navItemIndex === -1) throw new Error("Navigation Item not found");
				const navItem = items[navItemIndex];
				if (navItem.status === StepStatus.Active || navItem.status === StepStatus.Complete) {
					return navItemIndex;
				}

				this.setState({
					editingNavigationItem: undefined
				});
			}

			const activeNavigationItemIndex = items.findIndex(item => item.status === StepStatus.Active);

			if (activeNavigationItemIndex === -1) return DEFAULT_NAVIGATION_ITEM_INDEX;
			return activeNavigationItemIndex;
		} catch (e) {
			message.error("Something went wrong!");
			return DEFAULT_NAVIGATION_ITEM_INDEX;
		}
	};

	loadActiveNavigationItem = (items: WizardStep[]) => {
		const index = this.getIndexOfActiveNavigationItem(items);
		const navigationItem = items[index];
		const navigationItemProps = getNavItems().find(navItem => navItem.id === navigationItem.id);
		this.setState({
			currentNavigationItem: navigationItemProps
		});
	};

	redirectToNavigationItem = (id: string) => {
		try {
			const navigationItemProps = getNavItems().filter(item => item.show).find(navItem => navItem.id === id);
			if (navigationItemProps === undefined) throw new Error("Navigation Item not found");
			this.setState({
				currentNavigationItem: navigationItemProps,
				editingNavigationItem: id
			});
		} catch (e) {
			message.error("Something went wrong!");
		}
	};

	goBack = () => {
		try {
			const currentNavigationItemIndex = this.getIndexOfActiveNavigationItem(
				this.state.onboardingState.navigationItems
			);
			const item = this.state.onboardingState.navigationItems[currentNavigationItemIndex];
			if (item === undefined) throw new Error("Navigation Item not found");

			if (currentNavigationItemIndex > 0) {
				const nextItem = this.state.onboardingState.navigationItems[currentNavigationItemIndex - 1];
				this.redirectToNavigationItem(nextItem.id);
			} else {
				this.redirectToNavigationItem(
					this.state.onboardingState.navigationItems[DEFAULT_NAVIGATION_ITEM_INDEX].id
				);
			}
		} catch (e) {
			message.error("Something went wrong!");
			this.redirectToNavigationItem(this.state.onboardingState.navigationItems[DEFAULT_NAVIGATION_ITEM_INDEX].id);
		}
	};

	goNext = () => {
		try {
			const currentNavigationItemIndex = this.getIndexOfActiveNavigationItem(
				this.state.onboardingState.navigationItems
			);
			const navigationItems = this.state.onboardingState.navigationItems;
			const item = navigationItems[currentNavigationItemIndex];
			if (item === undefined) throw new Error("Navigation Item not found");
			item.status = StepStatus.Complete;
			if (currentNavigationItemIndex < navigationItems.length - 1) {
				const nextItem = navigationItems[currentNavigationItemIndex + 1];
				nextItem.status = StepStatus.Active;
				this.redirectToNavigationItem(nextItem.id);
			} else {
				this.redirectToNavigationItem(navigationItems[DEFAULT_NAVIGATION_ITEM_INDEX].id);
			}
			const oldState = this.state.onboardingState;
			this.setState({
				onboardingState: {
					...oldState,
					navigationItems: [...navigationItems]
				}
			});
		} catch (e) {
			message.error("Something went wrong!");
			this.redirectToNavigationItem(this.state.onboardingState.navigationItems[DEFAULT_NAVIGATION_ITEM_INDEX].id);
		}
	};

	createNewWizard = async (user: any, navItemsFiltered: any[]) => {
		let wizardSteps: WizardStep[];
		if (user.Roles.some((role: any) => role.code === Role.Teacher)) {
			wizardSteps = defaultTeacherWizardSteps;
		} else {
			wizardSteps = defaultStudentWizardSteps;
		}

		const stepsFilterd = wizardSteps.filter((wizard: any) =>
			navItemsFiltered.some((navItem: any) => navItem.id === wizard.id));
		
		
		const state = {
			navigationItems: stepsFilterd,
			role: user.Roles[0].code
		};
		this.setState({
			onboardingState: state
		});
		this.loadActiveNavigationItem(stepsFilterd);
		return new OnboardingService()
			.createWizard(
				new WizardDto({
					State: JSON.stringify(state),
					WizardType: "ONBOARDING"
				})
			)
			.then((data: any) => {
				const state = JSON.parse(data.State);
				this.setState({
					onboardingState: { ...state, id: data.id }
				});
				return this.loadActiveNavigationItem(state.navigationItems);
			})
			.catch(error => {
				console.log(error);
				message.error("Something went wrong!");
			});
	};

	updateDraftStateWithTheLatestConfigurations(wizard: WizardDto, navItemsFiltered: any[]) {
		const draftState = JSON.parse(wizard.State);
		const draftNavigationItems: WizardStep[] = draftState.navigationItems;

		let wizardSteps: WizardStep[];
		if (draftState.role === Role.Teacher) {
			wizardSteps = defaultTeacherWizardSteps;
		} else {
			wizardSteps = defaultStudentWizardSteps;
		}

		const stepsFilterd = wizardSteps.filter((wizard: any) =>
			navItemsFiltered.some((navItem: any) => navItem.id === wizard.id));

		for (let i = 0; i < stepsFilterd.length; i++) {
			if (i >= draftNavigationItems.length) {
				draftNavigationItems[i] = {
					...stepsFilterd[i],
					status: StepStatus.Complete
				};
			} else if (stepsFilterd[i].id !== draftNavigationItems[i].id) {
				for (let j = draftNavigationItems.length; j > i; j--) {
					const tempWizardSep = { ...draftNavigationItems[j - 1] };
					draftNavigationItems[j] = { ...tempWizardSep, priority: tempWizardSep.priority + 1 };
				}
				draftNavigationItems[i] = {
					...stepsFilterd[i],
					status: StepStatus.Complete
				};
			}
		}

		// In case there are removed steps from the initial configuration
		if (draftNavigationItems.length > stepsFilterd.length) {
			for (let i = draftNavigationItems.length - 1; i >= stepsFilterd.length; i--) {
				draftNavigationItems.splice(i, 1);
			}
		}

		draftState.navigationItems = [...draftNavigationItems];
		return draftState;
	}

	async componentDidMount() {
		const { history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		if (currentUserInfo.User.status === "NEW")
			return history.push(`/${ON_BOARDING_PATH}/${REGISTER_EMAIL_VERIFICATION_PATH}`);
		const onBoarding: any | null = await new OnboardingService().getWizard();
		if (!onBoarding) return await this.createNewWizard(currentUserInfo, getNavItems().filter((item: any) => item.show));
		let state = JSON.parse(onBoarding.State);
		state = this.updateDraftStateWithTheLatestConfigurations(onBoarding, getNavItems().filter((item: any) => item.show));

		const newNavigationItems = state?.navigationItems.filter((navItem: any) =>
			getNavItems().filter((item: any) => item.show).some((item: any) => item.id === navItem.id));
		
		this.setState({
			onboardingState: {
				...state,
				id: onBoarding.id,
				navigationItems: [...newNavigationItems]
			}
		});
		return this.loadActiveNavigationItem(newNavigationItems);
	}

	isNextButtonDisabled = (): boolean => {
		if (!this.state.isFormValid) {
			return true;
		}
		return false;
	};

	backHandler = () => {
		this.goBack();
		this.setState({
			isSubmiting: false
		});
	};

	submitHandler = async () => {
		this.setState({
			isSubmiting: true
		});
		await this.submitFormHandler();
	};

	skipHandler = () => {
		try {
			const currentNavigationItemIndex = this.getIndexOfActiveNavigationItem(
				this.state.onboardingState.navigationItems
			);
			const item = this.state.onboardingState.navigationItems[currentNavigationItemIndex];
			if (item === undefined) throw new Error("Navigation Item not found");

			const navigationItemProps = getNavItems().filter(item => item.show).find(navItem => navItem.id === item.id);
			if (navigationItemProps === undefined) throw new Error("Navigation Item not found");

			const itemsToSkip = navigationItemProps.itemsToSkip;

			if (itemsToSkip === undefined) {
				item.status = StepStatus.Complete;
				if (currentNavigationItemIndex < this.state.onboardingState.navigationItems.length - 1) {
					const nextItem = this.state.onboardingState.navigationItems[currentNavigationItemIndex + 1];
					nextItem.status = StepStatus.Active;
					this.redirectToNavigationItem(nextItem.id);
				} else {
					this.redirectToNavigationItem(
						this.state.onboardingState.navigationItems[DEFAULT_NAVIGATION_ITEM_INDEX].id
					);
				}
			} else {
				for (let i = 0; i <= itemsToSkip; i++) {
					const nextItem = this.state.onboardingState.navigationItems[currentNavigationItemIndex + i];
					nextItem.status = StepStatus.Complete;
				}
				const nextItem = this.state.onboardingState.navigationItems[
					currentNavigationItemIndex + itemsToSkip + 1
				];
				nextItem.status = StepStatus.Active;
				this.redirectToNavigationItem(nextItem.id);
			}
		} catch (e) {
			message.error("Something went wrong!");
			this.redirectToNavigationItem(this.state.onboardingState.navigationItems[DEFAULT_NAVIGATION_ITEM_INDEX].id);
		}
	};

	getButtonBasedOnType = (type: WizardButton) => {
		const { t: translate } = this.props;
		switch (type) {
			case WizardButton.Back:
				return (
					<Button
						size="large"
						icon="ri-arrow-left-line"
						htmlType="button"
						type="default"
						onClick={this.backHandler}
					>
						{translate(`${TRANSLATION_BASE_PATH}._BACK`)}
					</Button>
				);
			case WizardButton.Skip:
				return (
					<Button size="large" htmlType="submit" type="link" onClick={this.skipHandler} className="right">
						{translate(`${TRANSLATION_BASE_PATH}._SKIP`)}
					</Button>
				);
			case WizardButton.Next:
				return (
					<Button
						icon="ri-arrow-right-line"
						iconPosition="right"
						size="large"
						htmlType="submit"
						type="primary"
						disabled={this.isNextButtonDisabled()}
						onClick={this.submitHandler}
						className="right"
						loading={this.state.isSubmiting}
					>
						{translate(`${TRANSLATION_BASE_PATH}._NEXT`)}
					</Button>
				);
			case WizardButton.Finish: {
				return (
					<Button
						icon="ri-arrow-right-line"
						iconPosition="right"
						size="large"
						htmlType="submit"
						type="primary"
						disabled={this.isNextButtonDisabled()}
						onClick={this.submitHandler}
						className="right"
						loading={this.state.isSubmiting}
					>
						{translate(`${TRANSLATION_BASE_PATH}._FINISH`)}
					</Button>
				);
			}
		}
	};

	getBackButton = (navigationItems: WizardStep[]): ReactNode => {
		if (
			navigationItems.length > 0 &&
			this.state.currentNavigationItem &&
			this.state.currentNavigationItem.id !== navigationItems[0].id
		) {
			return this.getButtonBasedOnType(WizardButton.Back);
		}
		return <div></div>;
	};

	getNextButton = (navigationItems: WizardStep[]): ReactNode => {
		if (navigationItems.length > 0 && this.state.currentNavigationItem) {
			let buttonType: NextButtonType = WizardButton.Next;
			if (this.state.currentNavigationItem.id === navigationItems[navigationItems.length - 1].id) {
				buttonType = WizardButton.Finish;
			}

			return this.getButtonBasedOnType(buttonType);
		}
		return null;
	};

	getSkipButton = (navigationItems: WizardStep[]): ReactNode => {
		if (
			navigationItems.length > 0 &&
			this.state.currentNavigationItem &&
			this.state.currentNavigationItem.id !== navigationItems[navigationItems.length - 1].id &&
			this.state.currentNavigationItem.id !== navigationItems[0].id
		) {
			return this.getButtonBasedOnType(WizardButton.Skip);
		}
		return null;
	};

	finish = (stateId: string, organizationCode: string, isOwner: boolean) => {
		const {t: translate, history } = this.props;
		return new OnboardingService().finish({
			wizardId: stateId,
			nationality: this.state.onboardingState?.nationality,
			organisationCode: organizationCode,
			profilePicture: this.state.onboardingState?.profilePicture,
			profilePictureType: this.state.onboardingState?.profilePictureType,
			profilePictureFile: this.state.onboardingState?.profilePictureFile
		}).then(() => {
			const decodedToken = OAuth2Service.CurrentToken;
			return OAuth2Service.refreshToken(decodedToken.refreshToken);
		})
			.then(() => {
				if (isOwner) {
					sessionStorage.setItem("showWelcomePopup", "true");
					return history.push(`/${MAIN_PATH}/${ORGANIZATION_PATH}`);
				}
				return history.push(`/${MAIN_PATH}`);
			})
			.catch(error => {
				const errorStatus = error?.statusCode ?? "500";
				throw {
					error: true,
					errorMessage:
						Number(errorStatus) === Number("404")
							? translate(`${TRANSLATION_BASE_PATH}._WRONG_CODE`)
							: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				};
			})
			.finally(() => {
				this.setState({ isSubmiting: false });
			});;
	};

	skipAll = () => {
		const {t: translate, history } = this.props;
		return history.push(`/${MAIN_PATH}`);
	}

	render() {
		return (
			<Skeleton loading={!this.state.currentNavigationItem || !this.state.onboardingState} active>
				{this.state.currentNavigationItem && this.state.onboardingState ? (
					<>
						<Row className="mt-80">
							<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
							<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
								<Text fontSize="30" lineHeight="38" wheight="semibold">
									{this.state.stepTitle}
								</Text>
							</Col>
						</Row>
						<Row className="mt-16">
							<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
							<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
								<HeaderNavigationStatus
									numberOfItems={this.state.onboardingState.navigationItems.length}
									currentIndex={this.state.onboardingState.navigationItems.findIndex(
										(item: any) => item.id === this.state.currentNavigationItem?.id
									)}
								/>
							</Col>
						</Row>
						<Row className="mt-24">
							<Col span={24}>
								<this.state.currentNavigationItem.component
									name={this.state.currentNavigationItem.id}
									setIsFormValid={(valid: boolean) => {
										this.setState({
											isFormValid: valid
										});
									}}
									setIsSubmiting={(valid: boolean) => {
										this.setState({
											isSubmiting: valid
										});
									}}
									setTitle={(title: string) => {
										this.setState({
											stepTitle: title
										});
									}}
									bindSubmitFormHandler={(submitForm: any) => {
										this.submitFormHandler = submitForm;
									}}
									state={this.state.onboardingState}
									updateState={(state: OnboardingState) => {
										this.setState({
											onboardingState: state
										});
									}}
									goNext={this.goNext}
									goBack={this.goBack}
									finish={this.finish}
									skipAll={this.skipAll}
								/>
							</Col>
						</Row>
						<Row className="mt-40 mb-40">
							<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={20}
								xl={16}
								xxl={16}
								className="flex__center_space_between"
							>
								{this.getBackButton(this.state.onboardingState.navigationItems)}
								<Space align="center">
									{this.getSkipButton(this.state.onboardingState.navigationItems)}
									{this.getNextButton(this.state.onboardingState.navigationItems)}
								</Space>
							</Col>
						</Row>
					</>
				) : null}
			</Skeleton>
		);
	}
}

export const OnboardingWizard = withTranslation()(OnboardingWizardComponent);
