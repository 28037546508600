import React, {useEffect} from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import Input from "common/components/dataEntry/formik/FormikInputField";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import { RegisterFooterActions } from "../../components/footerActions/RegisterFooterActions";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

interface TeacherRegistrationFormProps {
	submitting: boolean;
	recaptchaRef: any;
	parentEmail: string;
	predefinedEmail: string;
}

export const TeacherRegistrationForm = (props: TeacherRegistrationFormProps) => {
	const { isValid, setFieldValue } = useFormikContext<any>();
	const { t: translate } = useTranslation();

	useEffect(() => {
		setFieldValue("email", props.parentEmail ?? props.predefinedEmail);
	}, []);
	
	return (
		<>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={12} md={8} lg={12} xl={9}>
					<Input name="firstName" label={translate(`${TRANSLATION_BASE_PATH}._FIRST_NAME`)} size="large" />
				</Col>
				<Col xs={24} sm={12} md={8} lg={12} xl={9}>
					<Input name="lastName" label={translate(`${TRANSLATION_BASE_PATH}._LAST_NAME`)} size="large" />
				</Col>
			</Row>
			{!props.parentEmail && (
				<Row gutter={24}>
					<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
					<Col xs={24} sm={24} md={16} lg={24} xl={18}>
						<Input name="email" label={translate(`${TRANSLATION_BASE_PATH}._EMAIL`)} size="large"
							disabled={!!props.predefinedEmail}/>
					</Col>
				</Row>
			)}
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<PasswordInput
						name="password"
						label={translate(`${TRANSLATION_BASE_PATH}._CREATE_PASSWORD`)}
						showPopover={true}
						size="large"
						autoComplete="new-password"
					/>
				</Col>
			</Row>
			<RegisterFooterActions
				isFormValid={isValid}
				submitting={props.submitting}
				recaptchaRef={props.recaptchaRef}
			/>
		</>
	);
};
