import ACMService from "services/s3/acm.service";
import ELBV2Service from "services/s3/elbv2.service";
import {
	getEnvironmentFromDomain,
	getOrganizationCertificationConnection,
	getOrganizationCertificationState
} from "services/common/check-domain";

interface CertificateType {
	certificationState: string;
	certificationLinkedState: string;
}

/**
 * Return crt state and crt linked state
 * @param domain
 */
export const loadCertificationDomainState = async (domain: string): Promise<CertificateType> => {
	const [listOfCertificates, linkCertificates] =  await Promise.all([new ACMService().listCertificates(),
		new ELBV2Service().getCertificates(getEnvironmentFromDomain(window.location.host))]);

	const organizationCertState = getOrganizationCertificationState(domain, listOfCertificates);
	const organizationCertLinkedState = getOrganizationCertificationConnection(domain, listOfCertificates, linkCertificates);

	return {certificationState: organizationCertState, certificationLinkedState: organizationCertLinkedState};
};