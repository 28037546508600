import React, { useState } from "react";
import { Modal } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import Button from "common/components/general/Button";
import Tooltip from "common/components/dataDisplay/Tooltip";
import { showMessage, showError } from "common/utils/Notification";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import FormikTextAreaField from "common/components/dataEntry/formik/FormikTextAreaField";
import FormikTimeRangePickerField from "common/components/dataEntry/formik/FormikTimeRangePickerField";
import { formValidator } from "./ValidationSchema";
import MeetingService from "services/zoom/meeting.service";

interface ScheduleMeetingProps {
	classroomId: string;
	visible: boolean;
	initialValues?: any;
	afterSaveHandler: () => void;
	onCloseHandler: () => void;
}

const TRANSLATION_BASE_PATH = "_COURSE._COURSE_DETAILS._LIVE_CLASS";

export const ScheduleMeetingModal = (props: ScheduleMeetingProps) => {
	const { t: translate } = useTranslation();

	const initialValues = props.initialValues ?? {};
	const isEditMode: boolean = props.initialValues !== undefined;
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [isFormValid, setIsFormValid] = useState<boolean>(false);

	let submitFormHandler: () => Promise<any>;

	const createLiveClassButton = (
		<Button type="primary" onClick={() => submitFormHandler()} loading={submitting} disabled={!isFormValid}>
			{translate(`${TRANSLATION_BASE_PATH}.${isEditMode ? "_MODIFY" : "_ADD"}`)}
		</Button>
	);

	const footer = isFormValid ? (
		createLiveClassButton
	) : (
		<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._TOOLTIP_INFO`)} placement="left">
			{createLiveClassButton}
		</Tooltip>
	);

	const modalTitle = translate(`${TRANSLATION_BASE_PATH}.${isEditMode ? "_MODIFY_MEETING" : "_ADD_MEETING"}`);

	const saveMeeting = (values: any) => {
		setSubmitting(true);

		const today = moment();

		const duration = Math.round(moment.duration(values.startTime[1].diff(values.startTime[0])).asMinutes());
		const startTime = moment(
			moment(today).format("YYYY-MM-DD") + " " + values.startTime[0].format("HH:mm"),
			"YYYY-MM-DD HH:mm"
		);

		const meetingDto: any = {
			topic: values.name,
			agenda: values.description ?? "",
			type: 2,
			startTime: startTime,
			duration: duration,
			password: uuidv4().substr(0, 10),
			ClassroomId: props.classroomId
		};

		if (isEditMode) {
			meetingDto.id = `${props.initialValues?.id}`;
		}

		return (isEditMode
			? new MeetingService().updateMeeting(meetingDto)
			: new MeetingService().createMeeting(meetingDto)
		)
			.then((result: any) => {
				const status = (isEditMode ? "_UPDATE" : "") + "_API_STATUS_CODE";
				setIsFormValid(false);
				props.afterSaveHandler();
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._${result.status}${status}`));
			})
			.catch(error => {
				const errorStatus =
					typeof error?.response?.data?.error?.message === "number"
						? error.response.data.error.message
						: error.response.status ?? "500";
				showError(translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`));
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<Modal
			visible={props.visible}
			title={modalTitle}
			footer={footer}
			onCancel={props.onCloseHandler}
			destroyOnClose
		>
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				onSubmit={saveMeeting}
				validationSchema={formValidator(translate)}
				validate={values => {
					setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					submitFormHandler = submitForm;
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<FormikDefaultInputField
								name="name"
								label={translate(`${TRANSLATION_BASE_PATH}._TOPIC_LABEL`)}
								placeholder={translate(`${TRANSLATION_BASE_PATH}._TOPIC_PLACEHOLDER`)}
								size="middle"
							/>
							<FormikTextAreaField
								name="description"
								label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_LABEL`)}
								placeholder={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_PLACEHOLDER`)}
								labelInfo={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_INFO`)}
								rows={4}
							/>
							<FormikTimeRangePickerField
								name="startTime"
								label={translate(`${TRANSLATION_BASE_PATH}._DURATION_LABEL`)}
								defaultValue={initialValues.startTime}
								format={"HH:mm"}
								size="middle"
								placeholder={[
									translate(`${TRANSLATION_BASE_PATH}._DURATION_START_PLACEHOLDER`),
									translate(`${TRANSLATION_BASE_PATH}._DURATION_END_PLACEHOLDER`)
								]}
								direction="ltr"
								order={true}
								disabledHours={() => Array.from(Array(+moment().format("HH")).keys())}
								hideDisabledOptions
								minuteStep={5}
							/>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};
