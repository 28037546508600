import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import TagManager from 'react-gtm-module'

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import store from "store";

import "./assets/styles/index.less";

const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM}`
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div>loading...</div>}>
			<StoreProvider store={store}>
				<App />
			</StoreProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
