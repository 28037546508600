/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

import ApiServiceData from "../api.service";
import ResetPasswordDto from "../../dtos/authentication/user/resetPassword.dto";
import UserDto from "../../dtos/authentication/user/user.dto";
import ChangePasswordDto from "dtos/authentication/user/changePassword.dto";
import {UpdateProfileDto} from "../../dtos/authentication/user/updateProfile.dto";
const relativeUrl = "/v1/api/users";

export default class UserService extends ApiServiceData {
	public verifyAccount(userId: string, definedSignedToken: string) {
		return this.post(relativeUrl + "/verify-account/" + userId, undefined, undefined, definedSignedToken);
	}

	public resetPassword(requestDto: ResetPasswordDto, definedSignedToken: string) {
		return this.post(relativeUrl + "/reset-password", requestDto, undefined, definedSignedToken);
	}

	public async changePassword(requestDto: ChangePasswordDto) {
		return await this.post(relativeUrl + "/change-password", requestDto).catch(error => {
			throw error.response;
		});
	}

	public async changeChildPassword(userId: string, newPassword: string) {
		return await this.post(`${relativeUrl}/change-child-password/${userId}`, {newPassword: newPassword}).catch(error => {
			throw error.response;
		});
	}

	public async updateProfile(id: string, requestDto: UpdateProfileDto) {
		return await this.update(relativeUrl + "/update-profile/" + id, requestDto).catch(error => {
			throw error.response;
		});
	}

	public getUserById(id: string): Promise<UserDto> {
		return this.get(relativeUrl + "/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public existsParentUser(email: string): Promise<boolean> {
		return this.get(relativeUrl + "/exist/parent/" + email)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Parent not found"
				) {
					return false;
				}
				throw error.response;
			});
	}

	public hasChildren(email: string): Promise<boolean> {
		return this.get(relativeUrl + "/has/children/" + email)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Parent not found"
				) {
					return false;
				}
				throw error.response;
			});
	}

	public updateCurrentRoleInBaseOrganization() {
		return this.update(relativeUrl + "/switch-base-role/parent", null)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public search(key: string) {
		return this.get(relativeUrl + "/searchByKey/" + key)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async activateAccount(id: any) {
		return await this.update(relativeUrl + "/activate-account", {
			userId: id
		}).catch(error => {
			throw error.response;
		});
	}

	public findUserByEmail(email: string) {
		return this.get(relativeUrl + "/findUserByEmail/" + email)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async deleteChildAccount(childId: string) {
		return this.delete(`${relativeUrl}/delete-child-account/${childId}`).catch(error => {
			throw error.response;
		});
	}

	public async unlinkChildFromParent(childId: string) {
		return this.update(`${relativeUrl}/unlink-child-from-parent/${childId}`, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async removeParent() {
		return await this.update(`${relativeUrl}/remove-parent`, {}).catch(error => {
			throw error.response;
		});
	}

	public async getOrganizationUsers() {
		return this.get(relativeUrl + "/organization-users")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async deleteStaff(userRoleId: string) {
		return this.delete(relativeUrl + `/delete-staff/${userRoleId}`).catch(error => {
			throw error.response;
		});
	}

	public async updateStaffRole(userId: string, userRoleId: string, role: string) {
		return this.update(relativeUrl + "/update-staff-role", {
			UserId: userId,
			UserRoleId: userRoleId,
			role: role
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
