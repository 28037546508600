import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import _ from "lodash";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import Text from "common/components/general/Text";
import { SearchBox } from "scenes/admin/components/searchBox/SearchBox";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import SubjectService from "services/administration/subject.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { SubjectsSearch } from "./SubjectsSearch";
import { MarketplaceStore, useMarketplaceContext } from "../../../../../../../PublicMarketplace/PublicMarketplace";

export interface SearchBarProps {
	value?: string;
	loading: boolean;
	upperSpace?: boolean;
	search: (searchValue: string) => void;
	toggleFilterBar: (toggle: boolean) => void;
	filters: any;
}

const MIN_CHARS_LENGTH = 3;

export const SearchBar = function(props: SearchBarProps) {
	const { pathname } = useLocation();
	const [keyValid, setKeyValid] = useState(true);
	const {
		marketplaceStore: [state, setState]
	} = useMarketplaceContext() as { marketplaceStore: [MarketplaceStore, any] };

	const [displaysubjects, setDisplaySubjects] = useState(false);
	const filters = props.filters;

	const search = (key: any) => {
		const trimmedKey = key.trim();
		const keyValid = trimmedKey.length > MIN_CHARS_LENGTH || !trimmedKey;
		setKeyValid(keyValid);
		if (keyValid) props.search(trimmedKey);
	};

	const { t: translate } = useTranslation();
	const totalItems = useStoreState(state => state.subjects.totalItems);
	const totalCoursesOnPublicMarketPlace = useStoreState(state => state.subjects.totalCoursesOnPublicMarketPlace);
	// const totalCourseItems = Object.entries(totalCoursesOnPublicMarketPlace)
	// 	.filter(([key]) => key !== "all") // Filter out number of all courses
	// 	.reduce((acc: any, cur: any) => acc + cur[1], 0); // Only sum featured, popular and trending courses -> when on /shiko-mplc without any filter option

	const totalCourses =
		pathname === "/shiko-mplc"
			? state.searchKey ||
			  state.activeOrganization ||
			  state.group ||
			  state.category ||
			  state.subject ||
			  !!Object.keys(state.rightFilters).length
				? totalCoursesOnPublicMarketPlace["all"] // Show all courses when there are active filters
				: totalCoursesOnPublicMarketPlace["allCourses"]
			: totalItems;

	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const organizationId =
		currentUserInfo?.Organizations?.[0]?.OrganizationId || "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65";

	/**
	 * Load all Subjects without filters
	 */
	const loadAllSubjects = () => {
		return new SubjectService().getAll(organizationId).then((result: any) => {
			const subjects = result.filter((subject: any) => subject?.TotalClassrooms > 0);
			return setState((state: MarketplaceStore) => ({
				...state,
				subjects: _.orderBy(subjects, (x: any) => x.name)
			}));
		});
	};

	const onChange = () => {
		setKeyValid(true);
	};

	const displaySubjects = async () => {
		if (!state.subjects.length) {
			await loadAllSubjects();
		}
		setDisplaySubjects(!displaysubjects);
	};

	function handleClick(subject: any) {
		setDisplaySubjects(!displaysubjects);
		setState((state: MarketplaceStore) => ({ ...state, subject: subject.id }));
	}

	const subjectButton = () => {
		return (
			<Col className="pl-20 pt-5" xs={24} sm={24} md={4} lg={6} xl={6} xxl={6}>
				<StyledSubjectButton onClick={() => displaySubjects()} block={true}>
					{translate("_ADMIN._SEARCH_BOX._FILTER_NAME")} {displaysubjects ? <UpOutlined /> : <DownOutlined />}
				</StyledSubjectButton>
			</Col>
		);
	};

	return (
		<Row className={props.upperSpace ? "mt-24" : ""} gutter={[24, 0]}>
			<Col xs={24} className="mb-16">
				<Row gutter={[24, 0]}>
					{/* Search bar */}
					<Col
						xs={21}
						sm={filters.length > 0 ? 19 : 20}
						md={filters.length > 0 ? 15 : 16}
						lg={18}
						xl={18}
						xxl={18}
					>
						<SearchBox value={props.value} loading={props.loading} onSearch={search} onChange={onChange} />
						{!displaysubjects && (
							<Row justify="end">
								<Text fontSize="14">
									{translate("_ADMIN._SEARCH_BOX._REZULTATE", { nrResults: totalCourses })}
								</Text>
							</Row>
						)}
					</Col>
					{/* Mobile search icon button */}
					<Col className="pl-0" xs={3} sm={0} md={0} lg={0} xl={0} xxl={0}>
						<Button
							onClick={() => props.toggleFilterBar(true)}
							type={filters.length > 0 ? "primary" : "default"}
							ghost={!!filters.length}
							shape="circle"
							className="flex__center_justify_center"
						>
							<Icon type="ri-filter-line" />
						</Button>
					</Col>
					{/* Pad Search button */}
					<Col
						className="pl-0"
						xs={0}
						sm={filters.length > 0 ? 5 : 4}
						md={filters.length > 0 ? 4 : 3}
						lg={0}
						xl={0}
						xxl={0}
					>
						<Button
							onClick={() => props.toggleFilterBar(true)}
							type={filters.length > 0 ? "primary" : "default"}
							ghost={!!filters.length}
							shape="round"
							className="flex__center"
						>
							<Icon type="ri-filter-line" className="mr-4" />
							{translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} {filters.length ? `(${filters.length})` : ""}
						</Button>
					</Col>

					{/* Right side text on desktop */}
					{subjectButton()}
				</Row>

				{displaysubjects && (
					<SubjectsSearch activeSubject={state.subject} subjects={state.subjects} handleClick={handleClick} />
				)}
			</Col>
			{/* <ResultTags nrResults={totalItems} filters={filters} /> */}
			{!keyValid && (
				<Col span="24" className="color-red-6">
					{/* @todo move to translations */}
					Vendosni nje fjale me te gjate se {MIN_CHARS_LENGTH} karaktere
				</Col>
			)}
		</Row>
	);
};

const StyledSubjectButton = styled(Button)`
	@media (max-width: 760px) {
		margin-top: 15px;
	}
`;
