import {parseDomain} from "parse-domain";
import OAuth2Service from "services/authentication/oauth2.service";
import {LoadBalancerEnv} from "services/s3/utils";

export const isRequestCertificateRequired = (domain: string): boolean => {
	const subDomainsRestricted = [ "app.dev", "app.stage", "app", "app.qa", "www.app.dev", "www.app.stage", "www.app", "www.app.qa", "www", "*" ];
	const parseResult: any = parseDomain(domain);
	if (parseResult.type === "RESERVED") return false;
	const subDomainString = parseResult?.subDomains?.join(".");
	const domainString = [ parseResult?.domain, parseResult?.topLevelDomains?.join(".") ]?.join(".");
	if (window.location.host.includes(domainString) || domain.includes(OAuth2Service.CurrentOrganization?.domain) ||
		(OAuth2Service.CurrentOrganization?.domain && (OAuth2Service.CurrentOrganization?.domain?.includes(domain))) ||
		([ "akademi.al", "lms.al" ].some((restrictedDomain: string) => restrictedDomain === domainString) && subDomainString === "") ||
		([ "akademi.al", "lms.al" ].some((restrictedDomain: string) => restrictedDomain === domainString) &&
			subDomainString !== "" && subDomainsRestricted.some((subDomainRestricted: string) => subDomainRestricted === subDomainString)))
		return false;
	return true;
};

export const isValidDomain = (domain: string): boolean => {
	const parseResult: any = parseDomain(domain);
	if ([ "INVALID", "IP", "NOT_LISTED" ].some((restrictedDomain: string) => restrictedDomain === parseResult.type)) return false;
	return true;
};

export const getEnvironmentFromDomain = (domain: string): LoadBalancerEnv => {
	const parseResult: any = parseDomain(domain);
	if (parseResult.type === "RESERVED") return LoadBalancerEnv.DEV;
	if ([ "INVALID", "IP", "NOT_LISTED" ].some((restrictedDomain: string) => restrictedDomain === parseResult.type)) return LoadBalancerEnv.DEV;
	const subDomainString = parseResult?.subDomains?.join(".");
	const domainString = [ parseResult?.domain, parseResult?.topLevelDomains?.join(".") ]?.join(".");
	if (subDomainString.includes("dev")) return LoadBalancerEnv.DEV;
	if (subDomainString.includes("qa")) return LoadBalancerEnv.QA;
	if (subDomainString.includes("stage")) return LoadBalancerEnv.STAGE;
	if (subDomainString === "app" && domainString === "akademi.al") return LoadBalancerEnv.PRODUCTION;
	return LoadBalancerEnv.DEV;
};

export const getSubdomain = (domain: string): string => {
	const parseResult: any = parseDomain(domain);
	if (parseResult.type === "RESERVED") return "";
	if ([ "INVALID", "IP", "NOT_LISTED" ].some((restrictedDomain: string) => restrictedDomain === parseResult.type)) return "";
	return parseResult?.subDomains?.join(".");
};


export const getDomain = (domain: string): string => {
	const parseResult: any = parseDomain(domain);
	if (parseResult.type === "RESERVED") return "";
	if ([ "INVALID", "IP", "NOT_LISTED" ].some((restrictedDomain: string) => restrictedDomain === parseResult.type)) return "";
	return [ parseResult?.domain, parseResult?.topLevelDomains?.join(".") ]?.join(".");;
};

export const isReservedDomain = (domain: string): boolean => {
	const parseResult: any = parseDomain(domain);
	if (parseResult.type === "RESERVED") return true;
	return false;
};

export const getOrganizationCertificationState = (domain: string, listOfCertificates: any): string => {
	const env: any = getEnvironmentFromDomain(window.location.host);
	if (isReservedDomain(domain)) return "ISSUED";
	if (getDomain(domain) === "akademi.al" &&
		(env === LoadBalancerEnv.QA || env === LoadBalancerEnv.DEV || env === LoadBalancerEnv.STAGE))
		return listOfCertificates.find((certStatus: any) => certStatus.DomainName === "app.dev.akademi.al")?.Status || "INACTIVE";
	if (getDomain(domain) === "akademi.al" && env === LoadBalancerEnv.PRODUCTION)
		return listOfCertificates.find((certStatus: any) => certStatus.DomainName === "app.akademi.al")?.Status || "INACTIVE";
	if (getSubdomain(domain) === "")
		return listOfCertificates.find((certStatus: any) => certStatus.DomainName === `www.${domain}`)?.Status || "INACTIVE";
	return listOfCertificates.find((certStatus: any) => certStatus.DomainName === domain)?.Status || "INACTIVE";
};

export const getOrganizationCertificationConnection = (domain: string, listOfCertificates: any, linkCertificates: any): string => {
	const env: any = getEnvironmentFromDomain(window.location.host);
	if (isReservedDomain(domain)) return "CONNECTED";
	if (getDomain(domain) === "akademi.al" &&
		(env === LoadBalancerEnv.QA || env === LoadBalancerEnv.DEV || env === LoadBalancerEnv.STAGE)) {
		const certArn = listOfCertificates.find((certStatus: any) => certStatus.DomainName === "app.dev.akademi.al")?.CertificateArn;
		if (!certArn) return "NOT_CONNECTED";
		return linkCertificates.some((linked: any) => linked.CertificateArn === certArn) ? "CONNECTED" : "NOT_CONNECTED";
	}
	if (getDomain(domain) === "akademi.al" && env === LoadBalancerEnv.PRODUCTION) {
		const certArn = listOfCertificates.find((certStatus: any) => certStatus.DomainName === "app.akademi.al")?.CertificateArn;
		if (!certArn) return "NOT_CONNECTED";
		return linkCertificates.some((linked: any) => linked.CertificateArn === certArn) ? "CONNECTED" : "NOT_CONNECTED";
	}
	if (getSubdomain(domain) === "") {
		const certArn = listOfCertificates.find((certStatus: any) => certStatus.DomainName === `www.${domain}`)?.CertificateArn;
		if (!certArn) return "NOT_CONNECTED";
		return linkCertificates.some((linked: any) => linked.CertificateArn === certArn) ? "CONNECTED" : "NOT_CONNECTED";
	}
	const certArn = listOfCertificates.find((certStatus: any) => certStatus.DomainName === domain)?.CertificateArn;
	if (!certArn) return "NOT_CONNECTED";
	return linkCertificates.some((linked: any) => linked.CertificateArn === certArn) ? "CONNECTED" : "NOT_CONNECTED";
};
