import ApiServiceData from "../api.service";
import LessonDto from "../../dtos/administration/lesson.dto";

const relativeUrl = "/v1/api/lesson";

export default class LessonService extends ApiServiceData {
	public getLessonById(id: string): Promise<LessonDto> {
		return this.get(relativeUrl + "/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Lesson not found"
				) {
					return null;
				}
				throw error.response;
			});
	}

	public getLessonsByClassroomIdAndDateSegment(
		startDate: string,
		endDate: string,
		classroomId?: string
	): Promise<LessonDto[]> {
		return this.get(`${relativeUrl}/${startDate}/${endDate}/${classroomId}`).then(res => {
			return res.data;
		});
	}

	public async saveLesson(lessonDto: any) {
		return this.post(relativeUrl, lessonDto);
	}

	public async createNewLesson(newNode: any, parentId: string) {
		return this.post(relativeUrl, { ...newNode, subjectPlanTreeId: parentId });
	}

	public async getLessonsForSubjectPlanTree(subjectPlanTreeId: string) {
		return this.get(`${relativeUrl}/${subjectPlanTreeId}`);
	}

	public async updateLesson(lessonDto: any) {
		return this.update(`${relativeUrl}/${lessonDto.id}`, lessonDto);
	}

	public async deleteLesson(lessonId: string) {
		return this.delete(`${relativeUrl}/${lessonId}`);
	}

	public getCalendarLessonsByDateSegmentAndClassroomId(
		startDate: string,
		endDate: string,
		classroomId: string
	): Promise<LessonDto[]> {
		return this.get(`${relativeUrl}/endDate/${startDate}/${endDate}/classroom/${classroomId}`).then(res => {
			return res.data;
		});
	}
}
