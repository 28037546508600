import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";

const CLIENT_ID =
	process.env.REACT_APP_PAYPAL_CLIENT_ID ??
	"AVEiLyozJ4HBA6GwTqV-vlDlF43TpPQJW-e2LQbxdgK7YjyqwoYFClH1NDlApdLvlQQ16sGtY9P4Dg0A";

export enum FundingType {
	BANCONTACT = "bancontact",
	BLIK = "blik",
	BOLETO = "boleto",
	CARD = "card",
	CREDIT = "credit",
	EPS = "eps",
	GIROPAY = "giropay",
	IDEAL = "ideal",
	ITAU = "itau",
	MAXIMA = "maxima",
	MERCADOPAGO = "mercadopago",
	MYBANK = "mybank",
	OXXO = "oxxo",
	P24 = "p24",
	PAYLATER = "paylater",
	PAYPAL = "paypal",
	PAYU = "payu",
	SEPA = "sepa",
	SOFORT = "sofort",
	TRUSTLY = "trustly",
	VENMO = "venmo",
	VERKKOPANKKI = "verkkopankki",
	WECHATPAY = "wechatpay",
	ZIMPLER = "zimpler"
}

class PaypalButton extends React.Component<any, any> {
	PayPalButton: any = null;
	constructor(props: any) {
		super(props);

		this.state = {
			showButtons: false
		};

		window.React = React;
		window.ReactDOM = ReactDOM;
	}

	componentDidMount() {
		const { isScriptLoaded, isScriptLoadSucceed } = this.props;

		if (isScriptLoaded && isScriptLoadSucceed) {
			// @ts-ignore
			this.PayPalButton = window.paypal.Buttons.driver("react", {
				React,
				ReactDOM
			});
			this.setState({ showButtons: true });
		}
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps: any) {
		const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

		const scriptJustLoaded = !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

		if (scriptJustLoaded) {
			if (isScriptLoadSucceed) {
				// @ts-ignore
				this.PayPalButton = window.paypal.Buttons.driver("react", {
					React,
					ReactDOM
				});
				this.setState({ showButtons: true });
			}
		}
	}

	render() {
		const { showButtons } = this.state;

		return (
			showButtons && (
				<this.PayPalButton
					style={this.props.style}
					funding={this.props.funding}
					createOrder={(data: any, actions: any) => this.props.createOrder(data, actions)}
					onApprove={(data: any, actions: any) => {
						if (this.props.onApprove) this.props.onApprove(data, actions);
					}}
					onError={(error: any) => {
						if (this.props.onError) this.props.onError(error);
					}}
				/>
			)
		);
	}
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR`)(PaypalButton);
