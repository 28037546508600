import File from "../../administration/file.dto";
import {v4 as uuidv4} from "uuid";

export class CreateSubjectPlanTreeDto {
	id!: string;
	SubjectPlanId!: string;
	name!: string;
	description?: string;
	left!: number;
	right!: number;
	level!: number;
	createdBy!: string;
	updatedBy!: string;
	SubjectPlanLessons!: SubjectPlanLesson[];
	constructor(data: {
		SubjectPlanId: string;
		name: string;
		description?: string;
		left: number;
		right: number;
		level: number;
		createdBy: string;
		updatedBy: string;
		SubjectPlanLessons: SubjectPlanLesson[];
		[ key: string ]: any;
	}) {
		Object.assign(this, data);
		this.id = uuidv4();
	}
}

export class SubjectPlanLesson {
	id!: string;
	createdBy!: string;
	updatedBy!: string;
	Lesson!: Lesson[];
	constructor(data: {createdBy: string; updatedBy: string; Lesson: Lesson;[ key: string ]: any}) {
		Object.assign(this, data);
		this.id = uuidv4();
	}
}

export class Lesson {
	id!: string;
	name!: string;
	description?: string;
	startDate?: Date;
	endDate?: Date;
	videoUrl?: string;
	lessonType?: string;
	LessonSections?: LessonSection[];
	Week?: any;

	constructor(data: {
		name: string;
		description?: string;
		startDate?: Date;
		endDate?: Date;
		videoUrl?: string;
		lessonType?: string;
		LessonSections?: LessonSection[];
		Week?: any;
		[ key: string ]: any;
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}

export class Week {
	id!: string;
	name!: string;
	priority!: number;
	startDate?: Date;
	endDate?: Date;
	constructor(data: {name: string; priority: number; startDate?: Date; endDate?: Date;[ key: string ]: any}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}

export class LessonSection {
	id!: string;
	name!: string;
	description?: string;
	url?: string;
	File?: File;
	constructor(data: {name: string; description?: string; url?: string; File?: File;[ key: string ]: any}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}

export class Quiz {
	id!: string;
	lessonMetaInfo!: string;
	constructor(data: {lessonMetaInfo: string;[ key: string ]: any}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
