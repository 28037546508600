import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Layout, Space, Menu, Dropdown, Modal, Skeleton, Row, Col, Card, Collapse, Tooltip, Checkbox } from "antd";
import _ from "lodash";
import moment from "moment";
import { Formik } from "formik";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import ClassroomService from "services/administration/classroom.service";
import { MAIN_PATH } from "scenes/main";
import { COURSES_PATH } from "scenes/main/scenes/courses/TeacherCourses";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { showMessage } from "common/utils/Notification";
import GradeService from "services/administration/grade.service";
import { getLevelIcon } from "services/domain/administration/Grade";
import Option from "common/components/dataEntry/components/Option";
import defaultImg from "assets/images/main-classes-img2.svg";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";
import { HtmlInput as FormikHtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import { ChildClassroomsCarousel } from "../childClassroomsCarousel";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import { individualClassroomFormValidator } from "./ValidationSchema";
import FormikDatePickerField from "common/components/dataEntry/formik/FormikDatePickerField";
import { Uploader } from "common/components/uploader/Uploader";
import { previewFile } from "../../../../utils/FileUtils";
import Jimp from "jimp";
import FileDto from "dtos/administration/file.dto";
import BucketS3Service from "services/s3/s3.service";
import S3ActionType from "services/domain/administration/S3Action";
import PublishConfirmationModal from "./components/PublishConfirmationModal";
import { Role } from "services/domain/login/Role";
import { ADMIN_PATH } from "../../../../../admin";
import { LIVECLASSES_PATH } from "../../../../../admin/scenes/live-classes";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._INDIVIDUAL_CLASSROOM_LAYOUT";
const MARKETPLACE_COURSE_CATEGORIES = "_MARKETPLACE._COURSE_CATEGORIES";

class IndividualCourseBuilderLayoutComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			classroomId: "",
			classroom: {},
			gradesList: {},
			subjectsList: {},
			stagesList: [],
			refreshToggle: false,
			classroomEditMode: false,
			justCreated: props.location?.state?.justCreated,
			filesToDelete: []
		};
		this.getBackButton = this.getBackButton.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
	}

	async componentDidMount() {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		this.setState({ currentUserId: currentUserInfo?.UserId });

		const {
			history,
			match: { params }
		} = this.props;

		if (!params.classroomId) return history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		await this.loadClassroom();
		await this.getImage();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.classroomId !== this.state.classroomId) this.loadClassroom();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.classroomId !== prevState.classroomId) return { classroomId: params.classroomId };
		return null;
	}

	loadClassroom = async () => {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const [classroom, childClassrooms] = await Promise.all([
			new ClassroomService().getClassroomById(this.state.classroomId),
			new ClassroomService().getAllChildClassrooms(this.state.classroomId)
		]);

		if (!classroom) return this.props.history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		if (
			classroom &&
			(classroom.isAsync ||
				(!classroom.isAsync &&
					classroom.virtualClassroomType &&
					classroom.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Group))
		)
			return this.props.history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		if (currentUserInfo?.Roles?.[0]?.code !== Role.Owner && currentUserInfo?.UserId !== classroom.createdBy)
			return this.props.history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		this.setState({
			classroom: classroom,
			childClassrooms: childClassrooms,
			refreshToggle: !this.state.refreshToggle,
			organization: classroom.Organization,
			classroomInfo: {
				...classroom,
				name: classroom.name,
				description: classroom.description,
				stage: classroom.GradeSubject?.Grade?.level,
				gradeId: classroom.GradeSubject?.Grade?.id,
				subjectId: classroom.GradeSubject?.Subject?.id,
				subjectName: classroom.GradeSubject?.Subject?.name,
				endDate: classroom.endDate
			}
		});
	};

	goBack = () => {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const { history } = this.props;

		if (currentUserInfo?.Roles?.[0]?.code === Role.Owner && currentUserInfo?.UserId !== this.state.classroom.createdBy) {
			history.push(`/${ADMIN_PATH}/${LIVECLASSES_PATH}`)
		}
		else {
			history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		}
	};

	getBackButton = (): React.ReactNode => {
		return (
			<Text
				maxLength={25}
				fontSize="16"
				lineHeight="24"
				wheight="semibold"
				className="color-gray-9 cursor_pointer"
				onClick={this.goBack}
			>
				<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
					<Icon type="ri-arrow-left-line" />
				</Text>{" "}
				{this.state?.classroom?.name}
			</Text>
		);
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	openDeleteConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: null,
			title: (
				<Space size={16} align="start">
					<Icon type="ri-delete-bin-7-line" className="color-red-6" fontSize="24" />
					<Space direction="vertical" size={8}>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._DELETE_TITLE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._DELETE_MESSAGE`)}
						</Text>
					</Space>
				</Space>
			),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.deleteClassroom(),
			okType: "danger"
		});
	};

	deleteClassroom = () => {
		return new ClassroomService()
			.deleteVirtualClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	openArchiveConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: null,
			title: (
				<Space size={16} align="start">
					<Icon type="ri-inbox-archive-line" className="color-gray-6" fontSize="24" />
					<Space direction="vertical" size={8}>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_TITLE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_MESSAGE`)}{" "}
							<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
								{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
							</a>
						</Text>
					</Space>
				</Space>
			),
			okText: translate(`${TRANSLATION_BASE_PATH}._ARCHIVE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.archiveClassroom(),
			okType: "primary"
		});
	};

	archiveClassroom = () => {
		return new ClassroomService()
			.archiveClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	toggleClassroomEditMode = async () => {
		if (this.state.stagesList.length === 0) {
			await this.loadData();
		}

		this.setState({
			classroomEditMode: !this.state.classroomEditMode
		});
	};

	loadData = () => {
		const { t: translate } = this.props;

		return new GradeService()
			.getAllGrades()
			.then((grades: any) => {
				return _.chain(grades)
					.groupBy(grade => grade.level)
					.map((value, key) => ({ level: key, grades: value }))
					.value();
			})
			.then(allGrades => {
				const levels = allGrades.map(grade => {
					return {
						id: grade.level,
						name: translate(`_ADMIN._LEVELS._${grade.level}`),
						icon: getLevelIcon(grade.level),
						grades: grade.grades
					};
				});
				return this.setState({ stagesList: levels, subjectsList: [] });
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	resetGradeSelection(formik: any) {
		formik.setFieldValue("gradeId", "");
		formik.setFieldValue("subjectId", "");
	}

	resetSubjectSelection(formik: any) {
		formik.setFieldValue("subjectId", "");
	}

	getSubjectsOptions = (formik: any) => {
		const level = this.state.stagesList.find((stage: any) => stage.id === formik.values.stage);
		const grade = level.grades.find((grade: any) => grade.id === formik.values.gradeId);
		const subjects = grade?.GradeSubjects.map((gradeSubject: any) => gradeSubject.Subject);
		return subjects?.map((subject: any) => (
			<Option key={subject.id} value={subject.id}>
				{subject.name}
			</Option>
		));
	};

	createFile = (fileUploaded: any): FileDto => {
		const file = fileUploaded;
		if (file) {
			file.updatedBy = this.state.currentUserId;
			file.createdBy = this.state.currentUserId;
		}
		return file;
	};

	saveClassroom(item: any) {
		const { t: translate } = this.props;
		const file = this.createFile(this.state.fileUploaded);
		this.setState({ submitting: true });
		return new ClassroomService()
			.updateVirtualClassroom(item.id, {
				id: item.id,
				name: item.name,
				description: item.description,
				GradeSubject: {
					GradeId: item.gradeId,
					SubjectId: item.subjectId
				},
				File: this.state.fileUploaded === null ? null : file,
				FileId: this.state.fileUploaded === null ? null : file?.id,
				updatedBy: this.state.currentUserId,
				price: Number(item.price),
				endDate: item.endDate ? new Date(item.endDate).toISOString() : null,
				featured: item.featured,
				popular: item.popular,
				trending: item.trending
			})
			.then((result: any) => {
				return showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
			})
			.then(() => {
				return this.loadClassroom();
			})
			.finally(() => {
				this.state.filesToDelete.forEach((file: any) => {
					this.deleteFromS3(file);
				});

				this.setState(
					{
						submitting: false,
						newCoverPhoto: false
					},
					() => this.toggleClassroomEditMode()
				);

				this.getImage();
			});
	}

	onUploadingDone = async (fileUploaded: any) => {
		this.setState({ fileUploaded, newCoverPhoto: true });
		await this.getImage(fileUploaded);
	};

	getImage = async (file?: any) => {
		if (!file && !this.state.classroomInfo?.File) {
			this.setState({ imagePhto: undefined });
			return;
		}
		const response = await previewFile(file ? file : this.state.classroomInfo?.File);
		const imagePhoto = await this.transformFileDownload(response.url);
		return this.setState({ imagePhto: imagePhoto });
	};

	transformFileDownload = (imageUrl: any) => {
		return Jimp.read(imageUrl).then(image => {
			const width = image.bitmap.width;
			const height = image.bitmap.width / 4;
			return image
				.cover(width, height)
				.autocrop({ cropSymmetric: true })
				.getBase64Async(Jimp.MIME_JPEG);
		});
	};

	onUploadingStarted = (flag: boolean) => {
		this.setState({
			loading: flag
		});
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			fileUploaded: undefined
		});
	};

	closeImagePreview = () => {
		this.setState({ imagePhto: undefined });
		this.deleteFromS3(this.state.fileUploaded);
	};

	deleteCoverPhoto = () => {
		if (this.state.classroomInfo?.File) {
			this.setState({
				filesToDelete: [...this.state.filesToDelete, this.state.classroomInfo?.File],
				fileUploaded: null,
				imagePhto: undefined
			});
		}
	};

	editCoverPhoto = () => {
		if (this.state.classroomInfo?.File) {
			this.setState({
				filesToDelete: [...this.state.filesToDelete, this.state.classroomInfo?.File],
				fileUploaded: undefined,
				imagePhto: undefined
			});
		}
	};

	deleteFromS3 = (file: any) => {
		return new BucketS3Service()
			.getS3UrlForAction(`${file.filePath}/${file.name}`, S3ActionType.DELETE)
			.then(s3SignedUrl => {
				return fetch(s3SignedUrl, { method: "DELETE", body: file.name });
			});
	};

	cancelEditting = async () => {
		this.toggleClassroomEditMode();
		await this.loadClassroom();
		await this.getImage();
	};

	render() {
		const { t: translate } = this.props;

		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;

		const currentOrganization = currentUserInfo?.Organizations[0];
		const userRole =
			currentUserInfo?.Roles &&
			currentUserInfo.Roles.length > 0 &&
			currentUserInfo.Roles.sort((first: any, second: any) => Number(second?.priority) - Number(first?.priority))[0]?.code;

		const classroomOrganization = this.state?.classroom?.Organization;

		const menu = (
			<Menu>
				{this.state.classroom && this.state.classroom.status === "ACTIVE" && (
					<Menu.Item key="0" onClick={this.toggleClassroomEditMode}>
						<Space direction="horizontal">
							<Icon type="ri-edit-line" />
							{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
						</Space>
					</Menu.Item>
				)}
				{this.state.childClassrooms && this.state.childClassrooms.length > 0 ? (
					<Menu.Item key="1" disabled>
						<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._DELETE_DISABLED_TOOLTIP`)}>
							<div>
								<Space direction="horizontal">
									<Icon type="ri-delete-bin-7-line" />
									{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
								</Space>
							</div>
						</Tooltip>
					</Menu.Item>
				) : (
					<Menu.Item key="1" onClick={this.openDeleteConfirmationModal}>
						<Space direction="horizontal">
							<Icon type="ri-delete-bin-7-line" />
							{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
						</Space>
					</Menu.Item>
				)}
				{this.state.classroom && this.state.classroom.status === "ACTIVE" && (
					<Menu.Item key="2" onClick={this.openArchiveConfirmationModal}>
						<Space direction="horizontal">
							<Icon type="ri-inbox-archive-line" />
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE`)}
						</Space>
					</Menu.Item>
				)}
			</Menu>
		);

		let submitHandler: any;

		const xsMenu = (
			<Menu>
				<Menu.Item key="0" onClick={this.toggleClassroomEditMode}>
					<Space direction="horizontal">
						<Icon type="ri-close-line" />
						{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_CANCEL`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="1" onClick={() => submitHandler()}>
					<Space direction="horizontal">
						<Icon type="ri-save-line" />
						{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_SAVE`)}
					</Space>
				</Menu.Item>
			</Menu>
		);

		return (
			<Skeleton loading={!(this.state.classroom && this.state.organization)} active>
				{this.state.classroom && this.state.organization && !this.state.classroomEditMode && (
					<Layout className="full__min_height background-color-gray-2">
						<Row className="mb-24">
							<Col span={24} className="individual__course_builder_header_menu">
								{this.getBackButton()}

								<Space>
									{this.state.classroom.status === "DRAFT" && (
										<Button type="primary" onClick={() => PublishConfirmationModal({
											classroomId: this.state.classroomId,
											onFinish: this.loadClassroom,
											translate
										})}>
											{translate("_COURSE_BUILDER._COURSE_DETAILS._LAYOUT._PUBLISH")}
										</Button>
									)}
									<Dropdown.Button
										buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
										trigger={["click"]}
										overlay={menu}
										icon={<Icon type="ri-more-2-line" />}
									/>
								</Space>

							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Row justify="center">
									<Card
										className="individual__course_builder_course_overview_card"
										title={
											<Row>
												<Col span={24} className="flex__center_space_between">
													<Space direction="vertical" size={8}>
														<Text
															fontSize="30"
															lineHeight="38"
															className="color-blue-1"
															maxLength={25}
														>
															{this.state.classroom.name}
														</Text>
														<Text fontSize="20" lineHeight="28" className="color-blue-2">
															{OAuth2Service.CurrentUser?.User?.firstName}{" "}
															{OAuth2Service.CurrentUser?.User?.lastName}
														</Text>
														<Tooltip
															title={translate(
																`${TRANSLATION_BASE_PATH}._CLASSROOM_INTERVAL_TOOLTIP`
															)}
														>
															<Space direction="horizontal" size={8} align="center">
																<Icon
																	type="ri-calendar-line"
																	className="color-blue-2"
																	fontSize="20"
																/>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-blue-2"
																>
																	{this.state.classroom.startDate &&
																		moment(this.state.classroom.startDate).format(
																			"DD MMMM YYYY"
																		)}{" "}
																	-{" "}
																	{this.state.classroom.endDate &&
																		moment(this.state.classroom.endDate).format(
																			"DD MMMM YYYY"
																		)}
																</Text>
															</Space>
														</Tooltip>
													</Space>
													<div className="individual__classroom_cover_image">
														<Image src={this.state.imagePhto ?? defaultImg} />
													</div>
												</Col>
											</Row>
										}
									>
										<Collapse
											bordered={false}
											className="individual__classroom_collapse_info"
											expandIcon={({ isActive }) => (
												<div>
													<Icon
														type={
															isActive ? "ri-arrow-down-s-fill" : "ri-arrow-right-s-fill"
														}
														fontSize="24"
													/>
												</div>
											)}
										>
											<Collapse.Panel
												key="details"
												header={
													<Text
														fontSize="16"
														lineHeight="24"
														wheight="semibold"
														className="color-gray-9"
													>
														{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
													</Text>
												}
												extra={
													<Space
														direction="horizontal"
														size={24}
														className="individual__classroom_collapse_extra_info"
													>
														<Space direction="horizontal" size={8}>
															<Text
																fontSize="16"
																lineHeight="24"
																wheight="semibold"
																className="color-gray-8"
															>
																{translate(`${TRANSLATION_BASE_PATH}._ORGANIZATION`)}
															</Text>
															<Text
																fontSize="16"
																lineHeight="24"
																className="color-gray-8"
																maxLength={25}
															>
																{this.state.organization.name}
															</Text>
														</Space>
														<Space direction="horizontal" size={8}>
															<Text
																fontSize="16"
																lineHeight="24"
																wheight="semibold"
																className="color-gray-8"
															>
																{translate(`${TRANSLATION_BASE_PATH}._GRADE`)}
															</Text>
															<Text
																fontSize="16"
																lineHeight="24"
																className="color-gray-8"
															>
																{this.state.classroom?.GradeSubject?.Grade?.name}
															</Text>
														</Space>
														<Space direction="horizontal" size={8}>
															<Text
																fontSize="16"
																lineHeight="24"
																wheight="semibold"
																className="color-gray-8"
															>
																{translate(`${TRANSLATION_BASE_PATH}._SUBJECT`)}
															</Text>
															<Text
																fontSize="16"
																lineHeight="24"
																className="color-gray-8"
															>
																{this.state.classroom?.GradeSubject?.Subject?.name}
															</Text>
														</Space>
													</Space>
												}
											>
												<HtmlInput
													name="description"
													readOnly={true}
													value={this.state.classroom?.description}
												/>
											</Collapse.Panel>
										</Collapse>
									</Card>
								</Row>
							</Col>
						</Row>
						<Row className="mt-24 mb-24">
							<Col span={24}>
								<Row justify="center">
									<ChildClassroomsCarousel items={this.state.childClassrooms} />
								</Row>
							</Col>
						</Row>
					</Layout>
				)}
				{this.state.classroomEditMode && (
					<Layout className="full__min_height background-color-gray-2">
						<Row className="background-color-gray-1 teacher__layout__editClass__header" align="middle">
							<Col xs={21} sm={21} md={16} lg={16} xl={16} xxl={16}>
								<Row justify="start" className="pl-24">
									<Space>
										<Icon
											onClick={this.toggleClassroomEditMode}
											className="cursor_pointer"
											fontSize="16"
											type="ri-arrow-left-line"
										></Icon>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._TITLE`)}
										</Text>
									</Space>
								</Row>
							</Col>
							<Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}>
								<Row justify="end" className="pr-24">
									<Space>
										<Button type="default" size="middle" onClick={this.cancelEditting}>
											{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_CANCEL`)}
										</Button>
										<Button
											type="primary"
											size="middle"
											loading={this.state.submitting}
											disabled={this.state.submitting}
											onClick={() => submitHandler()}
										>
											{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_SAVE`)}
										</Button>
									</Space>
								</Row>
							</Col>
							<Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0}>
								<Row justify="end" className="pr-24">
									<Dropdown.Button
										buttonsRender={() => [
											<></>,
											<Button
												key="1"
												type="default"
												shape="circle"
												className="materialItem__layout materialItem__button-circle color-gray-8"
											>
												<Icon type="ri-more-2-line" />
											</Button>
										]}
										trigger={["click"]}
										overlay={xsMenu}
										icon={<Icon type="ri-more-2-line" />}
									/>
								</Row>
							</Col>
						</Row>
						<Row justify="center">
							<Col xs={22} sm={20} md={18} lg={16} xl={12} xxl={12}>
								<Card className="mt-16 update-live-course-card">
									<Formik
										enableReinitialize={true}
										initialValues={this.state.classroomInfo}
										onSubmit={values => this.saveClassroom(values)}
										validationSchema={individualClassroomFormValidator(translate)}
									>
										{formik => {
											const { handleSubmit, submitForm } = formik;
											submitHandler = submitForm;
											return (
												<form onSubmit={handleSubmit} autoComplete="off">
													<Row>
														<Col span={24}>
															{this.state.imagePhto && !this.state.newCoverPhoto && (
																<div className="cover-photo">
																	<img src={this.state.imagePhto} className="image" />
																	<Icon
																		type="ri-edit-line"
																		className="edit-cover-photo"
																		onClick={this.editCoverPhoto}
																	/>
																	<Icon
																		type="ri-delete-bin-7-line"
																		className="remove-cover-photo"
																		onClick={this.deleteCoverPhoto}
																	/>
																</div>
															)}
															{(!this.state.imagePhto || this.state.newCoverPhoto) && (
																<div className="upload-photo">
																	<p className="title">
																		{translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._FOTO`
																		)}
																	</p>
																	<p className="description">
																		{translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._FOTO_SUGGESTION_TEXT`
																		)}{" "}
																		<a
																			target="_blank"
																			rel="noopener noreferrer"
																			href="https://unsplash.com/"
																		>
																			Unsplash.com
																		</a>{" "}
																		{translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._FOTO_SUGGESTION_OR`
																		)}{" "}
																		<br />
																		<a
																			target="_blank"
																			rel="noopener noreferrer"
																			href="https://www.pexels.com/"
																		>
																			Pexels.com
																		</a>
																	</p>
																	<Uploader
																		fileType="IMAGE"
																		filePath={`classroom/${this.state.classroomId}`}
																		fileWithUniqueId={false}
																		onUploadingDone={this.onUploadingDone}
																		showUploadList={false}
																		onUploadingStarted={this.onUploadingStarted}
																		onSoftDeleteFile={this.onSoftDeleteFile}
																		className="upload-button"
																	/>
																	{this.state.imagePhto && (
																		<div className="uploaded-image-preview">
																			<img
																				src={this.state.imagePhto}
																				className="image"
																			/>
																			<p className="image-name">
																				{this.state.fileUploaded.name}
																			</p>
																			<Icon
																				type="ri-close-fill"
																				className="remove-image"
																				onClick={this.closeImagePreview}
																			/>
																		</div>
																	)}
																</div>
															)}
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikDatePickerField
																name="endDate"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._DATE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._DATE_PLACEHOLDER`
																)}
																className="full__width"
																dateFormat="YYYY-MM-DD"
																defaultValue={
																	formik.initialValues.endDate
																		? moment(formik.initialValues.endDate)
																		: undefined
																}
															/>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikDefaultInputField
																name="name"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._TITLE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._TITLE_PLACEHOLDER`
																)}
															/>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikHtmlInput
																name="description"
																basicToolbar={true}
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._DESCRIPTION`
																)}
															/>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikDefaultInputField
																name="price"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._PRICE`
																)}
																value={formik.values.price}
																addonAfter={<>&euro;</>}
															/>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikSelectField
																name="stage"
																onChange={e => this.resetGradeSelection(formik)}
																defaultValue={formik.initialValues.stage}
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._STAGE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._STAGE_PLACEHOLDER`
																)}
															>
																{this.state.stagesList.map((chapter: any) => (
																	<Option key={chapter.id} value={chapter.id}>
																		{chapter.name}
																	</Option>
																))}
															</FormikSelectField>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikSelectField
																name="gradeId"
																onChange={e => this.resetSubjectSelection(formik)}
																defaultValue={formik.initialValues.gradeId}
																value={formik.values.gradeId}
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._GRADE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._GRADE_PLACEHOLDER`
																)}
															>
																{this.state.stagesList
																	.filter(
																		(stage: any) => stage.id === formik.values.stage
																	)
																	.flatMap((stage: { grades: any }) => stage.grades)
																	.map((chapter: any) => (
																		<Option key={chapter.id} value={chapter.id}>
																			{chapter.name}
																		</Option>
																	))}
															</FormikSelectField>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikSelectField
																name="subjectId"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._SUBJECT`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._SUBJECT_PLACEHOLDER`
																)}
																size="middle"
																defaultValue={formik.initialValues.subjectName}
																value={formik.values.subjectId}
																showSearch
																filterOption={(
																	inputValue: string,
																	option?: any
																): boolean => {
																	return option.children
																		.toLowerCase()
																		.includes(inputValue.toLowerCase());
																}}
															>
																{this.getSubjectsOptions(formik)}
															</FormikSelectField>
														</Col>
													</Row>
													{(!currentOrganization.ParentOrganizationId && currentOrganization.sector === "PRIVATE" && userRole === Role.Admin) ||
													userRole === Role.Owner && currentOrganization?.OrganizationId === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65" &&
													(classroomOrganization?.sector === "PUBLIC" || classroomOrganization?.id === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65") && (
														<Row justify="center" className="mb-24">
															<Col span={18}>
																<Checkbox
																	checked={formik.values.featured}
																	onChange={e => {
																		formik.setFieldValue("featured", e.target.checked);
																	}}
																>
																	{translate(`${MARKETPLACE_COURSE_CATEGORIES}._FEATURED`)}
																</Checkbox>
																<Checkbox
																	checked={formik.values.trending}
																	onChange={e => {
																		formik.setFieldValue("trending", e.target.checked);
																	}}
																>
																	{translate(`${MARKETPLACE_COURSE_CATEGORIES}._TRENDING`)}
																</Checkbox>
																<Checkbox
																	checked={formik.values.popular}
																	onChange={e => {
																		formik.setFieldValue("popular", e.target.checked);
																	}}
																>
																	{translate(`${MARKETPLACE_COURSE_CATEGORIES}._POPULAR`)}
																</Checkbox>
															</Col>
														</Row>
													)}
												</form>
											);
										}}
									</Formik>
								</Card>
							</Col>
						</Row>
					</Layout>
				)}
			</Skeleton>
		);
	}
}

export const IndividualCourseBuilderLayout = withTranslation()(IndividualCourseBuilderLayoutComponent);
