import { Button, Card, Col, Divider, Row, Skeleton, Space, Tooltip } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";

import OAuth2Service from "services/authentication/oauth2.service";
import { isOver13 } from "common/utils/isOver13";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import PasswordForm from "./passwordForm/PasswordForm";
import ProfileForm from "./profileForm/ProfileForm";
import { DeleteAccount } from "./deleteAccount/deleteAccount";
import { UserAvatar } from "common/components/general/UserAvatar";
import { SectionHeader } from "../../components/sectionHeader/sectionHeader";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_SETTINGS";

export const PARENT_CHILD_SETTINGS_PATH = "child-settings";

class ParentChildSettingsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			child: null
		};
	}

	componentDidMount() {
		this.loadSettings(this.props.match.params.id);
	}

	loadSettings = (childId: string) => {
		const parentEmail = OAuth2Service.CurrentUser.User.email;

		this.setState({
			loading: true
		});

		return new OAuth2Service()
			.getChildren(parentEmail)
			.then((result: any) => {
				const child = result.children
					.filter((x: any) => {
						return x.id === childId;
					})
					.map((item: any) => {
						return {
							...item,
							over13: isOver13(item.dateOfBirth),
							parentEmail: parentEmail
						};
					});
				return this.setState({
					child: child[0]
				});
			})
			.finally(() =>
				this.setState({
					loading: false
				})
			);
	};

	get childAvatar() {
		const child = this.state.child;
		return <UserAvatar {...child} userId={child.id} />;
	}

	get childFullName() {
		const child = this.state.child;
		return (
			<Text fontSize="24" lineHeight="32" className="color-gray-9" wheight="semibold">
				{child.firstName} {child.lastName}
			</Text>
		);
	}

	get childEmail() {
		return (
			<Space>
				<Icon type="ri-mail-line" />
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{OAuth2Service.CurrentUser.User.email}
				</Text>
			</Space>
		);
	}

	get childUsername() {
		const child = this.state.child;
		return (
			<Space>
				<Icon type="ri-account-pin-box-line" />
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{child.username}
				</Text>
			</Space>
		);
	}

	get registeredOn() {
		const child = this.state.child;
		const { t: translate } = this.props;
		return (
			<Text fontSize="12" lineHeight="20" className="color-gray-8">
				{translate(`${TRANSLATION_BASE_PATH}._REGISTERED_ON`, {
					date: moment(child.createdAt).format("D MMMM YYYY")
				})}
			</Text>
		);
	}

	get unlink() {
		const { t: translate } = this.props;
		return (
			<Space>
				<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._TOOLTIP_UNLINK`)}>
					<a>
						<Icon fontSize="20" type="ri-information-line" className="color-blue-6" />
					</a>
				</Tooltip>
				<Button size="small" onClick={() => console.log("TODO: unlik...")}>
					<Space>
						<Icon type="ri-user-unfollow-line" />
						<Text fontSize="14" lineHeight="22" className="color-gray-6">
							{translate(`${TRANSLATION_BASE_PATH}._BTN_UNLINK`)}
						</Text>
					</Space>
				</Button>
			</Space>
		);
	}

	updateProfile = (child: any) => {
		this.setState({
			child: {
				...this.state.child,
				firstName: child.firstName,
				lastName: child.lastName,
				username: child.username
			}
		});
	};

	render() {
		return (
			<Skeleton loading={this.state.loading}>
				{this.state.child && (
					<Row className="pt-24 pr-16 pl-16 mb-24" justify="center">
						<Card title={null} className="parent__childSettings__card">
							<Row className="pt-24 pr-24 pl-24">
								<SectionHeader child={this.state.child} />
							</Row>
							<Divider className="pb-0" />
							<Row className="pl-24 pr-24 pb-0 mb-24">
								<Col span="24">
									<ProfileForm child={this.state.child} updateProfile={this.updateProfile} />
								</Col>
							</Row>
							<Row className="pl-24 pr-24 pb-0 mb-24">
								<Col span="24">
									<PasswordForm child={this.state.child} />
								</Col>
							</Row>
							<Row className="pl-24 pr-24 pb-24">
								<Col span="24">
									<DeleteAccount child={this.state.child} />
								</Col>
							</Row>
						</Card>
					</Row>
				)}
			</Skeleton>
		);
	}
}

const ParentChildSettings = withTranslation()(ParentChildSettingsComponent);
export default ParentChildSettings;
