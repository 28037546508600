import * as Yup from "yup";

import { OrganizationSectorType } from "services/domain/administration/Organization";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		numberOfUsers: Yup.number()
			.nullable()
			.notRequired()
			.when("sector", {
				is: (sector: string) => sector === OrganizationSectorType.Private,
				then: Yup.number()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_NUMBER`))
					.positive(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_POSITIVE`))
			}),
		price: Yup.number()
			.nullable()
			.notRequired()
			.when("sector", {
				is: (sector: string) => sector === OrganizationSectorType.Private,
				then: Yup.number()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_NUMBER`))
					.moreThan(-0.01, translate(`${TRANSLATION_BASE_PATH}._REQUIRED_POSITIVE`))
			})
	});
