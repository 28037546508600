import React, { ReactNode } from "react";
import { Col, Row, Space, Card, Button as AntButton } from "antd";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import LessonSectionDto from "dtos/administration/lessonSection.dto";
import { previewFile } from "scenes/courseBuilder/utils/FileUtils";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";

export const ASSIGNMENT_FULL_PATH = "assignment/:assignmentId";
const TRANSLATION_BASE_PATH = "_MATERIAL_INFO";

interface AssignmentsDetailsProps extends WithTranslation {
	lesson: any;
}

const getSubLessons = (lessonSection: LessonSectionDto, key: number): ReactNode => {
	return (
		<Row key={key}>
			{lessonSection && !lessonSection.File ? (
				<AntButton
					type="link"
					href={lessonSection.url}
					rel="noopener noreferrer"
					target="_blank"
					className="p-0"
				>
					<Space direction="horizontal" size={8}>
						<Icon type="ri-link-m" className="color-gray-6" />
						<Text fontSize="14" lineHeight="22" className="color-blue-6" maxLength={25}>
							{lessonSection.name}
						</Text>
					</Space>
				</AntButton>
			) : (
				<AntButton
					type="link"
					onClick={async () => {
						if (lessonSection.File) {
							const urlObj = await previewFile(lessonSection.File);
							const url = urlObj.url;
							window.open(url, "_blank");
						}
					}}
					rel="noopener noreferrer"
					target="_blank"
					className="p-0"
				>
					<Space direction="horizontal" size={8}>
						<Icon type="ri-file-text-line" className="color-gray-6" />
						<Text fontSize="14" lineHeight="22" className="color-blue-6" maxLength={25}>
							{lessonSection.name}
						</Text>
					</Space>
				</AntButton>
			)}
		</Row>
	);
};

const AssignmentsDetailsComponent = (props: AssignmentsDetailsProps) => {
	const { t: translate } = useTranslation();
	return props.lesson ? (
		<Row>
			<Col xs={0} sm={0} md={4} lg={5} xl={5} xxl={6}></Col>
			<Col xs={24} sm={24} md={16} lg={14} xl={14} xxl={12}>
				<Card>
					<Row>
						<Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
							<Row gutter={[0, 16]}>
								<Col span={24}>
									<Text fontSize="14" lineHeight="22" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_LABEL`)}
									</Text>
								</Col>
							</Row>
							<Row gutter={[0, 16]}>
								<Col span={24}>
									{props.lesson.description && props.lesson.description.indexOf("<p") > -1 ? (
										<HtmlInput name="description" readOnly value={props.lesson.description} />
									) : (
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{props.lesson.description}
										</Text>
									)}
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
							<Row gutter={[0, 8]}>
								<Col span={24}>
									<Text fontSize="14" lineHeight="22" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._MATERIALS`)}
									</Text>
								</Col>
							</Row>
							<Row gutter={[0, 8]}>
								<Col span={24}>
									<Space direction="vertical" size={0}>
										{props.lesson &&
											props.lesson.LessonSections?.map(
												(lessonSection: LessonSectionDto, i: number) => {
													return getSubLessons(lessonSection, i);
												}
											)}
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Space direction="vertical">
								<Text fontSize="14" lineHeight="22" className="color-gray-7">
									<Icon type="ri-timer-line" /> {translate(`${TRANSLATION_BASE_PATH}._END_DATE`)}
								</Text>
								{props.lesson?.endDate ? (
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{moment(props.lesson?.endDate).format("DD.MM.YYYY HH:mm")}
									</Text>
								) : null}
							</Space>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	) : null;
};

const AssignmentsDetails = withTranslation()(AssignmentsDetailsComponent);
export default AssignmentsDetails;
