import React from "react";
import moment from "moment";
import { useField, useFormikContext } from "formik";

import DatePicker, { DatePickerProps } from "../components/DatePicker";
import Error from "./FormItemError";
import Label from "../components/Label";
import { FieldProps } from "./FieldProps";

type FormikDatePickerProps = DatePickerProps & FieldProps & { dateFormat?: string };

const FormikDatePickerField: React.FC<FormikDatePickerProps> = props => {
	const [, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	const onDateChange = (date: any, dateString: string) => {
		context.setFieldValue(
			props.name,
			props.dateFormat
				? moment.utc(dateString).format(props.dateFormat)
				: moment.utc(dateString).format("YYYY-MM-DD")
		);
	};

	const onBlur = (): void => {
		context.setFieldTouched(props.name);
	};

	return (
		<div>
			<Label htmlFor={id} className="hand-on-hover">
				{props.label}
			</Label>
			<DatePicker {...props} format={props.dateFormat} onChange={onDateChange} onBlur={onBlur} />
			{error}
		</div>
	);
};

export default FormikDatePickerField;
