import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";

const TRANSLATION_BASE_PATH = "_LOGIN._JOIN_INSTAGRAM_ALERT";

const InstagramUrl = "https://www.instagram.com/akademi.al";

export const JoinInstagramAlert = () => {
	const { t: translate } = useTranslation();
	const message = (
		<div className="aler__content_wrapper">
			<Text fontSize="14" lineHeight="22" className="color-blue-1">
				{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_PART_1`)}{" "}
				<a href={InstagramUrl} target="_blank" rel="noopener noreferrer" className="join__instagram_link">
					akademi.al
				</a>{" "}
				{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_PART_2`)}
			</Text>
			<Button
				icon="ri-instagram-line"
				type="text"
				className="join__instagram_button"
				onClick={() => {
					window.open(InstagramUrl, "_blank");
				}}
			>
				{translate(`${TRANSLATION_BASE_PATH}._FOLLOW`)}
			</Button>
		</div>
	);
	return (
		<Alert
			message={message}
			banner
			closable
			type="info"
			icon={<Icon type="ri-information-fill" className="color-gray-1" />}
			className="login__join_instagram_alert"
		/>
	);
};
