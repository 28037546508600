import { Popover, Space } from "antd";
import React from "react";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import TagsDemonstrationImage from "assets/images/tags-popover-demonstration.svg";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";

export const classroomDescriptionPopover = (translate: (key: string) => string) => {
	return (
		<Popover
			title={translate(`${TRANSLATION_BASE_PATH}._FORM_CREATE_CLASS._FIELDS._DESCRIPTION`)}
			content={
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					Rekomandohet që ky përshkrim të jetë sa më i plotë edhe përshkrues rreth kursit dhe mënyrës si do
					zhvillohet.{" "}
					<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
						Mëso më shumë
					</a>
				</Text>
			}
			placement="top"
			overlayStyle={{ maxWidth: "224px" }}
		>
			<div>
				<Icon type="ri-information-line" className="color-gray-8" fontSize="20" />
			</div>
		</Popover>
	);
};

export const classroomTypePopover = (translate: (key: string) => string) => {
	return (
		<Popover
			title="Lloji klases"
			content={
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					Zgjidhni individual nqs do zhvilloni seanca mesimore vetem me nje nxenes. Zgjidhni Grupe nqs mesimi
					do te zhvillohet te pakten me dy nxenes.{" "}
					<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
						Mëso më shumë
					</a>
				</Text>
			}
			placement="top"
			overlayStyle={{ maxWidth: "224px" }}
		>
			<div>
				<Icon type="ri-information-line" className="color-gray-8" fontSize="20" />
			</div>
		</Popover>
	);
};

export const classroomIntervalPopover = (translate: (key: string) => string) => {
	return (
		<Space direction="horizontal" size={8}>
			<Text fontSize="14" lineHeight="22" className="color-gray-7">
				(Jo e detyrueshme)
			</Text>
			<Popover
				title="Vlefshmeria"
				content={
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						Perdoreni kete fushe per te treguar disponueshmerine tuaj. Data fillimit percakton daten qe kjo
						klase do te nise. Data perfundimi percakton daten qe kjo klase do te perfundoje.
					</Text>
				}
				placement="top"
				overlayStyle={{ maxWidth: "224px" }}
			>
				<div>
					<Icon type="ri-information-line" className="color-gray-8" fontSize="20" />
				</div>
			</Popover>
		</Space>
	);
};

export const classroomTagsTitlePopover = (translate: (key: string) => string) => {
	return (
		<Popover
			title="Grupimet"
			content={
				<Space direction="vertical" size={4}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						Grupimet janë opsione që Akademi.al i ka vënë në përdorim për të ndihmuar nxënësit në shfletimin
						edhe gjetjen e kurseve/klasave të dëshiruara. Këto grupime janë filtrat në Marketplace (shikoni
						imazhin).
					</Text>
					<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
						Mëso më shumë
					</a>
					<Image src={TagsDemonstrationImage} />
				</Space>
			}
			placement="top"
			overlayStyle={{ maxWidth: "336px" }}
		>
			<div>
				<Icon type="ri-information-line" className="color-gray-8" fontSize="20" />
			</div>
		</Popover>
	);
};
