import { Button, Col, Collapse, Divider, Row, Space } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";

import { CircularIcon } from "common/components/general/CircularIcon";
import Text from "common/components/general/Text";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import { passwordFormValidator } from "./PasswordValidationSchema";
import UserService from "services/authentication/user.service";
import { showError, showMessage } from "common/utils/Notification";

const { Panel } = Collapse;
const TRANSLATION_BASE_PATH = "_PARENT._CHILD_SETTINGS._PASSWORD";

class PasswordFormComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submittingPasswordForm: false,
			passwordInitialValues: {
				newPassword: "",
				repeatPassword: ""
			}
		};
	}

	get passwordHeader() {
		const { t: translate } = this.props;
		return (
			<Space>
				<CircularIcon type="ri-key-2-line" />
				<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
					{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
				</Text>
			</Space>
		);
	}

	get passwordContentForm() {
		const { t: translate } = this.props;
		return (
			<>
				<Row className="pt-24">
					<Col span="24">
						<Formik
							enableReinitialize={true}
							initialValues={{ ...this.state.passwordInitialValues }}
							onSubmit={this.submitPasswordForm}
							isInitialValid={false}
							validationSchema={passwordFormValidator(translate)}
						>
							{props => {
								const { handleSubmit } = props;
								return (
									<form onSubmit={handleSubmit} id="password-form" autoComplete="off">
										<Row>
											<Col span="24">
												<PasswordInput
													name="newPassword"
													label={translate(`${TRANSLATION_BASE_PATH}._FORM._PASSWORD`)}
													showPopover={true}
													size="large"
													autoComplete="new-password"
												/>
											</Col>
										</Row>
										<Row>
											<Col span="24">
												<PasswordInput
													name="repeatPassword"
													label={translate(
														`${TRANSLATION_BASE_PATH}._FORM._CONFIRM_PASSWORD`
													)}
													showPopover={false}
													size="large"
													autoComplete="new-password"
												/>
											</Col>
										</Row>
										<Row justify="end">
											<Button
												type="primary"
												size="middle"
												htmlType="submit"
												loading={this.state.submittingPasswordForm}
												disabled={this.state.submittingPasswordForm}
											>
												{translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_SAVE`)}
											</Button>
										</Row>
									</form>
								);
							}}
						</Formik>
					</Col>
				</Row>
				<Divider className="pb-0" />
			</>
		);
	}

	submitPasswordForm = (values: any) => {
		const { t: translate } = this.props;
		this.setState({
			submittingPasswordForm: true
		});

		return new UserService()
			.changeChildPassword(this.props.child.id, values.newPassword)
			.then(() => {
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._FORM._PASSWORD_CHANGED`));
			})
			.catch(error => {
				console.log(error);
				return showError(translate(`${TRANSLATION_BASE_PATH}._FORM._500_API_STATUS_CODE`));
			})
			.finally(() => {
				this.setState({
					submittingPasswordForm: false
				});
			});
	};

	render() {
		return (
			<Collapse defaultActiveKey={["1"]} ghost expandIconPosition="right">
				<Panel header={this.passwordHeader} key="1">
					{this.passwordContentForm}
				</Panel>
			</Collapse>
		);
	}
}

const PasswordForm = withTranslation()(PasswordFormComponent);
export default PasswordForm;
