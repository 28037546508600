import React from "react";
import { useFormikContext } from "formik";
import { Steps as AntSteps, Row, Col } from "antd";

const { Step } = AntSteps;

interface StepsObject {
	title: string;
	description?: string;
	icon?: string;
	disabled?: boolean
}

interface StepsProps {
	steps: Array<StepsObject>;
	title?: string;
	direction?: "vertical" | "horizontal";
	subtitle?: string;
	description?: string;
	current?: number;
	size?: "default" | "small";
	progressDot?: boolean;
	onChange?(current: number): void;
	className?: string;
	type?: string;
}

const Steps: React.FC<StepsProps> = props => {
	const context = useFormikContext();

	const onChange = (current: number) => {
		props.current = current;
		context.setFieldValue("step", current);
	};

	return (
		<Row>
			<Col span={props.direction === "vertical" ? 4 : 24}>
				{props.progressDot && (
					<AntSteps
						className={props.className}
						progressDot
						direction={props.direction}
						current={props.current}
						size={props.size}
						onChange={onChange}
					>
						{props.steps.map((item, index) => {
							return <Step key={index} disabled={item.disabled} title={item.title} description={item.description}></Step>;
						})}
					</AntSteps>
				)}
				{!props.progressDot && (
					<AntSteps direction={props.direction} current={props.current} onChange={onChange} size={props.size}>
						{props.steps.map((item, index) => {
							return <Step key={index} disabled={item.disabled} title={item.title} description={item.description}></Step>;
						})}
					</AntSteps>
				)}
			</Col>
		</Row>
	);
};
export default Steps;
