import {v4 as uuidv4} from "uuid";
import AssignmentUserCommitFileDto from "./assignmentUserCommitFile.dto";

export default class AssignmentUserCommitDto {
	readonly id!: string;
	readonly UserId!: string;
	readonly LessonId!: string;
	readonly description?: string;
	readonly isCommitted?: boolean;
	readonly grade?: string;
	readonly isEvaluated?: boolean;
	readonly AssignmentUserCommitFiles?: AssignmentUserCommitFileDto[];

	constructor(data: {
		UserId: string; LessonId: string; description?: string; isCommitted?: boolean;
		grade?: string; isEvaluated?: boolean; AssignmentUserCommitFiles?: AssignmentUserCommitFileDto[];[ key: string ]: any
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
