import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import { SIGN_IN_PATH } from "../signIn/index";
import { Layout } from "../../components/layout";

const TRANSLATION_BASE_PATH = "_LOGIN._CHANGE_PASSWORD";
export const PASSWORD_CHANGED_SUCCESSFULLY_PATH = "password-changed";

const PasswordChangedSuccessfully = (props: any) => {
	const { t: translate } = useTranslation();
	const history = useHistory();

	const Component = () => (
		<Row>
			<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
			<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
				<Row>
					<Col span={24}>
						<Icon type="ri-check-line" className="email__verification__icon" />
					</Col>
				</Row>
				<Row className="pt-32">
					<Col span={24}>
						<Text fontSize="30" lineHeight="38" wheight="semibold">
							{translate(`${TRANSLATION_BASE_PATH}._PASSWORD_CHANGED_SUCCESSFULLY`)}
						</Text>
					</Col>
				</Row>
				<Row className="pt-24">
					<Col span={24}>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							className="full_width_button"
							onClick={() => {
								history.push(`/${SIGN_IN_PATH}`);
							}}
						>
							{translate(`${TRANSLATION_BASE_PATH}._LOGIN`)}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);

	const hideCover = props.pageComponents?.loginCover?.status === 0;

	return (
		<>
			{hideCover ? (
				<Component />
			) : (
				<Layout {...props.pageComponents.loginCover?.specs}>
					<Component />
				</Layout>
			)}
		</>
	);
};
export default PasswordChangedSuccessfully;
