import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";

export const formValidatorEmail = (translate: (key: string) => string) =>
	Yup.object({
		ownerEmail: Yup.string()
			.email(translate(`${TRANSLATION_BASE_PATH}._NOT_VALID_EMAIL`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(40, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_40`)),
	});
