import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Button from "common/components/general/Button";
import Alert from "common/components/feedback/Alert";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { formValidator } from "./ValidationSchema";
import { SIGN_IN_PATH } from "../signIn";
import { REGISTER_PATH } from "../register";
import { FORGET_PASSWORD_EMAIL_VERIFICATION_PATH } from "../forgetPasswordEmailVerification";
import AccountEmailService from "services/email/account.email.service";
import OAuth2Service, { RECAPTCHA_KEY } from "services/authentication/oauth2.service";
import { Layout } from "../../components/layout";

export const FORGET_PASSWORD_PATH = "forget-password";
const TRANSLATION_BASE_PATH = "_LOGIN._FORGET_PASWORD";

class ForgetPasswordComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: "",
			notValidEmails: [],
			emailNotRegistered: false
		};

		this.Component = this.Component.bind(this);
	}

	recaptchaRef: any = React.createRef();

	showAlertContent = (translate: (key: string) => string): ReactNode => {
		const emailNotRegisteredMessage = (
			<Text fontSize="14">
				{translate(`${TRANSLATION_BASE_PATH}._EMAIL_NOT_REGISTERED`)}
				<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>{translate(`${TRANSLATION_BASE_PATH}._HERE`)}</Link>
			</Text>
		);
		return this.state.hasError ? (
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="mt-24">
					{this.state.emailNotRegistered ? (
						<Alert message={emailNotRegisteredMessage} type="error" showIcon closable />
					) : (
						<Alert message={this.state.errorMessage} type="error" showIcon closable />
					)}
				</Col>
			</Row>
		) : null;
	};

	onSubmit = async (values: any) => {
		const { t: translate } = this.props;

		if (this.state.notValidEmails.includes(values.email.toLowerCase())) {
			this.setState({
				submitting: false,
				hasError: true,
				errorMessage: translate(`${TRANSLATION_BASE_PATH}._406_API_STATUS_CODE`).replace(
					"{email}",
					values.email
				)
			});
			return;
		}

		this.setState({
			submitting: true
		});

		await OAuth2Service.loginClient()
			.then(async () => {
				return await this.sentForgetPasswordEmail(values.email);
			})
			.catch(error => {
				const errorStatus = error.response?.status ?? "500";
				return this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	sentForgetPasswordEmail = async (email: string) => {
		const { history, t: translate } = this.props;

		const recaptchaValue = await this.recaptchaRef.current.executeAsync();
		this.setState({ emailNotRegistered: false, hasError: false });

		await new AccountEmailService()
			.sentForgetPasswordEmail(email, recaptchaValue)
			.then(() => {
				this.recaptchaRef.current.reset();
				history.push(`/${SIGN_IN_PATH}/${FORGET_PASSWORD_EMAIL_VERIFICATION_PATH}`, {
					email: email
				});
				return;
			})
			.catch(error => {
				this.recaptchaRef.current.reset();
				const errorStatus = error?.status ?? "500";
				if (errorStatus === 406) {
					const notValidEmails = this.state.notValidEmails;
					return this.setState({
						notValidEmails: [...notValidEmails, email.toLowerCase()],
						submitting: false,
						hasError: true,
						errorMessage: translate(`${TRANSLATION_BASE_PATH}._406_API_STATUS_CODE`).replace(
							"{email}",
							email
						)
					});
				} else if (errorStatus === 404) {
					return this.setState({
						submitting: false,
						hasError: true,
						emailNotRegistered: true
					});
				}
				return this.setState({
					submitting: false,
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			});
	};

	Component = () => {
		const { t: translate } = this.props;
		return (
			<>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={{}}
					onSubmit={this.onSubmit}
					isInitialValid={false}
				>
					{formik => (
						<form onSubmit={formik.handleSubmit} autoComplete="off">
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
									<Text fontSize="30" lineHeight="38" wheight="semibold">
										{translate(`${TRANSLATION_BASE_PATH}._FORGET_PASSWORD_TITLE`)}
									</Text>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="mt-24">
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{translate(`${TRANSLATION_BASE_PATH}._FORGET_PASSWORD_DESCRIPTION`)}
									</Text>
								</Col>
							</Row>
							{this.showAlertContent(translate)}
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18} className="mt-24">
									<Input
										name="email"
										label={translate(`${TRANSLATION_BASE_PATH}._EMAIL`)}
										size="large"
									/>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18}>
									<ReCAPTCHA ref={this.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
								</Col>
							</Row>
							<Row gutter={24}>
								<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
								<Col xs={24} sm={24} md={16} lg={24} xl={18}>
									<Button
										type="primary"
										htmlType="submit"
										icon="ri-arrow-right-line"
										size="large"
										iconPosition="right"
										className="full_width_button"
										disabled={!formik.isValid}
										loading={this.state.submitting}
									>
										{translate(`${TRANSLATION_BASE_PATH}._SENT`)}
									</Button>
								</Col>
							</Row>
							<Row gutter={24} justify="center" className="mt-40">
								<Col>
									<Text fontSize="14" lineHeight="22">
										<Link to={`/${SIGN_IN_PATH}`}>
											{translate(`${TRANSLATION_BASE_PATH}._RETURN_AT_SIGNIN`)}
										</Link>
									</Text>
								</Col>
							</Row>
						</form>
					)}
				</Formik>
			</>
		);
	};

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<>
				{hideCover ? (
					this.Component()
				) : (
					<Layout {...this.props.pageComponents.loginCover?.specs}>{this.Component()}</Layout>
				)}
			</>
		);
	}
}

const ForgetPassword = withTranslation()(ForgetPasswordComponent);
export default ForgetPassword;
