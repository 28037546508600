import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_LOGIN._CHANGE_PASSWORD";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		account: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
