import React from "react";
import { Route, Redirect } from "react-router-dom";
import OAuth2Service from "services/authentication/oauth2.service";
import { ON_BOARDING_PATH } from "scenes/onBoarding";
import {REGISTER_EMAIL_VERIFICATION_PATH} from "scenes/onBoarding/scenes/registerEmailVerification";

class ActivatedUserRoute extends Route {
	render() {
		if (OAuth2Service.CurrentUser?.User?.status === "NEW")
			return (
				<Redirect
					to={{
						pathname: `/${ON_BOARDING_PATH}/${REGISTER_EMAIL_VERIFICATION_PATH}`,
						state: { from: this.props.location }
					}}
				/>
			);
		
		if (OAuth2Service.CurrentUser?.User?.status !== "ACTIVE")
			return (
				<Redirect
					to={{
						pathname: `/${ON_BOARDING_PATH}`,
						state: { from: this.props.location }
					}}
				/>
			);
		return <Route {...this.props} />;
	}
}

export default ActivatedUserRoute;
