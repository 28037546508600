import React, { ReactNode } from "react";
import { Radio as AntRadio } from "antd";

interface RadioElementProps {
	value?: any;
	children: ReactNode;
	className?: string;
	onChange?: (e: any) => void;
	showAsBox?: boolean;
	disabled?: boolean;
	withOutStyle?: boolean
}

const RadioElement: React.FC<RadioElementProps> = props => {
	return (
		<AntRadio
			{...props}
			className={`${props.className} ${props.showAsBox
				? (!props.withOutStyle && "radio__box__element") : (!props.withOutStyle && "radio__element")}`}
		>
			{props.children}
		</AntRadio>
	);
};

export default RadioElement;
