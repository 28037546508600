import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import Jimp from "jimp";

import Image from "common/components/general/Image";
import FileDto from "dtos/administration/file.dto";
import { previewFile } from "../../../../../../utils/FileUtils";

interface CoverImagePreviewProps {
	file: FileDto;
}

const transformFileDownload = (imageUrl: any) => {
	return Jimp.read(imageUrl).then(image => {
		return image.getBase64Async(Jimp.MIME_JPEG);
	});
};

export const CoverImagePreview = (props: CoverImagePreviewProps) => {
	const [imagePhoto, setImagePhoto] = useState<string | undefined>(undefined);
	useEffect(() => {
		async function fetchData() {
			const response = await previewFile(props.file);
			const photoAsString = await transformFileDownload(response.url);
			setImagePhoto(photoAsString);
		}
		fetchData();
	}, []);

	return (
		<Skeleton
			active
			loading={!imagePhoto}
			paragraph={{
				rows: 1
			}}
		>
			{imagePhoto ? (
				<div className="quiz__crud_cover_image_preview">
					<Image src={imagePhoto} className="image_preview" />
				</div>
			) : null}
		</Skeleton>
	);
};
