import {BaseEntity} from "../base.model";

export class User extends BaseEntity<User> {
	public email: string;
	public username: string;
	public password: string;
	public firstName: string;
	public lastName: string;
	public dateOfBirth: Date;

	constructor(data: {
		email: string, username: string, password: string, firstName: string,
		lastName: string, dateOfBirth: Date, [ key: string ]: any
	}) {
		const {id, createdAt, updatedAt, deletedAt} = data;
		super({id, createdAt, updatedAt, deletedAt});
		this.email = data.email;
		this.username = data.username;
		this.password = data.password;
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.dateOfBirth = data.dateOfBirth;
	}
}
