import { Space } from "antd";
import React from "react";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import BooksAndStudent from "assets/images/BooksAndStudent.svg";
import { useTranslation } from "react-i18next";

const TRANLSATION_BASE_PATH = "_SUBJECTS._SUBJECT_ITEMS._SUBJECT_LIST";

export const SectionHeader = (props: any) => {
	const { t: translate } = useTranslation();

	const HeaderText = (
		<Space direction="vertical" align="start">
			<Text fontSize="30" lineHeight="38" wheight="semibold">
				{translate(`${TRANLSATION_BASE_PATH}._TITLE`)}
			</Text>
			<Text fontSize="14" lineHeight="22" className="color-gray-8">
				{translate(`${TRANLSATION_BASE_PATH}._DESCRIPTION`)}
			</Text>
		</Space>
	);

	return (
		<Row className="border__bottom pb-16 section-header">
			<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
				<Space direction="horizontal" align="center" className="flex__center_space_between">
					{HeaderText}
					<Image src={BooksAndStudent} />
				</Space>
			</Col>
			<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
				<Row>{HeaderText}</Row>
				<Row justify="center">
					<Image src={BooksAndStudent} />
				</Row>
			</Col>
		</Row>
	);
};
