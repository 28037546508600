import { Alert, Button, Col, Modal, Row } from "antd";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";

import OAuth2Service from "services/authentication/oauth2.service";
import { WelcomeSection } from "./sections/welcomeSection";
import parentDashboardWelcomeImg from "assets/images/parent-dashboard-welcome.svg";
import parentDashboardChildrenImg from "assets/images/parent-dashboard-children.svg";
import { ChildrenSection } from "./sections/childrenSection";
import { showError } from "common/utils/Notification";
import { formValidatorBirthDateChild } from "./addChildValidation/ValidationSchemaBirthDateChild";
import { formValidatorAddChild } from "./addChildValidation/ValidationSchemaAddChild";
import { formValidatorInviteChild } from "./addChildValidation/ValidationSchemaInviteChild";
import Input from "common/components/dataEntry/formik/FormikInputField";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import { FormikGroupDatePickerField } from "../../../login/components/groupBirthDatePicker";
import { ChildRegistrationSuccess } from "./addChildSuccess/childRegistrationSuccess";
import Text from "common/components/general/Text";
import { ChildInvitationSuccess } from "./addChildSuccess/childInvitationSuccess";
import InvitationService from "../../../../services/administration/invitation.service";
import { createParenInviteChildNotification } from "../../../../common/components/_notifications/util/createNotifications";
import { CreateParentInviteChildNotificationDto } from "../../../../common/components/_notifications/dtos/createParentInviteChildNotificationDto";
import { Role } from "services/domain/login/Role";
import UserService from "../../../../services/authentication/user.service";
import { isOver13 } from "../../../../common/utils/isOver13";

const TRANSLATION_BASE_PATH = "_PARENT._DASHBOARD";

export const PARENT_DASHBOARD_PATH = "dashboard";

class ParentDashboardComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			user: null,
			userFullName: "",
			children: null,
			invites: null,
			loading: false,
			birthDateChildModalVisible: false,
			addChildModalVisible: false,
			inviteChildModalVisible: false,
			formSubmitting: false,
			hasError: false,
			errorMessage: "",
			initialBirthDateChildFormValues: {
				birthDate: null
			},
			initialAddChildFormValues: {
				firstName: "",
				lastName: "",
				username: "",
				password: ""
			},
			initialInviteChildFormValues: {
				email: ""
			},
			birthDateChildFormIsValid: false,
			addChildFormIsValid: false,
			inviteChildFormIsValid: false,
			showChildRegSuccess: false,
			showChildInviteSuccess: false,
			childFullname: "",
			childUsername: "",
			childBirthDate: null,
			childEmail: ""
		};
	}

	componentDidMount() {
		const user = OAuth2Service.CurrentUser.User;
		user["id"] = OAuth2Service.CurrentUser.UserId;

		this.setState({
			user: user,
			userFullName: user.firstName + " " + user.lastName,
			loading: true
		});

		return new OAuth2Service()
			.getChildren(user.email)
			.then((result: any) => {
				return this.setState({
					children: result.children.map((item: any) => {
						return {
							...item,
							over13: isOver13(item.dateOfBirth)
						};
					})
				});
			})
			.then(() => {
				return new InvitationService().getParentInvites(user.id);
			})
			.then((result: any) => {
				return this.setState({ invites: result });
			})
			.finally(() =>
				this.setState({
					loading: false
				})
			);
	}

	toggleBirthDateChildModal = () => {
		this.setState({
			birthDateChildModalVisible: !this.state.birthDateChildModalVisible
		});
	};

	toggleAddChildModal = () => {
		this.setState({
			addChildModalVisible: !this.state.addChildModalVisible,
			hasError: false
		});
	};

	toggleInviteChildModal = () => {
		this.setState({
			inviteChildModalVisible: !this.state.inviteChildModalVisible,
			hasError: false
		});
	};

	showAlertContent = (): ReactNode => {
		return this.state.hasError ? (
			<Row>
				<Col span={24} className="mb-24">
					<Alert message={this.state.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	submitBirthDateChildForm = async (values: any) => {
		this.toggleBirthDateChildModal();

		this.setState({ childBirthDate: values.birthDate }, () => {
			isOver13(values.birthDate) ? this.toggleInviteChildModal() : this.toggleAddChildModal();
		});
	};

	submitAddChildForm = async (values: any) => {
		const { t: translate } = this.props;

		this.setState({
			formSubmitting: true,
			hasError: false,
			errorMessage: ""
		});

		const dateOfBirth = moment(this.state.childBirthDate, "YYYY-MM-DD").utc(true);
		const username = values.username;

		const newUserDto = {
			id: uuidv4(),
			email: null,
			parentEmail: this.state.user.email,
			firstName: values.firstName,
			lastName: values.lastName,
			dateOfBirth: dateOfBirth.toDate(),
			password: values.password,
			username: username.replace(" ", ""),
			roleCode: Role.Student,
			recaptchaValue: null
		};

		await new OAuth2Service()
			.signUpFromParent(newUserDto)
			.then(() => {
				const newChildrenList = this.state.children;
				newChildrenList.push({
					id: newUserDto.id,
					email: null,
					firstName: newUserDto.firstName,
					lastName: newUserDto.lastName,
					username: newUserDto.username
				});
				return this.setState({
					hasError: false,
					errorMessage: "",
					showChildRegSuccess: true,
					addChildModalVisible: false,
					childFullname: newUserDto.firstName + " " + newUserDto.lastName,
					childUsername: newUserDto.username,
					children: newChildrenList
				});
			})
			.catch(error => {
				let errorStatus = error?.status ?? "500";
				if (errorStatus === 409) errorStatus = error.data.error.statusCode;
				this.setState({
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._FORM._${errorStatus}_API_STATUS_CODE`)
				});
				if (errorStatus === 406) {
					const notValidEmails = this.state.notValidEmails;
					this.setState({
						notValidEmails: [...notValidEmails, values.email.toLowerCase()]
					});
				}
			})
			.finally(() => {
				this.setState({ formSubmitting: false });
			});
	};

	submitEmailChildForm = async (values: any) => {
		const { t: translate } = this.props;
		const parentId = this.state.user.id;
		const email = values.email.trim();
		const invitePreviouslySent =
			this.state.children.some((x: any) => {
				return x.email === email;
			}) ||
			this.state.invites.some((x: any) => {
				return x.email === email;
			});

		if (invitePreviouslySent || email === this.state.user.email) {
			return this.setState({
				hasError: true,
				errorMessage: translate(`${TRANSLATION_BASE_PATH}._FORM._INVITATION_IS_SENT`)
			});
		}

		this.setState({
			formSubmitting: true,
			hasError: false,
			errorMessage: ""
		});

		return new UserService()
			.findUserByEmail(email)
			.then((user: any) => {
				if (user && user.Roles.some((x: any) => x.code !== "_STUDENT")) {
					this.setState({
						hasError: true,
						errorMessage: translate(`${TRANSLATION_BASE_PATH}._FORM._EXISTS_BUT_NOT_STUDENT`)
					});
					return;
				}
				if (user && user.parentEmail) {
					this.setState({
						hasError: true,
						errorMessage: translate(`${TRANSLATION_BASE_PATH}._FORM._HAS_PARENT`)
					});
					return;
				}
				return new InvitationService().inviteChild(parentId, email, this.state.childBirthDate);
			})
			.then((invitation: any) => {
				if (invitation) {
					this.toggleInviteChildModal();
					const newInvitesList = this.state.invites;
					newInvitesList.push({
						id: invitation.id,
						email: invitation.email,
						invitedOn: invitation.invitedOn
					});
					this.setState({
						childEmail: email,
						showChildInviteSuccess: true,
						invites: newInvitesList
					});
					createParenInviteChildNotification(
						new CreateParentInviteChildNotificationDto(
							invitation.email,
							this.state.user.email,
							invitation.id,
							this.state.user
						)
					);
				}
				return;
			})
			.catch((error: any) => {
				let errorStatus = error?.status ?? "500";
				if (errorStatus === 409) errorStatus = error.data.error.statusCode;
				this.setState({
					hasError: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._FORM._${errorStatus}_API_STATUS_CODE`)
				});
			})
			.finally(() => {
				this.setState({ formSubmitting: false });
			});
	};

	setIsBirthDateChildFormValid = (value: any) => {
		this.setState({ birthDateChildFormIsValid: value });
	};

	setIsAddChildFormValid = (value: any) => {
		this.setState({ addChildFormIsValid: value });
	};

	setIsInviteChildFormValid = (value: any) => {
		this.setState({ inviteChildFormIsValid: value });
	};

	getBirthDateChildModalForm = () => {
		const { t: translate } = this.props;
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._FORM._TITLE`);
		const formBtnContinue = translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_CONTINUE`);
		const formBtnCancel = translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_CANCEL`);

		let submitFormHandler: () => Promise<any>;

		return (
			<Modal
				title={formTitle}
				visible={this.state.birthDateChildModalVisible}
				destroyOnClose={true}
				onCancel={this.toggleBirthDateChildModal}
				footer={[<Button key="back" onClick={this.toggleBirthDateChildModal}>
					{formBtnCancel}
				</Button>,
					<Button
						key="submit"
						type="primary"
						form="birthDateForm"
						loading={this.state.formSubmitting}
						htmlType="submit"
						disabled={!this.state.birthDateChildFormIsValid}
						onClick={() => submitFormHandler}
					>
						{formBtnContinue}
					</Button>]}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorBirthDateChild(translate)}
					validate={values => {
						this.setIsBirthDateChildFormValid(formValidatorBirthDateChild(translate).isValidSync(values));
						return {};
					}}
					initialValues={this.state.initialBirthDateChildFormValues}
					onSubmit={this.submitBirthDateChildForm}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} id="birthDateForm" autoComplete="off">
								<Row gutter={24}>
									<Col span="24">
										<FormikGroupDatePickerField
											name="birthDate"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._BIRTH_DATE`)}
										/>
									</Col>
								</Row>
								{this.showAlertContent()}
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	};

	deleteInvite = (invitationId: string) => {
		const { t: translate } = this.props;

		new InvitationService()
			.deleteInvite(invitationId)
			.then(() => {
				const newInvitesList = this.state.invites.filter((x: any) => x.id !== invitationId);
				return this.setState({
					invites: newInvitesList
				});
			})
			.catch(error => {
				console.log(error);
				const errorStatus = error?.status ?? "500";
				showError(translate(`_GENERAL._LOGIN._REGISTER._${errorStatus}_API_STATUS_CODE`));
			});
	};

	resendInvite = (invite: any) => {
		return createParenInviteChildNotification(
			new CreateParentInviteChildNotificationDto(invite.email, this.state.user.email, invite.id, this.state.user)
		).then(() => {
			return this.setState({
				childEmail: invite.email,
				showChildInviteSuccess: true
			});
		});
	};

	getAddChildModalForm = () => {
		const { t: translate } = this.props;
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._FORM._TITLE`);
		const formBtnRegister = translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_REGISTER`);
		const formBtnCancel = translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_CANCEL`);

		let submitFormHandler: () => Promise<any>;

		return (
			<Modal
				title={formTitle}
				visible={this.state.addChildModalVisible}
				destroyOnClose={true}
				onCancel={this.toggleAddChildModal}
				footer={[<Button key="back" onClick={this.toggleAddChildModal}>
					{formBtnCancel}
				</Button>,
					<Button
						key="submit"
						type="primary"
						form="regForm"
						loading={this.state.formSubmitting}
						htmlType="submit"
						disabled={!this.state.addChildFormIsValid}
						onClick={() => submitFormHandler}
					>
						{formBtnRegister}
					</Button>]}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorAddChild(translate)}
					validate={values => {
						this.setIsAddChildFormValid(formValidatorAddChild(translate).isValidSync(values));
						return {};
					}}
					initialValues={this.state.initialAddChildFormValues}
					onSubmit={this.submitAddChildForm}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} id="regForm" autoComplete="off">
								<Row justify="space-between">
									<Col xs={24} sm={11} md={11} lg={11} xl={11}>
										<Input
											name="firstName"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._FIRST_NAME`)}
											size="large"
										/>
									</Col>
									<Col xs={24} sm={11} md={11} lg={11} xl={11}>
										<Input
											name="lastName"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._LAST_NAME`)}
											size="large"
										/>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span="24">
										<Input
											name="username"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._USERNAME`)}
											hint={translate(`${TRANSLATION_BASE_PATH}._FORM._USERNAME_HINT`)}
											placeholder={translate(
												`${TRANSLATION_BASE_PATH}._FORM._USERNAME_PLACEHOLDER`
											)}
											size="large"
											autoComplete="username"
										/>
									</Col>
								</Row>
								{this.showAlertContent()}
								<Row gutter={24}>
									<Col span="24">
										<PasswordInput
											name="password"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._PASSWORD`)}
											showPopover={true}
											size="large"
											autoComplete="new-password"
										/>
									</Col>
								</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	};

	getInviteChildModalForm = () => {
		const { t: translate } = this.props;
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._FORM._TITLE`);
		const formBtnInvite = translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_INVITE`);
		const formBtnCancel = translate(`${TRANSLATION_BASE_PATH}._FORM._BTN_CANCEL`);

		let submitFormHandler: () => Promise<any>;

		return (
			<Modal
				title={formTitle}
				visible={this.state.inviteChildModalVisible}
				destroyOnClose={true}
				onCancel={this.toggleInviteChildModal}
				footer={[<Button key="back" onClick={this.toggleInviteChildModal}>
					{formBtnCancel}
				</Button>,
					<Button
						key="submit"
						type="primary"
						form="inviteForm"
						loading={this.state.formSubmitting}
						htmlType="submit"
						disabled={!this.state.inviteChildFormIsValid}
						onClick={() => submitFormHandler}
					>
						{formBtnInvite}
					</Button>]}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorInviteChild(translate)}
					validate={values => {
						this.setIsInviteChildFormValid(formValidatorInviteChild(translate).isValidSync(values));
						return {};
					}}
					initialValues={this.state.initialInviteChildFormValues}
					onSubmit={this.submitEmailChildForm}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} id="inviteForm" autoComplete="off">
								<Row gutter={24} className="pb-24">
									<Col span="24">
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._FORM._EMAIL_FROM_TITLE`)}
										</Text>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span="24">
										<Input
											name="email"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._EMAIL`)}
											placeholder={translate(`${TRANSLATION_BASE_PATH}._FORM._EMAIL_PLACEHOLDER`)}
											size="large"
											autoComplete="email"
										/>
									</Col>
								</Row>
								{this.showAlertContent()}
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	};

	render() {
		return (
			<>
				<Row className="pt-16 pr-16 pl-16 pb-0" justify="center">
					<WelcomeSection
						parentFullName={this.state.userFullName}
						fullDate={moment().format("dddd, D MMMM YYYY")}
						welcomeImage={parentDashboardWelcomeImg}
					/>
				</Row>
				<Row className="p-16" justify="center">
					<ChildrenSection
						childImage={parentDashboardChildrenImg}
						childrenItems={this.state.children}
						invites={this.state.invites}
						parent={this.state.user}
						showAddChildModal={this.toggleBirthDateChildModal}
						loading={this.state.loading}
						deleteInvite={this.deleteInvite}
						resendInvite={this.resendInvite}
					/>
				</Row>
				{this.getBirthDateChildModalForm()}
				{this.getAddChildModalForm()}
				{this.getInviteChildModalForm()}
				<ChildRegistrationSuccess
					visible={this.state.showChildRegSuccess}
					cancel={() => this.setState({ showChildRegSuccess: false })}
					childFullname={this.state.childFullname}
					childUsername={this.state.childUsername}
				/>
				<ChildInvitationSuccess
					visible={this.state.showChildInviteSuccess}
					cancel={() => this.setState({ showChildInviteSuccess: false })}
					email={this.state.childEmail}
				/>
			</>
		);
	}
}

const ParentDashboard = withTranslation()(ParentDashboardComponent);
export default ParentDashboard;
