import React from "react";
import { Tooltip } from "antd";

export const TextOverflowTooltip = ({ children, className }: { className: string; children: any }) => {
	const textRef = React.useRef<HTMLParagraphElement>(null);
	const hasEllipsis = () => (textRef.current ? textRef.current.offsetWidth < textRef.current.scrollWidth : false);

	const textTooltip = hasEllipsis() ? children : "";
	return (
		<>
			<Tooltip title={textTooltip}>
				<p className={className} ref={textRef}>
					{children}
				</p>
			</Tooltip>
		</>
	);
};
