import ApiServiceData from "../api.service";
const relativeUrl = "/v1/api/marketplace";
	
type Data = {
	name: string,
	job: string,
	email: string,
	description?: string,
	cv?: string,
	filename?: string,
	organizationId?: string
}

export default class MarketplaceEmailService extends ApiServiceData {
	public sendApplyAsInstructorEmail(data: Data): Promise<string> {
		return this.publicPost(`${relativeUrl}/apply-as-instructor`, {
			...data
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
