import React, { Component } from "react";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import { formValidator } from "./ValidationSchema";

const TRANSLATION_BASE_PATH = "_LOGIN._CHANGE_PASSWORD";

class ChooseAccountComponent extends Component<any, any> {
	onSubmit = async (values: any) => {
		this.props.onAccountSelect(this.props.accounts.find((item: any) => item.id === values.account));
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={{}}
				onSubmit={this.onSubmit}
				isInitialValid={false}
			>
				{formik => (
					<form onSubmit={formik.handleSubmit} autoComplete="off">
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
								<Text fontSize="30" lineHeight="38" wheight="semibold">
									{translate(`${TRANSLATION_BASE_PATH}._CHANGE_PASSWORD_TITLE`)}
								</Text>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18} className="pt-24">
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_ACCOUNT`)}
								</Text>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18} className="mt-24">
								<Radio name="account">
									<Row gutter={[8, 8]}>
										{this.props.accounts.map((account: any, index: number) => {
											return (
												<Col span={24} key={index}>
													<RadioElement value={account.id}>
														{account.firstName} {account.lastName}{" "}
														{account.email === null ? "(" + account.username + ")" : ""}
													</RadioElement>
												</Col>
											);
										})}
									</Row>
								</Radio>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
							<Col xs={24} sm={24} md={16} lg={24} xl={18}>
								<Button
									type="primary"
									htmlType="submit"
									icon="ri-arrow-right-line"
									size="large"
									iconPosition="right"
									className="full_width_button"
									disabled={!formik.isValid}
								>
									{translate(`${TRANSLATION_BASE_PATH}._CONTINUE`)}
								</Button>
							</Col>
						</Row>
					</form>
				)}
			</Formik>
		);
	}
}

const ChooseAccount = withTranslation()(ChooseAccountComponent);
export default ChooseAccount;
