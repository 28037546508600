import moment from "moment";
import * as Yup from "yup";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._LESSON_MATERIAL";

export const formValidator = (translate: (key: string) => string) => {
	return Yup.object({
		name: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		description: Yup.string().nullable(),
		endDate: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.trim()
			.test("is-valid-date", translate(`${TRANSLATION_BASE_PATH}._DUE_DATE_IN_THE_PAST`), function(value) {
				return (
					value === undefined ||
					(moment(value, "YYYY-MM-DD HH:mm").isValid() === true &&
						moment(value, "YYYY-MM-DD HH:mm") > moment())
				);
			})
	});
};
