/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

import ApiServiceData from "../api.service";
import { FeedDto } from "../../dtos/administration/feed.dto";
const relativeUrl = "/v1/api/feeds";

export default class FeedService extends ApiServiceData {
	public addOrUpdate(feed: FeedDto) {
		if (feed.id && !feed.ParentId) {
			return this.update(`${relativeUrl}/${feed.id}`, feed);
		}
		return this.post(relativeUrl, feed);
	}

	public remove(feedId?: string) {
		return this.delete(`${relativeUrl}/${feedId}`);
	}

	public getAllByClassId(classroomId: string) {
		return this.get(`${relativeUrl}/${classroomId}`);
	}

	public getAllByClassIdByOffset(classroomId: string, offset: number, limit: number) {
		return this.get(`${relativeUrl}/${classroomId}/${offset}/${limit}`);
	}
}
