import React, { ReactNode } from "react";
import { Layout as AntLayout } from "antd";
import { Header } from "common/components/_header1/Header1";
import Col from "common/components/layout/Col";
import Row from "common/components/layout/Row";

interface LayoutProps {
	children: ReactNode;
}

export const Layout = (props: LayoutProps) => {
	return (
		<AntLayout className="onboarding__layout">
			<Header />
			<Row className="layout__content">
				<Col className="onboarding__form__content">{props.children}</Col>
			</Row>
		</AntLayout>
	);
};
