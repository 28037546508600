const SIGN_IN_PATH = "login";
const REGISTER_PATH = "register";

export const loggedOutMenuLinks = [
	{
		key: "1",
		icon: "ri-question-line",
		name: "_MAIN._MENU_HELP",
		path: "https://akademial.tawk.help/",
		redirectToQuestionPage: true
	},
	{
		key: "2",
		icon: "ri-login-box-line",
		name: "_LOGIN._SIGNIN._SIGNIN_LABEL",
		path: `/${SIGN_IN_PATH}`,
	},
	{
		key: "3",
		icon: "ri-login-box-fill",
		name: "_LOGIN._REGISTER._REGISTER_LABEL",
		path: `/${SIGN_IN_PATH}/${REGISTER_PATH}`,
	}
]