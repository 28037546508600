import { EnrollmentStatus } from "../../../../services/domain/administration/EnrollmentStatus";

export class CreateEnrollmentChangeNotificationDto{
	enrollment!: any;
	classroom!: any;
	newStatus!: EnrollmentStatus;
	readonly type? = "ENROLLMENT_STATUS_CHANGE";

	constructor(enrollment: any, classroom: any, status: EnrollmentStatus) {
		this.enrollment = enrollment;
		this.classroom = classroom;
		this.newStatus = status;
	}

	get navigateUrl() {
		return `course/_student/${this.classroom.id}`;
	}

	get title(){
		if (this.enrollment.status === "PENDING" && this.newStatus === "ACTIVE") {
			return `Hyrja juaj në klasën "${this.classroom.name}" u aprovua.`;
		}
		if (this.enrollment.status === "PENDING" && this.newStatus === "CANCELLED") {
			return `Kërkesa juaj ne klasën "${this.classroom.name}" u refuzua.`;
		}
		if (this.enrollment.status === "ACTIVE" && this.newStatus === "BLOCKED") {
			return `Hyrja juaj në klasën "${this.classroom.name}" u bllokua.`;
		}
		if (this.enrollment.status === "BLOCKED" && this.newStatus === "ACTIVE") {
			return `Hyrja juaj ne klasën "${this.classroom.name}" u zhbllokua.`;
		}
		if (this.enrollment.status === "ACTIVE" && this.newStatus === "CANCELLED") {
			return `Ju u çregjistruat nga klasa "${this.classroom.name}".`;
		}
		return `Aksesi juaj ne klasen "${this.classroom.name}" u ndryshua.`;
	};
}