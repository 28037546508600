import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import Jimp from "jimp";
import moment from "moment";

import { previewFile } from "../../../scenes/courseBuilder/utils/FileUtils";
import UserSettingsService from "services/administration/userSettings.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

interface UserAvatarProps {
	userId: string;
	firstName: string;
	lastName: string;
	className?: string;
}

export const UserAvatar = (props: UserAvatarProps) => {
	const [avatarDetails, setAvatarDetails] = useState<any>(undefined);
	const [loading, setLoading] = useState<boolean>(true);
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;

	const transformFileDownload = async (imageUrl: any) => {
		return Jimp.read(imageUrl).then(image => {
			return image.getBase64Async(Jimp.MIME_JPEG);
		});
	};

	const getImage = async (file: any) => {
		const response = await previewFile(file);
		let users: any[] = JSON.parse(localStorage.getItem("users-avatars") ?? "[]");
		const userAvatarIndex = users.findIndex(item => item.id === props.userId);
		if (userAvatarIndex === -1) {
			const base64 = await transformFileDownload(response.url);
			users = [
				...users,
				{
					...avatarDetails,
					base64: base64,
					lastUpdate: moment(),
					id: props.userId
				}
			];
		} else if (
			(users[userAvatarIndex].id === currentUserInfo?.UserId &&
				moment(users[userAvatarIndex].lastUpdate) < moment().add(-1, "minutes")) ||
			moment(users[userAvatarIndex].lastUpdate) < moment().add(-5, "minutes")
		) {
			const base64 = await transformFileDownload(response.url);
			users[userAvatarIndex] = {
				...avatarDetails,
				base64: base64,
				lastUpdate: moment(),
				id: props.userId
			};
		}
		localStorage.setItem("users-avatars", JSON.stringify([...users]));
		setLoading(false);
	};

	useEffect(() => {
		if (props.userId) {
			new UserSettingsService()
				.getAvatar(props.userId)
				.then(result => {
					return setAvatarDetails(result);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}, [props.userId]);

	useEffect(() => {
		if (
			avatarDetails !== undefined &&
			avatarDetails.profilePicture &&
			avatarDetails.profilePictureType &&
			avatarDetails.profilePictureType === "upload" &&
			avatarDetails.profilePictureFile
		) {
			getImage(avatarDetails.profilePictureFile);
		} else if (avatarDetails !== undefined) {
			let users: any[] = JSON.parse(localStorage.getItem("users-avatars") ?? "[]");
			const userAvatarIndex = users.findIndex(item => item.id === props.userId);
			if (userAvatarIndex === -1) {
				users = [
					...users,
					{
						...avatarDetails,
						lastUpdate: moment(),
						id: props.userId
					}
				];
			} else if (
				(users[userAvatarIndex].id === currentUserInfo?.UserId &&
					moment(users[userAvatarIndex].lastUpdate) < moment().add(-1, "minutes")) ||
				moment(users[userAvatarIndex].lastUpdate) < moment().add(-5, "minutes")
			) {
				users[userAvatarIndex] = {
					...avatarDetails,
					lastUpdate: moment(),
					id: props.userId
				};
			}
			localStorage.setItem("users-avatars", JSON.stringify([...users]));
			setLoading(false);
		}
	}, [avatarDetails]);

	const getAvatar = () => {
		if (loading) return null;
		const users: any[] = JSON.parse(localStorage.getItem("users-avatars") ?? "[]");
		const userAvatar = users.find((item: any) => item.id === props.userId);
		if (!userAvatar) {
			return <Avatar shape="square">{props.firstName.charAt(0) + props.lastName.charAt(0)}</Avatar>;
		} else if (
			userAvatar.profilePicture &&
			userAvatar.profilePictureType &&
			userAvatar.profilePictureType === "static" &&
			userAvatar.profilePicture.indexOf(".") > -1
		) {
			return (
				<Avatar
					shape="square"
					src={require(`assets/images/avatars/${userAvatar.profilePicture}`)}
					className={`${props.className} static__profile_avatar`}
				/>
			);
		} else if (
			userAvatar.profilePicture &&
			userAvatar.profilePicture.indexOf(".") < 0 &&
			userAvatar.profilePictureType &&
			userAvatar.profilePictureType === "upload" &&
			userAvatar.base64
		) {
			return <Avatar shape="square" src={userAvatar.base64}
				className={`${props.className} upload__profile_avatar`} />;
		}

		return <Avatar shape="square"
			className={`${props.className} upload__profile_avatar`}>{props.firstName.charAt(0) + props.lastName.charAt(0)}</Avatar>;
	};

	return <>{getAvatar()}</>;
};
