import React, { useState } from "react";
import { InputProps } from "../../utils/types";
import { ChromePicker } from "react-color";
import FormikInputField from "common/components/dataEntry/formik/FormikInputField";
import { useFormikContext } from "formik";

export function Color(props: InputProps) {
	const [inputVal, setInputVal] = useState<string>(props.value);
	const { setFieldValue } = useFormikContext();

	function handleChange(color: string | null) {

		if (!color) {
			setInputVal("");
			setFieldValue(props.name, "");
			return;
		}
		setInputVal(color);
		setFieldValue(props.name, color);
	}

	return (
		<>
			<FormikInputField
				onKeyUp={e => handleChange(e.currentTarget.value)}
				className="mb-8"
				label={props.title}
				name={props.name}
				defaultValue={inputVal}
			/>
			<ChromePicker color={inputVal} onChangeComplete={e => handleChange(e.hex)} />
		</>
	);
}
