import { Button, Col, Divider, Popconfirm, Row, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "common/components/general/UserAvatar";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import OAuth2Service from "services/authentication/oauth2.service";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { PARENT_CHILD_SETTINGS_PATH } from "../../scenes/childSettings";
import UserService from "services/authentication/user.service";
import { showError, showMessage } from "common/utils/Notification";

const TRANSLATION_BASE_PATH = "_PARENT._COMPONENTS._SECTION_HEADER";

export const SectionHeader = (props: any) => {
	const child = props.child;
	const history = useHistory();
	const { t: translate } = useTranslation();

	const [unLinking, setUnliniking] = useState(false);

	const ChildFullName = (child: any) => {
		return (
			<Text fontSize="24" lineHeight="32" className="color-gray-9" wheight="semibold">
				{child.firstName} {" "} {child.lastName}
			</Text>
		);
	};

	const ChildEmail = (child: any)=> {
		return (
			<Space>
				<Icon type="ri-mail-line"/>
				<Text fontSize="14" lineHeight="22" className="color-gray-8" >
					{child.email ?? OAuth2Service.CurrentUser.User.email}
				</Text>
			</Space>
		);
	};

	const ChildUsername = (child: any) => {
		return (
			<Space>
				<Icon type="ri-account-pin-box-line"/>
				<Text fontSize="14" lineHeight="22" className="color-gray-8" >
					{child.username}
				</Text>
			</Space>
		);
	};

	const RegisteredOn = (child: any) => {
		return (
			<Text fontSize="12" lineHeight="20" className="color-gray-8" >
				{translate(`${TRANSLATION_BASE_PATH}._REGISTERED_ON`,
					{ date: moment(child.createdAt).format("D MMMM YYYY") })}
			</Text>
		);
	};

	const UnlinkDisabled = (child: any) => {
		return !child.over13;
	};

	const SubmitUnlink = (child: any) => {
		setUnliniking(true);
		return new UserService().unlinkChildFromParent(child.id)
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._UNLINK_SUCCESS`));
				return history.goBack();
			})
			.catch(error => {
				console.log(error);
				return showError(translate(`${TRANSLATION_BASE_PATH}._UNLINK_ERROR`));
			})
			.finally(() => {
				setUnliniking(false);
			});
	};

	const UnLink = (child: any) => {
		const { t: translate } = useTranslation();
		const buttonClass = UnlinkDisabled(child) ? "color-gray-6" : "color-red-6";
		const buttonDisabled = UnlinkDisabled(child) || unLinking;
		return (
			<Space>
				<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._TOOLTIP_UNLINK`)}>
					<a>
						<Icon fontSize="20" type="ri-information-line" className="color-blue-6" />
					</a>
				</Tooltip>
				<Popconfirm
					title={translate(`${TRANSLATION_BASE_PATH}._POPCONFIRM._CONFIRM_MESSAGE`)}
					onConfirm={() => SubmitUnlink(child)}
					okText={translate(`${TRANSLATION_BASE_PATH}._POPCONFIRM._BTN_OK`)}
					cancelText={translate(`${TRANSLATION_BASE_PATH}._POPCONFIRM._BTN_CANCEL`)}
					disabled={buttonDisabled}
				>
					<Button
						size="small"
						disabled={buttonDisabled}
						loading={unLinking}
					> 
						<Space>
							<Icon type="ri-user-unfollow-line" className={ buttonClass}/>
							<Text fontSize="14" lineHeight="22" className={buttonClass} >
								{translate(`${TRANSLATION_BASE_PATH}._BTN_UNLINK`)}
							</Text>
						</Space>
					</Button>
				</Popconfirm>
			</Space>
		);
	};

	const ConfigurationLink = (child: any) => {
		const { t: translate } = useTranslation();
		return (
			<Link to={`../${PARENT_CHILD_SETTINGS_PATH}/${child.id}`}>
				<Button size="small"> 
					<Space>
						<Icon type="ri-settings-4-line" className="color-gray-8"/>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._BTN_GO_TO_CONFIG`)}
						</Text>
					</Space>
				</Button>
			</Link>
		);
	}; 

	const ShowConfigurationBtn = (showConfigBtn: any, child: any) => {
		return showConfigBtn && !child.over13;
	};

	const ChildAvatar = (child: any) => {
		return (
			<>
				<Col xs={0} sm={7} md={5} lg={6} xl={6} xxl={4}>
					<UserAvatar {...child} userId={child.id}
						className={"settings__my_profile_static_avatar"}/>
				</Col>
				<Col xs={4} sm={0} md={0} lg={0} xl={0} xxl={0}>
					<UserAvatar {...child} userId={child.id}/>
				</Col>	
			</>
		);
	};	

	const ChildDetails = (child: any) => {
		return (
			<Space direction="vertical">
				{ChildFullName(child)}
				{ChildEmail(child)}
				{ChildUsername(child)}
				{RegisteredOn(child)}
			</Space>
		)
	}

	return (
		<>	
			<Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
				<Space>
					{ChildAvatar(child)}
					{ChildDetails(child)}
				</Space>
			</Col>
			<Col xs={0} sm={0} md={10} lg={10} xl={10} xxl={10}>
				<Row justify="end">
					<Space>
						{UnLink(child)}
						{ShowConfigurationBtn(props.showConfigBtn, child) && ConfigurationLink(child)}
					</Space>
				</Row>
			</Col>
			<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
				<Divider />
				<Row>
					<Col xs={24} sm={11} md={0} lg={0} xl={0} xxl={0}>
						<Row justify="center">
							{UnLink(child)}
						</Row>
					</Col>
					{ShowConfigurationBtn(props.showConfigBtn, child) && (
						<Col xs={24} sm={11} md={0} lg={0} xl={0} xxl={0}>
							<Row justify="center">
								{ConfigurationLink(child)}
							</Row>
						</Col>
					)}
				</Row>
			</Col>
		</>
	);
};