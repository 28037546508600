import { Tooltip } from "antd";
import React from "react";
import LiveIcon from "assets/images/icons/marketplace-live-icon.svg";
import Image from "common/components/general/Image";

export const LiveClassIcon = () => {
	return (
		<Tooltip title={"Kurs live"}>
			<Image src={LiveIcon} />
		</Tooltip>
	);
};
