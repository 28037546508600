import { Uploader, UploadImageType } from "common/components/uploader/Uploader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OrganizationService from "../../../../../../services/administration/organization.service";
import Image from "common/components/general/Image";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Button from "common/components/general/Button";
import OrganizationDto from "../../../../../../dtos/administration/organization.dto";
import { getPublicFileUrl } from "../../../../../courseBuilder/utils/FileUtils";

export interface DesignFilesInterface {
	organizationId: string;
	domain: string;
	default: boolean;
	onUpload: any;
	onDelete: any;
}

export function MarketplaceUpload(props: DesignFilesInterface) {
	const { t: translate } = useTranslation();
	const [marketplaceLogo, setMarketplaceLogo] = useState<string>("");

	// Get the design list and the public orgs for the order number info
	useEffect(() => {
		const loadOrganizationProfile = () => {
			return new OrganizationService().findOrganizationById(props.organizationId).then((organization: OrganizationDto) => {
				return setMarketplaceLogo(getPublicFileUrl(organization.marketplaceLogo));
			}).catch(() => {
				return setMarketplaceLogo("");
			});
		};
		loadOrganizationProfile();
	}, []);

	/**
	 * Save file value in the server
	 * @param fileUploaded file
	 * @param type state name to store
	 */
	const uploadFile = async (fileUploaded: any) => {
		if (fileUploaded) {
			props.onUpload(`${fileUploaded.filePath}/${fileUploaded.name}`);
			setMarketplaceLogo(getPublicFileUrl(`${fileUploaded.filePath}/${fileUploaded.name}`));
		}

		return !!fileUploaded;
	};

	/**
	 * Delete the option name
	 */
	const deleteFile = async () => {
		props.onDelete();
		setMarketplaceLogo("");
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "start",
				maxWidth: "250px"
			}}
		>
			<Uploader
				fileType="IMAGE"
				uploadImageType={UploadImageType.Avatar}
				filePath={`public-files/organization/${props.organizationId}/profile`}
				fileWithUniqueId={true}
				onUploadingDone={(fileUploaded: any) => uploadFile(fileUploaded)}
				showUploadList={false}
				onRemoveUpload={() => deleteFile()}
			>
				{marketplaceLogo ? (
					<div className="onboarding__upload_avatar_preview_wrapper">
						<Image src={marketplaceLogo} />
					</div>
				) : (
					<div
						className="ant-upload ant-upload-select ant-upload-select-picture-card"
						style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
					>
						<div style={{ display: "block" }}>
							<PlusOutlined />
							<div style={{ marginTop: 8 }}>{translate("_MARKETPLACE._UPLOAD")}</div>
						</div>
					</div>
				)}
			</Uploader>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					marginLeft: "8px"
				}}
			>
				{marketplaceLogo && (
					<>
						<p style={{ margin: 0, color: "#98A9C0" }}>{translate("_MARKETPLACE._IMAGE_UPLOADED")}</p>
						<StyledButton type="link" onClick={() => deleteFile()}>
							{translate("_MARKETPLACE._REMOVE")}
						</StyledButton>
					</>
				)}
			</div>
		</div>
	);
}

const StyledButton = styled(Button)`
	padding: 0 !important;
`;
