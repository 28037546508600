import React from "react";
import { Link } from "react-router-dom";
import { Card } from "antd";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";

import styled from "styled-components";

import { TextOverflowTooltip } from "./TextOverflowTooltip";

import { LiveClassIcon } from "./_courseView/components/LiveClassIcon";
import { getFileUrl } from "../courseBuilder/utils/FileUtils";
import DefaultImg from "assets/images/main-classes-img1.svg";

const NAVIGATE_URL = "/subjects/course/";

export const CourseItem = (item: any) => {
	const imageUrl = item.File ? getFileUrl(item.File.id) : DefaultImg

	return (
		<StyledCard hoverable={true} className="box_shadow course-card" bordered={false} bodyStyle={{ padding: "0" }}>
			<Link to={NAVIGATE_URL + item.id}>
				<Image className="marketplace__classSlider__item__card__image" src={imageUrl} />

				<div className="body-wrapper">
					{!item.isAsync && (
						<div className="live-course-indicator">
							<LiveClassIcon />
						</div>
					)}

					<TextOverflowTooltip className="course-title">{item.name}</TextOverflowTooltip>

					<Text fontSize="12" lineHeight="20" className="marketplace__organization__name">
						{item.Organization?.name}
					</Text>

					<div className="price-tag">
						<Icon type="ri-price-tag-3-line" />
						<p>{item.price > 0 ? "€ " + item.price : "Falas"}</p>
					</div>
				</div>

				{/* <Row className="mt-8 ml-16">
					<Col span={24}>
						<Space direction="horizontal" size={4} align="center">
							{this.props.showEnrolledUsers && item.TotalEnrollments >= 10 ? (
								<>
									<Icon type="ri-user-line" className="color-gray-8" fontSize="14" />
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{item.TotalEnrollments}
									</Text>
								</>
							) : (
								""
							)}
						</Space>
					</Col>
				</Row> */}
			</Link>
		</StyledCard>
	);
};

const StyledCard = styled(Card)`
	.body-wrapper {
		padding: 16px 12px 10px 12px;
		position: relative;

		.live-course-indicator {
			position: absolute;
			top: -11px;
			right: 8px;
		}

		.course-title {
			font-family: Inter;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #2d3748;
		}

		.price-tag {
			display: flex;
			column-gap: 5px;
			font-family: Inter;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #2063e3;

			margin-top: 8px;
		}
	}
`;
