/* eslint-disable array-bracket-newline */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Row, Empty, Space, Popover, Skeleton, Col, Button } from "antd";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import Input from "common/components/dataEntry/formik/FormikInputField";
import Image from "common/components/general/Image";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import { showMessage } from "common/utils/Notification";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import RecordActions from "../../components/recordActions/RecordActions";
import { formValidator } from "./ValidationSchema";
import { CircularIcon } from "common/components/general/CircularIcon";
import SubjectService from "services/administration/subject.service";
import SubjectDto from "../../../../dtos/administration/subject.dto";
import { SearchUtil } from "../../util/SearchData";
import { ResponsiveSearchBox } from "../../components/searchBox/ResponsiveSearchBox";
import RiIconExample from "assets/images/ri-icon-example.svg";
import { SectionContent } from "../../components/sectionContent/SectionContent";
import { AdminTable } from "../../components/table/table";
import { DASHBOARD_PATH } from "../dashboard";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

export const SUBJECTS_PATH = "subjects";
const TRANSLATION_BASE_PATH = "_ADMIN._SUBJECTS";

interface Subject {
	id: string;
	name: string;
	icon: string;
	externalUrl?: string;
	description: string;
}

class SubjectsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			loading: true,
			message: "",
			subjects: [],
			drawerShown: false,
			formInitialValues: {
				id: null,
				name: "",
				icon: "",
				description: "",
				externalUrl: null
			}
		};
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.loadItemAndShowDrawer = this.loadItemAndShowDrawer.bind(this);
	}

	async componentDidMount() {
		this.loadData();
	}

	toggleDrawer = () => {
		this.setState({
			drawerShown: !this.state.drawerShown
		});
	};

	loadData = async () => {
		return new SubjectService()
			.getAllForAdmin()
			.then(result => {
				return this.setState({
					subjects: result,
					filteredData: _.cloneDeep(result)
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				});
			});
	};

	loadItemAndShowDrawer = (item?: Subject) => {
		this.setState(
			{
				isCreate: !item,
				formInitialValues: {
					id: item ? item?.id : uuidv4(),
					name: item ? item?.name : "",
					icon: item ? item?.icon : "",
					description: item ? item?.description : "",
					externalUrl: item ? item?.externalUrl : ""
				}
			},
			() => {
				this.toggleDrawer();
			}
		);
	};

	onSubmit = async (values: SubjectDto) => {
		const { t: translate } = this.props;

		this.setState({
			submitting: true
		});

		const subjectService = this.state.isCreate
			? new SubjectService().createSubject(values)
			: new SubjectService().updateSubject(values.id, values);

		return subjectService
			.then((result: any) => {
				showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
				return this.loadData();
			})
			.finally(() => {
				this.setState({
					submitting: false
				});
				this.toggleDrawer();
			});
	};

	search = (value: string) => {
		this.setState({
			filteredData: SearchUtil(this.state.subjects, value, {
				column1: "name"
			})
		});
	};

	removeSubject = (subjectId: string) => {
		const { t: translate } = this.props;
		this.setState({ loading: true });
		return new SubjectService().removeSubject(subjectId).then(result => {
			if (result === 1) {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._SUBJECT_DELETED`));
				return this.loadData();
			}
			return;
		});
	};

	render() {
		const { t: translate } = this.props;

		const title = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_2");
		const subTitle = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const btnTitle = translate(`${TRANSLATION_BASE_PATH}._BTN_ADD`);

		const columns = [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._NAME`),
				key: "name",
				render: (record: Subject) => {
					return (
						<Space>
							<CircularIcon type={`ri-${record.icon}`}></CircularIcon>
							{record.name}
						</Space>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._DESCRIPTION`),
				dataIndex: "description",
				key: "description",
				responsive: ["sm"]
			},
			{
				title: translate("_ADMIN._GENERIC._TABLE._COLUMNS._ACTIONS"),
				key: "actions",
				render: (record: Subject) => (
					<RecordActions
						record={record}
						loadItemAndShowDrawer={this.loadItemAndShowDrawer}
						popConfirm={{
							message: "Konfirmoni fshirjen?",
							confirmtBtnTxt: "Po, fshije",
							cancelBtnTxt: "Anullo",
							onConfirm: () => this.removeSubject(record.id)
						}}
					/>
				)
			}
		];

		let submitHandler: any;

		const CreateEditForm = () => {
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.onSubmit}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row gutter={[16, 0]}>
									<Input name="name" label={translate(`${TRANSLATION_BASE_PATH}._SUBJECT`)} />
								</Row>
								<Row gutter={[16, 24]}>
									<Input
										name="icon"
										hint={
											<div>
												Shfleto{" "}
												<a
													href="https://remixicon.com/"
													target="_blank"
													rel="noopener noreferrer"
												>
													librarine e ikonave
												</a>
												. Me pas kopjoni titullin dhe vendoseni me poshte si tek{" "}
												<Popover
													placement="leftTop"
													content={<Image src={RiIconExample}></Image>}
													title="Title"
												>
													<a>shembulli</a>
												</Popover>
											</div>
										}
										label={translate(`${TRANSLATION_BASE_PATH}._ICON`)}
									/>
								</Row>
								<Row gutter={[16, 24]}>
									<Input
										name="description"
										label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
									/>
								</Row>
								<Row gutter={[16, 24]}>
									<Input
										name="externalUrl"
										label={translate(`${TRANSLATION_BASE_PATH}._EXTERNAL_URL`)}
									/>
								</Row>
							</form>
						);
					}}
				</Formik>
			);
		};

		return (
			<>
				<SectionTitle title={title} subtitle={[subTitle, title]} subtitleUrl={[DASHBOARD_PATH]} />
				<SectionContent>
					<CreateEditDrawer
						title={btnTitle}
						onClose={this.toggleDrawer}
						visible={this.state.drawerShown}
						form={CreateEditForm}
						onSave={() => submitHandler()}
						width={window.innerWidth >= 576 ? 350 : window.innerWidth}
					/>
					<Row>
						<Col xs={24} sm={4} md={3} lg={3} xl={2} xxl={2}>
							<Button onClick={() => this.loadItemAndShowDrawer()} type="primary" size="middle" block>
								<Space>
									<Text fontSize="14" lineHeight="22">
										<Icon type="ri-add-line"></Icon>
									</Text>
									{btnTitle}
								</Space>
							</Button>
						</Col>
						<Col xs={24} sm={20} md={21} lg={21} xl={22} xxl={22}>
							<Row justify="end">
								<ResponsiveSearchBox placeholder={translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} onSearch={this.search} />
							</Row>
						</Col>
					</Row>

					<Skeleton loading={this.state.loading}>
						<AdminTable
							dataSource={this.state.filteredData}
							columns={columns}
							locale={{
								emptyText: (
									<Empty
										image={Empty.PRESENTED_IMAGE_SIMPLE}
										description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
									/>
								)
							}}
						/>
					</Skeleton>
				</SectionContent>
			</>
		);
	}
}

const Subjects = withTranslation()(SubjectsComponent);
export default Subjects;
