import Material from "./material.dto";
import Answer from "./answer.dto";
import FileDto from "../administration/file.dto";

export default class Question {
	id?: string;
	questionTitle?: string = "";
	questionType?: string = "";
	materials?: Material[];
	answers?: Answer[] = [];
	FileId?: string;
	File?: FileDto;
	fileList: any = [];
	QuestionFile?: FileDto;
}
