import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import moment from "moment";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import OAuth2Service from "services/authentication/oauth2.service";
import WelcomeStudentIcon from "assets/images/dashboard-welcome-student.svg";
import WelcomeTeacherIcon from "assets/images/dashboard-welcome-teacher.svg";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { getDesignS3Url } from "../../../../../../common/utils/Design";
import S3ActionType from "../../../../../../services/domain/administration/S3Action";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._WELCOME";

interface WelcomeBannerProps {
	greetingText?: string;
	description?: string;
	cover?: string;
	showDate?: string;
}

export const WelcomeBaner = (props: WelcomeBannerProps) => {
	const { t: translate } = useTranslation();
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const isTeacher = OAuth2Service.isTeacher;
	const user = currentUserInfo?.User;
	const showDate = props.showDate === undefined || parseInt(props.showDate) === 1;

	const getWelcomeIcon = () => {
		let iconSource: any;
		if (!isTeacher) iconSource = WelcomeStudentIcon;
		else iconSource = WelcomeTeacherIcon;
		return <Image src={iconSource} className="welcome_icon" />;
	};

	const getWelcomeDescription = () => {
		if (!isTeacher) return translate(`${TRANSLATION_BASE_PATH}._STUDENT_DESCRIPTION`);
		return translate(`${TRANSLATION_BASE_PATH}._TEACHER_DESCRIPTION`);
	};

	return (
		<div className="dashboard__welcome_banner">
			<Col>
				<Row className="mb-4">
					<Col span="24">
						<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
							{props.greetingText ? props.greetingText : translate(`${TRANSLATION_BASE_PATH}._TITLE`, {
								name: user?.firstName
							})}
						</Text>
					</Col>
				</Row>

				<Row className="mb-8">
					<Col span="24" className="welcome__description">
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{props.description ? (
								<div dangerouslySetInnerHTML={{ __html: props.description }} />
							) : (
								getWelcomeDescription()
							)}
						</Text>
					</Col>
				</Row>
				{showDate && (
					<Row className="mb-4 mt-24">
						<Col span="24">
							<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
								{moment().format("dddd, D MMMM YYYY")}
							</Text>
						</Col>
					</Row>
				)}
			</Col>

			{props.cover ? (
				<Image className="welcome_icon" src={getDesignS3Url(props.cover, S3ActionType.DOWNLOAD)} />
			) : (
				getWelcomeIcon()
			)}
		</div>
	);
};
