import React, { ReactNode } from "react";
import { Row, Empty, Col, Skeleton } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import { MeetingTask } from "./meetingTask";
import { AssignmentTask } from "./assignmentTask";
import { VideoTask } from "./videoTask";
import { LessonAsTodaysTaskDto } from "dtos/administration/lesson/lessonAsTodaysTask.dto";
import { QuizTask } from "./quizTask";

interface TodaysTasksProps {
	items: any[];
	classroomId: string;
	loadingTasks: boolean;
	viewAsTeacher: boolean;
	onEditTask?: (task: any) => void;
	onDelete?: (task: any) => void;
}

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW._TASKS";

const getTasksDetails = (
	items: any[],
	viewAsTeacher: boolean,
	classroomId: string,
	onEdit?: (task: any) => void,
	onDelete?: (task: any) => void
): ReactNode => {
	return items.map((item: LessonAsTodaysTaskDto) => {
		let taskInfo;
		switch (item.type) {
			case "MEETING":
				taskInfo = (
					<MeetingTask item={item} viewAsTeacher={viewAsTeacher} onEdit={onEdit} onDelete={onDelete} />
				);
				break;
			case "ASSIGNMENT":
				taskInfo = <AssignmentTask item={item} viewAsTeacher={viewAsTeacher} classroomId={classroomId} />;
				break;
			case "QUIZ":
				taskInfo = <QuizTask item={item} viewAsTeacher={viewAsTeacher} classroomId={classroomId} />;
				break;
			case "VIDEO":
				taskInfo = <VideoTask item={item} viewAsTeacher={viewAsTeacher} classroomId={classroomId} />;
				break;
			default:
				taskInfo = null;
				break;
		}
		return (
			<Row key={item.id} className="mb-8">
				<Col span={24}>{taskInfo}</Col>
			</Row>
		);
	});
};

export const TodaysTasks = (props: TodaysTasksProps) => {
	const { t: translate } = useTranslation();
	return (
		<>
			<Row>
				<Col span="24">
					<Text fontSize="16" lineHeight="24" wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._TODAYS_TASKS`)}
					</Text>
				</Col>
			</Row>
			<Row className="mb-16">
				<Col span="24">
					<Text fontSize="12" lineHeight="20">
						{moment().format("dddd, D MMMM YYYY")}
					</Text>
				</Col>
			</Row>
			<Skeleton active loading={props.loadingTasks}>
				{!props.loadingTasks ? (
					<Row>
						<Col span="24">
							{props.items && props.items.length > 0 ? (
								getTasksDetails(
									props.items,
									props.viewAsTeacher,
									props.classroomId,
									props.onEditTask,
									props.onDelete
								)
							) : (
								<Empty description={translate(`${TRANSLATION_BASE_PATH}._NO_TASKS_FOR_TODAY`)} />
							)}
						</Col>
					</Row>
				) : null}
			</Skeleton>
		</>
	);
};
