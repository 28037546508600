import ApiServiceData from "../api.service";
import WizardDto from "dtos/administration/onboarding/wizard.dto";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import {FinaliseOnboardingDto} from "dtos/administration/onboarding/finaliseOnboarding.dto";
const relativeUrl = "/v1/api/onboarding";

export default class OnboardingService extends ApiServiceData {
	public getWizard(): Promise<object> {
		return this.get(relativeUrl + "/wizard")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Wizard not found"
				) {
					return null;
				}
				throw error.response;
			});
	}

	public createWizard(wizard: WizardDto): Promise<object> {
		return this.post(relativeUrl + "/wizard", wizard)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateWizard(wizard: UpdateWizardDto): Promise<object> {
		return this.update(relativeUrl + "/wizard/" + wizard.id, wizard)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getSubjectsBasedOnGrade(gradeId: string): Promise<object> {
		return this.get(relativeUrl + "/subjects/" + gradeId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public finish(body: FinaliseOnboardingDto): Promise<any> {
		return this.post(relativeUrl + "/finish", body)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Organization not found"
				) {
					throw error.response.data.error;
				}
				throw error.response;
			});
	}
}
