import React, { ReactNode } from "react";
import { Select as AntSelect } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const { Option: AntOption } = AntSelect;

interface OptionProps {
	key: number | string;
	value: string | number;
	children: ReactNode;
	size?: SizeType;
}

const Option: React.FC<OptionProps> = props => {
	return <AntOption {...props}>{props.children}</AntOption>;
};

export default Option;
