import React, { ReactNode } from "react";
import { Row, Col, Input as AntInput, Button, Space, Card, Dropdown, Menu, Image, Skeleton, Collapse } from "antd";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import Linkify from "react-linkify";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { UserAvatar } from "common/components/general/UserAvatar";
import TextArea from "common/components/dataEntry/formik/FormikTextAreaField";
import Input from "common/components/dataEntry/formik/FormikInputField";
import UserDto from "dtos/authentication/user/user.dto";
import { FeedDto } from "dtos/administration/feed.dto";
import FeedService from "services/administration/feed.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { Uploader } from "../uploader/Uploader";
import { previewFile, downloadFile } from "../../../scenes/courseBuilder/utils/FileUtils";
import { TypeIcon } from "../subject/subjectTypeIcon";
import { LessonType } from "services/domain/administration/LessonType";
import { getTimeLabel } from "../utils/TimeLabel";
import { CourseIntroduction } from "./CourseIntrodation";
import { SIGN_IN_PATH } from "../../../scenes/login/scenes/signIn";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

const { Panel } = Collapse;

const TRANSLATION_BASE_PATH = "_COMPONENTS._NEWS_FEEDER";

const limit = 10;

export class NewsFeederComponent extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			allFeeds: [],
			currentSendingIndex: null,
			loading: false,
			inputMode: false,
			initialValues: {
				ClassroomId: this.props.classroomId,
				UserId: "",
				message: ""
			},
			initialComments: [],
			filesToDelete: [],
			existingFile: [],
			currentUser: null,
			isTeacher: false,
			offset: 0,
			loadingMore: false,
			hasMore: true
		};
	}

	componentDidMount() {
		const { history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		const isTeacher: boolean = OAuth2Service.isTeacher;
		this.setState(
			{
				currentUser: currentUserInfo,
				isTeacher: isTeacher,
				initialValues: { ...this.state.initialValues, UserId: currentUserInfo.UserId }
			},
			() => this.loadFeeds()
		);
	}

	getUserInitials = (user: UserDto) => {
		return <UserAvatar userId={user?.id} lastName={user?.lastName} firstName={user?.firstName} />;
	};

	getFeedMessage = (message?: string, navigateUrl?: any, isTeacher?: any) => {
		const componentDecorator = (href: any, text: any, key: any) => (
			<a href={href} key={key} target="_blank" rel="noopener noreferrer">
				{text}
			</a>
		);

		if (navigateUrl && !isTeacher) {
			return (
				<a href={navigateUrl}>
					<Text fontSize="14" lineHeight="22" className="color-blue-6">
						{message}
					</Text>
				</a>
			);
		}
		return (
			<Text fontSize="14" lineHeight="22" className="color-gray-8">
				<Linkify componentDecorator={componentDecorator}>{message}</Linkify>
			</Text>
		);
	};

	getFeedTitle = (feed: FeedDto, currentUser: UserDto) => {
		const firstName = feed.UserCreatedBy ? feed.UserCreatedBy.firstName : currentUser.firstName;
		const lastName = feed.UserCreatedBy ? feed.UserCreatedBy.lastName : currentUser.lastName;
		return (
			<Space>
				<Text fontSize="12" lineHeight="20" className="color-gray-10">
					{firstName + " " + lastName}
				</Text>
				<Text fontSize="12" lineHeight="20" className="color-gray-6">
					{getTimeLabel(feed.createdAt)}
				</Text>
			</Space>
		);
	};

	getPhotoUrls = async (items: any): Promise<any> => {
		return (
			(await Promise.all(
				items.map(async function(item: any) {
					let image = null;
					if (item.File && item.File.contentType.indexOf("image") > -1) {
						image = (item.File && (await previewFile(item.File))) || null;
					}
					return { ...item, filePath: image && image.url };
				})
			).then(classroom => {
				return classroom;
			})) || []
		);
	};

	renderFeeds = () => {
		this.setState({ loading: false });
		const feeds = this.state.allFeeds;
		const feedEntries = (feeds as FeedDto[]).filter((x: FeedDto) => !x.ParentId);
		const feedComments = (feeds as FeedDto[]).filter((x: FeedDto) => x.ParentId);
		this.setState(
			{
				feeds: _.orderBy(feedEntries, (x: FeedDto) => x.createdAt, "desc"),
				comments: _.orderBy(feedComments, (x: FeedDto) => x.createdAt, "desc")
			},
			() => {
				const currentComments: any[] = [];
				this.state.feeds.map((feed: FeedDto) => {
					currentComments.push({
						ClassroomId: this.props.classroomId,
						UserId: this.state.currentUser?.UserId,
						message: "",
						ParentId: feed.id
					});
				});
				this.setState(
					{
						initialComments: currentComments
					},
					() => {
						this.setState({ loading: false });
					}
				);
			}
		);
	};

	loadFeeds = () => {
		this.setState({ loading: this.state.offset === 0 });

		return new FeedService()
			.getAllByClassIdByOffset(this.state.initialValues.ClassroomId, this.state.offset, limit)
			.then((result: any) => {
				return result.data;
			})
			.then((result: any) => {
				return this.getPhotoUrls(result);
			})
			.then((result: any) => {
				return this.setState(
					{
						allFeeds: this.state.allFeeds.concat(result),
						hasMore: result.length > 0
					},
					() => {
						this.renderFeeds();
					}
				);
			})
			.finally(() => {
				this.setState({ loading: false, loadingMore: false });
			});
	};

	sendMessage = (feed: FeedDto) => {
		this.setState({ currentSendingIndex: feed.index });

		if (!feed.createdAt) {
			feed["createdBy"] = this.state.currentUser?.UserId;
		}
		feed["updatedBy"] = this.state.currentUser?.UserId;

		const file = this.state.fileUploaded;
		if (file) {
			file["createdBy"] = this.state.currentUser?.UserId;
			file["updatedBy"] = this.state.currentUser?.UserId;
			feed.FileId = file.id;
		}
		feed.File = file;
		delete feed.filePath;
		delete feed.fileUploaded;
		delete feed.index;

		let savedFeed: FeedDto;
		return new FeedService()
			.addOrUpdate(feed)
			.then((result: any) => {
				savedFeed = result.data as FeedDto;
				if (savedFeed.ParentId) {
					this.setState({ initialComments: [] });
				}
				return;
			})
			.finally(() => {
				this.setState({ currentSendingIndex: null });
				if (!feed.id) {
					feed.id = savedFeed.id;
					feed.createdAt = savedFeed.createdAt;
					feed.updatedAt = savedFeed.updatedAt;
					feed.filePath = savedFeed.filePath;
				}
				feed.fileUploaded = this.state.imageUrl;
				const updatedFeeds = this.state.allFeeds.filter((x: FeedDto) => {
					return x.id !== feed.id;
				});
				updatedFeeds.push(feed);
				this.setState(
					{
						allFeeds: updatedFeeds,
						initialValues: {
							ClassroomId: this.props.classroomId,
							UserId: this.state.currentUser?.UserId,
							message: ""
						},
						fileUploaded: null
					},
					() => {
						this.renderFeeds();
						this.hideForm();
					}
				);
			});
	};

	showNewFeedForm() {
		this.setState({
			inputMode: true
		});
	}

	hideForm() {
		this.setState({
			inputMode: false
		});
	}

	edit = async (feed: FeedDto) => {
		this.cancel();

		const fileList: any[] = [];

		if (feed.File) {
			await previewFile(feed.File)
				.then(file => fileList.push(file))
				.catch(err => {
					console.log(err);
				});
		}

		this.setState(
			{
				initialValues: feed,
				existingFile: fileList,
				fileUploaded: feed.File
			},
			() => {
				this.showNewFeedForm();
			}
		);
	};

	cancel = () => {
		this.setState(
			{
				initialValues: {
					ClassroomId: this.props.classroomId,
					message: ""
				},
				fileUploaded: null,
				existingFile: []
			},
			() => {
				this.hideForm();
			}
		);
	};

	delete = (feed: FeedDto) => {
		return new FeedService().remove(feed.id).then(() => {
			const remainedFeeds = this.state.allFeeds.filter((x: FeedDto) => {
				return x.id !== feed.id;
			});
			if (!feed.ParentId) {
				this.setState({
					offset: this.state.offset - 1
				});
			}
			return this.setState(
				{
					allFeeds: remainedFeeds
				},
				() => {
					return this.renderFeeds();
				}
			);
		});
	};

	getImage = async (file?: any) => {
		if (file) {
			const response = await previewFile(file);
			this.setState({
				imageUrl: response.url
			});
		}
		return;
	};

	getFeedAttachment = (
		fileId: string,
		fileName: string,
		filePath?: string,
		contentType?: string,
		fileUploaded?: string
	) => {
		if (contentType && contentType.indexOf("image") > -1) {
			return <Image width={50} src={filePath ?? fileUploaded} title={fileName} />;
		}
		return (
			<>
				<Space>
					<Text fontSize="30" lineHeight="38">
						<Icon type={"ri-attachment-2"}></Icon>
					</Text>
					<a onClick={() => downloadFile(fileId + "/" + fileName)}>{fileName}</a>
				</Space>
			</>
		);
	};

	onUploadingDone = async (fileUploaded: any) => {
		this.setState({ fileUploaded: fileUploaded });
		await this.getImage(fileUploaded);
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			fileUploaded: undefined
		});
	};

	getNewFeedForm = (): ReactNode => {
		const { t: translate } = this.props;

		if (this.state.inputMode) {
			return (
				<Formik
					enableReinitialize={true}
					initialValues={{ ...this.state.initialValues, index: -1 }}
					onSubmit={this.sendMessage}
					isInitialValid={false}
					validationSchema={Yup.object().shape({
						message: Yup.string()
							.nullable()
							.trim()
							.required("Fushë e detyrueshme")
							.max(255)
					})}
				>
					{props => {
						const { handleSubmit } = props;
						return (
							<form onSubmit={handleSubmit} id="message-form" autoComplete="off">
								<Card className="newsFeeder__card__feed newsFeeder__card__feed__form">
									<Row>
										<Col span="24">
											<TextArea
												name="message"
												placeholder={translate(
													`${TRANSLATION_BASE_PATH}._PLACEHOLDER_NEW_FEED`
												)}
												autoFocus={true}
											/>
										</Col>
									</Row>
									<Row>
										<Col span="16">
											<Row justify="start">
												<Uploader
													fileType={"OTHER"}
													filePath={`newsFeeder/${uuidv4()}`}
													fileWithUniqueId={false}
													onUploadingDone={this.onUploadingDone}
													showUploadList={true}
													onSoftDeleteFile={this.onSoftDeleteFile}
													defaultFileList={this.state.existingFile}
												/>
											</Row>
										</Col>
										<Col span="8">
											<Row justify="end">
												<Space>
													<Button type="text" onClick={this.cancel}>
														{translate(`${TRANSLATION_BASE_PATH}._BUTTONS._CANCEL`)}
													</Button>
													<Button
														type="primary"
														htmlType="submit"
														disabled={this.state.currentSendingIndex !== null}
													>
														{this.state.currentSendingIndex === -1 && (
															<LoadingOutlined style={{ fontSize: 12 }} spin />
														)}
														{translate(`${TRANSLATION_BASE_PATH}._BUTTONS._POST`)}
													</Button>
												</Space>
											</Row>
										</Col>
									</Row>
								</Card>
							</form>
						);
					}}
				</Formik>
			);
		}

		return (
			<Row className="newsFeeder__card__feed newsFeeder__card__top__round__borders newsFeeder__card__bottom__round__borders pt-16 pb-16">
				<Col xs={4} sm={2} md={2} lg={2} xl={2} xxl={2}>
					<Row justify="center">
						{this.getUserInitials({
							...this.state.currentUser?.User,
							id: this.state.currentUser?.UserId
						})}
					</Row>
				</Col>
				<Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
					<Row justify="center">
						<AntInput
							bordered={false}
							name="message"
							placeholder={translate(`${TRANSLATION_BASE_PATH}._PLACEHOLDER_NEW_FEED`)}
							autoFocus={false}
							width="100%"
							onFocus={() => this.showNewFeedForm()}
						/>
					</Row>
				</Col>
			</Row>
		);
	};

	getMenu = (feed: FeedDto) => {
		const { t: translate } = this.props;
		return (
			<Menu>
				{!feed.ParentId && (
					<Menu.Item key="0" onClick={() => this.edit(feed)}>
						{translate(`${TRANSLATION_BASE_PATH}._MENU._EDIT`)}
					</Menu.Item>
				)}
				<Menu.Item key="1" onClick={() => this.delete(feed)}>
					{translate(`${TRANSLATION_BASE_PATH}._MENU._DELETE`)}
				</Menu.Item>
			</Menu>
		);
	};

	getSubjectTypeIcon = (type: string): LessonType => {
		switch (type) {
			case "ASSIGNMENT":
				return "ASSIGNMENT";
			case "VIDEO":
				return "VIDEO";
			case "MEETING":
				return "MEETING";
			case "QUIZ":
				return "QUIZ";
			case "PDF":
				return "PDF";
			default:
				return "ASSIGNMENT";
		}
	};

	getMore = () => {
		this.setState(
			{
				loadingMore: true,
				offset: this.state.offset + limit
			},
			() => {
				this.loadFeeds();
			}
		);
	};

	render() {
		const { t: translate } = this.props;

		return (
			<>
				<Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18} className="pb-24">
					{this.getNewFeedForm()}
					<Skeleton loading={this.state.loading} active>
						{this.state.feeds && this.state.feeds.length === 0 && !this.state.hasMore ? (
							<CourseIntroduction className="mt-16" forTeacher={this.state.isTeacher} />
						) : null}
						{this.state.feeds &&
							this.state.comments &&
							this.state.initialComments &&
							this.state.feeds.map((feed: FeedDto, index: number) => {
								const formId = `comment-form-${feed.id}`;
								const feedComments = this.state.comments.filter((x: FeedDto) => x.ParentId === feed.id);
								const lastComment = feedComments.length > 0 ? feedComments[0] : undefined;
								const otherComments = feedComments.length > 1 ? feedComments.slice(1) : undefined;
								return (
									<>
										<Row
											key={feed.id}
											className="newsfeeder__card__border_right newsfeeder__card__border_left newsfeeder__card__border_top 
										newsFeeder__card__top__round__borders pt-16 pb-16 pr-16 mt-16 background-color-gray-1"
										>
											<Col xs={4} sm={2} md={2} lg={2} xl={2} xxl={2}>
												<Row justify="center">
													{feed.type ? (
														<TypeIcon type={this.getSubjectTypeIcon(feed.type)} />
													) : (
														this.getUserInitials(
															feed.UserCreatedBy ?? {
																...this.state.currentUser?.User,
																id: this.state.currentUser?.UserId
															}
														)
													)}
												</Row>
											</Col>
											<Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
												<Row>{this.getFeedTitle(feed, this.state.currentUser?.User)}</Row>
												<Row>
													<Col span="21">
														{this.getFeedMessage(
															feed.message,
															feed.navigateUrl,
															this.state.isTeacher
														)}
													</Col>
													{!feed.type && (
														<Col span="3">
															{(feed.createdBy === this.state.currentUser?.UserId ||
																this.state.isTeacher) && (
																<Row justify="end">
																	<Dropdown
																		trigger={["hover"]}
																		overlay={() => this.getMenu(feed)}
																	>
																		<a className="ant-dropdown-link">
																			<Text
																				fontSize="16"
																				lineHeight="22"
																				className="color-gray-8"
																			>
																				<Space>
																					<Text
																						fontSize="20"
																						lineHeight="24"
																						wheight="semibold"
																					>
																						<Icon type="ri-more-line"></Icon>
																					</Text>
																				</Space>
																			</Text>
																		</a>
																	</Dropdown>
																</Row>
															)}
														</Col>
													)}
												</Row>
												<Row>
													{feed.File &&
														this.getFeedAttachment(
															feed.File?.filePath ?? "",
															feed.File?.name,
															feed.filePath,
															feed.File?.contentType,
															feed.fileUploaded
														)}
												</Row>
											</Col>
										</Row>
										<Row
											key={`comments-${feed.id}`}
											className={`newsfeeder__card__border_left newsfeeder__card__border_right background-color-gray-1 ${
												otherComments ? "newsfeeder__card__border_top" : ""
											}`}
										>
											<Col span={24}>
												{otherComments && (
													<Collapse defaultActiveKey={[feed.id ?? ""]} ghost>
														<Panel
															header={`Të gjitha komentet (${otherComments.length + 1})`}
															key="1"
														>
															{otherComments.map((feed: FeedDto) => {
																return (
																	<Row key={feed.id}>
																		<Col xs={4} sm={2} md={2} lg={2} xl={2} xxl={2}>
																			<Row justify="center">
																				{this.getUserInitials(
																					feed.UserCreatedBy ?? {
																						...this.state.currentUser?.User,
																						id: this.state.currentUser
																							?.UserId
																					}
																				)}
																			</Row>
																		</Col>
																		<Col
																			xs={20}
																			sm={22}
																			md={22}
																			lg={22}
																			xl={22}
																			xxl={22}
																		>
																			<Row>
																				{this.getFeedTitle(
																					feed,
																					this.state.currentUser?.User
																				)}
																			</Row>
																			<Row>
																				<Col span="18">
																					{this.getFeedMessage(feed.message)}
																				</Col>
																				<Col span="6">
																					{(feed.createdBy ===
																						this.state.currentUser
																							?.UserId ||
																						this.state.isTeacher) && (
																						<Row justify="end">
																							<Dropdown
																								trigger={["hover"]}
																								overlay={() =>
																									this.getMenu(feed)
																								}
																							>
																								<a className="ant-dropdown-link">
																									<Text
																										fontSize="16"
																										lineHeight="22"
																										className="color-gray-8"
																									>
																										<Space>
																											<Text
																												fontSize="20"
																												lineHeight="24"
																												wheight="semibold"
																											>
																												<Icon type="ri-more-line"></Icon>
																											</Text>
																										</Space>
																									</Text>
																								</a>
																							</Dropdown>
																						</Row>
																					)}
																				</Col>
																			</Row>
																		</Col>
																	</Row>
																);
															})}
														</Panel>
													</Collapse>
												)}
											</Col>
										</Row>
										{lastComment && (
											<Row
												key={lastComment.id}
												className="newsfeeder__card__border_left newsfeeder__card__border_right pt-16 pr-16 background-color-gray-1"
											>
												<Col xs={4} sm={2} md={2} lg={2} xl={2} xxl={2}>
													<Row justify="center">
														{this.getUserInitials(
															lastComment.UserCreatedBy ?? {
																...this.state.currentUser?.User,
																id: this.state.currentUser?.UserId
															}
														)}
													</Row>
												</Col>
												<Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
													<Row>
														{this.getFeedTitle(lastComment, this.state.currentUser?.User)}
													</Row>
													<Row>
														<Col span="18">{this.getFeedMessage(lastComment.message)}</Col>
														<Col span="6">
															{(lastComment.createdBy ===
																this.state.currentUser?.UserId ||
																this.state.isTeacher) && (
																<Row justify="end">
																	<Dropdown
																		trigger={["hover"]}
																		overlay={() => this.getMenu(lastComment)}
																	>
																		<a className="ant-dropdown-link">
																			<Text
																				fontSize="16"
																				lineHeight="22"
																				className="color-gray-8"
																			>
																				<Space>
																					<Text
																						fontSize="20"
																						lineHeight="24"
																						wheight="semibold"
																					>
																						<Icon type="ri-more-line"></Icon>
																					</Text>
																				</Space>
																			</Text>
																		</a>
																	</Dropdown>
																</Row>
															)}
														</Col>
													</Row>
												</Col>
											</Row>
										)}
										<Row
											className={`
												newsFeeder__card__feed
												newsFeeder__card__bottom__round__borders
												newsFeeder__card__feed__comment
												pt-24
												pb-24
												pr-16
												background-color-gray-1`}
										>
											<Col xs={4} sm={2} md={2} lg={2} xl={2} xxl={2}>
												<Row justify="center">
													{this.getUserInitials({
														...this.state.currentUser?.User,
														id: this.state.currentUser?.UserId
													})}
												</Row>
											</Col>
											<Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
												<Row justify="center">
													<Formik
														enableReinitialize={true}
														initialValues={{
															...this.state.initialComments[index],
															index: index
														}}
														onSubmit={this.sendMessage}
														isInitialValid={false}
														validationSchema={Yup.object().shape({
															message: Yup.string()
																.nullable()
																.trim()
																.required("Fushë e detyrueshme")
																.max(255)
														})}
													>
														{props => {
															const { handleSubmit } = props;
															return (
																<form
																	onSubmit={handleSubmit}
																	id={formId}
																	autoComplete="off"
																	style={{ width: "100%" }}
																>
																	<Input
																		name="message"
																		id={feed.id}
																		placeholder={translate(
																			`${TRANSLATION_BASE_PATH}._PLACEHOLDER_NEW_COMMENT`
																		)}
																		autoFocus={false}
																		width="100%"
																		className="pr-32"
																	/>
																	<Button
																		type="text"
																		htmlType="submit"
																		disabled={
																			this.state.currentSendingIndex !== null
																		}
																	>
																		{this.state.currentSendingIndex === index && (
																			<LoadingOutlined
																				style={{ fontSize: 10 }}
																				spin
																			/>
																		)}
																		{this.state.currentSendingIndex !== index && (
																			<Icon type="ri-send-plane-2-line"></Icon>
																		)}
																	</Button>
																</form>
															);
														}}
													</Formik>
												</Row>
											</Col>
										</Row>
									</>
								);
							})}
					</Skeleton>
					{this.state.hasMore && (
						<Row justify="center" className="pt-24">
							<Button onClick={this.getMore} disabled={this.state.loadingMore}>
								{this.state.loadingMore && <LoadingOutlined style={{ fontSize: 12 }} spin />}
								{translate(`${TRANSLATION_BASE_PATH}._LOAD_MORE`)}
							</Button>
						</Row>
					)}
				</Col>
			</>
		);
	}
}

const NewsFeeder = withTranslation()(NewsFeederComponent);
export default NewsFeeder;
