import { Button, Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { InviteUsers } from "../orgContent/usersTab/inviteUsers/InviteUsers";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._INVITE_BANNER";

export const InviteBanner = (props: any) => {
	const { t: translate } = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);

	useEffect(() => {}, []);

	const RenderBanner = () => {
		const RenderInviteButton = () => {
			return (
				<Button className="pr-48 pl-48" onClick={() => setDrawerVisible(true)}>
					Ftoni
				</Button>
			);
		};
		return (
			<Row align="middle">
				<Col xs={24} sm={24} md={14} lg={18} xl={18} xxl={18}>
					<Space size="middle">
						<Icon type="ri-error-warning-fill" className="color-blue-1" fontSize="30" />
						<Text fontSize="14" lineHeight="22" className="color-blue-1">
							{translate(`${TRANSLATION_BASE_PATH}._INFO`)}
						</Text>
					</Space>
				</Col>
				<Col xs={0} sm={0} md={10} lg={6} xl={6} xxl={6}>
					{RenderInviteButton()}
				</Col>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
					<Row justify="center" className="pt-8">
						{RenderInviteButton()}
					</Row>
				</Col>
			</Row>
		);
	};

	return (
		props.visible && (
			<>
				<Row className="mt-16 pb-16 pt-16 background-color-blue-6" justify="center">
					<Col span="20">{RenderBanner()}</Col>
				</Row>
				<InviteUsers
					visible={drawerVisible}
					onClose={() => setDrawerVisible(false)}
					onRefresh={() => props.refreshUsers()}
					currentUsers={props.currentUsers}
				/>
			</>
		)
	);
};
