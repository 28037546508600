import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { Skeleton, Row, Col, Divider, Space, Radio, Checkbox, Alert } from "antd";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Modal from "common/components/feedback/Modal";
import OAuth2Service from "services/authentication/oauth2.service";
import { SUBJECTS_PATH } from "scenes/subjects/index";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import { LESSON_PATH } from "scenes/subjects/scenes/materialItems/scenes/lessonMaterial/LessonMaterial";
import LessonService from "services/administration/lesson.service";
import { ChildContext } from "../../components/layout/Layout";
import { LessonItem } from "scenes/subjects/components/lessonItem/LessonItem";
import QuizUserResponseService from "services/administration/quizUserResponse.service";
import QuizUserResponseDto from "dtos/administration/quizUserResponse.dto";
import ProgressLessonEnrollmentService from "services/administration/progressLessonEnrollment.service";
import ProgressLessonEnrollmentDto from "dtos/administration/progressLessonEnrollment.dto";
import { COURSE_PATH } from "scenes/course";
import { SIGN_IN_PATH } from "../../../../../login/scenes/signIn";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { AnswerImagePreview } from "../../components/answerImagePreview";
import Answer from "dtos/course-builder/answer.dto";
import { HtmlInput } from "common/components/dataEntry/components/HtmlInput";

export const QUIZ_FULL_PATH = "quiz/:quizId";
export const QUIZ_PATH = "quiz";
const TRANSLATION_BASE_PATH = "_SUBJECTS._MATERIAL_ITEMS._QUIZ_MATERIALS";

const getQuestionAnswersAsRadio = (answers: Answer[]): ReactNode => {
	if (answers.findIndex((item: Answer) => item.answerFile !== undefined) > -1) {
		return (
			<Row gutter={[24, 24]}>
				{answers.map((answer: any, index: number) => {
					return (
						<Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Space direction="vertical" className="full__width">
								{answer.answerFile ? <AnswerImagePreview file={answer.answerFile} /> : null}
								<Radio value={answer.id} className="flex__center">
									<HtmlInput name={`answer-${index}`} readOnly value={answer.title} />
								</Radio>
							</Space>
						</Col>
					);
				})}
			</Row>
		);
	} 
	return answers.map((answer: any, index: number) => {
		return (
			<Row key={index}>
				<Col span={24}>
					<Radio value={answer.id} className="flex__center">
						<HtmlInput name={`answer-${index}`} readOnly value={answer.title} />
					</Radio>
				</Col>
			</Row>
		);
	});
	
};

class QuizMaterialComponent extends Component<any, any> {
	static contextType = ChildContext;

	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			quizId: "",
			lessonQuiz: null,
			questions: null,
			answersByUser: new Map<string, any[]>(),
			isChecked: false,
			checkQuestionValidity: new Map<boolean, Map<boolean, string>>(),
			modalVisibility: false,
			checkedModal: false,
			quizUserAnswer: null,
			isVirtualClass: false,
			userId: ""
		};

		this.nextLesson = this.nextLesson.bind(this);
		this.checkAnswers = this.checkAnswers.bind(this);
		this.generateQuestionForm = this.generateQuestionForm.bind(this);
		this.generateQuestionAnswers = this.generateQuestionAnswers.bind(this);
		this.checkAnswers = this.checkAnswers.bind(this);
		this.onChangedCheckboxAnswer = this.onChangedCheckboxAnswer.bind(this);
		this.onChangedRadioButtonAnswer = this.onChangedRadioButtonAnswer.bind(this);
		this.submitAnswers = this.submitAnswers.bind(this);
		this.createAlertForAnyAnswer = this.createAlertForAnyAnswer.bind(this);
		this.createHeaderTest = this.createHeaderTest.bind(this);
		this.onModalAnswerCancel = this.onModalAnswerCancel.bind(this);
		this.getRightAnswerCount = this.getRightAnswerCount.bind(this);
		this.fillQuestionAnswers = this.fillQuestionAnswers.bind(this);
		this.checkValidityAnswer = this.checkValidityAnswer.bind(this);
	}

	componentDidMount() {
		const {
			history,
			match: { params }
		} = this.props;

		if (!params.quizId) return history.push(`${SUBJECTS_PATH}`);
		this.loadQuiz();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.quizId !== this.state.quizId) this.loadQuiz();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.quizId !== prevState.quizId) return { quizId: params.quizId };
		return null;
	}

	loadQuiz = async () => {
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const isVirtualClass = queryString.get("virtualClass");
		const groupedBy = this.context.groupedBy ? this.context.groupedBy : queryString.get("groupedBy");

		this.context.setActualLessonId(this.state.quizId);
		this.context.setBackUrl(
			isVirtualClass ? `/${COURSE_PATH}/_student/` : `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/`,
			isVirtualClass, false, groupedBy
		);

		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return this.props.history.push("/" + SIGN_IN_PATH);
		this.setState({ loading: true, userId: currentUserInfo.UserId });

		const [lessonQuiz, quizUserAnswer] = await Promise.all([new LessonService().getLessonById(this.state.quizId),
			new QuizUserResponseService().getQuizUserResponseByUserIdLessonId(this.state.quizId)]);

		if (!lessonQuiz) return this.props.history.push(isVirtualClass ? `/${COURSE_PATH}` : `/${SUBJECTS_PATH}`);

		const isVideoMaterial = lessonQuiz?.lessonType === "VIDEO" ?? true;
		if (isVideoMaterial) {
			const virtualClass = this.state.isVirtualClass ? "?virtualClass=true" : "";
			return this.props.history.push(
				`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${this.state.quizId}${virtualClass}`
			);
		}
		const subjectPlanTrees = lessonQuiz?.SubjectPlanTrees;
		const subjectPlan = subjectPlanTrees && subjectPlanTrees.length > 0 && subjectPlanTrees[0].SubjectPlan;
		const classroom = subjectPlan && subjectPlan.Classroom;
		classroom && this.context.setCourse(classroom.id);
		classroom && this.context.setSelectedTag(groupedBy, classroom.id);

		const questions =
			lessonQuiz && lessonQuiz.lessonMetaInfo && Array.isArray(lessonQuiz.lessonMetaInfo)
				? lessonQuiz.lessonMetaInfo
				: lessonQuiz && lessonQuiz.lessonMetaInfo && JSON.parse(lessonQuiz.lessonMetaInfo);
		const answers =
			quizUserAnswer && quizUserAnswer.answerMetaInfo && Array.isArray(quizUserAnswer.answerMetaInfo)
				? quizUserAnswer.answerMetaInfo
				: quizUserAnswer && quizUserAnswer.answerMetaInfo && JSON.parse(quizUserAnswer.answerMetaInfo);
		const answersByUser = this.fillQuestionAnswers(questions, answers);

		this.setState(
			{
				groupedBy: groupedBy,
				loading: false,
				lessonQuiz: lessonQuiz,
				questions: questions,
				answersByUser: answersByUser,
				quizUserAnswer: quizUserAnswer,
				isVirtualClass: isVirtualClass
			},
			() => {
				const { checkQuestionValidity } =
					answers && answers.length > 0
						? this.checkValidityAnswer(answersByUser)
						: { checkQuestionValidity: new Map<boolean, Map<boolean, string>>() };
				this.setState({
					isChecked: checkQuestionValidity.size > 0,
					checkQuestionValidity: checkQuestionValidity,
					checkedModal: answers && answers.length > 0
				});
			}
		);
	};

	fillQuestionAnswers = (questions: any, answers: any) => {
		const answersByUser: Map<string, Map<string, any>> = new Map<string, Map<string, any>>();
		questions &&
			questions.length > 0 &&
			questions.map((question: any) => {
				const questionResponse = answers && answers.find((answer: any) => answer.questionId === question.id);
				if (questionResponse) {
					const answerRetrived: Map<string, any> = new Map<string, any>();
					if (question.questionType === "singleChoice")
						answerRetrived.set(
							"radioSelectedButton",
							(questionResponse &&
								questionResponse.answers.length > 0 &&
								questionResponse.answers[0].answerId) ||
								""
						);
					else {
						questionResponse &&
							questionResponse.answers.length > 0 &&
							questionResponse.answers.map((answer: any) => {
								answerRetrived.set(answer.answerId, true);
							});
					}
					return answersByUser.set(question.id, answerRetrived);
				}
				return answersByUser.set(question.id, new Map<string, any>());
			});
		return answersByUser;
	};

	nextLesson = () => {
		const nextLesson = this.context.nextLesson();
		const virtualClass = this.state.isVirtualClass ? "?virtualClass=true" : "";
		const groupBy = (this.state.isVirtualClass) ?
			((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `&groupedBy=${this.state.groupedBy}`) :
			((!this.state.groupedBy || this.state.groupedBy === "") ? "" : `?groupedBy=${this.state.groupedBy}`);
		
		return (
			nextLesson &&
			this.props.history.push(
				`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${nextLesson}${virtualClass}${groupBy}`
			)
		);
	};

	resetQuiz = () => {
		const answersByUser: Map<string, Map<string, any>> = new Map<string, Map<string, any>>();
		this.state.questions &&
			this.state.questions.length > 0 &&
			this.state.questions.map((question: any) => {
				return answersByUser.set(question.id, new Map<string, any>());
			});
		this.setState(
			{
				answersByUser: answersByUser,
				isChecked: false,
				checkQuestionValidity: new Map<boolean, Map<boolean, string>>(),
				checkedModal: false
			},
			() => {
				this.state.quizUserAnswer &&
					new QuizUserResponseService().deleteQuizUserResponse(this.state.quizUserAnswer.id);
			}
		);
	};

	submitAnswers = async () => {
		const { checkQuestionValidity, questionAnswerToBeSaved } = this.checkValidityAnswer(this.state.answersByUser);
		this.setState(
			{
				isChecked: checkQuestionValidity.size > 0,
				checkQuestionValidity: checkQuestionValidity,
				modalVisibility: true
			},
			async () => {
				const quizUserAnswer = new QuizUserResponseDto({
					UserId: this.state.userId,
					LessonId: this.state.quizId,
					answerMetaInfo: JSON.stringify(questionAnswerToBeSaved)
				});
				const progressLessonEnrollment = new ProgressLessonEnrollmentDto({
					LessonId: this.state.quizId,
					EnrollmentId: this.context.enrollmentId,
					createdBy: this.state.userId,
					updatedBy: this.state.userId
				});
				await Promise.all([new QuizUserResponseService().createQuizUserResponse(quizUserAnswer),
					this.context.enrollmentId !== ""
						? new ProgressLessonEnrollmentService().createProgressLessonEnrollment(progressLessonEnrollment)
						: Promise.resolve(null)]);
			}
		);
	};

	checkValidityAnswer = (answersByUser: any) => {
		const userAnswers = answersByUser;
		const checkQuestionValidity = new Map<boolean, Map<boolean, string>>();
		const questionAnswerToBeSaved: { questionId: any; answers: any }[] = [];
		userAnswers.forEach((value: any, key: any) => {
			checkQuestionValidity.set(key, this.checkAnswers(value, key));
			const answerToBeSaved: any = [];
			value.forEach((answer: any, answerKey: any) => {
				answerToBeSaved.push({ answerId: answerKey === "radioSelectedButton" ? answer : answerKey });
			});
			questionAnswerToBeSaved.push({ questionId: key, answers: answerToBeSaved });
		});
		return { checkQuestionValidity: checkQuestionValidity, questionAnswerToBeSaved: questionAnswerToBeSaved };
	};

	checkAnswers = (answers: any, questionId: any) => {
		const checkAnswer = new Map<boolean, string>();
		const question = this.state.questions.find((question: any) => question.id === questionId);
		if (question.questionType === "singleChoice") {
			const correctAnswer = question.answers.filter((answer: any) => answer.correct === true);
			const correctAnswerValue = correctAnswer && correctAnswer.length === 1 && correctAnswer[0];
			const answerByUser = answers.get("radioSelectedButton");
			if (!correctAnswerValue || !correctAnswerValue.id) return checkAnswer.set(true, correctAnswerValue.title);
			if (!answerByUser) return checkAnswer.set(false, correctAnswerValue.title);
			return checkAnswer.set(correctAnswerValue.id === answerByUser, correctAnswerValue.title);
		}
		const correctAnswers = question.answers.filter((answer: any) => answer.correct === true);
		const correctAnswersTitle = correctAnswers.map((answer: any) => answer.title);
		const checkedUserAnswer = correctAnswers.filter(
			(correctAnswer: any) => (answers.get(correctAnswer.id) || false) === false
		);
		if ((checkedUserAnswer && checkedUserAnswer.length > 0) || correctAnswers.length !== answers.size)
			return checkAnswer.set(false, correctAnswersTitle.join(", "));
		return checkAnswer.set(true, correctAnswersTitle.join(", "));
	};

	onChangedCheckboxAnswer = (questionId: string, answerId: string, e: any) => {
		const answersByUser = this.state.answersByUser;
		answersByUser.get(questionId).set(answerId, e.target.checked);
		this.setState({ answersByUser: answersByUser });
	};

	onChangedRadioButtonAnswer = (questionId: string, e: any) => {
		const answersByUser = this.state.answersByUser;
		answersByUser.get(questionId).set("radioSelectedButton", e.target.value);
		this.setState({ answersByUser: answersByUser });
	};

	onModalAnswerOk = () => {
		this.setState({ checkedModal: true, modalVisibility: false });
	};

	onModalAnswerCancel = () => {
		this.setState({ checkedModal: true, modalVisibility: false });
	};

	createAlertForAnyAnswer = (questionId: any) => {
		const { t: translate } = this.props;
		const getAnswerOfTheQuestion = this.state.checkQuestionValidity.get(questionId);
		const thisAnswer = Object.fromEntries(getAnswerOfTheQuestion);
		return (
			<Row className="mt-16">
				<Col span={24}>
					<Alert
						message={
							thisAnswer.false ? (
								<div
									dangerouslySetInnerHTML={{
										__html: translate(`${TRANSLATION_BASE_PATH}._WRONG_ANSWER`) + thisAnswer.false
									}}
								/>
							) : (
								translate(`${TRANSLATION_BASE_PATH}._CORRECT_ANSWER`)
							)
						}
						type={thisAnswer.false ? "error" : "success"}
						showIcon
					/>
				</Col>
			</Row>
		);
	};

	getRightAnswerCount = () => {
		let correctAnswers = 0;
		this.state.checkQuestionValidity.forEach((value: any) => {
			const thisAnswer = Object.fromEntries(value);
			if (!thisAnswer.false) correctAnswers++;
		});
		return correctAnswers;
	};

	createHeaderTest = () => {
		const { t: translate } = this.props;

		return (
			<Row gutter={[0, 16]}>
				<Col span={18} offset={4}>
					<Row align="middle" justify="center">
						<Space direction="vertical" size={24}>
							<Row align="middle" justify="center">
								<Icon
									type="ri-trophy-line"
									className="color-blue-6 quizMaterial__scene quizMaterial__trophy_icon"
								/>
							</Row>
							<Row align="middle" justify="center">
								<Text fontSize="14" lineHeight="22" className="color-gray-9">
									{translate(`${TRANSLATION_BASE_PATH}._FINAL_TEST_RESPONSE`, {
										success: this.getRightAnswerCount(),
										outOf: this.state.questions && this.state.questions.length
									})}
								</Text>
							</Row>
						</Space>
						<Divider />
					</Row>
				</Col>
			</Row>
		);
	};

	generateQuestionForm = (): ReactNode => {
		const { t: translate } = this.props;

		return (
			this.state.questions &&
			this.state.questions.map((question: any, index: number) => {
				return (
					<Row key={index}>
						<Col span={24}>
							<Row>
								<Col span={24}>
									<Text fontSize="12" lineHeight="20" className="color-gray-7">
										{`${translate(`${TRANSLATION_BASE_PATH}._QUESTION_NUMBER`)} ${index + 1}`}
									</Text>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										<HtmlInput name={`question-${index}`} readOnly value={question.questionTitle} />
									</Text>
								</Col>
							</Row>
							{question.QuestionFile ? (
								<>
									<Row>
										<Col span={12}>
											<AnswerImagePreview file={question.QuestionFile} />
										</Col>
									</Row>
									<Divider />
								</>
							) : null}
							<Row className="mt-8">
								<Col span={24}>{this.generateQuestionAnswers(question)}</Col>
							</Row>
							{this.state.isChecked && this.createAlertForAnyAnswer(question.id)}
						</Col>
						<Col span={24}>
							<Divider />
						</Col>
					</Row>
				);
			})
		);
	};

	getQuestionAnswersAsCheckbox = (question: any, answers: Answer[]): ReactNode => {
		if (answers.findIndex((item: Answer) => item.answerFile !== undefined) > -1) {
			return (
				<Row gutter={[24, 24]}>
					{answers.map((answer: any, index: number) => {
						return (
							<Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
								<Space direction="vertical" className="full__width">
									{answer.answerFile ? (
										<AnswerImagePreview
											file={answer.answerFile}
											showAsSelected={
												this.state.answersByUser.get(question.id).get(answer.id) || false
											}
										/>
									) : null}
									<Checkbox
										id={answer.id}
										checked={this.state.answersByUser.get(question.id).get(answer.id) || false}
										onChange={(e: any) => this.onChangedCheckboxAnswer(question.id, answer.id, e)}
										className="flex__center"
									>
										<HtmlInput name={`answer-${index}`} readOnly value={answer.title} />
									</Checkbox>
								</Space>
							</Col>
						);
					})}
				</Row>
			);
		} 
		return answers.map((answer: any, index: number) => {
			return (
				<Row key={index}>
					<Col span={24}>
						<Checkbox
							id={answer.id}
							checked={this.state.answersByUser.get(question.id).get(answer.id) || false}
							onChange={(e: any) => this.onChangedCheckboxAnswer(question.id, answer.id, e)}
							className="flex__center"
						>
							<HtmlInput name={`answer-${index}`} readOnly value={answer.title} />
						</Checkbox>
					</Col>
				</Row>
			);
		});
		
	};

	generateQuestionAnswers = (question: any): ReactNode => {
		return question && question.questionType === "singleChoice" ? (
			<Radio.Group
				value={this.state.answersByUser.get(question.id).get("radioSelectedButton") || ""}
				onChange={(e: any) => this.onChangedRadioButtonAnswer(question.id, e)}
			>
				{question.answers ? getQuestionAnswersAsRadio(question.answers) : null}
			</Radio.Group>
		) : question.answers ? (
			this.getQuestionAnswersAsCheckbox(question, question.answers)
		) : null;
	};

	render() {
		const { t: translate } = this.props;

		return (
			<Skeleton active loading={this.state.loading}>
				{this.state.lessonQuiz && (
					<Row>
						<Col span={24}>
							<Row gutter={[0, 16]}>
								<Col span={24}>
									<LessonItem
										id={this.state.lessonQuiz.id}
										name={this.state.lessonQuiz.name}
										lessonType={this.state.lessonQuiz.lessonType}
									/>
								</Col>
							</Row>
							<Row className="quizMaterial__scene quizMaterial__body_quiz">
								<Col span={24}>
									{this.state.isChecked && this.state.checkedModal && this.createHeaderTest()}
									{!this.state.isVideoMaterial && (
										<Row gutter={[0, 16]}>
											<Col span={18} offset={4}>
												{this.generateQuestionForm()}
											</Col>
										</Row>
									)}
								</Col>
							</Row>
							<Divider />
							<Row gutter={[0, 16]}>
								<Col span={12}>
									<Row justify="start">
										{!this.state.isChecked && (
											<Button
												icon="ri-check-line"
												htmlType="button"
												type="primary"
												onClick={() => this.submitAnswers()}
											>
												{translate(`${TRANSLATION_BASE_PATH}._CHECK_ANSWERS`)}
											</Button>
										)}
										{this.state.isChecked && (
											<Button
												icon="ri-refresh-line"
												htmlType="button"
												type="default"
												onClick={() => this.resetQuiz()}
											>
												{translate(`${TRANSLATION_BASE_PATH}._RESET_QUIZ`)}
											</Button>
										)}
									</Row>
								</Col>
								<Col span={12}>
									<Row justify="end">
										<Button
											icon="ri-arrow-right-line"
											htmlType="button"
											type="default"
											iconPosition="right"
											className="right"
											onClick={() => this.nextLesson()}
										>
											{translate(`${TRANSLATION_BASE_PATH}._NEXT_MATERIAL`)}
										</Button>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<Modal
					visible={this.state.modalVisibility}
					closable={true}
					title={(this.state.lessonQuiz && this.state.lessonQuiz.name) || ""}
					okText={"KOT"}
					footer={false}
					onCancel={() => this.onModalAnswerCancel()}
				>
					<Row>
						<Col span={24}>
							<Space direction="vertical" className="full__width">
								<Row align="middle" justify="center">
									<Icon
										type="ri-trophy-line"
										className="color-blue-6 quizMaterial__scene quizMaterial__trophy_icon"
									/>
								</Row>
								<Row align="middle" justify="center">
									<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._FINISHED_QUIZ`)}
									</Text>
								</Row>
								<Row align="middle" justify="center">
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._FINAL_TEST_RESPONSE`, {
											success: this.getRightAnswerCount(),
											outOf: this.state.questions && this.state.questions.length
										})}
									</Text>
								</Row>
							</Space>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col span={24}>
							<Row justify="end">
								<Button htmlType="button" type="primary" onClick={() => this.onModalAnswerOk()}>
									{translate(`${TRANSLATION_BASE_PATH}._GET_ANSWERS`)}
								</Button>
							</Row>
						</Col>
					</Row>
				</Modal>
			</Skeleton>
		);
	}
}

export const QuizMaterial = withTranslation()(QuizMaterialComponent);
