import * as Yup from "yup";
import { generalPasswordRegx } from "../../../../../common/components/utils/Regx";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_SETTINGS._PASSWORD._FORM";

export const passwordFormValidator = (translate: (key: string) => string) =>
	Yup.object({
		newPassword: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.min(8, translate(`${TRANSLATION_BASE_PATH}._PASSWORD_REQUIREMENTS`))
			.matches(generalPasswordRegx, translate(`${TRANSLATION_BASE_PATH}._PASSWORD_REQUIREMENTS`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		repeatPassword: Yup.string()
			.oneOf([Yup.ref("newPassword")], translate(`${TRANSLATION_BASE_PATH}._PASSWORD_NOT_MATCH`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`))
	});
