import React from "react";
import ClassroomService from "../../../../../../../services/administration/classroom.service";
import { Modal, Space } from "antd";
import Icon from "../../../../../../../common/components/general/Icon";
import Text from "../../../../../../../common/components/general/Text";
import { useTranslation } from "react-i18next";

interface PublishClassroomProps {
	classroomId: string;
	onFinish: () => void;
	translate: any;
}

export default function PublishConfirmationModal(props: PublishClassroomProps) {
	const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._LAYOUT";

	const publishClassroom = () => {
		return new ClassroomService()
			.publishClassroom(props.classroomId)
			.then(() => {
				props.onFinish();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	const translate = props.translate;
	return Modal.confirm({
		icon: null,
		title: (
			<Space direction="horizontal" size={16} align="start">
				<Icon type="ri-inbox-archive-line" fontSize="24" className="color-gray-6" />
				<Space direction="vertical" size={8}>
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._PUBLISH_CONFIRM_TITLE`)}
					</Text>
					<Text fontSize="14" lineHeight="22" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._PUBLISH_CONFIRM_DESCRIPTION`)}
						<br />
						<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
							{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
						</a>
					</Text>
				</Space>
			</Space>
		),
		okText: translate(`${TRANSLATION_BASE_PATH}._PUBLISH`),
		okType: "primary",
		cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
		onOk: () => publishClassroom()
	});
};