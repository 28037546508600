import ApiServiceData from "../api.service";
import ProgressLessonEnrollmentDto from "dtos/administration/progressLessonEnrollment.dto";
const relativeUrl = "/v1/api/progress-lesson-enrollments";

export default class ProgressLessonEnrollmentService extends ApiServiceData {
	public createProgressLessonEnrollment(progressLessonEnrollment: ProgressLessonEnrollmentDto) {
		return this.post(relativeUrl, progressLessonEnrollment);
	}

	public countProgressLessonEnrollmentByLessonId(lessonId: string) {
		return this.get(`${relativeUrl}/count/${lessonId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (error && error.response) {
					return null;
				}
				throw error.response;
			});
	}

	public getForLessonsIds(lessonIds: string[]) {
		return this.post(`${relativeUrl}/get-for-lessons/`, lessonIds)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (error && error.response) {
					return null;
				}
				throw error.response;
			});
	}
}
