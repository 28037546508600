import React from "react";
import Text from "common/components/general/Text";
import { message, Row, Space } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import Icon from "common/components/general/Icon";
import i18n from "i18next";

interface InstitutionCodeProps {
	code: string;
}

const onCopy = (textCopied: string) => {
	message.success(
		i18n.t("_ADMIN._MAIN._COPY_CLIPBOARD", {
			code: `${textCopied}`
		})
	);
};

export const InstitutionCode = (props: InstitutionCodeProps) => {
	const toolTipOrgCode = i18n.t("_ADMIN._MAIN._TABLE._ORGANIZATIONS._TOOLTIP_CODE");
	return (
		<Row>
			<Space>
				<Icon type="ri-key-line"></Icon>
				<Text fontSize="12" lineHeight="22" className="color-gray-7 cursor_default" tooltip={toolTipOrgCode}>
					{props.code}
				</Text>
				<CopyToClipboard text={props.code} onCopy={(textCopied: any) => onCopy(textCopied)}>
					<Text fontSize="14" lineHeight="32" className="color-blue-6 cursor_pointer">
						<Icon type="ri-file-copy-line" />
					</Text>
				</CopyToClipboard>
			</Space>
		</Row>
	);
};
