import ApiServiceData from "../api.service";

const relativeUrl = "/v1/api/invitations";

export default class InvitationService extends ApiServiceData {
	public inviteStudents(classroomId: string, emails: string[]): Promise<object> {
		return this.post(relativeUrl + "/invite-students-to-classroom/" + classroomId, {
			emails: emails
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getClassroomInvitations(): Promise<any> {
		return this.get(relativeUrl + "/classroom-invitations")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getOrganizationOwnerInvitations(): Promise<object> {
		return this.get(relativeUrl + "/organization-owner")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getOrganizationAdminInvitations(): Promise<object> {
		return this.get(relativeUrl + "/organization-admin")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getOrganizationTeacherInvitations(): Promise<object> {
		return this.get(relativeUrl + "/organization-teacher")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getClassroomInvitationDetails(invitationId: string): Promise<object> {
		return this.get(relativeUrl + "/classroom-invitations/" + invitationId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public inviteChild(parentId: string, childEmail: string, dateOfBirth: string): Promise<object> {
		return this.post(`${relativeUrl}/invite-child-from-parent/${parentId}/${childEmail}/${dateOfBirth}`, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getParentInvites(parentId: string): Promise<object> {
		return this.get(`${relativeUrl}/get-parent-invites/${parentId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getInviteFromParent(): Promise<object> {
		return this.get(`${relativeUrl}/get-invite-from-parent`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public acceptParentInvite(invitationId: string, parentEmail: string) {
		return this.post(`${relativeUrl}/accept-parent-invite/${invitationId}/${parentEmail}`, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public deleteInvite(invitationId: string) {
		return this.post(`${relativeUrl}/reject-parent-invite/${invitationId}`, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getOrganizationInvite(organizationId: string, email: string) {
		return this.get(`${relativeUrl}/organization-invite/${organizationId}/${email}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getOrganizationOnwerInvite(organizationId: string, email: string) {
		return this.get(`${relativeUrl}/organization-invite/owner/${organizationId}/${email}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getMyOrganizationOnwerInvite(organizationId: string, email: string) {
		return this.get(`${relativeUrl}/my-organization-invite/owner/${organizationId}/${email}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public hasOrganizationOnwerInvite(organizationId: string, email: string) {
		return this.get(`${relativeUrl}/organization-invite/owner/count/${organizationId}/${email}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public resendInvitation(invitationId: string) {
		return this.get(`${relativeUrl}/organization-invite/resend-invitation/${invitationId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
	
	public resendUserInvitation(invitationId: string) {
		return this.get(`${relativeUrl}/resend-invitation/${invitationId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public acceptOrganizationInvite(organizationId: string, email: string) {
		return this.post(`${relativeUrl}/accept-organization-invite/${organizationId}/${email}`, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public rejectOrganizationInvite(organizationId: string, email: string) {
		return this.post(`${relativeUrl}/reject-organization-invite/${organizationId}/${email}`, {})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public inviteOrganizationStaff(users: any[]): Promise<object> {
		return this.post(relativeUrl + "/invite-organization-staff", users)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public inviteAkademiStaff(users: any[]): Promise<object> {
		return this.post(relativeUrl + "/organization-invite/invite", users)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getOrganizationStaffInvites(): Promise<object> {
		return this.get(relativeUrl + "/organization-staff-invites")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
