import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Row } from "antd";
import { Welcome } from "./components/welcome";
import { Success } from "./components/success";
import { CollectData } from "./components/collectData";
import {CollectedDataDto} from "dtos/administration/tutoring/collectedData.dto";

type State = {
	step: number,
	data: CollectedDataDto,
}

class TutoringModalComponent extends Component<any, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			step: 0,
			data: {
				subjects: [],
				level: "",
				grades: [],
				times: [],
				contact: {
					phoneNumber: "",
					reason: ""
				}
			}
		};
	}

	handleClose = () => {
		const { toggleTutoringModal } = this.props;
		toggleTutoringModal();
	}

	nextStep = () => {
		this.setState(prevState => ({ step: prevState.step + 1 }));
	}

	onUpdateData = (newData: object) => {
		this.setState(prevState => ({ ...prevState, data: {...prevState.data, ...newData} }));
	}

	render() {
		const { step, data } = this.state;
		return (
			<Row className="tutoring">
				{ step === 0 && (
					<Welcome next={this.nextStep} onCancel={this.handleClose}/>
				)}
				{ step === 1 && (
					<CollectData
						onCancel={this.handleClose}
						collectedData={data}
						updateData={this.onUpdateData}
						next={this.nextStep}
					/>
				)}
				{ step === 2 && (
					<Success onCancel={this.handleClose}/>
				)}
			</Row>
		);
	}
}

export const TutoringModal = withTranslation()(TutoringModalComponent);