import { Input, message, Modal, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import AccountEmailService from "services/email/account.email.service";

interface NewPasswordModalProps {
	userId: string;
	fullName: string;
	email: string;
	visible: boolean;
	onClose: () => void;
}

const TRANSLATION_BASE_PATH = "_ADMIN._USERS._NEW_PASSWORD";

export const NewPasswordModal = (props: NewPasswordModalProps) => {
	const { t: translate } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [resetPasswordUrl, setPasswordResetUrl] = useState<string>("");

	useEffect(() => {
		const fetchData = () => {
			setLoading(true);
			return new AccountEmailService()
				.generateLinkForResetPassword(props.userId)
				.then(result => {
					setPasswordResetUrl(result);
					return;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		};
		if (props.userId) {
			fetchData();
		}
	}, [props.userId]);

	return (
		<Modal
			title={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
			footer={
				<Button type="default" onClick={props.onClose}>
					{translate(`${TRANSLATION_BASE_PATH}._CLOSE`)}
				</Button>
			}
			onCancel={props.onClose}
			closable
			visible={props.visible}
			destroyOnClose
		>
			<Skeleton active loading={loading}>
				<Space direction="vertical" size={24}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._TO_RESET_PASSWORD_INFO`)}{" "}
						<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
							{props.fullName}
						</Text>{" "}
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							({props.email})
						</Text>{" "}
						{translate(`${TRANSLATION_BASE_PATH}._COPY_LINK_AND_SEND_TO_USER`)}
					</Text>
					<Input.Group compact style={{ display: "flex" }}>
						<Input addonBefore={<Icon type="ri-link" />} readOnly value={resetPasswordUrl} size="large" />
						<CopyToClipboard
							text={resetPasswordUrl}
							onCopy={() => {
								message.success(translate(`${TRANSLATION_BASE_PATH}._COPY_CLIPBOARD`));
							}}
						>
							<Button icon="ri-file-copy-line" size="large" type="primary">
								{translate(`${TRANSLATION_BASE_PATH}._COPY`)}
							</Button>
						</CopyToClipboard>
					</Input.Group>
				</Space>
			</Skeleton>
		</Modal>
	);
};
