import React, { useEffect, useState } from "react";
import { OldSectionSearch } from "./components/sectionSearch/OldSectionSearch";
import { useStoreActions, useStoreState } from "easy-peasy";
import { SectionHeader } from "./components/sectionHeader/SectionHeader";
import { SectionNewCourses } from "./components/sectionNewCourses/SectionNewCourses";
import { SectionMyCourses } from "./components/sectionMyCourses/SectionMyCourses";
import { SectionAllSubjects } from "./components/sectionAllSubjects/SectionAllSubjects";
import { Link } from "react-router-dom";
import { Col, Row, Space } from "antd";
import { SectionSingleSubjectAllItems } from "./components/sectionSingleSubjectAllItems/SectionSingleSubjectAllItems";
import Icon from "common/components/general/Icon";
import { ModalItemFilters } from "scenes/subjects/components/itemFilters/ModalItemFilters";
import { InstructorBanner } from "./components/instructorBanner";

export const MarketPlace = (props: any) => {
	const [searchKey, setSearchKey] = useState<any>(null);
	const [loading, setLoading] = useState(0);
	const activeFilters = useStoreState(state => state.subjects.activeFilters);
	const updateFilters = useStoreActions((actions: any) => actions.subjects.updateFilters);
	const [showFilterModal, updateShowFilterModal] = useState<boolean>(false);

	const handleSearch = (key: string) => {
		setSearchKey(key);
	};

	const handleSearchProgress = (direction: number) => {
		setLoading(loading + direction);
	};

	useEffect(() => {
		updateFilters({ filters: [], items: [] });
		setLoading(0);
	}, []);

	return (
		<>
			<Row className="mb-24">
				<InstructorBanner />
			</Row>
			<Row className="mb-24">
				<Col>
					<SectionHeader />
				</Col>
			</Row>
			<OldSectionSearch toggleFilterBar={updateShowFilterModal} loading={loading > 0} search={handleSearch} />
			{showFilterModal && <ModalItemFilters toggleModal={updateShowFilterModal} />}

			{props.section && (
				<Row>
					<Link to="/subjects">
						<Space>
							<Icon type="ri-arrow-left-line"></Icon>
							Kthehu
						</Space>
					</Link>
				</Row>
			)}
			{(!props.section || props.section === 1) && (
				<SectionNewCourses
					searchKey={searchKey}
					handleSearchProgress={handleSearchProgress}
					filters={activeFilters}
					singleSection={props.section === 1}
				/>
			)}
			{(!props.section || props.section === 2) && (
				<SectionMyCourses
					searchKey={searchKey}
					handleSearchProgress={handleSearchProgress}
					filters={activeFilters}
					singleSection={props.section === 2}
				/>
			)}
			{!props.section && (
				<SectionAllSubjects
					searchKey={searchKey}
					handleSearchProgress={handleSearchProgress}
					filters={activeFilters}
					singleSection={props.section === 3}
					subjectId={props.subjectId}
				/>
			)}
			{props.section === 3 && (
				<SectionSingleSubjectAllItems
					searchKey={searchKey}
					handleSearchProgress={handleSearchProgress}
					filters={activeFilters}
					subjectId={props.subjectId}
				/>
			)}
		</>
	);
};
