import UserDto from "dtos/authentication/user/user.dto";

export class CreateAssignmentSubmittedNotificationDto {
	classroomId!: string;
	assignmentTitle!: string;
	recordId!: string;
	currentUser!: UserDto;
	type!: string;

	constructor(classroomId: string, assignmentTitle: string, recordId: string, currentUser: UserDto) {
		this.classroomId = classroomId;
		this.assignmentTitle = assignmentTitle;
		this.recordId = recordId;
		this.currentUser = currentUser;
		this.type = "ASSIGNMENT_SUBMITTED";
	}

	get studentFullName() {
		return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
	}

	get notificationTitle() {
		return `${this.studentFullName} sapo dorëzoi një detyrë.`;
	}
}
