import React from "react";
import { Space, Row, Col, Dropdown, Menu, Modal } from "antd";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import { LessonType } from "services/domain/administration/LessonType";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/sq";

const TRANSLATION_BASE_PATH = "_SUBJECTS._LESSON_TREE._LESSON_TREE_ITEM";

interface LessonItemProps {
	id: string;
	name: string;
	canBeModified?: boolean;
	lessonType: LessonType;
	lessonClassroomTagId?: string;
	length?: number | null;
	textFontSize?: FontSize | null;
	textLineHeight?: LineHeight | null;
	textWeight?: FontWeight | null;
	textClassName?: string | null;
	onClick?: (item: any) => void;
	isActualLesson?: boolean;
	endDate?: Date | null;
	isEvalualable?: boolean;
	isEvaluated?: boolean;
	openEvaluation?: (lessonId: string) => void;
	onDelete?: (item: any) => void;
	onUngroup?: (item: any) => void;
}

declare type FontSize = "12" | "14" | "16" | "20" | "24" | "30" | "38" | "46" | "56";
declare type LineHeight = "20" | "22" | "24" | "28" | "32" | "38" | "46" | "54" | "64";
declare type FontWeight = "semibold" | "regular";

const openConfirmationModal = (props: any, translate: (key: string) => string) => {
	// e.stopPropagation();
	return Modal.confirm({
		title: translate(`${TRANSLATION_BASE_PATH}._DELETE_TITLE`),
		content: translate(`${TRANSLATION_BASE_PATH}._DELETE_MESSAGE`),
		okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
		cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
		onOk: () => props.onDelete({ lessonId: props.id, lessonType: props.lessonType })
	});
};

const openUngroupConfirmationModal = (props: any, translate: (key: string) => string) => {
	// e.stopPropagation();
	return Modal.confirm({
		title: translate(`${TRANSLATION_BASE_PATH}._DELETE_UNGROUP_TITLE`),
		content: translate(`${TRANSLATION_BASE_PATH}._DELETE_UNGROUP_MESSAGE`),
		okText: translate(`${TRANSLATION_BASE_PATH}._YES_UNGROUP`),
		cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
		onOk: () => props.onUngroup({ lessonId: props.id, lessonClassroomTagId: props.lessonClassroomTagId })
	});
};

const menu = (props: any, translate: (key: string) => string) => (
	<Menu>
		<Menu.Item
			key="0"
			onClick={async e => {
				e.domEvent.stopPropagation();
				props.onClick({ lessonId: props.id, lessonType: props.lessonType });
			}}
		>
			<Space direction="horizontal">
				<Icon type="ri-edit-line" />
				{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
			</Space>
		</Menu.Item>

		<Menu.Item
			key="1"
			onClick={async e => {
				e.domEvent.stopPropagation();
				openConfirmationModal(props, translate);
			}}
		>
			<Space direction="horizontal">
				<Icon type="ri-delete-bin-line" />
				{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
			</Space>
		</Menu.Item>

		{props.lessonClassroomTagId && (
			<Menu.Item
				key="2"
				onClick={async e => {
					e.domEvent.stopPropagation();
					openUngroupConfirmationModal(props, translate);
				}}
			>
				<Space direction="horizontal">
					<Icon type="ri-link-unlink" />
					{translate(`${TRANSLATION_BASE_PATH}._UNGROUP`)}
				</Space>
			</Menu.Item>
		)}
	</Menu>
);

const getLesson = (props: LessonItemProps, translate: any) => {
	moment.locale("sq");
	return (
		<Row>
			<Col span={props.canBeModified ? 20 : 22}>
				<Row justify="start">
					<Space direction="horizontal" size={24}>
						<TypeIcon type={props.lessonType} />
						<Text
							fontSize={props.textFontSize || "24"}
							lineHeight={props.textLineHeight || "32"}
							wheight={props.textWeight || "regular"}
							className={props.isActualLesson ? "color-blue-1" : props.textClassName || "color-gray-8"}
						>
							{props.name}
						</Text>
					</Space>
				</Row>
			</Col>
			<Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} className="pt-4">
				{props.isEvalualable && !props.isEvaluated && (
					<Row justify="end" align="middle" className="full__height">
						<Button
							htmlType="button"
							type="primary"
							className="color-blue-6"
							onClick={() => props.openEvaluation && props.openEvaluation(props.id)}
						>
							{translate(`${TRANSLATION_BASE_PATH}._EVALUATE`)}
						</Button>
					</Row>
				)}
				{props.isEvalualable && props.isEvaluated && (
					<Row justify="end" align="middle" className="full__height">
						<a onClick={() => props.openEvaluation && props.openEvaluation(props.id)}>
							<Text fontSize={"12"} lineHeight={"20"} className={"color-gray-7"}>
								{translate(`${TRANSLATION_BASE_PATH}._DETAILS`)}
							</Text>
						</a>
					</Row>
				)}
			</Col>
			{props.canBeModified && (
				<Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
					<Row justify="end">
						<Col>
							<Dropdown.Button
								buttonsRender={() => [
									<></>,
									<Button
										key="1"
										type="ghost"
										className="lessonItem__lessonList-button"
										onClick={e => e.stopPropagation()}
									>
										<Icon type="ri-more-2-line" />
									</Button>
								]}
								trigger={["click"]}
								overlay={menu(props, translate)}
								icon={<Icon type="ri-more-2-line" />}
							/>
						</Col>
					</Row>
				</Col>
			)}
		</Row>
	);
};

export const LessonItem = (props: LessonItemProps) => {
	const { t: translate } = useTranslation();
	return (
		<>
			{props.onClick && !props.canBeModified ? (
				<a href="" onClick={props.onClick} className="full__width">
					{getLesson(props, translate)}
				</a>
			) : (
				getLesson(props, translate)
			)}
		</>
	);
};

LessonItem.defaultProps = {
	isActualLesson: false,
	canBeModified: true
};
