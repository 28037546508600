import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Input, Space } from "antd";
import moment from "moment";
import { useField, useFormikContext } from "formik";

import Select from "common/components/dataEntry/components/Select";
import Option from "common/components/dataEntry/components/Option";
import Icon from "common/components/general/Icon";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import { getListOfYears } from "common/utils/DateUtils";
import { getArrayOfNumbers } from "common/utils/NumberUtils";
import Error from "common/components/dataEntry/formik/FormItemError";
import Label from "common/components/dataEntry/components/Label";

import NoDataIcon from "assets/images/icons/NoData.png";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

interface GroupDatePickerFieldProps {
	name: string;
	label?: string;
	className?: string;
	disabled?: boolean;
	size?: "large" | "middle";
}

const suffixIcon = <Icon type="ri-arrow-down-s-line" className="color-gray-6" />;

const customizeRenderEmpty = (translate: (key: string) => string): ReactNode => (
	<Space direction="vertical" align="center">
		<Image src={NoDataIcon} style={{ width: "50px" }} />
		<Text fontSize="14" lineHeight="20" className="color-gray-7">
			{translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
		</Text>
	</Space>
);

export const FormikGroupDatePickerField = (props: GroupDatePickerFieldProps) => {
	const { t: translate } = useTranslation();
	const [, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.name;
	const [initialized, setInitialized] = useState<boolean>(false);

	useEffect(() => {
		if (meta.initialValue) {
			const date = moment(meta.initialValue);
			context.setFieldValue(`${props.name}_date`, date.format("DD"));
			context.setFieldValue(`${props.name}_month`, date.format("MM"));
			context.setFieldValue(`${props.name}_year`, date.format("YYYY"));
			setInitialized(true);
		} else {
			setInitialized(true);
		}
	}, []);

	const dateFormat = "YYYY-MM-DD";

	const onBlur = (): void => {
		context.setFieldTouched(props.name);
	};

	const onDateChange = (value: any) => {
		const monthMeta = context.getFieldMeta(`${props.name}_month`);
		const yearMeta = context.getFieldMeta(`${props.name}_year`);

		context.setFieldTouched(props.name);
		context.setFieldValue(`${props.name}_date`, value);

		if (monthMeta.value !== undefined && yearMeta.value !== undefined) {
			const selectedDate = moment.utc(`${yearMeta.value}-${monthMeta.value}-${value}`, dateFormat);
			if (selectedDate.isValid()) {
				context.setFieldValue(props.name, selectedDate.format(dateFormat));
			} else {
				context.setFieldValue(props.name, "Invalid Date");
			}
		} else {
			context.setFieldValue(props.name, undefined);
		}
	};

	const onMonthChange = (value: any) => {
		const dateMeta = context.getFieldMeta(`${props.name}_date`);
		const yearMeta = context.getFieldMeta(`${props.name}_year`);

		context.setFieldTouched(props.name);
		context.setFieldValue(`${props.name}_month`, value);

		if (dateMeta.value !== undefined && yearMeta.value !== undefined) {
			const selectedDate = moment.utc(`${yearMeta.value}-${value}-${dateMeta.value}`, dateFormat);
			if (selectedDate.isValid()) {
				context.setFieldValue(props.name, selectedDate.format(dateFormat));
			} else {
				context.setFieldValue(props.name, "Invalid Date");
			}
		} else {
			context.setFieldValue(props.name, undefined);
		}
	};

	const monthFilterOption = (inputValue: string, option?: any): boolean => {
		return (
			option.key.toLowerCase().includes(inputValue.toLowerCase()) ||
			option.children.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const onYearChange = (value: any) => {
		const dateMeta = context.getFieldMeta(`${props.name}_date`);
		const monthMeta = context.getFieldMeta(`${props.name}_month`);

		context.setFieldTouched(props.name);
		context.setFieldValue(`${props.name}_year`, value);

		if (dateMeta.value !== undefined && monthMeta.value !== undefined) {
			const selectedDate = moment.utc(`${value}-${monthMeta.value}-${dateMeta.value}`, dateFormat);
			if (selectedDate.isValid()) {
				context.setFieldValue(props.name, selectedDate.format(dateFormat));
			} else {
				context.setFieldValue(props.name, "Invalid Date");
			}
		} else {
			context.setFieldValue(props.name, undefined);
		}
	};

	return initialized ? (
		<div>
			<Label htmlFor={id} className="hand-on-hover">
				{props.label}
			</Label>
			<ConfigProvider renderEmpty={() => customizeRenderEmpty(translate)}>
				<Input.Group compact size="default">
					<Select
						placeholder={translate(`${TRANSLATION_BASE_PATH}._DATE`)}
						size={props.size ?? "large"}
						listHeight={200}
						className="birthday__field_date"
						showSearch
						id={`${props.name}_date`}
						onBlur={onBlur}
						onChange={onDateChange}
						suffixIcon={suffixIcon}
						disabled={props.disabled}
						defaultValue={
							context.getFieldMeta(`${props.name}_date`).value
								? `${context.getFieldMeta(`${props.name}_date`).value}`
								: undefined
						}
					>
						{getArrayOfNumbers(31).map(item => (
							<Option key={item} value={item} size={props.size ?? "large"}>
								{item}
							</Option>
						))}
					</Select>

					<Select
						placeholder={translate(`${TRANSLATION_BASE_PATH}._MONTH`)}
						size={props.size ?? "large"}
						listHeight={200}
						className="birthday__field_month"
						showSearch
						id={`${props.name}_month`}
						onBlur={onBlur}
						onChange={onMonthChange}
						filterOption={monthFilterOption}
						suffixIcon={suffixIcon}
						disabled={props.disabled}
						defaultValue={
							context.getFieldMeta(`${props.name}_month`).value
								? `${context.getFieldMeta(`${props.name}_month`).value}`
								: undefined
						}
					>
						{getArrayOfNumbers(12).map(item => (
							<Option key={item} value={item} size={props.size ?? "large"}>
								{translate(`${TRANSLATION_BASE_PATH}._MONTH_${item}`)}
							</Option>
						))}
					</Select>
					<Select
						placeholder={translate(`${TRANSLATION_BASE_PATH}._YEAR`)}
						size={props.size ?? "large"}
						listHeight={200}
						className="birthday__field_year"
						showSearch
						id={`${props.name}_year`}
						onBlur={onBlur}
						onChange={onYearChange}
						suffixIcon={suffixIcon}
						disabled={props.disabled}
						defaultValue={
							context.getFieldMeta(`${props.name}_year`).value
								? `${context.getFieldMeta(`${props.name}_year`).value}`
								: undefined
						}
					>
						{getListOfYears((Number(moment().format("YYYY")) - 100)).map(item => (
							<Option key={item} value={item} size={props.size ?? "large"}>
								{item}
							</Option>
						))}
					</Select>
				</Input.Group>
			</ConfigProvider>
			{error}
		</div>
	) : null;
};
