import { Button, Card, Space } from "antd";
import React from "react";
import { DeleteOutlined, EditOutlined, FileAddOutlined, LinkOutlined } from "@ant-design/icons";
import { previewFile } from "../../../../utils/FileUtils";

interface FileCardComponentProps {
	material: any;
	editMaterial: (material: any) => void;
	deleteMaterial: (material: any) => void;
}

export const FileCardComponent = (props: FileCardComponentProps) => {
	return (
		<Card
			size="small"
			title={props.material.title}
			extra={
				<>
					<a onClick={() => props.editMaterial(props.material)}>
						<EditOutlined />
					</a>
					<a onClick={() => props.deleteMaterial(props.material)} style={{ marginLeft: 5 }}>
						<DeleteOutlined />
					</a>
				</>
			}
		>
			{props.material.type === "url" ? (
				<Space size={0}>
					<LinkOutlined />
					<Button href={props.material.path} target="_blank" type="link" rel="noopener noreferrer">
						{props.material.path}
					</Button>
				</Space>
			) : (
				<Space size={0}>
					<FileAddOutlined />
					<Button
						target="_blank"
						type="link"
						rel="noopener noreferrer"
						onClick={async () => {
							const urlObj = await previewFile(props.material.File);
							const url = urlObj.url;
							window.open(url, "_blank");
						}}
					>
						{props.material.File?.name}
					</Button>
				</Space>
			)}
		</Card>
	);
};
