import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb as AntdBreadcrumb } from "antd";
import { HomeFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GroupType, MarketplaceStore } from "./PublicMarketplace";
import styled from "styled-components";
import Text from "../../common/components/general/Text";

interface BreadcrumbProps {
	resetFilters: (defaultValues?: Pick<MarketplaceStore, any>) => void;
	group?: GroupType;
}


const Breadcrumb = (props: BreadcrumbProps) => {
	const MARKETPLACE_COURSE_CATEGORIES = "_MARKETPLACE._COURSE_CATEGORIES";
	const { t: translate } = useTranslation();

	const BreadCrumbText: React.FC = (props: any) => {
		return <Text {...props} fontSize="16" lineHeight="32" className="color-blue-6 cursor_pointer">
			{props.children}
		</Text>;
	};

	return <StyledBreadcrumbWrapper>
		<AntdBreadcrumb className="marketplace-breadcrumb">
			<AntdBreadcrumb.Item>
				<Link to={"/shiko-mplc"}>
					<BreadCrumbText>
						<HomeFilled onClick={props.resetFilters} />
						{!props.group && <div onClick={props.resetFilters} className="marketplace__clear-filters">
							{translate("_MARKETPLACE._CLEAR_FILTERS")}
						</div>}
					</BreadCrumbText>
				</Link>
			</AntdBreadcrumb.Item>
			{props.group === "all" && (
				<AntdBreadcrumb.Item onClick={() => props.resetFilters({ group: "all" })}>
					<BreadCrumbText>
						{translate("_MARKETPLACE._SHOW_ALL_COURSES")}
					</BreadCrumbText>
				</AntdBreadcrumb.Item>
			)}
			{props.group === "featured" && (
				<AntdBreadcrumb.Item onClick={() => props.resetFilters({ group: "featured" })}>
					<BreadCrumbText>
						{translate(`${MARKETPLACE_COURSE_CATEGORIES}._FEATURED`)}
					</BreadCrumbText>
				</AntdBreadcrumb.Item>
			)}
			{props.group === "trending" && (
				<AntdBreadcrumb.Item onClick={() => props.resetFilters({ group: "trending" })}>
					<BreadCrumbText>
						{translate(`${MARKETPLACE_COURSE_CATEGORIES}._TRENDING`)}
					</BreadCrumbText>
				</AntdBreadcrumb.Item>
			)}
			{props.group === "popular" && (
				<AntdBreadcrumb.Item onClick={() => props.resetFilters({ group: "popular" })}>
					<BreadCrumbText>
						{translate(`${MARKETPLACE_COURSE_CATEGORIES}._POPULAR`)}
					</BreadCrumbText>
				</AntdBreadcrumb.Item>
			)}
		</AntdBreadcrumb>
	</StyledBreadcrumbWrapper>;
};

export default Breadcrumb;


const StyledBreadcrumbWrapper = styled.div`
	.marketplace-breadcrumb {
		margin-bottom: 10px;
	}
	.marketplace__clear-filters {
		display: inline-block;
		margin-left: 10px;
	}
`;