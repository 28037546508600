import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { MainLayout } from "./components/layout/Layout";
import TeacherCourses, {COURSES_PATH} from "./scenes/courses/TeacherCourses";
import Calendar, { CALENDAR_PATH } from "./scenes/calendar/index";
import Settings, { SETTINGS_PATH } from "./scenes/settings/index";
import Organization, { ORGANIZATION_PATH } from "./scenes/organization";
import AuthorizedGuardedRoute, { TypeOfOrganization } from "App.AuthorizedRoute";
import { Role } from "services/domain/login/Role";
import DynamicLayout from "../../common/components/utils/DynamicLayout";
import OAuth2Service from "../../services/authentication/oauth2.service";
import TeacherDashboard from "./scenes/dashboard/TeacherDashboard";
import StudentDashboard from "./scenes/dashboard/StudentDashboard";
import StudentCourses from "./scenes/courses/studentCourses";

export const MAIN_PATH = "main";
export const DASHBOARD_PATH = "dashboard";

const Main = () => {
	return (
		<>
			<Switch>
				<Route

					path={`/${MAIN_PATH}/${DASHBOARD_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							templateName={OAuth2Service.isTeacher ? "kreuMesues" : "kreuNxenes"}
							Pages={OAuth2Service.isTeacher ? { kreuMesues1: TeacherDashboard } : { kreuNxenes1: StudentDashboard }}
							DefaultPage={OAuth2Service.isTeacher ? TeacherDashboard : StudentDashboard}
						/>
					)}
				/>
				<Route
					path={`/${MAIN_PATH}/${COURSES_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							templateName={OAuth2Service.isTeacher ? "klasaMesues" : "klasaNxenes"}
							Pages={OAuth2Service.isTeacher ? { klasaMesues: TeacherCourses } : { klasaNxenes: StudentCourses }}
							DefaultPage={OAuth2Service.isTeacher ? TeacherCourses : StudentCourses}
						/>
					)}
				/>
				<Route
					path={`/${MAIN_PATH}/${CALENDAR_PATH}`}
					exact
					component={(props: any) => (
						<DynamicLayout
							{...props}
							templateName={"calendar"}
							Pages={{calendar: Calendar}}
							DefaultPage={Calendar}
						/>
					)}
				/>
				<Route path={`/${MAIN_PATH}/${SETTINGS_PATH}`} component={Settings} />
				<MainLayout showMarketPlace={true} showAddCourse={true}>
					<AuthorizedGuardedRoute
						allowed={[
							{
								role: Role.Owner,
								type: TypeOfOrganization.Child
							},
							{
								role: Role.Admin,
								type: TypeOfOrganization.Child
							}
						]}
						path={`/${MAIN_PATH}/${ORGANIZATION_PATH}`}
						component={Organization}
					/>
					<Route
						path={`/${MAIN_PATH}`}
						exact
						component={() => <Redirect to={`/${MAIN_PATH}/${DASHBOARD_PATH}`} />}
					/>
				</MainLayout>
				<Route
					path={`/${MAIN_PATH}/*`}
					component={() => <Redirect to={`/${MAIN_PATH}/${DASHBOARD_PATH}`} />}
				/>
			</Switch>
		</>
	);
};

export default Main;
