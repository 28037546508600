import React from "react";
import { Drawer, Row, Col, Form, Space } from "antd";
import Button from "common/components/general/Button";
import Icon from "../general/Icon";

interface CreateEditDrawerProps {
	title: string;
	visible: boolean;
	width?: number;
	onClose?: () => void;
	onSave?: () => void;
	form?: any;
	footer?: any;
	submitting?: boolean;
	saveBtnTitle?: string;
	saveBtnDisabled?: boolean;
	className?: string;
}

export const CreateEditDrawer = (props: CreateEditDrawerProps) => {
	const icon = props.submitting ? "" : <Icon type="ri-save-line"></Icon>;

	return (
		<>
			<Drawer
				title={props.title}
				placement="right"
				closable={true}
				onClose={props.onClose}
				visible={props.visible}
				bodyStyle={{ paddingBottom: 80 }}
				width={props.width ?? 250}
				destroyOnClose={true}
				className={props.className}
				footer={
					props.footer || (
						<Row justify="end">
							<Col>
								<Button onClick={props.onClose} className="mr-16">
									Anullo
								</Button>
							</Col>
							<Col>
								<Button
									onClick={props.onSave}
									type="primary"
									htmlType="submit"
									iconPosition="right"
									className="full_width_button"
									disabled={props.submitting || props.saveBtnDisabled}
									loading={props.submitting}
								>
									{" "}
									{props.saveBtnTitle ? (
										props.saveBtnTitle
									) : (
										<Space>
											{icon}
											Ruaj
										</Space>
									)}
								</Button>
							</Col>
						</Row>
					)
				}
			>
				<Form layout="vertical">{props.form}</Form>
			</Drawer>
		</>
	);
};
