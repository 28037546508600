import { v4 as uuidv4 } from "uuid";
import WeekDto from "./week.dto";
import { LessonType } from "services/domain/administration/LessonType";
import SubjectPlanTreeDto from "./subjectPlanTree.dto";
import LessonSectionDto from "./lessonSection.dto";
import FileDto from "./file.dto";

export default class LessonDto {
	readonly id!: string;
	readonly name!: string;
	readonly description?: string;
	readonly videoUrl?: string;
	readonly startDate?: Date;
	readonly endDate?: Date;
	readonly lessonType!: LessonType;
	readonly Week!: WeekDto;
	readonly LessonSections!: LessonSectionDto[];
	readonly SubjectPlanTrees?: SubjectPlanTreeDto[];
	readonly lessonMetaInfo?: string;
	readonly File?: FileDto;
	readonly AssignmentEvaluation?: any;

	constructor(data?: {
		name: string;
		description?: string;
		videoUrl: string;
		subjectType: LessonType;
		week: WeekDto;
		startDate?: Date;
		endDate?: Date;
		LessonSections: LessonSectionDto[];
		SubjectPlanTrees: SubjectPlanTreeDto[];
		lessonMetaInfo?: string;
		File?: FileDto;
		AssignmentEvaluation?: any;
		[key: string]: any;
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
