import { Card, Col, Empty, Row, Skeleton, Space, Tooltip, Table } from "antd";
import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import LessonDto from "dtos/administration/lesson.dto";
import SubjectPlanTreeDto from "dtos/administration/subjectPlanTree.dto";
import AssignmentUserCommitService from "services/administration/assignmentUserCommit.service";
import { SUBJECTS_PATH } from "scenes/subjects";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";
import { LESSON_PATH } from "scenes/subjects/scenes/materialItems/scenes/lessonMaterial/LessonMaterial";
import { ListCard } from "./ListCard";

const TRANSLATION_BASE_PATH = "_COURSE._COURSE_DETAILS._ASSIGNMENT";

class AsignmentsComponent extends Component<any, any> {
	private columns: any = [];

	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			classroom: null,
			assignmentLesons: []
		};
		const { t: translate } = this.props;
		this.columns = [{
			title: translate(`${TRANSLATION_BASE_PATH}._NAME`),
			dataIndex: "name",
			key: "name",
			width: "37.5%",
			render: (name: any) => this.getNameColumns(name)
		},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._DATE`),
				dataIndex: "endDate",
				key: "endDate",
				width: "25%",
				render: (endDate: any) => this.getDateColumns(endDate)
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._COMMITTED`),
				dataIndex: "isCommitted",
				key: "isCommitted",
				width: "12.5%",
				align: "center",
				render: (isCommitted: boolean, record: any) => this.getIsCommittedColumns(isCommitted, record.endDate)
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._STATUS`),
				dataIndex: "isEvaluated",
				key: "isEvaluated",
				align: "center",
				width: "12.5%",
				render: (isEvaluated: boolean, record: any) =>
					this.getIsEvaluatedColumns(isEvaluated, record.endDate, record.isCommitted)
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._GRADE`),
				dataIndex: "grade",
				key: "grade",
				align: "center",
				width: "12.5%",
				render: (grade: string, record: any) =>
					this.getGradeColumns(grade, record.endDate, record.isCommitted, record.isEvaluated)
			}];
	}

	componentDidMount() {
		this.loadCourseItem();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if ((prevState.classroom && prevState.classroom.id) !== (this.state.classroom && this.state.classroom.id))
			this.loadCourseItem();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if ((nextProps.classroom && nextProps.classroom.id) !== (prevState.classroom && prevState.classroom.id))
			return { classroom: nextProps.classroom };
		return null;
	}

	loadCourseItem = async () => {
		this.setState({ loading: true });
		const subjectPlanTree: SubjectPlanTreeDto | null = !this.state.classroom.SubjectPlan
			? null
			: await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(this.state.classroom.SubjectPlan.id);
		const assignmentUserCommit = await new AssignmentUserCommitService().getAssignmentUserCommitByUserId();
		const newSubjectPlanTree: LessonDto[] | null = this.retriveLessonsFromTree(subjectPlanTree);
		const assignmentLessons = newSubjectPlanTree?.filter((lesson: any) => lesson.lessonType === "ASSIGNMENT");
		const dataLessonSummary = assignmentLessons?.map((lesson: any) => {
			const userCommit = assignmentUserCommit.find(userCommit => userCommit.LessonId === lesson.id);
			return {
				id: lesson.id,
				name: lesson.name,
				endDate: lesson.endDate,
				isCommitted: userCommit?.isCommitted,
				isEvaluated: userCommit?.isEvaluated,
				grade: userCommit?.grade
			};
		});

		const orderedDataLessonSummary =
			(dataLessonSummary &&
				_.orderBy(
					dataLessonSummary,
					(item: any) => {
						return item.endDate ? moment(item.endDate) : moment(new Date(0));
					},
					["asc"]
				)) ||
			[];

		this.setState({ loading: false, assignmentLesons: orderedDataLessonSummary });
	};

	retriveLessonsFromTree = (subjectPlanTree: SubjectPlanTreeDto | null): LessonDto[] | null => {
		if (!subjectPlanTree) return null;
		const subjectPlanNode: SubjectPlanTreeDto[] = [];
		subjectPlanNode.push(subjectPlanTree);
		const lesson: LessonDto[] = [];
		while (subjectPlanNode.length !== 0) {
			const node: any = subjectPlanNode.pop();
			if (node.children.length !== 0) subjectPlanNode.push(...node.children);
			lesson.push(...node.lessons);
		}
		return lesson;
	};

	getNoDataComponent = (): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row justify="center" gutter={[16, 24]} className="full__width">
				<Col span={24}>
					<Empty description={false}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
						</Text>
					</Empty>
				</Col>
			</Row>
		);
	};

	getNameColumns = (name: any): ReactNode => {
		return (
			<Row>
				<Col span={24}>
					<Row justify="start">
						<Space direction="horizontal" size={24}>
							<TypeIcon type={"ASSIGNMENT"} />
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{name}
							</Text>
						</Space>
					</Row>
				</Col>
			</Row>
		);
	};

	getDateColumns = (date: any): ReactNode => {
		return (
			<Row>
				<Col span={24}>
					<Row justify="start">
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{moment(date).format("DD.MM.YYYY HH:mm")}
						</Text>
					</Row>
				</Col>
			</Row>
		);
	};

	getIsCommittedColumns = (isCommitted: boolean, date: any, justify?: any): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					<Row justify={justify ?? "center"}>
						{isCommitted && (
							<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._COMMITTED`)} placement="top">
								<Icon type="ri-checkbox-circle-fill" className="color-green-6" />
							</Tooltip>
						)}
						{!isCommitted && !this.checkIsAvailable(date) && (
							<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._PASSED_DUE_DATE`)} placement="top">
								<Icon type="ri-close-circle-fill" className="color-red-6" />
							</Tooltip>
						)}
						{!isCommitted && this.checkIsAvailable(date) && (
							<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._UNCOMMITTED`)} placement="top">
								<Icon type="ri-time-fill" className="color-gray-6" />
							</Tooltip>
						)}
					</Row>
				</Col>
			</Row>
		);
	};

	getIsEvaluatedColumns = (isEvaluated: boolean, date: any, isCommitted: boolean, justify?: any): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					<Row justify={justify ?? "center"}>
						{isEvaluated && isCommitted && (
							<Text fontSize="14" lineHeight="22" className="color-green-6">
								{translate(`${TRANSLATION_BASE_PATH}._EVALUATED`)}
							</Text>
						)}
						{isCommitted && !isEvaluated && !this.checkIsAvailable(date) && (
							<Text fontSize="14" lineHeight="22" className="color-orange-6">
								{translate(`${TRANSLATION_BASE_PATH}._EVALUATING`)}
							</Text>
						)}
						{isCommitted && !isEvaluated && this.checkIsAvailable(date) && (
							<Text fontSize="14" lineHeight="22" className="color-geekblue-6">
								{translate(`${TRANSLATION_BASE_PATH}._NOT_EVALUATED`)}
							</Text>
						)}
						{!isCommitted && !this.checkIsAvailable(date) && (
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								--
							</Text>
						)}
						{!isCommitted && this.checkIsAvailable(date) && (
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								-
							</Text>
						)}
					</Row>
				</Col>
			</Row>
		);
	};

	getGradeColumns = (
		grade: string,
		date: any,
		isCommitted: boolean,
		isEvaluated: boolean,
		justify?: any
	): ReactNode => {
		return (
			<Row>
				<Col span={24}>
					<Row justify={justify ?? "center"}>
						{isEvaluated && isCommitted && (
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{grade}
							</Text>
						)}
						{!isCommitted && !this.checkIsAvailable(date) && (
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								--
							</Text>
						)}
						{!isEvaluated && !(!isCommitted && !this.checkIsAvailable(date)) && (
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								-
							</Text>
						)}
					</Row>
				</Col>
			</Row>
		);
	};

	checkIsAvailable = (endDate: any) => {
		const today = moment();
		const dueDate = moment(endDate);
		const difference = today.diff(dueDate, "minutes");
		return difference <= 0;
	};

	onRowClick = (id: string) => {
		return this.props.history.push(
			`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${id}?isAssignmentSection=true`
		);
	};

	render() {
		return (
			<Row justify="center">
				<Col xs={0} sm={22} md={20} lg={16} xl={16} xxl={16}>
					<Skeleton active loading={this.state.loading}>
						<Card size="small">
							<Table
								rowKey={record => record.id}
								onRow={record => {
									return {
										onClick: () => this.onRowClick(record.id)
									};
								}}
								rowClassName="course__assignment table-row-select"
								columns={this.columns}
								dataSource={this.state.assignmentLesons}
								locale={{ emptyText: this.getNoDataComponent() }}
							/>
						</Card>
					</Skeleton>
				</Col>
				<Col xs={22} sm={0} md={0} lg={0} xl={0} xxl={0}>
					<Skeleton active loading={this.state.loading}>
						{(!this.state.assignmentLesons || this.state.assignmentLesons.length === 0) &&
							this.getNoDataComponent()}
						{this.state.assignmentLesons && this.state.assignmentLesons.length > 0 && (
							<ListCard
								items={this.state.assignmentLesons}
								getDate={this.getDateColumns}
								getSubmissionStatus={this.getIsCommittedColumns}
								getStatus={this.getIsEvaluatedColumns}
								getGrade={this.getGradeColumns}
								getName={this.getNameColumns}
							/>
						)}
					</Skeleton>
				</Col>
			</Row>
		);
	}
}

const Asignments = withTranslation()(AsignmentsComponent);
export default Asignments;
