import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";

interface ActiveFiltersProps {
	subjectId?: any,
	hideResults?: boolean,
	hideClear?: boolean,
	noPadding?: boolean,
}

export const ActiveFilters = (props: ActiveFiltersProps) => {
	const { t: translate } = useTranslation();
	const filters = useStoreState(state => state.subjects.activeFilters);
	let activeFilters = useStoreState(state => state.subjects.activeFilters);
	const allItems = useStoreState(state => state.subjects.allItems);
	const updateFilters = useStoreActions((actions: any) => actions.subjects.updateFilters);
	const items = useStoreState(state => state.subjects.items);
	const [itemsFound, setItemsFound] = useState(0);

	const onFilterChange = (item?: any) => {
		if (!item) {
			updateFilters({ filters: [], items: allItems });
			return;
		}
		let temp = [...activeFilters];
		if (
			activeFilters.some((x: any) => {
				return item.id === x.id;
			})
		) {
			temp = activeFilters.filter((x: any) => {
				return x.id !== item.id;
			});
		} else {
			//TODO Add filter to select only one in one group
			temp.push({ id: item.id, name: item.name, parentId: item.ParentId });
		}
		updateFilters({ filters: temp, items: allItems });
	};

	useEffect(() => {
		if (props.subjectId) {
			const currentSubject = items.filter((x: any) => x.id === props.subjectId);
			setItemsFound(currentSubject && currentSubject.length > 0 && currentSubject[0].classrooms.length);
		} else {
			setItemsFound(items.filter((x: any) => x.classrooms.length > 0).length);
		}
	}, [onFilterChange]);

	if (!filters || filters.length === 0) return null;

	return (
		<>
			<Row className={props.noPadding ? "" : "pt-16"}>
				{/* Filter list */}
				<Col xs={24} sm={20}>
					<Space className="flex__wrap">
						{filters.map((filter: any) => {
							return (
								<span key={filter.id} onClick={() => onFilterChange(filter)}>
									<Space
										className={
											"cursor_pointer itemFilters__selected__filter itemFilters__selected__filter__small"
										}
									>
										{filter.name}
										<Icon type="ri-close-line"></Icon>
									</Space>
								</span>
							);
						})}
					</Space>
				</Col>

				{/* Clear filters */}
				{!props.hideClear && <Col xs={24} sm={4}>
					<Row justify="end">
						<Text
							fontSize="12"
							lineHeight="20"
							onClick={() => onFilterChange()}
							className="cursor_pointer color-blue-6 pt-4"
						>
							Hiq filtrat
						</Text>
					</Row>
				</Col>}
			</Row>

			{/* Results */}
			{filters && !props.hideResults && (
				<Row className="pt-8">
					<Col span="24">
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{itemsFound} {+itemsFound === 1 ? "rezultat" : "rezultate"}
						</Text>
					</Col>
				</Row>
			)}
		</>
	);
};
