import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import Jimp from "jimp";
import classnames from "classnames";

import Image from "common/components/general/Image";
import FileDto from "dtos/administration/file.dto";
import { previewFile } from "../../../../../courseBuilder/utils/FileUtils";

interface AnswerImagePreviewProps {
	file: FileDto;
	showAsSelected?: boolean;
}

const transformFileDownload = (imageUrl: any) => {
	return Jimp.read(imageUrl).then(image => {
		return image.getBase64Async(Jimp.MIME_JPEG);
	});
};

export const AnswerImagePreview = (props: AnswerImagePreviewProps) => {
	const [imagePhoto, setImagePhoto] = useState<string | undefined>(undefined);
	useEffect(() => {
		async function fetchData() {
			const response = await previewFile(props.file);
			const photoAsString = await transformFileDownload(response.url);
			setImagePhoto(photoAsString);
		}
		fetchData();
	}, []);

	const imageClassnames = classnames({ image_preview: true, selected_image_preview: props.showAsSelected });

	return (
		<Skeleton
			active
			loading={!imagePhoto}
			paragraph={{
				rows: 1
			}}
		>
			{imagePhoto ? (
				<div className="quiz__answer_preview_image">
					<Image src={imagePhoto} className={imageClassnames} />
				</div>
			) : null}
		</Skeleton>
	);
};
