import React from "react";
import { Row, Col, Divider, Button, Space, Collapse, List } from "antd";
import Title from "antd/lib/typography/Title";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { Level, Grade } from "./index";
import RecordActions from "../../components/recordActions/RecordActions";

const { Panel } = Collapse;

interface SectionLevelsProps {
	levels: Level[];
	btnTitle: string;
	onEditCreate: (level: Level, grade?: Grade) => void;
}

const genExtra = (level: Level, record: Grade, onEdit: any) => (
	<RecordActions
		record={record}
		loadItemAndShowDrawer={() => {
			onEdit(level, record);
		}}
		popConfirm={{
			message: "Konfirmoni fshirjen?",
			confirmtBtnTxt: "Po, fshije",
			cancelBtnTxt: "Anullo",
			onConfirm: () => { },
		}}
	/>
);

const grades = (level: Level, onEditCreate: any) => {
	return level?.grades?.map((grade: Grade) => {
		const data = grade.GradeSubjects.map((x: any) => {
			return {
				name: x.Subject?.name,
				icon: x.Subject?.icon
			};
		});

		return (
			<>
				<Collapse onChange={() => {}}>
					<Panel header={grade?.name} key={grade?.id} extra={genExtra(level, grade, onEditCreate)}>
						<List
							dataSource={data}
							grid={{ gutter: 16, column: 3 }}
							renderItem={item => (
								<List.Item>
									<Space>
										<Icon type={`ri-${item.icon}`}></Icon>
										{item.name}
									</Space>
								</List.Item>
							)}
						/>
					</Panel>
				</Collapse>
				<br />
			</>
		);
	});
};

export const SectionLevels = (props: SectionLevelsProps) => {
	return (
		<>
			{props.levels &&
				props.levels.map((level: Level) => {
					return (
						<>
							<Row>
								<Col xs={24} sm={19} md={20} lg={20} xl={21} xxl={22}>
									<Title level={4}>
										<Space>
											<Icon type={level.icon}></Icon>
											{level.name}
										</Space>
									</Title>
								</Col>
								<Col xs={24} sm={5} md={4} lg={4} xl={3} xxl={2}>
									<Row justify="end">
										<Button
											onClick={() => props.onEditCreate(level)}
											type="primary"
											size="middle"
											block
										>
											<Space>
												<Text fontSize="14" lineHeight="22">
													<Icon type="ri-add-line">
													</Icon>
												</Text>
												{props.btnTitle}
											</Space>
										</Button>
									</Row>
								</Col>
							</Row>
							<Divider />
							{grades(level, props.onEditCreate)}
							<br />
						</>
					);
				})}
		</>
	);
};
