import { Card, Col, Row, Skeleton, Select, Space, List, Tooltip, Empty, Divider } from "antd";
import React, { useEffect, useState } from "react";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";
import { WeekTotalFilter } from "../../../components/weekTotalFilter/WeekTotalFilter";
import Icon from "common/components/general/Icon";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import { formatTotal } from "../../../../admin/util/formatTotal";
import { Pie } from "react-chartjs-2";
import _ from "lodash";
import { showError } from "../../../../../common/utils/Notification";
import AnalyticsClassService from "../../../../../services/analytics/class.service.";
import moment from "moment";
import { AssignmentEvaluationStatus } from "common/components/subject/assignmentEvaluationStatus";

const TRANSLATION_BASE_PATH = "_PARENT._CHILD_STATISTICS._SECTION_VIRTUAL_CLASSES";

export const SectionVirtualClasses = (props: any) => {
	const { t: translate } = useTranslation();
	const allLessonType = "ALL";
	const lessonTypes = [allLessonType, "VIDEO", "QUIZ", "MEETING", "ASSIGNMENT", "PDF"];
	const colors = ["#F5222D", "#FAAD14", "#2063E3", "#722ED1", "#13C2C2"];
	const [loadingData, setLoadingData] = useState(false);
	const [data, setData] = useState<any>([]);
	const [selectedClass, setSelectedClass] = useState<any>(null);
	const [selectedLessonType, setSelectedLessonType] = useState<any>(allLessonType);
	const [lessonTotals, setLessonTotals] = useState<any>([]);

	const aggregateData = (data: any, selectedClass: any) => {
		setLessonTotals([data.filter((x: any) => {
			return x.LessonType === "VIDEO"
				&& x.ClassroomId === selectedClass.ClassroomId;
		}).length,
			data.filter((x: any) => {
				return x.LessonType === "QUIZ"
					&& x.ClassroomId === selectedClass.ClassroomId;
			}).length,
			data.filter((x: any) => {
				return x.LessonType === "MEETING"
					&& x.ClassroomId === selectedClass.ClassroomId;
			}).length,
			data.filter((x: any) => {
				return x.LessonType === "ASSIGNMENT"
					&& x.ClassroomId === selectedClass.ClassroomId;
			}).length,
			data.filter((x: any) => {
				return x.LessonType === "PDF"
					&& x.ClassroomId === selectedClass.ClassroomId;
			}).length]);
	};

	const loadData = () => {
		setLoadingData(true);
		return new AnalyticsClassService().getAsParentChildVirtualClassData(props.child.id)
			.then((items: any) => {
				setSelectedClass(items[0]);
				setData(items);
				aggregateData(items, items[0]);
				return;
			})
			.catch((error) => {
				console.log(error);
				showError("Error");
			})
			.finally(() => {
				setLoadingData(false);
			});
	};

	useEffect(() => {
		loadData();
	}, []);

	const GetLessonTypesDdl = () => {
		return (<Select
			defaultValue={selectedLessonType}
			style={{ width: "250px" }}
			onChange={value => {
				setSelectedLessonType(value);
				GetLessonsList();
			}}
		>
			{lessonTypes.map((item: any) => {
				return (
					<Select.Option value={item} key={item}>
						<Space size={10} align="center">
							{item !== allLessonType && (
								<Text fontSize="12" lineHeight="20">
									<TypeIcon type={item} smallIcon />
								</Text>)
							}
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._${item}`)}
							</Text>
						</Space>
					</Select.Option>
				);
			})}
		</Select>);
	};

	const GetLessonStatusIcon = (item: any) => {
		const formattedDate = moment(item.LessonDueDate).format("DD.MM.YYYY HH:mm");
		const completed = item.LessonStatus === "COMPLETED";
		return (
			<Text fontSize="24" lineHeight="28">
				<Tooltip
					title={completed ? translate(`${TRANSLATION_BASE_PATH}._TOOLTIP._COMPLETED`, { DateCompleted: formattedDate })
						: translate(`${TRANSLATION_BASE_PATH}._TOOLTIP._NOT_COMPLETED`)}>
					<a>
						<Icon
							type={completed ? "ri-check-line" : "ri-error-warning-line"}
							className={completed ? "color-green-6" : "color-red-6"}
						/>
					</a>
				</Tooltip>
			</Text>
		);
	};

	const getEvaluationStatus = (item: any) => {
		if (moment(item.LessonDueDate) >= moment()) {
			return <AssignmentEvaluationStatus status="NOT_EVALUATED" />;
		}
		return <AssignmentEvaluationStatus status="EVALUATING" />;
	};

	const GetLessonsList = () => {
		const filteredData = data
			.filter((x: any) => (x.LessonId)
				&& (x.LessonType === selectedLessonType || selectedLessonType === allLessonType)
				&& (x.ClassroomId === selectedClass.ClassroomId));
		
		if (filteredData.length === 0) {
			return <Empty description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)} />;
		}

		return (
			<List
				dataSource={filteredData}
				pagination={{
					position: "bottom"
				}}
				grid={{ gutter: 16, column: 1 }}
				renderItem={(item: any) => (
					<List.Item>
						<Row className="parent__statistics__virtualClass__item cursor_pointer p-16">
							<Col span="12">
								<Space>
									<TypeIcon type={item.LessonType} />
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{item.LessonName}
									</Text>
								</Space>
							</Col>
							<Col span="12">
								<Row justify="end">
									<Space>
										{item.grade && item.LessonType === "ASSIGNMENT" && (
											<>
												<Space>
													<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._EVALUATION`)}>
														<a>
															<Icon
																type="ri-shield-star-line"
																className="color-blue-6"
																fontSize="20"
															/>
														</a>
													</Tooltip>
													<Text fontSize="16" className="color-gray-8">{item.grade}</Text>
													<Divider type="vertical" />
												</Space>
											</>
										)}
										{item.LessonType === "ASSIGNMENT" && !item.grade && getEvaluationStatus(item)}
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{moment(item.LessonDueDate).format("D MMMM")}
										</Text>
										{item.LessonType !== "MEETING" && GetLessonStatusIcon(item)}
									</Space>
								</Row>
							</Col>
						</Row>
					</List.Item>
				)}
			/>
		);
	};

	const GetClassesDdl = () => {
		const uniqueClasses = _.uniqBy(data, "ClassroomId");
		return (<Select
			defaultValue={selectedClass?.ClassroomId}
			onChange={value => {
				const item = data.filter((x: any) => { return x.ClassroomId === value; })[0];
				setSelectedClass(item);
				GetLessonsList();
				aggregateData(data, item);
			}}
			style={{ width: "250px" }}
		>
			{uniqueClasses.map((item: any) => {
				return (
					<Select.Option value={item.ClassroomId} key={item.ClassroomId}>
						<Space size={10} align="center">
							<Text fontSize="12" lineHeight="20">
								<Icon type="ri-door-open-line" />
							</Text>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{item.ClassroomName}
							</Text>
						</Space>
					</Select.Option>
				);
			})}
		</Select>);
	};

	const GetTeacherName = () => {
		return (
			<Text fontSize="14" lineHeight="22" className="color-gray-8">
				<Space>
					<Icon type="ri-user-2-line" />
					{selectedClass?.ClassroomTeacherName}
				</Space>
			</Text>
		);
	};

	const GetPieChart = () => {
		if (!lessonTotals) return null;
		const titleName = translate(`${TRANSLATION_BASE_PATH}._TITLE_CHART`);
		const labels = [translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._VIDEO`),
		translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._QUIZ`),
		translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._MEETING`),
		translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._ASSIGNMENT`),
			translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._PDF`)];
		
		if (_.sum(lessonTotals) === 0){
			return  <Empty description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)} />;
		}

		const chartData = {
			labels: labels
				.map((label: string, index: number) => {
					return `${formatTotal(lessonTotals[index])} ${label}`;
				}),
			datasets: [{
				backgroundColor: colors,
				data: lessonTotals
			}]
		};

		return (
			<Pie
				data={chartData}
				options={{
					legend: {
						display: false
					},
					title: {
						display: true,
						text: `${titleName}  ${formatTotal(_.sum(lessonTotals))}`,
						fontSize: 16,
						fontColor: "#2d3748"
					}
				}}
			/>
		);
	};

	const GetLegend = () => {
		return (
			<>
				{lessonTypes.filter((x: any) => x !== allLessonType).map((item: any, index: number) => {
					return (
						<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} className="pb-16" key={item}>
							<Row align="middle">
								<Col span="3">
									<Icon type="ri-checkbox-blank-circle-fill" style={{ color: colors[index] }} />
								</Col>
								<Col>
									<Space direction="vertical" size={0}>
										<Text fontSize="14" lineHeight="20">
											{translate(`${TRANSLATION_BASE_PATH}._LESSON_TYPE._${item}`)}
										</Text>
										<Text fontSize="14" lineHeight="20" className="color-gray-9" wheight="semibold">
											{formatTotal(lessonTotals[index])}
										</Text>
									</Space>
								</Col>
							</Row>
						</Col>
					);
				})}
			</>
		);
	};

	return (
		<Card title={null} className="child__statistics__no__top__border">
			<Row>
				<Col span="24">
					<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Col>
			</Row>
			<Row className="pt-24 pb-16">
				<Col xs={0} sm={24} md={24} lg={14} xl={14} xxl={14}>
					<Skeleton loading={loadingData}>
						{selectedClass && (
							<Space>
								{GetClassesDdl()}
								{GetTeacherName()}
							</Space>
						)}
					</Skeleton>
				</Col>
				<Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
					<Skeleton loading={loadingData}>
						{selectedClass && (
							<>
								<Row>
									{GetClassesDdl()}
								</Row>
								<Row className="pt-16">
									{GetTeacherName()}
								</Row>
							</>
						)}
					</Skeleton>
				</Col>
				<Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
					<WeekTotalFilter />
				</Col>
			</Row>
			<Row>
				<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="pt-24">
					<Skeleton loading={loadingData}>
						<Row justify="center">
							{GetPieChart()}
						</Row>
						<Row className="pt-24">
							{GetLegend()}
						</Row>
					</Skeleton>
				</Col>
				<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16} className="pt-24 child__statistics__left__border">
					<Skeleton loading={loadingData}>
						<Row>
							<Col span="12">
								<Text fontSize="16" lineHeight="22" className="color-gray-9" wheight="semibold">
									{translate(`${TRANSLATION_BASE_PATH}._TITLE_LESSONS`)}
								</Text>
							</Col>
							<Col span="12">
								<Row justify="end">
									{GetLessonTypesDdl()}
								</Row>
							</Col>
						</Row>
						<Row className="pt-24" justify="center">
							{GetLessonsList()}
						</Row>
					</Skeleton>
				</Col>
			</Row>
		</Card>
	);
};