import {StudentsCountByLocation} from "../../dtos/analytics/studentsCountByLocation.dto";
import {TeachersCountByLocation} from "../../dtos/analytics/teachersCountByLocation.dto";
import {UserCountByType} from "../../dtos/analytics/userCountByType.dto";
import ApiServiceData from "../api.service";
import { Role } from "../domain/login/Role";
import { getValue } from "./util/GetValue";
const relativeUrl = "/v1/api/analytics/users";

export default class AnalyticsUserService extends ApiServiceData {
	public async countAllByType(): Promise<UserCountByType> {
		return this.get(relativeUrl + "/role/count")
			.then(res => {
				return {
					students: getValue(res.data, "code", Role.Student),
					teachers: getValue(res.data, "code", Role.Teacher),
					parents: getValue(res.data, "code", Role.Parent)
				};
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countStudentsByLocation(): Promise<StudentsCountByLocation> {
		return this.get(relativeUrl + "/count/byLocation/students")
			.then(res => {
				return {
					Albania: getValue(res.data, "nationality", "ALBANIA"),
					Kosovo: getValue(res.data, "nationality", "KOSOVO"),
					Diaspora: getValue(res.data, "nationality", "OTHER"),
					Other: getValue(res.data, "nationality", null)
				};
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countTeachersByLocation(): Promise<TeachersCountByLocation> {
		return this.get(relativeUrl + "/count/byLocation/teachers")
			.then(res => {
				return {
					Albania: getValue(res.data, "nationality", "ALBANIA"),
					Kosovo: getValue(res.data, "nationality", "KOSOVO"),
					Diaspora: getValue(res.data, "nationality", "OTHER"),
					Other: getValue(res.data, "nationality", null)
				};
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countTeachersByCountry(): Promise<any> {
		return this.get(relativeUrl + "/count/byLocation/teachers")
			.then(res => {
				return [getValue(res.data, "nationality", "ALBANIA"),
					getValue(res.data, "nationality", "KOSOVO")];
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countStudentsByCountry(): Promise<any> {
		return this.get(relativeUrl + "/count/byLocation/students")
			.then(res => {
				return [getValue(res.data, "nationality", "ALBANIA"),
					getValue(res.data, "nationality", "KOSOVO")];
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async getTeacherTotalsReport(teacherId: string): Promise<any> {
		return this.get(relativeUrl + "/teacher/totalsReport/" + teacherId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countTeacherTotalStudents(teacherId: string): Promise<any> {
		return this.get(relativeUrl + "/teacher/totalStudents/" + teacherId)
			.then(res => {
				return getValue(res.data, "", undefined);
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async countAllParents(): Promise<number> {
		return this.get(relativeUrl + "/parents/count")
			.then((res: any) => {
				return res.data[0].parentCount;
			})
			.catch(error => {
				throw error.response;
			});
	}	

	public async countAllStudentsByOrganization(): Promise<number> {
		return this.get(relativeUrl + "/students/count/byOrganization")
			.then((res: any) => {
				return res.data[0].count;
			})
			.catch(error => {
				throw error.response;
			});
	}	
}
