import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Dropdown, Layout, Menu, Modal, Row, Skeleton, Space, Tabs, Tooltip } from "antd";
import _ from "lodash";

import Button from "common/components/general/Button";
import TabPane from "common/components/dataDisplay/TabPane";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import ClassroomService from "services/administration/classroom.service";
import { ADMIN_PATH } from "scenes/admin";
import CourseOverview from "../../scenes/CourseOverview/CourseOverview";
import { ASYNCHCLASSES_PATH } from "scenes/admin/scenes/asynch-classes";
import SubjectPlanCreator from "../../scenes/SubjectPlanCreator/SubjectPlanCreator";
import OAuth2Service from "services/authentication/oauth2.service";
import { MAIN_PATH } from "scenes/main";
import { COURSES_PATH } from "scenes/main/scenes/courses/TeacherCourses";
import ClassroomDto from "dtos/administration/classroom.dto";
import AnalyticsClassService from "services/analytics/class.service.";
import CounterCard from "common/components/_counterCard/CounterCard";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import ProgressLessonEnrollmentService from "services/administration/progressLessonEnrollment.service";
import PublishConfirmationModal from "./components/PublishConfirmationModal";

export const RELATED_COURSE_PATH = ":classroomId";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._LAYOUT";

interface AsynchCourseBuilderLayoutState {
	classroomId: string;
	classroom: ClassroomDto | undefined;
	activeTab: string;
	coursesPath: string;
	justCreated?: boolean;
	totalEnrollments?: number;
	cardTotalRegistered: number;
	cardTotalRegisteredLoading: boolean;
	cardTotalCompleted: number;
	cardTotalCompletedLoading: boolean;
}

class AsynchCourseBuilderLayoutComponent extends Component<any, AsynchCourseBuilderLayoutState> {
	constructor(props: any) {
		super(props);
		this.state = {
			classroomId: "",
			classroom: undefined,
			activeTab: "1",
			coursesPath: "",
			justCreated: props.location?.state?.justCreated,
			cardTotalRegistered: 0,
			cardTotalRegisteredLoading: false,
			cardTotalCompleted: 0,
			cardTotalCompletedLoading: false
		};
		this.getBackButton = this.getBackButton.bind(this);
	}

	componentDidMount() {
		const {
			history,
			match: { params }
		} = this.props;
		let asynchCoursesFullPath = `/${ADMIN_PATH}/${ASYNCHCLASSES_PATH}`;
		if (!OAuth2Service.isOwnerOrAdminAtDefaultOrganization) {
			asynchCoursesFullPath = `/${MAIN_PATH}/${COURSES_PATH}`;
		}
		this.setState({ coursesPath: asynchCoursesFullPath });

		const search = this.props.location.search;
		const params2 = new URLSearchParams(search);

		const activeContent = params2.get("content");
		this.setState({ activeTab: activeContent ? "2" : "1" });

		if (!params.classroomId) return history.push(`/${ADMIN_PATH}`);
		this.setState({
			cardTotalCompletedLoading: true,
			cardTotalRegisteredLoading: true
		});
		this.loadClassroom().then(() => {
			this.getTotalCompleted();
			this.getTotalRegistered();
		});
	}

	getTotalCompleted = () => {
		const getAllLessons = (treeData: any) => {
			const treeToArray = (treeData: any) => {
				const toSeeQueue = [];
				toSeeQueue.push(treeData);
				const seen = [];

				while (toSeeQueue.length !== 0) {
					const seenItem: any = toSeeQueue.pop();
					if (seenItem && seenItem.children && seenItem.children.length !== 0) {
						toSeeQueue.push(...seenItem.children);
					}
					seen.push(seenItem);
				}
				return seen;
			};

			const flattenedTree = treeToArray(treeData);
			const normalizedTree =
				flattenedTree &&
				flattenedTree.reduce((obj, item) => {
					if (!item) return obj;
					const newObjec = !obj ? {} : obj;
					newObjec[item.id] = {
						...item,
						children: item.children.map((child: any) => child.id)
					};
					return newObjec;
				}, null);
			const lessons = Object.values(normalizedTree).flatMap((node: any) => node.lessons);
			return lessons;
		};

		if (!this.state.classroom?.SubjectPlan) {
			return this.setState({
				cardTotalCompletedLoading: false,
				cardTotalCompleted: 0
			});
		}
		new SubjectPlanTreeService()
			.getSubjectPlanTreeBySubjectId(this.state.classroom?.SubjectPlan?.id ?? "")
			.then((subjectPlanTree: any) => {
				const lessonIds = getAllLessons(subjectPlanTree).map((item: any) => {
					return item.id;
				});
				const countLessons = lessonIds.length;

				return new ProgressLessonEnrollmentService()
					.getForLessonsIds(lessonIds)
					.then((completitions: any) => {
						let total = 0;
						const completitionsByUser = _.groupBy(completitions, (item: any) => item.createdBy);
						Object.keys(completitionsByUser).forEach(function(key) {
							if (completitionsByUser[key].length >= countLessons) {
								total++;
							}
						});
						return this.setState({
							cardTotalCompleted: total
						});
					})
					.finally(() => {
						this.setState({
							cardTotalCompletedLoading: false
						});
					});
			});
	};

	getTotalRegistered = () => {
		this.setState({
			cardTotalRegistered: this.state.totalEnrollments ?? 0,
			cardTotalRegisteredLoading: false
		});
	};

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.classroomId !== this.state.classroomId) this.loadClassroom();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.classroomId !== prevState.classroomId) return { classroomId: params.classroomId };
		return null;
	}

	loadClassroom = async () => {
		const classroom = await new ClassroomService().getClassroomById(this.state.classroomId);
		if (!classroom) return this.props.history.push(this.state.coursesPath);
		if (!classroom.isAsync) return this.props.history.push(this.state.coursesPath);
		this.setState({ classroom: classroom });
		const enrollments = await new AnalyticsClassService().getClassroomTotalActiveEnrollments(
			this.state.classroomId
		);
		this.setState({ totalEnrollments: enrollments && enrollments.length > 0 ? enrollments[0]?.enrollments : 0 });
	};

	goBack = () => {
		const { history } = this.props;
		history.push(this.state.coursesPath);
	};

	getBackButton = (): React.ReactNode => {
		return (
			<Button onClick={this.goBack} type="text">
				<Space>
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						<Icon type="ri-arrow-left-line" />
					</Text>
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{this.state.classroom?.name}
					</Text>
				</Space>
			</Button>
		);
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	deleteClassroom = () => {
		return new ClassroomService()
			.deleteAsyncClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	archiveClassroom = () => {
		return new ClassroomService()
			.archiveClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	unarchiveClassroom = () => {
		return new ClassroomService()
			.unarchiveClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};


	openDeleteConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: null,
			title: (
				<Space direction="horizontal" size={16} align="start">
					<Icon type="ri-delete-bin-7-line" fontSize="24" className="color-red-6" />
					<Space direction="vertical" size={8}>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._DELETE_ASYNCH_CONFIRM_TITLE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._DELETE_ASYNCH_CONFIRM_DESCRIPTION`)}
						</Text>
					</Space>
				</Space>
			),
			okText: translate(`${TRANSLATION_BASE_PATH}._DELETE`),
			okType: "danger",
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.deleteClassroom()
		});
	};

	openArchiveConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: null,
			title: (
				<Space direction="horizontal" size={16} align="start">
					<Icon type="ri-inbox-archive-line" fontSize="24" className="color-gray-6" />
					<Space direction="vertical" size={8}>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_ASYNCH_CONFIRM_TITLE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_ASYNCH_CONFIRM_DESCRIPTION`)}
							<br />
							<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
								{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
							</a>
						</Text>
					</Space>
				</Space>
			),
			okText: translate(`${TRANSLATION_BASE_PATH}._ARCHIVE`),
			okType: "primary",
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.archiveClassroom()
		});
	};

	openUnarchiveConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: null,
			title: (
				<Space direction="horizontal" size={16} align="start">
					<Icon type="ri-inbox-archive-line" fontSize="24" className="color-gray-6" />
					<Space direction="vertical" size={8}>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._UNARCHIVE_ASYNCH_CONFIRM_TITLE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._UNARCHIVE_ASYNCH_CONFIRM_DESCRIPTION`)}
							<br />
							<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
								{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
							</a>
						</Text>
					</Space>
				</Space>
			),
			okText: translate(`${TRANSLATION_BASE_PATH}._UNARCHIVE`),
			okType: "primary",
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.unarchiveClassroom()
		});
	};

	getRightExtraMenu = () => {
		const { t: translate } = this.props;

		let deleteMenuItem = (
			<Menu.Item key="0" onClick={this.openDeleteConfirmationModal}>
				<Space direction="horizontal">
					<Icon type="ri-delete-bin-7-line" />
					{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
				</Space>
			</Menu.Item>
		);
		let archiveMenuItem = (
			<Menu.Item
				key="1"
				onClick={this.openArchiveConfirmationModal}
			>
				<Space direction="horizontal">
					<Icon type="ri-inbox-archive-line" />
					{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE`)}
				</Space>
			</Menu.Item>
		);
		if (this.state.totalEnrollments && this.state.totalEnrollments > 0) {
			deleteMenuItem = (
				<Menu.Item key="0">
					<Tooltip
						title={translate(`${TRANSLATION_BASE_PATH}._DELETE_ASYNCH_DISABLED_TOOLTIP`)}
						placement="left"
						trigger="hover"
					>
						<div>
							<Space direction="horizontal">
								<Icon type="ri-delete-bin-7-line" className="color-gray-6" />
								<Text fontSize="14" lineHeight="22" className="color-gray-6">
									{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
								</Text>
							</Space>
						</div>
					</Tooltip>
				</Menu.Item>
			);
		}
		if (this.state.classroom?.status === "ARCHIVED") {
			archiveMenuItem = (
				<Menu.Item
					key="1"
					onClick={this.openUnarchiveConfirmationModal}
				>
					<Space direction="horizontal">
						<Icon type="ri-inbox-archive-line" />
						{translate(`${TRANSLATION_BASE_PATH}._UNARCHIVE`)}
					</Space>
				</Menu.Item>
			);
		}

		return (
			<Menu>
				{deleteMenuItem}
				{archiveMenuItem}
			</Menu>
		);
	};

	getCards = () => {
		const { t: translate } = this.props;

		return (
			<>
				<Row gutter={[16, 0]} className="pl-16 pr-16">
					<Col xs={24} sm={12} md={24} lg={24} xl={24} xxl={24}>
						<CounterCard
							title={translate(`${TRANSLATION_BASE_PATH}._CARD_REGISTERED`)}
							icon="ri-eye-line"
							data={this.state.cardTotalRegistered}
							loading={this.state.cardTotalRegisteredLoading}
						/>
					</Col>
					<Col xs={24} sm={12} md={24} lg={24} xl={24} xxl={24}>
						<CounterCard
							title={translate(`${TRANSLATION_BASE_PATH}._CARD_COMPLETED`)}
							icon="ri-checkbox-circle-line"
							data={this.state.cardTotalCompleted}
							loading={this.state.cardTotalCompletedLoading}
						/>
					</Col>
				</Row>
			</>
		);
	};

	getPaneContent = (paneInput: any) => {
		return (
			<Row>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
					<Row justify="center">{this.getCards()}</Row>
				</Col>
				<Col xs={24} sm={24} md={17} lg={17} xl={18} xxl={18}>
					{paneInput}
				</Col>
				<Col xs={0} sm={0} md={7} lg={7} xl={5} xxl={5}>
					{this.getCards()}
				</Col>
			</Row>
		);
	};

	render() {
		const { t: translate } = this.props;

		return (
			<Skeleton active loading={!this.state.classroom}>
				{this.state.classroom && (
					<Layout className="full__min_height background-color-gray-2">
						<Tabs
							centered={true}
							tabBarExtraContent={{
								left: this.getBackButton(),
								right: (
									<Space>
										{this.state.classroom.status === "DRAFT" && (
											<Button type="primary" onClick={() => PublishConfirmationModal({
												classroomId: this.state.classroomId,
												onFinish: this.loadClassroom,
												translate
											})}>

												{translate(`${TRANSLATION_BASE_PATH}._PUBLISH`)}
											</Button>
										)}
										<Dropdown.Button
											buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
											trigger={["click"]}
											overlay={this.getRightExtraMenu()}
										/>
									</Space>
								)
							}}
							className="courseBuilder__courseDetails-tab"
							defaultActiveKey={this.state.activeTab}
						>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._DETAILS`)} key="1">
								{this.getPaneContent(
									<CourseOverview
										classroom={this.state.classroom}
										{...this.props}
										reloadClassroom={this.loadClassroom}
										justCreated={this.state.justCreated}
									/>
								)}
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._CONTENT`)} key="2">
								{this.getPaneContent(
									<SubjectPlanCreator
										classroom={this.state.classroom}
										{...this.props}
										reloadClassroom={this.loadClassroom}
									/>
								)}
							</TabPane>
						</Tabs>
					</Layout>
				)}
			</Skeleton>
		);
	}
}

export const AsynchCourseBuilderLayout = withTranslation()(AsynchCourseBuilderLayoutComponent);
