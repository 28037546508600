import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Menu, Space, Skeleton, Tabs, Card, Empty, Divider, Radio, Modal } from "antd";
import { Link } from "react-scroll";
import _ from "lodash";

import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Icon from "common/components/general/Icon";
import Button from "common/components/general/Button";
import { AdminLessonList } from "common/components/lessonList/AdminLessonList";
import { TeacherLessonList } from "common/components/lessonList/TeacherLessonList";
import { AddLessonModal } from "../../../../components/addLessonModal";
import { MATERIALS_PATH } from "scenes/courseBuilder/scenes/buildMaterials";
import ClassroomDto from "dtos/administration/classroom.dto";
import LessonDto from "dtos/administration/lesson.dto";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import LessonService from "services/administration/lesson.service";
import { LessonType } from "services/domain/administration/LessonType";
import SubjectPlanService from "services/administration/subjectPlan.service";
import OAuth2Service from "services/authentication/oauth2.service";
import ClassroomService from "services/administration/classroom.service";
import { ClassCloneDrawer } from "./ClassCloneDrawer/ClassCloneDrawer";
import TagService from "services/administration/tag.services";
import { CreateEditTagsForm } from "../../../../../admin/scenes/tag-manager/CreateEditForm";
import { showError, showMessage } from "common/utils/Notification";
import { UserAvatar } from "../../../../../../common/components/general/UserAvatar";

const { TabPane } = Tabs;

export const COURSE_BUILDER_PATH = "course-builder";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._SUBJECT_PLAN_CREATOR";

const materialPathBasedOnLessonType = new Map<LessonType, string>([["VIDEO", "lesson"],
	["QUIZ", "quiz"],
	["MEETING", "meeting"],
	["ASSIGNMENT", "assignment"],
	["PDF", "pdf"]]);

class SubjectPlanCreatorScene extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			rootLessonTags: [],
			lessonTagGroup: [],
			tabActiceKey: "0",
			lessons: [],
			subjectPlanId: "",
			isEmptyTree: true,

			isEditModeGroupDrawer: false,
			titleGroupDrawer: "",
			currentTag: undefined,
			submitting: false,
			drawerGroupVisible: false,
			modalVisibility: false,
			lessonModalVisibility: false,
			modifyingNode: "",
			showPopover: false,
			loading: true,
			respectiveAsyncClassroom: null,
			drawerShown: false,
			classContentShown: false,
			classContentLoaded: false,
			formInitialValues: {
				startDate: null
			},
			lessonsToClone: null,
			selectedDateHumanized: null
		};
		this.addVirtualCourseLessonContent = this.addVirtualCourseLessonContent.bind(this);
		this.addAsynchCourseLessonContent = this.addAsynchCourseLessonContent.bind(this);
		this.getDropDownButton = this.getDropDownButton.bind(this);
	}

	toggleDrawer = async () => {
		this.setState({ drawerShown: !this.state.drawerShown });
	};

	async componentDidMount() {
		this.setState({ loading: true });
		return this.loadSubjectPlanTree().finally(() =>
			this.setState({
				loading: false
			})
		);
	}

	fetchAllSubGroupsLesson = async (key: string) => {
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			this.state.rootLessonTags[key]?.id,
			this.props.classroom?.id
		);
		return this.setState({
			lessonTagGroup: _(lessonTagGroup)
				.orderBy((x: any) => Number(x.priority), "asc")
				.value(),
			tabActiceKey: key
		});
	};

	fetchAllSubGroupsLessonTeacher = async (e: any) => {
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			this.state.rootLessonTags[e.target.value.toString()]?.id,
			this.props.classroom?.id
		);
		return this.setState({
			lessonTagGroup: _(lessonTagGroup)
				.orderBy((x: any) => Number(x.priority), "asc")
				.value(),
			tabActiceKey: e.target.value.toString()
		});
	};

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.classroomId !== this.state.classroomId) this.loadSubjectPlanTree();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if (
			nextProps.classroom &&
			nextProps.classroom?.SubjectPlan &&
			nextProps.classroom?.SubjectPlan?.id &&
			nextProps.classroom?.SubjectPlan?.id !== prevState.subjectPlanId
		)
			return { subjectPlanId: nextProps.classroom?.SubjectPlan?.id };
		return null;
	}

	loadSubjectPlanTree = async () => {
		this.setState({ respectiveAsyncClassroom: null });
		const rootLessonTags: any = OAuth2Service.isOwnerOrAdminAtDefaultOrganization
			? await new TagService().getAllRootTagsforAdminLessons(this.props.classroom?.id)
			: await new TagService().getAllRootTagsforLessons();
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			rootLessonTags[this.state.tabActiceKey]?.id,
			this.props.classroom?.id
		);

		const subjectPlan: any =
			this.props.classroom && !this.props.classroom.SubjectPlan
				? await new SubjectPlanService().createSubjectPlanForClassroom(
					this.props.classroom.name,
					this.props.classroom.id
				  )
				: this.props.classroom.SubjectPlan;

		const subjectPlanTree = await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(subjectPlan.id);
		const lessons = this.getAllLessons(subjectPlanTree);

		let classroom: ClassroomDto | null = null;
		if (lessons?.length < 1 && !OAuth2Service.isOwnerOrAdminAtDefaultOrganization)
			classroom = await new ClassroomService().getClassroomByGradeSubjectId(this.props.classroom.GradeSubjectId);

		return this.setState(
			{
				subjectPlanId: subjectPlan.id,
				lessons: lessons,
				isEmptyTree: lessons?.length < 1,
				rootLessonTags: rootLessonTags,
				lessonTagGroup: _(lessonTagGroup)
					.orderBy((x: any) => Number(x.priority), "asc")
					.value(),
				respectiveAsyncClassroom: classroom,
				currentTag: undefined
			},
			() => this.props.reloadClassroom()
		);
	};

	getAllLessons(treeData: any) {
		const flattenedTree = this.treeToArray(treeData);
		const normalizedTree =
			flattenedTree &&
			flattenedTree.reduce((obj, item) => {
				if (!item) return obj;
				const newObjec = !obj ? {} : obj;
				newObjec[item.id] = {
					...item,
					children: item.children.map((child: any) => child.id)
				};
				return newObjec;
			}, null);
		const lessons = Object.values(normalizedTree).flatMap((node: any) => node.lessons);
		return lessons;
	}

	treeToArray = (treeData: any) => {
		const toSeeQueue = [];
		toSeeQueue.push(treeData);
		const seen = [];

		while (toSeeQueue.length !== 0) {
			const seenItem: any = toSeeQueue.pop();
			if (seenItem && seenItem.children && seenItem.children.length !== 0) {
				toSeeQueue.push(...seenItem.children);
			}
			seen.push(seenItem);
		}
		return seen;
	};

	addLesson = async (name: string, description: string, duration: string[]) => {
		const lessonService = new LessonService();
		const parentId = this.state.modifyingNode;

		await lessonService.createNewLesson(
			{
				name: name,
				description: description,
				startDate: duration ? new Date(duration[0]).toISOString() : undefined,
				endDate: duration ? new Date(duration[1]).toISOString() : undefined
			},
			parentId
		);

		return this.setState(
			{
				lessonModalVisibility: false
			},
			() => {
				return lessonService.getLessonsForSubjectPlanTree(this.state.modifyingNode).then(test => {
					return this.setState({
						lessons: test.data,
						showContent: true
					});
				});
			}
		);
	};

	onDeleteGroup = (classroomTagId: string) => {
		return new TagService().deleteClassroomTagId(classroomTagId, this.props.classroom.id).finally(() => {
			this.fetchAllSubGroupsLesson(this.state.tabActiceKey);
		});
	};

	onClickLesson = (item: any) => {
		const materialPath = materialPathBasedOnLessonType.get(item.lessonType);
		const object = new LessonDto();
		this.props.history.push(
			`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/${materialPath}/${
				item.lessonId ? item.lessonId : object.id
			}?subjectPlanId=${this.state.subjectPlanId}&classroomId=${this.props.classroom.id}`
		);
	};

	deleteLesson = async (item: any) => {
		return new LessonService().deleteLesson(item.lessonId).then(() => {
			return this.loadSubjectPlanTree();
		});
	};

	onUngroupLesson = async (item: any) => {
		return new TagService().deleteLessonClassroomTagId(item.lessonClassroomTagId, item.lessonId).finally(() => {
			this.fetchAllSubGroupsLesson(this.state.tabActiceKey);
		});
	};

	onGroupReorder = async (items: any[]) => {
		const orderdTags = items.map((item, index) => {
			return {
				id: item.ClassroomTags[0].id,
				priority: index + 1
			};
		});
		items = items.map((item, index) => {
			const classroomTags = [...item.ClassroomTags];
			classroomTags[0].priority = index + 1;
			return {
				...item,
				ClassroomTags: [...classroomTags]
			};
		});
		this.setState({
			lessonTagGroup: items
		});
		return new TagService().reorderTags(orderdTags);
	};

	onGroupLessonsReorder = async (groupTagId: string, items: any[]) => {
		const old = [...this.state.lessonTagGroup];
		const oldGroupIndex = old.findIndex((item: any) => item.id === groupTagId);
		const orderedClassroomLessonsTags = items.map((item, index) => {
			return {
				...item,
				priority: index + 1
			};
		});
		old[oldGroupIndex].ClassroomTags[0].LessonClassroomTag = orderedClassroomLessonsTags;
		this.setState({
			lessonTagGroup: old
		});

		const orderedClassroomLessonsTagsId = items.map((item, index) => {
			return {
				id: item.id,
				priority: index + 1
			};
		});
		return new TagService().reorderClassroomLessonsTags(orderedClassroomLessonsTagsId);
	};

	addAsynchCourseLessonContent = () => {
		const { t: translate } = this.props;
		return (
			<Menu>
				<Menu.Item key="0" onClick={() => this.onClickLesson({ lessonType: "VIDEO" })}>
					<Space direction="horizontal">
						<Icon type="ri-play-line" />
						{translate(`${TRANSLATION_BASE_PATH}._VIDEO_LESSON`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.onClickLesson({ lessonType: "QUIZ" })}>
					<Space direction="horizontal">
						<Icon type="ri-question-line" />
						{translate(`${TRANSLATION_BASE_PATH}._QUIZ`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="2" onClick={() => this.onClickLesson({ lessonType: "PDF" })}>
					<Space direction="horizontal">
						<Icon type="ri-slideshow-2-line" />
						{translate(`${TRANSLATION_BASE_PATH}._PDF`)}
					</Space>
				</Menu.Item>
			</Menu>
		);
	};

	addVirtualCourseLessonContent = () => {
		const { t: translate } = this.props;

		return (
			<Menu>
				<Menu.Item key="0" onClick={() => this.onClickLesson({ lessonType: "VIDEO" })}>
					<Space direction="horizontal">
						<Icon type="ri-play-line" />
						{translate(`${TRANSLATION_BASE_PATH}._VIDEO_LESSON`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="1" onClick={() => this.onClickLesson({ lessonType: "MEETING" })}>
					<Space direction="horizontal">
						<Icon type="ri-live-line" />
						{translate(`${TRANSLATION_BASE_PATH}._LIVE_CLASS`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="2" onClick={() => this.onClickLesson({ lessonType: "QUIZ" })}>
					<Space direction="horizontal">
						<Icon type="ri-todo-line" />
						{translate(`${TRANSLATION_BASE_PATH}._QUIZ`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="3" onClick={() => this.onClickLesson({ lessonType: "ASSIGNMENT" })}>
					<Space direction="horizontal">
						<Icon type="ri-draft-line" />
						{translate(`${TRANSLATION_BASE_PATH}._ASIGNMENT`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="4" onClick={() => this.onClickLesson({ lessonType: "PDF" })}>
					<Space direction="horizontal">
						<Icon type="ri-slideshow-2-line" />
						{translate(`${TRANSLATION_BASE_PATH}._PDF`)}
					</Space>
				</Menu.Item>
			</Menu>
		);
	};

	getDropDownButton = (): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Button type="primary" htmlType="button" icon="ri-add-line" size="middle" iconPosition="left">
				{translate(`${TRANSLATION_BASE_PATH}._ADD_LESSON`)}
			</Button>
		);
	};

	openDeleteGroupConfirmationModal = () => {
		const { t: translate } = this.props;

		return Modal.confirm({
			title: translate(`${TRANSLATION_BASE_PATH}._DELETE_GROUP_TITLE`),
			content: translate(`${TRANSLATION_BASE_PATH}._DELETE_GROUP_MESSAGE`),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () =>
				this.deleteTag(this.state.rootLessonTags[this.state.tabActiceKey]).then(() =>
					this.loadSubjectPlanTree()
				)
		});
	};

	getAddGroupingButton = (): React.ReactNode => {
		return (
			<Space size="middle">
				<a onClick={() => this.loadItemAndShowDrawer(null)}>
					<Icon type="ri-add-line"></Icon>
				</a>
				<a onClick={() => this.loadItemAndShowDrawer(this.state.rootLessonTags[this.state.tabActiceKey])}>
					<Icon type="ri-edit-line"></Icon>
				</a>
				<a onClick={() => this.openDeleteGroupConfirmationModal()}>
					<Icon type="ri-delete-bin-line"></Icon>
				</a>
			</Space>
		);
	};

	deleteTag = async (tag: any) => {
		const { t: translate } = this.props;
		const deletedTag = await new TagService().removeTag(tag.id);
		if (deletedTag.status === 409) return showError(translate(`${TRANSLATION_BASE_PATH}._HAS_USED_TAG`));
		return showMessage(translate(`${TRANSLATION_BASE_PATH}._DELETE_TAG_SUCCESS`));
	};

	loadItemAndShowDrawer = async (tag: any) => {
		const { t: translate } = this.props;
		let tagToBeModified = tag;
		let titleGroupDrawer = translate(`${TRANSLATION_BASE_PATH}._ADD_GROUP`);
		if (tag) {
			let childTags: any[] = [];
			titleGroupDrawer = translate(`${TRANSLATION_BASE_PATH}._MODIFY`) + " " + tag.name;
			if (tag.isGlobal) childTags = await new TagService().getAllChildTags(tag.id);
			tagToBeModified = {
				...tag,
				Tags: _(childTags)
					.orderBy(x => Number(x.priority), "asc")
					.value()
			};
		}

		this.setState({
			currentTag: tagToBeModified,
			drawerGroupVisible: true,
			titleGroupDrawer: titleGroupDrawer,
			isEditModeGroupDrawer: !!tag
		});
	};

	openDeleteItemGroupConfirmationModal = (itemToDelete: string) => {
		const { t: translate } = this.props;
		return Modal.confirm({
			title: translate(`${TRANSLATION_BASE_PATH}._DELETE_GROUP_TITLE`),
			content: translate(`${TRANSLATION_BASE_PATH}._DELETE_GROUP_MESSAGE`),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.deleteItemTag(itemToDelete)
		});
	};

	deleteItemTag = async (classroomTagId: string) => {
		const { t: translate } = this.props;

		return new TagService()
			.deleteClassroomTagId(classroomTagId, this.props.classroom.id)
			.then(() => {
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._DELETE_TAG_SUCCESS`));
			})
			.finally(() => {
				this.fetchAllSubGroupsLesson(this.state.tabActiceKey);
			});
	};

	getContentMenu = (groupChildrenTag: any[]) => {
		const { t: translate } = this.props;
		return (
			<Space direction="vertical" className="full__width" size={6}>
				<Row className="pr-40">
					<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._CONTENT`)}
					</Text>
				</Row>
				<Row className="pr-40">
					{groupChildrenTag && groupChildrenTag.length > 0 && (
						<Menu
							mode="inline"
							inlineIndent={0}
							inlineCollapsed={false}
							defaultSelectedKeys={["0"]}
							className="background-color-gray-2 courseBuilder__subjectPlanCreator-menuContent"
						>
							{groupChildrenTag.map((childrenTag: any, indexChild: any) => {
								return (
									<Menu.Item key={indexChild} className="background-color-gray-2">
										<Row>
											<Col span={20}>
												<Link
													to={childrenTag.name}
													spy={true}
													smooth={true}
													className="background-color-gray-2 ellipsis-text"
												>
													{childrenTag.name}
												</Link>
											</Col>
											{!childrenTag.isGlobal && (
												<Col span={4}>
													<Row justify="end">
														<a
															onClick={() =>
																this.openDeleteItemGroupConfirmationModal(
																	childrenTag.ClassroomTags[0].id
																)
															}
														>
															<Icon type="ri-delete-bin-line"></Icon>
														</a>
													</Row>
												</Col>
											)}
										</Row>
									</Menu.Item>
								);
							})}
						</Menu>
					)}
				</Row>
			</Space>
		);
	};

	emptyStateComponent = (): React.ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col span={24}>
					<Row justify="center">
						<Card bordered={true} className="full__width pb-0">
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false}>
								<Row justify="center">
									<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._EMPTY_DESCRIPTION`)}
									</Text>
								</Row>
								<Row justify="center" className="mt-8">
									<Text fontSize={"16"} lineHeight={"24"} className="color-gray-8">
										{!this.props.classroom.isAsync
											? translate(`${TRANSLATION_BASE_PATH}._ADD_TEACHER_LESSON_TYPE`)
											: translate(`${TRANSLATION_BASE_PATH}._ADD_LESSON_TYPE`)}
									</Text>
								</Row>
								<Row justify="center" className="pt-24">
									<Col span={24}>
										<Dropdown.Button
											buttonsRender={() => [<></>, this.getDropDownButton()]}
											trigger={["click"]}
											placement="bottomLeft"
											overlay={
												this.props.classroom.isAsync
													? this.addAsynchCourseLessonContent
													: this.addVirtualCourseLessonContent
											}
											icon={<Icon type="ri-more-2-line" />}
										/>
									</Col>
								</Row>
								{!OAuth2Service.isOwnerOrAdminAtDefaultOrganization &&
									OAuth2Service.isTeacher &&
									this.state.respectiveAsyncClassroom &&
									this.props.classroom?.GradeSubject?.Grade?.level !== "OTHER" && (
									<>
										<Divider plain>OSE</Divider>
										<Row justify="center">
											<Text
												fontSize="20"
												lineHeight="28"
												wheight={"semibold"}
												className="color-gray-9 pb-8"
											>
												{translate(`${TRANSLATION_BASE_PATH}._CLONE_CLASS_TITLE`, {
													className: this.state.respectiveAsyncClassroom.name
												})}
											</Text>
										</Row>
										<Row justify="center">
											<Text fontSize="16" lineHeight="24" className="color-gray-8 mb-24">
												{translate(`${TRANSLATION_BASE_PATH}._CLONE_CLASS_SUBTITLE`, {
													className: this.state.respectiveAsyncClassroom.name
												})}
											</Text>
										</Row>
										<Row justify="center">
											<Button
												type="default"
												htmlType="button"
												icon="ri-file-copy-line"
												size="middle"
												iconPosition="left"
												onClick={() => this.toggleDrawer()}
											>
												{translate(`${TRANSLATION_BASE_PATH}._CLONE_CLASS_BTN`)}
											</Button>
										</Row>
									</>
								)}
							</Empty>
						</Card>
					</Row>
				</Col>
			</Row>
		);
	};

	adminGroupTagTabs = (): React.ReactNode => {
		return (
			<Tabs
				defaultActiveKey={this.state.tabActiceKey}
				tabPosition={"top"}
				onChange={this.fetchAllSubGroupsLesson}
				tabBarExtraContent={{
					right: this.getAddGroupingButton()
				}}
			>
				{this.state.rootLessonTags.map((lessonTag: any, index: number) => (
					<TabPane tab={lessonTag.name} key={index}>
						<Space direction="vertical" className="full__width" size={6}>
							<Row>
								<Col span={24}>
									<Dropdown.Button
										buttonsRender={() => [<></>, this.getDropDownButton()]}
										trigger={["click"]}
										placement="bottomLeft"
										overlay={
											this.props.classroom.isAsync
												? this.addAsynchCourseLessonContent
												: this.addVirtualCourseLessonContent
										}
										icon={<Icon type="ri-more-2-line" />}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<AdminLessonList
										lessonGroups={this.state.lessonTagGroup}
										lessons={this.state.lessons}
										onDeleteGroup={this.onDeleteGroup}
										onClickItem={this.onClickLesson}
										onDeleteItem={this.deleteLesson}
										onUngroupItem={this.onUngroupLesson}
										onGroupReorder={this.onGroupReorder}
										onGroupLessonsReorder={this.onGroupLessonsReorder}
									/>
								</Col>
							</Row>
						</Space>
					</TabPane>
				))}
			</Tabs>
		);
	};

	teacherGroupTagTabs = (): React.ReactNode => {
		return (
			<Space direction="vertical" className="full__width" size={6}>
				<Row>
					<Col span={24}>
						<Dropdown.Button
							buttonsRender={() => [<></>, this.getDropDownButton()]}
							trigger={["click"]}
							placement="bottomLeft"
							overlay={
								this.props.classroom.isAsync
									? this.addAsynchCourseLessonContent
									: this.addVirtualCourseLessonContent
							}
							icon={<Icon type="ri-more-2-line" />}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<TeacherLessonList
							lessonGroups={this.state.lessonTagGroup}
							lessons={this.state.lessons}
							classroomId={this.props.classroom.id}
							enrolledStudents={this.props.enrolledStudents?.length}
							onDeleteGroup={this.onDeleteGroup}
							onClickItem={this.onClickLesson}
							onDeleteItem={this.deleteLesson}
							onUngroupItem={this.onUngroupLesson}
							onGroupReorder={this.onGroupReorder}
							onGroupLessonsReorder={this.onGroupLessonsReorder}
						/>
					</Col>
				</Row>
			</Space>
		);
	};

	groupingOptionsToSelect = (): React.ReactNode => {
		return (
			<Radio.Group defaultValue={Number(this.state.tabActiceKey)} onChange={this.fetchAllSubGroupsLessonTeacher}>
				{this.state.rootLessonTags.map((lessonTag: any, index: number) => (
					<Radio.Button key={index} value={index}>
						{lessonTag.name}
					</Radio.Button>
				))}
			</Radio.Group>
		);
	};

	onSubmit = (newTag: any) => {
		const { t: translate } = this.props;

		this.setState({ loading: true, submitting: true });

		if (this.state.isEditModeGroupDrawer) {
			delete newTag.OrganizationId;
			delete newTag.createdBy;
			delete newTag.updatedBy;
			delete newTag.createdAt;
			delete newTag.updatedAt;
			delete newTag.deletedAt;
		}

		return (this.state.isEditModeGroupDrawer
			? new TagService().updateTag(newTag.id, newTag)
			: new TagService().addTag(newTag)
		)
			.then(() => {
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._SUBMIT_TAG_SUCCESS`));
			})
			.finally(() => {
				this.setState(
					{
						submitting: false,
						loading: false,
						drawerGroupVisible: false,
						currentTag: undefined,
						isEditModeGroupDrawer: false
					},
					() => this.loadSubjectPlanTree()
				);
			});
	};

	toggleGroupDrawer = () => {
		this.setState({
			drawerGroupVisible: !this.state.drawerGroupVisible,
			currentTag: undefined,
			isEditModeGroupDrawer: false
		});
	};

	getAuthor = () => {
		const { t: translate } = this.props;
		return (
			<Col offset="6">
				<Space>
					<Text fontSize="14" lineHeight="24" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._AUTHOR`)}:
					</Text>
					<UserAvatar
						userId={this.props.classroom.createdBy}
						firstName={this.props.classroom.UserCreatedBy.firstName}
						lastName={this.props.classroom.UserCreatedBy.lastName}
					></UserAvatar>
					<Text fontSize="14" lineHeight="24" className="color-gray-8">
						<Space>
							{this.props.classroom.UserCreatedBy.firstName}
							{this.props.classroom.UserCreatedBy.lastName}
						</Space>
					</Text>
				</Space>
			</Col>
		);
	};

	render() {
		return (
			<>
				{this.state.drawerShown && (
					<ClassCloneDrawer
						toClassroom={this.props.classroom}
						fromClassroom={this.state.respectiveAsyncClassroom}
						hideDrawer={this.toggleDrawer}
						reload={this.loadSubjectPlanTree}
					/>
				)}
				{!OAuth2Service.isOwnerOrAdminAtDefaultOrganization && OAuth2Service.isTeacher && !this.state.isEmptyTree && (
					<Row justify="center" className="pl-48 pt-16 pb-24">
						<Col span={22}>{this.groupingOptionsToSelect()}</Col>
					</Row>
				)}
				<Row className="pl-16 pb-16">{this.getAuthor()}</Row>
				<Row justify="center" className="mb-24">
					<Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4} className="mr-16 courseBuilder__overflow-menu">
						{!this.state.isEmptyTree && this.getContentMenu(this.state.lessonTagGroup)}
					</Col>
					<Col xs={23} sm={22} md={20} lg={16} xl={16} xxl={16}>
						<Skeleton active loading={this.state.loading}>
							{this.state.isEmptyTree
								? this.emptyStateComponent()
								: OAuth2Service.isOwnerOrAdminAtDefaultOrganization
									? this.adminGroupTagTabs()
									: this.teacherGroupTagTabs()}
						</Skeleton>
					</Col>
					<AddLessonModal
						modalVisibility={this.state.lessonModalVisibility}
						addLesson={this.addLesson}
						closeModal={() => {
							this.setState({
								lessonModalVisibility: false
							});
						}}
					/>
				</Row>
				<CreateEditTagsForm
					Title={this.state.titleGroupDrawer}
					Tag={this.state.currentTag}
					drawerVisible={this.state.drawerGroupVisible}
					toggleDrawer={this.toggleGroupDrawer}
					onSubmit={this.onSubmit}
					submitting={this.state.submitting}
					defaultTarget={"LESSON"}
					defaultAsyncOnly={this.state.currentTag ? this.state.currentTag.asyncOnly : true}
					defaultIsGlobal={this.state.currentTag ? this.state.currentTag.isGlobal : false}
					defaultPriority={this.state.currentTag ? this.state.currentTag.priority : 0}
				/>
			</>
		);
	}
}

const SubjectPlanCreator = withTranslation()(SubjectPlanCreatorScene);
export default SubjectPlanCreator;
