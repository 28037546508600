import * as Yup from "yup";

import { generalPasswordRegx, usernameRegx } from "common/components/utils/Regx";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

export const formValidatorAddChild = (translate: (key: string) => string) =>
	Yup.object({
		firstName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		lastName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		username: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.matches(usernameRegx, translate(`${TRANSLATION_BASE_PATH}._USERNAME_CHARACTERS_ALLOWED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		password: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.min(8, translate(`${TRANSLATION_BASE_PATH}._PASSWORD_REQUIREMENTS`))
			.matches(generalPasswordRegx, translate(`${TRANSLATION_BASE_PATH}._PASSWORD_REQUIREMENTS`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`))
	});
