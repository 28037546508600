import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Divider } from "antd";

import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Skeleton from "common/components/feedback/Skeleton";
import { REGISTER_PATH } from "../register";
import { RegisterEmailVerification } from "common/components/login/registerEmailVerification";
import OAuth2Service from "services/authentication/oauth2.service";
import { SIGN_IN_PATH } from "../signIn";
import AccountEmailService from "services/email/account.email.service";
import UserService from "services/authentication/user.service";
import { REDIRECT_URI } from "common/utils/Constants";
import { Layout } from "../../components/layout";

export const CONFIRM_EMAIL_REGISTRATION_PATH = "confirm-account/:code";
const TRANSLATION_BASE_PATH = "_LOGIN._EMAIL_VERIFICATION";

const cleanOldUserTokensInStorage = () => {
	localStorage.removeItem("user");
	localStorage.removeItem("jwt-token");
	localStorage.removeItem("jwrt-token");
};

class ConfirmRegistrationEmailComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.resendVerificationEmail = this.resendVerificationEmail.bind(this);
		this.state = {
			submitting: false,
			hasError: false,
			errorMessage: "",
			loading: true,
			status: "Sent",
			user: undefined
		};

		this.Component = this.Component.bind(this);
	}

	recaptchaRef: any = React.createRef();

	componentDidMount() {
		const {
			history,
			match: { params }
		} = this.props;
		if (params.code === "" || params.code === null) return history.push(`/${SIGN_IN_PATH}/${REGISTER_PATH}`);

		const decoded = new Buffer(params.code ?? "", "base64").toString("ascii");
		const fields = decoded.split("&");
		if (fields === null || fields.length < 4) return history.push(`/${SIGN_IN_PATH}/${REGISTER_PATH}`);

		const authorizationCode = fields[0].split("=")[1];
		const userId = fields[1].split("=")[1];
		const email = fields[2].split("=")[1];
		const name = fields[3].split("=")[1];
		this.setState({
			submitting: true,
			user: {
				code: authorizationCode,
				email: email,
				name: name,
				userId: userId
			}
		});

		return OAuth2Service.loginAuthorizationCode(authorizationCode, REDIRECT_URI)
			.then((signedToken: string) => {
				return new UserService().verifyAccount(userId ?? "", signedToken);
			})
			.then(() => {
				cleanOldUserTokensInStorage();
				return this.setState({status: "Verified"});
			})
			.catch(() => {
				return this.setState({status: "Expired"});
			}).finally(() => {
				this.setState({submitting: false, loading: false});
			});
	}

	resendVerificationEmail = async () => {
		this.setState({
			submitting: true
		});

		await OAuth2Service.loginClient().catch(() => {
			return this.setState({
				submitting: false,
				status: "Resent"
			});
		});

		const recaptchaValue = await this.recaptchaRef.current.executeAsync();

		return new AccountEmailService()
			.resentVerificationEmail(this.state.user?.userId ?? "", recaptchaValue)
			.then(() => {
				return this.setState({status: "Resent"});
			})
			.catch(() => {
				return this.setState({status: "Expired"});
			}).finally(() => {
				this.recaptchaRef.current.reset();
				this.setState({submitting: false});
			});
	};

	onContinueHandler = () => {
		window.location.href = `/${SIGN_IN_PATH}`;
	};


	Component = () => {
		const { t: translate } = this.props;
		return (
			<Row>
				<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
					<Skeleton active loading={this.state.loading}>
						<RegisterEmailVerification
							email={this.state.user?.email ?? ""}
							name={this.state.user?.name ?? ""}
							submitting={this.state.submitting}
							onResendEmail={this.resendVerificationEmail}
							status={this.state.status}
							onContinue={this.onContinueHandler}
							recaptchaRef={this.recaptchaRef}
						/>
						{this.state.status !== "Verified" ? (
							<Row justify="center" className="pt-40">
								<Col>
									<Text fontSize="14" lineHeight="22">
										<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}>
											{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_REGISTER`)}
										</Link>
										<Divider orientation="center" type="vertical" />
										<Link to={`/${SIGN_IN_PATH}`}>
											{translate(`${TRANSLATION_BASE_PATH}._BACK_TO_SIGNIN`)}
										</Link>
									</Text>
								</Col>
							</Row>
						) : null}
					</Skeleton>
				</Col>
			</Row>
		);
	}

	render() {
		const hideCover = this.props.pageComponents?.loginCover?.status === 0;

		return (
			<>
				{hideCover ? this.Component() : <Layout {...this.props.pageComponents.loginCover?.specs}>{this.Component()}</Layout>}
			</>
		);
	}
}

const ConfirmRegistrationEmail = withTranslation()(ConfirmRegistrationEmailComponent);
export default ConfirmRegistrationEmail;
