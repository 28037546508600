import React, { ReactNode } from "react";
import classnames from "classnames";

interface HeaderNavigationStatusProps {
	numberOfItems: number;
	currentIndex: number;
}

enum DotStatus {
	Active = 0,
	Complete = 1,
	Disable = 2
}

const getDotStatusContent = (key: number, status: DotStatus): ReactNode => {
	return (
		<div
			className={classnames(
				"navigation__dot_status",
				"mr-16",
				{ navigation__dot_active: status === DotStatus.Active },
				{ navigation__dot_complete: status === DotStatus.Complete }
			)}
			key={key}
		></div>
	);
};

const getDotStatus = (index: number, currentIndex: number): DotStatus => {
	if (index === currentIndex) {
		return DotStatus.Active;
	} else if (index < currentIndex) {
		return DotStatus.Complete;
	}
	return DotStatus.Disable;
};

export const HeaderNavigationStatus: React.FC<HeaderNavigationStatusProps> = (props: HeaderNavigationStatusProps) => {
	return (
		<div className="flex__center">
			{Array.from({ length: props.numberOfItems }, (value, key) => key).map(item => {
				return getDotStatusContent(item, getDotStatus(item, props.currentIndex));
			})}
		</div>
	);
};
