import React, { ReactNode } from "react";
import { Row, Col, Card, Space, Tooltip } from "antd";
import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import { Link } from "react-router-dom";
import { getImageUrl } from "../_utils/getImageUrl";
import { PriceTag } from "./PriceTag";
import { LiveClassIcon } from "./LiveClassIcon";

const NAVIGATE_URL = "/subjects/course/";

class ClassSlider extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			index: 0,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.noOfItemsToShowFromScreenSize,
			currentItems: this.props.expandAll
				? this.props.items
				: this.props.items.slice(0, this.noOfItemsToShowFromScreenSize)
		};
	}

	setInitialState() {
		this.setState({
			index: 0,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.noOfItemsToShowFromScreenSize,
			currentItems: this.props.expandAll
				? this.props.items
				: this.props.items.slice(0, this.noOfItemsToShowFromScreenSize)
		});
	}

	componentDidMount() {
		window.addEventListener("resize", this.setInitialState.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setInitialState.bind(this));
	}

	scroll = (step: number) => {
		if (this.state.index + step >= 0) {
			this.setState({
				index: this.state.index + step,
				sliderLeftVisible: this.state.index + step > 0,
				sliderRightVisible:
					this.state.index + step < this.props.items.length - this.noOfItemsToShowFromScreenSize,
				currentItems: this.props.expandAll
					? this.props.items
					: this.props.items.slice(this.state.index + step).slice(0, this.noOfItemsToShowFromScreenSize)
			});
		}
	};

	get noOfItemsToShowFromScreenSize(): number {
		let decrement = 1;
		if (window.innerWidth > 992) {
			decrement = 3;
		} else if (window.innerWidth >= 576) {
			decrement = 2;
		}
		return decrement;
	}

	get LeftScrollButton() {
		const iconClass = "marketplace__classSlider__nav__icon marketplace__classSlider__nav__icon__left";

		return (
			this.state.sliderLeftVisible &&
			!this.props.expandAll && (
				<a className={iconClass} onClick={() => this.scroll(-1)}>
					<Icon type="ri-arrow-left-s-line" />
				</a>
			)
		);
	}

	get RightScrollButton() {
		const iconClass = "marketplace__classSlider__nav__icon marketplace__classSlider__nav__icon__right";

		return (
			this.state.sliderRightVisible &&
			!this.props.expandAll && (
				<a className={iconClass} onClick={() => this.scroll(+1)}>
					<Icon type="ri-arrow-right-s-line" />
				</a>
			)
		);
	}

	getPhoto(item: any) {
		return (
			<Image
				className="marketplace__classSlider__item__card__image"
				src={getImageUrl(this.props.imagePrefix, item.File)}
			/>
		);
	}

	getItem(item: any): ReactNode {
		return (
			<Card hoverable={true} className="box_shadow pb-8" bordered={false} bodyStyle={{ padding: "0" }}>
				<Link to={NAVIGATE_URL + item.id}>
					<Row className="mb-8">
						{this.getPhoto(item)}
						{!item.isAsync && <LiveClassIcon />}
					</Row>

					<Row className="ml-16">
						<Text
							tooltipOnEllipsis={true}
							tooltip={item.name}
							fontSize="16"
							lineHeight="24"
							className="color-gray-9 marketplace__full-width-ellipsis"
						>
							{item.name}
						</Text>
					</Row>

					<Row className="pt-8 ml-16">
						<Col span={24}>
							<Text fontSize="12" lineHeight="20" className="marketplace__organization__name">
								{item.Organization?.name}
							</Text>
						</Col>
					</Row>
					<Row className="mt-8 ml-16">
						<Col span={24}>
							<Space direction="horizontal" size={4} align="center">
								{this.props.showEnrolledUsers && item.TotalEnrollments >= 10 ? (
									<>
										<Icon type="ri-user-line" className="color-gray-8" fontSize="14" />
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{item.TotalEnrollments}
										</Text>
									</>
								) : (
									""
								)}
							</Space>
						</Col>
					</Row>
					<PriceTag price={item.price} />
				</Link>
			</Card>
		);
	}

	get EmptyState(): ReactNode {
		return null;
	}

	get Items(): ReactNode {
		if (!(this.state.currentItems && this.state.currentItems.length > 0)) return this.EmptyState;
		return this.state.currentItems.map((item: any) => {
			return (
				<Col key={item.id} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
					{this.getItem(item)}
				</Col>
			);
		});
	}

	render() {
		return (
			<Row gutter={[24, 24]} align="middle" className={"marketplace__classSlider"}>
				{this.LeftScrollButton}
				{this.Items}
				{this.RightScrollButton}
			</Row>
		);
	}
}

export default ClassSlider;
