import { Col, Row, Space, Skeleton, Collapse, Empty, Card, Dropdown, Menu, Popconfirm, Tooltip } from "antd";
import React, { Component } from "react";
import Button from "common/components/general/Button";
import { withTranslation } from "react-i18next";
import { ADMIN_PATH } from "../..";
import { SectionContent } from "../../components/sectionContent/SectionContent";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { showMessage } from "../../../../common/utils/Notification";
import TagService, { TAG_TYPE } from "../../../../services/administration/tag.services";
import { CreateEditTagsForm } from "./CreateEditForm";
import { Formik, Form } from "formik";
import { formValidator } from "./ValidationSchema";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { v4 as uuidv4 } from "uuid";
import TagDto from "../../../../dtos/administration/tag.dto";

export const TAG_MANAGER_PATH = "tag-manager";

const TRANSLATION_BASE_PATH = "_ADMIN._TAG_MANAGER";
const { Panel } = Collapse;

class TagManagerComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			data: [],
			submitting: false,
			loading: false,
			drawerShown: false,
			CurrentTag: null,
			EditMode: false,
			defaultTarget: "CLASSROOM",
			defaultAsyncOnly: true,
			childAddFormVisible: false,
			defaultIsGlobal: true,
			defaultPriority: "1"
		};
		this.loadItemAndShowDrawer = this.loadItemAndShowDrawer.bind(this);
		this.loadTags = this.loadTags.bind(this);
	}

	async componentDidMount() {
		this.loadTags();
	}

	transformToParentChild = (data: any[]) => {
		const result: any[] = [];
		const parents = data.filter((x: any) => !x.ParentId);
		parents.map((item: any) => {
			const children = data.filter((x: any) => x.ParentId && x.ParentId === item.id);
			result.push({
				...item,
				Tags: children
			});
		});
		return result;
	};

	loadTags = () => {
		this.setState({
			loading: true
		});
		return new TagService()
			.getAllFor()
			.then((result: any) => {
				console.log({ result });
				return this.setState({
					data: this.transformToParentChild(result)
				});
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	loadItemAndShowDrawer = (tag: any) => {
		this.setState({
			CurrentTag: tag,
			drawerVisible: true,
			EditMode: tag
		});
	};

	onSubmit = (tag: any, type?: TAG_TYPE) => {
		const { t: translate } = this.props;

		this.setState({
			loading: true,
			submitting: true
		});

		// Synchronize main type with the children tag type
		for (const i in tag.Tags) {
			tag.Tags[i].type = tag.type;
		}
		if (type) {
			tag.type = type;
		}

		delete tag.ClassroomTags;
		if (this.state.EditMode) {
			delete tag.OrganizationId;
			delete tag.createdBy;
			delete tag.updatedBy;
			delete tag.createdAt;
			delete tag.updatedAt;
			delete tag.deletedAt;
			return new TagService()
				.updateTag(tag.id, tag)
				.then(() => {
					this.loadTags();
					return showMessage(translate(`${TRANSLATION_BASE_PATH}._FORM._EDIT_SUCCESS`));
				})
				.finally(() => {
					this.setState({
						submitting: false,
						loading: false,
						drawerVisible: false,
						addingNewTagInParent: null,
						EditMode: false
					});
				});
		}

		return new TagService()
			.addTag(tag)
			.then(() => {
				this.loadTags();
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._FORM._ADD_SUCCESS`));
			})
			.finally(() => {
				this.setState({
					submitting: false,
					loading: false,
					drawerVisible: false,
					addingNewTagInParent: null,
					EditMode: false
				});
			});
	};

	onDelete = (tagId: string) => {
		const { t: translate } = this.props;

		this.setState({
			loading: true
		});

		return new TagService()
			.removeTag(tagId)
			.then((result: any) => {
				this.loadTags();
				if (result && result.status === 409) return;
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_SUCCESS`));
			})
			.finally(() => {
				this.setState({
					loading: false
				});
			});
	};

	toggleDrawer = () => {
		this.setState({
			drawerVisible: !this.state.drawerVisible
		});
	};

	AddNewTagButton = () => {
		const { t: translate } = this.props;
		return (
			<Button onClick={() => this.loadItemAndShowDrawer(null)} type={"primary"} size={"middle"}>
				<Space>
					<Text fontSize="14" lineHeight="22">
						<Icon type="ri-add-line"></Icon>
					</Text>
					{translate(`${TRANSLATION_BASE_PATH}._BTN_ADD_PARENT_TAG`)}
				</Space>
			</Button>
		);
	};

	RenderTags = () => {
		const { t: translate } = this.props;

		const RenderHeader = (item: any) => {
			const tagIsInUse = item.Tags && item.Tags.length > 0;
			const menu = (
				<Menu>
					<Menu.Item key="0" onClick={() => this.loadItemAndShowDrawer(item)}>
						<Space direction="horizontal">
							<Icon type="ri-edit-line" />
							{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
						</Space>
					</Menu.Item>
					<Menu.Item key="1">
						<Popconfirm
							title={translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_CONFIRM_MESSAGE`, {
								tagName: item.name
							})}
							onConfirm={() => this.onDelete(item.id)}
							okText={translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_CONFIRM_YES`)}
							cancelText={translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_CONFRIM_CANCEL`)}
							disabled={tagIsInUse}
							placement="left"
						>
							<Tooltip
								trigger={["click"]}
								placement="left"
								title={
									tagIsInUse
										? translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_DISABLED_MESSAGE_PARENT`, {
											tagName: item.name
										  })
										: ""
								}
							>
								<Space direction="horizontal">
									<Icon type="ri-delete-bin-line" />
									{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
								</Space>
							</Tooltip>
						</Popconfirm>
					</Menu.Item>
				</Menu>
			);

			return (
				<Row align="middle">
					<Col span="20">
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{item.name}
						</Text>
					</Col>
					<Col span="4">
						<Row justify="end">
							<Dropdown.Button
								buttonsRender={() => [
									<></>,
									<Button key="1" type="link" className="color-gray-8">
										<Icon type="ri-more-2-line" />
									</Button>
								]}
								trigger={["click"]}
								overlay={menu}
								icon={<Icon type="ri-more-2-line" />}
							/>
						</Row>
					</Col>
				</Row>
			);
		};

		const RenderChildTags = (tags: TagDto[]) => {
			return tags.map((tag: any, index: number) => {
				return (
					<Col key={index} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
						{tag.name}
					</Col>
				);
			});
		};

		return this.state.data.map((item: TagDto, index: number) => {
			return (
				<Collapse key={index} defaultActiveKey={"0"} className="mb-24">
					<Panel header={RenderHeader(item)} key={item.id}>
						<Row className="mb-16">
							{this.state.addingNewTagInParent === item.id
								? this.RenderChildTagAddForm(item.type)
								: this.RenderChildTagAddButton(item.id)}
						</Row>
						<Row>{RenderChildTags(item.Tags ?? [])}</Row>
					</Panel>
				</Collapse>
			);
		});
	};

	RenderEmptyState = () => {
		const { t: translate } = this.props;
		return (
			<Card>
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}
				/>
				<Row justify="center">{this.AddNewTagButton()}</Row>
			</Card>
		);
	};

	toggleChildAddForm = (parentId?: string) => {
		this.setState({
			addingNewTagInParent: parentId
		});
	};

	RenderChildTagAddButton = (parentId: string) => {
		const { t: translate } = this.props;
		return (
			<Button onClick={() => this.toggleChildAddForm(parentId)} type={"ghost"} size={"middle"}>
				<Space>
					<Text fontSize="14" lineHeight="22">
						<Icon type="ri-add-line"></Icon>
					</Text>
					{translate(`${TRANSLATION_BASE_PATH}._BTN_ADD_PARENT_TAG`)}
				</Space>
			</Button>
		);
	};

	RenderChildTagAddForm = (type?: TAG_TYPE) => {
		const { t: translate } = this.props;
		const emptyTag: any = {
			id: uuidv4(),
			name: "",
			target: this.state.defaultTarget,
			asyncOnly: this.state.defaultAsyncOnly,
			ParentId: this.state.addingNewTagInParent,
			isGlobal: this.state.defaultIsGlobal,
			priority: this.state.defaultPriority,
			Tags: []
		};

		return (
			<Formik
				initialValues={emptyTag}
				onSubmit={(values: any) => this.onSubmit(values, type)}
				enableReinitialize={true}
				isInitialValid={false}
				validationSchema={formValidator(translate)}
			>
				<Form>
					<Row className="pt-16" justify="space-between">
						<Col span="17">
							<Input
								name="name"
								placeholder={translate(`${TRANSLATION_BASE_PATH}._FORM._ADD_CHILD_BTN_LABEL`)}
							/>
						</Col>
						<Col span="7">
							<Row justify="end">
								<Space>
									<Button type="primary" htmlType="submit" shape="circle">
										<Icon type="ri-check-fill"></Icon>
									</Button>
									<Button type="ghost" shape="circle" onClick={() => this.toggleChildAddForm()}>
										<Icon type="ri-close-line"></Icon>
									</Button>
								</Space>
							</Row>
						</Col>
					</Row>
				</Form>
			</Formik>
		);
	};

	render() {
		const { t: translate } = this.props;
		const title = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_7");
		const subTitle = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");

		return (
			<>
				<SectionTitle title={title} subtitle={[subTitle, title]} subtitleUrl={[ADMIN_PATH]} />
				<SectionContent>
					<Row className="pb-24">{this.state.data.length > 0 && this.AddNewTagButton()}</Row>
					<Row>
						<Col span="24">
							<Skeleton loading={this.state.loading}>
								{this.state.data.length > 0 ? this.RenderTags() : this.RenderEmptyState()}
							</Skeleton>
						</Col>
					</Row>
				</SectionContent>
				<CreateEditTagsForm
					Title={title}
					Tag={this.state.CurrentTag}
					drawerVisible={this.state.drawerVisible}
					toggleDrawer={this.toggleDrawer}
					onSubmit={(val: any) => this.onSubmit(val)}
					submitting={this.state.submitting}
					defaultTarget={this.state.defaultTarget}
					defaultAsyncOnly={this.state.defaultAsyncOnly}
					defaultIsGlobal={this.state.defaultIsGlobal}
					defaultPriority={this.state.defaultPriority}
				/>
			</>
		);
	}
}

const TagManager = withTranslation()(TagManagerComponent);
export default TagManager;
