import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import _ from "lodash";
import { Divider, Row, Select, Checkbox } from "antd";
import Jimp from "jimp";

import { formValidator } from "./ValidationSchema";
import Button from "common/components/general/Button";
import Image from "common/components/general/Image";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import FormikTextAreaField from "common/components/dataEntry/formik/FormikTextAreaField";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import GradeService from "services/administration/grade.service";
import { getLevelIcon } from "services/domain/administration/Grade";
import Col from "common/components/layout/Col";
import ClassroomService from "services/administration/classroom.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { Uploader } from "common/components/uploader/Uploader";
import FileDto from "dtos/administration/file.dto";
import { previewFile } from "../../../../utils/FileUtils";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { showMessage } from "common/utils/Notification";
import Text from "common/components/general/Text";
import TagService from "services/administration/tag.services";
import { IntroductionInfoModal } from "./introductionInfoModal";
import { OrganizationSectorType } from "services/domain/administration/Organization";
import { HtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import { Role } from "../../../../../../services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW";
const MARKETPLACE_COURSE_CATEGORIES = "_MARKETPLACE._COURSE_CATEGORIES";

class CourseOverviewComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			classroomInfo: this.props.classroom
				? {
						id: this.props.classroom.id,
						name: this.props.classroom.name,
						description: this.props.classroom.description,
						gradeId: this.props.classroom.GradeSubject?.GradeId,
						stage: this.props.classroom.GradeSubject?.Grade?.level,
						subjectId: this.props.classroom.GradeSubject?.Subject?.id,
						File: this.props.classroom.File,
						FileId: this.props.classroom.FileId,
						price: this.props.classroom.price,
						featured: this.props.classroom.featured || false,
						popular: this.props.classroom.popular || false,
						trending: this.props.classroom.trending || false
				  }
				: {},
			gradesList: {},
			subjectsList: {},
			stagesList: [],
			classroomId: this.props.classroom ? this.props.classroom.id : "",
			loading: false,
			filesToDelete: [],
			currentUserId: "",
			removedTags: [],
			justCreated: props.justCreated
		};
		this.transformFileDownload = this.transformFileDownload.bind(this);
	}

	async componentDidMount() {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		this.setState({ currentUserId: currentUserInfo?.UserId });
		this.loadData();
		this.loadTags();
		await this.getImage();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.classroomId !== this.state.classroomId) {
			this.loadData();
		}
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if (nextProps.classroom && nextProps.classroom?.id !== prevState.classroomId) {
			return {
				classroomInfo: {
					id: nextProps.classroom.id,
					name: nextProps.classroom.name,
					description: nextProps.classroom.description,
					gradeId: nextProps.classroom.GradeSubject?.GradeId,
					stage: nextProps.classroom.GradeSubject?.Grade?.level,
					subjectId: nextProps.classroom.GradeSubject?.Subject?.id,
					File: nextProps.classroom.File,
					FileId: nextProps.classroom.FileId,
					price: nextProps.classroom.price
				},
				classroomId: nextProps.classroom.id
			};
		}
		return null;
	}

	resetGradeSelection(formik: any) {
		formik.setFieldValue("gradeId", "");
	}

	resetSubjectSelection(formik: any) {
		formik.setFieldValue("subjectId", "");
	}

	createFile = (fileUploaded: any): FileDto => {
		const file = fileUploaded;
		if (file) {
			file.updatedBy = this.state.currentUserId;
			file.createdBy = this.state.currentUserId;
		}
		return file;
	};

	saveClassroom(item: any) {
		const { t: translate } = this.props;
		const file = this.createFile(this.state.fileUploaded);

		let oldTags = this.props.classroom.ClassroomTags.filter((x: any) => {
			return x.Tag && !this.state.removedTags.includes(x.Tag?.ParentId);
		});

		const tags: any[] = [];
		this.state.tags
			.filter((x: any) => {
				return x.ParentId;
			})
			.forEach((childTag: any) => {
				if (item[childTag.ParentId]) {
					tags.push(item[childTag.ParentId]);
					oldTags = oldTags.filter((x: any) => {
						return x.Tag?.ParentId !== childTag.ParentId;
					});
				}
			});

		const newTags = _.uniq([
			...oldTags.map((x: any) => {
				return x.TagId;
			}),
			...tags
		]);

		return new ClassroomService()
			.updateClassroom(item.id, {
				id: item.id,
				name: item.name,
				description: item.description,
				startDate: new Date(),
				endDate: new Date(),
				isAsync: true,
				GradeSubject: {
					GradeId: item.gradeId,
					SubjectId: item.subjectId
				},
				updatedBy: this.state.currentUserId,
				File: file,
				FileId: file?.id,
				tags: newTags,
				price: Number(item.price),
				featured: item.featured,
				popular: item.popular,
				trending: item.trending
			})
			.then((result: any) => {
				return showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
			})
			.finally(() => {
				this.setState({ submitting: false });
				this.props.reloadClassroom();
			});
	}

	loadData = () => {
		const { t: translate } = this.props;

		return new GradeService()
			.getAllGrades()
			.then((grades: any) => {
				return _.chain(grades)
					.groupBy(grade => grade.level)
					.map((value, key) => ({ level: key, grades: value }))
					.value();
			})
			.then(allGrades => {
				const levels = allGrades.map(grade => {
					return {
						id: grade.level,
						name: translate(`_ADMIN._LEVELS._${grade.level}`),
						icon: getLevelIcon(grade.level),
						grades: grade.grades
					};
				});
				return this.setState({ stagesList: levels, subjectsList: [] });
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	onUploadingDone = async (fileUploaded: any) => {
		this.setState({ fileUploaded: fileUploaded });
		await this.getImage(fileUploaded);
	};

	getImage = async (file?: any) => {
		if (!file && !this.state.classroomInfo?.File) return;
		const response = await previewFile(file ? file : this.state.classroomInfo?.File);
		const imagePhoto = await this.transformFileDownload(response.url);
		return this.setState({ imagePhto: imagePhoto });
	};

	transformFileDownload = (imageUrl: any) => {
		return Jimp.read(imageUrl).then(image => {
			const width = image.bitmap.width;
			const height = image.bitmap.width / 4;
			return image
				.cover(width, height)
				.autocrop({ cropSymmetric: true })
				.getBase64Async(Jimp.MIME_JPEG);
		});
	};

	onUploadingStarted = (flag: boolean) => {
		this.setState({
			loading: flag
		});
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			fileUploaded: undefined
		});
	};

	getSubjectsOptions = (formik: any) => {
		const level = this.state.stagesList.find((stage: any) => stage.id === formik.values.stage);
		const grade = level?.grades.find((grade: any) => grade.id === formik.values.gradeId);
		const subjects = grade?.GradeSubjects.map((gradeSubject: any) => gradeSubject.Subject);
		return subjects?.map((subject: any) => (
			<Select.Option key={subject.id} value={subject.id}>
				{subject.name}
			</Select.Option>
		));
	};

	loadTags = () => {
		return new TagService().getAllFor().then((result: any) => {
			return this.setState({
				tags: result
			});
		});
	};

	removeTag(tag: any) {
		const newTags = this.state.removedTags;
		newTags.push(tag.id);
		this.setState({ removedTags: newTags });
		return false;
	}

	renderTags = () => {
		const tags = this.props.classroom.ClassroomTags;
		return this.state.tags
			.filter((x: any) => {
				return !x.ParentId;
			})
			.map((tag: any) => {
				const selectedValue = tags?.filter((x: any) => {
					return x.Tag && x.Tag.ParentId === tag.id;
				})[0]?.TagId;
				return (
					<Row key={tag.id}>
						<Col span={24}>
							<FormikSelectField
								name={tag.id}
								defaultValue={selectedValue}
								label={tag.name}
								showSearch
								filterOption={(inputValue: string, option?: any): boolean => {
									return option.children.toLowerCase().includes(inputValue.toLowerCase());
								}}
								allowClear={true}
								onClear={() => this.removeTag(tag)}
							>
								{this.state.tags
									.filter((item: any) => {
										return item.ParentId === tag.id;
									})
									.map((childTag: any) => (
										<Select.Option key={childTag.id} value={childTag.id}>
											{childTag.name}
										</Select.Option>
									))}
							</FormikSelectField>
						</Col>
					</Row>
				);
			});
	};

	render() {
		const { t: translate } = this.props;
		
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;

		const currentOrganization = currentUserInfo?.Organizations[0];
		const userRole =
			currentUserInfo?.Roles &&
			currentUserInfo.Roles.length > 0 &&
			currentUserInfo.Roles.sort((first: any, second: any) => Number(second?.priority) - Number(first?.priority))[0]?.code;

		const classroomOrganization = this.props?.classroom?.Organization;

		return this.state.stagesList.length > 0 ? (
			<Row justify="center">
				<Col span="22">
					{this.state.justCreated && (
						<IntroductionInfoModal
							onClose={() => {
								this.setState({
									showNoStudentsBadge: true
								});
							}}
						/>
					)}
					<Formik
						enableReinitialize={true}
						initialValues={this.state.classroomInfo}
						onSubmit={values => this.saveClassroom(values)}
						validationSchema={formValidator(translate)}
					>
						{formik => (
							<form onSubmit={formik.handleSubmit} autoComplete="off">
								<Row className="mb-24">
									<Col span={24}>
										<div className="classroom__cover_image">
											{this.state.imagePhto ? (
												<Image src={this.state.imagePhto}></Image>
											) : (
												<div className="no__cover_image"></div>
											)}
											<Uploader
												fileType="IMAGE"
												filePath={`classroom/${this.state.classroomId}`}
												fileWithUniqueId={false}
												onUploadingDone={this.onUploadingDone}
												showUploadList={false}
												onUploadingStarted={this.onUploadingStarted}
												onSoftDeleteFile={this.onSoftDeleteFile}
											/>
										</div>
									</Col>
								</Row>
								<Row justify="center">
									<Col span={18}>
										<FormikDefaultInputField
											name="name"
											label={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
											placeholder={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
										/>
									</Col>
								</Row>
								<Row justify="center">
									<Col span={18}>
										{this.props.classroom?.Organization?.superOrganization ? (
											<FormikTextAreaField
												name="description"
												label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
												placeholder={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
											/>
										) : (
											<HtmlInput
												name="description"
												label={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
												placeholder={translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
												basicToolbar={true}
												className="background-color-gray-1"
											/>
										)}
									</Col>
								</Row>
								<Row justify="center">
									<Col span={18}>
										<FormikSelectField
											name="stage"
											onChange={e => this.resetGradeSelection(formik)}
											defaultValue={formik.initialValues.stage}
											label={translate(`${TRANSLATION_BASE_PATH}._STAGE`)}
											placeholder={translate(`${TRANSLATION_BASE_PATH}._STAGE`)}
										>
											{this.state.stagesList.map((chapter: any) => (
												<Select.Option key={chapter.id} value={chapter.id}>
													{chapter.name}
												</Select.Option>
											))}
										</FormikSelectField>
									</Col>
								</Row>
								<Row justify="center">
									<Col span={18}>
										<FormikSelectField
											name="gradeId"
											onChange={e => this.resetSubjectSelection(formik)}
											defaultValue={formik.initialValues.gradeId}
											value={formik.values.gradeId}
											label={translate(`${TRANSLATION_BASE_PATH}._GRADE`)}
											placeholder={translate(`${TRANSLATION_BASE_PATH}._GRADE`)}
										>
											{this.state.stagesList
												.filter((stage: any) => stage.id === formik.values.stage)
												.flatMap((stage: { grades: any }) => stage.grades)
												.map((chapter: any) => (
													<Select.Option key={chapter.id} value={chapter.id}>
														{chapter.name}
													</Select.Option>
												))}
										</FormikSelectField>
									</Col>
								</Row>
								<Row justify="center">
									<Col span={18}>
										<FormikSelectField
											name="subjectId"
											defaultValue={formik.initialValues.subjectId}
											value={formik.values.subjectId}
											label={translate(`${TRANSLATION_BASE_PATH}._SUBJECT`)}
											placeholder={translate(`${TRANSLATION_BASE_PATH}._SUBJECT`)}
										>
											{this.getSubjectsOptions(formik)}
										</FormikSelectField>
									</Col>
								</Row>
								{OAuth2Service.CurrentOrganization?.sector === OrganizationSectorType.Public && (
									<>
										<Row justify="center" className="pb-24">
											<Col span={18}>
												<Divider className="m-0" />
											</Col>
										</Row>
										<Row justify="center">
											<Col span={18}>
												<FormikDefaultInputField
													name="price"
													label="Cmimi"
													value={formik.values.price}
													addonAfter={<>&euro;</>}
												/>
											</Col>
										</Row>
									</>
								)}
								{this.state.tags && this.state.tags.length > 0 && (
									<>
										<Row justify="center">
											<Col span={18}>
												<Divider className="p-0" />
											</Col>
										</Row>
										<Row justify="center" className="pb-16">
											<Col span={18}>
												<Text fontSize="14" lineHeight="20" wheight="semibold">
													Grupimet
												</Text>
											</Col>
										</Row>
										<Row justify="center" className="pb-16">
											<Col span={18}>{this.renderTags()}</Col>
										</Row>
									</>
								)}
								{
									(!currentOrganization.ParentOrganizationId && currentOrganization.sector === "PRIVATE" && userRole === Role.Admin) ||
									userRole === Role.Owner && currentOrganization?.OrganizationId === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65" &&
									(classroomOrganization?.sector === "PUBLIC" || classroomOrganization?.id === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65") && (
									<Row justify="center" className="mb-24">
										<Col span={18}>
											<Checkbox
												checked={formik.values.featured}
												onChange={e => {
													formik.setFieldValue("featured", e.target.checked);
												}}
											>
												{translate(`${MARKETPLACE_COURSE_CATEGORIES}._FEATURED`)}
											</Checkbox>
											<Checkbox
												checked={formik.values.trending}
												onChange={e => {
													formik.setFieldValue("trending", e.target.checked);
												}}
											>
												{translate(`${MARKETPLACE_COURSE_CATEGORIES}._TRENDING`)}
											</Checkbox>
											<Checkbox
												checked={formik.values.popular}
												onChange={e => {
													formik.setFieldValue("popular", e.target.checked);
												}}
											>
												{translate(`${MARKETPLACE_COURSE_CATEGORIES}._POPULAR`)}
											</Checkbox>
										</Col>
									</Row>
								)}
								<Row justify="center" className="mb-24">
									<Col span={18}>
										<Button
											type="primary"
											className="full_width_button"
											htmlType={"submit"}
											loading={this.state.loading}
										>
											{translate(`${TRANSLATION_BASE_PATH}._SAVE`)}
										</Button>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				</Col>
			</Row>
		) : (
			<></>
		);
	}
}

const CourseOverview = withTranslation()(CourseOverviewComponent);
export default CourseOverview;
