import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Logo from "assets/images/icons/WhiteLogo.svg";
import FooterPattern from "assets/images/icons/IntroMessageStudentPattern.svg";
import Image from "common/components/general/Image";
import { useStoreState } from "../../../../store/hooks";
import { getDesignFile, getDesignS3Url } from "../../../../common/utils/Design";
import S3ActionType from "../../../../services/domain/administration/S3Action";

const TRANSLATION_BASE_PATH = "_LOGIN._INTRO_MESSAGE";

interface PropsInterface {
	cover?: string;
	title?: string;
	body?: string;
	gradient?: string
}

const IntroMessage = (props: PropsInterface) => {
	const primaryInverseLogo = useStoreState(state => state.designSettings.items.primaryInverseLogo);
	const designLoading = useStoreState(state => state.designSettings.loading);
	const style = props.gradient ? {background: props.gradient} : {};

	const { t: translate } = useTranslation();
	return (
		<Row className="intro__message__panel" style={style}>
			<Col span={24}>
				<a href="https://www.akademi.al/">
					{!designLoading &&
					<Image className="intro__message__panel-logo" src={ getDesignFile(Logo, primaryInverseLogo) } />}
				</a>
			</Col>
			<Col span={24} className="message__title">
				<h1>{props.title ? props.title : translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</h1>
			</Col>
			<Col span={24} className="message__description">
				{
					props.body ? <div dangerouslySetInnerHTML={{__html: props.body}} /> : <h6>{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}</h6>
 				}
			</Col>
			<Col span={24} className="footer__pattern">
				<Image src={props.cover ? getDesignS3Url(props.cover, S3ActionType.DOWNLOAD) : FooterPattern} />
			</Col>
		</Row>
	);
};

export default IntroMessage;
