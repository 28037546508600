import ApiServiceData from "../api.service";
import SubjectPlanTreeDto from "../../dtos/administration/subjectPlan.dto";

const relativeUrl = "/v1/api/subject-plan-tree";
const relativePublicUrl = "/v1/api/public-subject-plan-tree";

export default class SubjectPlanTreeService extends ApiServiceData {
	public getSubjectPlanTreeBySubjectId(id: string): Promise<SubjectPlanTreeDto> {
		return this.get(relativeUrl + "/subjectPlan/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getPublicSubjectPlanTreeBySubjectId(id: string): Promise<SubjectPlanTreeDto> {
		return this.publicGet(relativePublicUrl + "/subjectPlan/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public async createNewNode(newNode: any, parentId: string) {
		return this.post(`${relativeUrl}/${parentId}`, newNode);
	}

	public async deleteNode(nodeId: string) {
		return this.delete(`${relativeUrl}/${nodeId}`);
	}

	public async moveNode(nodeId: string, newParentId: string) {
		return this.update(`${relativeUrl}/${newParentId}`, { id: nodeId });
	}

	public async getTree(copyFromSubjectPlanId: string) {
		return this.get(`${relativeUrl}/getTree/${copyFromSubjectPlanId}`);
	}

	public async createTree(treeData: any, subjectPlanTree: string) {
		return this.post(`${relativeUrl}/createTree/${subjectPlanTree}`, treeData);
	}
}
