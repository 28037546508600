import ApiServiceData from "../api.service";

const relativeUrl = "/v1/api/subject-plan";

export default class SubjectPlanService extends ApiServiceData {
	public async createSubjectPlanForClassroom(name: string, classroomId: string) {
		return this.post(relativeUrl, {
			name: name,
			ClassroomId: classroomId
		}).then(res => {
			return res.data;
		}).catch(error => {
			throw error.response;
		});;
	}
}
