import React from "react";
import Text from "common/components/general/Text";
import { Space, Tooltip } from "antd";
import i18n from "i18next";
import Icon from "common/components/general/Icon";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";

export const FormFieldLabelWithTooltip = (label: string, tooltipInfo: string) => {
	return (
		<Space>
			<Text fontSize="14" lineHeight="22" className="color-gray-9">
				{i18n.t(`${TRANSLATION_BASE_PATH}.${label}`)}
			</Text>
			<Tooltip trigger={["hover"]} title={tooltipInfo}>
				<a>
					<Icon className="cursor_pointer color-gray-9" fontSize="20" type="ri-information-line" />
				</a>
			</Tooltip>
		</Space>
	);
};
