import React from "react";
import { Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW._PENDIG_REQUESTS_BADGE";

interface PendingClassJoinBadgeProps {
	onShow: () => void;
	onAccept: (enrollmentId: string) => void;
	pendingRequests: any[];
}



export const PendingClassJoinBadge = (props: PendingClassJoinBadgeProps) => {
	const { t: translate } = useTranslation();

	const getMessage = (pendingRequests: any[]) => {
		if (pendingRequests.length === 1) {
			return (
				<Space>
					<Text fontSize="14" lineHeight="22" wheight="semibold">
						{pendingRequests[0].User.firstName + " " + pendingRequests[0].User.lastName}
					</Text>
					{translate(`${TRANSLATION_BASE_PATH}._MESSAGE_FOR_1`)}
				</Space>
			);
		}
		if (pendingRequests.length > 1) {
			return (
				<Space>
					<Text fontSize="14" lineHeight="22" wheight="semibold">
						{pendingRequests[0].User.firstName + " " + pendingRequests[0].User.lastName}
					</Text>
					{translate(`${TRANSLATION_BASE_PATH}._MESSAGE_FOR_MORE_THAN_1`,
						{ studentCount: pendingRequests.length - 1 })
					}
				</Space>
			);
		}
		return (
			"There are no pending requests!"
		);
	};

	return (
		<Row justify="center" className="classroom__pending_requests_badge">
			<Space direction="horizontal" align="center" size={16}>
				<Icon type="ri-error-warning-fill" className="color-gray-2" fontSize="24" />
				<Text fontSize="14" lineHeight="22" className="color-gray-2">
					{getMessage(props.pendingRequests)}
				</Text>
				{props.pendingRequests.length > 1 &&
					<Button type="default" onClick={() => props.onShow()}>
						{translate(`${TRANSLATION_BASE_PATH}._BUTTON_SHOW`)}
					</Button>
				}
				{props.pendingRequests.length === 1 &&
					<Button type="default" onClick={() => props.onAccept(props.pendingRequests[0].id)} className="background-color-green-6">
						<span className="color-gray-1">
							{translate(`${TRANSLATION_BASE_PATH}._BUTTON_ACCEPT`)}</span>
					</Button>
				}
			</Space>
		</Row>
	);
};
