import { v4 as uuidv4 } from "uuid";
import { EnrollmentStatus } from "services/domain/administration/EnrollmentStatus";
import ClassroomDto from "./classroom.dto";
import UserDto from "../authentication/user/user.dto";

export default class EnrollmentDto {
	readonly id!: string;
	readonly UserId!: string;
	readonly ClassroomId!: string;
	readonly status!: EnrollmentStatus;
	readonly enrolledAt!: Date;
	readonly Classroom?: ClassroomDto;
	readonly createdBy?: string;
	readonly User?: UserDto;

	constructor(data: {
		UserId: string;
		ClassroomId: string;
		status: EnrollmentStatus;
		enrolledAt: Date;
		[key: string]: any;
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
