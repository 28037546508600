import * as Yup from "yup";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		searchKey: Yup.string()
			.nullable()
			.trim()
			.max(255)
			.required("*")
	});
