import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_NATIONALITY";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		nationality: Yup.string()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
