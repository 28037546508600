import * as Yup from "yup";
import moment from "moment";

import { generalPasswordRegx, usernameRegx } from "common/components/utils/Regx";
import { Role } from "services/domain/login/Role";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		accountType: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.oneOf([Role.Student, Role.Teacher, Role.Parent], "Not valid option"),
		firstName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		lastName: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		email: Yup.string()
			.typeError(translate(`${TRANSLATION_BASE_PATH}._NOT_VALID_EMAIL`))
			.email(translate(`${TRANSLATION_BASE_PATH}._NOT_VALID_EMAIL`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(40, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_40`)),
		password: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.min(8, translate(`${TRANSLATION_BASE_PATH}._PASSWORD_REQUIREMENTS`))
			.matches(generalPasswordRegx, translate(`${TRANSLATION_BASE_PATH}._PASSWORD_REQUIREMENTS`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		birthDate: Yup.string()
			.nullable()
			.notRequired()
			.when("accountType", {
				is: (accountType: string) => accountType === Role.Student,
				then: Yup.string()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.test("is-valid-date", translate(`${TRANSLATION_BASE_PATH}._DATE_NOT_VALID`), function(value) {
						return moment.utc(value, "YYYY-MM-DD").isValid() === true;
					})
					.test("gretaer-than-three", translate(`${TRANSLATION_BASE_PATH}._GREATER_THAN_THREE`), function(
						value
					) {
						return moment.utc(value, "YYYY-MM-DD") < moment().add(-3, "years");
					})
			}),
		username: Yup.string()
			.nullable()
			.notRequired()
			.when(["accountType", "birthDate"], {
				is: (accountType: string, birthDate: Date) => {
					if (accountType === Role.Student && birthDate !== undefined) {
						const secondDate = moment();
						const duration = moment.duration(secondDate.diff(birthDate));
						const years = duration.asYears();

						if (years <= 13) return true;
					}

					return false;
				},
				then: Yup.string()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.matches(usernameRegx, translate(`${TRANSLATION_BASE_PATH}._USERNAME_CHARACTERS_ALLOWED`))
					.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`))
			})
	});
