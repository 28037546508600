import { Card, Col, Radio, Row, Select, Skeleton, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pie } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import Ellipse from "common/components/general/Ellipse";
import ClassroomService from "services/administration/classroom.service";
import { MAIN_PATH } from "../../../..";
import { COURSES_PATH } from "../../../courses/TeacherCourses";
import DashboardLessonsAnalyticsIcon from "assets/images/dashboard-lessons-analytics.svg";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._LESSONS_ANALYTICS";
const ALL_KEY = "ALL";

const getCardTitle = (translate: (key: string) => string) => {
	return (
		<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
			{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
		</Text>
	);
};

const emptyContent = (translate: (key: string) => string, history: any) => {
	return (
		<Space direction="horizontal" size={24} className="lessons__analytics_empty_state_space">
			<Image src={DashboardLessonsAnalyticsIcon} />
			<Space direction="vertical" size={24}>
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION`)}
				</Text>
				<Button
					type="primary"
					icon="ri-add-line"
					iconPosition="left"
					onClick={() => {
						history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
					}}
				>
					{translate(`${TRANSLATION_BASE_PATH}._ADD_VIRTUAL_CLASSROOM`)}
				</Button>
			</Space>
		</Space>
	);
};

export const LessonsAnalytics = () => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const [loadingData, setLoadingData] = useState<boolean>(false);
	const [classrooms, setClassrooms] = useState<any[]>([]);
	const [groupBy, setGroupBy] = useState<"all" | "week">("all");
	const [selectedClassroom, setSelectedClassroom] = useState<string>(ALL_KEY);

	useEffect(() => {
		const fetchData = () => {
			setLoadingData(true);
			return new ClassroomService()
				.getAllWithLessons()
				.then((result: any[]) => {
					return setClassrooms(result);
				})
				.finally(() => {
					setLoadingData(false);
				});
		};
		fetchData();
	}, []);

	const getExtraContent = () => {
		if (classrooms.length === 0) return null;
		return (
			<Select
				defaultValue={selectedClassroom}
				style={{ width: "190px" }}
				onChange={value => {
					setSelectedClassroom(value);
				}}
			>
				<Select.Option value={ALL_KEY}>
					<Space size={10} align="center">
						<Icon type="ri-door-open-line" className="color-gray-6" />
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._ALL`)}
						</Text>
					</Space>
				</Select.Option>
				{classrooms.map((item, index) => {
					return (
						<Select.Option value={item.id} key={index}>
							<Space size={10} align="center">
								<Icon type="ri-door-open-line" className="color-gray-6" />
								<Text fontSize="14" lineHeight="22" className="color-gray-8" maxLength={15}>
									{item.name}
								</Text>
							</Space>
						</Select.Option>
					);
				})}
			</Select>
		);
	};

	const getFilteredLessons = () => {
		let classroomsData = classrooms;
		if (selectedClassroom !== ALL_KEY) {
			classroomsData = classrooms.filter(item => item.id === selectedClassroom);
		}

		let lessons: any[] = classroomsData.reduce(function(result, next) {
			return result.concat(next.SubjectPlan.SubjectPlanTree.Lessons);
		}, []);

		if (groupBy !== "all") {
			lessons = lessons.filter(item =>
				moment(item.endDate).isBetween(moment().startOf("week"), moment().endOf("week"))
			);
		}

		return lessons;
	};

	const getTotalLessons = () => {
		return getFilteredLessons().length;
	};

	const getTotalLessonsByType = (type: string) => {
		return getFilteredLessons().filter(item => item.lessonType === type).length;
	};

	const getLessonsContent = () => {
		const videos = getTotalLessonsByType("VIDEO");
		const meetings = getTotalLessonsByType("MEETING");
		const quizes = getTotalLessonsByType("QUIZ");
		const assignments = getTotalLessonsByType("ASSIGNMENT");
		const pdfPresentations = getTotalLessonsByType("PDF");
		return (
			<>
				<Row className="mb-24">
					<Col span={24}>
						<Space className="full__width" size={16} align="center">
							<Radio.Group
								value={groupBy}
								onChange={e => {
									setGroupBy(e.target.value);
								}}
							>
								<Radio.Button value="all">{translate(`${TRANSLATION_BASE_PATH}._TOTAL`)}</Radio.Button>
								<Radio.Button value="week">{translate(`${TRANSLATION_BASE_PATH}._WEEK`)}</Radio.Button>
							</Radio.Group>

							{groupBy === "week" ? (
								<Space size={4} align="center" className="week__interval">
									<Icon type="ri-calendar-line" className="color-gray-6" />
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{moment()
											.startOf("week")
											.format("DD MMM")}{" "}
										~{" "}
										{moment()
											.endOf("week")
											.format("DD MMM")}
									</Text>
								</Space>
							) : null}
						</Space>
					</Col>
				</Row>
				<Row gutter={[48, 24]}>
					<Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
						<Row className="pie__analytics">
							<Col xs={0} sm={2} md={0} lg={0} xl={0} xxl={4}></Col>
							<Col xs={12} sm={10} md={12} lg={12} xl={12} xxl={8}>
								<Space size={4} direction="vertical">
									<Text fontSize="14" lineHeight="22" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._ALL_LESSONS`)}
									</Text>
									<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
										{getTotalLessons()}
									</Text>
								</Space>
							</Col>
							<Col xs={12} sm={10} md={12} lg={12} xl={12} xxl={8}>
								<div className="">
									<Pie
										data={{
											labels: ["", "", "", "", ""],
											datasets: [
												{
													data: [videos, meetings, quizes, assignments, pdfPresentations],
													backgroundColor: [
														"#F5222D",
														"#2063E3",
														"#FAAD14",
														"#722ED1",
														"#52c41a"
													],
													hoverBackgroundColor: [
														"#F5222D",
														"#2063E3",
														"#FAAD14",
														"#722ED1",
														"#52c41a"
													]
												}
											]
										}}
										options={{
											legend: {
												display: false
											}
										}}
										width={120}
										height={120}
									/>
								</div>
							</Col>
						</Row>
					</Col>
					<Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
						<Row gutter={[24, 16]}>
							<Col span={12}>
								<Space size={12}>
									<Ellipse size={14} color="#F5222D" />
									<Space direction="vertical" size={2}>
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{translate(`${TRANSLATION_BASE_PATH}._TOTAL_VIDEOS`)}
										</Text>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{videos.toLocaleString()}
										</Text>
									</Space>
								</Space>
							</Col>
							<Col span={12}>
								<Space size={12}>
									<Ellipse size={14} color="#2063E3" />
									<Space direction="vertical" size={2}>
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{translate(`${TRANSLATION_BASE_PATH}._TOTAL_MEETINGS`)}
										</Text>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{meetings.toLocaleString()}
										</Text>
									</Space>
								</Space>
							</Col>
							<Col span={12}>
								<Space size={12}>
									<Ellipse size={14} color="#FAAD14" />
									<Space direction="vertical" size={2}>
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{translate(`${TRANSLATION_BASE_PATH}._TOTAL_QUIZES`)}
										</Text>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{quizes.toLocaleString()}
										</Text>
									</Space>
								</Space>
							</Col>
							<Col span={12}>
								<Space size={12}>
									<Ellipse size={14} color="#722ED1" />
									<Space direction="vertical" size={2}>
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{translate(`${TRANSLATION_BASE_PATH}._TOTAL_ASSIGNMENTS`)}
										</Text>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{assignments.toLocaleString()}
										</Text>
									</Space>
								</Space>
							</Col>
							<Col span={12}>
								<Space size={12}>
									<Ellipse size={14} color="#52c41a" />
									<Space direction="vertical" size={2}>
										<Text fontSize="14" lineHeight="22" className="color-gray-7">
											{translate(`${TRANSLATION_BASE_PATH}._TOTAL_PRESENTATIONS`)}
										</Text>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{pdfPresentations.toLocaleString()}
										</Text>
									</Space>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	};

	return (
		<Skeleton active loading={loadingData}>
			<Card
				title={getCardTitle(translate)}
				extra={getExtraContent()}
				className="dashboard__lessons_analytics_card"
			>
				{classrooms && classrooms.length > 0 ? getLessonsContent() : emptyContent(translate, history)}
			</Card>
		</Skeleton>
	);
};
