import { Col, Row } from "antd";
import { SearchBox } from "scenes/admin/components/searchBox/SearchBox";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import { ResultTags } from "./ResultTags";
import { DownOutlined,UpOutlined } from '@ant-design/icons';
import SubjectService from "services/administration/subject.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import styled from "styled-components";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {SubjectsSearch} from './SubjectsSearch'
export interface SearchBarProps {
	loading: boolean;
	upperSpace?: boolean;
	search: (searchValue: string) => void;
	toggleFilterBar: (toggle: boolean) => void;
	filters: any;
}

const MIN_CHARS_LENGTH = 3;

export const OldSearchBar = function (props: SearchBarProps) {
	let history = useHistory();
	const [keyValid, setKeyValid] = useState(true);
	const [subjects, setSubjects] = useState<any>([]);

	const [displaysubjects, setDisplaySubjects] = useState(false)
	const filters = props.filters;
	const search = (key: any) => {
		const trimmedKey = key.trim();
		const keyValid = trimmedKey.length > MIN_CHARS_LENGTH || !trimmedKey;
		setKeyValid(keyValid);
		if (keyValid) props.search(trimmedKey);
	};

	const { t: translate } = useTranslation();
	const totalItems = useStoreState(state => state.subjects.totalItems);

	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const organizationId = currentUserInfo?.Organizations?.[0]?.OrganizationId || 'e47c7a13-83e8-41a0-afcc-3a8fd0fbda65';


	/**
	 * Load all Subjects without filters
	 */
	const loadAllSubjects = () => {
		return new SubjectService()
			.getAll(organizationId)
			.then((result: any) => {
				const subjects = result.filter((subject: any) => subject?.TotalClassrooms > 0);
				return setSubjects(_.orderBy(subjects, (x: any) => x.name));
			})
	};


	const onChange = () => {
		setKeyValid(true);
	};

	const displaySubjects = async () =>{
		if(!subjects.length){
			await loadAllSubjects()
		}
		setDisplaySubjects(!displaysubjects)
	}

	function handleClick(subject:any) {
		history.push(`/subjects/all-courses-by-subject/${subject.id}`);
		setDisplaySubjects(!displaysubjects)
	}

	const subjectButton = () => {
		return <Col
			className="pl-20 pt-5"
			xs={24}
			sm={24}
			md={0}
			lg={6}
			xl={6}
			xxl={6}>
			<StyledSubjectButton onClick={()=> displaySubjects()} block={true}>
				{translate("_ADMIN._SEARCH_BOX._FILTER_NAME")} {displaysubjects ? <UpOutlined/> : <DownOutlined />}
			</StyledSubjectButton>
		</Col>
	}

	return (<Row
		className={props.upperSpace ? "mt-24" : ""}
		gutter={[24, 0]}>

		<Col xs={24} className="mb-16">
			<Row gutter={[24, 0]}>
				{/* Search bar */}
				<Col
					xs={21}
					sm={filters.length > 0 ? 19 : 20}
					md={filters.length > 0 ? 20 : 21}
					lg={18}
					xl={18}
					xxl={18}>
					<SearchBox loading={props.loading} onSearch={search} onChange={onChange} />
				</Col>
				{/* Mobile search icon button */}
				<Col
					className="pl-0"
					xs={3}
					sm={0}
					md={0}
					lg={0}
					xl={0}
					xxl={0}>
					<Button
						onClick={() => props.toggleFilterBar(true)}
						type={filters.length > 0 ? "primary": "default"}
						ghost={!!filters.length}
						shape="circle"
						className="flex__center_justify_center">
						<Icon type="ri-filter-line"/>
					</Button>
				</Col>
				{/* Pad Search button */}
				<Col
					className="pl-0"
					xs={0}
					sm={filters.length > 0 ? 5 : 4}
					md={filters.length > 0 ? 4 : 3}
					lg={0}
					xl={0}
					xxl={0}>
					<Button
						onClick={() => props.toggleFilterBar(true)}
						type={filters.length > 0 ? "primary": "default"}
						ghost={!!filters.length}
						shape="round"
						className="flex__center">
						<Icon type="ri-filter-line" className="mr-4"/>
						{translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} {filters.length ? `(${filters.length})` : ""}
					</Button>
				</Col>

				{/* Right side text on desktop */}
				{subjectButton()}

			</Row>

			{!displaysubjects && <Row gutter={[24, 0]} style={{textAlign:'end'}}>
				<Col
					xs={0}
					sm={0}
					md={21}
					lg={18}
					xl={18}
					xxl={18}>
					{translate("_ADMIN._SEARCH_BOX._REZULTATE", {nrResults: totalItems})}
				</Col>
			</Row>}
			{/* all subjects */}
			{displaysubjects && <SubjectsSearch subjects={subjects} handleClick={handleClick}/>}

		</Col>
		{/* <ResultTags nrResults={totalItems} filters={filters} /> */}
		{!keyValid && (
			<Col span="24" className="color-red-6">
				{/* @todo move to translations */}
				Vendosni nje fjale me te gjate se {MIN_CHARS_LENGTH} karaktere
			</Col>
		)}
	</Row>);
};

const StyledSubjectButton = styled(Button)`
	@media (max-width: 768px) {
		margin-top: 15px;
	}
`;