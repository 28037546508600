import React from "react";
import { Bool } from "../../common/inputs/Bool";
import { Color } from "../../common/inputs/Color";
import { Text } from "../../common/inputs/Text";
import { Image } from "../../common/inputs/Image";
import { InputProps, PageComponentSpecsOptionType } from "../../utils/types";
import { HtmlText } from "../../common/inputs/HtmlText";

interface DesignInputInterface {
	specs: PageComponentSpecsOptionType;
	value: string;
	organizationId: string;
}

export function InputGenerator(props: DesignInputInterface) {
	const Components = {
		bool: Bool,
		color: Color,
		htmlText: HtmlText,
		image: Image,
		text: Text,
	};

	const inputProps: InputProps = {
		title: props.specs.name,
		name: props.specs.var,
		value: props.value,
		options: props.specs.options || {},
		organizationId: props.organizationId
	};

	return <div>{Components[props.specs.type](inputProps)}</div>;
}
