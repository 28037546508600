import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import { SIGN_IN_PATH } from "../../scenes/signIn";
import { TERMS_OF_SERVICE_PATH } from "../../scenes/terms/TermsOfService";
import { PRIVACY_POLICY_PATH } from "../../scenes/terms/PrivacyPolicy";
import { RECAPTCHA_KEY } from "services/authentication/oauth2.service";

const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER";

interface RegisterFooterActionsProps {
	isFormValid: boolean;
	submitting: boolean;
	recaptchaRef: any;
}

export const RegisterFooterActions = (props: RegisterFooterActionsProps) => {
	const { t: translate } = useTranslation();

	return (
		<>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<ReCAPTCHA ref={props.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
				</Col>
			</Row>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<Button
						type="primary"
						htmlType="submit"
						icon="ri-arrow-right-line"
						size="large"
						iconPosition="right"
						className="full_width_button"
						disabled={!props.isFormValid}
						loading={props.submitting}
					>
						{translate(`${TRANSLATION_BASE_PATH}._REGISTER_LABEL`)}
					</Button>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
				<Col xs={24} sm={24} md={16} lg={24} xl={18}>
					<Text fontSize="12" lineHeight="20">
						{translate(`${TRANSLATION_BASE_PATH}._YOU_ACCEPT_BY_REGISTER`)}{" "}
						<Link to={`/${SIGN_IN_PATH}/${TERMS_OF_SERVICE_PATH}`}>
							{translate(`${TRANSLATION_BASE_PATH}._TERMS_OF_SERVICE`)},{" "}
						</Link>
						<Link to={`/${SIGN_IN_PATH}/${PRIVACY_POLICY_PATH}`}>
							{translate(`${TRANSLATION_BASE_PATH}._PRIVACY_POLICY`)}
						</Link>
					</Text>
				</Col>
			</Row>
		</>
	);
};
