import React, { ChangeEvent } from "react";
import { Input } from "antd";

const { TextArea: AntTextArea } = Input;

interface TextAreaProps {
	name?: string;
	placeholder?: string;
	cols?: number;
	rows?: number;
	wrap?: string;
	disabled?: boolean;
	maxLength?: number;
	minLength?: number;
	className?: string;
	id?: string;
	autoFocus?: boolean;
	value?: string;
	autoSize?: object;
	onChange?: ((event: ChangeEvent<HTMLTextAreaElement>) => void) | undefined
}

const TextArea: React.FC<TextAreaProps> = props => {
	return <AntTextArea {...props} />;
};

export default TextArea;
