import React from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import { SUPPORT_EMAIL } from "../../../utils/Constants";
import { RECAPTCHA_KEY } from "services/authentication/oauth2.service";

export type VerificationStatus = "Sent" | "Resent" | "Expired" | "Verified";

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface RegisterEmailVerificationProps {
	email: string;
	name?: string;
	submitting: boolean;
	status: VerificationStatus;
	onResendEmail?: (email: string) => void;
	onContinue?: (email: string) => void;
	recaptchaRef: any;
}

const TRANSLATION_BASE_PATH = "_LOGIN._EMAIL_VERIFICATION";

const getContactSupportContent = (status: VerificationStatus, translate: (key: string) => string) => {
	if (status === "Resent") {
		return (
			<Row justify="center" className="pt-24">
				<Col>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._PROBLEMS_WITH_REGISTER_EMAIL`)}
					</Text>
					<br />
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._CONTACT_US`)}{" "}
						<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
					</Text>
				</Col>
			</Row>
		);
	}
	return null;
};

const iconMapWithStatus = new Map<VerificationStatus, string>([
	["Sent", "ri-mail-send-line"],
	["Resent", "ri-mail-send-line"],
	["Expired", "ri-error-warning-line"],
	["Verified", "ri-checkbox-circle-line"]
]);

export const RegisterEmailVerification = (props: RegisterEmailVerificationProps) => {
	const { t: translate } = useTranslation();

	const getTitleContentBasedOnStatus = () => {
		switch (props.status) {
			case "Sent":
				return translate(`${TRANSLATION_BASE_PATH}._CONFIRM_REGISTRATION_EMAIL`);
			case "Resent":
				return (
					<>
						{translate(`${TRANSLATION_BASE_PATH}._REGISTER_EMAIL_SENT`)}{" "}
						<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-blue-6">
							{translate(`${TRANSLATION_BASE_PATH}._RESENT`)}
						</Text>{" "}
						{translate(`${TRANSLATION_BASE_PATH}._SUCCESS`)}
						{". "}
						{translate(`${TRANSLATION_BASE_PATH}._CONFIRM_REGISTRATION_EMAIL`)}
					</>
				);
			case "Expired":
				return <>{translate(`${TRANSLATION_BASE_PATH}._CODE_EXPIRED`)}</>;
			case "Verified":
				return (
					<>{translate(`${TRANSLATION_BASE_PATH}._ACCOUNT_VERIFIED`).replace("[USER]", props.name ?? "")}</>
				);
		}
	};

	const getDescriptionContentBasedOnStatus = () => {
		switch (props.status) {
			case "Sent":
			case "Resent":
				return (
					<>
						{translate(`${TRANSLATION_BASE_PATH}._EMAIL_SENT_AT`)}{" "}
						<Text fontSize="14" lineHeight="22" wheight="semibold">
							{props.email}
						</Text>
						. {translate(`${TRANSLATION_BASE_PATH}._PLEASE_VERIFY_EMAIL`)}
					</>
				);
			case "Expired":
				return <>{translate(`${TRANSLATION_BASE_PATH}._CODE_EXPIRED_DESCRIPTION`)}</>;
			case "Verified":
				return <>{translate(`${TRANSLATION_BASE_PATH}._ACCOUNT_VERIFIED_DESCRIPTION`)}</>;
		}
	};

	const getButtonBasedOnStatus = () => {
		switch (props.status) {
			case "Sent":
			case "Resent":
				return (
					<Text fontSize="14" lineHeight="22" wheight="regular" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._EMAIL_NOT_RECEIVED`)}{" "}
						<Text
							fontSize="14"
							lineHeight="22"
							wheight="semibold"
							className="color-blue-6 cursor_pointer"
							onClick={() => {
								if (props.onResendEmail !== undefined) {
									props.onResendEmail(props.email);
								}
							}}
						>
							{translate(`${TRANSLATION_BASE_PATH}._RESENT_EMAIL`)}
						</Text>
						<ReCAPTCHA ref={props.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
					</Text>
				);
			case "Expired":
				return (
					<>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							className="full_width_button"
							onClick={() => {
								if (props.onResendEmail !== undefined) {
									props.onResendEmail(props.email);
								}
							}}
						>
							{translate(`${TRANSLATION_BASE_PATH}._GET_NEW_VERIFICATION_LINK`)}
						</Button>
						<ReCAPTCHA ref={props.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
					</>
				);
			case "Verified":
				return (
					<Button
						type="primary"
						htmlType="submit"
						size="large"
						className="full_width_button"
						onClick={() => {
							if (props.onContinue !== undefined) {
								props.onContinue(props.email);
							}
						}}
					>
						{translate(`${TRANSLATION_BASE_PATH}._SIGN_IN`)}
					</Button>
				);
			default:
				return null;
		}
	};

	return (
		<Spin spinning={props.submitting} indicator={loadingIcon}>
			<Row>
				<Col span={24}>
					<Icon type={iconMapWithStatus.get(props.status) ?? ""} className="email__verification__icon" />
				</Col>
			</Row>
			<Row className="pt-32">
				<Col span={24}>
					<Text fontSize="30" lineHeight="38" wheight="semibold">
						{getTitleContentBasedOnStatus()}
					</Text>
				</Col>
			</Row>
			<Row className="pt-24">
				<Col span={24}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{getDescriptionContentBasedOnStatus()}
					</Text>
				</Col>
			</Row>
			<Row className="pt-24">
				<Col span={24} className="flex__center_justify_center">
					{getButtonBasedOnStatus()}
				</Col>
			</Row>
			{getContactSupportContent(props.status, translate)}
		</Spin>
	);
};
