import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { NotFound } from "common/components/feedback/NotFound";

import { ChangePassword } from "./scenes/changePassword";
import { MyProfile } from "./scenes/myProfile";
import DynamicLayout from "../../../../common/components/utils/DynamicLayout";

export const SETTINGS_PATH = "settings";
export const USER_PROFILE_PATH = "user-profile";
export const CHANGE_PASSWORD_PATH = "change-password";

const Settings = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/${USER_PROFILE_PATH}`}
				exact
				component={(props: any) => (
					<DynamicLayout
						{...props}
						templateName={"settingsMyProfile"}
						Pages={{settingsMyProfile: MyProfile}}
						DefaultPage={MyProfile}
					/>
				)}
			/>
			<Route
				path={`${path}/${CHANGE_PASSWORD_PATH}`}
				exact
				component={(props: any) => (
					<DynamicLayout
						{...props}
						templateName={"settingsPassword"}
						Pages={{settingsPassword: ChangePassword}}
						DefaultPage={ChangePassword}
					/>
				)}
			/>
			<Route path={`${path}`} component={NotFound} />
		</Switch>
	);
};

export default Settings;
