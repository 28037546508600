import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_LOGIN._FORGET_PASWORD";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		email: Yup.string()
			.trim()
			.email(translate(`${TRANSLATION_BASE_PATH}._NOT_VALID_EMAIL`))
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`))
	});
