import React, { ReactNode, Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { message } from "antd";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Icon from "common/components/general/Icon";
import { formValidator } from "./ValidationSchema";
import { GradeLevel } from "services/domain/administration/Grade";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import GradeService from "services/administration/grade.service";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_GRADE";

class ChooseGradeComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			initialValues: {
				level: props.state.level ?? undefined,
				grade: props.state.grade ?? undefined
			},
			gradeOptions: []
		};
	}

	fetchData = async () => {
		const { t: translate } = this.props;
		await new GradeService()
			.getAllGrades()
			.then((data: any) => {
				this.setState({
					gradeOptions: data.map((item: any) => {
						return {
							id: item.id,
							name: item.name,
							level: item.level
						};
					})
				});
				return;
			})
			.catch(() => {
				message.error(translate(`${TRANSLATION_BASE_PATH}._ERROR_GETTING_GRADES`));
			});
	};

	componentDidMount() {
		const { t: translate } = this.props;
		this.props.setIsFormValid(formValidator(translate).isValidSync(this.state.initialValues));
		this.props.setTitle(translate(`${TRANSLATION_BASE_PATH}._GRADE`));
		this.fetchData();
	}

	onLevelChange = async (formik: FormikProps<any>, newLevelValue: string) => {
		if (newLevelValue !== undefined && formik.values.grade !== undefined) {
			const gradeConfig = this.state.gradeOptions?.find((item: any) => item.id === formik.values.grade);
			if (gradeConfig !== undefined && gradeConfig.level !== newLevelValue) {
				formik.setValues({
					level: newLevelValue,
					grade: undefined
				});
			}
		}
	};

	getGradeOptionsContent = (level: GradeLevel | undefined, formik: any): ReactNode => {
		if (level === undefined || this.state.gradeOptions === undefined || this.state.gradeOptions.length === 0)
			return null;
		return (
			<Row>
				<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
					<Radio name="grade" value={formik.values.grade} defaultValue={this.state.initialValues.grade}>
						<Row gutter={[8, 8]}>
							{this.state.gradeOptions
								.filter((item: any) => item.level === level)
								.map((item: any, index: number) => {
									return (
										<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} key={index}>
											<RadioElement value={item.id}>{item.name}</RadioElement>
										</Col>
									);
								})}
						</Row>
					</Radio>
				</Col>
			</Row>
		);
	};

	onSubmit = async (values: any) => {
		const newState = {
			...this.props.state,
			level: values.level,
			grade: values.grade
		};
		this.props.updateState(newState);

		await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				this.props.setIsSubmiting(false);
				this.props.goNext();
				return;
			})
			.catch(error => {
				console.log(error);
				this.props.setIsSubmiting(false);
			});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={false}
				validationSchema={formValidator(translate)}
				initialValues={this.state.initialValues}
				onSubmit={this.onSubmit}
				validate={values => {
					this.props.setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					this.props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-8">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_GRADE_DESCRIPTION`)}
									</Text>
									<br />
									<Text fontSize="14" lineHeight="22" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_GRADE_DESCRIPTION_TOOLTIP`)}
									</Text>
								</Col>
							</Row>

							<Radio
								name="level"
								onRadioChange={e => {
									this.onLevelChange(formik, e.target.value);
								}}
								defaultValue={this.state.initialValues.level}
							>
								<Row gutter={[8, 8]}>
									<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={GradeLevel.Preschool} showAsBox={true}>
											<div className="grade_level_type__option">
												<Icon type="ri-user-5-line" />
												{translate(`${TRANSLATION_BASE_PATH}._PRESCHOOL`)}
											</div>
										</RadioElement>
									</Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={GradeLevel.Elementary} showAsBox={true}>
											<div className="grade_level_type__option">
												<Icon type="ri-open-arm-line" />
												{translate(`${TRANSLATION_BASE_PATH}._ELEMENTARY`)}
											</div>
										</RadioElement>
									</Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={GradeLevel.Middle} showAsBox={true}>
											<div className="grade_level_type__option">
												<Icon type="ri-user-line" />
												{translate(`${TRANSLATION_BASE_PATH}._MIDDLE`)}
											</div>
										</RadioElement>
									</Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={GradeLevel.High} showAsBox={true}>
											<div className="grade_level_type__option">
												<Icon type="ri-user-4-line" />
												{translate(`${TRANSLATION_BASE_PATH}._HIGH`)}
											</div>
										</RadioElement>
									</Col>
								</Row>
							</Radio>

							{this.getGradeOptionsContent(formik.values.level, formik)}
						</form>
					);
				}}
			</Formik>
		);
	}
}

export const ChooseGrade = withTranslation()(ChooseGradeComponent);
