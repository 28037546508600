/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Col, Row, Skeleton, Modal } from "antd";
import React, { Component, ReactNode } from "react";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { WelcomeBaner } from "./components/welcome";
import { MarketplaceBanner } from "./components/marketplaceBanner";
import { CalendarWithLessons } from "./components/calendarWithLessons";
import { StatisticsCard } from "./components/statisticsCard";
import ClassSlider, { ClassItem } from "./components/classSlider";
import { previewFile } from "../../../courseBuilder/utils/FileUtils";
import { COURSE_BUILDER_PATH } from "../../../../scenes/courseBuilder";
import ClassroomDto from "dtos/administration/classroom.dto";
import ClassroomService from "services/administration/classroom.service";
import AnalyticsCoursesService from "services/analytics/course.service";
import { TeacherAssignmentsOverview } from "./components/teacherAssignmentsOverview";
import { LessonsAnalytics } from "./components/lessonsAnalytics";
import InvitationService from "services/administration/invitation.service";
import { showError, showMessage } from "common/utils/Notification";
import OrganizationService from "services/administration/organization.service";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { MainLayout } from "scenes/main/components/layout/Layout";
import { isComponentVisible, getComponentProps, boolVal } from "../../../../common/utils/Design";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._TEACHER_DASHBOARD";

class TeacherDashboardComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			loadingVirtualCoursesStatistics: false,
			classItems: null,
			totalVirtualClassrooms: 0,
			totalEnrolledStudents: 0,
			organizationInvitationModalVisible: false,
			organizationInvited: null
		};
	}

	teacherCourseBuilderPath = `/${COURSE_BUILDER_PATH}/_teacher/`;
	individualClassroomCourseBuilderPath = `/${COURSE_BUILDER_PATH}/individual/`;

	getPhotoUrls = async (items: any): Promise<any> => {
		return (
			(await Promise.all(
				items.map(async function(classroom: any) {
					const image = (classroom.File && (await previewFile(classroom.File))) || null;
					return { ...classroom, filePath: image && image.url };
				})
			).then(classroom => {
				return classroom;
			})) || []
		);
	};

	getClassItemsOverviewDetails = (data: ClassroomDto[]): Promise<ClassItem[]> => {
		const items = data.map((x: ClassroomDto) => {
			let path = this.teacherCourseBuilderPath;
			if (x.virtualClassroomType && x.virtualClassroomType === TypeOfClassroomBasedOnEnrollments.Individual) {
				path = this.individualClassroomCourseBuilderPath;
			}
			return {
				id: x.id,
				name: x.name,
				organizationName: x.SubjectPlan?.Organization?.name ?? "",
				teacherName: x.UserCreatedBy?.firstName + " " + x.UserCreatedBy?.lastName,
				isAsync: false,
				pathToClassroom: path + x.id,
				File: x.File
			};
		});

		return this.getPhotoUrls(items);
	};

	fetchClassrooms() {
		this.setState({ loading: true });

		return new ClassroomService()
			.getAllVirtualClassrooms()
			.then((result: any) => {
				return this.getClassItemsOverviewDetails(result);
			})
			.then(result => {
				return this.setState({ classItems: result });
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	fetchGeneralStatistics() {
		this.setState({ loadingVirtualCoursesStatistics: true });
		return new AnalyticsCoursesService()
			.getVirtualCoursesGeneralAnalytics()
			.then((result: any[]) => {
				return this.setState({
					totalVirtualClassrooms: result[0].classrooms,
					totalEnrolledStudents: result[0].students
				});
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.setState({ loadingVirtualCoursesStatistics: false });
			});
	}

	fetchInvitationOrganization() {
		return Promise.all([
			new InvitationService().getOrganizationOwnerInvitations(),
			new InvitationService().getOrganizationAdminInvitations(),
			new InvitationService().getOrganizationTeacherInvitations()
		]).then((organizationInvitation: any) => {
			const allOrganizationInvitation = organizationInvitation?.flat(1) || [];
			if (allOrganizationInvitation?.length > 0)
				return this.setState({
					organizationInvitationModalVisible: true,
					organizationInvited: allOrganizationInvitation[0]
				});
			return this.setState({ organizationInvitationModalVisible: false, organizationInvited: null });
		});
	}

	resize() {
		this.setState({
			smallerOrMedium: window.innerWidth < 992,
			large: window.innerWidth >= 992 && window.innerWidth < 1200,
			extraLarge: window.innerWidth >= 1200
		});
	}

	async componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
		this.fetchInvitationOrganization();
		this.fetchClassrooms();
		this.fetchGeneralStatistics();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	enrollToInvitedOrganization() {
		const { t: translate } = this.props;

		return new OrganizationService()
			.joinOrganizationByInvitation(this.state.organizationInvited?.id)
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._DONE`));
				return this.setState({ teacherHasJoinedOrg: true });
			})
			.catch(() => {
				showError(translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INVALID_CODE`));
			})
			.finally(() =>
				this.setState(
					{
						organizationInvitationModalVisible: false,
						organizationInvited: null
					},
					() => window.location.reload()
				)
			);
	}

	joinOrganizationFromInvitationModal(): ReactNode {
		const { t: translate } = this.props;

		const modalTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._TITLE`);
		const OkBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._OK_BTN_TITLE`);
		const cancelBtnTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._CANCEL_BTN_TITLE`);
		const inputTitle = translate(`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INPUT_TITLE`);

		return (
			<Modal
				title={modalTitle}
				visible={this.state.organizationInvitationModalVisible}
				onOk={() => this.enrollToInvitedOrganization()}
				onCancel={() => {
					return this.setState({
						organizationInvitationModalVisible: false,
						organizationInvited: null
					});
				}}
				okText={OkBtnTitle}
				cancelText={cancelBtnTitle}
			>
				<Formik
					initialValues={{}}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onSubmit={() => {}}
				>
					{() => (
						<>
							<Row>
								<Col span={24}>
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										{translate(
											`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._INVITATION_DESCRIPTION`
										)}{" "}
										<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-8">
											{this.state.organizationInvited?.name}.
										</Text>
									</Text>
								</Col>
							</Row>
							<Row className="mt-16">
								<Col span={24}>
									<Input
										name="code"
										label={inputTitle}
										value={`${this.state.organizationInvited?.code} (${this.state.organizationInvited?.name})`}
										disabled
										disabledTooltip={translate(
											`${TRANSLATION_BASE_PATH}._MODAL_INVITATION_JOIN._FIELD_CANT_BE_MODIFIED`
										)}
										suffix={<Icon type="ri-checkbox-circle-fill" className="color-green-6" />}
									/>
								</Col>
							</Row>
						</>
					)}
				</Formik>
			</Modal>
		);
	}

	isVissible(key: string) {
		return isComponentVisible(this.props.pageComponents, key);
	}

	render() {
		const { t: translate } = this.props;
		const showGreetings: boolean = this.isVissible("greetingsCard");
		const showBanner: boolean = this.isVissible("banner");
		const showStatistics: boolean = this.isVissible("statistics");
		const showOverview: boolean = this.isVissible("overview");
		const showHomeworkOverview: boolean = this.isVissible("homeworkOverview");
		const showMyClasses: boolean = this.isVissible("myClasses");
		const showCalendar: boolean = this.isVissible("calendar");
		const statisticsProps = getComponentProps(this.props.pageComponents, "statistics");
		const sideLayoutProps = getComponentProps(this.props.pageComponents, "sideLayout");

		return (
			<MainLayout
				showMarketPlace={boolVal(sideLayoutProps, "showExploreCourses", true)}
				showAddCourse={boolVal(sideLayoutProps, "showAddCourse", true)}
			>
				<SectionTitle subtitle="Kreu" />
				<div className="dashboard__wrapper">
					<div className="dashboard__wrapper_content">
						<Row>
							{this.joinOrganizationFromInvitationModal()}
							<Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={19}>
								{showGreetings && <Row className="mb-24">
									<Col span={24}>
										<WelcomeBaner
											{...getComponentProps(this.props.pageComponents, "greetingsCard")}
										/>
									</Col>
								</Row>}
								{showBanner && <Row className="mt-24 mb-24">
									<Col span={24}>
										<MarketplaceBanner
											{...getComponentProps(this.props.pageComponents, "banner")}
										/>
									</Col>
								</Row>}
								{this.state.smallerOrMedium ? (
									<Row className="mb-16">
										<Col span={24}>
											{showCalendar && (
												<CalendarWithLessons
													{...getComponentProps(this.props.pageComponents, "calendar")}
												/>
											)}
										</Col>
									</Row>
								) : null}
								{this.state.large ? (
									<Row>
										<Col span={16} className="pr-24">
											<Row gutter={[24, 0]}>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
													<Skeleton
														active
														loading={this.state.loadingVirtualCoursesStatistics}
														paragraph={{
															rows: 1
														}}
													>
														{showStatistics && (
															<StatisticsCard
																rawTitle={!!statisticsProps.totalLiveCoursesTitle}
																title={
																	statisticsProps.totalLiveCoursesTitle
																		? statisticsProps.totalLiveCoursesTitle
																		: translate(
																				`${TRANSLATION_BASE_PATH}._TOTAL_VIRTUAL_COURSES`
																		  )
																}
																icon="ri-door-open-line"
																value={this.state.totalVirtualClassrooms}
															/>
														)}
													</Skeleton>
												</Col>
												<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
													<Skeleton
														active
														loading={this.state.loadingVirtualCoursesStatistics}
														paragraph={{
															rows: 1
														}}
													>
														{showStatistics && (
															<StatisticsCard
																rawTitle={!!statisticsProps.totalLiveCoursesTitle}
																title={
																	statisticsProps.totalRegisteredCoursesTitle
																		? statisticsProps.totalRegisteredCoursesTitle
																		: translate(
																				`${TRANSLATION_BASE_PATH}._TOTAL_ENROLLED_STUDENTS`
																		  )
																}
																icon="ri-user-line"
																value={this.state.totalEnrolledStudents}
															/>
														)}
													</Skeleton>
												</Col>
											</Row>
											<Row className="mt-24">
												<Col span={24}>
													{showOverview && (
														<LessonsAnalytics
															{...getComponentProps(
																this.props.pageComponents,
																"overview"
															)}
														/>
													)}
												</Col>
											</Row>
											<Row className="mt-24">
												<Col span={24}>
													{showHomeworkOverview && (
														<TeacherAssignmentsOverview
															{...getComponentProps(
																this.props.pageComponents,
																"homeworkOverview"
															)}
														/>
													)}
												</Col>
											</Row>
											<Row className="mt-24">
												<Col span={24}>
													<Skeleton active loading={this.state.loading}>
														{this.state.classItems && showMyClasses && (
															<ClassSlider
																{...getComponentProps(
																	this.props.pageComponents,
																	"myClasses"
																)}
																items={this.state.classItems}
																hideTeacherName={true}
															/>
														)}
													</Skeleton>
												</Col>
											</Row>
										</Col>
										<Col span={8}>
											{showCalendar && (
												<CalendarWithLessons
													{...getComponentProps(this.props.pageComponents, "calendar")}
												/>
											)}
										</Col>
									</Row>
								) : null}

								{this.state.smallerOrMedium || this.state.extraLarge ? (
									<>
										<Row gutter={[24, 16]}>
											<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
												<Skeleton
													active
													loading={this.state.loadingVirtualCoursesStatistics}
													paragraph={{
														rows: 1
													}}
												>
													{showStatistics && (
														<StatisticsCard
															rawTitle={!!statisticsProps.totalLiveCoursesTitle}
															title={
																statisticsProps.totalLiveCoursesTitle
																	? statisticsProps.totalLiveCoursesTitle
																	: translate(
																			`${TRANSLATION_BASE_PATH}._TOTAL_VIRTUAL_COURSES`
																	  )
															}
															icon="ri-door-open-line"
															value={this.state.totalVirtualClassrooms}
														/>
													)}
												</Skeleton>
											</Col>
											<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
												<Skeleton
													active
													loading={this.state.loadingVirtualCoursesStatistics}
													paragraph={{
														rows: 1
													}}
												>
													{showStatistics && (
														<StatisticsCard
															rawTitle={!!statisticsProps.totalLiveCoursesTitle}
															title={
																statisticsProps.totalRegisteredCoursesTitle
																	? statisticsProps.totalRegisteredCoursesTitle
																	: translate(
																			`${TRANSLATION_BASE_PATH}._TOTAL_ENROLLED_STUDENTS`
																	  )
															}
															icon="ri-user-line"
															value={this.state.totalEnrolledStudents}
														/>
													)}
												</Skeleton>
											</Col>
										</Row>
										<Row className="mt-8">
											<Col span={24}>
												{showOverview && (
													<LessonsAnalytics
														{...getComponentProps(this.props.pageComponents, "overview")}
													/>
												)}
											</Col>
										</Row>
										<Row className="mt-24">
											<Col span={24}>
												{showHomeworkOverview && (
													<TeacherAssignmentsOverview
														{...getComponentProps(
															this.props.pageComponents,
															"homeworkOverview"
														)}
													/>
												)}
											</Col>
										</Row>
										<Row className="mt-24">
											<Col span={24}>
												<Skeleton active loading={this.state.loading}>
													{this.state.classItems && showMyClasses && (
														<ClassSlider
															{...getComponentProps(
																this.props.pageComponents,
																"myClasses"
															)}
															items={this.state.classItems}
															hideTeacherName={true}
														/>
													)}
												</Skeleton>
											</Col>
										</Row>
									</>
								) : null}
							</Col>
							{this.state.extraLarge ? (
								<Col xs={0} sm={0} md={0} lg={0} xl={7} xxl={5} className="pl-24">
									{showCalendar && (
										<CalendarWithLessons
											{...getComponentProps(this.props.pageComponents, "calendar")}
										/>
									)}
								</Col>
							) : null}
						</Row>
					</div>
				</div>
			</MainLayout>
		);
	}
}

const TeacherDashboard = withTranslation()(TeacherDashboardComponent);
export default TeacherDashboard;
