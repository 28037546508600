import { v4 as uuidv4 } from "uuid";
import ClassroomDto from "../../administration/classroom.dto";

export default class UserDto {
	readonly id!: string;
	readonly email!: string;
	readonly parentEmail!: string;
	readonly parentInvitationId!: string;
	readonly username!: string;
	readonly password!: string;
	readonly firstName!: string;
	readonly lastName!: string;
	readonly dateOfBirth!: Date;
	readonly roleCode!: string;
	readonly OrganizationId!: string;
	readonly Classrooms?: ClassroomDto[];
	readonly Roles?: any[];

	constructor(data: {
		email: string;
		parentEmail: string;
		parentInvitationId: string;
		username: string;
		password: string;
		firstName: string;
		lastName: string;
		dateOfBirth: Date;
		Classrooms?: ClassroomDto[];
		[key: string]: any;
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
