import { Button, Col, Empty, Input, Row, Skeleton, Space, Tabs } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { SectionContent } from "../../components/sectionContent/SectionContent";
import { CircularIcon } from "common/components/general/CircularIcon";
import Text from "common/components/general/Text";
import TabPane from "common/components/dataDisplay/TabPane";
import AlFlag from "assets/images/icons/Al.svg";
import KsFlag from "assets/images/icons/Ks.svg";
import Image from "common/components/general/Image";
import AnalyticsUserService from "services/analytics/user.service";
import AnalyticsOrganizationService from "services/analytics/organization.service";
import AnalyticsCoursesService from "services/analytics/course.service";
import PieCard from "./components/pieCard/PieCard";
import CounterCard from "common/components/_counterCard/CounterCard";
import { formatTotal } from "../../util/formatTotal";
import { ADMIN_PATH } from "../..";
import { ORGANIZATIONS_PATH } from "../organizations/scenes/list";
import { AdminTable } from "../../components/table/table";
import { sumTotal } from "../../util/sumTotal";
import { TableRowNavLink } from "../../components/tableRowNavLink/TableRowNavLink";
import { COURSE_BUILDER_PATH } from "../../../courseBuilder/scenes/courseDetails/scenes/SubjectPlanCreator/SubjectPlanCreator";
import { InstitutionCode } from "../../components/institutionCode/InstitutionCode";
import OAuth2Service from "services/authentication/oauth2.service";
import { TrimmedText } from "../../components/common/TrimmedText";

export const DASHBOARD_PATH = "dashboard";

const TRANSLATION_BASE_PATH = "_ADMIN._MAIN";

class MainComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			activeTabKey: 0,

			chartTotalUsers: {
				data: null,
				loading: false
			},
			chartStudentsByLocation: {
				data: null,
				loading: false
			},
			chartTeachersByLocation: {
				data: null,
				loading: false
			},

			tableOrganizationsTotalsByCountry: {
				loading: false,
				data: [0, 0]
			},
			cardTotalInstitutions: null,
			cardTotalInstitutionsByCountry: [0, 0],
			cardTotalTeachersByCountry: [0, 0],
			cardTotalVirtualClassesByCountry: [0, 0],
			cardTotalStudentsByCountry: [0, 0],

			tableCoursesTotalsByCountry: {
				loading: false,
				data: [0, 0]
			},
			cardTotalCoursesByCountry: [0, 0],
			cardTotalVideos: [0, 0],
			cardTotalVideosLoading: false,
			cardTotalQuiz: [0, 0],
			cardTotalQuizLoading: false,

			searchOrgText: "",
			searchedOrgColumn: "",

			searchClassText: "",
			searchedClassColumn: ""
		};
	}

	componentDidMount() {
		this.loadPieChartTotalUsers();
		this.loadPieChartStudentsByLocation();
		this.loadPieChartTeachersByLocation();

		OAuth2Service.isOwnerOrAdminAtSuperOrganization &&
			this.loadTableOrganizationsTotalsByCountry()
				.then(() => {
					return this.calcOrganizationsCardsTotals();
				})
				.catch(err => {
					throw err;
				});

		this.loadTableCoursesTotalsByCountry()
			.then(() => {
				return this.calcCoursesCardsTotals();
			})
			.catch(err => {
				throw err;
			});

		this.getTotalVideos();
		this.getTotalQuiz();
	}

	getTotalVideos = async () => {
		this.setState({
			cardTotalVideosLoading: true
		});
		return new AnalyticsCoursesService()
			.getTotalVideos()
			.then((result: any) => {
				return this.setState({
					cardTotalVideos: [result[0].total, 0]
				});
			})
			.finally(() => {
				this.setState({
					cardTotalVideosLoading: false
				});
			});
	};

	getTotalQuiz = async () => {
		this.setState({
			cardTotalQuizLoading: true
		});
		return new AnalyticsCoursesService()
			.getTotalQuiz()
			.then((result: any) => {
				return this.setState({
					cardTotalQuiz: [result[0].total, 0]
				});
			})
			.finally(() => {
				this.setState({
					cardTotalQuizLoading: false
				});
			});
	};

	calcOrganizationsCardsTotals() {
		const totalInstitutionsAlbania = this.state.tableOrganizationsTotalsByCountry?.data[0].length;
		const totalInstitutionsKosovo = this.state.tableOrganizationsTotalsByCountry?.data[1].length;
		const totalInstitutions = totalInstitutionsAlbania + totalInstitutionsKosovo;
		const totalTeachersAlbania = sumTotal(this.state.tableOrganizationsTotalsByCountry?.data[0], "teacherCount");
		const totalTeachersKosovo = sumTotal(this.state.tableOrganizationsTotalsByCountry?.data[1], "teacherCount");
		const totalVirtualClassesAlbania = sumTotal(
			this.state.tableOrganizationsTotalsByCountry?.data[0],
			"classCount"
		);
		const totalVirtualClassesKosovo = sumTotal(this.state.tableOrganizationsTotalsByCountry?.data[1], "classCount");
		const totalStudentsAlbania = sumTotal(this.state.tableOrganizationsTotalsByCountry?.data[0], "studentCount");
		const totalStudentsKosovo = sumTotal(this.state.tableOrganizationsTotalsByCountry?.data[1], "studentCount");
		this.setState({
			cardTotalInstitutions: totalInstitutions,
			cardTotalInstitutionsByCountry: [totalInstitutionsAlbania, totalInstitutionsKosovo],
			cardTotalTeachersByCountry: [totalTeachersAlbania, totalTeachersKosovo],
			cardTotalVirtualClassesByCountry: [totalVirtualClassesAlbania, totalVirtualClassesKosovo],
			cardTotalStudentsByCountry: [totalStudentsAlbania, totalStudentsKosovo]
		});
	}

	calcCoursesCardsTotals() {
		const totalCoursesAlbania = this.state.tableCoursesTotalsByCountry?.data[0].length;
		const totalCoursesKosovo = this.state.tableCoursesTotalsByCountry?.data[1].length;
		const totalSubjectsAlbania = _.uniq(
			this.state.tableCoursesTotalsByCountry?.data[0].map((item: any) => item.subjectName)
		).length;
		const totalSubjectsKosovo = _.uniq(
			this.state.tableCoursesTotalsByCountry?.data[1].map((item: any) => item.subjectName)
		).length;
		const totalSeenAlbania = sumTotal(this.state.tableCoursesTotalsByCountry?.data[0], "countSeen");
		const totalSeenKosovo = sumTotal(this.state.tableCoursesTotalsByCountry?.data[1], "countSeen");
		const totalCompletedAlbania = sumTotal(this.state.tableCoursesTotalsByCountry?.data[0], "countCompleted");
		const totalCompletedKosovo = sumTotal(this.state.tableCoursesTotalsByCountry?.data[1], "countCompleted");
		this.setState({
			cardTotalCoursesByCountry: [totalCoursesAlbania, totalCoursesKosovo],
			cardTotalSubjectsByCountry: [totalSubjectsAlbania, totalSubjectsKosovo],
			cardTotalSeenByCountry: [totalSeenAlbania, totalSeenKosovo],
			cardTotalCompletedByCountry: [totalCompletedAlbania, totalCompletedKosovo]
		});
	}

	getTabContent(index: number) {
		const { t: translate } = this.props;
		const cardTitleOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._ORGANIZATIONS`);
		const cardTitleTeachers = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._TEACHERS`);
		const cardTitleClasses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._CLASSES`);
		const cardTitleStudents = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._STUDENTS`);
		const cardTitleCourses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._COURSES`);
		const noDataLabelTableCourses = translate(`${TRANSLATION_BASE_PATH}._TABLE._COURSES._NO_DATA`);
		const noDataLabelTableOrganizations = translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._NO_DATA`);
		const cardTitleVideoTotal = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._VIDEO`);
		const cardTitleQuizTotal = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._QUIZ`);

		return (
			<>
				<Row justify="space-between">
					{OAuth2Service.isOwnerOrAdminAtSuperOrganization && (
						<Col xs={24} sm={24} md={11} lg={11} xl={5} xxl={5}>
							<CounterCard
								title={cardTitleOrganizations}
								icon="ri-door-open-line"
								data={this.state.cardTotalInstitutionsByCountry[index]}
								loading={this.state.tableOrganizationsTotalsByCountry?.loading}
							/>
						</Col>
					)}
					<Col xs={24} sm={24} md={11} lg={11} xl={5} xxl={5}>
						<CounterCard
							title={cardTitleTeachers}
							icon="ri-user-2-line"
							data={this.state.cardTotalTeachersByCountry[index]}
							loading={this.state.tableOrganizationsTotalsByCountry?.loading}
						/>
					</Col>
					<Col
						xs={24}
						sm={24}
						md={11}
						lg={11}
						xl={OAuth2Service.isOwnerOrAdminAtSuperOrganization ? 5 : 7}
						xxl={OAuth2Service.isOwnerOrAdminAtSuperOrganization ? 5 : 7}
					>
						<CounterCard
							title={cardTitleClasses}
							icon="ri-door-open-line"
							data={this.state.cardTotalVirtualClassesByCountry[index]}
							loading={this.state.tableOrganizationsTotalsByCountry?.loading}
						/>
					</Col>
					<Col
						xs={24}
						sm={24}
						md={11}
						lg={11}
						xl={OAuth2Service.isOwnerOrAdminAtSuperOrganization ? 5 : 7}
						xxl={OAuth2Service.isOwnerOrAdminAtSuperOrganization ? 5 : 7}
					>
						<CounterCard
							title={cardTitleStudents}
							icon="ri-user-line"
							data={this.state.cardTotalStudentsByCountry[index]}
							loading={this.state.tableOrganizationsTotalsByCountry?.loading}
						/>
					</Col>
				</Row>
				{OAuth2Service.isOwnerOrAdminAtSuperOrganization && (
					<Row>
						<Skeleton loading={this.state.tableOrganizationsTotalsByCountry?.loading}>
							<Col span="24">
								<AdminTable
									columns={this.columnsTableOrganizations}
									dataSource={this.state.tableOrganizationsTotalsByCountry?.data[index]}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={noDataLabelTableOrganizations}
											/>
										)
									}}
									defaultPageSize={5}
								/>
							</Col>
						</Skeleton>
					</Row>
				)}
				<Row justify="space-between">
					<Col xs={24} sm={24} md={11} lg={11} xl={5} xxl={5}>
						<CounterCard
							title={cardTitleCourses}
							icon="ri-slideshow-3-line"
							data={this.state.cardTotalCoursesByCountry[index]}
							loading={this.state.tableCoursesTotalsByCountry?.loading}
						/>
					</Col>
					<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
						<CounterCard
							title={cardTitleVideoTotal}
							icon="ri-play-mini-fill"
							data={this.state.cardTotalVideos[index]}
							loading={this.state.cardTotalVideosLoading}
						/>
					</Col>
					<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
						<CounterCard
							title={cardTitleQuizTotal}
							icon="ri-question-fill"
							data={this.state.cardTotalQuiz[index]}
							loading={this.state.cardTotalQuizLoading}
						/>
					</Col>
				</Row>
				<Row>
					<Skeleton loading={this.state.tableCoursesTotalsByCountry?.loading}>
						<Col span="24">
							<AdminTable
								columns={this.columnsTableCourses}
								dataSource={this.state.tableCoursesTotalsByCountry?.data[index]}
								locale={{
									emptyText: (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={noDataLabelTableCourses}
										/>
									)
								}}
								defaultPageSize={5}
							/>
						</Col>
					</Skeleton>
				</Row>
			</>
		);
	}

	loadPieChartTotalUsers() {
		this.setState({
			chartTotalUsers: {
				loading: true
			}
		});
		return new AnalyticsUserService()
			.countAllByType()
			.then((data: any) => {
				return this.setState({
					chartTotalUsers: {
						data: data,
						loading: false
					}
				});
			})
			.finally(() => {
				this.setState({
					chartTotalUsers: {
						...this.state.chartTotalUsers,
						loading: false
					}
				});
			});
	}

	loadPieChartStudentsByLocation() {
		this.setState({
			chartStudentsByLocation: {
				loading: true
			}
		});
		return new AnalyticsUserService()
			.countStudentsByLocation()
			.then((data: any) => {
				return this.setState({
					chartStudentsByLocation: {
						data: data,
						loading: false
					}
				});
			})
			.finally(() => {
				this.setState({
					chartStudentsByLocation: {
						...this.state.chartStudentsByLocation,
						loading: false
					}
				});
			});
	}

	loadPieChartTeachersByLocation() {
		this.setState({
			chartTeachersByLocation: {
				loading: true
			}
		});
		return new AnalyticsUserService()
			.countTeachersByLocation()
			.then((data: any) => {
				return this.setState({
					chartTeachersByLocation: {
						data: data,
						loading: false
					}
				});
			})
			.finally(() => {
				this.setState({
					chartTeachersByLocation: {
						...this.state.chartTeachersByLocation,
						loading: false
					}
				});
			});
	}

	loadTableOrganizationsTotalsByCountry() {
		this.setState({
			tableOrganizationsTotalsByCountry: {
				...this.state.tableOrganizationsTotalsByCountry,
				loading: true
			}
		});
		return new AnalyticsOrganizationService()
			.getOrganizationsTotals()
			.then((data: any) => {
				const albaniaData = _.orderBy(
					data.filter((x: any) => {
						return x.nationality === "ALBANIA";
					}),
					"createdAt",
					"desc"
				);
				const kosovoData = _.orderBy(
					data.filter((x: any) => {
						return x.nationality === "KOSOVO";
					}),
					"createdAt",
					"desc"
				);
				return this.setState({
					tableOrganizationsTotalsByCountry: {
						data: [albaniaData, kosovoData]
					}
				});
			})
			.finally(() => {
				this.setState({
					tableOrganizationsTotalsByCountry: {
						...this.state.tableOrganizationsTotalsByCountry,
						loading: false
					}
				});
			});
	}

	loadTableCoursesTotalsByCountry() {
		this.setState({
			tableCoursesTotalsByCountry: {
				...this.state.tableCoursesTotalsByCountry,
				loading: true
			}
		});
		return new AnalyticsCoursesService()
			.getCoursesTotals()
			.then((data: any) => {
				const albaniaData = _.orderBy(
					data.filter((x: any) => {
						return x.nationality === "ALBANIA";
					}),
					"createdAt",
					"desc"
				);
				const kosovoData = _.orderBy(
					data.filter((x: any) => {
						return x.nationality === "KOSOVO";
					}),
					"createdAt",
					"desc"
				);
				return this.setState({
					tableCoursesTotalsByCountry: {
						data: [albaniaData, kosovoData]
					}
				});
			})
			.finally(() => {
				this.setState({
					tableCoursesTotalsByCountry: {
						...this.state.tableCoursesTotalsByCountry,
						loading: false
					}
				});
			});
	}

	get columnsTableCourses() {
		const { t: translate } = this.props;
		const btnSearch = translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._FILTER_SEARCH`);
		const btnClear = translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._FILTER_CLEAR`);

		const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
			confirm();
			this.setState({
				searchClassText: selectedKeys[0],
				searchedClassColumn: dataIndex
			});
		};

		const handleReset = (clearFilters: any) => {
			clearFilters();
			this.setState({ searchClassText: "" });
		};

		let searchInput: any = null;

		const getColumnSearchProps = (dataIndex: any) => ({
			filterDropdown: (action: any) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={node => {
							searchInput = node;
						}}
						placeholder={`${btnSearch}`}
						value={action.selectedKeys[0]}
						onChange={e => action.setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(action.selectedKeys, action.confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(action.selectedKeys, action.confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							{btnSearch}
						</Button>
						<Button onClick={() => handleReset(action.clearFilters)} size="small" style={{ width: 90 }}>
							{btnClear}
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			onFilter: (value: any, record: any) =>
				record[dataIndex]
					? record[dataIndex]
							.toString()
							.toLowerCase()
							.includes(value.toLowerCase())
					: "",
			onFilterDropdownVisibleChange: (visible: any) => {
				if (visible) {
					setTimeout(() => searchInput.select(), 100);
				}
			},
			render: (text: any) =>
				this.state.searchedClassColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[this.state.searchClassText]}
						autoEscape
						textToHighlight={text ? text.toString() : ""}
					/>
				) : (
					text
				)
		});

		return [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COURSES._COLUMNS._COURSE`),
				dataIndex: "className",
				key: "className",
				...getColumnSearchProps("className"),
				render: (text: any, row: any) => {
					return (
						<Row align="middle">
							<Space>
								<Col>
									<CircularIcon type="ri-slideshow-3-line" />
								</Col>

								<Col>
									<Row>{text}</Row>
									<Row>
										<TrimmedText fontSize="12" lineHeight="20" className="color-gray-7">
											{row.classDescription}
										</TrimmedText>
									</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COURSES._COLUMNS._SUBJECT`),
				dataIndex: "subjectName",
				key: "subjectName",
				render: (text: any, row: any) => {
					return (
						<>
							<Row>
								{text} {row.gradeName.split(" ").length > 1 && row.gradeName.split(" ")[1]}
							</Row>
							<Row>
								<Text fontSize="12" lineHeight="20" className="color-gray-7">
									{translate(
										`${TRANSLATION_BASE_PATH}._TABLE._COURSES._GRADE_LEVELS._${row.gradeLevel}`
									)}
								</Text>
							</Row>
						</>
					);
				},
				responsive: ["sm"]
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COURSES._COLUMNS._SEEN`),
				dataIndex: "countSeen",
				key: "countSeen",
				align: "right",
				sorter: (a: any, b: any) => a.countSeen - b.countSeen,
				render: (text: any) => {
					return formatTotal(text);
				},
				responsive: ["md"]
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COURSES._COLUMNS._COMPLETED`),
				dataIndex: "countCompleted",
				key: "countCompleted",
				align: "right",
				sorter: (a: any, b: any) => a.countCompleted - b.countCompleted,
				render: (text: any) => {
					return formatTotal(text);
				},
				responsive: ["lg"]
			},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				align: "center",
				render: (id: any, row: any) => {
					const to = `/${COURSE_BUILDER_PATH}/asynch/${row.id}`;
					return <TableRowNavLink to={to} />;
				}
			}
		];
	}

	get columnsTableOrganizations() {
		const { t: translate } = this.props;
		const btnSearch = translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._FILTER_SEARCH`);
		const btnClear = translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._FILTER_CLEAR`);

		const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
			confirm();
			this.setState({
				searchOrgText: selectedKeys[0],
				searchedOrgColumn: dataIndex
			});
		};

		const handleReset = (clearFilters: any) => {
			clearFilters();
			this.setState({ searchOrgText: "" });
		};

		let searchInput: any = null;

		const getColumnSearchProps = (dataIndex: any) => ({
			filterDropdown: (action: any) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={node => {
							searchInput = node;
						}}
						placeholder={`${btnSearch}`}
						value={action.selectedKeys[0]}
						onChange={e => action.setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(action.selectedKeys, action.confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(action.selectedKeys, action.confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							{btnSearch}
						</Button>
						<Button onClick={() => handleReset(action.clearFilters)} size="small" style={{ width: 90 }}>
							{btnClear}
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			onFilter: (value: any, record: any) =>
				record[dataIndex]
					? record[dataIndex]
							.toString()
							.toLowerCase()
							.includes(value.toLowerCase())
					: "",
			onFilterDropdownVisibleChange: (visible: any) => {
				if (visible) {
					setTimeout(() => searchInput.select(), 100);
				}
			},
			render: (text: any) =>
				this.state.searchedOrgColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[this.state.searchOrgText]}
						autoEscape
						textToHighlight={text ? text.toString() : ""}
					/>
				) : (
					text
				)
		});

		return [
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._COLUMNS._ORGANIZATION`),
				dataIndex: "name",
				key: "name",
				...getColumnSearchProps("name"),
				render: (text: any, row: any) => {
					return (
						<Row align="middle">
							<Space>
								<Col>
									<CircularIcon type="ri-building-4-line" />
								</Col>
								<Col>
									<Row>{text}</Row>
									<Row>
										<InstitutionCode code={row.code} />
									</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._COLUMNS._TEACHER`),
				dataIndex: "teacherCount",
				key: "teacherCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.teacherCount - b.teacherCount,
				responsive: ["sm"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._COLUMNS._CLASS`),
				dataIndex: "classCount",
				key: "classCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.classCount - b.classCount,
				responsive: ["md"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._ORGANIZATIONS._COLUMNS._STUDENT`),
				dataIndex: "studentCount",
				key: "studentCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.studentCount - b.studentCount,
				responsive: ["lg"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				align: "center",
				render: (id: any) => {
					const to = `/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${id}`;
					return <TableRowNavLink to={to} />;
				}
			}
		];
	}

	render() {
		const { t: translate } = this.props;

		const title = translate("_MAIN._LEFT_SIDE_MENU._ITEM_1");
		const users = translate(`${TRANSLATION_BASE_PATH}._USERS`);
		const students = translate(`${TRANSLATION_BASE_PATH}._STUDENTS`);
		const teachers = translate(`${TRANSLATION_BASE_PATH}._TEACHERS`);
		const albania = translate(`${TRANSLATION_BASE_PATH}._ALBANIA`);
		const kosovo = translate(`${TRANSLATION_BASE_PATH}._KOSOVO`);
		const totalInstEdu = translate(`${TRANSLATION_BASE_PATH}._TOTAL_INST_EDU`);

		return (
			<>
				<SectionTitle title={title} subtitleUrl={[]} />
				<SectionContent>
					<Row justify="space-between">
						<Col xs={24} sm={24} md={24} lg={11} xl={7} xxl={7}>
							<PieCard
								title={users}
								data={this.state.chartTotalUsers?.data}
								loading={this.state.chartTotalUsers?.loading}
								labelId="chartTotalsLabels"
							/>
						</Col>
						<Col xs={24} sm={24} md={24} lg={11} xl={7} xxl={7}>
							<PieCard
								title={students}
								data={this.state.chartStudentsByLocation?.data}
								loading={this.state.chartStudentsByLocation?.loading}
								labelId="chartStudentTotalsLabels"
							/>
						</Col>
						<Col xs={24} sm={24} md={24} lg={11} xl={7} xxl={7}>
							<PieCard
								title={teachers}
								data={this.state.chartTeachersByLocation?.data}
								loading={this.state.chartTeachersByLocation?.loading}
								labelId="chartTeacherTotalsLabels"
							/>
						</Col>
					</Row>
					{OAuth2Service.isOwnerOrAdminAtSuperOrganization && (
						<Row align="middle">
							<Col span="24">
								<CounterCard
									title={totalInstEdu}
									icon="ri-door-open-line"
									loading={this.state.tableOrganizationsTotalsByCountry?.loading}
									data={this.state.cardTotalInstitutions}
									allRow
								/>
							</Col>
						</Row>
					)}
					<Row>
						<Col span="24">
							<Tabs defaultActiveKey="0" onChange={value => this.setState({ activeTabKey: value })}>
								<TabPane
									tab={
										<Space>
											<Image src={AlFlag} />
											{albania}
										</Space>
									}
									key="0"
								>
									{this.getTabContent(0)}
								</TabPane>
								<TabPane
									tab={
										<Space>
											<Image src={KsFlag} />
											{kosovo}
										</Space>
									}
									key="1"
								>
									{this.getTabContent(1)}
								</TabPane>
							</Tabs>
						</Col>
					</Row>
				</SectionContent>
			</>
		);
	}
}

const Main = withTranslation()(MainComponent);
export default Main;
