import React, { useEffect, useState } from "react";
import ls from "localstorage-slim";
import {
	PublicSettingsResponse,
	TemplatePublicDataComponent
} from "../../../scenes/admin/scenes/organizations/scenes/customize/utils/types";
import { Skeleton } from "antd";
import { MainLayout } from "scenes/main/components/layout/Layout";
import { useStoreState } from "../../../store/hooks";

interface PropsInterface {
	Pages: {
		[key: string]: any;
	};
	DefaultPage: any;
	templateName: string;
}

/**
 * Proxy component to load the selected page or fallback to the default one
 * @param Pages List of available pages
 * @param DefaultPage Default page if none
 * @param templateName Template name where to find the pages
 * @param rest
 * @constructor
 */
export default function DynamicLayout({ Pages, DefaultPage, templateName, ...rest }: PropsInterface) {
	const [index, setIndex] = useState("-1");
	const [pageComponents, setPageComponents] = useState<TemplatePublicDataComponent[]>([]);
	const storeLoading = useStoreState((state => state.designSettings.loading));

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let res;
		if (!ls.get("design-settings")) {
			res = {};
		} else {
			const designSettings = ls.get("design-settings") as PublicSettingsResponse;
			res = designSettings?.pages?.[templateName] ?? {};
		}


		if (res?.deactivated) {
			window.location.href = window.location.origin;
			return;
		}
		if (res.pageComponent) {
			setIndex(res.pageComponent);
			setPageComponents(res.components as TemplatePublicDataComponent[]);
		}
		return setLoading(false);
	}, [storeLoading]);


	const MatchedComponent = Pages[index] || DefaultPage;
	const params = {...rest, pageComponents};
	const _loading = storeLoading || loading;

	return _loading ? (
		<MainLayout loading={_loading}><></></MainLayout>
	) : (
		<Skeleton loading={_loading}>
			<MatchedComponent {...params} />
		</Skeleton>
	);
}
