import axios from "axios";
import ApiServiceData from "../api.service";
import SubjectDto from "../../dtos/administration/subject.dto";
const relativeUrl = "/v1/api/subjects";

export default class SubjectService extends ApiServiceData {
	public getAll(organizationId: string): Promise<object> {
		return this.publicGet(`${relativeUrl}?organization=${organizationId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllWithAyncCourses(): Promise<object> {
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;

		return axios
			.get(`${API_URL}${relativeUrl}/with-async-web`, {
				headers: {
					BrowserBaseURL: window.location.protocol + "//" + window.location.host
				}
			})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllWithAyncCoursesBySyncDate(lastSyncDate: string): Promise<object> {
		const API_URL = process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host;

		return axios
			.get(`${API_URL}${relativeUrl}/subjects-with-async/${lastSyncDate}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getById(id: string): Promise<SubjectDto> {
		return this.get(`${relativeUrl}/find/${id}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createSubject(subject: SubjectDto) {
		return this.post(relativeUrl, subject);
	}

	public updateSubject(subjectId: string, subject: SubjectDto) {
		return this.update(relativeUrl + "/" + subjectId, subject);
	}

	public getAllForAdmin(): Promise<object> {
		return this.get(`${relativeUrl}/forAdmin`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllForAdminLevels(): Promise<object> {
		return this.get(`${relativeUrl}/forAdminLevels`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public hasLinkedClassrooms(subjectId: string): Promise<boolean> {
		return this.get(`${relativeUrl}/has-classrooms/${subjectId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public removeSubject(subjectId: string): Promise<number> {
		return this.delete(`${relativeUrl}/${subjectId}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
