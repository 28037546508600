import { EventEmitter } from "events";

export enum REFRESH_TOKEN_EVENTS {
	Unblocked = "refreshToken.unblocked",
	Blocked = "refreshToken.blocked",
}

const inMemoryJWTManager = () => {
		
	let isRefreshing = false;
	const refreshTokenEmitter = new EventEmitter();

	const getIsRefreshing = (): boolean => isRefreshing;

	const setIsRefreshing = (status: boolean) => {
		isRefreshing = status;
	};

	const getRefreshTokenEmitter = (): EventEmitter => refreshTokenEmitter;

	return {
		getIsRefreshing,
		setIsRefreshing,
		getRefreshTokenEmitter
	};
};

export default inMemoryJWTManager();