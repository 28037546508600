import { Avatar, Popover, Space } from "antd";
import React from "react";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";

export const ArchivedStatusAvatar = () => {
	const { t: translate } = useTranslation();

	return (
		<Popover
			title={translate(`${TRANSLATION_BASE_PATH}._ARCHIVED_STATUS_TITLE`)}
			content={
				<Space direction="vertical" size={2}>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._ARCHIVED_STATUS_DESCRIPTION`)}
					</Text>
					<Text
						fontSize="14"
						lineHeight="22"
						className="color-blue-6 cursor_pointer"
						onClick={() => {
							console.log("Learn more archived classroom");
						}}
					>
						{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
					</Text>
				</Space>
			}
		>
			<Avatar
				icon={<Icon type="ri-inbox-archive-line" fontSize="16" className="color-gray-6" />}
				shape="circle"
				size={24}
				className="archived__avatar"
			/>
		</Popover>
	);
};
