import { Col, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Radar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Image from "common/components/general/Image";
import { AllCoursesPerformanceModal } from "./allCoursesPerformanceModal";
import AnalyticsCoursesService from "services/analytics/course.service";
import { CourseProgress } from "dtos/analytics/courseProgress.dto";
import DashboardCoursesPerformanceIcon from "assets/images/dashboard-courses-performance.svg";
import { SUBJECTS_PATH } from "scenes/subjects";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._COURSES_PERFORMANCE";
const BASE_SCORE = 5.0;

interface CoursesPerformanceProps {
	title?: string;
}

export const CoursesPerformance = (props: CoursesPerformanceProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const [loadingData, setLoadingData] = useState<boolean>(true);
	const [coursesData, setCoursesData] = useState<any[]>([]);
	const [modalVisible, setModalVisible] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = () => {
			return new AnalyticsCoursesService()
				.getAsyncCoursesProgress()
				.then((result: CourseProgress[]) => {
					if (result.length > 0) {
						const calculatedCourses = result
							.map((item: CourseProgress) => {
								let score = 0;
								if (item.lessons > 0) {
									score = item.finishedItems / item.lessons;
								}
								return {
									...item,
									score: score
								};
							})
							.sort((first: any, second: any) => (first.score >= second.score ? -1 : 1));
						const maxScore = calculatedCourses[0].score;
						const coursesWithConvertedScore = calculatedCourses
							.map((item: any) => {
								let calculatedScore = 0;
								if (maxScore > 0) {
									calculatedScore =
										item.score === maxScore ? BASE_SCORE : (BASE_SCORE * item.score) / maxScore;
								}
								return {
									...item,
									calculatedScore: calculatedScore
								};
							})
							.filter(item => item.calculatedScore > 0)
							.sort((first: any, second: any) =>
								first.calculatedScore >= second.calculatedScore ? -1 : 1
							);
						setCoursesData(coursesWithConvertedScore);
					}
					return;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					setLoadingData(false);
				});
		};
		fetchData();
	}, []);

	const emptyState = () => {
		return (
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{props.title ? props.title : translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Col>
				<Col span={24}>
					<Space direction="horizontal" size={24} className="courses__performance_empty_state_space">
						<Image src={DashboardCoursesPerformanceIcon} />
						<Space direction="vertical" size={24}>
							<Space direction="vertical" size={0}>
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION`)}
								</Text>
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION_EXTRA_INFO`)}
								</Text>
							</Space>
							<Button
								type="primary"
								icon="ri-arrow-right-line"
								iconPosition="right"
								onClick={() => {
									history.push(`/${SUBJECTS_PATH}`);
								}}
							>
								{translate(`${TRANSLATION_BASE_PATH}._VIEW_COURSES`)}
							</Button>
						</Space>
					</Space>
				</Col>
			</Row>
		);
	};

	return (
		<div className="dashboard__courses_performance">
			<Skeleton active loading={loadingData}>
				{coursesData.length > 3 ? (
					<Row gutter={[24, 0]}>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="flex__center">
							<Space direction="vertical" size={24} className="section__title_wrapper">
								<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
									{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
								</Text>
								<Button
									type="ghost"
									onClick={() => {
										setModalVisible(true);
									}}
								>
									{translate(`${TRANSLATION_BASE_PATH}._ALL_COURSES_BUTTON_LABEL`, {
										count: coursesData.length
									})}
								</Button>
							</Space>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<div className="courses__radar_chart">
								<Radar
									data={{
										labels: coursesData
											.slice(0, 8)
											.map(item => item.name + ` (${item.calculatedScore.toFixed(1)})`),
										datasets: [
											{
												data: coursesData
													.slice(0, 8)
													.map(item => item.calculatedScore.toFixed(1)),
												backgroundColor: "rgba(32, 99, 227, 0.2)",
												borderColor: "#2063e3",
												borderWidth: 1.5
											}
										]
									}}
									options={{
										legend: {
											display: false
										},
										scale: {
											reverse: false,
											gridLines: {
												color: [
													"rgba(32, 99, 227, 0.3)",
													"rgba(32, 99, 227, 0.3)",
													"rgba(32, 99, 227, 0.3)",
													"rgba(32, 99, 227, 0.3)",
													"rgba(32, 99, 227, 0.3)"
												]
											},
											ticks: {
												beginAtZero: true
											}
										}
									}}
								/>
							</div>
						</Col>
						<Col span={24}>
							<AllCoursesPerformanceModal
								items={coursesData}
								visible={modalVisible}
								onClose={() => {
									setModalVisible(false);
								}}
							/>
						</Col>
					</Row>
				) : (
					emptyState()
				)}
			</Skeleton>
		</div>
	);
};
