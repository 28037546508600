import ApiServiceData from "../api.service";
import {getLoadBalancerEnvSpec, LoadBalancerEnv, LoadBalancerEnvSpec} from "services/s3/utils";
const relativeUrl = "/v1/api/aws-elbv2";

export default class ELBV2Service extends ApiServiceData {
	public getCertificates(environment: LoadBalancerEnv): Promise<string> {
		const environmentVar: LoadBalancerEnvSpec = getLoadBalancerEnvSpec(environment);
		return this.post(`${relativeUrl}`, {loadBalancerArn: environmentVar.arn})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public addCertificateToListener(certificateArn: string, environment: LoadBalancerEnv): Promise<any> {
		const environmentVar: LoadBalancerEnvSpec = getLoadBalancerEnvSpec(environment);
		return this.post(`${relativeUrl}/addCertificationToListener`, {loadBalancerArn: environmentVar.arn, certificateArn: certificateArn})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
