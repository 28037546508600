import React from "react";
import { InputProps } from "../../utils/types";
import { Checkbox } from "antd";
import { useFormikContext } from "formik";

export function Bool(props: InputProps) {
	const { setFieldValue } = useFormikContext();
	const val = !!parseInt(props.value);

	return (
		<Checkbox
			defaultChecked={val}
			name={props.name}
			onChange={(param) => setFieldValue(props.name, param.target.checked ? "1" : "0")}
		>
			{props.title}
		</Checkbox>
	);
}
