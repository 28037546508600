import React, { useState, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import Alert from "common/components/feedback/Alert";
import { formValidator } from "./ValidationSchema";
import Button from "common/components/general/Button";
import { showMessage } from "common/utils/Notification";
import UserService from "services/authentication/user.service";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._CHANGE_PASSWORD";

export const ChangePasswordForm = () => {
	const { t: translate } = useTranslation();
	const [hasError, setHasError] = useState<{ error: boolean; errorMessage: string }>({
		error: false,
		errorMessage: ""
	});
	const [isFormValid, setIsFormValid] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const initialValues = {
		oldPassword: undefined,
		newPassword: undefined,
		repeatPassword: undefined
	};

	const showAlertContent = (): ReactNode => {
		return hasError.error ? (
			<Row className="mb-24">
				<Col span={24}>
					<Alert message={hasError.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	const onSubmit = async (values: any, { resetForm }: any) => {
		setSubmitting(true);
		setHasError({
			error: false,
			errorMessage: ""
		});
		await new UserService()
			.changePassword({
				oldPassword: values.oldPassword,
				newPassword: values.newPassword
			})
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._PASSWORD_CHANGED`));
				resetForm(initialValues);
				return setIsFormValid(false);
			})
			.catch(error => {
				const errorStatus = error?.status ?? "500";
				return setHasError({
					error: true,
					errorMessage: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<Formik
			enableReinitialize={true}
			validationSchema={formValidator(translate)}
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={values => {
				setIsFormValid(formValidator(translate).isValidSync(values));
				return {};
			}}
		>
			{formik => (
				<form onSubmit={formik.handleSubmit} autoComplete="off">
					{showAlertContent()}
					<Row>
						<Col span={24}>
							<PasswordInput
								name="oldPassword"
								label={translate(`${TRANSLATION_BASE_PATH}._OLD_PASSWORD`)}
								showPopover={false}
								autoComplete="password"
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<PasswordInput
								name="newPassword"
								label={translate(`${TRANSLATION_BASE_PATH}._NEW_PASSWORD`)}
								showPopover={true}
								autoComplete="new-password"
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<PasswordInput
								name="repeatPassword"
								label={translate(`${TRANSLATION_BASE_PATH}._REPEAT_PASSWORD`)}
								autoComplete="new-password"
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Button
								type="primary"
								className="full__width"
								loading={submitting}
								disabled={!isFormValid}
								htmlType="submit"
							>
								{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
							</Button>
						</Col>
					</Row>
				</form>
			)}
		</Formik>
	);
};
