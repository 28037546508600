import ApiServiceData from "../api.service";
import TagDto from "../../dtos/administration/tag.dto";
import { JWTUserInfoDto } from "../../dtos/authentication/token/jwtUserInfo.dto";
import OAuth2Service from "../authentication/oauth2.service";

const relativeUrl = "/v1/api/tags";
export const TYPE_CATEGORY = "category";
export const TYPE_DROPDOWN = "dropdown";
export type TAG_TYPE = "category" | "dropdown";

export default class TagService extends ApiServiceData {
	public getAllFor(): Promise<object> {
		// @todo move organization id out of the function
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const organizationId =
			currentUserInfo?.Organizations?.[0]?.OrganizationId || "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65";

		return this.publicGet(relativeUrl, {
			params: {
				organization: organizationId || "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65"
			}
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllForPublic(params: object): Promise<TagDto[]> {
		return this.publicGet(relativeUrl + "/public", { params })
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public addTag(tag: any) {
		return this.post(relativeUrl, tag)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public creategroupClassroomTag(classroomTag: any[]) {
		return this.post(relativeUrl + "/classroomTag/allTree", classroomTag)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public createTagTree(tag: any[]) {
		return this.post(relativeUrl + "/allTree", tag)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateTag(tagId: string, tag: any) {
		return this.update(relativeUrl + "/" + tagId, tag)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllLessonClassroomTags(tagId: string, classroomId: string): Promise<object[]> {
		// @todo possibly move into the specific use case
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const organizationId =
			currentUserInfo?.Organizations?.[0]?.OrganizationId || "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65";

		return this.publicGet(relativeUrl + "/tag/" + tagId + "/classroom/" + classroomId + "/lessons", {
			params: {
				organization: organizationId
			}
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllRootTagsforAdminLessons(classroomId: string): Promise<object[]> {
		// @todo possibly move into the specific use case
		const organizationId = "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65";

		return this.publicGet(relativeUrl + "/lessons/tag/all/" + classroomId, {
			params: {
				organization: organizationId
			}
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllRootTagsforLessons(): Promise<object[]> {
		return this.get(relativeUrl + "/lessons/tag/not-asyncOnly")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllChildTags(tagId: string): Promise<object[]> {
		return this.get(relativeUrl + "/lessons/tag/child/" + tagId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllChildTagsForClassroom(tagId: string, classroomId: string): Promise<object[]> {
		return this.get(relativeUrl + "/lessons/tag/child/" + tagId + "/classroom/" + classroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public deleteClassroomTagId(classroomTagId: string, classroomId: string): Promise<object[]> {
		return this.delete(relativeUrl + "/classroomTag/" + classroomTagId + "/classroom/" + classroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public updateClassroomTagId(classroomTagId: string, tag: any): Promise<object[]> {
		return this.update(relativeUrl + "/classroomTag/" + classroomTagId, tag)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public removeTag(tagId: string) {
		return this.delete(relativeUrl + "/" + tagId)
			.then(res => {
				return res;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Parent tag is in use"
				) {
					return { status: 409 };
				}
				throw error.response;
			});
	}

	public deleteLessonClassroomTagId(lessonClassroomTagId: string, lessonId: string): Promise<object[]> {
		return this.delete(relativeUrl + "/lessonClassroomTag/" + lessonClassroomTagId + "/lesson/" + lessonId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllLessonsGroup(lessonId: string): Promise<object[]> {
		return this.get(relativeUrl + "/lesson/" + lessonId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public reorderTags(orderdTags: any[]): Promise<any> {
		return this.post(relativeUrl + "/reorder-classroom-tags", orderdTags)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public reorderClassroomLessonsTags(orderdTags: { id: string; priority: number }[]): Promise<any> {
		return this.post(relativeUrl + "/reorder-classroom-lessons-tags", orderdTags)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
