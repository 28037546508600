import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Button from "common/components/general/Button";
import MarketplaceIcon from "assets/images/dashboard-marketplace-banner-icon.svg";
import { SUBJECTS_PATH } from "../../../../../admin/scenes/subjects";
import RawCss from "../../../../../../common/components/utils/RawCss";
import { getDesignS3Url } from "../../../../../../common/utils/Design";
import S3ActionType from "../../../../../../services/domain/administration/S3Action";
import classnames from "classnames";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._MARKETPLACE_BANNER";

interface MarketplaceBannerProps {
	showButton?: string;
	title?: string;
	subtitle?: string;
	actionText?: string;
	actionLink?: string;
	image?: string;
	backgroundColor?: string;
	textColor?: string;
}

export const MarketplaceBanner = (props: MarketplaceBannerProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const showButton = !props.showButton || parseInt(props.showButton) === 1;
	const classNames = {
		".dashboard__marketplace_banner": {
			"background-color": props.backgroundColor || "",
			"text-color": props.textColor || "#fff"
		}
	};
	const mainClass = classnames({
		"dashboard__marketplace_banner": true,
		"dashboard__marketplace_banner--no-bg": props.backgroundColor && props.backgroundColor !== ""
	});

	return (
		<>
			<div className={mainClass}>
				<RawCss classNames={classNames} />
				<Col>
					<Row className="mb-4">
						<Col span="24" className="marketplace_banner__title">
							<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-2">
								{props.title && props.title.length > 0 ? (
									<div dangerouslySetInnerHTML={{ __html: props.title }} />
								) : (
									translate(`${TRANSLATION_BASE_PATH}._TITLE`)
								)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-16">
						<Col span="24" className="marketplace_banner__description">
							<Text fontSize="14" lineHeight="22" className="color-gray-5">
								{props.subtitle && props.subtitle.length > 0 ? (
									<div dangerouslySetInnerHTML={{ __html: props.subtitle }} />
								) : (
									translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)
								)}
							</Text>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col span="24">
							{showButton && (
								<Button
									className="marketplace_banner__button"
									type="primary"
									icon="ri-arrow-right-line"
									iconPosition="right"
									onClick={
										props.actionLink && props.actionLink.length > 0
											? () => (window.location.href = props.actionLink || "")
											: () => history.push(`/${SUBJECTS_PATH}`)
									}
								>
									{props.actionText && props.actionText.length > 0
										? props.actionText
										: translate(`${TRANSLATION_BASE_PATH}._BUTTON`)}
								</Button>
							)}
						</Col>
					</Row>
				</Col>
				{props.image ? (
					<Image
						className="marketplace_banner__custom-icon"
						src={getDesignS3Url(props.image, S3ActionType.DOWNLOAD)}
					/>
				) : (
					<Image src={MarketplaceIcon} className="marketplace_banner__icon" />
				)}
			</div>
		</>
	);
};
