import React, { Component, createContext } from "react";
import { Layout, Row, Col, Space, Popconfirm } from "antd";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import { COURSE_BUILDER_PATH } from "scenes/courseBuilder/scenes/courseDetails/scenes/SubjectPlanCreator/SubjectPlanCreator";
import LessonService from "services/administration/lesson.service";
import ClassroomService from "services/administration/classroom.service";

const { Header, Content } = Layout;
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._LAYOUT";

type ChildProps = {
	closeLessonMaterial: any;
	setEditMode: any;
	isEditMode: boolean;
	setTitle: any;
	setActualLessonId: any;
	setClassroom: any;
	classroom: any;
	showPrompt: boolean;
	setShowPromptState: any;
	onUploadingStarted: any;
};

export const ChildContext = createContext<ChildProps>({
	closeLessonMaterial: null,
	setEditMode: null,
	isEditMode: false,
	setTitle: null,
	setActualLessonId: null,
	setClassroom: null,
	classroom: null,
	showPrompt: false,
	setShowPromptState: null,
	onUploadingStarted: null
});

class MainLayoutComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			isEditMode: false,
			title: "",
			actualLessonId: "",
			classroom: undefined,
			redirectToClassroom: false,
			showPrompt: false,
			uploading: false
		};
		this.closeLessonMaterial = this.closeLessonMaterial.bind(this);
		this.setEditMode = this.setEditMode.bind(this);
		this.setTitle = this.setTitle.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.setActualLessonId = this.setActualLessonId.bind(this);
		this.setShowPromptState = this.setShowPromptState.bind(this);
	}

	closeLessonMaterial() {
		this.setState({ redirectToClassroom: true });
	}

	setEditMode = (isEditMode: boolean) => {
		this.setState({ isEditMode: isEditMode });
	};

	setTitle = (title: string) => {
		this.setState({ title: title });
	};

	setActualLessonId = (actualLessonId: string) => {
		this.setState({ actualLessonId: actualLessonId });
	};

	setClassroom = async (classroomId: string) => {
		if (!this.state.classroom || this.state.classroom.id !== classroomId) {
			const classroom = await new ClassroomService().getClassroomById(classroomId);
			this.setState({ classroom: classroom });
		}
	};

	setShowPromptState = (state: any) => {
		this.setState({ showPrompt: state });
	};

	onDelete = async () => {
		await new LessonService().deleteLesson(this.state.actualLessonId);
		this.closeLessonMaterial();
		this.setShowPromptState(true);
	};

	onUploadingStarted = (started: boolean) => {
		this.setState({
			uploading: started
		});
	};

	render() {
		const { t: translate } = this.props;
		const pathToCourseContent = `/${COURSE_BUILDER_PATH}/${this.state.classroom?.isAsync ? "asynch" : "_teacher"}/${
			this.state.classroom?.id
		}?content=true`;

		if (this.state.redirectToClassroom) return <Redirect to={pathToCourseContent} />;
		return (
			<Layout className="full__min_height">
				<Header>
					<Row justify="space-between" align="middle">
						<Col span={21}>
							<Space direction="horizontal">
								<Icon
									type="ri-close-line"
									className="color-gray-9 cursor_pointer"
									onClick={() => {
										this.closeLessonMaterial();
									}}
									fontSize="24"
								/>
								<Text fontSize="20" lineHeight="28" wheight="semibold">
									{this.state.title}
								</Text>
							</Space>
						</Col>
						<Col span={3}>
							<Row justify="end">
								<Space>
									{this.state.isEditMode && (
										<Popconfirm
											title={translate(`${TRANSLATION_BASE_PATH}._DELETE_CONFIRMATION`)}
											okText={translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
											cancelText={translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
											onConfirm={() => this.onDelete()}
										>
											<Button
												type="ghost"
												htmlType={"button"}
												size={"middle"}
												icon={"ri-delete-bin-7-line"}
												disabled={this.state.uploading}
											>
												{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
											</Button>
										</Popconfirm>
									)}
									<Button
										type="primary"
										onClick={() => this.props.onSubmitTrigger()}
										disabled={this.state.uploading}
									>
										{translate(`${TRANSLATION_BASE_PATH}._SAVE`)}
									</Button>
								</Space>
							</Row>
						</Col>
					</Row>
				</Header>
				<Content className="background-color-gray-2">
					<ChildContext.Provider
						value={{
							closeLessonMaterial: this.closeLessonMaterial,
							isEditMode: this.state.isEditMode,
							setEditMode: this.setEditMode,
							setTitle: this.setTitle,
							setActualLessonId: this.setActualLessonId,
							setClassroom: this.setClassroom,
							classroom: this.state.classroom,
							showPrompt: this.state.showPrompt,
							setShowPromptState: this.setShowPromptState,
							onUploadingStarted: this.onUploadingStarted
						}}
					>
						{this.props.children}
					</ChildContext.Provider>
				</Content>
			</Layout>
		);
	}
}

const MainLayout = withTranslation()(MainLayoutComponent);
export default MainLayout;
