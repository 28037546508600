import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import {
	AsynchCourseBuilderLayout,
	RELATED_COURSE_PATH as ASYNCH_RELATED_COURSE_PATH
} from "scenes/courseBuilder/scenes/courseDetails/components/layout/AsynchCourseBuilderLayout";
import {
	MainTeacherLayout,
	RELATED_COURSE_PATH as TEACHER_RELATED_COURSE_PATH
} from "scenes/courseBuilder/scenes/courseDetails/components/layout/TeacherLayout";
import { MAIN_PATH } from "scenes/main";
import BuildMaterialLayout, { MATERIALS_PATH } from "scenes/courseBuilder/scenes/buildMaterials";
import MainLayout from "./components/layout/Layout";
import { IndividualCourseBuilderLayout } from "./scenes/courseDetails/components/layout/IndividualCourseBuilderLayout";

export const COURSE_BUILDER_PATH = "course-builder";

const Main = () => {
	return (
		<MainLayout>
			<Switch>
				<Route path={`/${COURSE_BUILDER_PATH}/asynch/${ASYNCH_RELATED_COURSE_PATH}`} exact component={AsynchCourseBuilderLayout} />
				<Route path={`/${COURSE_BUILDER_PATH}/_teacher/${TEACHER_RELATED_COURSE_PATH}`} exact component={MainTeacherLayout} />
				<Route path={`/${COURSE_BUILDER_PATH}/individual/:classroomId`} exact component={IndividualCourseBuilderLayout} />
				<Route path={`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/*`} component={BuildMaterialLayout} />
				<Route path={`/${COURSE_BUILDER_PATH}/*`} component={() => <Redirect to={`/${MAIN_PATH}`} />} />
			</Switch>
		</MainLayout>
	);
};

export default Main;
