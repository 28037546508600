import {v4 as uuidv4} from "uuid";
import { TAG_TYPE } from "../../services/administration/tag.services";

export default class TagDto {
	readonly id!: string;
	readonly ParentId?: string;
	readonly name!: string;
	readonly target!: string;
	readonly asyncOnly!: boolean
	readonly isGlobal!: boolean
	readonly priority!: string
	readonly type?: TAG_TYPE
	readonly SubTagsCount!: string
	ClassroomTags?: any[];
	Tags?: any[];

	constructor(data: {
		ParentId?: string;
		name: string;
		target: string;
		asyncOnly: boolean;
		isGlobal: boolean;
		priority: string;
		SubTagsCount: string;
		type?: string;
		Tags?: any[];
		[key: string]: any
	}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
