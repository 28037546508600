import { Empty } from "antd";
import React from "react";

import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._INDIVIDUAL_CLASSROOM_LAYOUT";

export const EmptyState = () => {
	const { t: translate } = useTranslation();

	return (
		<Empty
			description={
				<Text fontSize="14" lineHeight="22" className="color-gray-7">
					{translate(`${TRANSLATION_BASE_PATH}._EMPTY_WITHOUT_ENROLLED_STUDENTS`)} <br />
					{translate(`${TRANSLATION_BASE_PATH}._EMPTY_WITHOUT_ENROLLED_STUDENTS_DESCRIPTION`)}
				</Text>
			}
		/>
	);
};
