import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Space, Row, Col } from "antd";
import { ListCard } from "common/components/listCard/ListCard";
import _ from "lodash";

const TRANSLATION_BASE_PATH = "_COURSE._COURSE_DETAILS._STUDENT_LIST";

class StudentListComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			teacher: [],
			students: [],
			classroom: null,
			enrolledStudents: null,
			refreshToggle: null
		};
	}

	componentDidMount() {
		this.loadStudentList();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.refreshToggle !== this.state.refreshToggle) this.loadStudentList();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.refreshToggle !== prevState.refreshToggle) return { refreshToggle: params.refreshToggle };
		return null;
	}

	loadStudentList = () => {
		this.setState({ teacher: [], students: [] });

		const { firstName, lastName } = (this.props.classroom && this.props.classroom.UserCreatedBy) || {
			firstName: null,
			lastName: null
		};
		const teacher = this.state.teacher;
		const students = this.state.students;
		teacher.push({ firstName, lastName, userId: (this.props.classroom && this.props.classroom.createdBy) || null });

		this.props.enrolledStudents &&
			this.props.enrolledStudents.map((enrolledStudent: any) => {
				const { firstName, lastName } = enrolledStudent.User;
				students.push({ firstName, lastName, userId: enrolledStudent.UserId });
			});

		const orderStudents = _(students)
			.orderBy(x => x.firstName, "asc")
			.value();

		this.setState({ teacher: teacher, students: orderStudents });
	};

	render() {
		const { t: translate } = this.props;

		return (
			<>
				<Space direction="vertical" className="full__width" size={24}>
					<Row justify="center">
						<Col xs={23} sm={22} md={20} lg={16} xl={16} xxl={16}>
							<ListCard
								listItem={this.state.teacher}
								size="small"
								cardTitle={translate(`${TRANSLATION_BASE_PATH}._TEACHER`)}
							/>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={23} sm={22} md={20} lg={16} xl={16} xxl={16}>
							<ListCard
								listItem={this.state.students}
								size="small"
								cardTitle={translate(`${TRANSLATION_BASE_PATH}._STUDENT`, {
									noStudent: (this.state.students && this.state.students.length) || 0
								})}
							/>
						</Col>
					</Row>
				</Space>
			</>
		);
	}
}

const StudentList = withTranslation()(StudentListComponent);
export default StudentList;
