import React, { Component, ReactNode } from "react";
import {
	Row,
	Col,
	Button,
	Space,
	Tabs,
	Empty,
	Skeleton,
	Slider,
	Collapse,
	Card,
	Divider,
	Modal,
	message,
	Switch, InputNumber
} from "antd";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import _ from "lodash";
import { CaretRightOutlined } from "@ant-design/icons";
import { Pie } from "react-chartjs-2";
import { v4 as uuidv4 } from "uuid";
import { SectionTitle } from "../../../../components/sectionTitle/SectionTitle";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import Input from "common/components/dataEntry/formik/FormikInputField";
import OrganizationService from "services/administration/organization.service";
import { formValidator } from "../../utils/ValidationSchema";
import { showError, showMessage } from "common/utils/Notification";
import { ResponsiveSearchBox } from "../../../../components/searchBox/ResponsiveSearchBox";
import { SearchUtil } from "../../../../util/SearchData";
import { getUuidCode } from "common/utils/UuidCode";
import { SectionContent } from "../../../../components/sectionContent/SectionContent";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import CounterCard from "common/components/_counterCard/CounterCard";
import AnalyticsOrganizationService from "services/analytics/organization.service";
import { formatTotal } from "../../../../util/formatTotal";
import TabPane from "common/components/dataDisplay/TabPane";
import AlFlag from "assets/images/icons/Al.svg";
import KsFlag from "assets/images/icons/Ks.svg";
import Image from "common/components/general/Image";
import { AdminTable } from "../../../../components/table/table";
import { ADMIN_PATH } from "../../../../index";
import { DASHBOARD_PATH } from "../../../dashboard";
import { sumTotal } from "../../../../util/sumTotal";
import { TableRowNavLink } from "../../../../components/tableRowNavLink/TableRowNavLink";
import { InstitutionCode } from "../../../../components/institutionCode/InstitutionCode";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import { FormFieldLabelWithTooltip } from "../../../../components/formFieldLabelWithTooltip/FormFieldLabelWithTooltip";
import { GenerateReportButton } from "../../components/generateReportButton/GenerateReportButton";
import OAuth2Service from "services/authentication/oauth2.service";
import AnalyticsUserService from "services/analytics/user.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import { ItemType } from "services/domain/administration/ItemType";
import {
	getEnvironmentFromDomain,
	getSubdomain,
	isRequestCertificateRequired,
	getOrganizationCertificationState,
	getOrganizationCertificationConnection,
	isReservedDomain
} from "services/common/check-domain";
import { ModalInfoDNS } from "../../../../components/modalInfoDNS/ModalInfoDNS";
import { formValidatorDomain } from "../../components/editDrawer/ValidationSchemaDomain";
import ACMService from "services/s3/acm.service";
import ELBV2Service from "services/s3/elbv2.service";
import { MarketplaceUpload } from "../../components/marketplaceUpload/MarketplaceUpload";
import styled from "styled-components";

const { Panel } = Collapse;

export const ORGANIZATIONS_PATH = "organizations";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";
const NATIONALITIES = ["ALBANIA", "KOSOVO"];
const TYPES = ["PRE_UNI", "UNI", "INDIVID", "INSTITUTION"];
const TYPES_ICONS = new Map([
	[TYPES[0], "ri-community-line"],
	[TYPES[1], "ri-bank-line"],
	[TYPES[2], "ri-account-pin-circle-line"],
	[TYPES[3], "ri-hotel-line"]
]);
const CONTENT_TYPES = ["OFFLINE", "LIVE", "ALL"];
const SECTORS = ["PUBLIC", "PRIVATE"];

class OrganizationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			organizations: [0, 0],
			filteredData: [],
			drawerShown: false,
			formInitialValues: {
				id: null,
				name: "",
				code: "",
				type: "",
				contentType: "",
				sector: "",
				domain: "",
				numberOfUsers: null,
				price: null,
				ratio: null,
				ownerEmail: "",
				editDomainModal: false,
				showModalInfoDNS: false,
				certificateCreated: null,
				listOfCertificates: []
			},
			activeTabKey: 0,
			activeGraphTabKey: 0,
			nationalities: NATIONALITIES,
			cardTotalPublicInstitutionsByCountry: [],
			cardTotalPrivateInstitutionsByCountry: [],
			cardTotalPreUniInstitutionsByCountry: [],
			cardTotalUniInstitutionsByCountry: [],
			cardTotalIndividInstitutionsByCountry: [],
			cardTotalInstInstitutionsByCountry: [],
			cardTotalInstitutions: [],
			cardTotalInstitutionsByCountry: [],
			cardTotalTeachersByCountry: [],
			cardTotalVirtualClassesByCountry: [],
			cardTotalAsyncClassesByCountry: [],
			cardTotalStudentsByCountry: [null],
			cardTotalParents: 0,
			cardTotalParentsLoading: false
		};
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.loadItemAndShowDrawer = this.loadItemAndShowDrawer.bind(this);
	}

	async componentDidMount() {
		this.setState({
			loading: true,
			cardTotalParentsLoading: true
		});

		OAuth2Service.isOwnerOrAdminAtSuperOrganization &&
			this.loadTableOrganizationTotals().then(() => {
				this.loadCertificationDomainState();
				return this.calcCardTotals();
			});

		return new AnalyticsUserService()
			.countAllParents()
			.then((result: any) => {
				return this.setState({
					cardTotalParents: parseInt(result)
				});
			})
			.finally(() => {
				this.setState({
					cardTotalParentsLoading: false
				});
			});
	}

	calcCardTotals() {
		const totalInstitutionsAlbania = this.state.organizations[0].length;
		const totalInstitutionsKosovo = this.state.organizations[1].length;
		const totalInstitutionsDiaspora = 9;
		const totalInstitutions = totalInstitutionsAlbania + totalInstitutionsKosovo;
		const totalPrivateAlbania = this.state.organizations[0].filter((x: any) => x.sector === "PRIVATE").length;
		const totalPrivateKosovo = this.state.organizations[1].filter((x: any) => x.sector === "PRIVATE").length;
		const totalPublicAlbania = this.state.organizations[0].filter((x: any) => x.sector === "PUBLIC").length;
		const totalPublicKosovo = this.state.organizations[1].filter((x: any) => x.sector === "PUBLIC").length;

		const totalPreUniAlbania = this.state.organizations[0].filter((x: any) => x.type === "PRE_UNI").length;
		const totalPreUniKosovo = this.state.organizations[1].filter((x: any) => x.type === "PRE_UNI").length;
		const totalUniAlbania = this.state.organizations[0].filter((x: any) => x.type === "UNI").length;
		const totalUniKosovo = this.state.organizations[1].filter((x: any) => x.type === "UNI").length;
		const totalIndividAlbania = this.state.organizations[0].filter((x: any) => x.type === "INDIVID").length;
		const totalIndividKosovo = this.state.organizations[1].filter((x: any) => x.type === "INDIVID").length;
		const totalInstAlbania = this.state.organizations[0].filter((x: any) => x.type === "INSTITUTION").length;
		const totalInstKosovo = this.state.organizations[1].filter((x: any) => x.type === "INSTITUTION").length;

		const totalTeachersAlbania = sumTotal(this.state.organizations[0], "teacherCount");
		const totalTeachersKosovo = sumTotal(this.state.organizations[1], "teacherCount");
		const totalVirtualClassesAlbania = sumTotal(this.state.organizations[0], "classCount");
		const totalVirtualClassesKosovo = sumTotal(this.state.organizations[1], "classCount");
		const totalAsyncClassesAlbania = sumTotal(this.state.organizations[0], "asyncCount");
		const totalAsyncClassesKosovo = sumTotal(this.state.organizations[1], "asyncCount");
		const totalStudentsAlbania = sumTotal(this.state.organizations[0], "studentCount");
		const totalStudentsKosovo = sumTotal(this.state.organizations[1], "studentCount");

		this.setState({
			cardTotalPublicInstitutionsByCountry: [totalPublicAlbania, totalPublicKosovo],
			cardTotalPrivateInstitutionsByCountry: [totalPrivateAlbania, totalPrivateKosovo],
			cardTotalPreUniInstitutionsByCountry: [totalPreUniAlbania, totalPreUniKosovo],
			cardTotalUniInstitutionsByCountry: [totalUniAlbania, totalUniKosovo],
			cardTotalIndividInstitutionsByCountry: [totalIndividAlbania, totalIndividKosovo],
			cardTotalInstInstitutionsByCountry: [totalInstAlbania, totalInstKosovo],
			cardTotalInstitutions: totalInstitutions,
			cardTotalInstitutionsByCountry: [
				totalInstitutionsAlbania,
				totalInstitutionsKosovo,
				totalInstitutionsDiaspora
			],
			cardTotalVirtualClassesByCountry: [totalVirtualClassesAlbania, totalVirtualClassesKosovo],
			cardTotalAsyncClassesByCountry: [totalAsyncClassesAlbania, totalAsyncClassesKosovo],
			cardTotalTeachersByCountry: [totalTeachersAlbania, totalTeachersKosovo],
			cardTotalStudentsByCountry: [totalStudentsAlbania, totalStudentsKosovo]
		});
	}

	loadTableOrganizationTotals = async () => {
		await new AnalyticsOrganizationService()
			.getOrganizationsTotals()
			.then((result: any) => {
				const albaniaData = _.orderBy(
					result.filter((x: any) => {
						return x.nationality === "ALBANIA";
					}),
					"createdAt",
					"desc"
				);
				const kosovoData = _.orderBy(
					result.filter((x: any) => {
						return x.nationality === "KOSOVO";
					}),
					"createdAt",
					"desc"
				);
				return this.setState({
					organizations: [albaniaData, kosovoData],
					filteredData: [_.cloneDeep(albaniaData), _.cloneDeep(kosovoData)]
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				});
			});
	};

	loadCertificationDomainState = async () => {
		await Promise.all([
			new ACMService().listCertificates(),
			new ELBV2Service().getCertificates(getEnvironmentFromDomain(window.location.host))
		]).then(([listOfCertificates, linkCertificates]: any) => {
			const newFilteredData = this.state.filteredData.map((filtered: any) => {
				return filtered.map((organization: any) => {
					const organizationCertState = getOrganizationCertificationState(
						organization.domain,
						listOfCertificates
					);
					const organizationCertLinkedState = getOrganizationCertificationConnection(
						organization.domain,
						listOfCertificates,
						linkCertificates
					);
					return {
						...organization,
						certificationState: organizationCertState,
						certificationLinked: organizationCertLinkedState
					};
				});
			});
			return this.setState({
				listOfCertificates: listOfCertificates,
				organizations: newFilteredData,
				filteredData: [_.clone(newFilteredData[0]), _.clone(newFilteredData[1])]
			});
		});
	};

	toggleDrawer = () => {
		this.setState({
			drawerShown: !this.state.drawerShown
		});
	};

	toggleEditDomainModal = (status: boolean) => {
		this.setState({
			editDomainModal: status
		});
	};

	createOrganization = async (values: any) => {
		const { t: translate } = this.props;
		this.setState({ loading: true, submitting: true });

		const { generateRequestCertificate, certificateCreated }: any = await this.checkNewDomainToCreate(
			values.domain,
			values.name
		).catch((error: any) => {
			throw this.awsDomainError(error);
		});

		return new OrganizationService()
			.createOrganization({ ...values, hasNewDomain: generateRequestCertificate })
			.then(({ data: { id } }) => {
				showMessage(
					translate(`${TRANSLATION_BASE_PATH}._SUCCESS_MESSAGE`, {
						OrganizationName: values.name
					})
				);
				const newData =
					this.state.organizations && this.state.organizations.length > 0
						? this.state.organizations[+this.state.activeTabKey]
						: [];
				newData.push({
					classCount: "0",
					studentCount: "0",
					teacherCount: "0",
					code: values.code,
					createdAt: new Date(),
					id: values.id,
					name: values.name,
					nationality: values.nationality,
					type: values.type,
					sector: values.sector,
					status: "PENDING",
					domain: values.domain,
					contentType: values.contentType,
					ownerEmail: values.ownerEmail,
					default: generateRequestCertificate,
					certificationState: "PENDING_VALIDATION",
					certificationLinked: "NOT_CONNECTED"
				});
				this.navigateToOrganizationDetails(id);
				const newOrganizations =
					+this.state.activeTabKey === 0
						? [newData, this.state.organizations[1]]
						: [this.state.organizations[0], newData];
				return this.setState(
					{
						organizations: newOrganizations,
						filteredData: [_.clone(newOrganizations[0]), _.clone(newOrganizations[1])]
					},
					() => {
						return this.calcCardTotals();
					}
				);
			})
			.finally(() => {
				this.setState({
					submitting: false,
					loading: false,
					showModalInfoDNS: generateRequestCertificate,
					certificateCreated: certificateCreated
				});
				this.toggleDrawer();
			});
	};

	navigateToOrganizationDetails = (id: any) => {
		const to = `/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${id}`;
		this.props.history.push(to);
	};

	private async checkNewDomainToCreate(domain: string, organizationName: string) {
		const { t: translate } = this.props;
		const generateRequestCertificate: any = isRequestCertificateRequired(domain);
		let certificateCreated: any = null;

		if (generateRequestCertificate) {
			const isDomainUsed: boolean = await new OrganizationService().isDomainUsed(domain);
			if (isDomainUsed) throw translate(`${TRANSLATION_BASE_PATH}._ERROR_DOMAIN_EXIST`, { domain: domain });
			certificateCreated = await new ACMService()
				.requestCertificate(
					{ domain: domain, organizationName: organizationName },
					getEnvironmentFromDomain(window.location.host),
					getSubdomain(domain)
				)
				.catch((error: any) => {
					throw error;
				});
		}
		return { generateRequestCertificate, certificateCreated };
	}

	private awsDomainError = (error: any) => {
		return this.setState(
			{
				submitting: false,
				loading: false,
				showModalInfoDNS: false,
				certificateCreated: null
			},
			() => {
				return showError(error);
			}
		);
	};

	onDelete = (organizationId: string) => {
		const { t: translate } = this.props;

		this.setState({
			submitting: true
		});

		return new OrganizationService()
			.delete(organizationId)
			.then((result: any) => {
				showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
				return this.loadTableOrganizationTotals();
			})
			.finally(() => {
				this.setState({
					submitting: false
				});
			});
	};

	loadItemAndShowDrawer = () => {
		const userInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		this.setState(
			{
				formInitialValues: {
					id: uuidv4(),
					name: "",
					code: getUuidCode(),
					nationality: NATIONALITIES[this.state.activeTabKey],
					type: "",
					contentType: "",
					sector: "",
					domain: userInfo?.Organizations[0].Domain,
					numberOfUsers: null,
					price: null,
					ratio: null,
					ownerEmail: ""
				}
			},
			() => {
				this.toggleDrawer();
			}
		);
	};

	search = (value: string) => {
		if (this.state.activeTabKey === 0) {
			this.setState({
				filteredData: [
					SearchUtil(this.state.organizations[+this.state.activeTabKey], value, {
						column1: "name",
						column2: "code"
					}),
					this.state.filteredData[1]
				]
			});
		}

		if (+this.state.activeTabKey === 1) {
			this.setState({
				filteredData: [
					this.state.filteredData[0],
					SearchUtil(this.state.organizations[+this.state.activeTabKey], value, {
						column1: "name",
						column2: "code"
					})
				]
			});
		}
	};

	get columnsTableOrganizations() {
		const { t: translate } = this.props;

		const renderSector = (sector: string) => {
			if (sector === "PRIVATE") {
				return (
					<Space className="users__elipse color-blue-1 background-color-blue-9">
						<Icon type="ri-shield-keyhole-line" />
						<Text fontSize="14" lineHeight="22">
							Private
						</Text>
					</Space>
				);
			}
			return (
				<Space className="users__elipse color-blue-6 background-color-blue-1">
					<Icon type="ri-shield-user-line" />
					<Text fontSize="14" lineHeight="22">
						Publike
					</Text>
				</Space>
			);
		};

		const renderType = (type: string) => {
			return (
				<Space>
					<Icon type={TYPES_ICONS.get(type) ?? "ri-user-line"}></Icon>
					<Text fontSize="14" lineHeight="22" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._TYPE_${type}`)}
					</Text>
				</Space>
			);
		};

		const renderStatus = (status: string) => {
			return (
				<Space>
					<Icon
						type="ri-checkbox-blank-circle-fill"
						className={
							status === "ACTIVE"
								? "color-green-6"
								: status === "PENDING"
								? "color-gray-5"
								: "color-red-6"
						}
					></Icon>
					{translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS_${status}`)}
				</Space>
			);
		};

		const renderCertState = (state: string) => {
			return (
				<Space>
					<Icon
						type="ri-checkbox-blank-circle-fill"
						className={
							!state || state === "PENDING_VALIDATION"
								? "color-gray-5 "
								: state === "ISSUED_CONNECTED"
								? "color-green-6"
								: state === "ISSUED_NOT_CONNECTED"
								? "color-orange-6"
								: "color-red-6"
						}
					></Icon>
					{translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._CERT_STATE_${state}`)}
				</Space>
			);
		};

		return [
			{
				dataIndex: "featuredSort",
				key: "featuredSort",
				width: 0,
				render: (text: any, row: any, index: any) => {
					const visibility = row.featured ? "visible" : "hidden";
					return (
						<div
							style={{
								width: "100px",
								height: "20px",
								position: "absolute",
								top: "0px",
								left: "0px",
								zIndex: 1000,
								visibility: visibility,
								display: "flex"
							}}
						>
							<div
								style={{
									flexBasis: "20px",
									backgroundColor: "#418FDE",
									color: "white",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: "normal",
									fontSize: "12px",
									lineHeight: "22px"
								}}
							>
								{text}
							</div>
							<div
								style={{
									flexBasis: "80px",
									backgroundColor: "#4DCAC2",
									color: "white",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									fontFamily: "Inter",
									fontStyle: "normal",
									fontWeight: "normal",
									fontSize: "12px",
									lineHeight: "22px"
								}}
							>
								Featured
							</div>
						</div>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._NAME`),
				dataIndex: "name",
				key: "name",
				fixed: "left",
				width: 250,
				render: (text: any, row: any) => {
					const marginTop = row.featured === true ? "1em" : "0";
					return (
						<Row align="middle" style={{ marginTop: marginTop }}>
							<Space>
								<Col xs={0} sm={4} md={4} lg={4} xl={3} xxl={2}>
									<TypeIcon fontSize={14} type={(row.status + "_ORGANIZATION") as ItemType} />
								</Col>
								<Col>
									<Row>{text}</Row>
									<Row>
										<InstitutionCode code={row.code} />
									</Row>
									<Row>
										<Space>
											<Icon type="ri-link"></Icon>
											<a href={`http://${row.domain}`} target="_blank" rel="noopener noreferrer">
												<Text fontSize="12" lineHeight="20" className="color-gray-7">
													{row.domain}
												</Text>
											</a>
										</Space>
									</Row>
								</Col>
							</Space>
						</Row>
					);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._CERT_STATE`),
				dataIndex: "certificationState",
				key: "certificationState",
				responsive: ["sm"],
				render: (text: any, row: any) => {
					const state = text === "ISSUED" ? `${text}_${row.certificationLinked}` : text;
					return renderCertState(state);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._SECTOR`),
				dataIndex: "sector",
				key: "sector  ",
				responsive: ["sm"],
				render: (text: any) => {
					return renderSector(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._TYPE`),
				dataIndex: "type",
				key: "type  ",
				responsive: ["sm"],
				render: (text: any) => {
					return renderType(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._CONTENT_TYPE`),
				dataIndex: "contentType",
				key: "contentType",
				responsive: ["sm"],
				render: (text: any) => {
					return translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._CONTENT_TYPE_${text}`);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._OWNER_EMAIL`),
				dataIndex: "ownerEmail",
				key: "ownerEmail",
				responsive: ["sm"],
				render: (text: any) => {
					return text;
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STATUS`),
				dataIndex: "status",
				key: "status",
				responsive: ["sm"],
				render: (text: any) => {
					return renderStatus(text);
				}
			},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				align: "center",
				fixed: "right",
				width: 150,
				render: (id: any) => {
					const to = `/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${id}`;
					return <TableRowNavLink to={to} />;
				}
			}
		];
	}

	getTabContent(index: number) {
		const { t: translate } = this.props;

		const cardPublicOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._PUBLIC_ORGANIZATIONS`);
		const cardPrivateOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._PRIVATE_ORGANIZATIONS`);

		const cardPreUniOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._PRE_UNI_ORGANIZATIONS`);
		const cardUniOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._UNI_ORGANIZATIONS`);
		const cardIndividOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._INDIVID_ORGANIZATIONS`);
		const cardCoorpOrganizations = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._COORP_ORGANIZATIONS`);

		const cardTitleVirtualClasses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._VIRTUAL_CLASSES`);
		const cardTitleAsynchClasses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._ASYNC_CLASSES`);
		const cardTitleTeachers = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._TEACHERS`);
		const cardTitleStudents = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._STUDENTS`);

		const btnTitle = translate(`${TRANSLATION_BASE_PATH}._BTN_ADD`);

		return (
			<>
				<Collapse
					ghost={true}
					className="background-color-gray-2 pb-8"
					expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
				>
					<Panel
						header={
							<Text fontSize="16" lineHeight="24" className="color-gray-9" wheight="semibold">
								Statistika
							</Text>
						}
						key="1"
					>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
								<CounterCard
									title={cardPublicOrganizations}
									icon="ri-shield-user-line"
									data={this.state.cardTotalPublicInstitutionsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
								<CounterCard
									title={cardPrivateOrganizations}
									icon="ri-shield-keyhole-line"
									data={this.state.cardTotalPrivateInstitutionsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardPreUniOrganizations}
									icon={TYPES_ICONS.get(TYPES[0])}
									data={this.state.cardTotalPreUniInstitutionsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardUniOrganizations}
									icon={TYPES_ICONS.get(TYPES[1])}
									data={this.state.cardTotalUniInstitutionsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardIndividOrganizations}
									icon={TYPES_ICONS.get(TYPES[2])}
									data={this.state.cardTotalIndividInstitutionsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardCoorpOrganizations}
									icon={TYPES_ICONS.get(TYPES[3])}
									data={this.state.cardTotalInstInstitutionsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardTitleVirtualClasses}
									icon="ri-door-open-line"
									data={this.state.cardTotalVirtualClassesByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardTitleAsynchClasses}
									icon="ri-door-open-line"
									data={this.state.cardTotalAsyncClassesByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardTitleTeachers}
									icon="ri-user-2-line"
									data={this.state.cardTotalTeachersByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
								<CounterCard
									title={cardTitleStudents}
									icon="ri-user-line"
									data={this.state.cardTotalStudentsByCountry[index]}
									loading={this.state.loading}
								/>
							</Col>
						</Row>
					</Panel>
				</Collapse>
				<Row>
					<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
						<Space>
							<Button onClick={() => this.loadItemAndShowDrawer()} type="primary" size="middle" block>
								<Space>
									<Text fontSize="14" lineHeight="22">
										<Icon type="ri-add-line"></Icon>
									</Text>
									{btnTitle}
								</Space>
							</Button>
							<GenerateReportButton user={OAuth2Service.CurrentUser} />
						</Space>
					</Col>
					<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
						<Row justify="end">
							<ResponsiveSearchBox placeholder={translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} onSearch={this.search} />
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Skeleton loading={this.state.loading}>
							{this.state.filteredData[this.state.activeTabKey] && (
								<AdminTable
									columns={this.columnsTableOrganizations}
									dataSource={this.state.filteredData[this.state.activeTabKey].map((x: any) => {
										x.key = x.id;
										return x;
									})}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
											/>
										)
									}}
									scroll={{ x: 1200, y: "max-content" }}
								/>
							)}
						</Skeleton>
					</Col>
				</Row>
			</>
		);
	}

	onTypeChange = (element: any) => {
		this.setState({ checkedType: element.target.value });
	};

	getGraphTabs = () => {
		const { t: translate } = this.props;

		const GetPieChart = (data: any, titleSuffix: string, labels: any, colors: any) => {
			if (!data) return null;
			const titleName = translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS.${titleSuffix}`);

			if (_.sum(data) === 0) {
				return (
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._NO_DATA`)}
					/>
				);
			}

			const chartData = {
				labels: labels.map((label: string, index: number) => {
					return `${formatTotal(data[index])} ${label}`;
				}),
				datasets: [
					{
						backgroundColor: colors,
						data: data
					}
				]
			};

			return (
				<Space>
					<Pie
						data={chartData}
						options={{
							legend: {
								display: false
							},
							title: {
								display: true,
								text: `${titleName}  ${formatTotal(_.sum(data))}`,
								fontSize: 16,
								fontColor: "#2d3748"
							}
						}}
					/>
					<Divider type="vertical" />
				</Space>
			);
		};

		const GetLegend = (data: any, labels: any, colors: any) => {
			return (
				<>
					{labels.map((item: any, index: number) => {
						return (
							<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="pb-16" key={item}>
								<Row align="middle">
									<Col span="3">
										<Icon type="ri-checkbox-blank-circle-fill" style={{ color: colors[index] }} />
									</Col>
									<Col>
										<Space direction="vertical" size={0}>
											<Text fontSize="14" lineHeight="22">
												{translate(`${item}`)}
											</Text>
											<Text
												fontSize="16"
												lineHeight="24"
												className="color-gray-9"
												wheight="semibold"
											>
												{formatTotal(data[index])}
											</Text>
										</Space>
									</Col>
								</Row>
							</Col>
						);
					})}
				</>
			);
		};

		const getTabNationality = () => {
			const colors = ["#F5222D", "#2063E3", "#A0D911", "#722ED1", "#FAAD14"];
			const labels = [
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._NATIONALITY._ORG_ALBANIA`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._NATIONALITY._ORG_KOSOVO`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._NATIONALITY._ORG_DIASPORA`)
			];

			return (
				<Row>
					<Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
						<Row justify="center">
							{GetPieChart(
								this.state.cardTotalInstitutionsByCountry,
								"_NATIONALITY._TOTAL",
								labels,
								colors
							)}
						</Row>
					</Col>
					<Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16} className="pt-24">
						<Row>{GetLegend(this.state.cardTotalInstitutionsByCountry, labels, colors)}</Row>
					</Col>
				</Row>
			);
		};

		const getTabType = () => {
			const colors = ["#A0D911", "#2063E3"];
			const labels = [
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._SECTOR._ORG_PUBLIC`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._SECTOR._ORG_PRIVATE`)
			];
			const data = [
				_.sum(this.state.cardTotalPublicInstitutionsByCountry),
				_.sum(this.state.cardTotalPrivateInstitutionsByCountry)
			];

			return (
				<Row>
					<Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
						<Row justify="center">{GetPieChart(data, "_TYPE._TOTAL", labels, colors)}</Row>
					</Col>
					<Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16} className="pt-24">
						<Row>{GetLegend(data, labels, colors)}</Row>
					</Col>
				</Row>
			);
		};

		const getTabSector = () => {
			const colors = ["#2063E3", "#001C70", "#A0D911", "#FA8C16"];
			const labels = [
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._TYPE._ORG_PRE_UNI`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._TYPE._ORG_UNI`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._TYPE._ORG_INDIVID`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._TYPE._ORG_COORP`)
			];
			const data = [
				_.sum(this.state.cardTotalPreUniInstitutionsByCountry),
				_.sum(this.state.cardTotalUniInstitutionsByCountry),
				_.sum(this.state.cardTotalIndividInstitutionsByCountry),
				_.sum(this.state.cardTotalInstInstitutionsByCountry)
			];

			return (
				<Row>
					<Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
						<Row justify="center">{GetPieChart(data, "_TYPE._TOTAL", labels, colors)}</Row>
					</Col>
					<Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16} className="pt-24">
						<Row>{GetLegend(data, labels, colors)}</Row>
					</Col>
				</Row>
			);
		};

		const getTabUsers = () => {
			const colors = ["#FA8C16", "#2063E3", "#A0D911"];
			const labels = [
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._USERS._TEACHERS`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._USERS._STUDENTS`),
				translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS._USERS._PARENTS`)
			];
			const data = [
				_.sum(this.state.cardTotalTeachersByCountry),
				_.sum(this.state.cardTotalStudentsByCountry),
				this.state.cardTotalParents
			];

			return (
				<Row>
					<Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
						<Row justify="center">{GetPieChart(data, "_USERS._TOTAL", labels, colors)}</Row>
					</Col>
					<Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16} className="pt-24">
						<Row>{GetLegend(data, labels, colors)}</Row>
					</Col>
				</Row>
			);
		};

		const getTabTitle = (type: string): ReactNode => {
			return (
				<Text fontSize="16" lineHeight="24" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._GRAPH_TABS.${type}._TITLE`)}
				</Text>
			);
		};

		return (
			<Card bordered={false} className="box_shadow">
				<Skeleton loading={this.state.loading || this.state.cardTotalParentsLoading}>
					<Tabs
						defaultActiveKey={this.state.activeGraphTabKey}
						onChange={value => this.setState({ activeGraphTabKey: value })}
					>
						<TabPane tab={getTabTitle("_NATIONALITY")} key={0}>
							{getTabNationality()}
						</TabPane>
						<TabPane tab={getTabTitle("_TYPE")} key={1}>
							{getTabType()}
						</TabPane>
						<TabPane tab={getTabTitle("_SECTOR")} key={2}>
							{getTabSector()}
						</TabPane>
						<TabPane tab={getTabTitle("_USERS")} key={3}>
							{getTabUsers()}
						</TabPane>
					</Tabs>
				</Skeleton>
			</Card>
		);
	};

	onCopy = (textCopied: any) => {
		const { t: translate } = this.props;
		message.success(
			translate(`${TRANSLATION_BASE_PATH}._COPY_CLIPBOARD`, {
				code: `${textCopied}`
			})
		);
	};

	onOk = () => {
		this.setState({ showModalInfoDNS: false, certificateCreated: null });
	};

	setDomainChangeRequest = (values: any, organizationForm: any) => {
		this.toggleEditDomainModal(false);
		organizationForm.setFieldValue(
			"domain",
			isReservedDomain(values.domain)
				? values.domain
				: getSubdomain(values.domain) === ""
				? `www.${values.domain}`
				: values.domain
		);
	};

	getEditDomainModalForm = (organizationForm: any) => {
		const { t: translate } = this.props;
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._TITLE`);
		const formSubtitle = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._SUBTITLE`, {
			orgName: organizationForm.values.name
		});
		const formBtnSave = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._SAVE`);
		const cancel = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._CANCEL`);

		let submitFormHandler: () => Promise<any>;

		return (
			<Modal
				title={formTitle}
				visible={this.state.editDomainModal}
				destroyOnClose={true}
				onCancel={() => this.toggleEditDomainModal(false)}
				footer={[
					<Button key="back" onClick={() => this.toggleEditDomainModal(false)}>
						{cancel}
					</Button>,
					<Button
						key="submit"
						type="primary"
						form="domainForm"
						htmlType="submit"
						onClick={() => submitFormHandler()}
					>
						{formBtnSave}
					</Button>
				]}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorDomain(translate)}
					initialValues={{
						domain: organizationForm.values.domain
					}}
					onSubmit={(values: any) => this.setDomainChangeRequest(values, organizationForm)}
					validateOnMount
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} id="domainForm" autoComplete="off">
								<Row gutter={24} className="pb-24">
									<Col span="24">
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{formSubtitle}
										</Text>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span="24">
										<Input addonBefore={<Icon type="ri-link"></Icon>} name="domain" />
									</Col>
								</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	};

	render() {
		const { t: translate } = this.props;

		const title = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_6");
		const subTitle1 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const subTitle2 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_6_LONG");
		const albania = translate(`${TRANSLATION_BASE_PATH}._ALBANIA`);
		const kosovo = translate(`${TRANSLATION_BASE_PATH}._KOSOVO`);
		const btnTitle = translate(`${TRANSLATION_BASE_PATH}._BTN_ADD`);

		let submitHandler: any;

		const CreateEditForm = () => {
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.createOrganization}
					validateOnMount
				>
					{formik => {
						const { handleSubmit, submitForm, setFieldValue } = formik;
						submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row>
									<Col span="24">
										<Input name="name" label={translate(`${TRANSLATION_BASE_PATH}._NAME`)} />
									</Col>
								</Row>
								<Row className="pt-4">
									<Col span="24">
										<Input
											addonBefore={<Icon type="ri-key-line" />}
											disabled
											value={this.state.formInitialValues.code}
											name="code"
											label={translate(`${TRANSLATION_BASE_PATH}._CODE`)}
										/>
									</Col>
								</Row>
								<Row className="pt-4">
									<Col span="24">
										<Input
											disabled
											value={this.state.formInitialValues.nationality}
											name="nationality"
											label={translate(`${TRANSLATION_BASE_PATH}._NATIONALITY`)}
										/>
									</Col>
								</Row>
								<Row className="pt-4">
									<Radio
										name="type"
										value={formik.values.type}
										label={translate(`${TRANSLATION_BASE_PATH}._TYPE`)}
									>
										{TYPES.map((item: any, index: number) => {
											return (
												<Col span="24" key={index}>
													<RadioElement withOutStyle={true} value={item}>
														<Text fontSize="14" lineHeight="22" className="color-gray-8">
															{translate(`${TRANSLATION_BASE_PATH}._TYPES._${item}`)}
														</Text>
													</RadioElement>
												</Col>
											);
										})}
									</Radio>
								</Row>
								<Row className="pt-4">
									{FormFieldLabelWithTooltip(
										"_CONTENT_TYPE",
										translate(`${TRANSLATION_BASE_PATH}._CONTENT_TYPES_TOOLTIP`)
									)}
								</Row>
								<Row>
									<Radio name="contentType" value={formik.values.contentType}>
										{CONTENT_TYPES.map((item: any, index: number) => {
											return (
												<Col span="24" key={index}>
													<RadioElement withOutStyle={true} value={item}>
														<Text fontSize="14" lineHeight="22" className="color-gray-8">
															{translate(
																`${TRANSLATION_BASE_PATH}._CONTENT_TYPES._${item}`
															)}
														</Text>
													</RadioElement>
												</Col>
											);
										})}
									</Radio>
								</Row>
								<Row className="pt-4">
									{FormFieldLabelWithTooltip(
										"_SECTOR",
										translate(`${TRANSLATION_BASE_PATH}._SECTOR_TOOLTIP`)
									)}
								</Row>
								<Row>
									<Radio name="sector" value={formik.values.sector}>
										{SECTORS.map((item: any, index: number) => {
											return (
												<RadioElement key={index} withOutStyle={true} value={item}>
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{translate(`${TRANSLATION_BASE_PATH}._SECTORS._${item}`)}
													</Text>
												</RadioElement>
											);
										})}
									</Radio>
								</Row>
								{formik.values.sector === "PRIVATE" && (
									<>
										{this.getEditDomainModalForm(formik)}
										<Row className="pt-4">
											{FormFieldLabelWithTooltip(
												"_DOMAIN",
												translate(`${TRANSLATION_BASE_PATH}._DOMAIN_TOOLTIP`)
											)}
										</Row>
										<Row>
											<Input
												addonBefore={<Icon type="ri-link"></Icon>}
												name="domain"
												disabled={true}
												addonAfter={
													<span
														className={"cursor_pointer color-blue-6"}
														onClick={() => this.toggleEditDomainModal(true)}
													>
														{translate(`${TRANSLATION_BASE_PATH}._CHANGE_FIELD`)}
													</span>
												}
											/>
										</Row>
										<Row className="pt-4" justify="space-between">
											<Col span="11">
												<Row>
													{FormFieldLabelWithTooltip(
														"_NO_USERS",
														translate(`${TRANSLATION_BASE_PATH}._NO_USERS_TOOLTIP`)
													)}
												</Row>
												<Row>
													<Input name="numberOfUsers" />
												</Row>
											</Col>
											<Col span="11">
												<Row>
													{FormFieldLabelWithTooltip(
														"_PRICE",
														translate(`${TRANSLATION_BASE_PATH}._PRICE_TOOLTIP`)
													)}
												</Row>
												<Row>
													<Input addonAfter="ALL" name="price" />
												</Row>
											</Col>
										</Row>
									</>
								)}
								{formik.values.sector === "PUBLIC" && (
									<>
										<Row>
											{FormFieldLabelWithTooltip(
												"_RATIO",
												translate(`${TRANSLATION_BASE_PATH}._RATIO_TOOLTIP`)
											)}
										</Row>
										<Row className="pt-4" justify="space-between">
											<Col span="15">
												<Slider
													min={0}
													max={100}
													value={formik.values.ratio}
													onChange={(value: any) => {
														setFieldValue("ratio", value);
													}}
												/>
											</Col>
											<Col span="8">
												<Space>
													<Input
														addonAfter="%"
														name="ratio"
														onChange={(value: any) => {
															setFieldValue("ratio", value);
														}}
													/>
												</Space>
											</Col>
										</Row>
									</>
								)}
								<Row className="pt-4">
									<Col span="24">
										<Input name="ownerEmail" label={translate(`${TRANSLATION_BASE_PATH}._EMAIL`)} />
									</Col>
								</Row>

								{formik.values.sector === "PUBLIC" && (
									<>
										<Row className="pt-8" justify="space-between" style={{ marginTop: "20px" }}>
											<Col xs={12}>
												<Text fontSize="14" lineHeight="22" wheight="semibold">
													{translate("_MARKETPLACE._SHOW_ON_MARKETPLACE")}
												</Text>
											</Col>
											<Col xs={8}>
												<p
													style={{
														fontSize: "14",
														marginRight: "12px",
														display: "inline",
														color: "#98A9C0",
														visibility: formik.values.featured === true ? "visible" : "hidden"
													}}
												>
													{translate("_MARKETPLACE._ACTIVE")}
												</p>

												<Switch
													onChange={(value: any) => {
														setFieldValue("featured", value);
													}}
													defaultChecked={
														formik.values.featured === true
													}
												/>
											</Col>
										</Row>
										{formik.values.featured === true && (
											<Row className="pt-8" justify="start" style={{ marginTop: "20px" }}>
												<Col span="16">
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "start"
														}}
													>
														<p
															style={{
																fontSize: "14",
																display: "inline",
																color: "#718096"
															}}
														>
															{translate("_MARKETPLACE._UPLOAD_IMAGE")}
														</p>

														<MarketplaceUpload
															default={this.state.organization?.default}
															domain={this.state.organization?.domain}
															organizationId={this.state.formInitialValues.id}
															onUpload={(filePath: string) =>
																(formik.values.marketplaceLogo = filePath)
															}
															onDelete={() => (formik.values.marketplaceLogo = null)}
														/>
													</div>
												</Col>
												<Col>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "start"
														}}
													>
														<p
															style={{
																fontSize: "14",
																display: "inline",
																color: "#718096"
															}}
														>
															{translate("_MARKETPLACE._SPECIFY_SORT_ORDER")}
														</p>
														<StyledNumberInput
															min={1}
															defaultValue={formik.values?.featuredSort}
															onChange={(value: any) => {
																setFieldValue("featuredSort", value);
															}}
														/>
													</div>
												</Col>
											</Row>
										)}
									</>
								)}
							</form>
						);
					}}
				</Formik>
			);
		};

		return (
			<>
				<SectionTitle title={title} subtitle={[subTitle1, subTitle2]} subtitleUrl={[DASHBOARD_PATH]} />
				<CreateEditDrawer
					title={btnTitle}
					onClose={this.toggleDrawer}
					onSave={() => submitHandler()}
					visible={this.state.drawerShown}
					submitting={this.state.submitting}
					form={CreateEditForm}
					width={window.innerWidth >= 576 ? 350 : window.innerWidth}
				/>
				<SectionContent>
					<Row align="middle" className="pb-24">
						<Col span="24">{this.getGraphTabs()}</Col>
					</Row>
					<Row>
						<Col span="24">
							<Tabs
								defaultActiveKey={this.state.activeTabKey}
								onChange={value => this.setState({ activeTabKey: value })}
							>
								<TabPane
									tab={
										<Space>
											<Image src={AlFlag} />
											{albania}
										</Space>
									}
									key={0}
								>
									{this.getTabContent(0)}
								</TabPane>
								<TabPane
									tab={
										<Space>
											<Image src={KsFlag} />
											{kosovo}
										</Space>
									}
									key={1}
								>
									{this.getTabContent(1)}
								</TabPane>
							</Tabs>
						</Col>
					</Row>
				</SectionContent>
				<ModalInfoDNS
					onOk={this.onOk}
					certificateCreated={this.state.certificateCreated}
					showModal={this.state.showModalInfoDNS}
				/>
			</>
		);
	}
}

const Organizations = withTranslation()(OrganizationComponent);
export default Organizations;


const StyledNumberInput = styled(InputNumber)`
	height: 35px;
	max-width: 120px;
	border-radius: 5px;
`;

