import moment from "moment";
import * as Yup from "yup";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._BUILD_MATERIALS._LESSON_MATERIAL";

export const formValidator = (translate: (key: string) => string) => {
	const regex = new RegExp("^.*(youtu.be/|v/|u/w/|embed/|watch?v=|&v=|.?v=)([^#&?]*).*");
	return Yup.object({
		name: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		description: Yup.string().nullable(),
		path: Yup.string()
			.nullable()
			.when("contentType", {
				is: value => {
					return value === "file";
				},
				then: Yup.string()
					.trim()
					.required(translate(`${TRANSLATION_BASE_PATH}._ONE_FILE_REQUIRED`))
			}),
		videoUrl: Yup.string()
			.nullable()
			.when("contentType", {
				is: value => {
					return value === "url";
				},
				then: Yup.string()
					.trim()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.matches(regex, translate(`${TRANSLATION_BASE_PATH}._NOT_YOUTUBE_LINK`))
			}),
		endDate: Yup.string()
			.trim()
			.nullable()
			.notRequired()
			.test("is-valid-date", translate(`${TRANSLATION_BASE_PATH}._DUE_DATE_IN_THE_PAST`), function (value) {
				return (
					value === undefined ||
					(moment(value, "YYYY-MM-DD HH:mm").isValid() === true &&
						moment(value, "YYYY-MM-DD HH:mm") >=
						moment()
							.seconds(0)
							.milliseconds(0))
				);
			})
	});
};
