import React, { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import {Empty, Menu, Radio, Skeleton, Space} from "antd";
import {Link} from "react-scroll";
import _ from "lodash";
import Text from "common/components/general/Text";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import SubjectPlanTreeService from "services/administration/subjectPlanTree.service";
import LessonDto from "dtos/administration/lesson.dto";
import SubjectPlanTreeDto from "dtos/administration/subjectPlanTree.dto";
import {TeacherLessonList} from "common/components/lessonList/TeacherLessonList";
import TagService from "services/administration/tag.services";
import { SUBJECTS_PATH } from "scenes/subjects/index";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";
import {LESSON_PATH} from "scenes/subjects/scenes/materialItems/scenes/lessonMaterial/LessonMaterial";

const TRANSLATION_BASE_PATH = "_COURSE._COURSE_DETAILS._SUBJECT_PLAN";

class SubjectPlanComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			rootLessonTags: [],
			lessonTagGroup: [],
			tabActiceKey: "0",
			loading: false,
			classroom: null,
			lessons: [],
			lessonsFinished: []
		};
		this.loadCourseItem = this.loadCourseItem.bind(this);
		this.retriveLessonsFromTree = this.retriveLessonsFromTree.bind(this);
	}

	componentDidMount() {
		this.loadCourseItem();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if ((prevState.classroom && prevState.classroom.id) !== (this.state.classroom && this.state.classroom.id))
			this.loadCourseItem();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if ((nextProps.classroom && nextProps.classroom.id) !== (prevState.classroom && prevState.classroom.id))
			return { classroom: nextProps.classroom };
		return null;
	}

	loadCourseItem = async () => {
		this.setState({loading: true});

		const rootLessonTags: any = await new TagService().getAllRootTagsforLessons();
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(rootLessonTags[ this.state.tabActiceKey ]?.id, this.state.classroom?.id);
		const subjectPlanTree: SubjectPlanTreeDto | null = !this.state.classroom.SubjectPlan
			? null
			: await new SubjectPlanTreeService().getSubjectPlanTreeBySubjectId(this.state.classroom.SubjectPlan.id);
		const lessons: LessonDto[] | null = this.retriveLessonsFromTree(subjectPlanTree);
		const tabActiceKey = this.props.groupedBy && rootLessonTags.findIndex((tag: any) => tag.name === this.props.groupedBy);

		this.setState({
			rootLessonTags: rootLessonTags, lessonTagGroup: _(lessonTagGroup).orderBy((x: any) => Number(x.priority), "asc").value(),
			tabActiceKey: tabActiceKey && tabActiceKey > -1 ? tabActiceKey : "0",
			lessons: lessons,
			loading: false,
			lessonsFinished: (this.props.enrollmentObject && this.props.enrollmentObject.Lessons) ?? []
		}, () => this.fetchAllSubGroupsLessonTeacher(tabActiceKey && tabActiceKey > -1 ? tabActiceKey : "0"));
	};

	retriveLessonsFromTree = (subjectPlanTree: SubjectPlanTreeDto | null): LessonDto[] | null => {
		if (!subjectPlanTree) return null;
		const subjectPlanNode: SubjectPlanTreeDto[] = [];
		subjectPlanNode.push(subjectPlanTree);
		const lesson: LessonDto[] = [];
		while (subjectPlanNode.length !== 0) {
			const node: any = subjectPlanNode.pop();
			if (node.children.length !== 0) subjectPlanNode.push(...node.children);
			lesson.push(...node.lessons);
		}
		return lesson;
	};

	fetchAllSubGroupsLessonTeacher = async (activeKey: any) => {
		const lessonTagGroup =
			await new TagService().getAllLessonClassroomTags(this.state.rootLessonTags[activeKey]?.id, this.state.classroom?.id);
		return this.setState({
			lessonTagGroup: _(lessonTagGroup).orderBy((x: any) => Number(x.priority), "asc").value(),
			tabActiceKey: activeKey
		});
	}

	groupingOptionsToSelect = (): React.ReactNode => {
		return (
			<Radio.Group defaultValue={Number(this.state.tabActiceKey)} onChange={(e: any) => this.fetchAllSubGroupsLessonTeacher(e.target.value.toString())}>
				{this.state.rootLessonTags.map((lessonTag: any, index: number) => (
					<Radio.Button key={index} value={index}>{lessonTag.name}</Radio.Button>
				))}
			</Radio.Group>
		);
	}

	getNoDataComponent = (): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row justify="center" gutter={[16, 24]} className="p-24">
				<Col span={24}>
					<Empty description={false}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._EMPTY_DESCRIPTION`)}
						</Text>
					</Empty>
				</Col>
			</Row>
		);
	};

	getContentMenu = (groupChildrenTag: any[]) => {
		const { t: translate } = this.props;
		return (
			<Space direction="vertical" className="full__width" size={6}>
				<Row className="pr-40 pl-48">
					<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._CONTENT`)}
					</Text>
				</Row>
				<Row className="pr-40 pl-48">
					{
						groupChildrenTag && groupChildrenTag.length > 0 && (
							<Menu mode="inline" inlineIndent={0} inlineCollapsed={false} defaultSelectedKeys={["0"]}
								className="background-color-gray-2 courseBuilder__subjectPlanCreator-menuContent">
								{groupChildrenTag.map((childrenTag: any, indexChild: any) => {
									return (
										<Menu.Item key={indexChild} className="background-color-gray-2">
											<Link to={childrenTag.name} spy={true} smooth={true} className="background-color-gray-2"> 
												{childrenTag.name}
											</Link>
										</Menu.Item>
									);
								})}
							</Menu>
						)
					}
				</Row>
			</Space>
		);
	};

	onClickItem = (item: any) => {
		const selectedTab = this.state.rootLessonTags[ this.state.tabActiceKey ];
		
		const virtualClass ="?virtualClass=true" + (selectedTab ? "&groupedBy=" + selectedTab.name : "") ;
		return this.props.history.push(
			`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${item}${virtualClass}`
		);
	}

	render() {
		return (
			<>
				{this.state.lessons && this.state.lessons.length > 0 && (
					<Row justify="center" className="pl-48 pt-16 pb-24">
						<Col span={24}>
							{this.groupingOptionsToSelect()}
						</Col>
					</Row>
				)}
				<Row justify="start">
					<Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4} className="mr-16 courseBuilder__overflow-menu">
						{this.state.lessons && this.state.lessons.length > 0 && this.getContentMenu(this.state.lessonTagGroup)}
					</Col>
					<Col xs={23} sm={22} md={20} lg={16} xl={16} xxl={16}>
						<Skeleton active loading={this.state.loading}>
							{!this.state.lessons || this.state.lessons.length === 0 ? (
								this.getNoDataComponent()
							) : (
								<TeacherLessonList
									lessonGroups={this.state.lessonTagGroup}
									lessons={this.state.lessons}
									classroomId={this.props.classroom.id}
									canBeModified={false}
									enrolledStudents={this.props.enrolledStudents?.length}
									onClickItem={this.onClickItem}/>
							)}
						</Skeleton>
					</Col>
				</Row>
			</>
		);
	}
}

const SubjectPlan = withTranslation()(SubjectPlanComponent);
export default SubjectPlan;
