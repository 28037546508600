import React, { ReactNode } from "react";
import { Row as AntdRow } from "antd";
import { Gutter } from "antd/lib/grid/row";

declare const RowJustify: ["start", "end", "center", "space-around", "space-between"];

export interface RowProps {
	children: ReactNode;
	gutter?: Gutter | [Gutter, Gutter];
	justify?: typeof RowJustify[number];
	className?: string;
}

const Row: React.FC<RowProps> = props => <AntdRow {...props}>{props.children}</AntdRow>;

export default Row;
