import React, { ReactNode } from "react";


interface SectionContentProps{
	children: ReactNode
}

export const SectionContent = (props: SectionContentProps) => {
	return (
		<div className="m-24">
			{props.children}
		</div>
	);
};