import { Avatar, Card, Col, Dropdown, Menu, Popconfirm, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import { InviteUsers } from "../../../../main/scenes/organization/components/orgContent/usersTab/inviteUsers/InviteUsers";
import Icon from "../../../../../common/components/general/Icon";
import InvitationService from "../../../../../services/administration/invitation.service";
import _ from "lodash";
import { UserAvatar } from "../../../../../common/components/general/UserAvatar";
import { UserRole } from "../../../../main/scenes/organization/components/orgContent/usersTab/userRole/UserRole";
import { showMessage } from "../../../../../common/utils/Notification";
import { withTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT._USERS_TAB";

const InviteUserSection = (props: any) => {
	const { t: translate } = props;

	const [drawerVisible, setDrawerVisible] = useState(false);
	const [users, setUsers] = useState<any>([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const loadUsers = () => {
		setLoadingUsers(true);
		new InvitationService()
			.getOrganizationStaffInvites()
			.then((invites: any) => {
				return invites.map((item: any) => {
					return {
						id: item.id,
						firstName: item.email,
						lastName: null,
						email: item.email,
						role: item.invitationType == "ADMIN_ORGANIZATION" ? "_ADMIN" : "_TEACHER",
						type: "INVITE",
						updatedAt: item.updatedAt,
						UserRoleId: null,
						UserId: null,
						priority: item.invitationType == "ADMIN_ORGANIZATION" ? 2 : 100,
						status: "ACTIVE"
					};
				});
			})
			.then((invites: any) => {
				const totalRecords = _.orderBy(invites, ["priority", "firstName", "lastName"], ["asc"]);
				setUsers(totalRecords);
				// props.updateTotalUsers(totalRecords);
				// setUsers(totalRecords);
			})
			.finally(() => {
				return setLoadingUsers(false);
			});
	};
	const ResendInvite = (item: any) => {
		return new InvitationService().resendUserInvitation(item.id).then(() => {
			return showMessage(translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._INVITATION_RESEND_SUCCEEDED`));
		});
	};

	const DeleteInvite = (item: any) => {
		return new InvitationService().deleteInvite(item.id).then(() => {
			loadUsers();
			return showMessage(translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._INVITATION_DELETE_SUCCEEDED`));
		});
	};

	const RenderInviteMenu = (item: any) => {
		return (
			<Menu>
				<Menu.Item key="0">
					<Popconfirm
						title={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._RESEND_CONFIRM_MESSAGE`)}
						onConfirm={() => ResendInvite(item)}
						okText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._RESEND`)}
						cancelText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._CANCEL`)}
					>
						<Space>
							<Icon type="ri-send-plane-line" />
							{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._RESEND`)}
						</Space>
					</Popconfirm>
				</Menu.Item>
				<Menu.Item key="1">
					<Popconfirm
						title={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE_CONFIRM_MESSAGE`)}
						onConfirm={() => DeleteInvite(item)}
						okText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE`)}
						cancelText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._CANCEL`)}
					>
						<Space>
							<Icon type="ri-delete-bin-7-line" />
							{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE`)}
						</Space>
					</Popconfirm>
				</Menu.Item>
			</Menu>
		);
	};

	const ShowModal = (item: any) => {
		setModalVisible(!modalVisible);
		// setItem(item);
	};

	const getUsers = (type: string) => {
		return users.filter((x: any) => {
			return x.status === type;
		});
	};

	useEffect(() => {
		loadUsers();
	}, []);

	const columns = [
		{
			title: "",
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Space size="middle">
						{row.type === "USER" && (
							<UserAvatar userId={row.id} firstName={row.firstName} lastName={row.lastName} />
						)}
						{row.type === "INVITE" && (
							<Avatar shape="square">
								<Icon type="ri-time-line" />
							</Avatar>
						)}
						{row.type === "USER" ? row.firstName + " " + row.lastName : row.email}
						{row.role !== "_TEACHER" && <UserRole role={row.role} />}
					</Space>
				);
			}
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			render: (text: any, row: any) => {
				return (
					<>
						<Row justify="end">
							<Dropdown.Button
								buttonsRender={() => [
									<></>,
									<Button
										type="default"
										shape="circle"
										className="materialItem__layout materialItem__button-circle color-gray-8"
									>
										<Icon type="ri-more-2-line" />
									</Button>
								]}
								trigger={["click"]}
								overlay={RenderInviteMenu(row)}
								icon={<Icon type="ri-more-2-line"></Icon>}
							/>
						</Row>
					</>
				);
			}
		}
	];

	const columnsSmallScreen = [
		{
			title: "",
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Row justify="space-between">
						{row.type === "USER" ? row.firstName + " " + row.lastName : row.email}
						<Dropdown.Button
							buttonsRender={() => [
								<></>,
								<Button
									type="default"
									shape="circle"
									className="materialItem__layout materialItem__button-circle color-gray-8"
								>
									<Icon type="ri-more-2-line" />
								</Button>
							]}
							trigger={["click"]}
							overlay={RenderInviteMenu(row)}
							icon={<Icon type="ri-more-2-line"></Icon>}
						/>
					</Row>
				);
			}
		}
	];

	return (
		<Row className="pb-16">
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Card>
					<Row className="pb-16">
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Row justify="space-between">
								<Text fontSize="20" lineHeight="28" className="color-gray-9">
									{"Shtoni perdorues"}
								</Text>
								<Button type="primary" onClick={() => setDrawerVisible(true)}>
									<Space>
										<Icon type="ri-add-line" />
										Shto
									</Space>
								</Button>
							</Row>
							<InviteUsers
								visible={drawerVisible}
								onClose={() => setDrawerVisible(false)}
								onRefresh={() => loadUsers()}
								currentUsers={getUsers("ACTIVE")}
								isParentOrganization={true}
								isNewUser={true}
							/>
							{getUsers("ACTIVE").length !== 0 ? (
								<Row>
									<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
										<Table dataSource={getUsers("ACTIVE")} columns={columns} showHeader={false} />
									</Col>
									<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
										<Table
											dataSource={getUsers("ACTIVE")}
											columns={columnsSmallScreen}
											showHeader={false}
										/>
									</Col>
								</Row>
							) : (
								<></>
							)}
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
};

const InviteNewUser = withTranslation()(InviteUserSection);
export default InviteNewUser;
