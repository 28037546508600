import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AnalyticsUserService from "../../../../services/analytics/user.service";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { InviteBanner } from "./components/inviteBanner/InviteBanner";
import { OrgContent } from "./components/orgContent/OrgContent";
import { StatCards } from "./components/statCards/StatCards";
import { SubHeader } from "./components/subHeader/SubHeader";
import { WelcomePopup } from "./components/welcomePopup/WelcomePopup";

export const ORGANIZATION_PATH = "organization";
const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION";

class OrganizationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			refresh: 0,
			bannerVisible: false,
			currentUsers: [],
			totalUsers: 0,
			totalAdmins: 0,
			totalTeachers: 0,
			totalStudents: 0,
			totalVirtualClasses: 0,
			totalAsyncClasses: 0,
			showWelcomePopup: false,
			organization: null
		};
	}

	componentDidMount = () => {
		this.setState({
			showWelcomePopup: sessionStorage.getItem("showWelcomePopup")
		});
		sessionStorage.removeItem("showWelcomePopup");
		new AnalyticsUserService().countAllStudentsByOrganization().then((result: any) => {
			this.setState({
				totalStudents: result
			});
		});
	};

	updateOrganization = (organization: any) => {
		this.setState({
			organization: organization
		});
	};

	refreshUsers = () => {
		this.setState({
			refresh: this.state.refresh + 1
		});
	};

	updateTotalUsers = (users: any) => {
		const bannerVisible = users.length === 1;
		const totalUsers = users.filter((x: any) => {
			return x.type !== "INVITE";
		}).length;
		const totalAdmins = users.filter((x: any) => {
			return x.role === "_ADMIN" && x.type !== "INVITE";
		}).length;
		const totalTeachers = users.filter((x: any) => {
			return x.role === "_TEACHER" && x.type !== "INVITE";
		}).length;

		this.setState({
			currentUsers: users,
			bannerVisible: bannerVisible,
			totalUsers: totalUsers,
			totalAdmins: totalAdmins,
			totalTeachers: totalTeachers
		});
	};

	updateTotalAsyncClasses = (count: number) => {
		this.setState({
			totalAsyncClasses: count
		});
	};

	updateTotalVirtualClasses = (count: number) => {
		this.setState({
			totalVirtualClasses: count
		});
	};

	render() {
		const { t: translate } = this.props;
		const subtitle = translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`);

		return (
			<>
				<SectionTitle subtitle={subtitle} />
				<SubHeader updateOrganization={this.updateOrganization} />
				<InviteBanner
					currentUsers={this.state.currentUsers}
					refreshUsers={this.refreshUsers}
					visible={this.state.bannerVisible}
				/>
				<StatCards
					totalUsers={this.state.totalUsers + +this.state.totalStudents}
					totalAdmins={this.state.totalAdmins}
					totalTeachers={this.state.totalTeachers}
					totalStudents={this.state.totalStudents}
					totalVirtualClasses={this.state.totalVirtualClasses}
					totalAsyncClasses={this.state.totalAsyncClasses}
				/>
				<OrgContent
					refresh={this.state.refresh}
					updateTotalUsers={this.updateTotalUsers}
					updateTotalAsyncClasses={this.updateTotalAsyncClasses}
					updateTotalVirtualClasses={this.updateTotalVirtualClasses}
				/>
				{this.state.organization && (
					<WelcomePopup
						visible={this.state.showWelcomePopup}
						onCancel={() => this.setState({ showWelcomePopup: false })}
						organization={this.state.organization}
						refreshUsers={this.refreshUsers}
					/>
				)}
			</>
		);
	}
}

const Organization = withTranslation()(OrganizationComponent);
export default Organization;
