import { Avatar, Button, Card, Col, Divider, Popconfirm, Row, Skeleton, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import { UserAvatar } from "common/components/general/UserAvatar";
import { PARENT_CHILD_SETTINGS_PATH } from "../../childSettings";
import { PARENT_CHILD_STATISTICS_PATH } from "../../childStatistics";
import { CircularIcon } from "common/components/general/CircularIcon";

interface ChildrenSectionProps {
	childImage: any;
	childrenItems: any;
	invites: any;
	parent: any;
	showAddChildModal: any;
	loading: boolean;
	deleteInvite: any;
	resendInvite: any;
}

const TRANSLATION_BASE_PATH = "_PARENT._DASHBOARD._CHILDREN_SECTION";

export const ChildrenSection = (props: ChildrenSectionProps) => {
	const { t: translate } = useTranslation();

	const getContent = () => {
		return (
			<>
				<Row>
					<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Row>
				<Row className="pt-8">
					<Text fontSize="14" lineHeight="20" className="color-gray-8">
						{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}
					</Text>
				</Row>
			</>
		);
	};

	const getImage = () => {
		return <Image src={props.childImage} />;
	};

	const getChildren = () => {
		return props.childrenItems?.map((child: any) => {
			return (
				<>
					<Row key={child.id} className="pl-24 pr-24">
						<Col xs={24} sm={20} md={18} lg={14} xl={12} xxl={12}>
							<Row>
								<Space>
									<Col>
										<UserAvatar {...child} userId={child.id} />
									</Col>
									<Col className="ml-16">
										<Row className="pb-4">
											<Text
												fontSize="14"
												lineHeight="22"
												wheight="semibold"
												className="color-gray-9"
											>
												{child.firstName + " " + child.lastName}
											</Text>
										</Row>
										<Row>
											<Text fontSize="12" lineHeight="20" className="color-gray-8">
												<Space>
													<Icon type="ri-mail-line" />
													{child.email ?? props.parent.email}
												</Space>
											</Text>
										</Row>
										<Row>
											<Text fontSize="12" lineHeight="20" className="color-gray-8">
												<Space>
													<Icon type="ri-account-pin-box-line" />
													{child.username}
												</Space>
											</Text>
										</Row>
									</Col>
								</Space>
							</Row>
						</Col>
						<Col xs={24} sm={4} md={6} lg={10} xl={12} xxl={12}>
							<Row justify="end" className="pt-24">
								{!child.over13 && (
									<>
										<Text
											fontSize="16"
											lineHeight="24"
											className="color-blue-6 mr-8 cursor_pointer"
											onClick={() => null}
										>
											<Link to={`${PARENT_CHILD_SETTINGS_PATH}/${child.id}`}>
												<Row>
													<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
														<Space>
															<Icon type="ri-settings-4-line" />
															{translate(`${TRANSLATION_BASE_PATH}._LINK_CONFIGURATIONS`)}
														</Space>
													</Col>
													<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
														<Icon type="ri-settings-4-line" />
													</Col>
												</Row>
											</Link>
										</Text>
										<Text fontSize="16" lineHeight="24" className="color-gray-4">
											|
										</Text>
									</>
								)}
								<Text
									fontSize="16"
									lineHeight="24"
									className="color-blue-6 ml-8 cursor_pointer"
									onClick={() => null}
								>
									<Link to={`${PARENT_CHILD_STATISTICS_PATH}/${child.id}`}>
										<Row>
											<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
												<Space>
													<Icon type="ri-eye-line" />
													{translate(`${TRANSLATION_BASE_PATH}._LINK_PROFILE`)}
												</Space>
											</Col>
											<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
												<Icon type="ri-eye-line" />
											</Col>
										</Row>
									</Link>
								</Text>
							</Row>
						</Col>
					</Row>
					<Row className="pl-24 pr-24">
						<Divider />
					</Row>
				</>
			);
		});
	};

	const getInvites = () => {
		return props.invites?.map((item: any) => {
			return (
				<>
					<Row key={item.email} className="pl-24 pr-24">
						<Col xs={24} sm={20} md={18} lg={14} xl={12} xxl={12}>
							<Row>
								<Space>
									<Col>
										<Avatar>?</Avatar>
									</Col>
									<Col className="ml-16">
										<Row className="pb-4">
											<Text
												fontSize="14"
												lineHeight="22"
												wheight="semibold"
												className="color-gray-9"
											>
												{item.email}
											</Text>
										</Row>
										<Row>
											<Text fontSize="12" lineHeight="20" className="color-gray-8">
												<Space>{translate(`${TRANSLATION_BASE_PATH}._INVITE_WAITING`)}</Space>
											</Text>
										</Row>
									</Col>
								</Space>
							</Row>
						</Col>
						<Col xs={24} sm={4} md={6} lg={10} xl={12} xxl={12}>
							<Row justify="end" className="pt-24">
								<Popconfirm
									title={translate(`${TRANSLATION_BASE_PATH}._DELETE_INVITE_CONFIRM_MESSAGE`)}
									onConfirm={() => props.deleteInvite(item.id)}
									okText={translate(`${TRANSLATION_BASE_PATH}._DELETE_INVITE`)}
									cancelText={translate(`${TRANSLATION_BASE_PATH}._POP_CONFIRM_CANCEL`)}
								>
									<Text fontSize="16" lineHeight="24" className="mr-8 cursor_pointer">
										<Row className="color-red-6">
											<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
												<Space>
													<Icon type="ri-delete-bin-line" />
													{translate(`${TRANSLATION_BASE_PATH}._DELETE_INVITE`)}
												</Space>
											</Col>
											<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
												<Icon type="ri-delete-bin-line" />
											</Col>
										</Row>
									</Text>
								</Popconfirm>
								<Text fontSize="16" lineHeight="24" className="color-gray-4">
									|
								</Text>
								<Popconfirm
									title={translate(`${TRANSLATION_BASE_PATH}._RESEND_INVITE_CONFIRM_MESSAGE`)}
									onConfirm={() => props.resendInvite(item)}
									okText={translate(`${TRANSLATION_BASE_PATH}._RESEND_INVITE`)}
									cancelText={translate(`${TRANSLATION_BASE_PATH}._POP_CONFIRM_CANCEL`)}
								>
									<Text fontSize="16" lineHeight="24" className="color-blue-6 ml-8 cursor_pointer">
										<Row>
											<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
												<Space>
													<Icon type="ri-send-plane-line" />
													{translate(`${TRANSLATION_BASE_PATH}._RESEND_INVITE`)}
												</Space>
											</Col>
											<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
												<Icon type="ri-send-plane-line" />
											</Col>
										</Row>
									</Text>
								</Popconfirm>
							</Row>
						</Col>
					</Row>
					<Row className="pl-24 pr-24">
						<Divider />
					</Row>
				</>
			);
		});
	};

	const ButtonAddChild = () => {
		return (
			<Button onClick={props.showAddChildModal} type="primary" size="middle">
				<Space>
					<Text fontSize="14" lineHeight="22">
						<Icon type="ri-add-line"></Icon>
					</Text>
					{translate(`${TRANSLATION_BASE_PATH}._BTN_ADD_CHILD`)}
				</Space>
			</Button>
		);
	};

	const NoChildrenEmptyState = () => {
		return (
			<Row className="p-24">
				<Col span="24">
					<Row className="pt-24">
						<Col span="24">
							<Row justify="center">
								<CircularIcon type="ri-user-add-line" />
							</Row>
						</Col>
					</Row>
					<Row className="pt-24">
						<Col span="24">
							<Row justify="center">
								<Text fontSize="24" lineHeight="32" className="color-gray-9" wheight="semibold">
									{translate(`${TRANSLATION_BASE_PATH}._NO_CHILD_EMPTY_STATE._TITLE`)}
								</Text>
							</Row>
						</Col>
					</Row>
					<Row className="pt-16">
						<Col span="24">
							<Row justify="center">
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{translate(`${TRANSLATION_BASE_PATH}._NO_CHILD_EMPTY_STATE._SUBTITLE`)}
								</Text>
							</Row>
						</Col>
					</Row>
					<Row className="pt-24">
						<Col span="24">
							<Row justify="center">{ButtonAddChild()}</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	};

	return (
		<>
			<Card title={null} className="parent__dashboard__card parent__dashboard__card__children">
				<Row>
					<Col xs={0} sm={0} md={18} lg={18} xl={18} xxl={20} className="pl-24 pr-24 pt-24">
						{getContent()}
					</Col>
					<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={4}>
						<Row justify="end">{getImage()}</Row>
					</Col>
					<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
						<Row justify="center">{getImage()}</Row>
					</Col>
					<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="pl-24 pr-24 pt-24 pb-16">
						{getContent()}
					</Col>
				</Row>
			</Card>
			<Card title={null} className="parent__dashboard__card parent__dashboard__card__children__content">
				<Skeleton loading={props.loading}>
					{(props.childrenItems?.length > 0 || props.invites?.length > 0) && (
						<>
							<Row className="pl-24 pr-24 pt-24">{ButtonAddChild()}</Row>
							<Row className="pl-24 pr-24">
								<Divider />
							</Row>
							{getChildren()}
							{getInvites()}
						</>
					)}
					{props.childrenItems?.length === 0 && props.invites?.length === 0 && NoChildrenEmptyState()}
				</Skeleton>
			</Card>
		</>
	);
};
