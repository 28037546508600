import React from "react";

import Text from "../../general/Text";

const FormItemError: React.FC = props => {
	return (
		<div className="form__item__error">
			<Text fontSize="14" lineHeight="22" className="color-red-6">
				{props.children}
			</Text>
		</div>
	);
};
export default FormItemError;
