import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_LOGIN._SIGNIN";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		password: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(30, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH`)),
		username: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.max(40, translate(`${TRANSLATION_BASE_PATH}._MAX_LENGTH_40`))
	});
