import {v4 as uuidv4} from "uuid";

export default class QuizUserResponseDto {
	readonly id!: string;
	readonly UserId!: string;
	readonly LessonId!: string;
	readonly answerMetaInfo?: string;

	constructor(data: {UserId: string; LessonId: string; answerMetaInfo?: string;[ key: string ]: any}) {
		this.id = uuidv4();
		Object.assign(this, data);
	}
}
