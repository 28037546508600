import React, { ReactNode, useEffect, useState } from "react";
import {Row, Col, Collapse, Space, Empty} from "antd";
import { useTranslation } from "react-i18next";
import {CaretRightOutlined} from "@ant-design/icons";
import {LessonItem} from "scenes/subjects/components/lessonItem/LessonItem";
import _ from "lodash";

const TRANSLATION_BASE_PATH = "_COMPONENTS._LESSON_LIST";

const { Panel } = Collapse;

interface TeacherCopyContentListProps {
	lessonGroups: any[];
	lessons: any[];
}

export const TeacherCopyContentList = (props: TeacherCopyContentListProps) => {
	const {t: translate} = useTranslation();
	const [activeKey, setActiveKey] = useState([1]);
	const [ungroupedLessons, setUngroupedLessons] = useState(Array);
	
	const changeActiveKey = (e: any) => {
		setActiveKey(e);
	};

	const getLessonItems = (lessonItems: any[]): ReactNode => {
		return (
			<>
				{lessonItems.map((lesson: any, index: number) => {
					return (
						<Row
							key={index}
							className={"item-list"}
							align="middle"
						>
							<Col span={24}>
								<LessonItem
									key={index}
									id={lesson.id}
									canBeModified={false}
									lessonClassroomTagId={lesson.lessonClassroomTagId}
									name={lesson.name}
									lessonType={lesson.lessonType}
									endDate={lesson.endDate}
									isActualLesson={false}
									textFontSize="14"
									textLineHeight="22"
									textWeight="semibold"
								/>
							</Col>
						</Row>
					);
				})}
			</>
		);
	};

	const getNoDataComponent = (): ReactNode => {
		return (
			<Row justify="center">
				<Col span={24}>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA`)}/>
				</Col>
			</Row>
		);
	};

	const getGroupCard = (childTag: any, index: number): ReactNode => {
		return (
			<Row key={index + new Date().toString()} justify="center">
				<Col span={24}>
					<Collapse
						bordered={false}
						className={"lessonList__course-view_teacher-copy-content-panel"}
						activeKey={activeKey}
						expandIcon={({isActive}) => (
							<CaretRightOutlined className="color-gray-8" rotate={isActive ? 90 : 0} />
						)}
						onChange={e => changeActiveKey(e)}
					>
						<Panel
							className={childTag.name}
							header={childTag.name}
							key={index + 1}
						>
							{childTag.lessons?.length > 0 ?
								getLessonItems(childTag.lessons) : getNoDataComponent()}
						</Panel>
					</Collapse>
				</Col>
			</Row>

		);
	};
	
	const calculateUnGroupedItems = (lessonGroups: any[], lessons: any[]) => {
		const groupedLessons = lessonGroups?.flatMap((group: any) => group.ClassroomTags[ 0 ].LessonClassroomTag);
		const ungroupedLessson = lessons?.filter((lesson: any) =>
			groupedLessons.findIndex((groupedLesson: any) => groupedLesson.LessonId === lesson.id) === -1);
		return _(ungroupedLessson)
			.orderBy((x: any) => x.name, "asc")
			.value();;
	};

	useEffect(() => {
		const oldActiveKey = [...activeKey];
		setActiveKey([...oldActiveKey, 1]);
		const ungroupLessons: any[] = calculateUnGroupedItems(props.lessonGroups, props.lessons);
		setUngroupedLessons(ungroupLessons);
	}, [props.lessonGroups, props.lessons]);

	return (
		<Space direction="vertical" className="full__width" size={18}>
			{ungroupedLessons?.length > 0 && getGroupCard({id: Date.now(), name: translate(`${TRANSLATION_BASE_PATH}._UNGROUP`), lessons: ungroupedLessons}, 0)}
			{props.lessonGroups?.map((childTag: any, index) => (
				getGroupCard({
					id: childTag.id, name: childTag.name,
					lessons: childTag.ClassroomTags[0].LessonClassroomTag.flatMap((lessonTag: any) => {
						return { ...lessonTag.Lesson, lessonClassroomTagId: lessonTag.id };
					}),
					classroomId: childTag.ClassroomTags[ 0 ].id
				}, ungroupedLessons?.length > 0 ? index + 1 : index)
			))}
		</Space>

	);
};

TeacherCopyContentList.defaultProps = {
	clickable: true
};
