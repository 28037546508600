import React from "react";
import { useTranslation } from "react-i18next";
import TutoringWelcome from "assets/images/digitalize-welcome.svg";
import Icon from "common/components/general/Icon";
import { Row, Col } from "antd";
import Button from "common/components/general/Button";
import Modal from "antd/lib/modal/Modal";
import Image from "common/components/general/Image";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT._WELCOME";

type Props = {
	next: () => void,
	onCancel: () => void,
}

export const Welcome = (props: Props) => {
	const { t: translate } = useTranslation();
	const { next, onCancel } = props;
	return (
		<Modal
			centered
			visible
			title=""
			footer=""
			width="100%"
			style={{ maxWidth: "420px" }}
			bodyStyle={{ padding: 0 }}
			onCancel={() => onCancel()}
		>
			<Image width="100%" src={TutoringWelcome} />
			<Row className="p-24">
				<Row className="digitalizeStudent_modal__welcome_title">{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</Row>
				<Row className="digitalizeStudent_modal__welcome_subtitle">{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}</Row>
				<Col span={24}>
					<Row align="middle" justify="center">
						<Button type="primary" size="middle" onClick={() => next()}>
							<Row align="middle">
								{translate(`${TRANSLATION_BASE_PATH}._BUTTON`)}
								<Icon className="ml-8" type="ri-arrow-right-line" />
							</Row>
						</Button>
					</Row>
				</Col>
			</Row>
		</Modal>
	);
};