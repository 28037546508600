import React, { ReactNode, useEffect, useState } from "react";
import { Input as ReactInput, Alert, Badge, Button, Col, Modal, Row, Slider, Switch, InputNumber } from "antd";
import { Formik } from "formik";
import { formValidator } from "./ValidationSchema";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Icon from "common/components/general/Icon";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Text from "common/components/general/Text";
import { FormFieldLabelWithTooltip } from "../../../../components/formFieldLabelWithTooltip/FormFieldLabelWithTooltip";
import { Space } from "common/components/layout/Space";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import { formValidatorEmail } from "./ValidationSchemaEmail";
import { formValidatorDomain } from "./ValidationSchemaDomain";
import { getUuidCode } from "common/utils/UuidCode";
import { StopOutlined, CheckOutlined } from "@ant-design/icons";
import { RequireApprovalToJoinOrg } from "../../../../../main/scenes/organization/components/welcomePopup/requireApprovalToJoinOrg/requireApprovalToJoinOrg";
import { OrganizationSectorType, OrganizationUpdateAttribute } from "services/domain/administration/Organization";
import InvitationService from "services/administration/invitation.service";
import { MarketplaceUpload } from "../marketplaceUpload/MarketplaceUpload";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import { Role } from "services/domain/login/Role";
const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._ORGANIZATION";
const TYPES = ["PRE_UNI", "UNI", "INDIVID", "INSTITUTION"];
const CONTENT_TYPES = ["OFFLINE", "LIVE", "ALL"];
const SECTORS = ["PUBLIC", "PRIVATE"];

export const EditDrawer = (props: any) => {
	const { t: translate } = useTranslation();
	const [invitationPending, setInvitationPending] = useState<any>(null);
	const [myInvitationPending, setMyInvitationPending] = useState<any>(null);
	const [hasOtherInvitationActive, setHasOtherInvitationActive] = useState<boolean>(false);
	const [editEmailModalFormVisible, setEditEmailModalFormVisible] = useState(false);
	const [editDomainModalFormVisible, setEditDomainModalFormVisible] = useState(false);
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const currentOrganization = currentUserInfo?.Organizations[0];
	const userRole =
		currentUserInfo?.Roles &&
		currentUserInfo.Roles.length > 0 &&
		currentUserInfo.Roles.sort((first: any, second: any) => Number(second?.priority) - Number(first?.priority))[0]
			?.code;
	useEffect(() => {
		async function fetchSuperAdminInvitationData() {
			const invitation = await new InvitationService().getOrganizationOnwerInvite(
				props.organization.id,
				props.organization.ownerEmail
			);
			setInvitationPending(invitation);
		}

		async function fetchMyInvitationData() {
			const [myInvitationPending, hasOtherInvitationActive] = await Promise.all([
				new InvitationService().getMyOrganizationOnwerInvite(
					props.organization.id,
					props.organization.ownerEmail
				),
				new InvitationService().hasOrganizationOnwerInvite(props.organization.id, props.organization.ownerEmail)
			]);
			setMyInvitationPending(myInvitationPending);
			setHasOtherInvitationActive(hasOtherInvitationActive);
		}

		props.superAdmin && props.organization.id && props.organization.ownerEmail && fetchSuperAdminInvitationData();
		!props.superAdmin && props.organization.id && props.organization.ownerEmail && fetchMyInvitationData();
	}, [props.organization.id, props.organization.ownerEmail, props.superAdmin]);

	useEffect(() => {
		setEditDomainModalFormVisible(props.editDomainModalFormVisible);
	}, [props.editDomainModalFormVisible]);

	useEffect(() => {
		setEditEmailModalFormVisible(props.editEmailModalFormVisible);
	}, [props.editEmailModalFormVisible]);

	let submitHandler: any;

	const onChangeSwitch = (checked: any, formik: any) => {
		formik.setFieldValue("status", checked ? "ACTIVE" : "INACTIVE");
	};

	const onFeaturedSwitch = (checked: any, formik: any) => {
		formik.setFieldValue("featured", checked ? true : false);
	};

	const setOrderNumber = (value: any, formik: any) => {
		formik.setFieldValue("featuredSort", value);
	};

	const CreateEditForm = () => {
		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={props.formInitialValues}
				onSubmit={(values: any) => props.onSubmit(values, OrganizationUpdateAttribute._ALL, true)}
				validateOnMount
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					submitHandler = submitForm;
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row>
								<Col span="24">
									<Input
										name="name"
										label={translate(`${TRANSLATION_BASE_PATH}._NAME`)}
										disabled={!props.superAdmin && props.disableModify}
									/>
								</Col>
							</Row>
							<Row justify="space-between">
								<Col span="11">
									<Row className="pb-24">
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._CODE`)}
										</Text>
										<ReactInput
											addonBefore={<Icon type="ri-key-line"></Icon>}
											value={formik.values.code}
											disabled
										/>
										<Text
											fontSize="14"
											lineHeight="22"
											className="color-blue-6 cursor_pointer"
											onClick={() => formik.setFieldValue("code", getUuidCode())}
										>
											{translate(`${TRANSLATION_BASE_PATH}._CHANGE_CODE`)}
										</Text>
									</Row>
								</Col>
								{(!props.superAdmin || formik.values.status === "PENDING") && (
									<Col span="11">
										<Row className="mb-4">
											<Text fontSize="14" lineHeight="22" className="color-gray-9">
												{translate(`${TRANSLATION_BASE_PATH}._STATUS`)}
											</Text>
										</Row>
										<Badge
											color={
												formik.values.status === "ACTIVE"
													? "green"
													: formik.values.status === "PENDING"
													? "gray"
													: "red"
											}
											text={
												<Text fontSize="14" lineHeight="22" className="color-gray-7">
													{translate(
														`${TRANSLATION_BASE_PATH}._STATUS_${formik.values.status}`
													)}
												</Text>
											}
										/>
									</Col>
								)}
								{props.superAdmin && formik.values.status !== "PENDING" && (
									<Col span="11">
										<Row className="pb-24 full__height" align="middle">
											<Col>
												<Switch
													onChange={e => onChangeSwitch(e, formik)}
													checkedChildren={<CheckOutlined />}
													unCheckedChildren={<StopOutlined />}
													defaultChecked={
														props.formInitialValues.status === "ACTIVE" ? true : false
													}
												/>
											</Col>
											<Col span={1}></Col>
											<Col>
												<Text fontSize="14" lineHeight="22" className="color-gray-7">
													{translate(
														`${TRANSLATION_BASE_PATH}._STATUS_${formik.values.status}`
													)}
												</Text>
											</Col>
										</Row>
									</Col>
								)}
							</Row>
							<Row>
								<Col span="24">
									<Input
										disabled
										value={props.formInitialValues.nationality}
										name="nationality"
										label={translate(`${TRANSLATION_BASE_PATH}._NATIONALITY`)}
									/>
								</Col>
							</Row>
							<Row className="pt-4">
								<Radio
									name="type"
									value={formik.values.type}
									label={translate(`${TRANSLATION_BASE_PATH}._TYPE`)}
									disabled={true}
									defaultValue={formik.values.type}
								>
									{TYPES.map((item: any, index: number) => {
										return (
											<Col span="24" key={index}>
												<RadioElement withOutStyle={true} value={item}>
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{translate(`${TRANSLATION_BASE_PATH}._TYPES._${item}`)}
													</Text>
												</RadioElement>
											</Col>
										);
									})}
								</Radio>
							</Row>
							<Row className="pt-4">
								{FormFieldLabelWithTooltip(
									"_CONTENT_TYPE",
									translate(`${TRANSLATION_BASE_PATH}._CONTENT_TYPES_TOOLTIP`)
								)}
							</Row>
							<Row>
								<Radio
									name="contentType"
									value={formik.values.contentType}
									disabled={true}
									defaultValue={formik.values.contentType}
								>
									{CONTENT_TYPES.map((item: any, index: number) => {
										return (
											<Col span="24" key={index}>
												<RadioElement withOutStyle={true} value={item}>
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{translate(`${TRANSLATION_BASE_PATH}._CONTENT_TYPES._${item}`)}
													</Text>
												</RadioElement>
											</Col>
										);
									})}
								</Radio>
							</Row>
							<Row className="pt-4">
								{FormFieldLabelWithTooltip(
									"_SECTOR",
									translate(`${TRANSLATION_BASE_PATH}._SECTOR_TOOLTIP`)
								)}
							</Row>
							<Row>
								<Radio
									name="sector"
									value={formik.values.sector}
									disabled={true}
									defaultValue={formik.values.contentType}
								>
									{SECTORS.map((item: any, index: number) => {
										return (
											<RadioElement key={index} withOutStyle={true} value={item}>
												<Text fontSize="14" lineHeight="22" className="color-gray-8">
													{translate(`${TRANSLATION_BASE_PATH}._SECTORS._${item}`)}
												</Text>
											</RadioElement>
										);
									})}
								</Radio>
							</Row>
							{formik.values.sector === OrganizationSectorType.Private && (
								<>
									<Row className="pt-4">
										{FormFieldLabelWithTooltip(
											"_DOMAIN",
											translate(`${TRANSLATION_BASE_PATH}._DOMAIN_TOOLTIP`)
										)}
									</Row>
									<Row>
										<Col span="24">
											<Input
												addonBefore={<Icon type="ri-link"></Icon>}
												name="domain"
												disabled={true}
												addonAfter={
													<span
														className={
															"cursor_pointer " +
															(!props.superAdmin && props.disableModify
																? "color-gray-6"
																: "color-blue-6")
														}
														onClick={() =>
															props.superAdmin &&
															props.setEditDomainModalFormVisible(true)
														}
													>
														{translate(`${TRANSLATION_BASE_PATH}._CHANGE_FIELD`)}
													</span>
												}
											/>
										</Col>
									</Row>
									<Row className="pt-4" justify="space-between">
										<Col span="11">
											<Row>
												{FormFieldLabelWithTooltip(
													"_NO_USERS",
													translate(`${TRANSLATION_BASE_PATH}._NO_USERS_TOOLTIP`)
												)}
											</Row>
											<Row>
												<Input
													name="numberOfUsers"
													disabled={!props.superAdmin && props.disableModify}
												/>
											</Row>
										</Col>
										<Col span="11">
											<Row>
												{FormFieldLabelWithTooltip(
													"_PRICE",
													translate(`${TRANSLATION_BASE_PATH}._PRICE_TOOLTIP`)
												)}
											</Row>
											<Row>
												<Input
													addonAfter={"ALL"}
													name="price"
													disabled={!props.superAdmin && props.disableModify}
												/>
											</Row>
										</Col>
									</Row>
								</>
							)}
							{formik.values.sector === OrganizationSectorType.Public && (
								<>
									<Row>
										{FormFieldLabelWithTooltip(
											"_RATIO",
											translate(`${TRANSLATION_BASE_PATH}._RATIO_TOOLTIP`)
										)}
									</Row>
									<Row className="pt-4" justify="space-between">
										<Col span="15">
											<Slider min={0} max={100} disabled={true} value={formik.values.ratio} />
										</Col>
										<Col span="8">
											<Space>
												<Input addonAfter={"%"} name="ratio" readOnly={true} disabled={true} />
											</Space>
										</Col>
									</Row>
								</>
							)}
							<Row className="pt-4">
								<Col span="24">
									<Input
										disabledError={true}
										name="ownerEmail"
										label={translate(`${TRANSLATION_BASE_PATH}._EMAIL`)}
										disabled={true}
										addonAfter={
											<span
												className={
													"cursor_pointer " +
													(!props.superAdmin && props.disableModify
														? "color-gray-6"
														: "color-blue-6")
												}
												onClick={() =>
													(props.superAdmin || !props.disableModify) &&
													setEditEmailModalFormVisible(true)
												}
											>
												{translate(`${TRANSLATION_BASE_PATH}._CHANGE_FIELD`)}
											</span>
										}
									/>
									{props.superAdmin && invitationPending && (
										<Space direction="horizontal" size={8}>
											<Icon type="ri-error-warning-line" className="color-orange-6" />
											<Text fontSize="14" lineHeight="22" className="color-gray-8">
												{translate(`${TRANSLATION_BASE_PATH}._UNAPPROVED_EMAIL`)}
											</Text>
											<a onClick={() => props.resendInvitation(invitationPending?.id)}>
												<Text fontSize="14" lineHeight="22" className="color-blue-6">
													{translate(`${TRANSLATION_BASE_PATH}._RESEND_REQUEST`)}
												</Text>
											</a>
										</Space>
									)}
									{!props.superAdmin && hasOtherInvitationActive && (
										<Space direction="horizontal" size={8}>
											<Icon type="ri-error-warning-line" className="color-orange-6" />
											<Text fontSize="14" lineHeight="22" className="color-gray-8">
												{myInvitationPending
													? translate(`${TRANSLATION_BASE_PATH}._UNAPPROVED_EMAIL`)
													: translate(`${TRANSLATION_BASE_PATH}._REQUEST_SENT_BY_AKADEMI`)}
											</Text>
											{myInvitationPending && (
												<a onClick={() => props.resendInvitation(myInvitationPending?.id)}>
													<Text fontSize="14" lineHeight="22" className="color-blue-6">
														{translate(`${TRANSLATION_BASE_PATH}._RESEND_REQUEST`)}
													</Text>
												</a>
											)}
										</Space>
									)}
								</Col>
							</Row>
							{userRole === Role.Owner &&
								currentOrganization?.Name === "Akademi.al" &&
								currentOrganization?.OrganizationId === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65" &&
								props.organization.sector !== "PRIVATE" && (
									<>
										<Row className="pt-8" style={{ marginTop: "20px" }}>
											<Col span={18}>
												<Text fontSize="14" lineHeight="22" wheight="semibold">
													{translate(`_MARKETPLACE._SHOW_ON_MARKETPLACE`)}
												</Text>
											</Col>
											<Col>
												<p
													style={{
														fontSize: "14",
														marginRight: "12px",
														display: "inline",
														color: "#98A9C0",
														visibility:
															formik.values.featured === true ? "visible" : "hidden"
													}}
												>
													{translate(`_MARKETPLACE._ACTIVE`)}
												</p>

												<Switch
													onChange={e => onFeaturedSwitch(e, formik)}
													defaultChecked={
														props.formInitialValues.featured === true ? true : false
													}
												/>
											</Col>
										</Row>
										{formik.values.featured === true && (
											<Row className="pt-8" justify="start" style={{ marginTop: "20px" }}>
												<Col span="16">
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "start"
														}}
													>
														<p
															style={{
																fontSize: "14",
																display: "inline",
																color: "#718096"
															}}
														>
															{translate(`_MARKETPLACE._UPLOAD_IMAGE`)}
														</p>

														<MarketplaceUpload
															default={props.organization?.default}
															domain={props.organization?.domain}
															organizationId={props.formInitialValues.id}
															onUpload={(filePath: string) =>
																(formik.values.marketplaceLogo = filePath)
															}
															onDelete={() => (formik.values.marketplaceLogo = null)}
														/>
													</div>
												</Col>
												<Col>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "start"
														}}
													>
														<p
															style={{
																fontSize: "14",
																display: "inline",
																color: "#718096"
															}}
														>
															{translate("_MARKETPLACE._SPECIFY_SORT_ORDER")}
														</p>
														<StyledNumberInput
															min={1}
															defaultValue={props.formInitialValues?.featuredSort}
															onChange={e => setOrderNumber(e, formik)}
														/>
													</div>
												</Col>
											</Row>
										)}
									</>
								)}
							{!props.superAdmin && <RequireApprovalToJoinOrg />}
						</form>
					);
				}}
			</Formik>
		);
	};

	const getEditDomainModalForm = () => {
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._TITLE`);
		const formSubtitle = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._SUBTITLE`, {
			orgName: props.organization.name
		});
		const formBtnSave = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._SAVE`);
		const cancel = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_DOMAIN._CANCEL`);

		let submitFormHandler: () => Promise<any>;

		return (
			<Modal
				title={formTitle}
				visible={editDomainModalFormVisible}
				destroyOnClose={true}
				onCancel={() => props.setEditDomainModalFormVisible(false)}
				footer={[
					<Button key="back" onClick={() => props.setEditDomainModalFormVisible(false)}>
						{cancel}
					</Button>,
					<Button
						key="submit"
						type="primary"
						form="domainForm"
						htmlType="submit"
						disabled={!editDomainModalFormVisible}
						onClick={() => submitFormHandler}
					>
						{formBtnSave}
					</Button>
				]}
			>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidatorDomain(translate)}
					initialValues={{
						id: props.organization.id,
						domain: props.organization.domain
					}}
					onSubmit={(values: any) => props.onSubmit(values, OrganizationUpdateAttribute._DOMAIN, false)}
					validateOnMount
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitFormHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} id="domainForm" autoComplete="off">
								<Row gutter={24} className="pb-24">
									<Col span="24">
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{formSubtitle}
										</Text>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col span="24">
										<Input addonBefore={<Icon type="ri-link"></Icon>} name="domain" />
									</Col>
								</Row>
							</form>
						);
					}}
				</Formik>
			</Modal>
		);
	};

	const getEditOwnerEmailModalForm = () => {
		const formTitle = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._TITLE`);
		const formSubtitle1 = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._SUBTITLE_1`, {
			orgName: props.organization.name
		});
		const notAdminDesc = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._NOT_ADMIN_DESC`, {
			orgName: props.organization.name
		});
		const formSubtitle2 = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._SUBTITLE_2`);
		const formBtnSave = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._SAVE`);
		const cancel = translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._CANCEL`);

		let submitFormHandler: () => Promise<any>;

		return (
			<Modal
				title={formTitle}
				visible={editEmailModalFormVisible}
				destroyOnClose={true}
				onCancel={() => setEditEmailModalFormVisible(false)}
				footer={[
					<Button key="back" onClick={() => setEditEmailModalFormVisible(false)}>
						{cancel}
					</Button>,
					userRole === Role.Owner &&
						currentOrganization?.Name === "Akademi.al" &&
						currentOrganization?.OrganizationId === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65" && (
							<Button
								key="submit"
								type="primary"
								form="domainForm"
								htmlType="submit"
								disabled={!editEmailModalFormVisible}
								onClick={() => submitFormHandler}
							>
								{formBtnSave}
							</Button>
						)
				]}
			>
				{userRole === Role.Owner &&
				currentOrganization?.Name === "Akademi.al" &&
				currentOrganization?.OrganizationId === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65" ? (
					<Formik
						enableReinitialize={true}
						validationSchema={formValidatorEmail(translate)}
						initialValues={{
							id: props.organization.id,
							ownerEmail: props.organization.ownerEmail,
							domain: props.organization.domain
						}}
						onSubmit={(values: any) =>
							props.onSubmit(values, OrganizationUpdateAttribute._OWNER_EMAIL, false)
						}
						validateOnMount
					>
						{formik => {
							const { handleSubmit, submitForm } = formik;
							submitFormHandler = submitForm;
							return (
								<form onSubmit={handleSubmit} id="domainForm" autoComplete="off">
									<Row gutter={24} className="pb-24">
										<Col span="24">
											<Text fontSize="14" lineHeight="22" className="color-gray-8">
												{formSubtitle1}
											</Text>
										</Col>
										<Col span="24">
											<Text fontSize="14" lineHeight="22" className="color-gray-8">
												{formSubtitle2}
											</Text>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span="24">
											<Input
												addonBefore={<Icon type="ri-link"></Icon>}
												name="ownerEmail"
												label={translate(`${TRANSLATION_BASE_PATH}._EDIT_MODAL_EMAIL._EMAIL`)}
											/>
										</Col>
									</Row>
								</form>
							);
						}}
					</Formik>
				) : (
					<Row gutter={24} className="pb-24">
						<Col span="24">
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{notAdminDesc}
							</Text>
						</Col>
					</Row>
				)}
			</Modal>
		);
	};

	return (
		<>
			<CreateEditDrawer
				title={translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
				onClose={props.toggleDrawer}
				onSave={() => submitHandler()}
				visible={props.drawerShown}
				form={CreateEditForm}
				width={window.innerWidth >= 576 ? 450 : window.innerWidth}
			/>
			{props.superAdmin && getEditDomainModalForm()}
			{getEditOwnerEmailModalForm()}
		</>
	);
};

const StyledNumberInput = styled(InputNumber)`
	height: 35px;
	max-width: 120px;
	border-radius: 5px;
`;
