import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import OAuth2Service from "services/authentication/oauth2.service";
import { MAIN_PATH } from "./scenes/main";
import { ADMIN_PATH } from "./scenes/admin";
import { PARENT_PATH } from "./scenes/parent";
import { PARENT_DASHBOARD_PATH } from "./scenes/parent/scenes/dashboard";
import { Role } from "./services/domain/login/Role";

export enum TypeOfOrganization {
	Main,
	Child
}

interface AllowedProps {
	role: string;
	type: TypeOfOrganization;
}

interface AuthorizedGuardedRouteProps extends RouteProps {
	allowed: AllowedProps[];
}

const getPath = (role: string, defaultOrganization?: boolean) => {
	if (defaultOrganization && (role === Role.Owner || role === Role.Admin)) return `/${ADMIN_PATH}`;
	if (role === Role.Parent) return `/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`;
	if (
		(defaultOrganization !== undefined && !defaultOrganization && (role === Role.Owner || role === Role.Admin)) ||
		role === Role.Student ||
		role === Role.Teacher
	)
		return `/${MAIN_PATH}`;
	return "/";
};

const AuthorizedGuardedRoute = ({ component: Component, ...rest }: AuthorizedGuardedRouteProps) => {
	if (!Component) return null;
	const userInfo = OAuth2Service.CurrentUser;
	const userRole =
		userInfo?.Roles &&
		userInfo.Roles.length > 0 &&
		userInfo.Roles.sort((first: any, second: any) => Number(second?.priority) - Number(first?.priority))[0];
	const organization =
		userInfo?.Organizations &&
		userInfo?.Organizations.length > 0 &&
		userInfo?.Organizations.filter((o: any) => o.UserRoleId === userRole?.UserRoleId).sort(
			(first: any, second: any) => Number(first?.Default) - Number(second?.Default)
		)[0];
	let isAuthorizedRole = false;
	if (
		userRole &&
		organization &&
		rest.allowed &&
		rest.allowed.some((r: AllowedProps) => {
			if (
				r.role === userRole.code &&
				((r.type === TypeOfOrganization.Main && organization.Default) ||
					(r.type === TypeOfOrganization.Child && !organization.Default))
			) {
				return true;
			}
			return false;
		})
	) {
		isAuthorizedRole = true;
	}

	const pathToNavigate: string = getPath(userRole?.code, organization?.Default);
	return (
		<Route
			{...rest}
			render={props =>
				isAuthorizedRole ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: pathToNavigate,
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
};

export default AuthorizedGuardedRoute;
