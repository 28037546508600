import { Affix, Card, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {SearchBar} from "./SearchBar";
import { useStoreState } from "easy-peasy";
import { MarketplaceStore, useMarketplaceContext } from "../../../../../../../PublicMarketplace/PublicMarketplace";

interface SectionSearchProps {
	loading: boolean;
	upperSpace?: boolean;
	search: (searchValue: string) => void;
	toggleFilterBar: (toggle: boolean) => void;
}

export const SectionSearch = (props: SectionSearchProps) => {
	const [stickyBar, setStickyBar] = useState(false);
	const filters = useStoreState(state => state.subjects.activeFilters);
	const offsetTop = useRef(0);
	const { marketplaceStore: [marketplaceState] } = useMarketplaceContext() as { marketplaceStore: [MarketplaceStore] };

	useEffect(() => {
		const handleStickyFiltersScroll = () => {
			if (window.innerWidth > 991) {
				setStickyBar(false);
			} else {
				const threshold = document.querySelector(".section-header")?.scrollHeight ?? 200;
				const scroll = window.scrollY;
				const reachedTheTop = scroll > threshold + 85;
				setStickyBar(reachedTheTop);
			}
		};

		window.addEventListener("scroll", handleStickyFiltersScroll);

		return () => window.removeEventListener("scroll", handleStickyFiltersScroll);
	}, []);

	return (
		<Row>
			<Col xs={24}>
				{!stickyBar && (
					<SearchBar
						value={marketplaceState.searchKey}
						filters={filters}
						toggleFilterBar={props.toggleFilterBar}
						search={props.search}
						upperSpace={props.upperSpace}
						loading={props.loading}
					/>
				)}
				{stickyBar && (
					<Affix offsetTop={offsetTop.current}>
						<Card className={"section-search__card"}>
							<SearchBar
								value={marketplaceState.searchKey}
								filters={filters}
								toggleFilterBar={props.toggleFilterBar}
								search={props.search}
								upperSpace={props.upperSpace}
								loading={props.loading}
							/>
						</Card>
					</Affix>
				)}
			</Col>
		</Row>
	);
};
