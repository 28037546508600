import React from "react";
import { Card, Space, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { MATERIALS_PATH, SUBJECTS_PATH } from "../../../../scenes/subjects/scenes/materialItems";
import { LessonAsTodaysTaskDto } from "dtos/administration/lesson/lessonAsTodaysTask.dto";

interface MeetingTaskProps {
	item: LessonAsTodaysTaskDto;
	viewAsTeacher: boolean;
	onEdit?: (task: any) => void;
	onDelete?: (task: any) => void;
}

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW._TASKS";

export const MeetingTask = ({ onEdit, onDelete, ...props }: MeetingTaskProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();

	const meetingInfo =
		!props.item.isSubjectMaterial || (props.item.isSubjectMaterial && props.viewAsTeacher)
			? JSON.parse(props.item.extraInfo ?? "")
			: {};

	const menu = (
		<Menu>
			{onEdit ? (
				<Menu.Item key="0" onClick={() => onEdit(props.item)}>
					<Space direction="horizontal">
						<Icon type="ri-edit-line" />
						{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
					</Space>
				</Menu.Item>
			) : null}
			{onDelete ? (
				<Menu.Item key="1" onClick={() => onDelete(props.item)}>
					<Space direction="horizontal">
						<Icon type="ri-delete-bin-line" />
						{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
					</Space>
				</Menu.Item>
			) : null}
		</Menu>
	);

	let actionsMenu = null;
	if (props.viewAsTeacher && !props.item.isSubjectMaterial) {
		actionsMenu = (
			<Dropdown.Button
				buttonsRender={() => [<></>,
					<Button
						key="1"
						type="default"
						shape="circle"
						className="materialItem__layout materialItem__button-circle color-gray-8"
					>
						<Icon type="ri-more-2-line" />
					</Button>]}
				trigger={["click"]}
				overlay={menu}
				icon={<Icon type="ri-more-2-line" />}
			/>
		);
	} else if (!props.viewAsTeacher && props.item.isSubjectMaterial) {
		actionsMenu = (
			<Icon
				type="ri-arrow-right-line"
				className="cursor_pointer color-gray-6"
				fontSize="16"
				onClick={() => history.push(`/${SUBJECTS_PATH}/${MATERIALS_PATH}/video/${props.item.id}`)}
			/>
		);
	}

	return (
		<Card className="meeting__task_card">
			<TypeIcon type="MEETING" />
			<div className="flex__space_between full__width">
				<div className="ml-16 meeting__task_info">
					<Text fontSize="14" lineHeight="22" className="color-gray-9">
						{props.item.title.length > 30 ? `${props.item.title.substring(0, 30)}...` : props.item.title}
					</Text>
					<Text fontSize="12" lineHeight="20" className="color-gray-7">
						<Icon type="ri-time-line" /> {moment(props.item.startDate).format("DD.MM.YYYY HH:mm")}
					</Text>
					{props.item.isSubjectMaterial === false ||
					(props.item.isSubjectMaterial === true && props.viewAsTeacher) ? (
							<>
								{props.viewAsTeacher ? (
									<a href={meetingInfo.startUrl} target="_blank" rel="noopener noreferrer">
										{translate(`${TRANSLATION_BASE_PATH}._START_LIVE_CLASS`)}
									</a>
								) : (
									<a href={meetingInfo.joinUrl} target="_blank" rel="noopener noreferrer">
										{translate(`${TRANSLATION_BASE_PATH}._JOIN_LIVE_CLASS`)}
									</a>
								)}
							</>
						) : null}
				</div>
				{actionsMenu}
			</div>
		</Card>
	);
};
