import { Row, Space } from "antd";
import Text from "common/components/general/Text";
import React from "react";
import Icon from "common/components/general/Icon";

export const PriceTag = (props: any) => {
	return (
		<Row className="pt-4 ml-16 color-blue-6 whiteSpace-nowrap">
			<Space>
				<Icon type="ri-price-tag-3-line" />
				<Text fontSize="16" lineHeight="24">
					{props.price > 0 ? "€ " + props.price : "Falas"}
				</Text>
			</Space>
		</Row>
	);
};
