import React, { useEffect, useState } from "react";
import { Uploader, UploadImageType } from "common/components/uploader/Uploader";
import { InputProps } from "../../utils/types";
import { PlusOutlined } from "@ant-design/icons";
import ImageComponent from "common/components/general/Image";
import { useFormikContext } from "formik";
import BucketS3Service from "services/s3/s3.service";
import S3ActionType from "services/domain/administration/S3Action";
import { Col, message, Row } from "antd";
import Button from "common/components/general/Button";
import Text from "../../../../../../../../common/components/general/Text";

export function Image(props: InputProps) {
	const { setFieldValue } = useFormikContext();
	const [img, setImg] = useState(props.value);
	const [imageUrl, setImgUrl] = useState<string | null>(null);

	useEffect(() => {
		if (!img || img === "") {
			setImgUrl(null);
			return;
		}
		new BucketS3Service()
			.getS3UrlForAction(img, S3ActionType.DOWNLOAD)
			.then(function(res) {
				return setImgUrl(res);
			})
			.catch(err => {
				console.log(err.message);
				message.error("Failed to retrieve image");
			});
	}, [img]);

	function deleteFile() {
		return new BucketS3Service()
			.getS3UrlForAction(img, S3ActionType.DELETE)
			.then(s3SignedUrl => {
				return fetch(s3SignedUrl, {method: "DELETE", body: img});
			})
			.then(() => {
				setImg("");
				setImgUrl(null);
				return setFieldValue(props.name, "");
			})
			.catch(err => {
				setImg("");
				setImgUrl(null);
				setFieldValue(props.name, "");
				console.log(err.message);
			});
	}

	return (
		<Row>
			<Col xs={24}>
				<Text fontSize="14" lineHeight="22" className="color-gray-9">
					{props.title}
				</Text>
			</Col>
			<Col xs={24}>
				<div className="image-input">
					<input type="hidden" name={props.name} value={img} />
					<Uploader
						fileType="IMAGE"
						uploadImageType={props.options?.uploadImageType ?? UploadImageType.Cover}
						filePath={`design/${props.organizationId}/images`}
						fileWithUniqueId={true}
						onUploadingDone={(fileUploaded: any) => {
							setImg(fileUploaded.filePath + "/" + fileUploaded.name);
							setFieldValue(props.name, fileUploaded.filePath + "/" + fileUploaded.name);
						}}
						onRemoveUpload={() => deleteFile()}
						showUploadList={false}
						className="quiz__add_question_upload_photo"
					>
						{img ? (
							<div className="onboarding__upload_avatar_preview_wrapper">
								<ImageComponent src={imageUrl || ""} />
							</div>
						) : (
							<div className="ant-upload ant-upload-select ant-upload-select-picture-card">
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Ngarko</div>
							</div>
						)}
					</Uploader>
					<Col xs={24} className="single-file-upload__remove-link">
						{img && (
							<Button type="link" onClick={() => deleteFile()}>
								Hiq
							</Button>
						)}
					</Col>
				</div>
			</Col>
		</Row>
	);
}
