import React from "react";
import { useField, useFormikContext } from "formik";

import Error from "./FormItemError";
import InputNumber, { InputNumberProps } from "../components/InputNumber";
import Label from "../components/Label";
import { FieldProps } from "./FieldProps";

type Props = InputNumberProps &
	FieldProps & {
		hint?: string;
		formatter?: (value: number | string | undefined) => string;
		parser?: (displayValue: string | undefined) => number | string;
	};

const FormikInputNumberField: React.FC<Props> = props => {
	const [field, meta] = useField(props.name);
	const { setFieldValue } = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	const onChange = (value: string | number | undefined) => {
		setFieldValue(props.name, value);
	};

	const hint = props.hint ? <span className="formik-field__span"> {props.hint}</span> : null;

	return (
		<div>
			<Label htmlFor={id} className="hand-on-hover">
				{props.label}
				{hint}
			</Label>
			<InputNumber {...field} {...props} id={id} onChange={onChange} />
			{error}
		</div>
	);
};

export default FormikInputNumberField;
