import React, { useEffect, useState } from "react";
import { Alert, Col, Popconfirm, Row, Skeleton, Space } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { showError, showMessage } from "common/utils/Notification";
import ClassroomService from "services/administration/classroom.service";
import OrganizationService from "services/administration/organization.service";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { CircularIcon } from "common/components/general/CircularIcon";
import { formValidator } from "../../../../components/joinCreateClassModal/JoinClassOrgValidationSchema";
import OAuth2Service from "services/authentication/oauth2.service";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._MY_PROFILE._DRAWER";

interface ChangeOrganizationDrawerProps {
	organization: any;
	visible: boolean;
	setDrawerVisible: (visible: boolean) => void;
	setOrganization: (organization: any) => void;
}

export const ChangeOrganizationDrawer = (props: ChangeOrganizationDrawerProps) => {
	const { t: translate } = useTranslation();
	const [drawerDataSubmitting, setDrawerDataSubmitting] = useState<any>(false);
	const [drawerClassDeleting, setDrawerClassDeleting] = useState<any>(false);
	const [teacherClasses, setTeacherClasses] = useState<any>([]);
	const [newOrganization, setNewOrganization] = useState<any>(null);
	const isTeacher = OAuth2Service.isTeacher;

	let submitDrawerFormHandler: () => Promise<any>;

	const loadTeacherClasses = async () => {
		await new ClassroomService().getAllVirtualClassrooms().then((classrooms: any) => {
			return setTeacherClasses(classrooms);
		});
	};

	useEffect(() => {
		if (isTeacher) loadTeacherClasses();
	}, []);

	const deleteClassroom = async (teacherClass: any) => {
		setDrawerClassDeleting(true);
		await new ClassroomService().deleteVirtualClassroom(teacherClass.id).then(() => {
			const remainedTeacherClasses = teacherClasses.filter((x: any) => x.id !== teacherClass.id);
			setDrawerClassDeleting(false);
			showMessage(translate(`${TRANSLATION_BASE_PATH}._CLASS_DELETED`));
			return setTeacherClasses(remainedTeacherClasses);
		});
	};

	const resetDrawerForm = () => {
		setNewOrganization(null);
		props.setDrawerVisible(false);
		setDrawerDataSubmitting(false);
		setDrawerClassDeleting(false);
	};

	const loadNewOrganization = async (code: string) => {
		setDrawerDataSubmitting(true);
		await new OrganizationService()
			.findOrganizationByCode(code)
			.then((newOrganization: any) => {
				return setNewOrganization(newOrganization);
			})
			.catch((error: any) => {
				if (error.status === 404) return showError(translate(`${TRANSLATION_BASE_PATH}._CODE_DOES_NOT_EXIST`));
				if (error.status === 409)
					return showError(translate(`${TRANSLATION_BASE_PATH}._ORGANIZATION_ENROLLED`));
				return;
			})
			.finally(() => {
				setDrawerDataSubmitting(false);
			});
	};

	const joinOrganization = async (values: any) => {
		if (!newOrganization) {
			if (values.code === props.organization.code) {
				showError(translate(`${TRANSLATION_BASE_PATH}._TRY_SAME_CODE`));
				return;
			}
			return loadNewOrganization(values.code);
		}
		setDrawerDataSubmitting(true);
		return new OrganizationService()
			.updateJoinedOrganization(newOrganization.code)
			.then(() => {
				showMessage(translate(`${TRANSLATION_BASE_PATH}._NEW_ORG_JOINED`));
				return new OrganizationService().findCurrentOrganization();
			})
			.then((organization: any) => {
				props.setOrganization(organization[0]);
				return window.location.reload();
			})
			.finally(() => {
				resetDrawerForm();
			});
	};

	const Form = () => {
		const alertMessage = (
			<Text fontSize="14" lineHeight="22" wheight="semibold">
				{translate(`${TRANSLATION_BASE_PATH}._ALERT_MESSAGE`)}
			</Text>
		);
		const alertDescription = (
			<Text fontSize="14" lineHeight="22">
				{translate(`${TRANSLATION_BASE_PATH}._ALERT_DESCRIPTION`)}
			</Text>
		);

		const popConfirm = {
			title: translate(`${TRANSLATION_BASE_PATH}._POPCONFIRM._TITLE`),
			deleteBtn: (
				<Space>
					<Icon type="ri-delete-bin-line" className="cursor_pointer" />
					{translate(`${TRANSLATION_BASE_PATH}._POPCONFIRM._DELETE`)}
				</Space>
			),
			cancelBtn: translate(`${TRANSLATION_BASE_PATH}._POPCONFIRM._CANCEL`)
		};

		return (
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator(translate)}
				initialValues={{ code: props.organization.code }}
				onSubmit={joinOrganization}
				isInitialValid={false}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					submitDrawerFormHandler = submitForm;
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							{!newOrganization && (
								<>
									<Row>{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`)}</Row>
									<Row className="pb-24">
										<Text fontSize="14" lineHeight="20" wheight="semibold">
											{props.organization.name}
										</Text>
									</Row>
									<Row>
										<Input
											name="code"
											label={translate(`${TRANSLATION_BASE_PATH}._FIELDS._CODE`)}
											value={formik.values.code}
											disabled={props.organization && teacherClasses && teacherClasses.length > 0}
											className="pr-36"
											suffix={
												<Icon
													type={
														teacherClasses && teacherClasses.length > 0
															? "ri-lock-line"
															: "ri-lock-unlock-line"
													}
												/>
											}
										/>
									</Row>
								</>
							)}
							{teacherClasses && teacherClasses.length > 0 && (
								<Row className="pb-24">
									<Alert
										message={alertMessage}
										description={alertDescription}
										type="warning"
										showIcon
									/>
								</Row>
							)}
							<Skeleton loading={drawerClassDeleting}>
								{teacherClasses.map((teacherClass: any) => {
									return (
										<Row
											key={teacherClass.id}
											className="pb-24"
											justify="space-between"
											align="middle"
										>
											<Col span="22">
												<Space>
													<CircularIcon type="ri-door-open-line" />
													<Text fontSize="14" lineHeight="22" className="color-gray-9">
														{teacherClass.name}
													</Text>
												</Space>
											</Col>
											<Col span="2">
												<Popconfirm
													title={popConfirm.title}
													onConfirm={async () => await deleteClassroom(teacherClass)}
													okText={popConfirm.deleteBtn}
													cancelText={popConfirm.cancelBtn}
												>
													<Icon type="ri-delete-bin-line" className="cursor_pointer" />
												</Popconfirm>
											</Col>
										</Row>
									);
								})}
							</Skeleton>
							{newOrganization && (
								<>
									<Row>{translate(`${TRANSLATION_BASE_PATH}._WELCOME_SUBTITLE`)}</Row>
									<Row className="pb-24">
										<Text fontSize="14" lineHeight="20" wheight="semibold">
											{newOrganization.name}
										</Text>
									</Row>
									<Row align="middle">
										<Col span="6">
											<Text fontSize="56" lineHeight="54" className="color-blue-6">
												<Icon type="ri-building-line"></Icon>
											</Text>
										</Col>
										<Col>
											<Row>
												<Text fontSize="24" lineHeight="32">
													{newOrganization.name}
												</Text>
											</Row>
											<Row>
												<Text fontSize="14" lineHeight="22" className="color-gray-7">
													{translate(`${TRANSLATION_BASE_PATH}._WELCOME_INSTITUTION`)}
												</Text>
											</Row>
										</Col>
									</Row>
									<Row className="color-blue-6" onClick={() => setNewOrganization(null)}>
										<Space>
											<Icon type="ri-edit-line"></Icon>
											<Text fontSize="14" lineHeight="22" className="cursor_pointer">
												{translate(`${TRANSLATION_BASE_PATH}._CHANGE_CODE`)}
											</Text>
										</Space>
									</Row>
								</>
							)}
						</form>
					);
				}}
			</Formik>
		);
	};

	return (
		<CreateEditDrawer
			title={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
			onClose={() => resetDrawerForm()}
			onSave={() => submitDrawerFormHandler()}
			visible={props.visible}
			form={Form}
			width={window.innerWidth >= 576 ? 350 : window.innerWidth}
			submitting={drawerDataSubmitting}
			saveBtnTitle={!newOrganization ? undefined : "Perfundo"}
			saveBtnDisabled={teacherClasses.length > 0}
		/>
	);
};
