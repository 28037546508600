import React from "react";
import { Layout } from "antd";

import Logo from "assets/images/icons/Logo.svg";
import Image from "common/components/general/Image";
import { useHistory } from "react-router-dom";
import { SIGN_IN_PATH } from "../../scenes/signIn";
import { getDesignFile } from "../../../../common/utils/Design";
import { useStoreState } from "../../../../store/hooks";

const { Header: AntHeader } = Layout;

export const Header = () => {
	const history = useHistory();
	const designLoading = useStoreState(state => state.designSettings.loading);
	const primaryLogo = useStoreState(state => state.designSettings.items.primaryLogo);

	return (
		<AntHeader className="account__header">
			<a onClick={() => history.push(`/${SIGN_IN_PATH}`)} className="account__header__logo">
				{!designLoading &&
				<Image style={{maxWidth: "125px"}} src={ getDesignFile(Logo, primaryLogo) } />}
			</a>
		</AntHeader>
	);
};
