import { Button, Col, Divider, Popconfirm, Row, Space, Tooltip, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { formValidator } from "./ValidationSchema";
import { useTranslation } from "react-i18next";
import FormikCheckboxField from "common/components/dataEntry/formik/FormikCheckboxField";
import InputNumber from "common/components/dataEntry/formik/FormikInputNumberField";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { TAG_TYPE } from "../../../../services/administration/tag.services";

const TRANSLATION_BASE_PATH = "_ADMIN._TAG_MANAGER";

class Tag {
	id!: string;
	name!: string;
	target!: string;
	asyncOnly!: boolean;
	ParentId: any;
	isGlobal!: boolean;
	priority!: string;
}

class GroupTag {
	id!: string;
	name!: string;
	target!: string;
	asyncOnly!: boolean;
	isGlobal!: boolean;
	priority!: string;
	Tags!: Tag[];
	type!: TAG_TYPE;
}

class CreateEditFormProps {
	Title!: string;
	Tag?: GroupTag;
	drawerVisible!: boolean;
	toggleDrawer: any;
	onSubmit: any;
	submitting?: boolean;
	defaultTarget!: string;
	defaultAsyncOnly!: boolean;
	defaultIsGlobal!: boolean;
	defaultPriority!: string;
}

export const CreateEditTagsForm = (props: CreateEditFormProps) => {
	const { t: translate } = useTranslation();
	const emptyGroupTagId = uuidv4();

	const emptyTag: Tag = {
		id: uuidv4(),
		name: "",
		target: props.defaultTarget,
		asyncOnly: props.defaultAsyncOnly,
		ParentId: (props.Tag && props.Tag?.id) || emptyGroupTagId,
		isGlobal: props.defaultIsGlobal,
		priority: props.defaultPriority
	};

	const emptyGroupTag: GroupTag = {
		id: emptyGroupTagId,
		name: "",
		Tags: [],
		target: props.defaultTarget,
		asyncOnly: props.defaultAsyncOnly,
		isGlobal: props.defaultIsGlobal,
		priority: props.defaultPriority,
		type: "category"
	};

	const [childAddFormVisible, setChildAddFormVisible] = useState(false);
	const [tag, setTag] = useState<GroupTag>(props.Tag ?? emptyGroupTag);
	const [currentChildTag, setCurrentChildTag] = useState<Tag>(emptyTag);
	const [editMode, setEditMode] = useState(false);
	const [globalStatus, setGlobalStatus] = useState(props.defaultIsGlobal);

	useEffect(() => {
		setTag(props.Tag ?? emptyGroupTag);
		setGlobalStatus(props.Tag?.isGlobal || false);
	}, [props]);

	const addChildTag = (value: Tag) => {
		if (!editMode) {
			tag.Tags.push({
				...value,
				isGlobal: tag.isGlobal,
				asyncOnly: tag.asyncOnly,
				priority: Number(tag?.Tags?.length || 0).toString()
			});
		} else {
			tag.Tags.map((tag: Tag) => {
				if (tag.id === value.id) tag.name = value.name;
				return;
			});
		}
		setTag(tag);
		setChildAddFormVisible(false);
		setCurrentChildTag(emptyTag);
		setEditMode(false);
	};

	const editChildTag = (index: number) => {
		setCurrentChildTag(tag.Tags[index]);
		setChildAddFormVisible(true);
		setEditMode(true);
	};

	const deleteChildTag = (index: number) => {
		const currentTag = _.clone(tag);
		currentTag.Tags.splice(index, 1);
		setTag(currentTag);
	};

	const onChangedCheckboxGlobal = (e: any, formik: any) => {
		formik.values.isGlobal = e.target.checked;
		setGlobalStatus(e.target.checked);
	};

	const RenderChildTags = () => {
		return (
			tag &&
			tag.Tags.map((item: any, index: number, arr: any[]) => {
				const tagIsInUse = item.ClassroomTags && item.ClassroomTags.length > 0;
				return (
					<>
						<Divider className="p-0 m-0" />
						<Row justify="space-between" className="p-4" key={index}>
							<Col span="14">
								<Space>
									<Icon type="ri-list-unordered"></Icon>
									<Text fontSize="14" lineHeight="22" wheight="semibold">
										{item.name}
									</Text>
								</Space>
							</Col>
							<Col span="10">
								<Row justify="end">
									<Space>
										<Icon
											className="color-gray-6 cursor_pointer"
											type="ri-edit-line"
											onClick={() => editChildTag(index)}
										></Icon>
										<Popconfirm
											title={translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_CONFIRM_MESSAGE`, {
												tagName: item.name
											})}
											onConfirm={() => deleteChildTag(index)}
											okText={translate(`${TRANSLATION_BASE_PATH}._FORM._DELETE_CONFIRM_YES`)}
											cancelText={translate(
												`${TRANSLATION_BASE_PATH}._FORM._DELETE_CONFRIM_CANCEL`
											)}
											disabled={tagIsInUse}
											placement="left"
										>
											<a>
												<Tooltip
													trigger={["click"]}
													placement="left"
													title={
														tagIsInUse
															? translate(
																	`${TRANSLATION_BASE_PATH}._FORM._DELETE_DISABLED_MESSAGE`,
																	{ tagName: item.name }
															  )
															: ""
													}
												>
													<Icon className="color-gray-6" type="ri-delete-bin-7-line" />
												</Tooltip>
											</a>
										</Popconfirm>
									</Space>
								</Row>
							</Col>
						</Row>
						{index === arr.length - 1 && <Divider className="p-0 m-0" />}
					</>
				);
			})
		);
	};

	const RenderChildTagAddForm = () => {
		return (
			childAddFormVisible && (
				<Formik
					initialValues={currentChildTag}
					onSubmit={(values: Tag) => addChildTag(values)}
					enableReinitialize={true}
					isInitialValid={false}
					validationSchema={formValidator(translate)}
				>
					<Form>
						<Row className="pt-16" justify="space-between">
							<Col span="17">
								<Input
									name="name"
									placeholder={translate(`${TRANSLATION_BASE_PATH}._FORM._ADD_CHILD_BTN_LABEL`)}
								/>
							</Col>
							<Col span="7">
								<Row justify="end">
									<Space>
										<Button type="primary" htmlType="submit" shape="circle">
											<Icon type="ri-check-fill"></Icon>
										</Button>
										<Button
											type="ghost"
											shape="circle"
											onClick={() => setChildAddFormVisible(false)}
										>
											<Icon type="ri-close-line"></Icon>
										</Button>
									</Space>
								</Row>
							</Col>
						</Row>
					</Form>
				</Formik>
			)
		);
	};

	const RenderChildTagAddButton = () => {
		return (
			!childAddFormVisible && (
				<Row className="pt-16 pb-16">
					<span
						onClick={() => {
							setChildAddFormVisible(true);
							setCurrentChildTag(emptyTag);
						}}
					>
						<Space className="cursor_pointer color-blue-6">
							<Icon type="ri-add-circle-line"></Icon>
							<Text fontSize="14" lineHeight="22">
								{translate(`${TRANSLATION_BASE_PATH}._FORM._ADD_CHILD_BTN_TITLE`)}
							</Text>
						</Space>
					</span>
				</Row>
			)
		);
	};

	let submitHandler: any;

	const TagForm = () => {
		return (
			<>
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={tag}
					onSubmit={props.onSubmit}
					isInitialValid={false}
				>
					{formik => {
						const { handleSubmit, submitForm } = formik;
						submitHandler = submitForm;
						return (
							<form onSubmit={handleSubmit} autoComplete="off">
								<Row>
									<Input name="name" label={translate(`${TRANSLATION_BASE_PATH}._FORM._NAME`)} />
								</Row>
								{props.defaultTarget === "LESSON" && (
									<Space direction="vertical" size={8}>
										<Row>
											<InputNumber
												name="priority"
												label={translate(`${TRANSLATION_BASE_PATH}._FORM._PRIORITY`)}
											/>
										</Row>
										<Row>
											<FormikCheckboxField
												name="asyncOnly"
												defaultChecked={formik.initialValues.asyncOnly}
												disabled={!!props.Tag?.name}
											>
												{translate(`${TRANSLATION_BASE_PATH}._FORM._ASYNC_ONLY`)}
											</FormikCheckboxField>
										</Row>
										<Row>
											<FormikCheckboxField
												name="isGlobal"
												defaultChecked={formik.initialValues.isGlobal}
												onChange={(e: any) => onChangedCheckboxGlobal(e, formik)}
												disabled={!!props.Tag?.name}
											>
												{translate(`${TRANSLATION_BASE_PATH}._FORM._IS_GLOBAL`)}
											</FormikCheckboxField>
										</Row>
									</Space>
								)}
								{(props.defaultTarget !== "LESSON" || globalStatus) && (
									<>
										<Divider className="p-0 m-0" />
										<Row className="pt-16">
											<Text fontSize="14" lineHeight="22">
												{translate(`${TRANSLATION_BASE_PATH}._FORM._SUBTITLE`)}
											</Text>
										</Row>
									</>
								)}

								<Row className="pt-16">
									<Radio.Group
										onChange={e => formik.setFieldValue("type", e.target.value)}
										value={formik.values.type}
									>
										<Radio value={"category"}>Category</Radio>
										<Radio value={"dropdown"}>Dropdown</Radio>
									</Radio.Group>
								</Row>
							</form>
						);
					}}
				</Formik>
				{(props.defaultTarget !== "LESSON" || globalStatus) && RenderChildTagAddForm()}
				{(props.defaultTarget !== "LESSON" || globalStatus) && RenderChildTagAddButton()}
				{(props.defaultTarget !== "LESSON" || globalStatus) && RenderChildTags()}
			</>
		);
	};

	return (
		<CreateEditDrawer
			title={props.Title}
			onClose={props.toggleDrawer}
			onSave={() => submitHandler()}
			visible={props.drawerVisible}
			form={TagForm}
			width={window.innerWidth >= 576 ? 350 : window.innerWidth}
			submitting={props.submitting}
			saveBtnDisabled={props.submitting}
		/>
	);
};
