import React, { useState } from "react";
import { Card, Col, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Image from "common/components/general/Image";

interface IntroductionInfoModalProps {
	onClose: () => void;
}

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._COURSE_OVERVIEW._ASYNCH_INTRODUCTION_INFO_MODAL";

export const IntroductionInfoModal = (props: IntroductionInfoModalProps) => {
	const { t: translate } = useTranslation();
	const [visible, setVisible] = useState<boolean>(true);

	const onCancel = () => {
		setVisible(false);
		props.onClose();
	};

	return (
		<Modal
			visible={visible}
			title={null}
			footer={null}
			destroyOnClose
			onCancel={onCancel}
			className="asynch__classroom__introdaction_modal"
		>
			<Card
				cover={
					<div className="introduction__cover_content">
						<Image src={require("assets/images/asynch-classroom-welcome.svg")} />
					</div>
				}
			>
				<Row className="mb-8">
					<Col span={24} className="flex__center_justify_center text__align_center">
						<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._HEAD`)}
						</Text>
					</Col>
				</Row>
				<Row className="mb-24">
					<Col span={24} className="flex__center_justify_center text__align_center">
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
						</Text>
					</Col>
				</Row>
				<Row className="mb-24">
					<Col span={24} className="flex__center_justify_center">
						<Button type="primary" onClick={onCancel}>
							{translate(`${TRANSLATION_BASE_PATH}._OK_BUTTON`)}
						</Button>
					</Col>
				</Row>
				<Row className="mb-8">
					<Col span={24} className="flex__center_justify_center">
						<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
							{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
						</a>
					</Col>
				</Row>
			</Card>
		</Modal>
	);
};
