import React from "react";
import { Select as AntSelect } from "antd";
import { SelectProps as AntSelectProps } from "antd/lib/select";

export type SelectProps = AntSelectProps<string | string[] | number[]>;

const Input: React.FC<SelectProps> = props => {
	return <AntSelect {...props}>{props.children}</AntSelect>;
};

export default Input;
