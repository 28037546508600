import moment from "moment";

export const getListOfYears = (minYear: number, maxYear: number = +moment().format("YYYY")): number[] => {
	const years: number[] = [];

	while (maxYear >= minYear) {
		years.push(maxYear);
		maxYear--;
	}

	return years;
};
