import { Col, Row, Space } from "antd";
import React from "react";
import Text from "common/components/general/Text";
import { PARENT_PATH } from "../../..";
import { DASHBOARD_PATH } from "../../../../admin/scenes/dashboard";
import { Link } from "react-router-dom";
import { PARENT_DASHBOARD_PATH } from "../../../scenes/dashboard";
import Icon from "common/components/general/Icon";
import { useTranslation } from "react-i18next";

const TRANSLATION_BASE_PATH = "_PARENT._DASHBOARD._SUB_HEADER";

export const SubHeader = () => {
	const { t: translate } = useTranslation();

	const getContent = () => {
		const dashboardPathResult = <Text
			fontSize="20"
			lineHeight="28"
			wheight="semibold"
			className="color-gray-9"
		>
			{translate(`${TRANSLATION_BASE_PATH}._DASHBOARD_PATH`)}
		</Text>;

		const goBackLinkResult =
			<Link to={`/${PARENT_PATH}/${DASHBOARD_PATH}`}>
				<Space>
					<Text
						fontSize="20"
						lineHeight="28">
						<Icon type="ri-arrow-left-line" />
					</Text>
					<Text
						fontSize="20"
						lineHeight="28"
						wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._CHILD_SETTINGS_PATH`)}
					</Text>
				</Space>
			</Link>;

		return window.location.pathname === `/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`
			? dashboardPathResult : goBackLinkResult;
	};

	return (
		<Row className="parent-dashboard-subHeader">   
			<Col span="24">  
				{getContent()}
			</Col>
		</Row>
	);
};
