import React, { ReactNode } from "react";
import { Checkbox as AntCheckbox } from "antd";

interface CheckboxGroupElementProps {
	value?: any;
	children: ReactNode;
	className?: string;
}

const CheckboxGroupElement: React.FC<CheckboxGroupElementProps> = props => {
	return (
		<AntCheckbox {...props} className={`${props.className ?? ""} check__box__element`}>
			{props.children}
		</AntCheckbox>
	);
};

export default CheckboxGroupElement;
