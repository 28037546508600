import ApiServiceData from "../api.service";
const relativeUrl = "/v1/api/digitalize";

type Type = "" | "_REQUEST" | "_DONATE";
	
type Data = {
	city: string,
	address: string,
	device: string,
	school: string,
	age: string,
	phoneNumber: string
}

export default class DigitalizeEmailService extends ApiServiceData {
	public sendDigitalizeEmail(type: Type, data: Data): Promise<string> {
		return this.post(relativeUrl + "/contact", {
			...data, type
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
