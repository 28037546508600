import { Avatar, Col, Dropdown, Menu, Modal, Popconfirm, Row, Space, Table, Tooltip, Collapse } from "antd";
import React, { useState } from "react";
import Icon from "common/components/general/Icon";
import { UserAvatar } from "common/components/general/UserAvatar";
import { UserRole } from "./userRole/UserRole";
import Button from "common/components/general/Button";
import { ResponsiveSearchBox } from "../../../../../../admin/components/searchBox/ResponsiveSearchBox";
import { InviteUsers } from "./inviteUsers/InviteUsers";
import { useTranslation } from "react-i18next";
import InvitationService from "services/administration/invitation.service";
import { showMessage } from "common/utils/Notification";
import { ModalPermissions } from "./modalPermissions/ModalPermissions";
import UserService from "services/authentication/user.service";
import Text from "common/components/general/Text";

import { CaretRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import OAuth2Service from "services/authentication/oauth2.service";
import { createOrganizationEnrollmentChangeNotification } from "common/components/_notifications/util/createNotifications";
import { CreateOrganizationEnrollmentChangeNotificationDto } from "common/components/_notifications/dtos/CreateOrganizationEnrollmentChangeNotificationDto";
import OrganizationService from "services/administration/organization.service";

const { Panel } = Collapse;

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT._USERS_TAB";

export const UsersTab = (props: any) => {
	const { t: translate } = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [item, setItem] = useState<any>(null);
	const [searchText, setSearchText] = useState("");

	const PanelHeader = (panel: string) => {
		return (
			<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
				{translate(`${TRANSLATION_BASE_PATH}._TITLE_${panel}`)}
			</Text>
		);
	};

	const openConfirmationModal = (user: any, actionName: string, confirm: any) => {
		const actionVerb = translate(`${TRANSLATION_BASE_PATH}._ACTION.${actionName}`);
		return Modal.confirm({
			icon: <QuestionCircleOutlined />,
			content: translate(`${TRANSLATION_BASE_PATH}._ACTION_MESSAGE`, {
				action: actionVerb,
				student: user
					? `${user.firstName} ${user.lastName}`
					: translate(`${TRANSLATION_BASE_PATH}._ALL_PENDING`)
			}),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES`) + actionVerb,
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => confirm(user)
		});
	};

	const getUsers = (type: string) => {
		return props.users.filter((x: any) => {
			return x.status === type;
		});
	};

	const getFilteredActiveUsers = () => {
		if (!searchText) return getUsers("ACTIVE");
		const users = getUsers("ACTIVE");
		const searchPatetrns = searchText.replace(/ +(?= )/g, "").split(" ").map(p => p.trim());

		return users.filter((u: any) => searchPatetrns.find(p =>
			u.firstName?.toLowerCase().includes(p.toLocaleLowerCase())
			|| u.lastName?.toLowerCase().includes(p.toLocaleLowerCase())
			|| u.email?.toLowerCase().includes(p.toLocaleLowerCase())
		));

	};

	const updateStatus = async (item: any, newStatus: string) => {
		return new OrganizationService()
			.updateEnrollment({
				UserRoleId: item.UserRoleId,
				status: newStatus
			})
			.then(() => {
				createOrganizationEnrollmentChangeNotification(
					new CreateOrganizationEnrollmentChangeNotificationDto(
						item.UserId,
						newStatus,
						OAuth2Service.CurrentOrganization?.Name
					)
				);
				return props.loadUsers();
			});
	};

	const block = (item: any) => {
		updateStatus(item, "BLOCKED");
	};

	const DeleteUser = (item: any) => {
		return new UserService().deleteStaff(item.UserRoleId).then((result: any) => {
			showMessage(translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._USER_REMOVE_SUCCEEDED`));
			setModalVisible(false);
			return props.loadUsers();
		});
	};

	const RenderPendingStaff = () => {
		const accept = (item: any) => {
			updateStatus(item, "ACTIVE");
		};

		const cancelButton = (item: any): React.ReactNode => {
			return (
				<Button
					type="default"
					onClick={() => openConfirmationModal(item, "_CANCEL", DeleteUser)}
					className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__cancell"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._CANCEL`)}
				</Button>
			);
		};

		const acceptButton = (item: any): React.ReactNode => {
			return (
				<Button
					type="default"
					onClick={() => openConfirmationModal(item, "_ACCEPT", accept)}
					className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__accept"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._ACCEPT`)}
				</Button>
			);
		};

		const blockButton = (item: any): React.ReactNode => {
			return (
				<Button
					type="default"
					onClick={() => openConfirmationModal(item, "_BLOCK", block)}
					className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__block"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._BLOCK`)}
				</Button>
			);
		};

		const cancelAll = () => {
			getUsers("PENDING").forEach((element: any) => {
				updateStatus(element, "CANCELLED");
			});
		};

		const acceptAll = () => {
			getUsers("PENDING").forEach((element: any) => {
				updateStatus(element, "ACTIVE");
			});
		};

		const columns = [{
			title: "",
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Space>
						<UserAvatar userId={row.id} firstName={row.firstName} lastName={row.lastName} />
						{row.firstName}
						{row.lastName}
					</Space>
				);
			}
		},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				render: (text: any, row: any) => {
					return (
						<Row justify="end">
							<Space>
								{blockButton(row)}
								{cancelButton(row)}
								{acceptButton(row)}
							</Space>
						</Row>
					);
				}
			}];

		return (
			<Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
				<Panel
					header={PanelHeader("PENDING")}
					key="1"
					extra={
						<Row justify="end">
							<Space>
								<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._BTN._CANCEL_ALL_TOOLTIP`)}>
									<Button
										type="default"
										icon="ri-close-line"
										onClick={() => openConfirmationModal(null, "_CANCEL", cancelAll)}
										className="courseBuilder__courseDetails__studentList__btn__cancellAll"
									/>
								</Tooltip>
								<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._BTN._ACCEPT_ALL_TOOLTIP`)}>
									<Button
										type="default"
										icon="ri-check-fill"
										onClick={() => openConfirmationModal(null, "_ACCEPT", acceptAll)}
										className="courseBuilder__courseDetails__studentList__btn__acceptAll"
									/>
								</Tooltip>
							</Space>
						</Row>
					}
				>
					<Row>
						<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
							<Table dataSource={getUsers("PENDING")} columns={columns} showHeader={false} />
						</Col>
					</Row>
				</Panel>
			</Collapse>
		);
	};

	const RenderStaff = () => {
		const ResendInvite = (item: any) => {
			return new InvitationService().resendUserInvitation(item.id).then(() => {
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._INVITATION_RESEND_SUCCEEDED`));
			});
		};

		const DeleteInvite = (item: any) => {
			return new InvitationService().deleteInvite(item.id).then(() => {
				props.loadUsers();
				return showMessage(translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._INVITATION_DELETE_SUCCEEDED`));
			});
		};

		const ShowModal = (item: any) => {
			setModalVisible(!modalVisible);
			setItem(item);
		};

		const RenderUserMenu = (item: any) => {
			if (item.role === "_OWNER") return <></>;
			return (
				<Menu>
					<Menu.Item key="0" onClick={() => ShowModal(item)}>
						<Space direction="horizontal">
							<Icon type="ri-settings-4-line" />
							{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._MODIFY`)}
						</Space>
					</Menu.Item>
					<Menu.Item key="1">
						<Popconfirm
							title={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE_USER_CONFIRM_MESSAGE`)}
							onConfirm={() => DeleteUser(item)}
							okText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE`)}
							cancelText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._CANCEL`)}
						>
							<Space direction="horizontal">
								<Icon type="ri-delete-bin-7-line" />
								{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE`)}
							</Space>
						</Popconfirm>
					</Menu.Item>
					<Menu.Item key="3">
						<Popconfirm
							title={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._BLOCK_USER_CONFRIM_MESSAGE`)}
							onConfirm={() => updateStatus(item, "BLOCKED")}
							okText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._BLOCK`)}
							cancelText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._CANCEL`)}
						>
							<Space direction="horizontal">
								<Icon type="ri-stop-circle-line" />
								{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._BLOCK`)}
							</Space>
						</Popconfirm>
					</Menu.Item>
				</Menu>
			);
		};

		const RenderInviteMenu = (item: any) => {
			return (
				<Menu>
					<Menu.Item key="0">
						<Popconfirm
							title={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._RESEND_CONFIRM_MESSAGE`)}
							onConfirm={() => ResendInvite(item)}
							okText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._RESEND`)}
							cancelText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._CANCEL`)}
						>
							<Space>
								<Icon type="ri-send-plane-line" />
								{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._RESEND`)}
							</Space>
						</Popconfirm>
					</Menu.Item>
					<Menu.Item key="1">
						<Popconfirm
							title={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE_CONFIRM_MESSAGE`)}
							onConfirm={() => DeleteInvite(item)}
							okText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE`)}
							cancelText={translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._CANCEL`)}
						>
							<Space>
								<Icon type="ri-delete-bin-7-line" />
								{translate(`${TRANSLATION_BASE_PATH}._TABLE._MENU._DELETE`)}
							</Space>
						</Popconfirm>
					</Menu.Item>
				</Menu>
			);
		};

		const columns = [{
			title: "",
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Space size="middle">
						{row.type === "USER" && (
							<UserAvatar userId={row.id} firstName={row.firstName} lastName={row.lastName} />
						)}
						{row.type === "INVITE" && (
							<Avatar shape="square">
								<Icon type="ri-time-line" />
							</Avatar>
						)}
						{row.type === "USER" ? row.firstName + " " + row.lastName : row.email}
						{row.role !== "_TEACHER" && <UserRole role={row.role} />}
					</Space>
				);
			}
		},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				render: (text: any, row: any) => {
					return (
						<>
							<Row justify="end">
								<Dropdown.Button
									buttonsRender={() => [<></>,
										<Button
											type="default"
											shape="circle"
											className="materialItem__layout materialItem__button-circle color-gray-8"
										>
											<Icon type="ri-more-2-line" />
										</Button>]}
									trigger={["click"]}
									overlay={row.type === "USER" ? RenderUserMenu(row) : RenderInviteMenu(row)}
									icon={<Icon type="ri-more-2-line"></Icon>}
								/>
							</Row>
						</>
					);
				}
			}];

		const columnsSmallScreen = [{
			title: "",
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Row justify="space-between">
						{row.type === "USER" ? row.firstName + " " + row.lastName : row.email}
						<Dropdown.Button
							buttonsRender={() => [<></>,
									<Button
										type="default"
										shape="circle"
										className="materialItem__layout materialItem__button-circle color-gray-8"
									>
										<Icon type="ri-more-2-line" />
									</Button>]}
							trigger={["click"]}
							overlay={row.type === "USER" ? RenderUserMenu(row) : RenderInviteMenu(row)}
							icon={<Icon type="ri-more-2-line"></Icon>}
						/>
					</Row>
				);
			}
		}];

		const search = (value: string) => {
			setSearchText(value);
		};

		return (
			<Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
				<Panel header={PanelHeader("ACTIVE")} key="2">
					<Row>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{marginBottom: "5px"}}>
							<Row justify="start">
								<Button type="primary" onClick={() => setDrawerVisible(true)}>
									<Space>
										<Icon type="ri-add-line" />
										Shto
									</Space>
								</Button>
								<InviteUsers
									visible={drawerVisible}
									onClose={() => setDrawerVisible(false)}
									onRefresh={() => props.loadUsers()}
									currentUsers={getUsers("ACTIVE")}
								/>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<ResponsiveSearchBox
								placeholder={translate(`${TRANSLATION_BASE_PATH}._SEARCHBAR_PLACEHOLDER`)}
								onSearch={f => f}
								onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => search(value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
							<Table dataSource={getFilteredActiveUsers()} columns={columns} showHeader={false} />
						</Col>
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
							<Table dataSource={getFilteredActiveUsers()} columns={columnsSmallScreen} showHeader={false} />
						</Col>
					</Row>
					{item && (
						<ModalPermissions
							visible={modalVisible}
							onCancel={() => setModalVisible(false)}
							item={item}
							loadUsers={props.loadUsers}
						/>
					)}
				</Panel>
			</Collapse>
		);
	};

	const RenderBlockedStaff = () => {
		const unblock = (item: any) => {
			updateStatus(item, "ACTIVE");
		};

		const unblockButton = (item: any): React.ReactNode => {
			return (
				<Button
					type="default"
					onClick={() => openConfirmationModal(item, "_UNBLOCK", unblock)}
					className="courseBuilder__courseDetails__studentList__btn courseBuilder__courseDetails__studentList__btn__unblock"
				>
					{translate(`${TRANSLATION_BASE_PATH}._BTN._UNBLOCK`)}
				</Button>
			);
		};

		const columns = [{
			title: "",
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Space>
						<UserAvatar userId={row.id} firstName={row.firstName} lastName={row.lastName} />
						{row.firstName}
						{row.lastName}
					</Space>
				);
			}
		},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				render: (text: any, row: any) => {
					return (
						<Row justify="end">
							<Space>{unblockButton(row)}</Space>
						</Row>
					);
				}
			}];
		return (
			<Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
				<Panel header={PanelHeader("BLOCKED")} key="3">
					<Row justify="center">
						<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
							<Table dataSource={getUsers("BLOCKED")} columns={columns} showHeader={false} />
						</Col>
					</Row>
				</Panel>
			</Collapse>
		);
	};

	return (
		<>
			{getUsers("PENDING").length > 0 && RenderPendingStaff()}
			{RenderStaff()}
			{getUsers("BLOCKED").length > 0 && RenderBlockedStaff()}
		</>
	);
};
