import ApiServiceData from "../api.service";
import QuizUserResponseDto from "dtos/administration/quizUserResponse.dto";
const relativeUrl = "/v1/api/quiz-user-responses";

export default class QuizUserResponseService extends ApiServiceData {
	public getQuizUserResponseByUserIdLessonId(lessonId: string): Promise<QuizUserResponseDto> {
		return this.get(relativeUrl + "/" + lessonId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "No Quiz User Response found."
				)
					return null;
				throw error.response;
			});
	}

	public createQuizUserResponse(quizUserResponse: QuizUserResponseDto) {
		return this.post(relativeUrl, quizUserResponse)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public deleteQuizUserResponse(quizUserResponseId: string) {
		return this.delete(`${relativeUrl}/${quizUserResponseId}`).catch(error => {
			throw error.response;
		});
	}
}
