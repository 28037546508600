import React from "react";
import { Col, Divider, Row, Space } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import OAuth2Service from "services/authentication/oauth2.service";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";

interface LesonOverviewByTypeProps {
	item: any;
}

const getEndDateContentBasedOnLessonType = (item: any) => {
	switch (item.type) {
		case "VIDEO":
		case "QUIZ":
		case "ASSIGNMENT":
			return (
				<Space direction="horizontal" size={6}>
					<Icon type="ri-timer-line" className="color-gray-7" />
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						{moment(item.endDate).format("HH:mm")}
					</Text>
				</Space>
			);
		case "MEETING":
			return (
				<Space direction="horizontal" size={6}>
					<Icon type="ri-time-line" className="color-gray-7" />
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						{moment(item.startDate).format("HH:mm")} - {moment(item.endDate).format("HH:mm")}
					</Text>
				</Space>
			);
	}
};

export const LesonOverviewByType = ({ item }: LesonOverviewByTypeProps) => {
	const history = useHistory();
	const createdBy = `${item.classroom.UserCreatedBy.firstName} ${item.classroom.UserCreatedBy.lastName}`;

	const isTeacher = OAuth2Service.isTeacher;

	const url = isTeacher
		? `/course-builder/materials/${item.type === "VIDEO" ? "lesson" : item.type}/${item.id}?subjectPlanId=${
				item.subjectPlan.id
		  }&classroomId=${item.classroom.id}`
		: `/subjects/materials/video/${item.id}?virtualClass=true`;

	return (
		<div
			className="lesson__overview_by_type"
			onClick={() => {
				history.push(url);
			}}
		>
			<Row>
				<Col span={24} className="lesson__item_details">
					<Space direction="horizontal" size={12} align="start">
						<TypeIcon type={item.type} />
						<Space direction="vertical" size={4}>
							<Text fontSize="14" lineHeight="22" maxLength={45}>
								{item.name}
							</Text>
							{getEndDateContentBasedOnLessonType(item)}
						</Space>
					</Space>
					<Icon
						type="ri-arrow-right-line"
						className="cursor_pointer color-gray-7"
						onClick={() => {
							history.push(url);
						}}
					/>
				</Col>
			</Row>
			<Divider type="horizontal" />
			<Row>
				<Col span={12}>
					<Space direction="horizontal" size={4}>
						<Icon type="ri-door-open-line" className="color-gray-7" />
						<Text fontSize="12" lineHeight="20" className="color-gray-7" maxLength={16}>
							{item.classroom.name}
						</Text>
					</Space>
				</Col>
				<Col span={12}>
					<Space direction="horizontal" size={4}>
						<Icon type="ri-user-2-line" className="color-gray-7" />
						<Text fontSize="12" lineHeight="20" className="color-gray-7" maxLength={15}>
							{createdBy}
						</Text>
					</Space>
				</Col>
			</Row>
		</div>
	);
};
