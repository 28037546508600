import React from "react";
import { DatePicker as AntDatepicker } from "antd";
import { DatePickerProps as AntDatePickerProps } from "antd/lib/date-picker/index";

export type DatePickerProps = AntDatePickerProps;

const DatePicker: React.FC<DatePickerProps> = props => {
	return <AntDatepicker {...props} />;
};

export default DatePicker;
