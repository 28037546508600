import ApiServiceData from "../api.service";
import ClassroomDto from "../../dtos/administration/classroom.dto";

import axios from "axios";
import { ActiveFilters } from "../../scenes/PublicMarketplace/DropdownFilters";
const CLIENTID = process.env.REACT_APP_PUBLIC;

const relativeUrl = "/v1/api/classroom";
const relativePublicUrl = "/v1/api/public-classroom";

export interface ClassroomSearch {
	order: "asc" | "created";
	category?: string;
	subcategory?: string;
	featured?: boolean;
	trending?: boolean;
	popular?: boolean;
	organization: string;
	subject?: string;
	query?: string;
	limit: number;
	page: number;
	tags?: ActiveFilters;
}

export default class ClassroomService extends ApiServiceData {
	public getAllVirtualClassrooms(): Promise<object> {
		return this.get(relativeUrl)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllMyClassrooms(): Promise<object> {
		return this.get(relativeUrl + "/my-classrooms")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public classroomsSearch(params: ClassroomSearch): Promise<object> {
		return axios
			.get(
				(process.env.REACT_APP_URL || window.location.protocol + "//" + window.location.host) +
					relativeUrl +
					"/search",
				{
					params: {
						...params,
						tags: Object.values(params.tags ?? {})
					},
					headers: {
						BrowserBaseURL: window.location.protocol + "//" + window.location.host,
						ClientId: CLIENTID
					}
				}
			)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAllChildClassrooms(parentClassroomId: string): Promise<object> {
		return this.get(relativeUrl + "/child-classrooms/" + parentClassroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getPayPalOrderId(id: string): Promise<ClassroomDto> {
		return this.get(relativeUrl + "/pay-pal-order/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public getClassroomById(id: string): Promise<ClassroomDto> {
		return this.get(relativeUrl + "/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public getPublicClassroomById(id: string): Promise<ClassroomDto> {
		return this.publicGet(relativePublicUrl + "/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public getClassroomByCode(code: string): Promise<ClassroomDto> {
		return this.get(relativeUrl + "/code/" + code)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public createClassroom(createClassroom: any) {
		return this.post(relativeUrl, createClassroom);
	}

	public updateClassroom(id: string, createClassroom: any) {
		return this.update(relativeUrl + "/" + id, createClassroom);
	}

	public updateVirtualClassroom(id: string, createClassroom: any) {
		return this.update(relativeUrl + "/virtual/" + id, createClassroom);
	}

	public deleteVirtualClassroom(id: string) {
		return this.delete(relativeUrl + "/" + id);
	}

	public deleteAsyncClassroom(id: string) {
		return this.delete(relativeUrl + "/async/" + id);
	}

	public archiveClassroom(id: string) {
		return this.delete(relativeUrl + "/archive/" + id);
	}

	public unarchiveClassroom(id: string) {
		return this.update(relativeUrl + "/unarchive/" + id, {});
	}

	public publishClassroom(id: string) {
		return this.update(relativeUrl + "/publish/" + id, {});
	}

	public getTeacherTasks(classroomId: string): Promise<any> {
		return this.get(relativeUrl + "/teacher-tasks/" + classroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public getStudentTasks(classroomId: string): Promise<any> {
		return this.get(relativeUrl + "/student-tasks/" + classroomId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public getClassroomByGradeSubjectId(gradeSubjectId: string): Promise<ClassroomDto> {
		return this.get(relativeUrl + "/GradeSubject/" + gradeSubjectId)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Classroom not found"
				)
					return null;

				throw error.response;
			});
	}

	public findAllByUserIdWithoutInclude(): Promise<object> {
		return this.get(relativeUrl + "/without-include/teacher")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public findAllDistinctGradeSubjectId(): Promise<object> {
		return this.get(relativeUrl + "/all/distinct-GradeSubjectId")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getAssignmentsByStartDate(startDate: string): Promise<any[]> {
		return this.get(`${relativeUrl}/assignments/${startDate}`).then(res => {
			return res.data;
		});
	}

	public getAllWithLessons(): Promise<any[]> {
		return this.get(`${relativeUrl}/user/my-courses-with-lessons`).then(res => {
			return res.data;
		});
	}

	public getAsyncClassesByOrganization(): Promise<object> {
		return this.get(relativeUrl + "/async/byOrganization")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getVirtualClassesByOrganization(): Promise<object> {
		return this.get(relativeUrl + "/virtual/byOrganization")
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getForMarketPlaceNewClassrooms(searchFields: any): Promise<object> {
		return this.post(relativeUrl + "/marketplace/newClassrooms", searchFields)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getForMarketPlacePrivateOrgClassrooms(searchFields: any): Promise<object> {
		return this.post(relativeUrl + "/marketplace/privateOrgClassrooms", searchFields)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getForMarketClassroomsBySubjectId(searchFields: any): Promise<object> {
		return this.post(relativeUrl + "/marketplace/classrooms-by-subject-id", searchFields)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
