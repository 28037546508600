import React, { ReactNode, Component } from "react";
import { withTranslation } from "react-i18next";
import { Layout, Tabs, Space } from "antd";
import { Link } from "react-router-dom";

import Button from "common/components/general/Button";
import TabPane from "common/components/dataDisplay/TabPane";
import { MAIN_PATH } from "scenes/main";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { COURSES_PATH } from "scenes/main/scenes/courses/TeacherCourses";
import { TypeIcon } from "common/components/subject/subjectTypeIcon";
import LessonService from "services/administration/lesson.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { COURSE_BUILDER_PATH } from "../../../../../courseBuilder";
import AssignmentsDetails from "../../scenes/assignmentDetails/AssignementDetailsComponent";
import Skeleton from "common/components/feedback/Skeleton";
import { AssignmentGradingComponent } from "../../scenes/assignmentGrading/AssignmentGradingComponent";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";

export const RELATED_COURSE_PATH = ":classroomId";
const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._LAYOUT";
export const MATERIAL_INFO_FULL_PATH = "material/:materialId";

class MainMaterialInfoLayoutComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			materialId: "",
			lesson: null,
			activeTab: "1",
			loading: false,
			isAssignmentSection: false
		};
		this.getBackButton = this.getBackButton.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
	}

	componentDidMount() {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const userRole = currentUserInfo?.Roles && currentUserInfo?.Roles.length > 0 && currentUserInfo?.Roles[0];
		const activeTab = new URLSearchParams(this.props.location.search).get("active")?.substring(0, 1) || "1";
		this.setState({ userRole: userRole?.code?.toLowerCase() || "", activeTab });
		this.loadLesson();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.materialId !== this.state.materialId) this.loadLesson();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.materialId !== prevState.materialId) return { materialId: params.materialId };
		return null;
	}

	goBack = () => {
		const { history } = this.props;
		history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
	};

	loadLesson = async () => {
		this.setState({
			loading: true
		});
		const search = this.props.location.search;
		const queryString = new URLSearchParams(search);
		const classroomId = queryString.get("classroomId");
		const isAssignmentSection = queryString.get("isAssignmentSection") || false;
		const lesson = await new LessonService().getLessonById(this.state.materialId);
		if (!lesson)
			return this.props.history.push(
				`/${COURSE_BUILDER_PATH}/${this.state.userRole}/${classroomId}?${
					isAssignmentSection ? "assignment=true" : "content=true"
				}`
			);
		this.setState({
			lesson: lesson,
			classroomId: classroomId,
			loading: false,
			isAssignmentSection: isAssignmentSection
		});
	};

	getBackButton = (): React.ReactNode => {
		return (
			<Link
				to={`/${COURSE_BUILDER_PATH}/${this.state.userRole}/${this.state.classroomId}?${
					this.state.isAssignmentSection ? "assignment=true" : "content=true"
				}`}
				type="text"
			>
				<Space className="ml-24">
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						<Icon type="ri-arrow-left-line" />
					</Text>
					<TypeIcon type={this.state.lesson?.lessonType} fontSize={"32"} />
					<Text fontSize={"16"} lineHeight={"24"}>
						{this.state.lesson?.name}
					</Text>
				</Space>
			</Link>
		);
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	toggleClassroomEditMode = () => {
		this.setState({
			classroomEditMode: !this.state.classroomEditMode
		});
	};

	render() {
		const { t: translate } = this.props;

		return (
			<Skeleton loading={this.state.loading}>
				<Layout className="full__min_height background-color-gray-2">
					<Tabs
						centered={true}
						tabBarExtraContent={{
							left: this.getBackButton()
						}}
						className="courseBuilder__courseDetails-tab"
						defaultActiveKey={this.state.activeTab}
					>
						<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._INSTRUCTIONS`)} key="1">
							<AssignmentsDetails lesson={this.state.lesson} />
						</TabPane>
						<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._CONTENT`)} key="2">
							<AssignmentGradingComponent
								lesson={this.state.lesson}
								classroomId={this.state.classroomId}
								loadLesson={this.loadLesson}
							/>
						</TabPane>
					</Tabs>
				</Layout>
			</Skeleton>
		);
	}
}

export const MainMaterialInfoLayout = withTranslation()(MainMaterialInfoLayoutComponent);
