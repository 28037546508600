import React, { ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import Error from "./FormItemError";
import Radio from "../components/Radio";
import { FieldProps } from "./FieldProps";
import Label from "../components/Label";
import Text from "../../general/Text";

interface RadioProps extends FieldProps {
	children: ReactNode;
	buttonStyle?: "outline" | "solid";
	size?: SizeType;
	disabled?: boolean;
	defaultValue?: any;
	value?: any;
	onRadioChange?: (e: any) => void;
	labelInfo?: string | ReactNode;
	labelClassname?: string;
}

const FormikRadioField: React.FC<RadioProps> = props => {
	const [, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched && meta.error;

	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;

	const onChange = (e: any) => {
		context.setFieldValue(props.name, e.target.value);
		if (props.onRadioChange) props.onRadioChange(e);
	};

	return (
		<div>
			{props.label && (
				<Label htmlFor={props.id || props.name} className={props.labelClassname}>
					{props.label}{" "}
					{props.labelInfo ? (
						<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
							{props.labelInfo}
						</Text>
					) : null}
				</Label>
			)}
			<Radio {...props} onChange={onChange}>
				{props.children}
			</Radio>
			{error}
		</div>
	);
};

export default FormikRadioField;
