import { Button, Col, message, Modal, Row, Space } from "antd";
import React, { useState } from "react";
import Text from "common/components/general/Text";
import { useTranslation } from "react-i18next";
import WelcomeImage from "assets/images/administration-owner-welcome.svg";
import Image from "common/components/general/Image";
import OAuth2Service from "../../../../../../services/authentication/oauth2.service";
import { CircularIcon } from "../../../../../../common/components/general/CircularIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import Icon from "../../../../../../common/components/general/Icon";
import { InviteUsers } from "../orgContent/usersTab/inviteUsers/InviteUsers";
import { RequireApprovalToJoinOrg } from "./requireApprovalToJoinOrg/requireApprovalToJoinOrg";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._WELCOME_POPUP";

export const WelcomePopup = (props: any) => {
	const { t: translate } = useTranslation();
	const [drawerVisible, setDrawerVisible] = useState(false);

	const showDrawer = () => {
		props.onCancel();
		setDrawerVisible(true);
	};

	return (
		<>
			<Modal bodyStyle={{ padding: "0px" }} footer={null} visible={props.visible} onCancel={props.onCancel}>
				<Row justify="center" className="background-color-blue-1 pt-24 pb-24" style={{ borderRadius: "10px" }}>
					<Image src={WelcomeImage} />
				</Row>
				<Row justify="center" className="pt-24">
					<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
					</Text>
				</Row>
				<Row justify="center" className="p-24">
					<Text fontSize="14" lineHeight="22" className="color-gray-8 text__align_center">
						{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE_1`, {
							userFullName:
								OAuth2Service.CurrentUser.User.firstName +
								" " +
								OAuth2Service.CurrentUser.User.lastName,
							organizationName: props.organization.name
						})}
					</Text>
					<Text fontSize="14" lineHeight="22" className="color-gray-8 text__align_center">
						{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE_2`)}
					</Text>
				</Row>
				<Row className="p-16">
					<Col span="12">
						<Row justify="center" className="pb-16">
							<CircularIcon type="ri-building-4-line" fontSize="24" lineHeight="28" />
						</Row>
						<Row justify="center" className="pb-8">
							<Text fontSize="14" lineHeight="22" className="color-gray-7">
								{translate(`${TRANSLATION_BASE_PATH}._ORGANIZATION`)}
							</Text>
						</Row>
						<Row justify="center" className="pb-8">
							<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
								{props.organization.name}
							</Text>
						</Row>
					</Col>
					<Col span="12">
						<Row justify="center" className="pb-16">
							<CircularIcon type="ri-key-line" fontSize="24" lineHeight="28" />
						</Row>
						<Row justify="center" className="pb-8">
							<Text fontSize="14" lineHeight="22" className="color-gray-7">
								{translate(`${TRANSLATION_BASE_PATH}._CODE`)}
							</Text>
						</Row>
						<Row justify="center" className="pb-8">
							<Text fontSize="20" lineHeight="28" className="color-gray-9" wheight="semibold">
								{props.organization.code}
							</Text>
						</Row>
						<Row justify="center" className="pb-8">
							<CopyToClipboard
								text={props.organization.code}
								onCopy={(textCopied: any) => {
									message.success(
										translate("_ADMIN._MAIN._COPY_CLIPBOARD", {
											code: `${textCopied}`
										})
									);
								}}
							>
								<Text fontSize="14" lineHeight="32" className="color-blue-6 cursor_pointer">
									<Space>
										<Icon type="ri-file-copy-line" />
										{translate(`${TRANSLATION_BASE_PATH}._COPY`)}
									</Space>
								</Text>
							</CopyToClipboard>
						</Row>
					</Col>
				</Row>
				<Row justify="center" className="mb-24">
					<Button type="primary" className="pr-48 pl-48" onClick={() => showDrawer()}>
						{translate(`${TRANSLATION_BASE_PATH}._INVITE`)}
					</Button>
				</Row>
				<RequireApprovalToJoinOrg />
			</Modal>
			<InviteUsers
				visible={drawerVisible}
				onClose={() => setDrawerVisible(false)}
				onRefresh={() => props.refreshUsers()}
				currentUsers={[]}
			/>
		</>
	);
};
