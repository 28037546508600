import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	Layout,
	Tabs,
	Space,
	Menu,
	Dropdown,
	Modal,
	Skeleton,
	Row,
	Col,
	Card,
	Divider,
	message,
	Switch,
	Tooltip,
	Checkbox
} from "antd";
import { QuestionCircleOutlined, CloseOutlined, CheckOutlined, SyncOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Formik } from "formik";
import CopyToClipboard from "react-copy-to-clipboard";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import TabPane from "common/components/dataDisplay/TabPane";
import ClassroomService from "services/administration/classroom.service";
import { MAIN_PATH } from "scenes/main";
import { Role } from "services/domain/login/Role";
import TeachCourseOverview from "scenes/courseBuilder/scenes/courseDetails/scenes/TeacherCourseOverview/TeacherCourseOverview";
import StudentList from "scenes/courseBuilder/scenes/courseDetails/scenes/StudentList/StudentList";
import Asignments from "scenes/courseBuilder/scenes/courseDetails/scenes/Asignments/Asignments";
import SubjectPlanCreator from "../../scenes/SubjectPlanCreator/SubjectPlanCreator";
import { COURSES_PATH } from "scenes/main/scenes/courses/TeacherCourses";
import EnrollmentService from "services/administration/enrollment.service";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { showMessage } from "common/utils/Notification";
import { formValidator } from "./ValidationSchema";
import FormikDefaultInputField from "common/components/dataEntry/formik/FormikInputField";
import FormikSelectField from "common/components/dataEntry/formik/FormikSelectField";
import GradeService from "services/administration/grade.service";
import { getLevelIcon } from "services/domain/administration/Grade";
import { getUuidCode } from "common/utils/UuidCode";
import Option from "common/components/dataEntry/components/Option";
import { COURSE_BUILDER_PATH } from "../../../..";
import { HtmlInput } from "common/components/dataEntry/formik/FormikHtmlInput";
import { OrganizationSectorType } from "services/domain/administration/Organization";
import FormikDatePickerField from "common/components/dataEntry/formik/FormikDatePickerField";
import { Uploader } from "common/components/uploader/Uploader";
import { previewFile } from "../../../../utils/FileUtils";
import Jimp from "jimp";
import BucketS3Service from "services/s3/s3.service";
import S3ActionType from "services/domain/administration/S3Action";
import FileDto from "dtos/administration/file.dto";
import moment from "moment";
import PublishConfirmationModal from "./components/PublishConfirmationModal";

export const RELATED_COURSE_PATH = ":classroomId";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER._COURSE_DETAILS._LAYOUT";
const MARKETPLACE_COURSE_CATEGORIES = "_MARKETPLACE._COURSE_CATEGORIES";

class MainTeacherLayoutComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			classroomId: "",
			classroom: {},
			classroomInfo: {},
			gradesList: {},
			subjectsList: {},
			stagesList: [],
			activeTab: "1",
			enrolledStudents: null,
			refreshToggle: false,
			classroomEditMode: false,
			justCreated: props.location?.state?.justCreated,
			generatingNewCode: false,
			togglingRequireApproval: false,
			filesToDelete: []
		};
		this.getBackButton = this.getBackButton.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
	}

	async componentDidMount() {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		this.setState({ currentUserId: currentUserInfo?.UserId });

		const { t: translate } = this.props;
		const {
			history,
			match: { params }
		} = this.props;

		const search = this.props.location.search;
		const params2 = new URLSearchParams(search);
		const activeContent = params2.get("content");
		const activeAssignment = params2.get("assignment");
		const goToStudentList = params2.get("goToStudentList");

		this.setState({ activeTab: activeContent ? "2" : activeAssignment ? "4" : goToStudentList ? "3" : "1" });

		if (goToStudentList && goToStudentList !== "tab3") {
			showMessage(translate(`${TRANSLATION_BASE_PATH}._NEW_STUDENT_APPROVAL`, { studentName: goToStudentList }));
		}

		if (!params.classroomId) return history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		await this.loadClassroom();
		await this.getImage();
	}

	async componentDidUpdate(prevProps: any, prevState: any) {
		if (prevState.classroomId !== this.state.classroomId) this.loadClassroom();
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const {
			match: { params }
		} = nextProps;

		if (params.classroomId !== prevState.classroomId) return { classroomId: params.classroomId };
		return null;
	}

	loadClassroom = async () => {
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		const [classroom, enrolledStudents] = await Promise.all([
			new ClassroomService().getClassroomById(this.state.classroomId),
			new EnrollmentService().getAllEnrollmentsByClassroomId(this.state.classroomId)
		]);
		if (!classroom) return this.props.history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		if (classroom && classroom.isAsync) return this.props.history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		if (currentUserInfo?.Roles?.[0]?.code !== Role.Owner && currentUserInfo?.UserId !== classroom.createdBy)
			return this.props.history.push(`/${MAIN_PATH}/${COURSES_PATH}`);
		this.setState({
			classroom: classroom,
			enrolledStudents: enrolledStudents,
			refreshToggle: !this.state.refreshToggle,
			organization: classroom.Organization,
			classroomInfo: {
				...classroom,
				name: classroom.name,
				stage: classroom.GradeSubject?.Grade?.level,
				gradeId: classroom.GradeSubject?.Grade?.id,
				subjectId: classroom.GradeSubject?.Subject?.id,
				subjectName: classroom.GradeSubject?.Subject?.name,
				code: classroom.code,
				endDate: classroom.endDate
			}
		});
	};

	goBack = () => {
		const { history } = this.props;
		if (this.state.classroom && this.state.classroom?.ParentClassroomId && !OAuth2Service.isStudent) {
			history.push(`/${COURSE_BUILDER_PATH}/individual/${this.state.classroom?.ParentClassroomId}`);
		} else {
			history.goBack()
		}
	};

	getBackButton = (): React.ReactNode => {
		return (
			<Button
				onClick={() => {
					this.goBack();
				}}
				type="text"
			>
				<Space>
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						<Icon type="ri-arrow-left-line" />
					</Text>
					<Text maxLength={25} fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{this.state?.classroom?.name}
					</Text>
				</Space>
			</Button>
		);
	};

	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	openConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: <QuestionCircleOutlined />,
			title: translate(`${TRANSLATION_BASE_PATH}._DELETE_TITLE`),
			content: translate(`${TRANSLATION_BASE_PATH}._DELETE_MESSAGE`, {
				class: `${this.state?.classroom?.name}`
			}),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.deleteClassroom()
		});
	};

	deleteClassroom = () => {
		return new ClassroomService()
			.deleteVirtualClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	openArchiveConfirmationModal = () => {
		const { t: translate } = this.props;
		return Modal.confirm({
			icon: null,
			title: (
				<Space size={16} align="start">
					<Icon type="ri-inbox-archive-line" className="color-gray-6" fontSize="24" />
					<Space direction="vertical" size={8}>
						<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_TITLE`)}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_MESSAGE`)}{" "}
						</Text>
						<Text fontSize="12" lineHeight="20" className="color-gray-9">
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE_MESSAGE_DESCRIPTION`)}{" "}
							<a href="https://akademial.tawk.help" target="_blank" rel="noopener noreferrer">
								{translate(`${TRANSLATION_BASE_PATH}._LEARN_MORE`)}
							</a>
						</Text>
					</Space>
				</Space>
			),
			okText: translate(`${TRANSLATION_BASE_PATH}._ARCHIVE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () => this.archiveClassroom(),
			okType: "primary"
		});
	};

	archiveClassroom = () => {
		return new ClassroomService()
			.archiveClassroom(this.state.classroomId)
			.then(() => {
				this.goBack();
				return;
			})
			.catch(error => {
				console.log(error);
			});
	};

	toggleClassroomEditMode = async () => {
		if (this.state.stagesList.length === 0) {
			await this.loadData();
		}

		this.setState({
			classroomEditMode: !this.state.classroomEditMode
		});
	};

	loadData = () => {
		const { t: translate } = this.props;

		return new GradeService()
			.getAllGrades()
			.then((grades: any) => {
				return _.chain(grades)
					.groupBy(grade => grade.level)
					.map((value, key) => ({ level: key, grades: value }))
					.value();
			})
			.then(allGrades => {
				const levels = allGrades.map(grade => {
					return {
						id: grade.level,
						name: translate(`_ADMIN._LEVELS._${grade.level}`),
						icon: getLevelIcon(grade.level),
						grades: grade.grades
					};
				});
				return this.setState({ stagesList: levels, subjectsList: [] });
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	resetGradeSelection(formik: any) {
		formik.setFieldValue("gradeId", "");
		formik.setFieldValue("subjectId", "");
	}

	resetSubjectSelection(formik: any) {
		formik.setFieldValue("subjectId", "");
	}

	getSubjectsOptions = (formik: any) => {
		const level = this.state.stagesList.find((stage: any) => stage.id === formik.values.stage);
		const grade = level.grades.find((grade: any) => grade.id === formik.values.gradeId);
		const subjects = grade?.GradeSubjects.map((gradeSubject: any) => gradeSubject.Subject);
		return subjects?.map((subject: any) => (
			<Option key={subject.id} value={subject.id}>
				{subject.name}
			</Option>
		));
	};

	createFile = (fileUploaded: any): FileDto => {
		const file = fileUploaded;
		if (file) {
			file.updatedBy = this.state.currentUserId;
			file.createdBy = this.state.currentUserId;
		}
		return file;
	};

	saveClassroom(item: any) {
		const { t: translate } = this.props;
		const file = this.createFile(this.state.fileUploaded);

		this.setState({ submitting: true });
		return new ClassroomService()
			.updateVirtualClassroom(item.id, {
				id: item.id,
				name: item.name,
				description: item?.description,
				price: Number(item?.price ?? 0),
				GradeSubject: {
					GradeId: item.gradeId,
					SubjectId: item.subjectId
				},
				File: this.state.fileUploaded === null ? null : file,
				FileId: this.state.fileUploaded === null ? null : file?.id,
				updatedBy: this.state.currentUserId,
				endDate: item.endDate ? new Date(item.endDate).toISOString() : null,
				featured: item.featured,
				popular: item.popular,
				trending: item.trending
			})
			.then((result: any) => {
				return showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
			})
			.then(() => {
				return this.loadClassroom();
			})
			.finally(() => {
				this.state.filesToDelete.forEach((file: any) => {
					this.deleteFromS3(file);
				});
				this.setState(
					{
						submitting: false,
						newCoverPhoto: false
					},
					() => this.toggleClassroomEditMode()
				);
				this.getImage();
			});
	}

	onUploadingDone = async (fileUploaded: any) => {
		this.setState({ fileUploaded, newCoverPhoto: true });
		await this.getImage(fileUploaded);
	};

	getImage = async (file?: any) => {
		if (!file && !this.state.classroomInfo?.File) {
			this.setState({ imagePhto: undefined });
			return;
		}
		const response = await previewFile(file ? file : this.state.classroomInfo?.File);
		const imagePhoto = await this.transformFileDownload(response.url);
		return this.setState({ imagePhto: imagePhoto });
	};

	transformFileDownload = (imageUrl: any) => {
		return Jimp.read(imageUrl).then(image => {
			const width = image.bitmap.width;
			const height = image.bitmap.width / 4;
			return image
				.cover(width, height)
				.autocrop({ cropSymmetric: true })
				.getBase64Async(Jimp.MIME_JPEG);
		});
	};

	onUploadingStarted = (flag: boolean) => {
		this.setState({
			loading: flag
		});
	};

	onSoftDeleteFile = (file: any) => {
		this.setState({
			filesToDelete: [...this.state.filesToDelete, file],
			fileUploaded: undefined
		});
	};

	closeImagePreview = () => {
		this.setState({ imagePhto: undefined });
		this.deleteFromS3(this.state.fileUploaded);
	};

	deleteCoverPhoto = () => {
		if (this.state.classroomInfo?.File) {
			this.setState({
				filesToDelete: [...this.state.filesToDelete, this.state.classroomInfo?.File],
				fileUploaded: null,
				imagePhto: undefined
			});
		}
	};

	editCoverPhoto = () => {
		if (this.state.classroomInfo?.File) {
			this.setState({
				filesToDelete: [...this.state.filesToDelete, this.state.classroomInfo?.File],
				fileUploaded: undefined,
				imagePhto: undefined
			});
		}
	};

	deleteFromS3 = (file: any) => {
		return new BucketS3Service()
			.getS3UrlForAction(`${file.filePath}/${file.name}`, S3ActionType.DELETE)
			.then(s3SignedUrl => {
				return fetch(s3SignedUrl, { method: "DELETE", body: file.name });
			});
	};

	generateNewCode = () => {
		const { t: translate } = this.props;

		if (this.state.generatingNewCode) return;

		this.setState({ generatingNewCode: true });
		const newCode = getUuidCode();
		return new ClassroomService()
			.updateVirtualClassroom(this.state.classroom.id, {
				code: newCode,
				id: this.state.classroom.id,
				name: this.state.classroom.name,
				GradeSubject: {
					GradeId: this.state.classroom.GradeSubject.Grade.id,
					SubjectId: this.state.classroom.GradeSubject.Subject.id
				},
				updatedBy: this.state.currentUserId
			})
			.then(() => {
				return this.setState(
					{
						classroom: {
							...this.state.classroom,
							code: newCode
						}
					},
					() => {
						message.success(translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._CODE_CHANGE_SUCCESS`));
					}
				);
			})
			.finally(() => {
				this.setState({ generatingNewCode: false });
			});
	};

	toggleRequireApproval(value: any) {
		const { t: translate } = this.props;

		this.setState({ togglingRequireApproval: true });
		return new ClassroomService()
			.updateVirtualClassroom(this.state.classroom.id, {
				requireApproval: value,
				id: this.state.classroom.id,
				name: this.state.classroom.name,
				GradeSubject: {
					GradeId: this.state.classroom.GradeSubject.Grade.id,
					SubjectId: this.state.classroom.GradeSubject.Subject.id
				},
				updatedBy: this.state.currentUserId
			})
			.then(() => {
				return this.setState(
					{
						classroom: {
							...this.state.classroom,
							requireApproval: value
						}
					},
					() => {
						message.success(
							value
								? translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._APPROVAL_ENABLED_SUCCESS`)
								: translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._APPROVAL_DISABLED_SUCCESS`)
						);
					}
				);
			})
			.finally(() => {
				this.setState({ togglingRequireApproval: false });
			});
	}

	cancelEditting = async () => {
		this.toggleClassroomEditMode();
		await this.loadClassroom();
		await this.getImage();
	};

	render() {
		const { t: translate } = this.props;

		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;

		const currentOrganization = currentUserInfo?.Organizations[0];
		const userRole =
			currentUserInfo?.Roles &&
			currentUserInfo.Roles.length > 0 &&
			currentUserInfo.Roles.sort((first: any, second: any) => Number(second?.priority) - Number(first?.priority))[0]?.code;

		const classroomOrganization = this.state?.classroom?.Organization;

		const menu = (
			<Menu>
				{this.state.classroom && this.state.classroom.status === "ACTIVE" && (
					<Menu.Item key="0" onClick={this.toggleClassroomEditMode}>
						<Space direction="horizontal">
							<Icon type="ri-edit-line" />
							{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
						</Space>
					</Menu.Item>
				)}
				{this.state.classroom && this.state.enrolledStudents && this.state.enrolledStudents.length > 0 ? (
					<Menu.Item key="1" disabled>
						<Tooltip title={translate(`${TRANSLATION_BASE_PATH}._DELETE_DISABLED_TOOLTIP`)}>
							<div>
								<Space direction="horizontal">
									<Icon type="ri-delete-bin-7-line" />
									{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
								</Space>
							</div>
						</Tooltip>
					</Menu.Item>
				) : (
					<Menu.Item key="1" onClick={this.openConfirmationModal}>
						<Space direction="horizontal">
							<Icon type="ri-delete-bin-7-line" />
							{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
						</Space>
					</Menu.Item>
				)}
				{this.state.classroom && this.state.classroom.status === "ACTIVE" && (
					<Menu.Item key="1" onClick={this.openArchiveConfirmationModal}>
						<Space direction="horizontal">
							<Icon type="ri-inbox-archive-line" />
							{translate(`${TRANSLATION_BASE_PATH}._ARCHIVE`)}
						</Space>
					</Menu.Item>
				)}
			</Menu>
		);

		let submitHandler: any;

		const xsMenu = (
			<Menu>
				<Menu.Item key="0" onClick={this.toggleClassroomEditMode}>
					<Space direction="horizontal">
						<Icon type="ri-close-line" />
						{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_CANCEL`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="1" onClick={() => submitHandler()}>
					<Space direction="horizontal">
						<Icon type="ri-save-line" />
						{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_SAVE`)}
					</Space>
				</Menu.Item>
			</Menu>
		);

		return (
			<Skeleton loading={!(this.state.classroom && this.state.organization)}>
				{this.state.classroom && this.state.organization && !this.state.classroomEditMode && (
					<Layout className="full__min_height background-color-gray-2">
						<Tabs
							centered={true}
							tabBarExtraContent={{
								left: this.getBackButton(),
								right: (
									<Space>
										{this.state.classroom.status === "DRAFT" && (
											<Button type="primary" onClick={() => PublishConfirmationModal({
												classroomId: this.state.classroomId,
												onFinish: this.loadClassroom,
												translate
											})}>
												{translate("_COURSE_BUILDER._COURSE_DETAILS._LAYOUT._PUBLISH")}
											</Button>
										)}
										<Dropdown.Button
											buttonsRender={() => [<></>, this.getCircleDropDownButton()]}
											trigger={["click"]}
											overlay={menu}
											icon={<Icon type="ri-more-2-line"></Icon>}
										/>
									</Space>
								)
							}}
							className="courseBuilder__courseDetails-tab"
							defaultActiveKey={this.state.activeTab}
						>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._HEAD`)} key="1">
								<TeachCourseOverview
									classroom={this.state.classroom}
									classroomEditMode={false}
									setEditMode={() => null}
									enrolledStudents={this.state.enrolledStudents}
									organization={this.state.organization}
									justCreated={this.state.justCreated}
									{...this.props}
								/>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._CONTENT`)} key="2">
								<SubjectPlanCreator
									classroom={this.state.classroom}
									{...this.props}
									reloadClassroom={this.loadClassroom}
									enrolledStudents={this.state.enrolledStudents}
								/>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._STUDENTS`)} key="3">
								<StudentList
									refreshToggle={this.state.refreshToggle}
									classroom={this.state.classroom}
									enrolledStudents={this.state.enrolledStudents}
									reloadClassroom={this.loadClassroom}
									{...this.props}
								/>
							</TabPane>
							<TabPane tab={translate(`${TRANSLATION_BASE_PATH}._ASIGNMENTS`)} key="4">
								<Asignments classroom={this.state.classroom} {...this.props} />
							</TabPane>
						</Tabs>
					</Layout>
				)}
				{this.state.classroomEditMode && (
					<Layout className="full__min_height background-color-gray-2">
						<Row className="background-color-gray-1 teacher__layout__editClass__header" align="middle">
							<Col xs={21} sm={21} md={16} lg={16} xl={16} xxl={16}>
								<Row justify="start" className="pl-24">
									<Space>
										<Icon
											onClick={this.toggleClassroomEditMode}
											className="cursor_pointer"
											fontSize="16"
											type="ri-arrow-left-line"
										></Icon>
										<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
											{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._TITLE`)}
										</Text>
									</Space>
								</Row>
							</Col>
							<Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}>
								<Row justify="end" className="pr-24">
									<Space>
										<Button type="default" size="middle" onClick={this.cancelEditting}>
											{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_CANCEL`)}
										</Button>
										<Button
											type="primary"
											size="middle"
											loading={this.state.submitting}
											disabled={this.state.submitting}
											onClick={() => submitHandler()}
										>
											{translate(`${TRANSLATION_BASE_PATH}._EDIT_CLASS._BTN_SAVE`)}
										</Button>
									</Space>
								</Row>
							</Col>
							<Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0}>
								<Row justify="end" className="pr-24">
									<Dropdown.Button
										buttonsRender={() => [
											<></>,
											<Button
												key="1"
												type="default"
												shape="circle"
												className="materialItem__layout materialItem__button-circle color-gray-8"
											>
												<Icon type="ri-more-2-line" />
											</Button>
										]}
										trigger={["click"]}
										overlay={xsMenu}
										icon={<Icon type="ri-more-2-line" />}
									/>
								</Row>
							</Col>
						</Row>
						<Row justify="center">
							<Col xs={22} sm={20} md={18} lg={16} xl={12} xxl={12}>
								<Card className="mt-16 update-live-course-card">
									<Formik
										enableReinitialize={true}
										initialValues={this.state.classroomInfo}
										onSubmit={values => this.saveClassroom(values)}
										validationSchema={formValidator(translate)}
									>
										{formik => {
											const { handleSubmit, submitForm } = formik;
											submitHandler = submitForm;
											return (
												<form onSubmit={handleSubmit} autoComplete="off">
													<Row>
														<Col span={24}>
															{this.state.imagePhto && !this.state.newCoverPhoto && (
																<div className="cover-photo">
																	<img src={this.state.imagePhto} className="image" />
																	<Icon
																		type="ri-edit-line"
																		className="edit-cover-photo"
																		onClick={this.editCoverPhoto}
																	/>
																	<Icon
																		type="ri-delete-bin-7-line"
																		className="remove-cover-photo"
																		onClick={this.deleteCoverPhoto}
																	/>
																</div>
															)}
															{(!this.state.imagePhto || this.state.newCoverPhoto) && (
																<div className="upload-photo">
																	<p className="title">
																		{translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._FOTO`
																		)}
																	</p>
																	<p className="description">
																		{translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._FOTO_SUGGESTION_TEXT`
																		)}{" "}
																		<a
																			target="_blank"
																			rel="noopener noreferrer"
																			href="https://unsplash.com/"
																		>
																			Unsplash.com
																		</a>{" "}
																		{translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._FOTO_SUGGESTION_OR`
																		)}{" "}
																		<br />
																		<a
																			target="_blank"
																			rel="noopener noreferrer"
																			href="https://www.pexels.com/"
																		>
																			Pexels.com
																		</a>
																	</p>
																	<Uploader
																		fileType="IMAGE"
																		filePath={`classroom/${this.state.classroomId}`}
																		fileWithUniqueId={false}
																		onUploadingDone={this.onUploadingDone}
																		showUploadList={false}
																		onUploadingStarted={this.onUploadingStarted}
																		onSoftDeleteFile={this.onSoftDeleteFile}
																		className="upload-button"
																	/>
																	{this.state.imagePhto && (
																		<div className="uploaded-image-preview">
																			<img
																				src={this.state.imagePhto}
																				className="image"
																			/>
																			<p className="image-name">
																				{this.state.fileUploaded.name}
																			</p>
																			<Icon
																				type="ri-close-fill"
																				className="remove-image"
																				onClick={this.closeImagePreview}
																			/>
																		</div>
																	)}
																</div>
															)}
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikDatePickerField
																name="endDate"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._DATE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._DATE_PLACEHOLDER`
																)}
																className="full__width"
																dateFormat="YYYY-MM-DD"
																defaultValue={
																	formik.initialValues.endDate
																		? moment(formik.initialValues.endDate)
																		: undefined
																}
															/>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikDefaultInputField
																name="name"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._TITLE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._TITLE_PLACEHOLDER`
																)}
															/>
														</Col>
													</Row>
													{this.state?.organization?.sector ===
														OrganizationSectorType.Public && (
														<>
															<Row>
																<Col span={24}>
																	<HtmlInput
																		name="description"
																		label={translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._DESCRIPTION`
																		)}
																	/>
																</Col>
															</Row>
															<Row>
																<Col span={24}>
																	<FormikDefaultInputField
																		name="price"
																		label={translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._PRICE`
																		)}
																	/>
																</Col>
															</Row>
														</>
													)}
													<Row>
														<Col span={24}>
															<FormikSelectField
																name="stage"
																onChange={e => this.resetGradeSelection(formik)}
																defaultValue={formik.initialValues.stage}
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._STAGE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._STAGE_PLACEHOLDER`
																)}
															>
																{this.state.stagesList.map((chapter: any) => (
																	<Option key={chapter.id} value={chapter.id}>
																		{chapter.name}
																	</Option>
																))}
															</FormikSelectField>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikSelectField
																name="gradeId"
																onChange={e => this.resetSubjectSelection(formik)}
																defaultValue={formik.initialValues.gradeId}
																value={formik.values.gradeId}
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._GRADE`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._GRADE_PLACEHOLDER`
																)}
															>
																{this.state.stagesList
																	.filter(
																		(stage: any) => stage.id === formik.values.stage
																	)
																	.flatMap((stage: { grades: any }) => stage.grades)
																	.map((chapter: any) => (
																		<Option key={chapter.id} value={chapter.id}>
																			{chapter.name}
																		</Option>
																	))}
															</FormikSelectField>
														</Col>
													</Row>
													<Row>
														<Col span={24}>
															<FormikSelectField
																name="subjectId"
																label={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._SUBJECT`
																)}
																placeholder={translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._FORM._SUBJECT_PLACEHOLDER`
																)}
																size="middle"
																defaultValue={formik.initialValues.subjectName}
																value={formik.values.subjectId}
																showSearch
																filterOption={(
																	inputValue: string,
																	option?: any
																): boolean => {
																	return option.children
																		.toLowerCase()
																		.includes(inputValue.toLowerCase());
																}}
															>
																{this.getSubjectsOptions(formik)}
															</FormikSelectField>
														</Col>
													</Row>
													{(!currentOrganization.ParentOrganizationId && currentOrganization.sector === "PRIVATE" && userRole === Role.Admin) ||
													userRole === Role.Owner && currentOrganization?.OrganizationId === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65" &&
													(classroomOrganization?.sector === "PUBLIC" || classroomOrganization?.id === "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65") && (
														<Row justify="center" className="mb-24">
															<Col span={18}>
																<Checkbox
																	checked={formik.values.featured}
																	onChange={e => {
																		formik.setFieldValue("featured", e.target.checked);
																	}}
																>
																	{translate(`${MARKETPLACE_COURSE_CATEGORIES}._FEATURED`)}
																</Checkbox>
																<Checkbox
																	checked={formik.values.trending}
																	onChange={e => {
																		formik.setFieldValue("trending", e.target.checked);
																	}}
																>
																	{translate(`${MARKETPLACE_COURSE_CATEGORIES}._TRENDING`)}
																</Checkbox>
																<Checkbox
																	checked={formik.values.popular}
																	onChange={e => {
																		formik.setFieldValue("popular", e.target.checked);
																	}}
																>
																	{translate(`${MARKETPLACE_COURSE_CATEGORIES}._POPULAR`)}
																</Checkbox>
															</Col>
														</Row>
													)}
												</form>
											);
										}}
									</Formik>
									{this.state?.organization?.sector === OrganizationSectorType.Private && (
										<>
											<Divider />
											<Row>
												<Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={12}>
													<Row align="middle">
														<Col span="3">
															<Row justify="start">
																<Icon
																	type="ri-building-line"
																	fontSize="24"
																	className="color-blue-6"
																/>
															</Row>
														</Col>
														<Col span="21">
															<Row>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-7"
																>
																	{translate(
																		`${TRANSLATION_BASE_PATH}._EDIT_CLASS._SCHOOL`
																	)}
																</Text>
															</Row>
															<Row>
																<Text
																	fontSize="20"
																	lineHeight="28"
																	className="color-gray-8"
																>
																	{this.state.organization?.name}
																</Text>
															</Row>
														</Col>
													</Row>
													<Row className="pb-16">
														<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}></Col>
													</Row>
												</Col>
												<Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={12}>
													<Row justify="start" align="middle">
														<Col span="3">
															<Row justify="start">
																<Icon
																	type="ri-key-line"
																	fontSize="24"
																	className="color-blue-6"
																></Icon>
															</Row>
														</Col>
														<Col span="12">
															<Row>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-7"
																>
																	{translate(
																		`${TRANSLATION_BASE_PATH}._EDIT_CLASS._CLASS_CODE`
																	)}
																</Text>
															</Row>
															<Space>
																<Text
																	fontSize="20"
																	lineHeight="28"
																	className="color-gray-7"
																>
																	{this.state.generatingNewCode ? (
																		<SyncOutlined spin />
																	) : (
																		this.state.classroom.code
																	)}
																</Text>
																{!this.state.generatingNewCode && (
																	<CopyToClipboard
																		text={this.state.classroom.code}
																		onCopy={(textCopied: any) => {
																			message.success(
																				translate(
																					"_ADMIN._MAIN._COPY_CLIPBOARD",
																					{
																						code: `${textCopied}`
																					}
																				)
																			);
																		}}
																	>
																		<Text
																			fontSize="14"
																			lineHeight="32"
																			className="color-blue-6 cursor_pointer"
																		>
																			<Icon type="ri-file-copy-line" />
																		</Text>
																	</CopyToClipboard>
																)}
															</Space>
														</Col>
														<Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
															<Text
																fontSize="14"
																lineHeight="22"
																className="color-blue-6 cursor_pointer"
																onClick={this.generateNewCode}
															>
																{translate(
																	`${TRANSLATION_BASE_PATH}._EDIT_CLASS._GENERATE_NEW_CODE`
																)}
															</Text>
														</Col>
													</Row>
												</Col>
											</Row>
											<Divider />
											<Row>
												<Skeleton loading={this.state.togglingRequireApproval}>
													<Col span="21">
														<Row>
															<Text
																fontSize="16"
																lineHeight="24"
																className="color-gray-9"
																wheight="semibold"
															>
																{this.state.classroom.requireApproval
																	? translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._APPROVE_ENABLED_TITLE`
																	  )
																	: translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._APPROVE_DISABLED_TITLE`
																	  )}
															</Text>
														</Row>
														<Row>
															<Text
																fontSize="14"
																lineHeight="22"
																className="color-gray-8"
															>
																{this.state.classroom.requireApproval
																	? translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._APPROVE_ENABLED_SUBTITLE`
																	  )
																	: translate(
																			`${TRANSLATION_BASE_PATH}._EDIT_CLASS._APPROVE_DISABLED_SUBTITLE`
																	  )}
															</Text>
														</Row>
													</Col>
													<Col span="3">
														<Row justify="end" align="middle">
															<Switch
																checkedChildren={<CheckOutlined />}
																unCheckedChildren={<CloseOutlined />}
																onChange={value => this.toggleRequireApproval(value)}
																checked={this.state.classroom.requireApproval}
															/>
														</Row>
													</Col>
												</Skeleton>
											</Row>
										</>
									)}
								</Card>
							</Col>
						</Row>
					</Layout>
				)}
			</Skeleton>
		);
	}
}

export const MainTeacherLayout = withTranslation()(MainTeacherLayoutComponent);
