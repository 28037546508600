import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SubjectService from "services/administration/subject.service";
import { Row, Col, Skeleton, Radio } from "antd";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Icon from "common/components/general/Icon";
import SubjectDto from "dtos/administration/subject.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import { Role } from "services/domain/login/Role";
import { GradeLevel } from "services/domain/administration/Grade";
import CheckboxGroup from "common/components/dataEntry/components/CheckboxGroup";
import Checkbox from "common/components/dataEntry/components/Checkbox";

type Props = {
	updateData: (data: object) => void,
	setIsNextActive: (active: boolean) => void,
	selectedSubjects: Array<SubjectDto>,
	selectedGrades: ({ subjectName: string, grades: Array<string>})[],
	level: string
}

export const Subject = (props: Props) => {
	const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
	const isTeacher = currentUserInfo?.Roles.some((role: any) => role.code === Role.Teacher);
	const TRANSLATION_BASE_PATH = isTeacher ? "_TUTORING._COLLECT_DATA._SUBJECT_TEACHER" : "_TUTORING._COLLECT_DATA._SUBJECT_STUDENT";
	const { t: translate } = useTranslation();
	const { updateData, selectedGrades, selectedSubjects, level, setIsNextActive } = props;
	const [subjects, setSubjects] = useState([]);
	const [fetching, setFetching] = useState(true);

	const organizationId = currentUserInfo?.Organizations?.[0]?.OrganizationId || 'e47c7a13-83e8-41a0-afcc-3a8fd0fbda65';

	const fetchSubjects = async () => {
		await new SubjectService()
			.getAll(organizationId)
			.then((data: any) => {
				setFetching(false);
				return setSubjects(data.sort((a: SubjectDto, b: SubjectDto) => {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), "en", {numeric: true});
				}));
			});
	};
	useEffect(() => {
		fetchSubjects();
		setIsNextActive(selectedSubjects.length > 0);
	}, []);
	return (
		<>
			<p>{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</p>
			<Row className={!fetching ? "tutoring__scrollable" : "tutoring__no_overflow"}>
				{isTeacher && (
					<Row gutter={24}>
						<Col>
							<Radio.Group
								defaultValue={level}
								name="level"
								onChange={e => {
									updateData({ level: e.target.value, subjects: [], grades: [] });
								}}
							>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<RadioElement value={GradeLevel.Preschool} showAsBox={true}>
											<Row className="account__type__option">
												<Icon type="ri-user-5-line" />
												{translate(`${TRANSLATION_BASE_PATH}._LEVELS._PRESCHOOL`)}
											</Row>
										</RadioElement>
									</Col>
									<Col span={12}>
										<RadioElement value={GradeLevel.Elementary} showAsBox={true}>
											<Row className="account__type__option">
												<Icon type="ri-open-arm-line" />
												{translate(`${TRANSLATION_BASE_PATH}._LEVELS._ELEMENTARY`)}
											</Row>
										</RadioElement>
									</Col>
									<Col span={12}>
										<RadioElement value={GradeLevel.Middle} showAsBox={true}>
											<Row className="account__type__option">
												<Icon type="ri-user-line" />
												{translate(`${TRANSLATION_BASE_PATH}._LEVELS._MIDDLE`)}
											</Row>
										</RadioElement>
									</Col>
									<Col span={12}>
										<RadioElement value={GradeLevel.High} showAsBox={true}>
											<Row className="account__type__option">
												<Icon type="ri-user-4-line" />
												{translate(`${TRANSLATION_BASE_PATH}._LEVELS._HIGH`)}
											</Row>
										</RadioElement>
									</Col>
								</Row>
							</Radio.Group>
						</Col>
					</Row>
				)}
				{isTeacher && level && <p>{translate(`${TRANSLATION_BASE_PATH}._TITLE_2`)}</p>}
				{!isTeacher && (
					<Row gutter={24}>
						<Col>
							<Radio.Group
								defaultValue={selectedSubjects[0]?.id}
								name="subjects"
								onChange={e => {
									updateData({ subjects: [subjects.find((x: SubjectDto) => x.id === e.target.value)] });
									setIsNextActive(true);
								}}
							>
								<Row gutter={[16, 16]}>
									{fetching ? (
										[...Array(10)].map((x, i) => (
											<Col key={selectedSubjects[0]?.id} span={12}>
												<Skeleton.Avatar key={i} active size={185} shape="square" />
											</Col>
										))
									) : (
										<>
											{isTeacher
												? subjects
													.filter((subject: SubjectDto) =>
															subject.GradeSubjects?.some(gradeSubject => gradeSubject.Grade?.level === level))
													.map((subject: SubjectDto) => (
														<Col key={subject.id} span={12}>
															<RadioElement value={subject.id} showAsBox={true}>
																<Row className="account__type__option">
																	<Icon className="tutoring__subject_icon" type={`ri-${subject.icon}`} />
																	{subject.name}
																</Row>
															</RadioElement>
														</Col>
													))
												: subjects.map((subject: SubjectDto) => (
													<Col key={subject.id} span={12}>
														<RadioElement value={subject.id} showAsBox={true}>
															<Row className="account__type__option">
																<Icon className="tutoring__subject_icon" type={`ri-${subject.icon}`} />
																{subject.name}
															</Row>
														</RadioElement>
													</Col>
												))}
										</>
									)}
								</Row>
							</Radio.Group>
						</Col>
					</Row>
				)}
				{isTeacher && level && (
					<Row gutter={24}>
						<Col>
							<CheckboxGroup
								defaultValue={selectedSubjects.map(subject => subject.id)}
								value={selectedSubjects.map(subject => subject.id)}
								name="subjects"
								onChange={e => {
									const newSubjects = subjects.filter((x: SubjectDto) => e.includes(x.id));
									const subjectGrades = selectedGrades.filter(grade =>
										newSubjects.some((subject: SubjectDto) => grade.subjectName === subject.name));
									updateData({ subjects: newSubjects });
									updateData({ grades: subjectGrades });
									setIsNextActive(e.length > 0);
								}}
							>
								<Row gutter={[16, 16]}>
									{fetching ? (
										[...Array(10)].map((x, i) => (
											<Col key={selectedSubjects[0]?.id} span={12}>
												<Skeleton.Avatar key={i} active size={185} shape="square" />
											</Col>
										))
									) : (
										<>
											{subjects
												.filter((subject: SubjectDto) =>
													subject.GradeSubjects?.some(gradeSubject => gradeSubject.Grade?.level === level))
												.map((subject: SubjectDto) => (
													<Col key={subject.id} span={12}>
														<Checkbox value={subject.id} showAsBox={true}>
															<Row className="account__type__option">
																<Icon className="tutoring__subject_icon" type={`ri-${subject.icon}`} />
																{subject.name}
															</Row>
														</Checkbox>
													</Col>
												))}
										</>
									)}
								</Row>
							</CheckboxGroup>
						</Col>
					</Row>
				)}
			</Row>
		</>
	);
};