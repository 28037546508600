import * as Yup from "yup";
import {isValidDomain} from "services/common/check-domain";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";

export const formValidatorDomain = (translate: (key: string) => string) =>
	Yup.object({
		domain: Yup.string()
			.nullable()
			.trim()
			.lowercase()
			.test(
				"isDomain",
				translate(`${TRANSLATION_BASE_PATH}._INVALID_DOMAIN`),
				(value) => isValidDomain(value)
			)
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
