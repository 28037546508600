import React, { ReactNode } from "react";
import { Layout } from "antd";
import { Header } from "common/components/_header1/Header1";
import { SubHeader } from "./subHeader/SubHeader";
const { Content } = Layout;

interface LayoutProps {
	children: ReactNode;
}

export const MainLayout = (props: LayoutProps) => {
	return (
		<Layout>
			<Header
				noDashboardButton
				showSwitchRole
				showParentProfileLinks
			/>
			<SubHeader />
			<Layout className="full__min_heigh">
				<Content className="p-0 background-color-gray-2">{props.children}</Content>
			</Layout>
		</Layout>
	);
};
