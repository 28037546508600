import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import { Row, Space, Checkbox, List, Card, Tooltip, Skeleton } from "antd";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import Input from "common/components/dataEntry/formik/FormikInputField";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { showMessage } from "common/utils/Notification";
import { CreateEditDrawer } from "common/components/_createEditDrawer/CreateEditDrawer";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { GradeLevel, getLevelIcon } from "services/domain/administration/Grade";
import { SectionLevels } from "./SectionLevel";
import { formValidator } from "./ValidationSchema";
import GradeService from "services/administration/grade.service";
import SubjectService from "services/administration/subject.service";
import ClassroomService from "../../../../services/administration/classroom.service";
import { SectionContent } from "../../components/sectionContent/SectionContent";
import { DASHBOARD_PATH } from "../dashboard";

export const LEVELS_PATH = "levels";

const TRANSLATION_BASE_PATH = "_ADMIN._LEVELS";

interface GradeSubject {
	id: string;
	Classrooms: [];
	Grade: Grade;
	Subject: Subject;
}

export interface Level {
	id: string;
	name: string;
	icon: string;
	grades: Grade[];
}

interface Subject {
	id: string;
	name: string;
	icon: string;
	description: string;
	GradeSubjects: GradeSubject[];
}

export interface Grade {
	id: string;
	name: string;
	GradeSubjects: GradeSubject[];
}

class LevelsComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true,
			message: "",
			drawerShown: false,
			isCreate: true,
			formInitialValues: {}
		};
	}

	async componentDidMount() {
		this.loadData();
	}

	toggleDrawer = () => {
		this.setState({
			drawerShown: !this.state.drawerShown
		});
	};

	loadItemAndShowDrawer = (level: Level, grade?: Grade) => {
		const isCreate = grade === undefined;

		if (isCreate) {
			grade = {
				id: uuidv4(),
				name: "",
				GradeSubjects: []
			};
		}

		this.setState(
			{
				isCreate: isCreate,
				formInitialValues: {
					levelId: level.id,
					levelName: level.name,
					grade: grade,
					gradeName: grade?.name
				},
				changes: []
			},
			() => {
				this.toggleDrawer();
			}
		);
	};

	loadData = () => {
		const { t: translate } = this.props;

		const requests = [
			new GradeService().getAllGrades(),
			new SubjectService().getAllForAdminLevels(),
			new ClassroomService().findAllDistinctGradeSubjectId()
		];

		return Promise.all(requests)
			.then(result => {
				const allGrades = _.groupBy(result[0], "level");
				const levels: Level[] = [];

				Object.values(GradeLevel).map((level: string) => {
					levels.push({
						id: level,
						name: translate(`_ADMIN._LEVELS._${level}`),
						icon: getLevelIcon(level),
						grades: allGrades[level]
					});
				});

				const allSubjects = result[1] as Subject[];
				const letterIndexes = _.orderBy(
					_.uniq(
						allSubjects.map((x: Subject) => {
							return x.name.charAt(0).toUpperCase();
						})
					)
				);

				const allSubjectsIndexed: Map<string, Subject[]> = new Map();
				letterIndexes.forEach((index: string) => {
					allSubjectsIndexed.set(
						index,
						allSubjects.filter((s: Subject) => {
							return s.name.charAt(0).toUpperCase() === index;
						})
					);
				});

				return this.setState({
					levels: levels,
					allGrades: allGrades,
					allSubjects: allSubjectsIndexed,
					GradeSubjectIdsInUse: result[2] as string[]
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				});
			});
	};

	onGradeCheck = (item: any) => {
		const subjectId = item.target.id;
		const gradeId = this.state.formInitialValues.grade.id;
		const changes = this.state.changes;

		_.remove(changes, (x: any) => x.SubjectId === subjectId);
		const exists = this.state.formInitialValues.grade.GradeSubjects.some((x: any) => {
			return x.SubjectId === subjectId && x.GradeId === gradeId;
		});

		if (item.target.checked && !exists) {
			changes.push({
				id: uuidv4(),
				SubjectId: subjectId,
				GradeId: gradeId,
				state: "ADDED"
			});
		} else if (!item.target.checked && exists) {
			const id = this.state.formInitialValues.grade.GradeSubjects.filter((x: any) => {
				return x.SubjectId === subjectId && x.GradeId === gradeId;
			})[0].id;
			changes.push({
				id: id,
				SubjectId: subjectId,
				GradeId: gradeId,
				state: "DELETED"
			});
		}

		this.setState({
			changes: changes
		});
	};

	onSubmit = async (values: any) => {
		const obj = {
			id: values.grade.id,
			name: values.gradeName,
			level: values.levelId,
			Changes: this.state.changes
		};

		const { t: translate } = this.props;

		this.setState({
			submitting: true
		});

		const subjectService = this.state.isCreate
			? new GradeService().createGrade(obj)
			: new GradeService().updateGrade(obj.id, obj);

		return subjectService
			.then((result: any) => {
				showMessage(translate(`_GENERAL._API._STATUS_CODES._${result.status}`));
				return this.loadData();
			})
			.finally(() => {
				this.setState({
					submitting: false
				});
				this.toggleDrawer();
			});
	};

	isChecked = (subject: Subject) => {
		return (
			(this.state.formInitialValues.grade &&
				this.state.formInitialValues.grade.GradeSubjects.some((x: any) => {
					return x.SubjectId === subject.id;
				}) &&
				!this.state.changes.some((x: any) => {
					return x.SubjectId === subject.id && x.state === "DELETED";
				})) ||
			this.state.changes.some((x: any) => {
				return x.SubjectId === subject.id && x.state === "ADDED";
			})
		);
	};

	isDisabled = (subject: Subject) => {
		const gradeId = this.state.formInitialValues.grade.id;
		const result = subject.GradeSubjects.filter((x: any) => {
			return x.GradeId === gradeId && x.SubjectId === subject.id;
		});
		return (
			result.length > 0 && this.state.GradeSubjectIdsInUse.filter((x: string) => x === result[0].id).length > 0
		);
	};

	render() {
		const { t: translate } = this.props;

		const title = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_3");
		const subtTitle = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const btnTitle = translate(`${TRANSLATION_BASE_PATH}._BTN_ADD`);
		const fromTitle = this.state.isCreate
			? translate(`${TRANSLATION_BASE_PATH}._BTN_ADD`)
			: translate(`${TRANSLATION_BASE_PATH}._BTN_EDIT`);
		let submitHandler: any;

		const CreateEditForm = () => {
			return (
				<Formik
					enableReinitialize={true}
					validationSchema={formValidator(translate)}
					initialValues={this.state.formInitialValues}
					onSubmit={this.onSubmit}
					isInitialValid={false}
				>
					{formik => {
						const { submitForm } = formik;
						submitHandler = submitForm;
						return (
							<>
								<Row gutter={[16, 24]}>
									<Input name="gradeName" label={translate(`${TRANSLATION_BASE_PATH}._CLASS`)} />
								</Row>
								<Row>
									<Text fontSize="14" wheight="semibold">
										Zgjidh lendet e klases
									</Text>
								</Row>
								{Array.from(this.state.allSubjects).map((item: any) => {
									return (
										<>
											<Card bordered={false} title={item[0]}>
												<List
													grid={{ gutter: 0, column: 2 }}
													dataSource={item[1]}
													renderItem={(subject: Subject) => {
														const isDisabled = this.isDisabled(subject);
														return (
															<List.Item>
																<Checkbox
																	onChange={this.onGradeCheck}
																	checked={this.isChecked(subject)}
																	disabled={isDisabled}
																	name={subject.name}
																	id={subject.id}
																>
																	<Tooltip
																		title={
																			isDisabled
																				? translate(
																						`${TRANSLATION_BASE_PATH}._SUBJECT_IN_USE`
																				  )
																				: ""
																		}
																	>
																		<Space>
																			<Icon type={`ri-${subject.icon}`}></Icon>
																			{subject.name}
																		</Space>
																	</Tooltip>
																</Checkbox>
															</List.Item>
														);
													}}
												/>
											</Card>
										</>
									);
								})}
							</>
						);
					}}
				</Formik>
			);
		};

		return (
			<>
				<SectionTitle title={title} subtitle={[subtTitle, title]} subtitleUrl={[DASHBOARD_PATH]} />
				<SectionContent>
					<Skeleton loading={this.state.loading}>
						<SectionLevels
							levels={this.state.levels}
							btnTitle={btnTitle}
							onEditCreate={this.loadItemAndShowDrawer}
						/>
					</Skeleton>
					<CreateEditDrawer
						title={fromTitle + " (" + this.state.formInitialValues.levelName + ")"}
						onClose={this.toggleDrawer}
						visible={this.state.drawerShown}
						form={CreateEditForm}
						onSave={() => submitHandler()}
						width={window.innerWidth >= 576 ? 400 : window.innerWidth}
					/>
				</SectionContent>
			</>
		);
	}
}

const Levels = withTranslation()(LevelsComponent);
export default Levels;
