import ApiServiceData, { API_BASE_URL } from "../api.service";
import { DesignSettingDto } from "../../dtos/administration/customize/DesignSetting.dto";
import {
	DesignSettingList, PublicSettingsResponse
} from "../../scenes/admin/scenes/organizations/scenes/customize/utils/types";
import axios from "axios";
import { getOrganizationIdOrCurrentUrl } from "../../common/utils/Design";

const relativeUrl = "/v1/api/design-setting";

export default class DesignSettingService extends ApiServiceData {
	async findAll(organizationId: string): Promise<DesignSettingList> {
		return this.get(relativeUrl + "/" + organizationId + "/list")
			.then(res => {
				return res.data as DesignSettingList;
			})
			.catch(error => {
				throw error.response;
			});
	}

	/**
	 * Get the public design settings of the current loaded application based on the domain
	 */
	async findPublicSettings(): Promise<PublicSettingsResponse> {
		const domain = getOrganizationIdOrCurrentUrl();
		const api = relativeUrl + "/" + encodeURIComponent(domain) + "/public-list";


		return axios
			.get(`${API_BASE_URL}${api}`, {
				headers: {
					BrowserBaseURL: window.location.protocol + "//" + window.location.host
				}
			})
			.then(res => {
				return res.data as PublicSettingsResponse;
			})
			.catch(error => {
				throw error.response;
			});
	}

	/**
	 * Delete a design option
	 * @param id
	 */
	async deleteOption(id: string) {
		return this.delete(relativeUrl + "/" + id)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	/**
	 * Save design option
	 * @param designSettingDto
	 */
	async saveDesignSetting(designSettingDto: DesignSettingDto) {
		return this.post(relativeUrl, designSettingDto).catch(error => {
			throw error.response;
		});
	}
}