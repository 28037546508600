import React, { Component, ReactNode } from "react";
import { Button, Col, Row, Input, Space } from "antd";
import { WithTranslation, withTranslation } from "react-i18next";
import Modal from "common/components/feedback/Modal";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import {Uploader} from "common/components/uploader/Uploader";

const { TextArea } = Input;
const TRANSLATION_BASE_PATH = "_SUBJECTS._MATERIAL_ITEMS._COMMIT_MODAL";
const MAXIMUM_CHARACTERS = 255;
export interface CommitModalProps extends WithTranslation {
	modalVisibility: boolean;
	closeModal: () => void;
	lessonName: string;
	fileUploaded: any[];
	okModal: (description: string) => void;
}

class CommitModalComponent extends Component<CommitModalProps, any> {
	constructor(props: CommitModalProps) {
		super(props);
		this.state = {
			value: ""
		}
	}

	getFooter = (): ReactNode => {
		const { t: translate } = this.props;
		return (
			<Row justify="end">
				<Col>
					<Button onClick={this.props.closeModal} style={{ marginRight: 8 }}>
						{translate(`${TRANSLATION_BASE_PATH}._CANCEL`)}
					</Button>
				</Col>
				<Col>
					<Button onClick={() => this.props.okModal(this.state.value)} type="primary">
						{translate(`${TRANSLATION_BASE_PATH}._APPROVE`)}
					</Button>
				</Col>
			</Row>
		);
	};

	onChange = ({ target: { value } }: any) => {
		const { t: translate } = this.props;
		if(value.length <= MAXIMUM_CHARACTERS){
			return this.setState({ value, error:null });
		}
		return this.setState({error: translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_MAX_NUMBER_ERROR`)})
	};

	render() {
		const { t: translate } = this.props;
		const styles = this.state.error ? {border:'solid 1px red'} : {}
		return (
			<Modal
				visible={this.props.modalVisibility}
				closable={true}
				footer={this.getFooter()}
				title={translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
				onCancel={this.props.closeModal}
			>
				<Row gutter={[0, 32]}>
					<Col span={24}>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{translate(`${TRANSLATION_BASE_PATH}._SUBTITLE`, {
								assignment: this.props.lessonName
							})}
						</Text>
					</Col>
				</Row>
				<Row gutter={[0, 32]}>
					<Col span={24}>
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION`)}
						</Text>
						<TextArea style={styles} rows={4} value={this.state.value} onChange={this.onChange}/>
						{this.state.error && <p style={{color:'red'}}>{this.state.error}</p>}
					</Col>
				</Row>
				<Row gutter={[0, 32]}>
					<Col span={24}>
						<Row>
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._DOCUMENTS`)}
							</Text>
						</Row>
						<Row className="full__width">
							<Uploader
								key={new Date().toISOString()}
								fileType={"OTHER"}
								multiple={true}
								showUploadList={true}
								defaultFileList={this.props.fileUploaded}
								className="lessonMaterial__modal_commit"
								showRemoveIcon={false}
							>
								<Row />
							</Uploader>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Space direction="horizontal" size={8}>
							<Icon type="ri-error-warning-line" className="color-orange-6" />
							<Text fontSize="14" lineHeight="22" className="color-gray-8">
								{translate(`${TRANSLATION_BASE_PATH}._INFO`)}
							</Text>
						</Space>
					</Col>
				</Row>
			</Modal>
		);
	}
}
const CommitModal = withTranslation()(CommitModalComponent);
export default CommitModal;
