import * as Yup from "yup";

const TRANSLATION_BASE_PATH_REQUIRED = "_COURSE_BUILDER._REQUIRED";

export const formValidator = (translate: (key: string) => string) => {
	return Yup.object({
		name: Yup.string()
			.trim()
			.typeError(translate(TRANSLATION_BASE_PATH_REQUIRED))
			.required(translate(TRANSLATION_BASE_PATH_REQUIRED)),
		startTime: Yup.array()
			.typeError(translate(TRANSLATION_BASE_PATH_REQUIRED))
			.required(translate(TRANSLATION_BASE_PATH_REQUIRED))
	});
};
