import React from "react";
import { Route, Redirect } from "react-router-dom";
import OAuth2Service from "services/authentication/oauth2.service";
import { SIGN_IN_PATH } from "./scenes/login/scenes/signIn";

class AuthenticatedRoute extends Route {
	render() {
		if (!OAuth2Service.isLoggedIn)
			return (
				<Redirect
					to={{
						pathname: `/${SIGN_IN_PATH}`,
						state: { from: this.props.location }
					}}
				/>
			);
		return <Route {...this.props} />;
	}
}

export default AuthenticatedRoute;
