import React, { ChangeEvent } from "react";
import { Row, Col } from "antd";
import { SearchBox } from "./SearchBox";

interface ResponsiveSearchBoxProps {
	onSearch: (searchValue: string) => void;
	loading?: boolean;
	placeholder?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	value?: string;
}

export const ResponsiveSearchBox = (props: ResponsiveSearchBoxProps) => {
	return (
		<Row>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Row justify="space-around" className="mb-24">
					<Col span="24">
						<SearchBox
							value={props.value || ""}
							onChange={props.onChange}
							placeholder={props.placeholder}
							loading={!!props.loading}
							onSearch={props.onSearch}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
