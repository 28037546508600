import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Skeleton from "common/components/feedback/Skeleton";
import { NotFound } from "common/components/feedback/NotFound";
import EmailVerification, { REGISTER_EMAIL_VERIFICATION_PATH } from "./scenes/registerEmailVerification";
import { Layout } from "./components/layout";
import { SIGN_IN_PATH } from "../login/scenes/signIn";
import { OnboardingWizard } from "./scenes/wizard";
import { MAIN_PATH } from "../main";
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import OAuth2Service from "services/authentication/oauth2.service";
import AuthorizedGuardedRoute, {TypeOfOrganization} from "App.AuthorizedRoute";
import {Role} from "services/domain/login/Role";

export const ON_BOARDING_PATH = "on-boarding";

class OnBoarding extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		const { history } = this.props;
		const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;
		if (!currentUserInfo) return history.push("/" + SIGN_IN_PATH);
		if (currentUserInfo.User?.status !== "NEW" && currentUserInfo.User.status !== "ONBOARDING")
			return history.push(`/${MAIN_PATH}`);
		return this.setState({ loading: false });
	}

	render() {
		return (
			<Skeleton active loading={this.state.loading}>
				{!this.state.loading ? (
					<Layout>
						<Switch>
							<Route
								path={`/${ON_BOARDING_PATH}/${REGISTER_EMAIL_VERIFICATION_PATH}`}
								exact
								component={EmailVerification}
							/>
							<AuthorizedGuardedRoute
								allowed={[{
									role: Role.Teacher,
									type: TypeOfOrganization.Main
								},
								{
									role: Role.Student,
									type: TypeOfOrganization.Main
								},
								{
									role: Role.Parent,
									type: TypeOfOrganization.Main
								}]}
								path={`/${ON_BOARDING_PATH}`}
								component={OnboardingWizard}
							/>
							<NotFound />
						</Switch>
					</Layout>
				) : null}
			</Skeleton>
		);
	}
}

export default OnBoarding;
