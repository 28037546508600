import React from "react";
import { useField, useFormikContext } from "formik";

import TimeRangePicker, { TimeRangePickerProps } from "../components/TimeRangePicker";
import Error from "./FormItemError";
import Label from "../components/Label";

type FormikTimeRangePickerProps = TimeRangePickerProps & {
	name: string;
	label?: string;
	id?: string;
	onBlur?: React.FormEventHandler<HTMLInputElement>;
	className?: string;
};

const FormikTimeRangePickerField: React.FC<FormikTimeRangePickerProps> = props => {
	const [, meta] = useField(props.name);
	const context = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	const onTimeChange = (values: any, formatString: [string, string]) => {
		context.setFieldValue(props.name, values);
	};

	const onBlur = (): void => {
		context.setFieldTouched(props.name);
	};

	return (
		<div>
			<Label htmlFor={id} className="hand-on-hover">
				{props.label}
			</Label>
			<TimeRangePicker {...props} onChange={onTimeChange} onBlur={onBlur} />
			{error}
		</div>
	);
};

export default FormikTimeRangePickerField;
