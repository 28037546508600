import React, { ChangeEvent, useState } from "react";
import { Input } from "antd";
import i18n from "i18next";
const { Search } = Input;

interface SearchBoxProps {
	value?: string;
	onSearch: (searchValue: string) => void;
	loading?: boolean;
	placeholder?: string,
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBox = (props: SearchBoxProps) => {
	const [value, setValue] = useState<string>(props.value || "");

	const handleChange = (e :  ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	}

	return (
		<Search
			value={value}
			loading={props.loading}
			placeholder={props.placeholder || i18n.t("_ADMIN._SEARCH_BOX._LEARN_TODAY")}
			onSearch={value => props.onSearch(value)}
			allowClear={true}
			onChange={(e) => {
				props.onChange && props.onChange(e);
				handleChange(e);
			}}
		/>

	);
};
