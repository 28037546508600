import React from "react";
import { Space, Row } from "antd";

import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import Image from "common/components/general/Image";
import { useHistory } from "react-router-dom";
import { SUBJECTS_PATH } from "scenes/admin/scenes/subjects";
import { COURSE_PREVIEW_PATH } from "../../scenes/courseItem/CourseItem";

interface ClassroomThumbnailProps {
	id: string;
	name: string;
	image?: string;
	file?: any;
}

export const ClassroomThumbnail = (props: ClassroomThumbnailProps) => {
	const history = useHistory();

	return (
		<Space direction="vertical" size={16} className="full__width">
			<Row align="middle" justify="center">
				<Space direction="vertical" className="full__width classroom__thumbnail">
					{props.image ? (
						<Image
							className="classroom__thumbnail_image"
							onClick={() => {
								history.push(`/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/${props.id}`);
							}}
							src={props.image}
						/>
					) : (
						<div
							className="classroom__thumbnail_icon"
							onClick={() => {
								history.push(`/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/${props.id}`);
							}}
						>
							<Row align="middle" justify="center">
								<Icon type="ri-image-line" />
							</Row>
						</div>
					)}
				</Space>
			</Row>
			<Row>
				<Text fontSize="14" lineHeight="22" wheight="semibold">
					{props.name}
				</Text>
			</Row>
		</Space>
	);
};
