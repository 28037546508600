import React, { useEffect, useState } from "react";
import { Space, Skeleton, Breadcrumb } from "antd";
import { useParams, useHistory, Link } from "react-router-dom";
import { orderBy } from "natural-orderby";
import { useTranslation } from "react-i18next";

import { useStoreState } from "store/hooks";
import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { SUBJECTS_PATH } from "scenes/admin/scenes/subjects";
import { ClassroomThumbnail } from "../../components/classroomThumbnail";
import { previewFile } from "scenes/courseBuilder/utils/FileUtils";
import { ActiveFilters } from "../../components/activeFilters";

export const RELATED_SUBJECTS_PATH = "/:subjectId";

const TRANLSATION_BASE_PATH = "_SUBJECTS._SUBJECT_ITEMS._SUBJECT_LIST";

export const RelatedSubjects = () => {
	const { subjectId } = useParams();
	const history = useHistory();
	const [subjectToShow, setSubjectToShow] = useState<any[]>([]);
	const { t: translate } = useTranslation();
	const activeFilters = useStoreState(state => state.subjects.activeFilters);

	const subject = useStoreState(state => {
		return state.subjects.items
			.filter(subject => subject.classrooms)
			.find(item => item.id === subjectId);
	});

	useEffect(() => {
		async function getSubject() {
			const orderClassroom = (subject && orderBy(subject.classrooms, [(v: any) => v.name], ["asc"])) || [];
			const subjectWithPhotos =
				(subject &&
					(await Promise.all(
						orderClassroom.map(async (classroom: any) => {
							const image = (classroom.File && (await previewFile(classroom.File))) || null;
							return { ...classroom, image: image && image.url };
						})
					).then(classroom => {
						return classroom;
					}))) ||
				[];
			return await setSubjectToShow(subjectWithPhotos);
		}
		getSubject();
	}, [history, subject]);

	return (
		<>
			<Row className="border__bottom pb-16">
				<Col span={24} className="pb-0">
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to={`/${SUBJECTS_PATH}`}>{translate(`${TRANLSATION_BASE_PATH}._TITLE`)}</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{subject?.name}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<Col span={24} className="flex__center_space_between related_subjects_header">
					<Space direction="vertical" align="start">
						<Text fontSize="30" lineHeight="38" wheight="semibold">
							{subject?.name}
						</Text>
						<Text fontSize="14" lineHeight="22" className="color-gray-8">
							{subject?.description}
						</Text>
					</Space>
					<Icon type={`ri-${subject?.icon}`} className="subject_thumbanil_icon" />
				</Col>
			</Row>
			<ActiveFilters subjectId={subjectId}/>
			<Row>
				<Skeleton active loading={activeFilters.length === 0 && !(subjectToShow && subjectToShow.length > 0)}>
					{subjectToShow.map(({ id, name, image, File }, index) => {
						return (
							<Col
								key={index}
								xs={24}
								sm={24}
								md={12}
								lg={8}
								xl={8}
								xxl={8}
								className="pt-24 pb-8 pl-4 pr-4"
							>
								<ClassroomThumbnail id={id} name={name} image={image} file={File} />
							</Col>
						);
					})}
				</Skeleton>
			</Row>
		</>
	);
};
