import React from "react";
import { Space } from "antd";
import styled from "styled-components";

import Text from "common/components/general/Text";
import { CourseItem } from "./CourseItem";
import { Pagination } from "./Pagination";

interface CourseGroupProps {
	items: any[];
	title: string;
	emoji?: string;
	total: number;
	current: number;
	onPaginationChange: Function;
}

export const CourseGroup = (props: CourseGroupProps) => {
	return (
		<CourseGroupStyledWrapper>
			<Space>
				<Text fontSize="24" lineHeight="32" wheight="semibold">
					{props.title}
				</Text>
				<Text fontSize="24" lineHeight="32" wheight="regular">
					{props.emoji}
				</Text>
			</Space>
			{props.items.length > 0 && (
				<>
					<div className="courses-wrapper">
						{props.items.map((item, itemIndex) => (
							<CourseItem {...item} key={itemIndex} />
						))}
					</div>
					{props.total / 9 > 1 && (
						<div className="pagination-wrapper">
							<Pagination
								showSizeChanger={false}
								defaultCurrent={0}
								total={props.total}
								current={props.current}
								onChange={props.onPaginationChange}
								pageSize={9}
							/>
						</div>
					)}
				</>
			)}
			{props.items.length === 0 && <div>Nuk ka kurse</div>}
		</CourseGroupStyledWrapper>
	);
};

const CourseGroupStyledWrapper = styled.div`
	.courses-wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		gap: 29px 14px;
		margin-top: 19px;
	}

	.pagination-wrapper {
		margin-top: 57px;
	}
`;
