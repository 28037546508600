import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Radio } from "antd";
import Image from "common/components/general/Image";
import RequestTablet from "assets/images/request-tablet.svg";
import DonateTablet from "assets/images/donate-tablet.svg";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._DIGITALIZE_STUDENT._DATA_COLLECTOR._REASON";

type Props = {
	updateData: (data: object) => void,
	setIsNextActive: (active: boolean) => void,
}

export const Reason = (props: Props) => {
	const { t: translate } = useTranslation();
	const [checked, setChecked] = useState("");
	const { updateData, setIsNextActive } = props;
	const handleChange = (value: string) => {
		updateData({ reason: value });
		setChecked(value);
		setIsNextActive(true);
	};
	return (
		<Col span={24}>
			<Row gutter={[0, 12]}>{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}</Row>
			<Row>
				<Col span={24}>
					<Radio.Group
						defaultValue={checked}
						name="reason"
						value={checked}
						onChange={e => {
							handleChange(e.target.value);
						}}
					>
						<Row gutter={[0, 12]} align="middle">
							<Col xs={24} sm={11} className="mr-32" onClick={() => handleChange("_REQUEST")}>
								<Row>
									<Image width="100%" src={RequestTablet} />
								</Row>
								<Col span={24}>
									<Radio value="_REQUEST">
										{translate(`${TRANSLATION_BASE_PATH}._REQUEST`)}
									</Radio>
								</Col>
							</Col>
							<Col xs={24} sm={11} onClick={() => handleChange("_DONATE")}>
								<Row>
									<Image width="100%" src={DonateTablet} />
								</Row>
								<Col span={24}>
									<Radio value="_DONATE">
										{translate(`${TRANSLATION_BASE_PATH}._DONATE`)}
									</Radio>
								</Col>
							</Col>
						</Row>
					</Radio.Group>
				</Col>
			</Row>
		</Col>
	);
};