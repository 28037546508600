import React, { ReactNode } from "react";
import { useField } from "formik";
import classnames from "classnames";

import Error from "./FormItemError";
import TextArea from "../components/TextArea";
import { FieldProps } from "./FieldProps";
import Label from "../components/Label";
import Text from "../../general/Text";
interface TextAreaProps extends FieldProps {
	cols?: number;
	rows?: number;
	wrap?: string;
	disabled?: boolean;
	maxLength?: number;
	minLength?: number;
	hint?: string | ReactNode;
	labelInfo?: string;
	autoFocus?: boolean;
}

const FormikTextAreaField: React.FC<TextAreaProps> = ({ hint, labelInfo, label, ...props }) => {
	const [field, meta] = useField(props.name);
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	const errorClassname = classnames({ error_input: showError });

	const hintContent = hint ? (
		<Text fontSize="14" lineHeight="22" className="color-gray-7">
			{hint}
		</Text>
	) : null;

	return (
		<div>
			{label && (
				<Label htmlFor={id} className="hand-on-hover">
					{label}{" "}
					{labelInfo ? (
						<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
							{labelInfo}
						</Text>
					) : null}
				</Label>
			)}
			{hintContent}
			<TextArea {...field} {...props} id={id} className={`${errorClassname} ${props.className ?? ""}`} />
			{error}
		</div>
	);
};

export default FormikTextAreaField;
