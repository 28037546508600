import { Col, Row, Space, Avatar, Empty } from "antd";
import React, { useState } from "react";

import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { Role } from "services/domain/login/Role";
import { JoinByClassroomCodeModal } from "./JoinByClassroomCodeModal";
import { WelcomeMessage } from "./WelcomeMessage";

interface ChooseAsStudentProps {
	organizations: any[];
	selectOrganization: (organizationId: string) => void;
	onGoBack: () => void;
	renderOnMount: () => void
}

export const ChooseAsStudent = (props: ChooseAsStudentProps) => {
	const [joinClassroomModalVisible, setJoinClassroomModalVisible] = useState<boolean>(false);
	const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);
	const organizationsAsStudent: number = props.organizations.reduce(
		(total, nextItem) =>
			total + (nextItem?.UserRoles?.some((userRole: any) => userRole.Role.code === Role.Student) ? 1 : 0),
		0
	);
	return (
		<>
			{showWelcomeMessage ? (
				<WelcomeMessage />
			) : (
				<>
					<Row>
						<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Button type="link" icon="ri-arrow-left-line" onClick={props.onGoBack}>
								Kthehu
							</Button>
						</Col>
					</Row>
					<Row className="mt-16">
						<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<Space direction="vertical" align="center" className="full__width" size={16}>
								<Avatar
									icon={<Icon type="ri-user-line" className="color-blue-6" />}
									className="choose__organization_avatar"
									size={48}
								/>
								<Text fontSize="30" lineHeight="38" className="color-gray-9">
									Portali i nxënësit {organizationsAsStudent > 0 ? `(${organizationsAsStudent})` : ""}
								</Text>
							</Space>
						</Col>
					</Row>
					{organizationsAsStudent > 0 ? (
						<>
							<Row className="mt-16">
								<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
								<Col
									xs={24}
									sm={24}
									md={12}
									lg={12}
									xl={12}
									xxl={12}
									className="flex__center_space_between"
								>
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										Zgjidhni institucionin arsimor ku dëshironi të hyni.
									</Text>
									<Button
										icon="ri-add-circle-line"
										type="link"
										onClick={() => {
											setJoinClassroomModalVisible(true);
										}}
									>
										Regjistrohu në klasë
									</Button>
								</Col>
							</Row>
							<Row className="mt-16">
								<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}></Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
									<Space direction="vertical" className="choose__organization_content" size={8}>
										{props.organizations.map((organization: any) => {
											return organization?.UserRoles?.filter(
												(userRole: any) => userRole.Role.code === Role.Student
											).map((userRole: any) => {
												return (
													<Row
														key={userRole.id + organization.id}
														onClick={() => props.selectOrganization(organization.id)}
													>
														<Col span={24} className="choose__organization_option">
															<Space direction="horizontal" size={8} align="center">
																<Avatar
																	icon={
																		<Icon
																			type="ri-building-4-line"
																			className="color-blue-6"
																		/>
																	}
																	className="background-color-blue-1"
																/>
																<Text
																	fontSize="14"
																	lineHeight="22"
																	className="color-gray-9"
																>
																	{organization.name}
																</Text>
															</Space>
															<Icon type="ri-arrow-right-line" className="color-gray-6" />
														</Col>
													</Row>
												);
											});
										})}
									</Space>
								</Col>
							</Row>
						</>
					) : null}
					{organizationsAsStudent === 0 ? (
						<>
							<Row className="mt-32">
								<Col xs={0} sm={0} md={8} lg={8} xl={8} xxl={8}></Col>
								<Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
									<Space direction="vertical" size={24} className="full__width" align="center">
										<Empty description="Ju nuk jeni pjesë e një kursi live." />
										<Button
											type="primary"
											icon="ri-user-line"
											onClick={() => {
												setJoinClassroomModalVisible(true);
											}}
										>
											Regjistrohu si nxënës
										</Button>
									</Space>
								</Col>
							</Row>
						</>
					) : null}
				</>
			)}
			<JoinByClassroomCodeModal
				visible={joinClassroomModalVisible}
				onCancel={() => {
					setJoinClassroomModalVisible(false);
				}}
				onSuccessfulJoin={() => {
					setShowWelcomeMessage(true);
					setJoinClassroomModalVisible(false);
				}}
				joinedOrganizations={props.organizations}
				renderOnMount={props.renderOnMount}
			/>
		</>
	);
};
