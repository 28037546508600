import { Col, Modal, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import CoursesPerformanceChartIcon from "assets/images/courses-performance-chart.svg";
import RocketIcon from "assets/images/icons/rocket.png";
import FireIcon from "assets/images/icons/fire.png";
import ThumbsUpIcon from "assets/images/icons/thumbsup.png";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._COURSES_PERFORMANCE";

interface AllCoursesPerformanceModalProps {
	items: any[];
	visible: boolean;
	onClose: () => void;
}

export const AllCoursesPerformanceModal = (props: AllCoursesPerformanceModalProps) => {
	const { t: translate } = useTranslation();

	let firstMaxScore = -1;
	let secondMaxScore = -1;
	let thirdMaxScore = -1;
	return (
		<Modal
			title={translate(`${TRANSLATION_BASE_PATH}._MODAL_TITLE`, {
				count: props.items.length
			})}
			visible={props.visible}
			onCancel={props.onClose}
			footer={
				<Button type="primary" onClick={props.onClose}>
					{translate(`${TRANSLATION_BASE_PATH}._CLOSE`)}
				</Button>
			}
			className="courses__performance_modal"
		>
			<Row className="pt-24 pb-24">
				<Col span={24} className="text__align_center">
					<Image src={CoursesPerformanceChartIcon} />
				</Col>
			</Row>
			{props.items.map((item, index) => {
				let scoreIcon = null;
				if (item.calculatedScore > 0) {
					if (item.calculatedScore.toFixed(1) >= firstMaxScore) {
						firstMaxScore = item.calculatedScore;
						scoreIcon = <Image src={RocketIcon} className="claculated__score_icon" />;
					} else if (item.calculatedScore.toFixed(1) >= secondMaxScore) {
						secondMaxScore = item.calculatedScore;
						scoreIcon = <Image src={FireIcon} className="claculated__score_icon" />;
					} else if (item.calculatedScore.toFixed(1) >= thirdMaxScore) {
						thirdMaxScore = item.calculatedScore;
						scoreIcon = <Image src={ThumbsUpIcon} className="claculated__score_icon" />;
					}
				}

				return (
					<Row key={index} className="course__item_details">
						<Col span={24} className="course__item_details_content">
							<Space direction="horizontal" size={12} align="center">
								<div className="course__icon">
									<Icon type={`ri-${item.icon}`} />
								</div>
								<Space direction="vertical" size={4}>
									<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-9">
										{item.name}
									</Text>
									<Row>
										<Col span={24}>
											<Space size={26}>
												<Space size={5.5}>
													<Icon type="ri-play-fill" className="color-gray-6" />
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{item.videos}
													</Text>
												</Space>
												<Space size={5.5}>
													<Icon type="ri-question-fill" className="color-gray-6" />
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{item.quizes}
													</Text>
												</Space>
												<Space size={5.5}>
													<Icon type="ri-slideshow-2-fill" className="color-gray-6" />
													<Text fontSize="14" lineHeight="22" className="color-gray-8">
														{item.materials}
													</Text>
												</Space>
											</Space>
										</Col>
									</Row>
								</Space>
							</Space>
							<Space direction="horizontal" align="center" size={6}>
								{scoreIcon}
								<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-9">
									{item.calculatedScore.toFixed(1)}
								</Text>
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									({item.finishedItems})
								</Text>
							</Space>
						</Col>
					</Row>
				);
			})}
		</Modal>
	);
};
