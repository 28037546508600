import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";

const TRANSLATION_BASE_PATH = "_MAIN._ORGANIZATION._CONTENT._VIRTUAL_CLASSES_TAB._EMPTY_STATE";

export const EmptyState = () => {
	const { t: translate } = useTranslation();
	return (
		<>
			<Row className="pb-24 pt-24" justify="center">
				<Image src={require("assets/images/my-asynch-courses-empty-state.svg")} />
			</Row>
			<Row className="pb-8" justify="center">
				<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
					{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
				</Text>
			</Row>
			<Row className="pt-16">
				<Col span={24}>
					<Row justify="center">
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_1`)}
						</Text>
					</Row>
				</Col>
				<Col span={24}>
					<Row justify="center">
						<Text fontSize="14" lineHeight="22" className="color-gray-7">
							{translate(`${TRANSLATION_BASE_PATH}._DESCRIPTION_2`)}
						</Text>
					</Row>
				</Col>
			</Row>
			<Row className="pt-16" justify="center">
				<a href="http://google.al" target="_blank">
					<Text fontSize="14" lineHeight="22" className="color-blue-6 text_underline">
						{translate(`${TRANSLATION_BASE_PATH}._READ_MORE`)}
					</Text>
				</a>
			</Row>
		</>
	);
};
