import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";

interface WelcomeSectionProps {
	parentFullName: string;
	fullDate: string;
	welcomeImage: any;
}

const TRANSLATION_BASE_PATH = "_PARENT._DASHBOARD._WELCOME_SECTION";

export const WelcomeSection = (props: WelcomeSectionProps) => {
	const { t: translate } = useTranslation();
	const getContent = () => {
		return (
			<>
				<Row>
					<Text fontSize="20" lineHeight="28" wheight="semibold" className="color-gray-9">
						{translate(`${TRANSLATION_BASE_PATH}._GREETING`)}
						{props.parentFullName}
					</Text>
				</Row>
				<Row className="pt-8">
					<Text fontSize="14" lineHeight="22" className="color-gray-7">
						<Trans i18nKey={`${TRANSLATION_BASE_PATH}._SUBTITLE`}>
							Këtu ju do të keni mundësinë të kontrolloni ecurinë dhe aktivitetin tuaj mësimor. Mëso çfarë
							mund të bësh si
							<a
								href="https://akademial.tawk.help/category/manual-p%C3%ABrdorimi-prind%C3%ABr"
								target="_blank"
								rel="noopener noreferrer"
							>
								LINK
							</a>{" "}
							në Akademi.al.
						</Trans>
					</Text>
				</Row>
				<Row className="pt-24">
					<Text fontSize="14" lineHeight="22" wheight="semibold" className="color-gray-9">
						{props.fullDate}
					</Text>
				</Row>
			</>
		);
	};

	const getImage = () => {
		return <Image src={props.welcomeImage} />;
	};

	return (
		<Card title={null} className="parent__dashboard__card">
			<Row>
				<Col xs={0} sm={0} md={18} lg={18} xl={18} xxl={20} className="p-24">
					{getContent()}
				</Col>
				<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={4}>
					<Row justify="end">{getImage()}</Row>
				</Col>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="pt-24">
					<Row justify="center">{getImage()}</Row>
				</Col>
				<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="p-24">
					{getContent()}
				</Col>
			</Row>
		</Card>
	);
};
