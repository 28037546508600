import * as Yup from "yup";
import {isValidDomain} from "services/common/check-domain";
import {OrganizationSectorType} from "services/domain/administration/Organization";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		name: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		type: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		contentType: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		sector: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`)),
		domain: Yup.string()
			.nullable()
			.notRequired()
			.trim()
			.lowercase()
			.test("isDomain", translate(`${TRANSLATION_BASE_PATH}._INVALID_DOMAIN`), value => isValidDomain(value)),
		numberOfUsers: Yup.number()
			.nullable()
			.notRequired()
			.when("sector", {
				is: (sector: string) => sector === OrganizationSectorType.Private,
				then: Yup.number()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_NUMBER`))
					.positive(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_POSITIVE`))
			}),
		price: Yup.number()
			.nullable()
			.notRequired()
			.when("sector", {
				is: (sector: string) => sector === OrganizationSectorType.Private,
				then: Yup.number()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_NUMBER`))
					.moreThan(-0.01, translate(`${TRANSLATION_BASE_PATH}._REQUIRED_POSITIVE`))
			}),
		ratio: Yup.number()
			.nullable()
			.notRequired()
			.when("sector", {
				is: (sector: string) => sector === OrganizationSectorType.Public,
				then: Yup.number()
					.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
					.typeError(translate(`${TRANSLATION_BASE_PATH}._REQUIRED_NUMBER`))
					.moreThan(-0.01, translate(`${TRANSLATION_BASE_PATH}._REQUIRED_POSITIVE`))
					.lessThan(100.01, translate(`${TRANSLATION_BASE_PATH}._REQUIRED_POSITIVE`))
			}),
		ownerEmail: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.email(translate(`${TRANSLATION_BASE_PATH}._INVALID_EMAIL`))
	});
