import React, { ReactNode } from "react";

import { Checkbox as AntCheckbox } from "antd";

declare type CheckboxValueType = string | number | boolean;

interface CheckboxProps {
	options?: string[];
	value?: string[];
	disabled?: boolean;
	defaultValue?: string[];
	children: ReactNode;
	onChange?: (checkedValue: Array<CheckboxValueType>) => void;
	name?: string
}

const Checkbox: React.FC<CheckboxProps> = props => {
	return <AntCheckbox.Group {...props}>{props.children}</AntCheckbox.Group>;
};

export default Checkbox;
