import React, { ReactNode } from "react";

interface FullScreenModalProps {
	head: ReactNode;
	body: ReactNode;
	footer: ReactNode;
}

export const FullScreenModal = function(props: FullScreenModalProps) {
	return <div className="full-screen-modal">
		<div className="full-screen-modal__head">{props.head}</div>
		<div className="full-screen-modal__body">{props.body}</div>
		<div className="full-screen-modal__footer">{props.footer}</div>
	</div>;
};