import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "assets/locales/en.json";
import translationAL from "assets/locales/al.json";

const resources = {
	en: {
		translation: translationEN
	},
	al: {
		translation: translationAL
	}
};

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: resources,
		fallbackLng: "al",
		debug: false,
		whitelist: ["al", "en"],
		initImmediate: true,
		lng: "al"
	});

export default i18n;
