import React, { ReactNode, Component } from "react";
import { withTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { message } from "antd";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Text from "common/components/general/Text";
import Radio from "common/components/dataEntry/formik/FormikRadioField";
import RadioElement from "common/components/dataEntry/components/RadioElement";
import Icon from "common/components/general/Icon";
import { formValidator } from "./ValidationSchema";
import { EducationLevel } from "services/domain/administration/Education";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_LEVEL";
class ChooseLevelComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			initialValues: {
				education: props.state.education ?? undefined,
			},
			gradeOptions: []
		};
	}

	componentDidMount() {
		const { t: translate } = this.props;
		this.props.setTitle(translate(`${TRANSLATION_BASE_PATH}._EDUCATION`));
	}

	onLevelChange = async (formik: FormikProps<any>, newLevelValue: string) => {
		const newState = {
			...this.props.state,
			education: newLevelValue
		};
		this.props.updateState(newState);
		if (newLevelValue != EducationLevel.Preschool) {
			await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				this.props.setIsSubmiting(false);
				return this.props.finish(newState.id ?? "", "", false);
			})
			.catch(error => {
				console.log(error);
				this.props.setIsSubmiting(false);
			});
		}
	};

	onSubmit = async (values: any) => {
		const newState = {
			...this.props.state,
			education: values.level
		};
		this.props.updateState(newState);
		await new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				this.props.setIsSubmiting(false);
				this.props.goNext();
				return;
			})
			.catch(error => {
				console.log(error);
				this.props.setIsSubmiting(false);
			});
	};

	render() {
		const { t: translate } = this.props;
		return (
			<Formik
				enableReinitialize={false}
				validationSchema={formValidator(translate)}
				initialValues={this.state.initialValues}
				onSubmit={this.onSubmit}
				validate={values => {
					this.props.setIsFormValid(formValidator(translate).isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					this.props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-8">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Text fontSize="14" lineHeight="22" className="color-gray-9">
										{translate(`${TRANSLATION_BASE_PATH}._CHOOSE_LEVEL_DESCRIPTION`)}
									</Text>
									<br />
								</Col>
							</Row>

							<Radio
								name="level"
								onRadioChange={e => {
									this.onLevelChange(formik, e.target.value);
								}}
								defaultValue={this.state.initialValues.level}
							>
								<Row gutter={[8, 8]}>
									<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={EducationLevel.Korporation} showAsBox={true}>
											<div className="level_level_type__option">
												<Icon type="ri-building-2-line" />
												{translate(`${TRANSLATION_BASE_PATH}._CORPORATION`)}
											</div>
										</RadioElement>
									</Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={EducationLevel.Preschool} showAsBox={true}>
											<div className="level_level_type__option">
												<Icon type="ri-open-arm-line" />
												{translate(`${TRANSLATION_BASE_PATH}._HIGHT_SCHOOL`)}
											</div>
										</RadioElement>
									</Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={EducationLevel.University} showAsBox={true}>
											<div className="level_level_type__option">
												<Icon type="ri-user-6-line" />
												{translate(`${TRANSLATION_BASE_PATH}._UNIVERSITY`)}
											</div>
										</RadioElement>
									</Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={EducationLevel.Professional} showAsBox={true}>
											<div className="level_level_type__option">
												<Icon type="ri-user-2-line" />
												{translate(`${TRANSLATION_BASE_PATH}._PROFESIONAL_EDUCATION`)}
											</div>
										</RadioElement>
									</Col>
								</Row>
								<Row gutter={[8, 8]}>
									<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
									<Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
										<RadioElement value={EducationLevel.Other} showAsBox={true}>
											<div className="level_level_type__option">
												<Icon type="ri-arrow-right-circle-line" />
												{translate(`${TRANSLATION_BASE_PATH}._OTHER`)}
											</div>
										</RadioElement>
									</Col>

								</Row>
							</Radio>
						</form>
					);
				}}
			</Formik>
		);
	}
}

export const ChooseLevel = withTranslation()(ChooseLevelComponent);
