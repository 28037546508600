import React, { ReactNode, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import classnames from "classnames";
// @ts-ignore
import katex from "katex";

// @ts-ignore
import mathquill4quill from "mathquill4quill";

import Text from "../../general/Text";
import Label from "../components/Label";

// @ts-ignore
window.katex = katex;
// @ts-ignore
window.$ = window.jQuery = require("jquery");
require("katex/dist/katex.min.css");
require("react-quill/dist/quill.snow.css");
require("mathquill/build/mathquill");
require("mathquill/build/mathquill.css");
require("mathquill4quill/mathquill4quill.css");

interface HtmlInputProps {
	name: string;
	id?: string;
	label?: string;
	labelInfo?: string | ReactNode;
	placeholder?: string;
	hint?: string | ReactNode;
	className?: string;
	readOnly?: boolean;
	value?: string;
	defaultValue?: string;
	onChange?: any
}

const toolbarOptions = [
	[{ header: [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
	[{ align: [] }],
	[{ list: "ordered" }, { list: "bullet" }],
	["blockquote", "code-block"],
	[{ indent: "-1" }, { indent: "+1" }],
	[{ script: "sub" }, { script: "super" }],
	["formula"]
];

export const HtmlInput = (props: HtmlInputProps) => {
	const reactQuill: any = React.createRef();
	const id = props.id || props.name;

	useEffect(() => {
		const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill });
		if (!props.readOnly) {
			enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
				operators: [
					["\\pm", "\\pm"],
					["\\sqrt{x}", "\\sqrt"],
					["\\sqrt[n]{x}", "\\nthroot"],
					["\\frac{x}{y}", "\\frac"],
					["\\sum ^{a}_{b} d", "\\sum"],
					["\\int ^{\\infty}_0", "\\int"]
				]
			});
		}
	}, []);

	const hintContent = props.hint ? (
		<Text fontSize="14" lineHeight="22" className="color-gray-7">
			{props.hint}
		</Text>
	) : null;

	const getModulesBasedOnReadOnlyStatus = () => {
		if (props.readOnly) {
			return {
				toolbar: false
			};
		}
		return {
			formula: true,
			toolbar: toolbarOptions
		};
	};

	const extraClassnames = classnames({ html__input_hide_wrapper_border: props.readOnly });

	return (
		<div>
			{props.label && (
				<Label htmlFor={id} className="hand-on-hover">
					{props.label}{" "}
					{props.labelInfo ? (
						<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
							{props.labelInfo}
						</Text>
					) : null}
				</Label>
			)}
			{hintContent}
			<ReactQuill
				id={id}
				theme="snow"
				ref={reactQuill}
				modules={getModulesBasedOnReadOnlyStatus()}
				placeholder={props.placeholder}
				className={`${extraClassnames} ${props.className ?? ""}`}
				value={props.value}
				readOnly={props.readOnly}
				onChange={props.onChange}
				defaultValue={props.defaultValue}
			/>
		</div>
	);
};
