import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Col, Divider, Row } from "antd";
import SectionHeader from "common/components/_vituralClassMainSection/SectionHeader";
import { SectionContent } from "common/components/_vituralClassMainSection/SectionContent";
import NewsFeeder from "common/components/_vituralClassMainSection/NewsFeeder";
import { TodaysTasks } from "common/components/_vituralClassMainSection/TodaysTasks";

import ClassroomService from "services/administration/classroom.service";

class StudentCourseOverviewComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			showScheduleMeeting: false,
			loadingTasks: false,
			tasks: []
		};
	}

	async componentDidMount() {
		await this.getTodaysTasks();
	}

	async getTodaysTasks(): Promise<any> {
		this.setState({
			loadingTasks: true
		});

		const tasks = await new ClassroomService().getStudentTasks(this.props.classroom.id);

		this.setState({
			tasks: tasks,
			loadingTasks: false
		});
	}

	render() {
		return (
			<>
				<Row justify="center">
					<SectionHeader
						classroom={this.props.classroom}
						pupils={this.props.enrolledStudents}
						organization={this.props.organization}
					/>
				</Row>
				<Row justify="center">
					<SectionContent>
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
							<Row>
								<Col span={24} className="course__builder__todaysTasks__card">
									<TodaysTasks
										items={this.state.tasks}
										viewAsTeacher={false}
										loadingTasks={this.state.loadingTasks}
										classroomId={this.props.classroom.id}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="pb-8">
							<Divider/>
						</Col>
						<NewsFeeder classroomId={this.props.classroom.id}></NewsFeeder>
						<Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6} className="pl-24">
							<Row>
								<Col span={24} className="course__builder__todaysTasks__card">
									<TodaysTasks
										items={this.state.tasks}
										viewAsTeacher={false}
										loadingTasks={this.state.loadingTasks}
										classroomId={this.props.classroom.id}
									/>
								</Col>
							</Row>
						</Col>
					</SectionContent>
				</Row>
			</>
		);
	}
}

const StudentCourseOverview = withTranslation()(StudentCourseOverviewComponent);
export default StudentCourseOverview;
