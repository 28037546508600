import { Card, Col, Row, Select, Skeleton, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import Image from "common/components/general/Image";
import { StatusIcon } from "./StatusIcon";
import AnalyticsCoursesService from "services/analytics/course.service";
import { TeacherAssignmentAnalyticsDto } from "dtos/analytics/teacherAssignmentAnalytics.dto";
import DashboardTeacherAssignmentsOverviewIcon from "assets/images/dashboard-teacher-assignments-overview.svg";
import { HOW_TO_ADD_ASSIGNMENT_URI } from "common/utils/Constants";

const TRANSLATION_BASE_PATH = "_MAIN._DASHBOARD._TEACHER_ASSIGNMENTS_OVERVIEW";

const getDefaultSelectedClassroom = (data: any[]) => {
	if (data.length === 0) return undefined;
	return data.sort((first, second) => (moment(first.endDate).isBefore(moment(second.endDate)) ? -1 : 1))[0]
		.classroomId;
};

const getDefaultSelectedAssignment = (data: any[], classroomId: string) => {
	if (data.length === 0) return undefined;
	return data
		.filter(item => item.classroomId === classroomId)
		.sort((first, second) => (moment(first.endDate).isBefore(moment(second.endDate)) ? -1 : 1))[0];
};

const getCardTitle = (translate: (key: string) => string) => {
	return (
		<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
			{translate(`${TRANSLATION_BASE_PATH}._TITLE`)}
		</Text>
	);
};

const emptyContent = (translate: (key: string) => string) => {
	return (
		<Space direction="horizontal" size={24} className="teacher__assignments_overview_empty_state_space">
			<Image src={DashboardTeacherAssignmentsOverviewIcon} />
			<Space direction="vertical" size={0}>
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION`)}{" "}
					<a href={HOW_TO_ADD_ASSIGNMENT_URI} target="_blank" rel="noopener noreferrer">
						{translate(`${TRANSLATION_BASE_PATH}._ARTICLE`)}
					</a>
					.
				</Text>
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{translate(`${TRANSLATION_BASE_PATH}._NO_DATA_DESCRIPTION_EXTRA_INFO`)}
				</Text>
			</Space>
		</Space>
	);
};

export const TeacherAssignmentsOverview = () => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const [loadingData, setLoadingData] = useState<boolean>(true);
	const [assignments, setAssignments] = useState<TeacherAssignmentAnalyticsDto[]>([]);
	const [selectedClassroomId, setSelectedClassroomId] = useState<string | undefined>(undefined);
	const [selectedAssignment, setSelectedAssignment] = useState<any>();

	useEffect(() => {
		const fetchData = () => {
			setLoadingData(true);
			return new AnalyticsCoursesService()
				.getAssignmentsAnalytics(
					moment()
						.startOf("day")
						.format("YYYY-MM-DD hh:mm")
				)
				.then((result: TeacherAssignmentAnalyticsDto[]) => {
					setAssignments(result);
					const defaultSelectedClassroomId = getDefaultSelectedClassroom(result);
					setSelectedClassroomId(defaultSelectedClassroomId);
					return setSelectedAssignment(getDefaultSelectedAssignment(result, defaultSelectedClassroomId));
				})
				.finally(() => {
					setLoadingData(false);
				});
		};
		fetchData();
	}, []);

	const getExtraContent = () => {
		if (assignments.length === 0 || !selectedAssignment) return null;
		const today = moment(selectedAssignment.endDate).isSame(moment(), "d");
		const iconColor = today ? "color-red-6" : "color-gray-6";
		const textColor = today ? "color-red-6" : "color-gray-8";
		return (
			<div
				className={`card__extra_next_assignment_end_date ${
					today ? "card__extra_next_assignment_end_date_warning" : ""
				}`}
			>
				<Icon type="ri-timer-line" className={`${iconColor} mr-6`} />
				<Text fontSize="12" lineHeight="20" className={textColor}>
					{moment(selectedAssignment.endDate).format("DD.MM.YYYY HH:mm")}
				</Text>
			</div>
		);
	};

	const getAssignmentsContent = () => {
		return [
			<Card.Grid hoverable={false} className="choose__classroom" key="choose-classroom">
				<Select
					defaultValue={selectedClassroomId}
					bordered={false}
					onChange={classroomId => {
						setSelectedClassroomId(classroomId);
						setSelectedAssignment(getDefaultSelectedAssignment(assignments, classroomId));
					}}
					className="full__width"
				>
					{assignments
						.filter((next, i, arr: any[]) => {
							return (
								arr.indexOf(
									arr.find((t: TeacherAssignmentAnalyticsDto) => t.classroomId === next.classroomId)
								) === i
							);
						})
						.map((item, index) => {
							return (
								<Select.Option value={item.classroomId} key={index}>
									<Space size={10} align="center">
										<Icon type="ri-door-open-line" className="color-gray-6" />
										<Text fontSize="14" lineHeight="22" className="color-gray-8" maxLength={30}>
											{item.classroomName}
										</Text>
									</Space>
								</Select.Option>
							);
						})}
				</Select>
			</Card.Grid>,
			<Card.Grid hoverable={false} className="choose__assignment" key="choose-assignment">
				<Select
					value={selectedAssignment?.id}
					bordered={false}
					onChange={assignmentId => {
						setSelectedAssignment(assignments.find(item => item.id === assignmentId));
					}}
					className="full__width"
				>
					{assignments
						.filter(item => item.classroomId === selectedClassroomId)
						.map((item, index) => {
							return (
								<Select.Option value={item.id} key={index}>
									<Space size={10} align="center">
										<Icon type="ri-todo-line" className="color-gray-6" />
										<Text fontSize="14" lineHeight="22" className="color-gray-8" maxLength={30}>
											{item.name}
										</Text>
									</Space>
								</Select.Option>
							);
						})}
				</Select>
			</Card.Grid>,
			<Card.Grid hoverable={false} className="full__width" key="assignment-details">
				{selectedAssignment ? (
					<Row>
						<Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6} className="right__divider enrolled__students">
							<Space
								direction="vertical"
								align="center"
								size={16}
								className="full__width enrolled__students_space"
							>
								<StatusIcon
									type="ri-user-line"
									color="color-gray-8"
									backgroundColor="background-color-gray-3"
								/>
								<Space
									direction="vertical"
									size={4}
									align="center"
									className="enrolled__students_value_space"
								>
									<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
										{selectedAssignment.enrolledStudents}
									</Text>
									<Text fontSize="12" lineHeight="20" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._ENROLLED_STUDENTS`)}
									</Text>
								</Space>
							</Space>
						</Col>
						<Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6} className="right__divider turned__in">
							<Space
								direction="vertical"
								align="center"
								size={16}
								className="full__width turned__in_space"
							>
								<StatusIcon
									type="ri-send-plane-line"
									color="color-blue-6"
									backgroundColor="background-color-blue-1"
								/>
								<Space direction="vertical" size={4} align="center" className="turned__in_value_space">
									<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
										{selectedAssignment.turnedIn}
									</Text>
									<Text fontSize="12" lineHeight="20" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._TURNED_IN`)}
									</Text>
								</Space>
							</Space>
						</Col>
						<Col
							xs={24}
							sm={8}
							md={6}
							lg={6}
							xl={6}
							xxl={6}
							className="right__divider evaluated__assignments"
						>
							<Space
								direction="vertical"
								align="center"
								size={16}
								className="full__width evaluated__assignments_space"
							>
								<StatusIcon
									type="ri-check-line"
									color="color-green-6"
									backgroundColor="background-color-green-1"
								/>
								<Space
									direction="vertical"
									size={4}
									align="center"
									className="evaluated__assignments_value_space"
								>
									<Text fontSize="24" lineHeight="32" wheight="semibold" className="color-gray-9">
										{selectedAssignment.evaluated}
									</Text>
									<Text fontSize="12" lineHeight="20" className="color-gray-7">
										{translate(`${TRANSLATION_BASE_PATH}._EVALUATED`)}
									</Text>
								</Space>
							</Space>
						</Col>
						<Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
							<div className="go__to_assignment">
								<Space direction="vertical" size={8} align="center">
									<Button
										icon="ri-arrow-right-line"
										type="default"
										size="middle"
										onClick={() => {
											history.push(
												`/material-info/_teacher/material/${selectedAssignment.id}?classroomId=${selectedAssignment.classroomId}`
											);
										}}
										shape="circle"
									></Button>
									<Text
										fontSize="14"
										lineHeight="22"
										className="color-blue-6 cursor_pointer"
										onClick={() => {
											history.push(
												`/material-info/_teacher/material/${selectedAssignment.id}?classroomId=${selectedAssignment.classroomId}`
											);
										}}
									>
										{translate(`${TRANSLATION_BASE_PATH}._VIEW_ASSIGNMENT`)}
									</Text>
								</Space>
							</div>
						</Col>
					</Row>
				) : null}
			</Card.Grid>
		];
	};

	return (
		<Skeleton active loading={loadingData}>
			<Card
				title={getCardTitle(translate)}
				extra={getExtraContent()}
				className="dashboard__teacher_assignments_overview_card"
			>
				{assignments && assignments.length > 0 ? getAssignmentsContent() : emptyContent(translate)}
			</Card>
		</Skeleton>
	);
};
