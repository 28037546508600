import React, { ReactNode, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import classnames from "classnames";
import ReactQuill, { Quill } from "react-quill";
// @ts-ignore
import katex from "katex";

// @ts-ignore
import mathquill4quill from "mathquill4quill";

import Error from "./FormItemError";
import Text from "../../general/Text";
import Label from "../components/Label";

// @ts-ignore
window.katex = katex;
// @ts-ignore
window.$ = window.jQuery = require("jquery");
require("katex/dist/katex.min.css");
require("react-quill/dist/quill.snow.css");
require("mathquill/build/mathquill");
require("mathquill/build/mathquill.css");
require("mathquill4quill/mathquill4quill.css");

interface HtmlInputProps {
	name: string;
	id?: string;
	label?: string;
	labelInfo?: string | ReactNode;
	placeholder?: string;
	hint?: string | ReactNode;
	className?: string;
	basicToolbar?: boolean;
	customToolbar?: any[];
}

const getToolbarOptions = (basic: boolean, customToolbar: any[]) => {
	let basicToolbarOptions: any[] = [
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		["bold", "italic", "underline"],
		[{ align: [] }],
		[{ list: "ordered" }, { list: "bullet" }],
		["blockquote", "code-block"],
		[{ indent: "-1" }, { indent: "+1" }]
	];
	basicToolbarOptions.push(...customToolbar);

	if (basic) return basicToolbarOptions;

	basicToolbarOptions.push([{ script: "sub" }, { script: "super" }]);
	basicToolbarOptions.push(["formula"]);

	return basicToolbarOptions;
};

export const HtmlInput = (props: HtmlInputProps) => {
	const reactQuill: any = React.createRef();
	const [field, meta] = useField(props.name);
	const { setFieldValue } = useFormikContext();
	const showError = meta.touched && meta.error;
	const error = showError ? <Error>{meta.error}</Error> : <Error></Error>;
	const id = props.id || props.name;

	useEffect(() => {
		const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill });
		enableMathQuillFormulaAuthoring(reactQuill.current.editor, {
			operators: [
				["\\pm", "\\pm"],
				["\\sqrt{x}", "\\sqrt"],
				["\\sqrt[n]{x}", "\\nthroot"],
				["\\frac{x}{y}", "\\frac"],
				["\\sum ^{a}_{b} d", "\\sum"],
				["\\int ^{\\infty}_0", "\\int"]
			]
		});
	}, []);

	const onChange = (content: string) => {
		setFieldValue(props.name, content);
	};

	const errorClassname = classnames({ error_input: showError, formik_html_input: true });

	const hintContent = props.hint ? (
		<Text fontSize="14" lineHeight="22" className="color-gray-7">
			{props.hint}
		</Text>
	) : null;

	return (
		<div>
			{props.label && (
				<Label htmlFor={id} className="hand-on-hover">
					{props.label}{" "}
					{props.labelInfo ? (
						<Text fontSize="14" lineHeight="22" className="color-gray-7 pl-8">
							{props.labelInfo}
						</Text>
					) : null}
				</Label>
			)}
			{hintContent}
			<ReactQuill
				id={id}
				theme="snow"
				onChange={onChange}
				ref={reactQuill}
				modules={{
					formula: true,
					toolbar: getToolbarOptions(props.basicToolbar ?? false, props.customToolbar ?? [])
				}}
				placeholder={props.placeholder}
				className={`${errorClassname} ${props.className ?? ""}`}
				defaultValue={field.value}
			/>
			{showError && error}
		</div>
	);
};
