import { Col, Row } from "antd";
import { Formik } from "formik";
import React, { Component } from "react";
import Input from "common/components/dataEntry/formik/FormikInputField";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import OAuth2Service, { RECAPTCHA_KEY } from "../../../../services/authentication/oauth2.service";
import Button from "common/components/general/Button";
import Text from "common/components/general/Text";
import { formValidatorParentEmail } from "./ParentEmailValidationSchema";
import PasswordInput from "common/components/dataEntry/formik/FormikPasswordField";
import { showError } from "../../../../common/utils/Notification";
import { JWTTokenDto } from "../../../../dtos/authentication/token/jwtToken.dto";
import { PARENT_PATH } from "../../../parent";
import { PARENT_DASHBOARD_PATH } from "../../../parent/scenes/dashboard";
import UserSettingsService from "services/administration/userSettings.service";
import UserService from "services/authentication/user.service";
import { REGISTER_PATH } from ".";
import { Link } from "react-router-dom";
import { SIGN_IN_PATH } from "../signIn";

export const REGISTER_PARENT_PATH = "parent";
const TRANSLATION_BASE_PATH = "_LOGIN._REGISTER._PARENT";

class ParentRegistrationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			formInitialValues: {
				email: "",
				password: ""
			},
			submitting: false,
			parentActive: false,
			parentEmail: "",
			parentName: ""
		};
	}

	checkEmail = (values: any) => {
		const email = values.email.trim();
		
		return OAuth2Service.loginClient().then(() => {
			return new UserService().existsParentUser(email);
		}).then((existsParent: boolean) => {
			if (!existsParent) this.props.showRegisterParent(email);
			return this.setState({
				parentEmail: email,
				parentName: email,
				parentActive: existsParent,
				formInitialValues: {
					email: email,
					password: values.password
				}
			});
		}).finally(() =>
			this.setState({submitting: false})
		);
	}

	onSubmit = async (values: any) => {
		this.setState({submitting: true});

		const { t: translate, history } = this.props;

		if (values.email.trim() && values.password) {
			return OAuth2Service.loginUser(values.email.trim(), values.password)
				.then(async (token: JWTTokenDto) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
					// @ts-ignore
					// eslint-disable-next-line no-undef
					smartlook("identify", token.UserId, {
						name: token.User.firstName + " " + token.User.lastName,
						email: token.User.email ?? token.User.parentEmail,
						username: token.User.username
					});
					return localStorage.removeItem("profile-picture-string");
				})
				.then(async () => {
					await new UserService().updateCurrentRoleInBaseOrganization();
					// Re login to refresh token
					return OAuth2Service.loginUser(values.email.trim(), values.password);
				})
				.then(() => {
					return new UserSettingsService().getSettings();
				}).then(result => {
					localStorage.setItem("user-settings", JSON.stringify(result));
					return window.location.href = `/${PARENT_PATH}/${PARENT_DASHBOARD_PATH}`;
				})
				.catch(error => {
					const errorStatus = error.response?.status ?? "500";
					showError(translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`));
				}).finally( () =>
					this.setState({
						submitting: false
					})
				);
		}
	}

	render() {
		const { t: translate } = this.props;
		return (
			<>
				{!this.state.parentActive && (
					<Formik
						enableReinitialize={true}
						isInitialValid={false}
						validationSchema={formValidatorParentEmail(translate)}
						initialValues={this.state.formInitialValues}
						onSubmit={this.checkEmail}
					>
						{formik => (
							<form onSubmit={formik.handleSubmit} autoComplete="off">
								<Row justify="center" className="pb-48 pt-8">
									<Text fontSize="14" lineHeight="22" className="color-gray-8">
										<Link to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`}
											onClick={this.props.goBack}>
											{translate(`${TRANSLATION_BASE_PATH}._FORM._BACK`)}
										</Link>
									</Text>
								</Row>
								<Row gutter={24} className="pb-24">
									<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
										<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-gray-9">
											{translate(`${TRANSLATION_BASE_PATH}._FORM._TITLE`)}
										</Text>
									</Col>
								</Row>
								<Row gutter={24} className="pb-24">
									<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._FORM._SUBTITLE`)}
										</Text>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18}>
										<Input name="email"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._EMAIL`)}
											size="large" />
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18}>
										<ReCAPTCHA ref={this.props.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18}>
										<Button
											type="primary"
											htmlType="submit"
											icon="ri-arrow-right-line"
											size="large"
											iconPosition="right"
											className="full_width_button"
											disabled={!formik.isValid}
											loading={this.state.submitting}
										>
											{translate(`${TRANSLATION_BASE_PATH}._REGISTER_LABEL`)}
										</Button>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				)}
				{this.state.parentActive && (
					<Formik
						enableReinitialize={true}
						isInitialValid={false}
						validationSchema={formValidatorParentEmail(translate)}
						initialValues={this.state.formInitialValues}
						onSubmit={this.onSubmit}
					>
						{formik => (
							<form onSubmit={formik.handleSubmit} autoComplete="off">
								<Row gutter={24} className="pb-24">
									<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
										<Text fontSize="30" lineHeight="38" wheight="semibold" className="color-gray-9">
											{translate(`${TRANSLATION_BASE_PATH}._FORM._PARENT_ACTIVE_TITLE`, {parentName: this.state.parentName })}
										</Text>
									</Col>
								</Row>
								<Row gutter={24} className="pb-24">
									<Col xs={0} sm={0} md={4} lg={0} xl={3} xxl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18} xxl={18}>
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._FORM._PARENT_ACTIVE_SUBTITLE`)}
										</Text>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18}>
										<PasswordInput
											name="password"
											label={translate(`${TRANSLATION_BASE_PATH}._FORM._PASSWORD`)}
											showPopover={true}
											size="large"
											autoComplete="new-password"
										/>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18}>
										<ReCAPTCHA ref={this.props.recaptchaRef} sitekey={RECAPTCHA_KEY} size="invisible" />
									</Col>
								</Row>
								<Row gutter={24}>
									<Col xs={0} sm={0} md={4} lg={0} xl={3}></Col>
									<Col xs={24} sm={24} md={16} lg={24} xl={18}>
										<Button
											type="primary"
											htmlType="submit"
											icon="ri-arrow-right-line"
											size="large"
											iconPosition="right"
											className="full_width_button"
											disabled={!formik.isValid}
											loading={this.state.submitting}
										>
											{translate(`${TRANSLATION_BASE_PATH}._REGISTER_LABEL`)}
										</Button>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				)}
			</>
		);
	}
}

const ParentRegistration = withTranslation()(ParentRegistrationComponent);
export default ParentRegistration;
