import _ from "lodash";

export interface ColumnsToSearch {
	column1: string;
	column2?: string;
	column3?: string;
	column4?: string;
	column5?: string;
}

export const SearchUtil = (data: any, value: string, columnsToSearch: ColumnsToSearch) => {
	let result = _.cloneDeep(data);
	if (value.trim().length > 0 && data !== undefined && data.length > 0) {
		value = value.toLowerCase();
		result = data.filter((x: any) => {
			return (
				x[columnsToSearch?.column1]?.toLowerCase().indexOf(value) > -1 ||
				(columnsToSearch?.column2 !== undefined &&
					x[columnsToSearch?.column2]?.toLowerCase().indexOf(value) > -1) ||
				(columnsToSearch?.column3 !== undefined &&
					x[columnsToSearch?.column3]?.toLowerCase().indexOf(value) > -1) ||
				(columnsToSearch?.column4 !== undefined &&
					x[columnsToSearch?.column4]?.toLowerCase().indexOf(value) > -1) ||
				(columnsToSearch?.column5 !== undefined && x[columnsToSearch?.column5]?.toLowerCase().indexOf(value) > -1)
			);
		});
	}
	return result;
};
