/* eslint-disable react/jsx-no-undef */
import React, { ReactNode, useState } from "react";
import { Layout, Row, Col } from "antd";
import Image from "common/components/general/Image";
import Logo from "assets/images/icons/Logo.svg";
import LoggedInMenu from "common/components/_loggedInMenu/LoggedInMenu";
import { useHistory } from "react-router-dom";
import { ADMIN_PATH } from "../../../scenes/admin/index";
import { DASHBOARD_PATH } from "../../../scenes/admin/scenes/dashboard";
import Icon from "../general/Icon";
import { MainMenu } from "../_leftSideBar/components/MainMenu";
import Text from "common/components/general/Text";
import { useStoreState } from "easy-peasy";
import { getDesignFile } from "../../utils/Design";

const { Header: AntHeader } = Layout;

export const Header = (props: any) => {
	const history = useHistory();
	const primaryLogo = useStoreState(state => state.designSettings.items.primaryLogo);
	const designLoading = useStoreState(state => state.designSettings.loading);

	const [fullScreenMenuPage, toggleFullScreenMenu] = useState<ReactNode>();

	return (
		<>
			<AntHeader>
				<Row justify="space-between" align="middle">
					{props.menu && (
						<Col xs={4} sm={8} md={0} lg={0} xl={0} xxl={0}>
							<Icon
								type="ri-menu-2-line"
								onClick={() =>
									toggleFullScreenMenu(
										<MainMenu toggle={() => toggleFullScreenMenu(null)} menu={props.menu} />
									)
								}
							/>
						</Col>
					)}
					<Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
						<Row justify="space-between">
							<a
								onClick={() => history.push(`/${ADMIN_PATH}/${DASHBOARD_PATH}`)}
								className="header__logo"
							>
								{!designLoading && <Image src={ getDesignFile(Logo, primaryLogo) } />}
							</a>
						</Row>
					</Col>
					<Col xs={10} sm={6} md={10} lg={10} xl={10} xxl={10}>
						<Row justify="end">
							{!fullScreenMenuPage && (
								<Col>
									<LoggedInMenu
										showSwitchRole={props.showSwitchRole}
										showParentProfileLinks={props.showParentProfileLinks} />
								</Col>
							)}
							{fullScreenMenuPage && (
								<Text lineHeight="24" fontSize="24">
									<Icon onClick={() => toggleFullScreenMenu(null)} type="ri-close-line"></Icon>
								</Text>
							)}
						</Row>
					</Col>
				</Row>
			</AntHeader>
			{fullScreenMenuPage && (
				<Row className="fullScreenMenu">
					<Col span={24}>{fullScreenMenuPage}</Col>
				</Row>
			)}
		</>
	);
};
