import React, { ReactNode } from "react";
import { Layout } from "antd";
import { LeftSideBar } from "common/components/_leftSideBar/LeftSideBar";
import { Header } from "common/components/_header1/Header1";
import { ADMIN_PATH } from "scenes/admin";
import { DASHBOARD_PATH } from "../../scenes/dashboard";
import { SUBJECTS_PATH } from "../../scenes/subjects";
import { LEVELS_PATH } from "../../scenes/levels";
import { ASYNCHCLASSES_PATH } from "../../scenes/asynch-classes";
import { LIVECLASSES_PATH } from "../../scenes/live-classes";
import { ORGANIZATIONS_PATH } from "../../scenes/organizations/scenes/list";
import { USERS_PATH } from "../../scenes/users";
import { TAG_MANAGER_PATH } from "../../scenes/tag-manager";
import OAuth2Service from "services/authentication/oauth2.service";

const { Content } = Layout;

interface LayoutProps {
	children: ReactNode;
}

export const MainLayout = (props: LayoutProps) => {
	const menuItems = [
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_1",
			path: `/${ADMIN_PATH}/${DASHBOARD_PATH}`,
			icon: "ri-home-3-line",
			key: "1",
			show: true
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_2",
			path: `/${ADMIN_PATH}/${SUBJECTS_PATH}`,
			icon: "ri-booklet-line",
			key: "2",
			show: true
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_3",
			path: `/${ADMIN_PATH}/${LEVELS_PATH}`,
			icon: "ri-function-line",
			key: "3",
			show: true
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_4",
			path: `/${ADMIN_PATH}/${ASYNCHCLASSES_PATH}`,
			icon: "ri-slideshow-3-line",
			key: "4",
			show: true
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_5",
			path: `/${ADMIN_PATH}/${LIVECLASSES_PATH}`,
			icon: "ri-slideshow-3-line",
			key: "5",
			show: true
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_6",
			path: `/${ADMIN_PATH}/${ORGANIZATIONS_PATH}`,
			icon: "ri-building-4-line",
			key: "6",
			show: OAuth2Service.isOwnerOrAdminAtSuperOrganization
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_7",
			path: `/${ADMIN_PATH}/${TAG_MANAGER_PATH}`,
			icon: "ri-price-tag-line",
			key: "7",
			show: true
		},
		{
			name: "_ADMIN._LEFT_SIDE_MENU._ITEM_LAST",
			path: `/${ADMIN_PATH}/${USERS_PATH}`,
			icon: "ri-team-line",
			key: "LAST",
			show: true
		}
	];

	const menuItemsDisplayFilter = menuItems.filter((menu: any) => menu.show).map((menu: any, index: number) => {
		delete menu.show;
		return {...menu, key: (index + 1)};
	});
	return (
		<Layout>
			<Header menu={menuItemsDisplayFilter} />
			<Layout className="full__min_heigh">
				<LeftSideBar menu={menuItemsDisplayFilter} />
				<Content className="p-0 background-color-gray-2">
					{props.children}
				</Content>
			</Layout>
		</Layout>
	);
};
