import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Skeleton from "common/components/feedback/Skeleton";
import CustomizePage from "../../scenes/customize/scenes/templates/index";
import { Pages } from "./scenes/pages";
import { PageComponents } from "./scenes/page-components";
import { DASHBOARD_PATH } from "../../../dashboard";
import { ORGANIZATIONS_PATH } from "../list";
import { SectionTitle } from "../../../../components/sectionTitle/SectionTitle";
import OrganizationService from "../../../../../../services/administration/organization.service";
import { loadCertificationDomainState } from "../../utils/utils";
import { withTranslation } from "react-i18next";
import { EditPageComponent } from "./scenes/page-components/edit";

class CustomizeIndex extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: true,
			organizationId: null,
			organizationDto: null
		};
	}

	/**
	 * Load organization data
	 */
	loadOrganizationProfile = () => {
		return new OrganizationService()
			.findOrganizationById(this.state.organizationId)
			.then((organization: any) => {
				return this.setState(
					{
						organizationDto: organization
					},
					() => this.loadCertificationDomainState()
				);
			});
	};

	/**
	 * Get domain certificate
	 */
	async loadCertificationDomainState() {
		const res = await loadCertificationDomainState(this.state.organizationDto.domain);
		return this.setState({
			organizationDto: {
				...this.state.organizationDto,
				certificationState: res.certificationState,
				certificationLinked: res.certificationLinkedState
			}
		});
	}

	componentDidMount() {
		this.setState(
			{
				loading: false,
				organizationId: this.props.match.params.organizationId,
			},
			() => {
				this.loadOrganizationProfile();
			}
		);
	}

	render() {

		const { t: translate } = this.props;
		const title = this.state.organizationDto?.name;
		const smallTitle = this.state.organizationDto?.code;
		const subTitle1 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const subTitle2 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_6_LONG");
		const customizeTitle = "Personalizo";
		const FULL_ORGANIZATION_PATH = `${ORGANIZATIONS_PATH}/${this.props.match.params.organizationId}`;

		const path = this.props.match.path;
		return (
			<Skeleton active loading={this.state.loading}>
				{!this.state.loading && (
					<>
						<SectionTitle
							title={title}
							smallTitle={smallTitle}
							subtitle={[subTitle1, subTitle2, title, customizeTitle]}
							subtitleUrl={[DASHBOARD_PATH, ORGANIZATIONS_PATH, FULL_ORGANIZATION_PATH]}
							certificationState={
								this.state.organizationDto?.certificationState === "ISSUED"
									? `${this.state.organizationDto?.certificationState}_${this.state.organizationDto?.certificationLinked}`
									: this.state.organizationDto?.certificationState
							}
						/>

						<Switch>
							<Route
								path={`${path}`}
								exact
								render={props =>
									!this.state.loading && (
										<CustomizePage {...props} organizationDto={this.state.organizationDto} />
									)
								}
							/>
							<Route
								path={`${path}/:templateId`}
								exact
								render={props =>
									!this.state.loading && (
										<Pages {...props} organizationDto={this.state.organizationDto} />
									)
								}
							/>
							<Route
								path={`${path}/:templateId/:pageId`}
								exact
								render={props => <PageComponents organizationDto={this.state.organizationDto} {...props} />}
							/>
							<Route
								path={`${path}/:pageId/:pageComponentId/edit-components`}
								exact
								render={props => <EditPageComponent organizationDto={this.state.organizationDto} {...props} />}
							/>
						</Switch>
					</>
				)}
			</Skeleton>
		);
	}
}

const CustomizeIndexPage = withTranslation()(CustomizeIndex);
export default CustomizeIndexPage;
