import React, { useState, useEffect, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Skeleton } from "antd";

import Row from "common/components/layout/Row";
import Col from "common/components/layout/Col";
import Input from "common/components/dataEntry/formik/FormikInputField";
import Alert from "common/components/feedback/Alert";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { formValidator } from "./ValidationSchema";
import OnboardingService from "services/administration/onboarding.service";
import UpdateWizardDto from "dtos/administration/onboarding/updateWizard.dto";
import { BaseComponentProps } from "../OnboardingStepsMapping";
import { MAIN_PATH } from "scenes/main";
import OAuth2Service from "services/authentication/oauth2.service";
import InvitationService from "services/administration/invitation.service";

const TRANSLATION_BASE_PATH = "_ONBOARDING._VIRTUAL_CLASSROOM";

export const VirtualClassroom = (props: BaseComponentProps) => {
	const { t: translate } = useTranslation();
	const history = useHistory();
	const [hasError, setHasError] = useState<{ error: boolean; errorMessage: string }>({
		error: false,
		errorMessage: ""
	});
	const [classroomInvitations, setClassroomInvitations] = useState<any[]>();
	const [loading, setLoading] = useState<boolean>(false);

	const fetchClassroomInvitations = async () => {
		setLoading(true);
		await new InvitationService()
			.getClassroomInvitations()
			.then((result: any) => {
				if (result && result.length > 0) {
					setClassroomInvitations(result);
				}
				return;
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		props.setIsFormValid(true);
		props.setTitle(translate(`${TRANSLATION_BASE_PATH}._TITLE`));
		fetchClassroomInvitations();
	}, []);

	const showAlertContent = (): ReactNode => {
		return hasError.error ? (
			<Row className="mb-24">
				<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
				<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
					<Alert message={hasError.errorMessage} type="error" showIcon closable />
				</Col>
			</Row>
		) : null;
	};

	const onSubmit = async (values: any) => {
		const newState = {
			...props.state,
			virtualClassCode: values.virtualClassCode
		};
		props.updateState(newState);
		return new OnboardingService()
			.updateWizard(
				new UpdateWizardDto({
					id: newState.id ?? "",
					State: JSON.stringify(newState)
				})
			)
			.then(() => {
				return new OnboardingService().finish({
					wizardId: newState.id ?? "",
					nationality: props.state.nationality,
					grade: props.state.grade,
					classRooms: props.state.classRooms,
					virtualClassCode: values.virtualClassCode,
					profilePicture: props.state.profilePicture,
					profilePictureType: props.state.profilePictureType,
					profilePictureFile: props.state.profilePictureFile
				});
			})
			.then(() => {
				const decodedToken = OAuth2Service.CurrentToken;
				return OAuth2Service.refreshToken(decodedToken.refreshToken);
			})
			.then(() => {
				return history.push(`/${MAIN_PATH}`);
			})
			.catch(error => {
				const errorStatus = error?.statusCode ?? "500";
				return setHasError({
					error: true,
					errorMessage:
						Number(errorStatus) === Number("404")
							? translate(`${TRANSLATION_BASE_PATH}._WRONG_CODE`)
							: translate(`${TRANSLATION_BASE_PATH}._${errorStatus}_API_STATUS_CODE`)
				});
			})
			.finally(() => {
				props.setIsSubmiting(false);
			});
	};

	return (
		<Skeleton active loading={loading}>
			<Formik
				enableReinitialize={true}
				validationSchema={formValidator}
				initialValues={{ virtualClassCode: "" }}
				onSubmit={onSubmit}
				validate={values => {
					props.setIsFormValid(formValidator().isValidSync(values));
					return {};
				}}
			>
				{formik => {
					const { handleSubmit, submitForm } = formik;
					props.bindSubmitFormHandler(submitForm);
					return (
						<form onSubmit={handleSubmit} autoComplete="off">
							<Row className="mb-24">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16}>
									<Alert
										type="info"
										showIcon
										message={translate(`${TRANSLATION_BASE_PATH}._ALERT_TITLE`)}
										description={translate(`${TRANSLATION_BASE_PATH}._ALERT_DESCRIPTION`)}
									/>
								</Col>
							</Row>
							{showAlertContent()}
							<Row className="mb-8">
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={15} lg={10} xl={10} xxl={10}>
									<Text fontSize="14" lineHeight="22" wheight="semibold">
										{translate(`${TRANSLATION_BASE_PATH}._INPUT_CLASS_CODE`)}{" "}
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{translate(`${TRANSLATION_BASE_PATH}._INPUT_CLASS_CODE_HINT`)}
										</Text>
									</Text>
								</Col>
							</Row>
							{classroomInvitations?.map((classroom, index) => {
								return (
									<Row key={index}>
										<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
										<Col xs={24} sm={24} md={15} lg={10} xl={10} xxl={10}>
											<Input
												name={`classroom-${index}`}
												size="large"
												value={`${classroom.code} (${classroom.name})`}
												disabled
												disabledTooltip={translate(
													`${TRANSLATION_BASE_PATH}._CLASS_CODE_DISABLED_TOOLTIP`
												)}
												suffix={
													<Icon type="ri-checkbox-circle-fill" className="color-green-6" />
												}
											/>
										</Col>
									</Row>
								);
							})}
							<Row>
								<Col xs={0} sm={0} md={0} lg={2} xl={4} xxl={4}></Col>
								<Col xs={24} sm={24} md={15} lg={10} xl={10} xxl={10}>
									<Input
										name="virtualClassCode"
										size="large"
										placeholder={translate(`${TRANSLATION_BASE_PATH}._CLASS_CODE_PLACEHOLDER`)}
									/>
								</Col>
							</Row>
						</form>
					);
				}}
			</Formik>
		</Skeleton>
	);
};
