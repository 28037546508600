import { Col, message, Row } from "antd";
import { Uploader, UploadImageType } from "common/components/uploader/Uploader";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import DesignSettingService from "../../../../../../services/design/designSetting.service";
import Image from "common/components/general/Image";
import { DesignOptionType, DesignSettingList } from "../../scenes/customize/utils/types";
import { DesignSettingDto } from "dtos/administration/customize/DesignSetting.dto";
import { PlusOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "services/api.service";
import Button from "common/components/general/Button";
import Text from "common/components/general/Text";

export interface DesignFilesInterface {
	organizationId: string;
	domain: string;
	default: boolean;
}

export function DesignFiles(props: DesignFilesInterface) {
	const [designFields, setDesignFields] = useState<DesignSettingList>({});
	const domainEncoded = props.default ? encodeURIComponent("https://" + props.domain) : props.organizationId;
	const [uploadedTime, setUploadedTime] = React.useState(Date.now());

	// Get the design list
	useEffect(() => {
		const getDesignList = (): void => {
			new DesignSettingService()
				.findAll(props.organizationId)
				.then((res: DesignSettingList) => {
					return setDesignFields(res);
				})
				.catch(err => {
					console.log(err);
				});
		};

		getDesignList();
	}, []);

	/**
	 * Save file value in the server
	 * @param fileUploaded file
	 * @param type state name to store
	 */
	const uploadFile = async (fileUploaded: any, type: DesignOptionType) => {
		if (fileUploaded) {
			const designSetting: DesignSettingDto = {
				optionName: type,
				optionValue: fileUploaded.filePath + "/" + fileUploaded.name,
				id: designFields[type]?.id ?? uuidv4(),
				OrganizationId: props.organizationId
			};
			return new DesignSettingService()
				.saveDesignSetting(designSetting)
				.then(() => {
					designFields[type] = designSetting;
					setUploadedTime(Date.now());
					return setDesignFields({
						...designFields,
						[type]: designSetting
					});
				})
				.catch(err => {
					message.error(err.data.message);
				});
		}
		return false;
	};

	/**
	 * Delete the option name
	 * @param type state name to store
	 */
	const deleteFile = async (type: DesignOptionType) => {
		if (!designFields[type]) {
			return;
		}
		const optionId: string = designFields[type]?.id as string;

		return new DesignSettingService().deleteOption(optionId).then(() => {
			return setDesignFields({
				...designFields,
				[type]: undefined
			});
		});
	};

	/**
	 * Renders a file input
	 * @param type
	 * @param title
	 * @param uploadImageType
	 */
	const singleUploadFile = function(type: DesignOptionType, title: string, uploadImageType: UploadImageType) {
		return (
			<Row justify={"center"} className="single-file-upload">
				<Col xs={24} className="single-file-upload__title">
					<Text fontSize="14" lineHeight="22" className="color-gray-6">
						{title}
					</Text>
				</Col>
				<Col xs={24} className="single-file-upload__upload-container">
					<Uploader
						// noCrop
						fileType="IMAGE"
						uploadImageType={uploadImageType}
						filePath={`design/${props.organizationId}/images/${type}`}
						fileWithUniqueId={true}
						onUploadingDone={(fileUploaded: any) => uploadFile(fileUploaded, type)}
						showUploadList={false}
						onRemoveUpload={() => deleteFile(type)}
						className="quiz__add_question_upload_photo"
					>
						{designFields[type] ? (
							<div className="onboarding__upload_avatar_preview_wrapper">
								<Image
									src={`${API_BASE_URL}/v1/api/s3/design-setting/${domainEncoded}/${type}?${uploadedTime}`}
								/>
							</div>
						) : (
							<div className="ant-upload ant-upload-select ant-upload-select-picture-card">
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Ngarko</div>
							</div>
						)}
					</Uploader>
				</Col>

				<Col xs={24} className="single-file-upload__remove-link">
					{designFields[type] && (
						<Button type="link" onClick={() => deleteFile(type)}>
							Hiq
						</Button>
					)}
				</Col>
			</Row>
		);
	};

	return (
		<Row className="design-files">
			<Col xs={24} sm={12} md={8} className="design-files__item">
				{singleUploadFile("primaryLogo", "Primary logo", UploadImageType.Cover)}
			</Col>
			<Col xs={24} sm={12} md={8} className="design-files__item">
				{singleUploadFile("primaryInverseLogo", "Primary inverse logo", UploadImageType.Cover)}
			</Col>
			<Col xs={24} sm={12} md={8} className="design-files__item">
				{singleUploadFile("favicon", "Favicon", UploadImageType.Avatar)}
			</Col>
		</Row>
	);
}
