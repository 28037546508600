import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_COURSE_BUILDER.";
const TRANSLATION_BASE_PATH_KEY = "_COURSE_BUILDER._REQUIRED";

export const formValidator = (translate: (key: string) => string, modality: string, fileUploaded: any) =>
	Yup.object({
		path:
			modality === "url"
				? Yup.string()
						.url(translate(TRANSLATION_BASE_PATH + "_INVALID_URL"))
						.required(translate(TRANSLATION_BASE_PATH_KEY))
				: Yup.string()
						.trim()
						.required(
							translate(`${TRANSLATION_BASE_PATH}_BUILD_MATERIALS._LESSON_MATERIAL._ONE_FILE_REQUIRED`)
						),
		title: Yup.string()
			.nullable()
			.trim()
			.required(translate(TRANSLATION_BASE_PATH_KEY))
	});
