import ApiServiceData from "../api.service";
import {getLoadBalancerEnvSpec, LoadBalancerEnv, LoadBalancerEnvSpec} from "services/s3/utils";
const relativeUrl = "/v1/api/aws-acm";

export default class ACMService extends ApiServiceData {
	public requestCertificate(data: {domain: string, organizationName: string}, environment: LoadBalancerEnv, subdomain: string):
		Promise<{
			environmentVar: LoadBalancerEnvSpec,
			certificateVar: {Name: string, Type: string, Value: string, CertificateArn: string}, subdomain: string
		}> {
		const environmentVar: LoadBalancerEnvSpec = getLoadBalancerEnvSpec(environment);
		return this.post(`${relativeUrl}`, {...data, loadBalancerArn: environmentVar.arn})
			.then(res => {
				return {
					environmentVar: environmentVar, certificateVar: res.data, subdomain: subdomain
				};
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message
				)
					throw error.response.data.message;
				throw error.response;
			});
	}

	public deleteCertification(certificateArn: string, environment: LoadBalancerEnv): Promise<any> {
		const environmentVar: LoadBalancerEnvSpec = getLoadBalancerEnvSpec(environment);
		return this.post(`${relativeUrl}/delete`, {certificateArn: certificateArn, loadBalancerArn: environmentVar.arn})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getCertification(domain: string): Promise<string> {
		return this.get(`${relativeUrl}/${domain}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message === "Certifikata nuk gjëndet e krijuar në AWS!"
				)
					return null;
				throw error.response;
			});
	}

	public listCertificates(): Promise<any> {
		return this.get(`${relativeUrl}`)
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}

	public getResourceRecordOfCertificate(certificateArn: string, environment: LoadBalancerEnv, subdomain: string): Promise<any> {
		const environmentVar: LoadBalancerEnvSpec = getLoadBalancerEnvSpec(environment);
		return this.post(`${relativeUrl}/describe`, {certificateArn})
			.then(res => {
				return {
					environmentVar: environmentVar, certificateVar: res.data, subdomain: subdomain
				};
			})
			.catch(error => {
				throw error.response;
			});
	}
}
