import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_LEVEL";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		level: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.oneOf(["PRE_SCHOOL"], "Not valid option")
	});
