import ApiServiceData from "../api.service";
import { CollectedDataDto } from "../../dtos/administration/tutoring/collectedData.dto";
const relativeUrl = "/v1/api/tutoring";

export default class TutoringEmailService extends ApiServiceData {
	public sendTutoringRequestEmail(data: CollectedDataDto): Promise<string> {
		return this.post(relativeUrl + "/contact", {
			subjects: data.subjects.map(subject => {
				return {
					subject: subject.name,
					grades: data.grades.find(grade => grade.subjectName === subject.name)?.grades
				};
			}),
			times: data.times,
			phoneNumber: data.contact.phoneNumber,
			reason: data.contact.reason,
		})
			.then(res => {
				return res.data;
			})
			.catch(error => {
				throw error.response;
			});
	}
}
