import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import Image from "common/components/general/Image";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { MainLayout } from "scenes/main/components/layout/Layout";
import { boolVal, getComponentProps } from "../../../../../../common/utils/Design";

const TRANSLATION_BASE_PATH = "_MAIN._SETTINGS._CHANGE_PASSWORD";

export const ChangePassword = (props: any) => {
	const { t: translate } = useTranslation();
	const sideLayoutProps = getComponentProps(props.pageComponents, "sideLayout");

	return (
		<MainLayout
			showMarketPlace={boolVal(sideLayoutProps, "showExploreCourses", true)}
			showAddCourse={boolVal(sideLayoutProps, "joinLiveCourse", true)}
		>
			<div className="settings__change_password pt-24 pl-8 pr-8">
				<Card title={translate(`${TRANSLATION_BASE_PATH}._TITLE`)} className="settings__change_password_card">
					<Row gutter={[24, 24]}>
						<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="change__password_image_preview">
							<Image src={require("assets/images/change-password.svg")} />
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
							<ChangePasswordForm />
						</Col>
					</Row>
				</Card>
			</div>
		</MainLayout>
	);
};
