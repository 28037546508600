import * as Yup from "yup";

const TRANSLATION_BASE_PATH = "_ONBOARDING._CHOOSE_GRADE";

export const formValidator = (translate: (key: string) => string) =>
	Yup.object({
		level: Yup.string()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
			.oneOf(["PRESCHOOL", "ELEMENTARY", "MIDDLE", "HIGH"], "Not valid option"),
		grade: Yup.string()
			.nullable()
			.trim()
			.required(translate(`${TRANSLATION_BASE_PATH}._REQUIRED`))
	});
